import {
  ApiHeaders,
  API_NAME,
  BASE_URL,
  INVENTORY_API_NAME,
} from './../API/variable'
/** @format */

//fetch animals
import { API } from 'aws-amplify'
import axios from 'axios'

export const fetchleatherProfile = async (params: any) => {
  const data: { data: any } = await API.get(
    API_NAME,
    `/leatherProfile?limit=${params.limit}&page=${params.page}`,
    {
      headers: ApiHeaders,
    }
  )
  return { data }
}

// leather add on
export const fetchleatherAddOn = async (params: any) => {
  const data: { data: any } = await API.get(
    API_NAME,
    `/leatherAddOns?limit=${params.limit}&page=${params.page}`,
    {
      headers: ApiHeaders,
    }
  )
  return { data }
}
// leather add on
export const fetchleatherInventory = async (params: any) => {
  const data: { data: any } = await API.get(
    API_NAME,
    `/item_dropdown?limit=0&page=0`,
    {
      headers: ApiHeaders,
    }
  )
  return { data }
}
export const postleatherAddOn = async (params: any) => {
  const url = params?._id ? `/leatherAddOns/${params._id}` : '/leatherAddOns'
  const data: { data: any } = await API.post(API_NAME, url, {
    headers: ApiHeaders,
    body: { ...params },
  })
  return { data }
}

export const postLeatherProfile = async (data: any) => {
  const res: { res: any } = await API.post(API_NAME, `/leatherProfile`, {
    headers: ApiHeaders,
    body: { ...data },
  })
  return { res }
}

export const cutLeatherAddOn = async (id: any) => {
  const res: { res: any } = await API.del(API_NAME, `/leatherAddOns/${id}`, {
    headers: ApiHeaders,
  })
  return { res }
}
