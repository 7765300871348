import { Col, Image, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import ImageIcon from '../../../Assets/Icons/ImageIcon.svg'
import MediaUrl from '../../../Utils/mediaUrl'

const LeathertDetails = () => {
  const { state }: any = useLocation()
  const [detailList, setDetailList] = useState<any>([])

  useEffect(() => {
    setDetailList([
      state?.finish,
      state?.hideType,
      state?.agingProcess,
      state?.touch,
      state?.texture,
      state?.inventory_item,
    ])
  }, [state])
  return (
    <>
      <Row gutter={[30, 30]}>
        <Col sm={24} xxl={6}>
          <div
            style={{
              padding: '10px 20px',
              borderRadius: '10px',
              minHeight: '350px',
              minWidth: '300px',
              height: '100%',
              position: 'relative',
              boxShadow:
                '2px 2px 4px rgba(114, 142, 171, 0.2), -6px -6px 20px 5px rgba(255, 255, 255, 1), 4px 4px 20px rgba(111, 140, 176, 0.5)',
            }}
          >
            <div
              className='image-container'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Image
                style={{
                  width: '200px',
                  height: '200px',
                  objectFit: 'contain',
                }}
                preview={false}
                src={MediaUrl(state?.inventory_item?.icon) || ImageIcon}
                alt={state?._id}
              />
            </div>
            <h3
              style={{
                fontSize: '22px',
                fontWeight: '700',
                marginLeft: 5,
                position: 'absolute',
                top: '90%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              {state?.inventory_item?.item_name}
            </h3>
          </div>
        </Col>
        <Col sm={24} xxl={18}>
          <div
            style={{
              padding: '30px',
              borderRadius: '10px',
              height: '100%',
              boxShadow:
                '2px 2px 4px rgba(114, 142, 171, 0.2), -6px -6px 20px 5px rgba(255, 255, 255, 1), 4px 4px 20px rgba(111, 140, 176, 0.5)',
            }}
          >
            <Row
              align='middle'
              style={{
                height: '100%',
              }}
              gutter={[15, 15]}
            >
              <Col
                sm={12}
                xs={24}
                lg={8}
                xl={6}
                style={{
                  padding: '10px 20px',
                  height: 300,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography.Title level={5}>TOUCH</Typography.Title>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#f5f5f5',
                    marginTop: '10px',
                    position: 'relative',
                    borderRadius: '5px',
                  }}
                >
                  <div
                    className='image-container'
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <Image
                      style={{
                        width: '70px',
                        height: '70px',
                        objectFit: 'contain',
                      }}
                      preview={false}
                      src={ImageIcon}
                      alt={state?._id}
                    />
                  </div>
                  <Typography.Title
                    style={{
                      fontSize: 14,
                      width: 'fit-content',
                      position: 'absolute',
                      top: '90%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    {state?.touch?.title}
                  </Typography.Title>
                </div>
              </Col>

              <Col
                sm={12}
                xs={24}
                lg={8}
                xl={6}
                style={{
                  padding: '10px 20px',
                  height: 300,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography.Title level={5}>FINISH</Typography.Title>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#f5f5f5',
                    marginTop: '10px',
                    position: 'relative',
                    borderRadius: '5px',
                  }}
                >
                  <div
                    className='image-container'
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <Image
                      style={{
                        width: '70px',
                        height: '70px',
                        objectFit: 'contain',
                      }}
                      preview={false}
                      src={ImageIcon}
                      alt={state?._id}
                    />
                  </div>
                  <Typography.Title
                    style={{
                      fontSize: 14,
                      width: 'fit-content',
                      position: 'absolute',
                      top: '90%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    {state?.finish?.title}
                  </Typography.Title>
                </div>
              </Col>

              <Col
                sm={12}
                xs={24}
                lg={8}
                xl={6}
                style={{
                  padding: '10px 20px',
                  height: 300,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography.Title level={5}>TEXTURE</Typography.Title>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#f5f5f5',
                    marginTop: '10px',
                    position: 'relative',
                    borderRadius: '5px',
                  }}
                >
                  <div
                    className='image-container'
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <Image
                      style={{
                        width: '70px',
                        height: '70px',
                        objectFit: 'contain',
                      }}
                      preview={false}
                      src={ImageIcon}
                      alt={state?._id}
                    />
                  </div>
                  <Typography.Title
                    style={{
                      fontSize: 14,
                      width: 'fit-content',
                      position: 'absolute',
                      top: '90%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    {state?.texture?.title}
                  </Typography.Title>
                </div>
              </Col>

              <Col
                sm={12}
                xs={24}
                lg={8}
                xl={6}
                style={{
                  padding: '10px 20px',
                  height: 300,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography.Title level={5}>AGING PROCESS</Typography.Title>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#f5f5f5',
                    marginTop: '10px',
                    position: 'relative',
                    borderRadius: '5px',
                  }}
                >
                  <div
                    className='image-container'
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <Image
                      style={{
                        width: '70px',
                        height: '70px',
                        objectFit: 'contain',
                      }}
                      preview={false}
                      src={ImageIcon}
                      alt={state?._id}
                    />
                  </div>
                  <Typography.Title
                    style={{
                      fontSize: 14,
                      width: 'fit-content',
                      position: 'absolute',
                      top: '90%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    {state?.agingProcess?.title}
                  </Typography.Title>
                </div>
              </Col>

              <Col
                sm={12}
                xs={24}
                lg={8}
                xl={6}
                style={{
                  padding: '10px 20px',
                  height: 300,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography.Title level={5}>HIDE TYPE</Typography.Title>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#f5f5f5',
                    marginTop: '10px',
                    position: 'relative',
                    borderRadius: '5px',
                  }}
                >
                  <div
                    className='image-container'
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <Image
                      style={{
                        width: '70px',
                        height: '70px',
                        objectFit: 'contain',
                      }}
                      preview={false}
                      src={ImageIcon}
                      alt={state?._id}
                    />
                  </div>
                  <Typography.Title
                    style={{
                      fontSize: 14,
                      width: 'fit-content',
                      position: 'absolute',
                      top: '90%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    {state?.hideType?.title}
                  </Typography.Title>
                </div>
              </Col>

              <Col
                sm={12}
                xs={24}
                lg={8}
                xl={6}
                style={{
                  padding: '10px 20px',
                  height: 300,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography.Title level={5}>INVENTORY</Typography.Title>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#f5f5f5',
                    marginTop: '10px',
                    position: 'relative',
                    borderRadius: '5px',
                  }}
                >
                  <div
                    className='image-container'
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <Image
                      style={{
                        width: '70px',
                        height: '70px',
                        objectFit: 'contain',
                      }}
                      preview={false}
                      src={ImageIcon}
                      alt={state?._id}
                    />
                  </div>
                  <Typography.Title
                    style={{
                      fontSize: 14,
                      width: 'fit-content',
                      position: 'absolute',
                      top: '90%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    {state?.inventory_item?.item_name}
                  </Typography.Title>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default LeathertDetails
