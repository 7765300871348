import { DeleteFilled, EditFilled, PlusCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tabs,
  Typography,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import CarouselLeatherProfile from '../../../../Components/Admin/CarouselLeatherProfile/CarouselLeatherProfile'
import CommonS3UploadFiles from '../../../../Components/Admin/S3UploadFiles/CommonS3UploadFiles'
import { LatidoAnimatedLogoLoader } from '../../../../Components/LatidoAnimateLogo/LatidoAnimateLogo'
import {
  GetLeatherAddOn,
  GetLeatherInventory,
  PostLeatherAddOn,
  PostLeatherProfile,
} from '../../../../Redux/Actions/LeatherProfileAction'
import MediaUrl from '../../../../Utils/mediaUrl'
import ImageIcon from '../../../../Assets/Icons/ImageIcon.svg'
import { cutLeatherAddOn } from '../../../../Apis/LeatherProfileApi'

const { TabPane } = Tabs

const TabTitle = ({ children }: any) => {
  return (
    <Typography.Title
      level={5}
      style={{
        textTransform: 'uppercase',
      }}
    >
      {children}
    </Typography.Title>
  )
}

const AddLeather = () => {
  const [activeTab, setActiveTab] = useState('1')
  const [selectedData, setSelectedData] = useState<any>({
    inventory_item: '',
    finish: '',
    texture: '',
    agingProcess: '',
    hideType: '',
    touch: '',
  })
  const [edit, setEdit] = useState<any>(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const { LeatherAddOn, loading, LeatherInventory, creating } = useSelector(
    (state: RootStateOrAny) => state.LeatherProfileReducer
  )

  const handleTabChange = (key: string) => {
    setActiveTab(key)
  }

  useEffect(() => {
    dispatch(GetLeatherAddOn({ page: 0, limit: 0 }))
    dispatch(GetLeatherInventory({ page: 1, limit: 10 }))
  }, [])

  useEffect(() => {
    if (Object.keys(LeatherAddOn).length > 0) {
      if (activeTab === '6') {
        setData(LeatherAddOn?.filter((item: any) => item.type === 'touch'))
      }
      if (activeTab === '2') {
        setData(LeatherAddOn?.filter((item: any) => item.type === 'finish'))
      }
      if (activeTab === '3') {
        setData(LeatherAddOn?.filter((item: any) => item.type === 'texture'))
      }
      if (activeTab === '4') {
        setData(
          LeatherAddOn?.filter((item: any) => item.type === 'agingProcess')
        )
      }
      if (activeTab === '5') {
        setData(LeatherAddOn?.filter((item: any) => item.type === 'hideType'))
      }
    }
  }, [activeTab, LeatherAddOn])

  const handleSelect = (name: string, value: string) => {
    setSelectedData({
      ...selectedData,
      [name]: value,
    })
  }

  const handleNext = () => {
    if (activeTab === '1' && selectedData.inventory_item === '') {
      return message.error('Please select inventory item')
    }
    if (activeTab === '6' && selectedData.touch === '') {
      return message.error('Please select touch')
    }
    if (activeTab === '2' && selectedData.finish === '') {
      return message.error('Please select finish')
    }
    if (activeTab === '3' && selectedData.texture === '') {
      return message.error('Please select texture')
    }
    if (activeTab === '4' && selectedData.agingProcess === '') {
      return message.error('Please select aging process')
    }
    if (activeTab === '5' && selectedData.hideType === '') {
      return message.error('Please select hide type')
    }

    setActiveTab(String(parseInt(activeTab) + 1))
  }

  const handlePrev = () => {
    if (activeTab === '1') {
      return navigate(-1)
    }

    setActiveTab(String(parseInt(activeTab) - 1))
  }

  const handleSubmit = () => {
    dispatch(PostLeatherProfile({ ...selectedData }))
  }

  const handleModalCLose = () => {
    setIsModalVisible(false)
  }

  const handleDeleteAddOn = async () => {
    setIsDeleting(true)
    try {
      await cutLeatherAddOn(
        selectedData[Object.keys(selectedData)[parseFloat(activeTab) - 1]]
      )
      message.success('Add on deleted successfully')
      setSelectedData({
        ...selectedData,
        [selectedData[Object.keys(selectedData)[parseFloat(activeTab) - 1]]]:
          '',
      })
      dispatch(GetLeatherAddOn({ page: 0, limit: 0 }))
      setIsDeleteModalVisible(false)
    } catch (e: any) {
      message.error(e?.response?.data?.message)
    }
    setIsDeleting(false)
  }

  return (
    <>
      {loading && (
        <Row justify='center'>
          <LatidoAnimatedLogoLoader />
        </Row>
      )}
      {!loading && (
        <>
          <Row justify='center'>
            <Tabs
              activeKey={activeTab}
              onChange={handleTabChange}
              tabBarGutter={100}
            >
              <TabPane disabled tab={<TabTitle>Inventory</TabTitle>} key='1'>
                <Row>
                  <CarouselLeatherProfile
                    LeatherProfileList={LeatherInventory}
                    selected={selectedData.inventory_item}
                    name='inventory_item'
                    handleSelect={handleSelect}
                  />
                </Row>
              </TabPane>

              <TabPane disabled tab={<TabTitle>Finish</TabTitle>} key='2'>
                <Row>
                  <CarouselLeatherProfile
                    LeatherProfileList={data}
                    selected={selectedData.finish}
                    name='finish'
                    handleSelect={handleSelect}
                  />
                </Row>
              </TabPane>
              <TabPane disabled tab={<TabTitle>Texture</TabTitle>} key='3'>
                <Row>
                  <CarouselLeatherProfile
                    LeatherProfileList={data}
                    selected={selectedData.texture}
                    name='texture'
                    handleSelect={handleSelect}
                  />
                </Row>
              </TabPane>
              <TabPane
                disabled
                tab={<TabTitle>Aging Process</TabTitle>}
                key='4'
              >
                <Row>
                  <CarouselLeatherProfile
                    LeatherProfileList={data}
                    selected={selectedData.agingProcess}
                    name='agingProcess'
                    handleSelect={handleSelect}
                  />
                </Row>
              </TabPane>
              <TabPane disabled tab={<TabTitle>Hide Type</TabTitle>} key='5'>
                <Row>
                  <CarouselLeatherProfile
                    LeatherProfileList={data}
                    selected={selectedData.hideType}
                    name='hideType'
                    handleSelect={handleSelect}
                  />
                </Row>
              </TabPane>
              <TabPane disabled tab={<TabTitle>Touch</TabTitle>} key='6'>
                <Row>
                  <CarouselLeatherProfile
                    LeatherProfileList={data}
                    selected={selectedData.touch}
                    name='touch'
                    handleSelect={handleSelect}
                  />
                </Row>
              </TabPane>
            </Tabs>
          </Row>
          {activeTab !== '1' && (
            <Row
              justify='end'
              style={{
                marginInline: 'auto',
                marginTop: '100px',
                width: 'clamp(700px, 70vw, 1400px)',
                gap: 30,
              }}
            >
              {selectedData[
                Object.keys(selectedData)[parseFloat(activeTab) - 1]
              ] && (
                <>
                  <Button
                    style={{
                      width: '150px',
                    }}
                    icon={<EditFilled />}
                    type='link'
                    onClick={() => {
                      setEdit(true)
                      setIsModalVisible(true)
                    }}
                  >
                    Edit
                  </Button>

                  <Button
                    style={{
                      width: '150px',
                    }}
                    icon={<DeleteFilled />}
                    type='link'
                    onClick={() => {
                      setIsDeleteModalVisible(true)
                    }}
                  >
                    Delete
                  </Button>
                </>
              )}
              <Button
                style={{
                  width: '150px',
                }}
                icon={<PlusCircleOutlined />}
                type='link'
                onClick={() => {
                  setEdit(false)
                  setIsModalVisible(true)
                }}
              >
                Add New
              </Button>
            </Row>
          )}
          <Row
            justify='end'
            style={{
              marginInline: 'auto',
              marginTop: '100px',
              width: 'clamp(700px, 70vw, 1400px)',
              gap: 30,
            }}
          >
            <Button
              style={{
                width: '150px',
              }}
              type='primary'
              onClick={handlePrev}
            >
              {activeTab === '1' ? 'Cancel' : 'Previous'}
            </Button>
            <Button
              disabled={creating}
              style={{
                width: '150px',
              }}
              type='primary'
              onClick={activeTab === '6' ? handleSubmit : handleNext}
            >
              {activeTab === '6' ? 'Add' : 'Next'}
            </Button>
          </Row>
        </>
      )}

      {isModalVisible && (
        <FormModal
          isModalVisible={isModalVisible}
          isEdit={edit}
          handleCancel={handleModalCLose}
          type={Object.keys(selectedData)[parseFloat(activeTab) - 1]}
          selectedData={
            selectedData[Object.keys(selectedData)[parseFloat(activeTab) - 1]]
          }
          data={data}
        />
      )}

      <Modal
        visible={isDeleteModalVisible}
        onCancel={() => setIsDeleteModalVisible(false)}
        okText='Delete'
        okType='danger'
        okButtonProps={isDeleting ? { disabled: true } : {}}
        onOk={handleDeleteAddOn}
      >
        <div
          style={{
            display: 'flex',
            margin: '10px 0',
            cursor: 'pointer',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <DeleteFilled
            style={{
              marginRight: '10px',
              color: 'red',
            }}
          />
          <span>Are you sure you want to Delete?</span>
        </div>
      </Modal>
    </>
  )
}

export default AddLeather

interface ModalPropsType {
  isModalVisible?: boolean
  isEdit?: boolean
  handleCancel?: () => void
  type?: string
  data?: any
  selectedData?: any
}
const FormModal = ({
  isModalVisible = false,
  handleCancel = () => {},
  isEdit = false,
  data,
  selectedData,
  type = '',
}: ModalPropsType) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [initialImage, setInitialImage] = useState('')

  useEffect(() => {
    if (type) {
      form.setFieldsValue({
        type,
      })
    }
  }, [type])

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        let data = values
        if (isEdit) {
          data = {
            ...values,
            _id: selectedData,
          }
        }

        dispatch(PostLeatherAddOn({ ...data }))
      })
      .catch((info) => {})
      .finally(() => {
        form.resetFields()
        handleCancel()
      })
  }

  useEffect(() => {
    if (isEdit) {
      const formValue = data.find((item: any) => item._id === selectedData)
      setInitialImage(MediaUrl(formValue.image))
      form.setFieldsValue({
        ...formValue,
      })
    }
  }, [selectedData])
  return (
    <Modal
      visible={isModalVisible}
      onOk={handleFormSubmit}
      onCancel={handleCancel}
    >
      <Form layout='vertical' form={form}>
        <Form.Item
          label='Image'
          name='image'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CommonS3UploadFiles
            module='item'
            type='circular'
            onUpload={(e) => {
              form.setFieldsValue({
                image: e,
              })
            }}
            url={initialImage ?? ''}
            prefix='admin/leather'
            // type="product"
            title='Add Image'
          />
        </Form.Item>
        <Form.Item label='Type' name='type'>
          <Select>
            <Select.Option value='touch'>Touch</Select.Option>
            <Select.Option value='finish'>Finish</Select.Option>
            <Select.Option value='texture'>Texture</Select.Option>
            <Select.Option value='agingProcess'>Aging Process</Select.Option>
            <Select.Option value='hideType'>Hide Type</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label='title' name='title'>
          <Input type='text' />
        </Form.Item>
        <Form.Item label='Data' name='data'>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}
