import {
  CloseCircleFilled,
  CloseCircleOutlined,
  CopyFilled,
  EyeFilled,
  MinusCircleOutlined,
  MinusOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Typography,
  Modal,
  Tooltip,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import CommonS3UploadFiles, {
  ProductFeatureContainer,
} from "../../../../Components/Admin/S3UploadFiles/CommonS3UploadFiles";
import { ReactComponent as MultipleImage } from "../../../../Assets/Icons/MultipleImage.svg";

import { useEffect, useState } from "react";
import {
  addProductSizeHardware,
  duplicateLeather,
  getProductDropDownItems,
} from "../../../../Redux/ProductRedux/ProductApis";
import { ProductSpecification } from "../../../../Types/product";
import { ProductHardwareSetup } from "./ProductHardwareSetup";
import MediaUrl from "../../../../Utils/mediaUrl";
import { asyncForEach } from "../../../../Utils/asyncForEach";
import { useDispatch, useSelector } from "react-redux";
import { getCreateSize } from "../../../../Utils/ProductSizeHardware";
import { fetchProduct } from "../../../../Redux/ProductRedux/ProductActions";
import { ProductHardwareSetupView } from "./ProductHardwareSetupView";
const { Panel } = Collapse;

export const availableSizes = [
  {
    name: "30",
    value: 30,
  },
  {
    name: "32",
    value: 32,
  },
  {
    name: "34",
    value: 34,
  },
  {
    name: "36",
    value: 36,
  },
  {
    name: "38",
    value: 38,
  },
  {
    name: "40",
    value: 40,
  },
  {
    name: "42",
    value: 42,
  },
  {
    name: "44",
    value: 44,
  },
  {
    name: "46",
    value: 46,
  },
  {
    name: "48",
    value: 48,
  },
  {
    name: "50",
    value: 50,
  },
  {
    name: "52",
    value: 52,
  },
  {
    name: "54",
    value: 54,
  },
  {
    name: "56",
    value: 56,
  },
  {
    name: "58",
    value: 58,
  },
  {
    name: "60",
    value: 60,
  },
];

interface ProductDetailsFormProps {
  onSubmit?: (e: any) => void;
  updating?: boolean;
  creating?: boolean;
  update?: boolean;
  data?: ProductSpecification[] | undefined;
  hardware?: string[];
  lining?: string[];
  productId?: string;
}
export const ProductDetailsForm = ({
  onSubmit,
  updating,
  creating,
  update,
  data,
  hardware,
  lining,
  productId,
}: ProductDetailsFormProps) => {
  const [dropodownItems, setDropdownItems] = useState<any>(false);
  const [specificationForm] = useForm();

  const [secondaryImages, setSecondaryImages] = useState<any>([]);
  const [multipleImageModal, setMultipleImageModal] = useState<any>(false);

  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);

  const [hardwareSetup, setHardwareSetup] = useState<{
    productId?: string;
    productSpecificationId?: string;
    sizeRangeId?: string;
    size?: number;
  }>({
    productId: productId,
  });

  const dispatch = useDispatch();

  const getDropdowns = async () => {
    await getProductDropDownItems({ limit: 0, page: 0, item: true })
      .then((data: any) => {
        setDropdownItems(data?.data);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  const { sizeDetail, details } = useSelector((state: any) => state.ProductReducer);

  const [duplicatedValue, setDuplicatedValue] = useState<any>("");

  const [loading, setLoading] = useState(false);

  const [viewHardware, setViewHardware] = useState<any>(false);

  const [alreadySelectedSize, setAlreadySelectedSize] = useState<(number)[]>([]);

  const getItem = (values: any, rangeID: any, pSepecId: any) => {
    const items = {
      productId: values.productId,
      productSpecificationId: duplicatedValue,
      sizeRangeId: rangeID.sizeRangeId,
      hardware: values.hardware,
      size: values.size,
      inventory: values.inventory?.map((iv: any) => ({
        isEditable: iv?.isEditable,
        itemId: iv?.itemId,
        quantity: iv?.quantity,
      })),
    };

    return items;
  };

  const duplicateHardware = async () => {
    const currentLeather = data?.[data?.length - 1];
    const availableHardwares = sizeDetail?.filter(
      (sz: any) => sz?.productSpecificationId === duplicatedValue
    );

    await asyncForEach(currentLeather?.size_range, async (a: any, index: any) => {
      const oldData = availableHardwares?.find((d: any) => d?.size === a?.size) || false;
      if (oldData) {
        await addProductSizeHardware(getItem(oldData, a?._id, currentLeather?._id))
          ?.then((data) => {
            // onClose({})
          })
          .catch((error) => {});
      }
    });

    setDuplicatedValue("");

    message.success("Leather duplicated successfully");
  };

  useEffect(() => {
    if (duplicatedValue) {
      duplicateHardware();
    }
  }, [data]);

  const [leatherNames, setLeatherNames] = useState<any>([]);

  useEffect(() => {
    specificationForm.setFieldsValue({
      product_specification: data?.map((ps) => {
        return (
          ps && {
            secondary_image: [
              ...(ps.secondary_image ? ps.secondary_image : MediaUrl(ps?.leather_id?.icon)),
            ],
            leather_id: ps.leather_id?._id,
            default_hardware: ps?.default_hardware,
            default_lining: ps?.default_lining,
            size_range: ps.size_range?.map((sr) => {
              return {
                size: sr.size,
                price: sr.price?.map((pr) => {
                  return {
                    currency: pr.currency,
                    value: pr.value,
                  };
                }),
              };
            }),
          }
        );
      }),
    });
    setLeatherNames(data?.map((ps) => ps?.leather_id?.item_name));
  }, [data]);

  const [duplicate, setDuplicate] = useState<any>({});
  const [duplicating, setDuplicating] = useState(false);

  const startDuplicate = async () => {
    setDuplicating(true);
    await duplicateLeather(duplicate)
      .then(() => {
        dispatch(fetchProduct({ id: productId }));
        setDuplicate({});
      })
      .catch(() => {
        message.error("Not able to duplicate selected data!");
      });
    setDuplicating(false);
    specificationForm.resetFields(["duplicate_leather_profile"]);
  };

  return (
    <Form
      layout="vertical"
      form={specificationForm}
      name="basic"
      onFinish={onSubmit}
      className="style--kbbs"
    >
      <Modal
        footer={false}
        visible={duplicate?.productId}
        onCancel={() => {
          setDuplicate({});
          specificationForm.resetFields(["duplicate_leather_profile"]);
        }}
      >
        <Typography.Title level={5}>DUPLICATE FOR</Typography.Title>

        <Form.Item name="duplicate_leather_profile">
          <Select
            style={{ width: "100%" }}
            onChange={(e) => setDuplicate({ ...duplicate, leatherId: e })}
          >
            {dropodownItems &&
              dropodownItems
                ?.filter((leather: any) => {
                  return !specificationForm
                    ?.getFieldsValue()
                    ?.product_specification?.some((ps: any) => {
                      return ps?.leather_id === leather?._id;
                    });
                })
                .map((category: any) => {
                  return (
                    <Select.Option value={category?._id} key={category?._id}>
                      {category?.item_name}
                    </Select.Option>
                  );
                })}
          </Select>
        </Form.Item>

        <Button
          loading={duplicating}
          onClick={() => startDuplicate()}
          disabled={!duplicate?.leatherId}
          type="primary"
          style={{ marginTop: 50 }}
        >
          DUPLICATE
        </Button>
      </Modal>
      <Card>
        <Row gutter={[40, 40]}>
          <Col xl={18} lg={22} md={22} sm={22} xs={23}>
            <Form.Item hidden initialValue={false} name="duplicate">
              <Checkbox />
            </Form.Item>
            <Form.List name="product_specification">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <Collapse expandIconPosition="right">
                    {fields.map((key, name, ...restField) => {
                      let productSpecificationId = data?.[key.name]?._id;
                      let leather = leatherNames[key.name];
                      let leatherName =
                        specificationForm?.getFieldsValue()?.product_specification?.[key.name]
                          ?.leather_id;
                      // ?.name?.leather_id;
                      return (
                        <Panel
                          className="leather__group__panel"
                          header={leather ? leather : `Leather ${key.name + 1}`}
                          key={name}
                        >
                          <Row justify="start">
                            <Col xl={20} lg={22} md={23} sm={24}>
                              <Form.Item hidden name={[name, "secondary_image"]}>
                                <Input />
                              </Form.Item>

                              <Form.Item
                                shouldUpdate={true}
                                {...restField}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Missing Images",
                                //   },
                                // ]}
                              >
                                {/* <CommonS3UploadFiles
                              style={{ width: "100%" }}
                              type="product-feature"
                              module="product"
                            /> */}
                                {() => {
                                  const imagesItems =
                                    specificationForm.getFieldsValue()?.product_specification[
                                      key.name
                                    ]?.secondary_image || [];

                                  return (
                                    <Row gutter={[20, 20]}>
                                      {imagesItems.slice(0, 4).map((value: any, index: number) => {
                                        return (
                                          <Col lg={4} key={index}>
                                            <div
                                              style={{
                                                position: "relative",
                                                borderRadius: 5,
                                                overflow: "hidden",
                                                height: "100%",
                                              }}
                                            >
                                              {index > 2 && imagesItems.length > 4 && (
                                                <div
                                                  style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    backgroundColor: "#00000070",
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    zIndex: 2,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Typography.Title
                                                    level={4}
                                                    style={{
                                                      color: "white",
                                                    }}
                                                  >
                                                    +{imagesItems?.length - 4}
                                                  </Typography.Title>
                                                </div>
                                              )}
                                              <Image
                                                alt="Secondary"
                                                src={value}
                                                style={{ width: "100%" }}
                                              />
                                            </div>
                                          </Col>
                                        );
                                      })}
                                      <Col flex="auto">
                                        <div
                                          onClick={() => {
                                            setMultipleImageModal(key.name + 1);
                                            setSecondaryImages(imagesItems);
                                          }}
                                          className=""
                                          style={{
                                            height: "100%",
                                            textAlign: "center",
                                            background: "#EBF5FF",
                                            border: "0.25px solid #B5B5B5",
                                            padding: "10px 0px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div>
                                            <MultipleImage />
                                            <Typography.Paragraph style={{ marginBottom: 0 }}>
                                              Upload More
                                            </Typography.Paragraph>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }}
                              </Form.Item>
                            </Col>
                            <Col lg={20}>
                              <Form.Item shouldUpdate>
                                {() => {
                                  const selectedLeathers = specificationForm
                                    .getFieldsValue()
                                    ?.product_specification?.map((lt: any) => lt?.leather_id);
                                  return (
                                    <Form.Item
                                      {...restField}
                                      label="Leather Type"
                                      name={[name, "leather_id"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing Leather Type",
                                        },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        disabled={data && name > data.length - 1 ? false : true}
                                        // Search on the basis of leather type label
                                        filterOption={(inputValue, options: any) => {
                                          if (options) {
                                            return (
                                              options?.children
                                                // .join("")
                                                .toLowerCase()
                                                .includes(inputValue.toLowerCase())
                                            );
                                          }
                                        }}
                                        onChange={(e) => {
                                          const itemName = dropodownItems?.find(
                                            (di: any) => di?._id === e
                                          )?.item_name;

                                          const newNames = [...leatherNames];
                                          newNames[key.name] = itemName;

                                          setLeatherNames(newNames);
                                        }}
                                      >
                                        {dropodownItems &&
                                          dropodownItems?.map((category: any) => {
                                            return (
                                              <Select.Option
                                                value={category?._id}
                                                key={category?._id}
                                                disabled={selectedLeathers?.includes(category?._id)}
                                              >
                                                {category?.item_name}
                                              </Select.Option>
                                            );
                                          })}
                                      </Select>
                                    </Form.Item>
                                  );
                                }}
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row
                            style={{
                              display: "flex",
                              marginBottom: 8,
                              width: "100%",
                            }}
                            gutter={[20, 20]}
                            align="top"
                            justify="start"
                          >
                            <Col lg={8}>
                              <Form.Item
                                {...restField}
                                label="Hardware"
                                name={[name, "default_hardware"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing hardware",
                                  },
                                ]}
                              >
                                <Select onChange={(e) => {}} showSearch>
                                  {Array.isArray(hardware) &&
                                    hardware?.map((hardware_item: any, index: number) => {
                                      return (
                                        <Select.Option value={hardware_item} key={index}>
                                          {hardware_item}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col lg={8}>
                              <Form.Item
                                {...restField}
                                label="Lining"
                                name={[name, "default_lining"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing lining",
                                  },
                                ]}
                              >
                                <Select
                                  // onChange={(e) => {}}
                                  showSearch
                                >
                                  {Array.isArray(lining) &&
                                    lining?.map((lining_item: any, index: number) => {
                                      return (
                                        <Select.Option value={lining_item} key={index}>
                                          {lining_item}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>

                          <Form.List initialValue={[""]} name={[name, "size_range"]}>
                            {(fields, { add: addSize, remove: removeSize }) => (
                              <>
                                {fields.map(
                                  (
                                    { key: sizeKey, name: sizeName, ...sizeRestField },
                                    index: number
                                  ) => {
                                    const sizeData = data?.[key?.name]?.size_range?.[sizeKey];
                                    if (sizeData?.size && !alreadySelectedSize.includes(sizeData.size)) {
                                      setAlreadySelectedSize((prev:any) => [...prev, sizeData.size]);
                                    }
                                    return (
                                      <Row
                                        key={sizeKey}
                                        style={{
                                          display: "flex",
                                          marginBottom: 8,
                                          width: "100%",
                                        }}
                                        gutter={[20, 20]}
                                        align="top"
                                        justify="space-between"
                                      >
                                        <Col lg={8}>
                                          <Form.Item
                                            style={{
                                              marginBottom: 5,
                                            }}
                                            {...restField}
                                            name={[sizeName, "size"]}
                                            label={sizeKey === 0 ? "Size" : false}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Missing first name",
                                              },
                                            ]}
                                          >
                                            <Select
                                              style={{
                                                minWidth: 100,
                                              }}
                                            >
                                              {/* {availableSizes?.map((category: any) => {
                                                return (
                                                  <Select.Option
                                                    value={category.value}
                                                    key={category?.name}
                                                  >
                                                    {category.name}
                                                  </Select.Option>
                                                );
                                              })} */}
                                              {availableSizes
                                                ?.filter(
                                                  (category: any) =>
                                                    !alreadySelectedSize.includes(category?.value)
                                                )
                                                ?.map((category: any) => {
                                                  return (
                                                    <Select.Option
                                                      value={category.value}
                                                      key={category?.name}
                                                    >
                                                      {category.name}
                                                    </Select.Option>
                                                  );
                                                })}
                                            </Select>
                                          </Form.Item>
                                        </Col>
                                        <Col lg={{ span: 6 }}>
                                          <Form.List
                                            initialValue={[{ currency: "Nrs", value: 4000 }]}
                                            name={[sizeName, "price"]}
                                          >
                                            {(priceFields, { add, remove }) => (
                                              <>
                                                {priceFields.map(
                                                  (
                                                    {
                                                      key: priceKey,
                                                      name: priceName,
                                                      ...priceRestField
                                                    },
                                                    index: number
                                                  ) => {
                                                    return (
                                                      <Row
                                                        gutter={[40, 40]}
                                                        style={{
                                                          display: "flex",
                                                          marginBottom: 8,
                                                        }}
                                                        align="top"
                                                        key={index}
                                                      >
                                                        <Form.Item
                                                          hidden
                                                          {...restField}
                                                          name={[priceName, "currency"]}
                                                          initialValue="Nrs"
                                                        >
                                                          <Input placeholder="" />
                                                        </Form.Item>
                                                        <Col lg={24}>
                                                          <Form.Item
                                                            style={{
                                                              marginBottom: 5,
                                                            }}
                                                            label={sizeKey === 0 ? "Price" : false}
                                                            name={[priceName, "value"]}
                                                          >
                                                            <InputNumber
                                                              style={{
                                                                width: "100%",
                                                              }}
                                                              placeholder="4000"
                                                            />
                                                          </Form.Item>
                                                        </Col>
                                                      </Row>
                                                    );
                                                  }
                                                )}
                                              </>
                                            )}
                                          </Form.List>
                                        </Col>
                                        <Col lg={{ span: 5 }}>
                                          <Form.Item label={sizeKey === 0 ? "Hardware" : false}>
                                            <Space>
                                              <Tooltip
                                                title={
                                                  sizeData?._id
                                                    ? " Manage Hardwares for this size"
                                                    : "Please update product before managing hardware"
                                                }
                                              >
                                                <Button
                                                  disabled={!sizeData?._id}
                                                  onClick={() =>
                                                    setHardwareSetup({
                                                      ...hardwareSetup,
                                                      productSpecificationId:
                                                        productSpecificationId,
                                                      sizeRangeId: sizeData?._id,
                                                      size: sizeData?.size,
                                                    })
                                                  }
                                                  icon={<SettingOutlined />}
                                                ></Button>
                                              </Tooltip>
                                              <Button
                                                disabled={!sizeData?._id}
                                                onClick={() =>
                                                  setViewHardware({
                                                    ...hardwareSetup,
                                                    productSpecificationId: productSpecificationId,
                                                    sizeRangeId: sizeData?._id,
                                                    size: sizeData?.size,
                                                  })
                                                }
                                                icon={<EyeFilled />}
                                              ></Button>
                                            </Space>
                                          </Form.Item>
                                        </Col>
                                        <Col lg={{ span: 2, offset: 1 }} style={{ paddingTop: 10 }}>
                                          {index === fields?.length - 1 && (
                                            <Space>
                                              <MinusCircleOutlined
                                                onClick={() => removeSize(sizeName)}
                                              />
                                              <PlusCircleOutlined
                                                onClick={() => addSize(sizeName)}
                                              />
                                            </Space>
                                          )}
                                        </Col>
                                      </Row>
                                    );
                                  }
                                )}
                                {fields.length === 0 ? (
                                  <PlusCircleOutlined onClick={() => addSize()} />
                                ) : null}
                              </>
                            )}
                          </Form.List>
                          <Row justify="space-between">
                            <Col span={24}>
                              <Divider />
                            </Col>
                            <Col style={{ marginTop: 40 }}>
                              <Button
                                loading={creating || updating}
                                type="primary"
                                // size='large'
                                shape="round"
                                htmlType="submit"
                              >
                                {update ? "UPDATE" : "UPDATE"}
                              </Button>
                            </Col>
                            <Col style={{ marginTop: 40 }}>
                              <Button
                                type="text"
                                shape="round"
                                style={{
                                  // borderColor: "#2E2E2E",
                                  fontWeight: "bold",
                                  // marginLeft: 20,
                                }}
                                danger
                                onClick={() => {
                                  const updatedLeatherList = leatherNames.filter(
                                    (item: string, index: number) => index !== name
                                  );
                                  setLeatherNames(updatedLeatherList);
                                  remove(name);
                                  setTimeout(() => {
                                    document.getElementById("submit_btn")?.click();
                                  }, 500);
                                }}
                                // icon={<MinusOutlined />}
                              >
                                REMOVE
                              </Button>
                              <Button
                                // disabled
                                type="text"
                                shape="round"
                                onClick={() => {
                                  // specificationForm.setFieldsValue({
                                  //   duplicate: true,
                                  // });
                                  // const existing =
                                  //   specificationForm?.getFieldsValue()?.product_specification ||
                                  //   [];
                                  // setDuplicatedValue(productSpecificationId);
                                  // specificationForm.setFieldsValue({
                                  //   product_specification: [...existing, existing[name]],
                                  // });
                                  // specificationForm.submit();
                                  setDuplicate({
                                    productId: productId,
                                    productSpecificationId: productSpecificationId,
                                  });
                                }}
                              >
                                <Tooltip title="Duplicate Leather Specifications">
                                  <CopyFilled />
                                </Tooltip>
                              </Button>
                              <Button size="large" type="text" hidden>
                                CANCEL
                              </Button>
                              <Button size="large" type="primary" hidden>
                                APPLY
                              </Button>
                            </Col>
                          </Row>
                        </Panel>
                      );
                    })}
                  </Collapse>
                  <Form.Item>
                    <Row justify="center">
                      <Col>
                        <Button
                          type="default"
                          shape="round"
                          style={{
                            borderColor: "#2E2E2E",
                            fontWeight: "bold",
                          }}
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          ADD NEW
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>

          {/* <Col lg={24}>
                        <Form.Item shouldUpdate>
                          <Button
                            loading={creating || updating}
                            type="primary"
                            size="large"
                            shape="round"
                            htmlType="submit"
                          >
                            {update ? "UPDATE" : "UPDATE"}
                          </Button>
                        </Form.Item>
                      </Col> */}
        </Row>
        <Row hidden>
          <Col>
            <Form.Item shouldUpdate>
              <Button
                loading={creating || updating}
                type="primary"
                id="submit_btn"
                // size='large'
                shape="round"
                htmlType="submit"
              >
                {update ? "UPDATE" : "UPDATE"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Modal
        onCancel={() => setMultipleImageModal(false)}
        visible={multipleImageModal}
        width="50vw"
        footer={false}
      >
        <Typography.Title
          level={4}
          style={{
            textTransform: "uppercase",
            color: "black",
            textAlign: "center",
          }}
        >
          ADD IMAGES
        </Typography.Title>
        <Divider />
        <Row justify="center">
          <Col lg={20}>
            <Typography.Paragraph>SELECT ONE DEFAULT IMAGE</Typography.Paragraph>
            <Row gutter={[40, 40]} style={{ margin: "40px 0px" }} align="stretch">
              {/* {specificationForm
                .getFieldsValue()
                ?.secondary_image?.map((image: any) => {
                  return <img src={image} alt="Alt" />;
                })} */}
              {secondaryImages?.map((image: any, index: number) => {
                return (
                  <Col lg={6} key={index}>
                    <div
                      // onClick={() => secondaryImages?.splice(index, 1)}
                      onClick={() =>
                        setSecondaryImages(secondaryImages?.filter((item: any) => item !== image))
                      }
                      style={{ position: "absolute", right: 0, top: -10 }}
                    >
                      <CloseCircleOutlined />
                    </div>
                    <img src={image} alt="Alt" style={{ width: "100%" }} />
                  </Col>
                );
              })}
              <Col flex={1}>
                <CommonS3UploadFiles
                  setIsImageUploading={setIsImageUploading}
                  style={{ width: "100%" }}
                  type="button-card"
                  module="product"
                  onUpload={(e) => {
                    setSecondaryImages([...secondaryImages, MediaUrl(e)]);
                    // if (
                    //   Array.isArray(
                    //     specificationForm.getFieldsValue().secondary_image
                    //   )
                    // ) {
                    //   specificationForm.setFieldsValue({
                    //     secondary_image: [
                    //       ...specificationForm.getFieldsValue().secondary_image,
                    //       e,
                    //     ],
                    //   });
                    // } else {
                    //   specificationForm.setFieldsValue({
                    //     secondary_image: [e],
                    //   });
                    // }
                  }}
                />
              </Col>
            </Row>

            <Row justify="end" align="middle" style={{ marginTop: 80 }}>
              <Col>
                <Space>
                  <Button onClick={() => setMultipleImageModal(false)}>CANCEL</Button>
                  <Button
                    disabled={isImageUploading}
                    type="primary"
                    onClick={() => {
                      const oldForm = specificationForm?.getFieldsValue().product_specification;
                      const newFormItem: any = oldForm[multipleImageModal - 1];
                      newFormItem.secondary_image = secondaryImages;
                      oldForm[multipleImageModal - 1] = newFormItem;
                      // const newForm = {
                      //   product_specification: specificationForm
                      //     ?.getFieldsValue()
                      //     .product_specification?.slice(
                      //       multipleImageModal - 1,
                      //       1,
                      //       {
                      //         ...specificationForm.getFieldsValue()
                      //           .product_specification[multipleImageModal - 1],
                      //         secondary_image: secondaryImages,
                      //       }
                      //     ),
                      // };
                      specificationForm.setFieldsValue({
                        product_specification: oldForm,
                      });
                      setMultipleImageModal(false);
                    }}
                  >
                    APPLY
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
      <Modal
        destroyOnClose
        width={1000}
        visible={!!hardwareSetup?.sizeRangeId}
        footer={false}
        onCancel={() => setHardwareSetup({})}
      >
        <ProductHardwareSetup
          {...hardwareSetup}
          hardware={hardware}
          onClose={() => setHardwareSetup({})}
        />
      </Modal>
      <Modal
        destroyOnClose
        width={1000}
        visible={!!viewHardware?.sizeRangeId}
        footer={false}
        onCancel={() => setViewHardware({})}
      >
        <ProductHardwareSetupView
          {...viewHardware}
          hardware={hardware}
          onClose={() => setViewHardware({})}
        />
      </Modal>
    </Form>
  );
};

export default ProductDetailsForm;