import { useParams } from 'react-router'
import ProductCategoryDetails from '../Details/Details/ProductCategoryDetails'
import ProductCategoryForm from '../Forms/Forms/ProductCategoryForm'

export const SingleProductCategory = (props: any) => {
  const { id, type } = useParams<any>()
  if (type === 'edit') {
    return <ProductCategoryForm update />
  } else if (id === 'add') {
    return <ProductCategoryForm />
  } else {
    return <ProductCategoryDetails />
  }
}

export default SingleProductCategory
