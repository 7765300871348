import { FC } from "react";
import { Anchor, Badge, Col, Image, Row, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import { itemAvailabilityData } from "../../../../Utils/consts";
import { ItemAvailability } from "../../../../Utils/enums";

import fallBackLogo from "../../../../Assets/Company/logo-dark.svg";

import "./MaterialItemBox.scss";
import { Link } from "react-router-dom";
import ImageHolder from "../../../ImageHolder/ImageHolder";

interface MaterialItemBoxProps {
  name: string;
  src?: string;
  quantity?: number;
  id: string;
  inventory_category: string;
  special_unit?: boolean;
  least_quantity?: number;
  high_quantity?: number;
  total_special_quantity?: number;
  unit?: string;
  lc?: string;
  sku?: string;
}

export const MaterialItemBox: FC<MaterialItemBoxProps> = ({
  src,
  name,
  quantity = 0,
  id,
  inventory_category,
  special_unit = false,
  least_quantity = 10,
  high_quantity = 0,
  total_special_quantity,
  unit,
  lc,
  sku,
}) => {
  const history = useNavigate();

  const findSpecialAvailability = () => {
    if (special_unit && total_special_quantity) {
      if (total_special_quantity <= 0) {
        return ItemAvailability.NOT_AVAILABLE;
      } else if (total_special_quantity < least_quantity) {
        return ItemAvailability.LIMITED;
      } else {
        return ItemAvailability.AVAILABLE;
      }
    }
  }

  const specialAvailability = findSpecialAvailability();

  const findAvailability = () => {
    if (quantity <= 0) {
      return ItemAvailability.NOT_AVAILABLE;
    }
    if (quantity < least_quantity) {
      return ItemAvailability.LIMITED;
    }
    return ItemAvailability.AVAILABLE;
  };

  const availability = findAvailability();

  const { text, color } = itemAvailabilityData[specialAvailability ? specialAvailability : availability];

  const handleClick = (id: string) => {
    history(`/inventory/item-details/${id}`, {
      state: {
        inventory_category,
        special_unit,
      },
    });
  };

  return (
    <>
      <div className="material_item_wrapper">
        <div
          onClick={() => handleClick(id)}
          // style={{ height: "100%" }}
        >
          <Badge.Ribbon
            text={text}
            className={`material_item_badge material_item_badge__color_${color}`}
          >
            <div
              style={{
                position: "relative",
                // height: "100%",
                backgroundColor: "#8fbded",
                minHeight: 260,
              }}
            >
              <ImageHolder
                src={src || fallBackLogo}
                alt={name}
                className="material_item_image"
                preview={true}
              />
              <div className="code">
                <span>LC: {lc} </span>
                <span>SKU: {sku} </span>
              </div>
            </div>
          </Badge.Ribbon>
          <Row>
            <Col span={24}>
              <Typography.Title level={4} className="material_item_name">
                {name}
                <span className={`material_item_quantity material_item_quantity__color_${color}`}>
                  <Row justify="space-between">
                    <Col>
                      <span>
                        {quantity} {unit}
                      </span>
                    </Col>
                    {total_special_quantity ? (
                      <Col>
                        <span>
                          {total_special_quantity ? Math.floor(total_special_quantity) : ""}
                          {special_unit ? special_unit : ""}
                        </span>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </span>
              </Typography.Title>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
