import { CreditCardOutlined, FlagOutlined, ShopTwoTone } from "@ant-design/icons";
import { Avatar, Card, Col, Descriptions, Empty, Popover, Row, Spin, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getExchangeRefundsDetails } from "../../../API/ExchangeRefundApi";
import { ReactComponent as KhaltiLogo } from "../../../Assets/Icons/KhaltiLogo.svg";
import { ReactComponent as NepalFlag } from "../../../Assets/Icons/NepalFlag.svg";
import { ExchangeRefundItemDetails } from "../../../Components/Admin/ExchangeRefundItem/ExchangeRefundItem";
import { ProfileSummary } from "../Users/UserProfile/UserProfileDetails";

interface RouteParams {
  orderId: any;
}

export const useForceUpdate = () => {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
};

export const Receipt = (props: any) => {
  const { data } = props;
  return (
    <Content style={{ width: "300px", background: "#E3EDF7" }}>
      <Descriptions labelStyle={{ width: "50%" }} column={1}>
        <Descriptions.Item label="COUNTRY">
          <Row align="middle">
            <NepalFlag />{" "}
            <Typography.Text style={{ fontSize: 18, marginLeft: 10 }}>NEP</Typography.Text>
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label="CARD TYPE">
          <KhaltiLogo />
        </Descriptions.Item>
        <Descriptions.Item label="CARD NUMBER">{data?.card || "N/A"}</Descriptions.Item>
      </Descriptions>
    </Content>
  );
};

const PopoverPayment = (props: any) => {
  const { data } = props;
  return (
    <Popover
      className="neumorphify"
      content={<Receipt data={data} />}
      trigger="click"
      placement="leftTop"
    >
      <Avatar
        style={{ background: "#E3EDF7", borderRadius: 15, cursor: "pointer" }}
        shape="square"
        className="neumorphify"
        size={36}
        icon={<CreditCardOutlined style={{ color: "black" }} />}
      />
    </Popover>
  );
};

export const ExchangeRefundDetails = (props: any) => {
  const { orderId } = useParams<any>();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<any>("");

  useEffect(() => {
    setLoading(true);
    getExchangeRefundsDetails("")
      .then((data: any) => {
        setDetails(data.details);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  }, [orderId]);
  return (
    <Content>
      <Typography.Title className="product-name">EXCHANGE</Typography.Title>

      {loading ? (
        <Row style={{ margin: 40 }} justify="center">
          <Spin tip="Getting Details" />
        </Row>
      ) : (
        <>
          {!loading && details ? (
            <Row gutter={[20, 20]} align="stretch">
              <Col lg={10}>
                <ProfileSummary
                  data={details?.customer}
                  noEdit
                  payment
                  popover={<PopoverPayment data={details.invoice} />}
                />
              </Col>
              <Col lg={14}>
                <ExchangeRefundItemDetails data={details?.orderDetails} />
              </Col>
            </Row>
          ) : (
            <Row style={{ margin: 40 }} justify="center">
              <Empty description="Details not available" />
            </Row>
          )}
        </>
      )}
    </Content>
  );
};

export default ExchangeRefundDetails;
