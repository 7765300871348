import {
  FETCH_DAMAGE_ITEMS,
  FETCH_DAMAGE_ITEMS_FAILURE,
  FETCH_DAMAGE_ITEMS_SUCCESS,
  FETCH_REPORT,
  FETCH_REPORTS,
  FETCH_REPORTS_FAILURE,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORT_FAILURE,
  FETCH_REPORT_SUCCESS,
} from './ReportTypes'



//fetch Reports
export const fetchReports = (page:number, limit:number) => ({
  type: FETCH_REPORTS,
  payload: { page, limit }
})

export const fetchReportsSuccess = (response: any) => ({
  type: FETCH_REPORTS_SUCCESS,
  payload: response,
})

export const fetchReportsFailure = (error: any) => ({
  type: FETCH_REPORTS_FAILURE,
  payload: error,
})



//fetch Reports
export const fetchReport = (id:string) => ({
  type: FETCH_REPORT,
  payload: id
})

export const fetchReportSuccess = (response: any) => ({
  type: FETCH_REPORT_SUCCESS,
  payload: response,
})

export const fetchReportFailure = (error: any) => ({
  type: FETCH_REPORT_FAILURE,
  payload: error,
})


//fetch damage items
export const fetchDamageItems = (page:number, limit:number) => ({
  type: FETCH_DAMAGE_ITEMS,
  payload: { page, limit }
})

export const fetchDamageItemsSuccess = (response: any) => ({
  type: FETCH_DAMAGE_ITEMS_SUCCESS,
  payload: response,
})

export const fetchDamageItemsFailure = (error: any) => ({
  type: FETCH_DAMAGE_ITEMS_FAILURE,
  payload: error,
})