import { EllipsisOutlined, EyeOutlined } from '@ant-design/icons'
import { Col, Image, Row, Typography } from 'antd'
import moment from 'moment'
import { useNavigate } from 'react-router'
import ImageHolder from '../../Components/ImageHolder/ImageHolder'
import { inventoryItemTrackDataType } from '../../Redux/InventoryItem/InventoryItemTypes'
import MediaUrl from '../../Utils/mediaUrl'
import textTruncate from '../../Utils/textTruncate'

type propsType = {
  data?: inventoryItemTrackDataType
}

export const SingleItems = ({ data }: propsType) => {
  const history = useNavigate()

  const handleAddItems = (data: any) => {
    history(`/inventory/reporting/${data?.id}`, {
      state: {
        data: data,
      },
    })
  }
  return (
    <Row className='singleItems' gutter={[30, 30]}>
      {/* col 1 */}
      <Col className='col' xl={2} xxl={2} lg={2} sm={6} xs={6}>
        <ImageHolder
          className='image'
          src={MediaUrl(data?.media?.[0])}
          alt='reportin'
        />
      </Col>
      <Col xl={5} xxl={5} lg={5} sm={8} xs={8} className='col '>
        <Typography.Title level={4}>
          {data?.inventory_item?.item_name}
        </Typography.Title>
        <p>{data?.inventory_item?.inventory_category?.inventory_category}</p>
      </Col>
      <Col xl={3} xxl={3} lg={3} sm={7} xs={7} className='col'>
        <p>Received Date</p>
        <Typography.Title level={5}>
          {moment(data?.received_date).format('MMM DD, YYYY')}
        </Typography.Title>
      </Col>
      <Col xl={6} xxl={6} lg={6} sm={8} xs={8} className='col '>
        <p>Vendor</p>
        <Typography.Title level={5}>
          {textTruncate(data?.vendor?.vendor_name, 25, '...')}
        </Typography.Title>
      </Col>
      <Col xl={3} xxl={3} lg={3} sm={7} xs={7} className='col '>
        <p>Quality</p>

        <h5 style={{ textTransform: 'capitalize', fontSize: 18 }}>
          {data?.quality}
        </h5>
      </Col>
      <Col xl={3} xxl={3} lg={3} sm={7} xs={7} className='col'>
        <p>Quantity</p>
        <h4>{data?.material_select?.total_quantity || 0}</h4>
      </Col>
      {/* col 7 */}
      <Col className='col' xl={2} lg={2} xxl={2} sm={2} xs={2}>
        <EyeOutlined onClick={() => handleAddItems(data)} />
      </Col>
    </Row>
  )
}
