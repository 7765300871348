import { Col, Row } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { LatidoAnimatedLogoLoader } from '../../LatidoAnimateLogo/LatidoAnimateLogo'
import Loading from '../Loading/Loading'
import './PageLoading.scss'
export const PageLoading = (props: any) => {
  const { loading, empty } = props
  return loading ? (
    <Row style={{ minHeight: 200 }} align='middle' justify='center'>
      <Col>
        <LatidoAnimatedLogoLoader />
      </Col>
    </Row>
  ) : (
    props.children
  )
}

export default PageLoading
