import { Card, Col, Row } from "antd";

interface propsType {
  data?: any;
}
const ShippingDetails = ({ data }: propsType) => {
  // console.log("ShippingDetails", data);
  return (
    <Card className="order-details__basicInfo white-border">
      <h2 className="page-title">Shipping Details</h2>

      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 50,
        }}
      >
        <Col className="col">
          <p>Name</p>
          <h3>{data?.full_name}</h3>
        </Col>
        <Col className="col">
          <p>Country</p>
          <h3>{data?.country}</h3>
        </Col>
        <Col className="col">
          <p>City</p>
          <h3>{data?.city}</h3>
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 50,
        }}
      >
        <Col className="col">
          <p>Contact No.</p>
          <h3>{data?.phone_number}</h3>
        </Col>
        <Col className="col">
          <p>Landmark</p>
          <h3>{data?.landmark}</h3>
        </Col>
        <Col className="col">
          <p>Address</p>
          <h3>{data?.address?.city || data?.address}</h3>
        </Col>
      </Row>
    </Card>
  );
};

export default ShippingDetails;
