import {
  GET_EXCHANGE_REFUNDS,
  GET_EXCHANGE_REFUNDS_SUCCESS,
  GET_EXCHANGE_REFUNDS_FAILURE,
} from '../Constants'

export const fetchExchangeRefund = (params: any) => ({
  type: GET_EXCHANGE_REFUNDS,
  payload: params,
})

export const fetchExchangeRefundSuccess = (response: any) => ({
  type: GET_EXCHANGE_REFUNDS_SUCCESS,
  payload: response,
})

export const fetchExchangeRefundFailure = (error: any) => ({
  type: GET_EXCHANGE_REFUNDS_FAILURE,
  payload: error,
})
