import { Col, Descriptions, Divider, Empty, Modal, Row, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ImageHolder from "../../Components/ImageHolder/ImageHolder";
import { CombineReducerType } from "../../Redux/Reducers/rootReducers";
import { VendorItemType } from "../../Redux/Vendor/VendorTypes";

const Table = () => {
  const { vendorItem } = useSelector((state: CombineReducerType) => state?.VendorReducer);

  const history = useNavigate();

  const [purchaseDetails, setPurchaseDetails] = useState<any>(false);

  return (
    <>
      <Modal
        visible={purchaseDetails}
        footer={false}
        onCancel={() => setPurchaseDetails(false)}
        title="Purchase Details"
      >
        <Descriptions column={2} layout="vertical" contentStyle={{ fontWeight: "bold" }}>
          <Descriptions.Item label="Item Name">
            {purchaseDetails?.inventory_item?.item_name}
          </Descriptions.Item>
          <Descriptions.Item label="Price">{purchaseDetails?.price}</Descriptions.Item>
          <Descriptions.Item label="Quality">{purchaseDetails?.quality}</Descriptions.Item>
          <Descriptions.Item label="Transport Mode">
            {purchaseDetails?.transport_mode}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Mode">
            {purchaseDetails?.payment_mode}
          </Descriptions.Item>
          <Descriptions.Item label="Received By">{purchaseDetails?.received_by}</Descriptions.Item>
        </Descriptions>
      </Modal>
      <div className="vendor-list">
        <Row className="title-group" gutter={[20, 20]} style={{ marginBottom: 40 }}>
          <Col className="title" lg={2} sm={2}>
            {" "}
          </Col>
          <Col className="title" lg={6} sm={6}>
            <h4>Name</h4>
          </Col>
          <Col className="title" lg={4} sm={4}>
            <h4>Quantity</h4>
          </Col>
          <Col className="title" lg={4} sm={4}>
            <h4>Price</h4>
          </Col>
          <Col className="title" lg={4} sm={4}>
            <h4>Ordered Date</h4>
          </Col>
          <Col className="title" lg={4} sm={4}>
            <h4>Received Date</h4>
          </Col>
        </Row>
        {vendorItem?.length > 0 ? (
          vendorItem?.map((item: VendorItemType, index) => {
            return (
              <Row
                gutter={[20, 20]}
                className="content-group"
                key={index}
                onClick={() => setPurchaseDetails(item)}
              >
                <Col lg={2} sm={2}>
                  <ImageHolder
                    className="image"
                    width="100%"
                    src={item?.media[0]}
                    alt={item?.order_id}
                  />
                </Col>
                <Col lg={6} sm={6}>
                  <Typography.Link
                  // onClick={() => history(`/inventory/item-details/${item?.inventory_item?._id}`)}
                  >
                    {item?.inventory_item?.item_name}
                  </Typography.Link>
                </Col>
                <Col lg={4} sm={4}>
                  <h4>{item?.material_select?.total_quantity}</h4>
                </Col>
                <Col lg={4} sm={4}>
                  <h4>{item?.price}</h4>
                </Col>
                <Col lg={4} sm={4}>
                  <h4>{moment(item?.ordered_date).format("DD-MM-YYYY")}</h4>
                </Col>
                <Col lg={4} sm={4}>
                  <h4>{moment(item?.received_date).format("DD-MM-YYYY")}</h4>
                </Col>
                <Divider
                  style={{
                    width: "100%",
                    boxShadow: "0px 1px 2px 1px white",
                    marginTop: -5,
                  }}
                />
              </Row>
            );
          })
        ) : (
          <p
            style={{
              textAlign: "center",
            }}
          >
            <Empty />
          </p>
        )}
        <Row gutter={[20, 20]} className="title-group">
          <Col className="center" xxl={2} lg={2} sm={2}>
            <div>
              {/* <Image
                src={vendor?.icon}
                alt='silver_zipper'
                className='vendor-image'
                preview={false}
              /> */}
            </div>
          </Col>
          <Col className="center" xxl={3} lg={3} sm={3}></Col>
        </Row>
      </div>
    </>
  );
};

export default Table;
