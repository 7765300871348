/** @format */
import img from '../Assets/Newsletter/newsletter.png'
import img1 from '../Assets/Newsletter/email.png'
import img2 from '../Assets/Newsletter/invoice.png'
import img3 from '../Assets/Newsletter/refund.png'
import img4 from '../Assets/Newsletter/special.png'

export const EmailTypesList = [
  {
    id: 1,
    img: img,
    title: 'NEWSLETTER',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    id: 2,
    img: img1,
    title: 'WELCOME EMAIL',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    id: 3,
    img: img2,
    title: 'INVOICE',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    id: 4,
    img: img3,
    title: 'EXCHANGE/REFUND REQUESTED INITIATED',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    id: 5,
    img: img4,
    title: 'ORDER RECEIPT',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    id: 6,
    img: img4,
    title: 'SPECIAL WISHES',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
]
