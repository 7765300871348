import { Reducer } from 'react'
import {
  FETCH_DASHBOARD,
  FETCH_DASHBOARD_FAILURE,
  FETCH_DASHBOARD_SUCCESS,
} from './DashboardTypes'


const INIT_STATE = {
  data: [],
  error: '',
  loading: false,
}

export const DashboardReducer: Reducer<any, any> = (
  state = INIT_STATE,
  action
) => {
  switch (action.type) {

  //fetch  Dashboard
  case FETCH_DASHBOARD:
    return { ...state, loading: true }
  case FETCH_DASHBOARD_SUCCESS:
    return { ...state, loading: false, data: action.payload }
  case FETCH_DASHBOARD_FAILURE:
    return { ...state, loading: false, error: action.payload }

  
  default:
    return state

  }
}
