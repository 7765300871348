import { useParams } from "react-router";
import ProductDetails from "../Details/Details/ProductDetails";
import ProductForm from "../Forms/Forms/ProductForm";

export const SingleProduct = (props: any) => {
  const { id, type } = useParams<any>();
  if (type === "edit") {
    return <ProductForm update />;
  } else if (id === "add") {
    return <ProductForm />;
  } else {
    return <ProductDetails />;
  }
};

export default SingleProduct;
