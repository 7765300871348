import './Loading.scss'

import { Col, Row, Spin } from 'antd'
import { LatidoAnimatedLogoLoader } from '../../LatidoAnimateLogo/LatidoAnimateLogo'

export const Loading = (props: any) => {
  const { fullScreen, title, tip } = props
  return (
    <Row
      className={fullScreen ? 'full--screen' : ''}
      justify='center'
      style={{ margin: '40px 0px', minHeight: '30vh', alignItems: 'center' }}
    >
      <Col>
        <LatidoAnimatedLogoLoader />
        {/* <Spin tip={title || tip || "Getting Data"} /> */}
      </Col>
    </Row>
  )
}

export default Loading
