/** @format */

import { Col, Row } from 'antd'
import { FC, useState } from 'react'
import { useParams } from 'react-router'
import PlacementForm from '../../../../Components/Admin/PlacementForm/PlacementForm'
import FeatureProductForm from '../FeatureproductForm/FeatureProductForm'

const FeatureProductMainForm: FC = () => {
  const [title, setTitle] = useState<string>()
  const [placementFormValues, setPlacementFormValues] = useState<any>()
  const [description, setDescription] = useState<string>('')
  const [initialFormState, setInitialFormState] =
    useState<string>('normal_form')
  const [formType, setFormType] = useState<string>('')
  const { slug } = useParams<any>()

  return (
    <Row>
      {initialFormState === 'normal_form' && (
        <Col span={24}>
          <FeatureProductForm
            placementFormValues={placementFormValues}
            title={title}
            description={description}
            onTitle={(e: string) => setTitle(e)}
            onDescription={(e: string) => setDescription(e)}
            onInitialFormState={(e: string) => setInitialFormState(e)}
          />
        </Col>
      )}
      {initialFormState === 'placement_form' && (
        <Col span={24}>
          <PlacementForm
            onPlacementFormValues={(e: {}) => setPlacementFormValues(e)}
            onInitialFormState={(e: string) => setInitialFormState(e)}
          />
        </Col>
      )}
    </Row>
  )
}

export default FeatureProductMainForm
