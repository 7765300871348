/** @format */

import {
  Card,
  Col,
  Dropdown,
  Image,
  Input,
  Pagination,
  Row,
  Select,
  Space,
  Typography,
} from 'antd'
import LatidoTable from '../../../Components/Admin/Table/Table'
import React, { useEffect, useState } from 'react'
import NeuMorph from '../../../Components/Admin/NeuMorph/NeuMorph'
import { EyeFilled, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router'

import { useDispatch, useSelector } from 'react-redux'
import { filterTypes } from '../Members/Members'
import { useDebounce } from 'use-debounce'
import { fetchCustomers } from '../../../Redux/CustomerRedux/CustomerActions'
import dayjs from 'dayjs'
export const Users = (props: any) => {
  const [filterParams, setFilterParams] = useState({})
  const [filteredItems, setFilteredItems] = useState([])
  const [filteredData, setFilteredData] = useState<filterTypes>({ page: 1 })
  const [dSearch, setDsearch] = useState()
  const [searchText] = useDebounce(dSearch, 500)

  const customers = useSelector((state: any) => state.CustomerReducer)

  const history = useNavigate()
  const dispatch = useDispatch()
  const ActionList = (props: any) => {
    const menu = (
      <NeuMorph>
        <Space
          className='content'
          style={{ padding: 10, alignItems: 'center', display: 'flex' }}
        >
          <EyeFilled
            onClick={() => history(`user-management/${props.userId}`)}
            style={{ fontSize: 18 }}
          />
          <EditOutlined
            onClick={() => history(`/user-management/${props.userId}`)}
            style={{ fontSize: 18 }}
          />
          <DeleteOutlined style={{ fontSize: 18 }} />
        </Space>
      </NeuMorph>
    )
    return (
      <Dropdown placement='topCenter' overlay={menu} trigger={['click']}>
        <Typography.Text
          strong
          style={{ fontSize: 18, fontWeight: 'bold', color: 'black' }}
        >
          ...
        </Typography.Text>
      </Dropdown>
    )
  }
  const columns = [
    {
      dataIndex: 'name',
      key: 'profileImg',
      render: (text: any, record: any) => (
        <NeuMorph>
          <Image preview={false} width='32px' src={record.profileImage} />
        </NeuMorph>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, row: any) => (
        <Typography.Link onClick={() => history(`/user-management/${row?.id}`)}>
          {text}
        </Typography.Link>
      ),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Contact',
      key: 'phone',
      dataIndex: 'phone',
    },
    {
      title: 'Email Address',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Joined Date',
      key: 'join_date',
      dataIndex: 'join_date',
      render: (text: any) => (
        <Typography.Text>{dayjs(text).format('LL')}</Typography.Text>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any): any => (
        <Space size='middle'>
          <ActionList userId={record.id} />
        </Space>
      ),
    },
  ]

  const setFilter = (value: any) => {}

  const fetchWithFilter = (data: any) => {
    dispatch(fetchCustomers({ ...data }))
  }

  // useEffect(() => {
  //   dispatch(fetchCustomers({ page: 1 }))
  // }, [])

  useEffect(() => {
    setFilteredData({ ...filteredData, search: searchText })
  }, [searchText])
  useEffect(() => {
    fetchWithFilter(filteredData)
  }, [filteredData])

  useEffect(() => {
    setFilteredItems(customers.customers)
  }, [customers.list])

  const onShowSizeChange = (current: number, size: number) => {
    fetchWithFilter({ limit: size, page: current })
  }
  const onPageChange = (page: number, pageSize?: number) => {
    fetchWithFilter({ limit: pageSize, page: page })
  }

  return (
    <>
      <Typography.Title>CUSTOMER LIST</Typography.Title>
      <Row align='middle' className='neumorphic'>
        <Col lg={17}>
          <Row justify='space-between' style={{ padding: '20px 0px' }}>
            {/* <Space>
              <label>Show</label>
            </Space>
            <Select
              showSearch
              style={{ width: 80 }}
              placeholder="14"
              optionFilterProp="children"
              onChange={(e: any) =>
                setFilteredData({ ...filteredData, limit: e })
              }
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="15">15</Option>
              <Option value="30">30</Option>
              <Option value="45">45</Option>
            </Select> */}
            {/* <Select
              showSearch
              style={{ width: 250 }}
              placeholder="Select a category"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={setFilter}
            >
              <Option value="jack">All Categories</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="tom">Tom</Option>
            </Select>
            <Select
              showSearch
              style={{ width: 250 }}
              placeholder="Select a category"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="jack">All Categories</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="tom">Tom</Option>
            </Select> */}
            <div>
              <Input.Search
                style={{ width: 250 }}
                placeholder='input search text'
                onChange={(e: any) => setDsearch(e.target.value)}
              />
            </div>
          </Row>
        </Col>
        <Col lg={7}>
          <Row style={{ padding: '20px 0px' }} justify='end'>
            <Card style={{ textAlign: 'center' }}>
              <Typography.Text>Total Users</Typography.Text>
              <Typography.Title level={2} className='product-name'>
                {customers.total_data}
              </Typography.Title>
            </Card>
          </Row>
        </Col>
      </Row>
      <Row justify='center'>
        <Col lg={24}>
          <LatidoTable
            pagination={false}
            loading={customers.loading}
            columns={columns}
            dataSource={customers.list}
          />
        </Col>
        <Col>
          {!customers.loading && (
            <Pagination
              showSizeChanger
              style={{ marginTop: 30 }}
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={customers.page_no}
              current={customers.page_no}
              total={customers.total_data}
              onChange={onPageChange}
              pageSize={filteredData?.limit || 10}
            />
          )}
        </Col>
      </Row>
    </>
  )
}
export default Users
