import {
  CreateBlogParams,
  CREATE_BLOG,
  CREATE_BLOG_FAILURE,
  CREATE_BLOG_SUCCESS,
  FETCH_BLOGS,
  FETCH_BLOGS_FAILURE,
  FETCH_BLOGS_SUCCESS,
  FETCH_BLOG_DETAILS,
  FETCH_BLOG_DETAILS_FAILURE,
  FETCH_BLOG_DETAILS_SUCCESS,
  REMOVE_BLOG,
  REMOVE_BLOG_FAILURE,
  REMOVE_BLOG_SUCCESS,
  UPDATE_BLOG,
  UPDATE_BLOG_FAILURE,
  UPDATE_BLOG_SUCCESS,
  BlogDetailsParams,
  BlogFetchParams,
} from "./BlogTypes";

//fetch Blog List
export const fetchBlogs = (params: BlogFetchParams) => ({
  type: FETCH_BLOGS,
  payload: params,
});

export const fetchBlogsSuccess = (response: any) => ({
  type: FETCH_BLOGS_SUCCESS,
  payload: response,
});

export const fetchBlogsFailure = (error: any) => ({
  type: FETCH_BLOGS_FAILURE,
  payload: error,
});

//fetch Blog Details
export const fetchBlog = (params: any) => ({
  type: FETCH_BLOG_DETAILS,
  payload: params,
});

export const fetchBlogSuccess = (response: any) => ({
  type: FETCH_BLOG_DETAILS_SUCCESS,
  payload: response,
});

export const fetchBlogFailure = (error: any) => ({
  type: FETCH_BLOG_DETAILS_FAILURE,
  payload: error,
});

//create Blog
export const createBlog = (params: CreateBlogParams) => ({
  type: CREATE_BLOG,
  payload: params,
});

export const createBlogSuccess = (response: CreateBlogParams) => ({
  type: CREATE_BLOG_SUCCESS,
  payload: response,
});
export const createBlogFailure = (error: any) => ({
  type: CREATE_BLOG_FAILURE,
  payload: error,
});

//update Blog
export const updateBlog = (params: CreateBlogParams) => ({
  type: UPDATE_BLOG,
  payload: params,
});

export const updateBlogSuccess = (response: CreateBlogParams) => ({
  type: UPDATE_BLOG_SUCCESS,
  payload: response,
});

export const updateBlogFailure = (error: any) => ({
  type: UPDATE_BLOG_FAILURE,
  payload: error,
});

//delete Blog
export const deleteBlog = (params: BlogDetailsParams) => ({
  type: REMOVE_BLOG,
  payload: params,
});
export const deleteBlogSuccess = (response: BlogDetailsParams) => ({
  type: REMOVE_BLOG_SUCCESS,
  payload: response,
});
export const deleteBlogFailure = (error: any) => ({
  type: REMOVE_BLOG_FAILURE,
  payload: error,
});
