import { Card, Col, Row, Tooltip, Typography } from "antd";
import { useCallback, useMemo, useState } from "react";
import {
  AppstoreOutlined,
  PlusOutlined,
  ReloadOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import SearchBar from "../../Components/SearchBar";
// import InputSelect from "../../Components/Select";
import { Tabs } from "antd";
import CustomerOrder from "./CustomerOrder";
import StoreOrder from "./StoreOrder";
import ConsumerOrder from "./ConsumerOrder";
import DeliveryFormModal from "./DeliveryFormModal";
import { useLocation, useNavigate } from "react-router-dom";
import TransferModal from "./TransferModal";
import { useQuery } from "react-query";
import { fetchOrders } from "../../API";
// import { LatidoAnimatedLogoLoader } from "../../Components/LatidoAnimateLogo/LatidoAnimateLogo";
import ReceiveFromStoreModal from "./ReceiveFromStoreModal";
import { useDebounce } from "use-debounce";

export const OrderList = () => {

  const navigate = useNavigate();
  const { search: searchParams } = useLocation();
  const searchParam = useMemo(()=>new URLSearchParams(searchParams), [searchParams]);

  const [isDeliverModalOpen, setIsDeliverModalOpen] = useState<boolean>(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState<boolean>(false);
  const [isReceiveFromStoreModalOpen, setIsReceiveFromStoreModalOpen] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [activeTab, setActiveTab] = useState(searchParam.get("tab") || 1);
  // const [listType, setListType] = useState(view || "list");
  const { TabPane } = Tabs;
  const [search, setSearch] = useState("");
  
  const [pagination, setPagination] = useState({
    current: Number(searchParam.get("page")) || 1,
    pageSize: Math.floor(Number(searchParam.get("pageSize")) / 10) || 1,
    listType: searchParam.get("view") || "list"
  });
  
  const [debounceSearch] = useDebounce(search, 1000);


  // fetching orders
  const { data, error, isFetching, refetch } = useQuery(
    ["refreshOrders"],

    //set default orderer as customer
    () => fetchOrders(1, 10, "customerOrder", search),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      staleTime: 5000,
    }
  );

  const handleClose = () => {
    refetch();
    setIsDeliverModalOpen(false);
    setIsTransferModalOpen(false);
    setIsReceiveFromStoreModalOpen(false);
    setCount(count + 1);
  };

  const handleClick = (route: string) => {
    navigate(route);
  };

  const handleRefresh = () => {
    refetch();
    setCount(count + 1);
  };

  const handleTabChange = useCallback((key: string) => {
    setActiveTab(key);
    setPagination(prevPagination => ({
      ...prevPagination,
      current: 1,
      listType: searchParam.get("view") || "list"
    }));
    navigate(`?tab=${key}&page=1&view=${searchParam.get("view") || "list"}`);
  }, [navigate, searchParam]);

  const handlePaginationChange = useCallback((page: number, pageSize: number, listType: string) => {
    setPagination({ current: page, pageSize: Math.floor(pageSize / 10), listType: listType || searchParam.get("view") || 'list' });
    navigate(`?tab=${activeTab}&page=${page}&view=${listType || searchParam.get("view") || 'list'}`);
  }, [navigate, activeTab, searchParam]);

  const handleViewChange = useCallback((type: string) => {
    handlePaginationChange(pagination.current, pagination.pageSize, type);
  }, [handlePaginationChange, pagination]);



  const [count, setCount] = useState(0);
  return (
    <>
      <Row style={{ width: "100%" }} className="order-list">
        <Col sm={24} lg={4} xl={8}>
          <Typography.Title
            className="product-name"
            style={{
              marginTop: "13px",
            }}
          >
            ORDER LISTS
          </Typography.Title>
        </Col>
        <Col sm={24} lg={20} xl={16}>
          <Row gutter={[20, 20]} justify="end">
            <Col sm={5} xs={12} lg={4} className="icon-card-container">
              <Tooltip placement="top" title="List">
                <Card
                  className={`icon-card white-border ${pagination.listType === "list" ? "selected" : ""}`}
                  onClick={() => handleViewChange("list")}
                >
                  <UnorderedListOutlined className={`icon`} />
                </Card>
              </Tooltip>
              <Tooltip placement="top" title="Grid">
                <Card
                  className={`icon-card white-border ${pagination.listType === "grid" ? "selected" : ""}`}
                  onClick={() => handleViewChange("grid")}
                >
                  <AppstoreOutlined className={`icon`} />
                </Card>
              </Tooltip>
            </Col>
            <Col sm={6} xs={16} lg={9}>
              <SearchBar value={search} setValue={setSearch} />
            </Col>
            <Col sm={3} xs={4} lg={2} className="center">
              <Tooltip placement="top" title="Create Option">
                <div className="add-icon" onClick={() => handleClick("/store/create/order/option")}>
                  <PlusOutlined />
                </div>
              </Tooltip>
            </Col>
            <Col sm={3} xs={4} lg={2} className="center">
              <Tooltip placement="top" title="Refresh">
                <div className="add-icon" onClick={() => handleRefresh()}>
                  <ReloadOutlined />
                </div>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ width: "100%" }}>
        <Tabs defaultActiveKey={activeTab.toString() || "1"} onChange={handleTabChange} style={{ width: "100%" }}>
          <TabPane tab="Customer Order" key="1" style={{ width: "100%" }}>
            <CustomerOrder
              listType={pagination.listType}
              setIsDeliverModalOpen={setIsDeliverModalOpen}
              setIsTransferModalOpen={setIsTransferModalOpen}
              setSelectedOrder={setSelectedOrder}
              setIsReceiveFromStoreModalOpen={setIsReceiveFromStoreModalOpen}
              search={debounceSearch}
              count={count}
              pagination={pagination}
              handlePaginationChange={handlePaginationChange}
            />
          </TabPane>

          <TabPane tab="Consumer Order" active={activeTab === "2" ? true : false} key="2">
            <ConsumerOrder
              listType={pagination.listType}
              setIsDeliverModalOpen={setIsDeliverModalOpen}
              setIsTransferModalOpen={setIsTransferModalOpen}
              setSelectedOrder={setSelectedOrder}
              setIsReceiveFromStoreModalOpen={setIsReceiveFromStoreModalOpen}
              search={debounceSearch}
              count={count}
              pagination={pagination}
              handlePaginationChange={handlePaginationChange}
            />
          </TabPane>

          <TabPane tab="Store Order" active={activeTab === "3" ? true : false} key="3">
            <StoreOrder
              listType={pagination.listType}
              setIsDeliverModalOpen={setIsDeliverModalOpen}
              setIsTransferModalOpen={setIsTransferModalOpen}
              setIsReceiveFromStoreModalOpen={setIsReceiveFromStoreModalOpen}
              setSelectedOrder={setSelectedOrder}
              search={debounceSearch}
              count={count}
              pagination={pagination}
              handlePaginationChange={handlePaginationChange}
            />
          </TabPane>
        </Tabs>
      </Row>
      <ReceiveFromStoreModal
        visible={isReceiveFromStoreModalOpen}
        handleClose={(e) => {
          if (e) {
            refetch();
          }
          handleClose();
        }}
        order={selectedOrder}
      />
      <DeliveryFormModal
        visible={isDeliverModalOpen}
        handleClose={handleClose}
        order={selectedOrder}
      />
      <TransferModal
        visible={isTransferModalOpen}
        handleClose={handleClose}
        order={selectedOrder}
        refetch={refetch}
      />
    </>
  );
};
