import { Col, Empty, Row } from "antd";
import MemberCard from "../../../Components/Admin/MemberCard/MemberCard";
import QueueAnim from "rc-queue-anim";

export const MembersList = (props: any) => {
  const { data, onEdit, filter } = props;
  return (
    <>
      <QueueAnim
        component={Row}
        componentProps={{
          gutter: [20, 20],
          style: { marginTop: 40, minHeight: "80vh" },
          align: "stretch",
        }}
        type="bottom"
      >
        {data &&
          data.length > 0 &&
          data.map((d: any, i: number) => {
            return (
              <Col lg={6} md={12} sm={12} xs={24} key={i}>
                <MemberCard onEdit={onEdit} data={d} list={data} filter={filter} />
              </Col>
            );
          })}
      </QueueAnim>

      <Row justify="center">{data && data.length === 0 && <Empty />}</Row>
    </>
  );
};

export default MembersList;
