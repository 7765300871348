// completion_process: {
//     type: String,
//     default: "in_cart",
//     enum: ["in_cart", "ordered", "assigned", "completed", "in_store", "delivered", "dispatched"],
//   },
export enum CompletionProcess {
  IN_CART = "in_cart",
  ORDERED = "ordered",
  ASSIGNED = "assigned",
  COMPLETED = "completed",
  IN_STORE = "in_store",
  DELIVERED = "delivered",
  DISPATCHED = "dispatched",
}

// order_manage_process: {
//     type: String,
//     required: true,
//     enum: ["pending", "assigned", "dispatched", "completed"],
//     default: "pending",
//   },
export enum OrderManageProcess {
  PENDING = "pending",
  ASSIGNED = "assigned",
  DISPATCHED = "dispatched",
  COMPLETED = "completed",
}

export enum PaymentStatus {
  PAYMENT_COMPLETE = "payment_complete",
  PAYMENT_INCOMPLETE = "incomplete",
}

export const PaymentOptions = [
  "cash",
  "card",
  "esewa",
  "khalti",
  "bank_transfer",
  "paypal",
  "other",
];

// transfer_state: { type: String, enum: ["customer_store", "consumer_store", "store_customer"] },
export enum TransferState {
  CUSTOMER_STORE = "customer_store", // transfer of customer order to store
  CONSUMER_STORE = "consumer_store", // transfer of consumer(PWA) order to store
  STORE_CUSTOMER = "store_customer", // transfer of of store order to customer
}

// origin: { type: String, enum: ["store", "customer", "pwa"] },
export enum Origin {
  STORE = "store",
  PWA = "pwa",
  CUSTOMER = "customer",
}
