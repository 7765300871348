import {
	CreateProductParams,
	CREATE_PRODUCT,
	CREATE_PRODUCT_FAILURE,
	CREATE_PRODUCT_SUCCESS,
	FETCH_PRODUCTS,
	FETCH_PRODUCTS_FAILURE,
	FETCH_PRODUCTS_SUCCESS,
	FETCH_PRODUCT,
	FETCH_PRODUCT_FAILURE,
	FETCH_PRODUCT_SUCCESS,
	REMOVE_PRODUCT,
	REMOVE_PRODUCT_FAILURE,
	REMOVE_PRODUCT_SUCCESS,
	UPDATE_PRODUCT,
	UPDATE_PRODUCT_FAILURE,
	UPDATE_PRODUCT_SUCCESS,
	ProductDetailsParams,
	ProductFetchParams
} from './ProductTypes';

//fetch Product List
export const fetchProducts = (params: ProductFetchParams) => ({
	type: FETCH_PRODUCTS,
	payload: params
});

export const fetchProductsSuccess = (response: any) => ({
	type: FETCH_PRODUCTS_SUCCESS,
	payload: response
});

export const fetchProductsFailure = (error: any) => ({
	type: FETCH_PRODUCTS_FAILURE,
	payload: error
});

//fetch Product Details
export const fetchProduct = (params: ProductDetailsParams) => ({
	type: FETCH_PRODUCT,
	payload: params
});

export const fetchProductSuccess = (response: any) => ({
	type: FETCH_PRODUCT_SUCCESS,
	payload: response
});

export const fetchProductFailure = (error: any) => ({
	type: FETCH_PRODUCT_FAILURE,
	payload: error
});

//create Product
export const createProduct = (params: CreateProductParams) => ({
	type: CREATE_PRODUCT,
	payload: params
});

export const createProductSuccess = (response: CreateProductParams) => ({
	type: CREATE_PRODUCT_SUCCESS,
	payload: response
});
export const createProductFailure = (error: any) => ({
	type: CREATE_PRODUCT_FAILURE,
	payload: error
});

//update Product
export const updateProduct = (params: CreateProductParams) => ({
	type: UPDATE_PRODUCT,
	payload: params
});

export const updateProductSuccess = (response: CreateProductParams) => ({
	type: UPDATE_PRODUCT_SUCCESS,
	payload: response
});

export const updateProductFailure = (error: any) => ({
	type: UPDATE_PRODUCT_FAILURE,
	payload: error
});

//delete Product
export const deleteProduct = (params: ProductDetailsParams) => ({
	type: REMOVE_PRODUCT,
	payload: params
});
export const deleteProductSuccess = (response: ProductDetailsParams) => ({
	type: REMOVE_PRODUCT_SUCCESS,
	payload: response
});
export const deleteProductFailure = (error: any) => ({
	type: REMOVE_PRODUCT_FAILURE,
	payload: error
});
