import {
  GET_LEATHER_ADD_ON,
  GET_LEATHER_INVENTORY,
  GET_LEATHER_INVENTORY_FAILURE,
  GET_LEATHER_INVENTORY_SUCCESS,
  POST_LEATHER_ADD_ON,
  POST_LEATHER_ADD_ON_FAILURE,
  POST_LEATHER_ADD_ON_SUCCESS,
} from './../Constants/constants'
/** @format */

import { Reducer } from 'redux'
import {
  GET_LEATHER_ADD_ON_FAILURE,
  GET_LEATHER_ADD_ON_SUCCESS,
  GET_LEATHER_PROFILE,
  GET_LEATHER_PROFILE_FAILURE,
  GET_LEATHER_PROFILE_SUCCESS,
  POST_LEATHER_PROFILE,
  POST_LEATHER_PROFILE_FAILURE,
  POST_LEATHER_PROFILE_SUCCESS,
} from '../Constants/constants'

const INIT_STATE = {
  LeatherProfileList: {},
  LeatherInventory: [],
  LeatherAddOn: [],
  PostLeatherProfileResponse: false,
  error: false,
  loading: true,
  animals: [],
  finish: [],
  creating: false,
  created: false,
}

export const LeatherProfileReducer: Reducer<any, any> = (
  state = INIT_STATE,
  action: any
) => {
  switch (action.type) {
    //GET LEATHER PROFILE DATA
    case GET_LEATHER_PROFILE:
      return { ...state, loading: true }
    case GET_LEATHER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        LeatherProfileList: action.payload.data,
      }
    case GET_LEATHER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    //POST LEATHER PROFILE DATA
    case POST_LEATHER_PROFILE:
      return {
        ...state,
        creating: true,
        created: false,
      }
    case POST_LEATHER_PROFILE_SUCCESS:
      return {
        ...state,
        creating: false,
        created: true,
      }
    case POST_LEATHER_PROFILE_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.payload.error,
      }

    //LEATHER Add On
    case GET_LEATHER_ADD_ON:
      return {
        ...state,
        loading: true,
      }
    case GET_LEATHER_ADD_ON_SUCCESS:
      return {
        ...state,
        loading: false,
        LeatherAddOn: action.payload.data,
      }
    case GET_LEATHER_ADD_ON_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case POST_LEATHER_ADD_ON:
      return {
        ...state,
        loading: true,
      }
    case POST_LEATHER_ADD_ON_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case POST_LEATHER_ADD_ON_FAILURE:
      return {
        ...state,
        loading: false,
      }

    //LEATHER Inventory
    case GET_LEATHER_INVENTORY:
      return {
        ...state,
        loading: true,
      }
    case GET_LEATHER_INVENTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        LeatherInventory: action.payload.data,
      }
    case GET_LEATHER_INVENTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    default:
      return { ...state }
  }
}
