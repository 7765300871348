import { notification } from "antd";
import { AxiosError, AxiosResponse } from "axios";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { setLayout } from "../Actions/LayoutActions";
import {
  createMemberFailure,
  createMemberSuccess,
  deleteMemberFailure,
  deleteMemberSuccess,
  fetchMemberFailure,
  fetchMembers,
  fetchMembersFailure,
  fetchMembersSuccess,
  fetchMemberSuccess,
  updateMemberFailure,
  updateMemberSuccess,
} from "./MemberActions";
import { cutMember, makeMember, modifyMember, takeMember, takeMembers } from "./MemberApis";
import {
  CreateMemberParams,
  FETCH_MEMBERS,
  UPDATE_MEMBER,
  CREATE_MEMBER,
  REMOVE_MEMBER,
  MemberDetailsParams,
  MemberFetchParams,
  FETCH_MEMBER_DETAILS,
} from "./MemberTypes";

//get members
const getMembersAsync = async (data: MemberFetchParams) => await takeMembers(data);

function* getMembers(params: any) {
  const { payload } = params;
  try {
    const members: AxiosResponse = yield call(getMembersAsync, payload);
    if (members) {
      yield put(fetchMembersSuccess(members?.data));
    } else {
      yield put(fetchMembersFailure("Could not get list of members"));
      notification.error({
        message: "Could not get list of members",
        placement: "bottom",
      });
    }
  } catch (error) {
    yield put(fetchMembersFailure("Network Error while getting Members"));
    notification.error({
      message: "Network Error while getting Members",
      placement: "bottom",
    });
  }
}

function* watchGetMembers() {
  yield takeLatest(FETCH_MEMBERS, getMembers);
}

//get member details
const getMemberAsync = async (data: MemberDetailsParams) => await takeMember(data);

function* getMember(params: any) {
  const { payload } = params;
  try {
    const members: AxiosResponse = yield call(getMemberAsync, payload);
    if (members) {
      yield put(fetchMemberSuccess(members.data));
    } else {
      yield put(fetchMemberFailure("Could not get list of members"));
      notification.error({
        message: "Could not get list of members",
        placement: "bottom",
      });
    }
  } catch (error) {
    yield put(fetchMemberFailure("Network Error while getting Members"));
    notification.error({
      message: "Network Error while getting Members",
      placement: "bottom",
    });
  }
}

function* watchGetMember() {
  yield takeLatest(FETCH_MEMBER_DETAILS, getMember);
}

//create Member
const generateMemberAsync = async (data: CreateMemberParams) => await makeMember(data);

function* generateMember(params: any) {
  const { payload } = params;
  try {
    const members: AxiosResponse = yield call(generateMemberAsync, payload);
    if (members) {
      yield put(createMemberSuccess(members.data));
      notification.success({
        message: "Member created successfully",
        placement: "bottom",
      });
      yield put(
        setLayout({
          created: true,
        })
      );
    } else {
      const message = "Could not create Member";
      yield put(createMemberFailure(message));
      notification.error({
        message: message,
        placement: "bottom",
      });
    }
  } catch (error: any) {
    const message = "Network Error while creating Member";
    yield put(createMemberFailure(message));
    notification.error({
      message: error?.response.data.message || message,
      placement: "bottom",
    });
  }
}

function* watchGenerateMember() {
  yield takeLatest(CREATE_MEMBER, generateMember);
}

//update Member
const amendMemberAsync = async (data: any) => await modifyMember(data);

function* amendMember(params: any) {
  const { payload } = params;
  try {
    const members: AxiosResponse = yield call(amendMemberAsync, payload);
    if (members) {
      yield put(updateMemberSuccess(members.data));
      yield put(fetchMembers({ page: 1, limit: 10 }));
      notification.success({
        message: "Member updated successfully",
        placement: "bottom",
      });
      yield put(
        setLayout({
          updated: true,
        })
      );
    } else {
      const message = "Could not Update Member";
      yield put(updateMemberFailure(message));
      notification.error({
        message: message,
        placement: "bottom",
      });
    }
  } catch (error) {
    const errors = error as AxiosError;
    const response = errors?.response;
    const data = response?.data;

    const message = data?.message ?? "Network Error while updating Member";
    yield put(updateMemberFailure(message));
    notification.error({
      message: message,
      placement: "bottom",
    });
  }
}

function* watchAmendMember() {
  yield takeLatest(UPDATE_MEMBER, amendMember);
}

//Delete Member
const removeMemberAsync = async (data: MemberDetailsParams) => await cutMember(data);

function* removeMember(params: any) {
  const { payload } = params;
  try {
    const members: AxiosResponse = yield call(removeMemberAsync, payload);
    if (members) {
      yield put(deleteMemberSuccess(members.data));
      notification.success({
        message: "Member deleted successfully",
        placement: "bottom",
      });
    } else {
      const message = "Could not delete Member";
      yield put(deleteMemberFailure(message));
      notification.error({
        message: message,
        placement: "bottom",
      });
    }
  } catch (error) {
    const message = "Network Error while deleting Member";
    yield put(deleteMemberFailure(message));
    notification.error({
      message: message,
      placement: "bottom",
    });
  }
}

function* watchRemoveMember() {
  yield takeLatest(REMOVE_MEMBER, removeMember);
}

export default function* MemberSagas() {
  yield all([
    fork(watchGetMembers),
    fork(watchGetMember),
    fork(watchGenerateMember),
    fork(watchAmendMember),
    fork(watchRemoveMember),
  ]);
}
