export interface ChunkPropsTypes{
    data?: any[];
    size: number;
}

export const ArrayChunk = (props: ChunkPropsTypes) =>{
    const {data=[], size} = props;
    const chunkedArray = Array.from({ length: Math.ceil(data.length / size) }, (v, i) =>data.slice(i * size, i * size + size));
    return chunkedArray;
}

export default ArrayChunk;
        