import { DeleteOutlined, FormOutlined, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Select, Space, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { CombineReducerType } from "../../Redux/Reducers/rootReducers";
import { deleteVendor, fetchVendors } from "../../Redux/Vendor/VendorActions";
import ImageHolder from "../../Components/ImageHolder/ImageHolder";
import MediaUrl from "../../Utils/mediaUrl";
import { LatidoAnimatedLogoLoader } from "../../Components/LatidoAnimateLogo/LatidoAnimateLogo";

export const VendorsList = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { vendors, loading } = useSelector((state: CombineReducerType) => state?.VendorReducer);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState<string>("1");

  useEffect(() => {
    dispatch(fetchVendors(page, 10, searchText, sortBy));
  }, [page, searchText, sortBy]);

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleEditIconClick = (id: string) => {
    history(`/inventory/vendor/edit/${id}`);
  };

  const handleChange = (value: string) => {
    setSortBy(value);
  };
  const handleDeleteIconClick = (id: string) => {
    Modal.confirm({
      title: "Are you sure?",
      icon: <DeleteOutlined />,
      content: <p>You cannot undo once you delete the vendor.</p>,
      onOk() {
        handleDelete(id);
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };
  const handleDelete = (id: string) => {
    dispatch(deleteVendor(id));
  };

  const vendorColumns = [
    {
      // title: "Name",
      dataIndex: "icon",
      key: "icon",
      width: 100,
      render: (text: any, record: any) => (
        <ImageHolder
          style={{ width: "150px", objectFit: "cover" }}
          src={MediaUrl(text)}
          height="80px"
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      render: (text: any, record: any) => (
        <Typography.Link onClick={() => history(`/inventory/vendor/${record?._id}`)}>
          <Space direction="vertical">
            <Typography.Title level={5}>{text}</Typography.Title>
            <Typography.Paragraph type="secondary">{record?.description}</Typography.Paragraph>
          </Space>
        </Typography.Link>
      ),
    },
    {
      title: "Number",
      dataIndex: "phone_no",
      key: "phone_no",
    },
    {
      title: "Types",
      dataIndex: "supplies_type",
      key: "supplies_type",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text: any, record: any) => (
        <Typography.Link href={`mailto:${text}`}>{text}</Typography.Link>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (text: any, record: any) => (
        <Space>
          <FormOutlined className="action-icon" onClick={() => handleEditIconClick(text)} />{" "}
          <DeleteOutlined className="action-icon" onClick={() => handleDeleteIconClick(text)} />
        </Space>
      ),
    },
  ];
  return (
    <>
      <Form layout="horizontal">
        <Row style={{ margin: "10px 0" }} justify="space-between" gutter={[20, 0]}>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Space align="center">
              <Typography.Title
                className="product-name"
                style={{
                  // marginTop: "13px",
                  marginBottom: 0,
                }}
              >
                VENDORS
              </Typography.Title>
              <Button
                type="primary"
                size="middle"
                shape="round"
                onClick={() => history("/inventory/vendor/add")}
              >
                <span style={{ fontWeight: 100 }}>
                  Add Vendors <PlusOutlined />
                </span>
              </Button>
            </Space>
          </Col>
          <Col>
            <Row gutter={20}>
              <Col>
                <Form.Item label="Sort By">
                  <Select defaultValue="Ascending" style={{ width: 120 }} onChange={handleChange}>
                    <Select.Option value="1">Ascending </Select.Option>
                    <Select.Option value="-1">Descending </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="">
                  <Input.Search
                    placeholder="Search vendor"
                    size="middle"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Table
        loading={{
          indicator: <LatidoAnimatedLogoLoader />,
          spinning: loading,
        }}
        columns={vendorColumns}
        dataSource={vendors?.data || []}
        pagination={{
          position: ["bottomCenter"],
          current: page,
          total: vendors?.total_data,
          onChange: (e) => handlePageChange(e),
          showTotal: (total, range) => (
            <Typography.Text>
              {range[0]} - {range[1]} of <b>{total}</b>
            </Typography.Text>
          ),
        }}
      />
    </>
  );
};
