import {
  CheckCircleFilled,
  CloseCircleFilled,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import Loading from "../../../../Components/Admin/Loading/Loading";
import { useEffect, useState } from "react";
import { defaultFunction } from "../../../../Redux/Constants/constants";
import {
  addProductSizeHardware,
  getNonLeather,
  getProductSizeHardware,
} from "../../../../Redux/ProductRedux/ProductApis";
import { getCreateSize, getFormSetData } from "../../../../Utils/ProductSizeHardware";

export interface ProductHardwareSetupProps {
  hardware?: string[];
  sizeRangeId?: string;
  productId?: string;
  productSpecificationId?: string;
  size?: number;
  onClose?: (e: any) => void;
}

const { Panel } = Collapse;

export const ProductHardwareSetupView = ({
  hardware,
  sizeRangeId,
  productId,
  size,
  productSpecificationId,
  onClose = defaultFunction,
}: ProductHardwareSetupProps) => {
  const [form] = useForm();
  form.setFieldsValue({
    hardware: hardware?.map((val) => {
      return {
        hardware: val,
        productId: productId,
        productSpecificationId: productSpecificationId,
        sizeRangeId: sizeRangeId,
        size: size,
      };
    }),
  });

  const [dropdownData, setDropdownData] = useState<any>([]);

  const getItems = async () => {
    await getNonLeather({ query: false })
      .then((items) => {
        setDropdownData(items?.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getItems();
  }, [0]);

  const [loading, setLoading] = useState(false);

  const addSizeItem = async (values: any) => {
    setLoading(true);
    const items = getCreateSize(values);
    for await (const item of items) {
      await addProductSizeHardware(item)
        ?.then((data) => {
          onClose({});
        })
        .catch((error) => {});
    }
    setLoading(false);
  };

  const [getting, setGetting] = useState(false);

  const [hardwareData, setHardwareData] = useState<any>(false);

  const getInitialData = async () => {
    setGetting(true);
    await getProductSizeHardware({ id: sizeRangeId || "" })
      .then((data) => {
        setGetting(false);

        setTimeout(() => {
          if (data?.data?.length > 0) {
            setHardwareData(data?.data);
            form.setFieldsValue(getFormSetData(data?.data));
          } else {
            form.setFieldsValue({
              hardware: hardware?.map((val) => {
                return {
                  hardware: val,
                  productId: productId,
                  productSpecificationId: productSpecificationId,
                  sizeRangeId: sizeRangeId,
                  size: size,
                };
              }),
            });
          }
        }, 0);
      })
      .catch((error) => {});
    setGetting(false);
  };

  useEffect(() => {
    getInitialData();
  }, [sizeRangeId]);
  console.log(dropdownData);
  return (
    <div>
      <Typography.Title style={{ textTransform: "uppercase" }} level={4}>
        HARDWARE DETAILS
      </Typography.Title>
      <Divider />
      {(getting || loading) && <Loading />}
      <Row gutter={[20, 50]} style={{ marginTop: 20 }}>
        {!getting && (!hardwareData || hardwareData?.length === 0) && (
          <Col lg={24}>
            <Empty description={"No hardware available"} />
          </Col>
        )}
        {!getting &&
          !loading &&
          hardwareData &&
          hardwareData?.map((hd: any) => {
            return (
              <Col span={24}>
                {/* <Card> */}
                <Typography.Title style={{ textTransform: "uppercase" }} level={5}>
                  {hd?.hardware}
                </Typography.Title>
                {/* <Divider /> */}
                <Table
                  columns={[
                    {
                      title: "Category",
                      dataIndex: "categoryId",
                      render: (text: any) =>
                        dropdownData?.find((dd: any) => dd?._id === text)?.inventory_category,
                    },
                    {
                      title: "Item Name",
                      dataIndex: "itemName",
                    },
                    {
                      title: "Quantity",
                      dataIndex: "quantity",
                    },
                    {
                      title: "Editable",
                      dataIndex: "isEditable",
                      render: (text: any) =>
                        text ? (
                          <CheckCircleFilled style={{ color: "green", fontSize: 24 }} />
                        ) : (
                          <CloseCircleFilled style={{ color: "red", fontSize: 24 }} />
                        ),
                    },
                  ]}
                  dataSource={hd?.inventory}
                  pagination={false}
                  showHeader
                  className="min-height-0"
                />
                {/* </Card> */}
              </Col>
            );
          })}
      </Row>
    </div>
  );
};
