import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import App from "./Container/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { configureAmplify } from "./Config/aws-config";
import { Provider } from "react-redux";
import localizedFormat from "dayjs/plugin/localizedFormat";
// import { ConnectedRouter } from "connected-react-router";
import { configureStore } from "./Redux/AppStore";
import dayjs from "dayjs";
const queryClient = new QueryClient();

configureAmplify();
dayjs.extend(localizedFormat);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={configureStore()}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
