import { Button, Image, Row } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const OrderOption = () => {
  const navigate = useNavigate()

  const handleRedirect = (route: string) => {
    navigate(route)
  }
  return (
    <div className='order-option'>
      <h1 className='page-title'>Create Option</h1>
      <div className='order-option__content'>
        <Row>
          <p>Select Option :</p>
        </Row>
        <Row>
          <Button
            className='order-option__button_1 btn'
            onClick={() => handleRedirect('/store/create/order/contact')}
          >
            For Customer
          </Button>
        </Row>

        <Row>
          <Button
            onClick={() => handleRedirect('/store/create/order/store')}
            className='order-option__button_2 btn'
          >
            For Store
          </Button>
        </Row>
      </div>
    </div>
  )
}
