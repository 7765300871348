/** @format */

import {
  DeleteOutlined,
  EditOutlined,
  EyeFilled,
  PlusOutlined,
} from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd'
import NeuMorph from '../../../../Components/Admin/NeuMorph/NeuMorph'
import LatidoTable from '../../../../Components/Admin/Table/Table'
import { useEffect, useState } from 'react'
import './Newsletter.scss'
import { Option } from 'antd/lib/mentions'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { GetNewsletterTableContents } from '../../../../Redux/Actions/GridAction'

const ActionList = (props: any) => {
  return (
    <NeuMorph>
      <Space
        className='content'
        style={{ padding: 10, alignItems: 'center', display: 'flex' }}
      >
        <EyeFilled style={{ fontSize: 18 }} />
        <EditOutlined style={{ fontSize: 18 }} />
        <DeleteOutlined style={{ fontSize: 18 }} />
      </Space>
    </NeuMorph>
  )
}

const FooterButtons = () => {
  return (
    <Row gutter={[20, 20]}>
      <Col>
        <Select defaultValue='Bulk Actions'>
          <Option value='select 1'>1</Option>
          <Option value='select 2'>2</Option>
          <Option value='select 3'>3</Option>
        </Select>
      </Col>
      <Col>
        <Button>Apply</Button>
      </Col>
    </Row>
  )
}
const Newsletter = ({ history }: any) => {
  const [title] = useState<string>('newsletter')
  const dispatch = useDispatch()

  const gridReducer = useSelector((state: RootStateOrAny) => state.gridReducer)
  const { newsLetterList, loading } = gridReducer

  useEffect(() => {
    dispatch(GetNewsletterTableContents(title))
  }, [])

  const handleCheckboxChange = (e: any) => {}

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <Checkbox onChange={handleCheckboxChange} />,
    },
    {
      title: 'title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'date',
      key: 'date',
      dataIndex: 'date',
    },
    {
      title: 'status',
      key: 'status',
      dataIndex: 'status',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any): any => (
        <Space size='middle'>
          <ActionList userId={record.id} />
        </Space>
      ),
    },
  ]
  return (
    <>
      <Row justify='space-between' align='middle'>
        <Col sm={24} md={16} lg={16}>
          <Space>
            <Typography.Title
              className='product-name'
              style={{ textTransform: 'uppercase' }}
            >
              NEWSLETTER
            </Typography.Title>
            {/*no plan to add sizing atm*/}
            <Button
              onClick={() => history.push('/grid/types-of-email')}
              type='primary'
              size='middle'
              shape='round'
            >
              <span style={{ fontWeight: 100 }}>
                Add New <PlusOutlined />
              </span>
            </Button>
          </Space>
        </Col>
        <Col span={8}>
          <Row gutter={[10, 0]}>
            <Col span={14}>
              <Input.Search placeholder='Search' />
            </Col>
            <Col span={10}>
              <Input placeholder='Sort by: Date' />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify='start' align='middle' style={{ marginTop: 20 }}>
        <Col span={8} className='newsletter-statusbar'>
          <Typography.Text>
            All: {newsLetterList[0]?.newsLetterList?.length}
          </Typography.Text>
          <Typography.Text>
            Scheduled: {newsLetterList[0]?.newsLetterList?.length}
          </Typography.Text>
          <Typography.Text>Draft: 1</Typography.Text>
          <Typography.Text>Sent: 1</Typography.Text>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col span={24}>
          <LatidoTable
            footer={() => <FooterButtons />}
            loading={loading}
            columns={columns}
            dataSource={newsLetterList[0]?.newsLetterList}
          />
        </Col>
      </Row>
    </>
  )
}
export default Newsletter
