import { PaperClipOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Typography, Upload } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React from 'react'
import GridBuilder from '../GridBuilder/GridBuilder'
import './BlogImages.scss'

export const BlogImages = (props: any) => {
  const handleFileUplode = (value: any) => {
    props.onChange(value)
  }
  return (
    <Content className='image-block'>
      <Row align='middle' gutter={[20, 20]}>
        <Col lg={12}>
          <Typography.Text>Upload Image</Typography.Text>
        </Col>
        <Col lg={24}>
          <GridBuilder
            upload
            onChange={handleFileUplode}
            data={props?.layout}
          />
        </Col>
      </Row>
    </Content>
  )
}

export default BlogImages
