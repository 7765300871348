import { Button, Card, Col, Form, Input, Row, Select, Space, Spin, Typography, Upload } from "antd";
import DatePicker from "antd/lib/date-picker";
import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import { CombineReducerType } from "../../Redux/Reducers/rootReducers";
import { VendorType } from "../../Redux/Vendor/VendorTypes";
import { fetchVendors } from "../../Redux/Vendor/VendorActions";
import {
  createInventoryItemTrack,
  fetchInventoryItem,
} from "../../Redux/InventoryItem/InventoryItemActions";
import { categoryUnits } from "../../Utils/consts";
import CommonS3UploadFiles from "../../Components/S3UploadFiles/CommonS3UploadFiles";
import ImageHolder from "../../Components/ImageHolder/ImageHolder";
import MediaUrl from "../../Utils/mediaUrl";
import qs from "query-string";

type stateType = {
  vendor: string;
  inventory_category: string;
  inventory_item: string;
  price: string;
  received_date: string;
  quality: string;
  transport_mode: string;
  payment_mode: string;
  received_by: string;
  bill: string;
  order_id?: string;
  media: Array<string>;
  material_select: any;
};
const initialState: stateType = {
  vendor: "",
  inventory_category: "",
  inventory_item: "",
  price: "",
  received_date: "",
  quality: "",
  transport_mode: "",
  payment_mode: "",
  media: [],
  bill: "https://images.pexels.com/photos/4386370/pexels-photo-4386370.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  received_by: "",
  material_select: {
    total_quantity: "",
    total_unit: "",
  },
};
export const AddInventory = () => {
  const { TabPane } = Tabs;
  const { Option } = Select;
  const [activeTab, setActiveTab] = useState("1");
  const { loading, vendors } = useSelector((state: CombineReducerType) => state?.VendorReducer);
  const { creating, inventoryItem } = useSelector(
    (state: CombineReducerType) => state?.InventoryItemReducer
  );
  const dispatch = useDispatch();
  const [formState, setFormState] = useState(initialState);
  const [vendorsOption, setVendorsOption] = useState<Array<any>>([]);
  const history: any = useNavigate();
  const { state }: any = useLocation();
  const [location, setLocation] = useState("");
  const [special_unit, setSpecialUnit] = useState<undefined | boolean>(false);
  const loca = useLocation();
  const id: any = qs.parse(loca?.search)?.inventory_item;
  useEffect(() => {
    if (!loading) {
      const data: Array<any> = vendors?.data?.map((vendor: VendorType) => ({
        value: vendor?._id,
        name: vendor?.vendor_name,
        location: vendor?.location,
      }));
      setVendorsOption(data);
    }
  }, [loading]);

  useEffect(() => {
    dispatch(fetchVendors(1, 100, "", "1"));
  }, []);
  useEffect(() => {
    dispatch(fetchInventoryItem(id));
  }, [id]);

  // set input field value in state
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormState((prevState: any) => ({ ...prevState, [name]: value }));
  };

  const handleTabChange = (e: any) => {
    console.log(e);
    setActiveTab(e);
  };

  const handleSelectOptionChange = (e: any) => {
    const filter = vendors?.data?.filter((vendor: VendorType) => vendor?._id === e);
    setFormState({ ...formState, vendor: e });
    setLocation(filter[0]?.location);
  };

  const onDateChange = (value: any, dateString: any) => {
    setFormState({ ...formState, received_date: dateString });
  };

  const onDatePickerDone = (value: any) => {};

  const [defaultUnit, setDefaultUnit] = useState<any>();

  useEffect(() => {
    if (!inventoryItem) {
      // history(-1);
    } else {
      const img: string = inventoryItem?.icon;

      setFormState({
        ...formState,
        inventory_category: inventoryItem?.inventory_category?._id,
        inventory_item: inventoryItem?._id,
        media: [img],
        material_select: {
          total_unit:
            inventoryItem?.inventory_category?.material_select?.total_unit ||
            inventoryItem?.inventory_category?.material_select?.unit,
          special_unit: inventoryItem?.inventory_category?.material_select?.special_unit,
        },
      });
      // setSpecialUnit(state?.special_unit);
      setDefaultUnit({
        total_unit:
          inventoryItem?.inventory_category?.material_select?.total_unit ||
          inventoryItem?.inventory_category?.material_select?.unit,
        special_unit: inventoryItem?.inventory_category?.material_select?.special_unit,
      });
      setSpecialUnit(inventoryItem?.inventory_category?.special_unit);
    }
  }, [inventoryItem]);

  const handleRemoveImage = (url: string) => {
    const tempImage = formState?.media;
    const filteredImagges = tempImage?.filter((image: string) => image !== url);
    setFormState({ ...formState, media: filteredImagges });
  };

  const handleFormSubmit = (e: any) => {
    let data = { ...formState };
    e.preventDefault();
    if (special_unit) {
      data = {
        ...data,
        material_select: { ...data.material_select, total_unit: "piece" },
      };
    }
    dispatch(
      createInventoryItemTrack({
        ...data,
        order_id: moment().format("YYYYMMDDHHmmss"),
      })
    );
  };

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }
  return (
    <>
      <Row style={{ margin: "30px 0" }} gutter={[20, 0]}>
        <Col
          span={15}
          style={{
            alignItems: "center",
          }}
        >
          {/* <h1 className="page-title">Add Inventory</h1> */}
          <Typography.Title
            className="product-name"
            style={{
              // marginTop: "13px",
              marginBottom: 0,
            }}
          >
            ADD INVENTORY
          </Typography.Title>

          <h5
            style={{
              // fontSize: 22,
              color: "#828282",
              wordSpacing: 5,
            }}
          >
            <Typography.Link onClick={() => history("/inventory/raw-materials")}>
              {`Raw Materials >`}{" "}
            </Typography.Link>
            <Typography.Link
              onClick={() => history(`/inventory/item-details/${inventoryItem?._id}`)}
            >
              {`${inventoryItem?.inventory_category?.inventory_category} > `}
            </Typography.Link>
            <Typography.Link
              onClick={() => history(`/inventory/item-details/${inventoryItem?._id}`)}
            >
              {`${inventoryItem?.item_name} > `}
            </Typography.Link>
            <Typography.Text type="secondary"> Add Inventory</Typography.Text>
          </h5>
        </Col>
      </Row>
      <Row>
        <Tabs
          activeKey={activeTab}
          onChange={(e) => handleTabChange(e)}
          size="middle"
          style={{ width: "100%" }}
        >
          <TabPane tab="Inventory Details" key="1">
            <Card style={{ width: "100%", padding: "30px 45px" }}>
              <Form layout="vertical">
                <Row gutter={[50, 30]}>
                  <Col span={12} lg={12} xxl={12} sm={24} xs={24}>
                    <Form.Item label="Vendor Name">
                      <Select
                        value={formState?.vendor}
                        onChange={handleSelectOptionChange}
                        placeholder="Vendor Name"
                        size="middle"
                      >
                        {vendorsOption?.map((vendor: any) => (
                          <Option value={vendor?.value} key={vendor?.value}>
                            {vendor?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12} xxl={12} lg={12} sm={24} xs={24}>
                    <Form.Item label="Latido Location">
                      <Input
                        type="text"
                        size="middle"
                        name="item_name"
                        value={location}
                        required={true}
                        autoFocus={true}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xxl={12} lg={12} sm={24} xs={24}>
                    <Form.Item label="Price">
                      <Input
                        min={0}
                        type="number"
                        size="middle"
                        name="price"
                        value={formState?.price}
                        required={true}
                        autoFocus={true}
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xxl={12} lg={12} sm={24} xs={24}>
                    <Form.Item label="Date">
                      <Space direction="vertical" size={12} style={{ zIndex: 100, width: "100%" }}>
                        <DatePicker
                          disabledDate={disabledDate}
                          onChange={onDateChange}
                          onOk={onDatePickerDone}
                          style={{ zIndex: 100, width: "100%" }}
                          size="middle"
                        />
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[50, 30]}>
                  <Col xxl={4} sm={18} xs={24} lg={4}>
                    <Form.Item label="Unit">
                      <Select
                        options={categoryUnits}
                        value={formState?.material_select?.total_unit}
                        allowClear={true}
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            material_select: {
                              ...formState?.material_select,
                              total_unit: e,
                            },
                          })
                        }
                        disabled={defaultUnit?.total_unit}
                        placeholder="Unit"
                        size="middle"
                      />
                    </Form.Item>
                  </Col>
                  <Col xxl={8} sm={18} xs={24} lg={8}>
                    <Form.Item label="Quantity">
                      <Input
                        type="number"
                        size="middle"
                        name="item_name"
                        value={formState?.material_select?.total_quantity}
                        required={true}
                        autoFocus={true}
                        min={0}
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            material_select: {
                              ...formState?.material_select,
                              total_quantity: e?.target?.value,
                              total_special_quantity: 0,
                            },
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xxl={12} sm={24} xs={24} lg={12}>
                    <Form.Item label="Quality">
                      <Select
                        value={formState?.quality}
                        onChange={(e) => setFormState({ ...formState, quality: e })}
                        placeholder="quality"
                        size="middle"
                      >
                        <Option value="excellent">Excellent</Option>
                        <Option value="good">Good</Option>
                        <Option value="bad">Bad</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                {inventoryItem?.inventory_category?.special_unit && (
                  <Row gutter={[50, 30]}>
                    <Col xxl={4} sm={18} xs={24} lg={4}>
                      <Form.Item label="Special Unit">
                        <Input type="text" size="middle" name="quality" value={"DCM"} disabled />
                      </Form.Item>
                    </Col>
                    <Col xxl={8} sm={18} xs={24} lg={8}>
                      <Form.Item label="Special Quantity">
                        <Input
                          type="number"
                          min={0}
                          size="middle"
                          name="item_name"
                          value={formState?.material_select?.total_special_quantity}
                          required={true}
                          autoFocus={true}
                          onChange={(e) =>
                            setFormState({
                              ...formState,
                              material_select: {
                                ...formState?.material_select,
                                total_special_quantity: e?.target?.value,
                              },
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row gutter={[50, 30]}>
                  <Col span={12} xxl={12} sm={24} xs={24} lg={12}>
                    <Form.Item label="Transportation Mode">
                      <Select
                        value={formState?.transport_mode}
                        onChange={(e) => setFormState({ ...formState, transport_mode: e })}
                        placeholder="Transportation Mode"
                        size="middle"
                      >
                        <Option value="By Truck">By Truck</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12} xxl={12} sm={24} xs={24} lg={12}>
                    <Form.Item label="Payment Mode">
                      <Select
                        value={formState?.payment_mode}
                        onChange={(e) => setFormState({ ...formState, payment_mode: e })}
                        placeholder="Payment Mode"
                        size="middle"
                      >
                        <Option value="By Cheque">By Cheque</Option>
                        <Option value="By Cash">By Cash</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[50, 30]}>
                  <Col span={12} xxl={12} sm={24} xs={24} lg={12}>
                    <Form.Item label="Received By">
                      <Input
                        type="text"
                        size="middle"
                        name="received_by"
                        value={formState?.received_by}
                        required={true}
                        autoFocus={true}
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xxl={12} sm={24} xs={24} lg={12}>
                    <Form.Item label="Attach Bill">
                      <CommonS3UploadFiles
                        module="item"
                        onUpload={(e) =>
                          setFormState({
                            ...formState,
                            bill: e,
                          })
                        }
                        prefix="inventory/items"
                        type="product"
                        title="Drag or drop the images / icons"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="end">
                  <Button onClick={() => history(-1)} size="middle">
                    Cancel
                  </Button>
                  <Button
                    size="middle"
                    type="primary"
                    style={{ marginLeft: 20 }}
                    onClick={() => setActiveTab("2")}
                  >
                    Next
                  </Button>
                </Row>
              </Form>
            </Card>
          </TabPane>

          <TabPane tab="Add Images" key="2">
            <Card style={{ width: "100%", padding: "30px 45px" }}>
              <Row style={{ width: "100%" }} gutter={[30, 30]} align="middle">
                {formState?.media?.map((image: any, index: number) => (
                  <Col
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <ImageHolder
                        src={MediaUrl(image)}
                        alt="image"
                        style={{
                          height: "200px",
                          objectFit: "contain",
                          boxShadow: "0.1px 0.1px 10px 2px rgba(0, 0, 0, 0.2)",
                        }}
                      />
                    </div>
                    <div style={{ marginTop: 20 }}>
                      {index === 0 ? (
                        <p> Default Image</p>
                      ) : (
                        <p style={{ cursor: "pointer" }} onClick={() => handleRemoveImage(image)}>
                          Remove
                        </p>
                      )}
                    </div>
                  </Col>
                ))}
                <Col xxl={4} lg={6} xl={4} sm={24} xs={24} style={{ height: 100 }}>
                  <CommonS3UploadFiles
                    module="item"
                    onUpload={(e) =>
                      setFormState({
                        ...formState,
                        media: [...formState?.media, e],
                      })
                    }
                    prefix="inventory/category"
                    type="multiple"
                    title="Attach More Image"
                  />
                </Col>
              </Row>
              <Row justify="end">
                <Button disabled={creating} size="middle" onClick={() => setActiveTab("1")}>
                  Previous
                </Button>
                <Button
                  size="middle"
                  type="primary"
                  loading={creating}
                  style={{ marginLeft: 20 }}
                  onClick={(e) => handleFormSubmit(e)}
                >
                  Submit
                </Button>
              </Row>
            </Card>
          </TabPane>
        </Tabs>
      </Row>
    </>
  );
};
