import { API, Auth } from 'aws-amplify'
import { ApiHeaders, INVENTORY_API_NAME } from '../../API/variable'
// import { INVENTORY_API_NAME, ApiHeaders } from 'Api/Api'

//get Reports
export const takeReports = async (data: any) => {
  const { limit, page, searchText } = data
  const res = await API.get(
    INVENTORY_API_NAME,
    `/vendor/vendor_list?page=${page}&limit=${limit}&search=${searchText}`,
    {
      headers: ApiHeaders,
    }
  )
  return res
}

//get Report
export const takeReport = async (id: string) => {
  const res = await API.get(
    INVENTORY_API_NAME,
    `/vendor/vendor_detail?vendor_id=${id}`,
    {
      headers: ApiHeaders,
    }
  )

  return res?.vendor
}

//get Damage Items
export const takeDamageItems = async (data: any) => {
  const { limit, page } = data
  const res = await API.get(
    INVENTORY_API_NAME,
    `/damage_report/damage_report_list?page=${page}&limit=${limit}`,
    {
      headers: ApiHeaders,
    }
  )
  return res
}
