import { Reducer } from 'react'
import {
  REQUEST_LOGIN,
  REQUEST_LOGIN_FAILURE,
  REQUEST_LOGIN_SUCCESS,
  SET_AUTH_USER,
  SET_CURRENT_USER,
} from './AuthTypes'

const INIT_STATE = {
  list: [],
  error: '',
  detailsError: '',
  details: {},
  loading: false,
  loaded: false,
  detailing: false,
  detailed: false,
  creating: false,
  created: false,
  deleting: false,
  deleted: false,
  updating: false,
  updated: false,
  total: 0,
  page: 1,
  atuhUser: false,
  user: {},
}

export const AuthReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
  switch (action.type) {
    //requestLogin
    case REQUEST_LOGIN:
      return { ...state, logging: true }
    case REQUEST_LOGIN_SUCCESS:
      return { ...state, logging: false, loginState: action.payload }
    case REQUEST_LOGIN_FAILURE:
      return { ...state, error: action.payload, logging: false }
    case SET_AUTH_USER:
      return { ...state, error: false, authUser: action.payload }
    case SET_CURRENT_USER:
      return { ...state, error: false, user: action.payload }
    default:
      return { ...state }
  }
}
