import { notification } from "antd";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { history } from "Redux/AppStore";
import {
  createVendorFailure,
  createVendorSuccess,
  deleteVendorFailure,
  deleteVendorSuccess,
  fetchVendorFailure,
  fetchVendorSuccess,
  fetchVendors,
  fetchVendorsFailure,
  fetchVendorsSuccess,
} from "./VendorActions";

import { cutVendor, makeVendor, takeVendor, takeVendors } from "./VendorApis";

import {
  CREATE_VENDOR,
  FETCH_VENDOR,
  FETCH_VENDORS,
  REMOVE_VENDOR,
  VendorType,
  VendorsRes,
} from "./VendorTypes";

//get Vendors
const getVendorsAsync = async (data: any) => {
  const res = await takeVendors(data);
  return res;
};
function* getVendors(params: any) {
  const { payload } = params;
  try {
    const vendors: VendorsRes = yield call(getVendorsAsync, payload);
    if (vendors) {
      yield put(fetchVendorsSuccess(vendors));
    } else {
      yield put(fetchVendorsFailure("Could not get Vendors"));
      notification.error({
        message: "Could not get  Vendors",
        placement: "bottom",
      });
    }
  } catch (error: any) {
    yield put(fetchVendorsFailure(error?.message));
    notification.error({
      message: error?.message,
      placement: "bottom",
    });
  }
}

function* watchGetVendors() {
  yield takeLatest(FETCH_VENDORS, getVendors);
}

//get Vendor
const getVendorAsync = async (data: any) => {
  const res = await takeVendor(data);
  return res;
};
function* getVendor(params: any) {
  const { payload } = params;
  try {
    const vendor: VendorType = yield call(getVendorAsync, payload);
    if (vendor) {
      yield put(fetchVendorSuccess(vendor));
    } else {
      yield put(fetchVendorFailure("Could not get Vendor"));
      notification.error({
        message: "Could not get Vendor",
        placement: "bottom",
      });
    }
  } catch (error: any) {
    yield put(fetchVendorFailure(error?.message));
    notification.error({
      message: error?.message,
      placement: "bottom",
    });
  }
}

function* watchGetVendor() {
  yield takeLatest(FETCH_VENDOR, getVendor);
}

//create Vendor
const generateVendorAsync = async (data: any) => {
  const res = await makeVendor(data);
  return res;
};
function* generateVendor(params: any) {
  const { payload } = params;
  try {
    const response: Array<any> = yield call(generateVendorAsync, payload);
    if (response) {
      yield put(createVendorSuccess(response));
      // history.push('/vendors/')
      const type = payload?._id ? "Updated" : "Created";
      notification.success({
        message: `Vendor ${type} successfully`,
        placement: "bottom",
      });
    } else {
      yield put(createVendorFailure("Could not create vendor"));
      notification.error({
        message: "Could not create vendor",
        placement: "bottom",
      });
    }
  } catch (error: any) {
    yield put(createVendorFailure("Network Error while creating  inventory item"));
    notification.error({
      message: error?.response?.data?.message,
      placement: "bottom",
    });
  }
}

function* watchGenerateVendor() {
  yield takeLatest(CREATE_VENDOR, generateVendor);
}

//Delete Post

const removeVendorAsync = async (id: string) => {
  const res = await cutVendor(id);
  return res;
};
function* removeVendor(params: any) {
  const { payload } = params;
  try {
    const res: string = yield call(removeVendorAsync, payload);

    if (res) {
      yield put(deleteVendorSuccess(res));
      yield put(fetchVendors(1, 10, "", "1"));
      notification.success({
        message: "Vendor deleted successfully",
        placement: "bottom",
      });
    } else {
      const message = "Could not delete Vendor";
      yield put(deleteVendorFailure(message));
      notification.error({
        message: message,
        placement: "bottom",
      });
    }
  } catch (error) {
    const message = "Network Error while deleting Vendor";
    yield put(deleteVendorFailure(message));
    notification.error({
      message: message,
      placement: "bottom",
    });
  }
}

function* watchRemovePost() {
  yield takeLatest(REMOVE_VENDOR, removeVendor);
}

export default function* VendorSagas() {
  yield all([
    fork(watchGetVendors),
    fork(watchGenerateVendor),
    fork(watchGetVendor),
    fork(watchRemovePost),
  ]);
}
