import axios from 'axios'
import { environment } from '../Config/environment'

export const ApiName = 'fakeApi'
export const DEV_API_NAME = 'devApi'
export const PROD_API_NAME = 'stagingApi'

export const DEV_BASE_URL =
  'https://kugi4q6dba.execute-api.ap-south-1.amazonaws.com/test'
export const PROD_BASE_URL =
  'https://taklk2jv9j.execute-api.ap-south-1.amazonaws.com/staging'

export const API_NAME = 'api'
export const INVENTORY_API_NAME = 'inventory-api'
export const BASE_URL = environment.baseUrl

export const ApiHeaders = {
  'Content-Type': 'application/json',
}

export const API = axios.create({
  baseURL: BASE_URL,
  timeout: 1000,
  headers: ApiHeaders,
})
