import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Image,
  Input,
  message,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchProducts, getOpt } from "../../API";
import { useCreateOrder } from "../../API/customHooks";
import InputSelect from "../../Components/Select";
import Moment from "moment";
import SuccessModel from "./SuccessModel";
import { ExclamationOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { availableSizes } from "../Admin/Forms/Forms/ProductDetailsForm";
import { disableBackDateFromToday } from "../../Utils";
import moment from "moment";
import { Origin } from "Views/Orders/OrdersEnum";

const { Panel } = Collapse;

const selectOptions = [
  {
    value: false,
    title: "No",
  },
  {
    value: true,
    title: "Yes",
  },
];

const bodyTypeOption = [
  {
    value: "FIT",
    title: "Fit",
  },
  {
    value: "SKIN_TIGHT",
    title: "Skin Tight",
  },
  {
    value: "GENERAL_FIT",
    title: "General Fit",
  },
  {
    value: "COMFORT",
    title: "Comfort",
  },
  {
    value: "LAYERED",
    title: "Layered",
  },
];

const LabelComponent = ({ children }: { children: any }) => (
  <div style={{ width: "clamp(150px, 10vw, 220px)" }}>{children}</div>
);

export const StoreOrder = () => {
  const [form] = Form.useForm();
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [selectedLeather, setSelectedLeather] = useState<any>({});
  const [daysLeft, setDaysLeft] = useState<number | undefined>();
  const productInfoItem = [
    "name",
    "leather_profile",
    "leather_size",
    "hardware",
    "lining",
    "polyfill",
    "rib",
  ];
  const bodyMeasurementItem = [
    "base_size",
    "body_type",
    "length",
    "chest",
    "waist",
    "hips",
    "shoulder",
    "sleeves",
    "arms",
    "weight",
    "front",
    "armhole",
  ];

  const priceItem = ["total_amount", "discount"];
  const [visible, setVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [leather, setLeather] = useState([]);
  const [hardware, setHardware] = useState([]);
  const [lining, setLining] = useState([]);
  const [size, setSize] = useState([]);
  const { state }: any = useLocation();
  const [errorSection, setErrorSection] = useState({
    productInfo: false,
    bodyMeasurement: false,
    remarks: false,
    price: false,
  });

  const [sizeRangeId, setSizeRangeId] = useState(state?.product_specification?.size_range_id || "");
  const [productId, setProductId] = useState(state?.product?._id || "");

  const { mutate: createOrder, isLoading, status, isError, error }: any = useCreateOrder();

  // fetching products
  const { data, isFetching } = useQuery(["products"], () => fetchProducts(), {
    refetchOnWindowFocus: false,
  });

  // handle close modal
  const handleClose = () => {
    setVisible(false);
    setOtpVisible(false);
  };

  const handleDateChange = (value: any) => {
    const utcDateValue = value?.utc();
    setDaysLeft(utcDateValue?.startOf("day").diff(moment().startOf("day"), "days"));
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (state?._id) {
      console.log("state: ", state);
      form.setFieldsValue({
        armhole: state?.sizing?.armhole || 0,
        arms: state?.sizing?.arms || 0,
        body_type: state?.sizing?.body_type || 0,
        base_size: state?.sizing?.body_type || 0,
        chest: state?.sizing?.chest || 0,
        height: state?.sizing?.height || 0,
        hips: state?.sizing?.hips || 0,
        shoulder: state?.sizing?.shoulder || 0,
        length: state?.sizing?.length || 0,
        sleeves: state?.sizing?.sleeves || 0,
        waist: state?.sizing?.waist || 0,
        weight: state?.sizing?.weight || 0,
        remarks: state?.special_request || "",
        discount: state?.total_discount || 0,
        total_amount: state?.total_amount || 0,
        name: state?.product?.name || "",
        hardware: state?.hardware,
        lining: state?.lining,
        leather_profile: state?.product_specification?.leather_id?.item_name,
        leather_size: state?.product_specification?.size,
        pollyfill: state?.pollyfill,
        rib: state?.rib,
      });
      const filtredProduct = data?.filter((product: any) => product._id === state?.product?._id);

      setSelectedProduct(filtredProduct?.[0]);

      const filtredLeather = filtredProduct?.[0]?.product_specification?.filter(
        (item: any) => item?.leather_id?._id === state?.product_specification?.leather_id?._id
      );
      setSelectedLeather(filtredLeather?.[0]);
    }
  }, [state, data]);

  // checking if form submit get success
  useEffect(() => {
    if (status === "success") {
      setVisible(true);
    }
    if (status === "error") {
      notification.error({
        message: error?.message,
        placement: "bottom",
      });
    }
  }, [status]);

  // getting size obtion from selected leather
  useEffect(() => {
    if (selectedLeather) {
      const sizeOption = selectedLeather?.size_range?.map((item: any) => {
        return { value: item.size, title: item.size };
      });
      setSize(sizeOption);
    }
  }, [selectedLeather]);

  // form submit error
  const handleFormSubmitError = (e: any) => {
    const isError = {
      productInfo: false,
      bodyMeasurement: false,
      remarks: false,
      price: false,
    };
    e?.errorFields?.map((field: any) => {
      if (productInfoItem.includes(field?.name[0])) {
        isError.productInfo = true;
      }
      if (bodyMeasurementItem.includes(field?.name[0])) {
        isError.bodyMeasurement = true;
      }
      if (priceItem.includes(field?.name[0])) {
        isError.price = true;
      }
      if (field?.name[0] === "remarks") {
        isError.remarks = true;
      }
    });
    setErrorSection(isError);
    window.scrollTo(0, 0);
  };

  // handling form submit
  const handleFormSubmit = async () => {
    setErrorSection({
      productInfo: false,
      bodyMeasurement: false,
      remarks: false,
      price: false,
    });
    const formData = form?.getFieldsValue();

    // leather_size
    const filtredSize = selectedLeather?.size_range?.filter(
      (item: any) => item?.size === formData?.leather_size
    );

    const data: any = {
      product_specification: {
        price: {
          currency: filtredSize[0]?.price[0]?.currency,
          value: filtredSize[0]?.price[0]?.value,
        },
        size_range_id: sizeRangeId,
        status: selectedLeather?.status,
        secondary_image: [...selectedLeather?.secondary_image],
        product_specification_id: selectedLeather?._id,
        leather_id: selectedLeather?.leather_id?._id,
        size: filtredSize[0]?.size,
      },
      pollyfill: formData?.pollyfill,
      rib: formData?.rib,
      delivery_date: Moment(formData?.delivery_date).format(),
      gift: false,
      product: productId,
      hardware: formData?.hardware,
      lining: formData?.lining,
      special_request: formData?.remarks,
      otp: otpCode,
      sizing: {
        front: parseFloat(formData?.front),
        armHole: parseFloat(formData?.armhole),
        shoulder: parseFloat(formData?.shoulder),
        waist: parseFloat(formData?.waist),
        length: parseFloat(formData?.length),
        arms: parseFloat(formData?.arms),
        hips: parseFloat(formData?.hips),
        chest: parseFloat(formData?.chest),
        weight: parseFloat(formData?.weight),
        sleeves: parseFloat(formData?.sleeves),
        body_type: formData?.body_type,
        base_size: formData?.base_size,
        note: "",
      },
      from_store: false,
      for_store: true,
      total_discount: parseInt(formData?.discount),
      total_amount: parseInt(formData?.total_amount),
      origin: Origin.STORE
    };
    if (state?._id) {
      data._id = state?._id;
    }
    await createOrder(data);
  };

  useEffect(() => {
    // getting selected products leather
    const leather = selectedProduct?.product_specification?.map((leather: any) => {
      return {
        value: leather.leather_id?._id,
        title: leather?.leather_id?.item_name,
      };
    });
    setLeather(leather);

    if (!selectedProduct?.pollyfill) {
      form.setFieldsValue({
        pollyfill: false,
      });
    }
    if (!selectedProduct?.rib) {
      form.setFieldsValue({
        rib: false,
      });
    }
    // getting selected products hardware
    const hardware = selectedProduct?.hardware?.map((item: any) => {
      return {
        value: item,
        title: item,
      };
    });
    setHardware(hardware);

    // getting selected products lining
    const lining = selectedProduct?.lining?.map((item: any) => {
      return {
        value: item,
        title: item,
      };
    });
    setLining(lining);
  }, [selectedProduct]);

  useEffect(() => {
      const product = data?.map((product: any) => {
        return { value: product._id, title: product.name };
      });
      setProducts(product);
  }, [data]);

  const handelChangeSelect = (value: any, fieldName: string) => {
    form.setFieldsValue({
      [fieldName]: value,
    });

    // on leather profile value change getting primary and secondary image from leather profile
    if (fieldName === "leather_profile") {
      const filtredLeather = selectedProduct?.product_specification?.filter(
        (item: any) => item?.leather_id?._id === value
      );
      setSelectedLeather(filtredLeather?.[0]);
    }

    // on product name change getting product specification
    if (fieldName === "name") {
      setProductId(value);
      const selectedProduct = data?.filter((product: any) => product._id === value);
      form.resetFields(["leather_size", "leather_profile"]);
      setSelectedProduct(selectedProduct?.[0]);
    }
  };
  const [otpCode, setOtpCode] = useState<any>();
  const [otpVisible, setOtpVisible] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  // handling form submit
  const handleFormSubmitfoOtp = async () => {
    setOtpVisible(true);
    setOtpLoading(true);
    await getOpt()
      .then(() => {
        setOtpVisible(true);
      })
      .catch(() => {
        message.error("Error while requesting OTP");
      });
    setOtpLoading(false);
  };
  //otp code
  const handleOptChange = (e: any) => {
    setOtpCode(parseInt(e?.target?.value));
  };
  return (
    <div className="store-order">
      <h2 className="page-title">Create Order</h2>

      <Form
        className="store-order__content"
        colon={false}
        requiredMark={false}
        labelCol={{ span: 30 }}
        form={form}
        labelAlign={"left"}
        // onFinish={state?._id ? editSubmit : handleFormSubmit}
        onFinish={handleFormSubmitfoOtp}
        onFinishFailed={handleFormSubmitError}
      >
        {/* <Row className="select-container">
          <InputSelect />
        </Row> */}

        <Collapse
          bordered={false}
          defaultActiveKey={["1", "2", "3", "4"]}
          style={{ marginTop: 50 }}
          ghost
        >
          {/* panel 1 */}
          <Panel
            showArrow={false}
            header={"1. Product Information"}
            key="1"
            extra={
              errorSection?.productInfo ? (
                <div>
                  <ExclamationOutlined />
                </div>
              ) : (
                ""
              )
            }
          >
            <Card className="product-info white-border">
              <Row>
                <Col sm={24} xl={12}>
                  <Form.Item
                    style={{
                      alignItems: "center",
                      marginBottom: 30,
                    }}
                    // label='Product Name'
                    label={<LabelComponent>Product Name</LabelComponent>}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input Product Name!",
                      },
                    ]}
                  >
                    {/* <InputSelect
                      defaultValue={selectedProduct?.name}
                      option={products}
                      handleChange={handelChangeSelect}
                      fieldName={"name"}
                    /> */}
                    <Select
                      showSearch
                      filterOption={(inputValue, category: any) => {
                        if (category) {
                          return (
                            category?.children
                              // .join("")
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          );
                        }
                      }}
                      onChange={(e) => {
                        handelChangeSelect(e, "name");
                      }}
                      style={{ minWidth: 300 }}
                    >
                      {products?.map((product: any) => {
                        return (
                          <Select.Option value={product?.value}>{product?.title}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    style={{
                      alignItems: "center",
                      marginBottom: 30,
                    }}
                    // label='Leather Profile'
                    label={<LabelComponent>Leather Profile</LabelComponent>}
                    name="leather_profile"
                    rules={[
                      {
                        required: true,
                        message: "Please input Leather Profile!",
                      },
                    ]}
                  >
                    {/* <InputSelect
                      defaultValue={selectedLeather?.leather_id?.item_name || ""}
                      option={leather}
                      handleChange={handelChangeSelect}
                      fieldName={"leather_profile"}
                    /> */}
                    <Select
                      style={{ minWidth: 300 }}
                      onChange={(e) => {
                        handelChangeSelect(e, "leather_profile");
                        form.setFieldValue("leather_size", undefined)
                      }}
                    >
                      {leather?.map((product: any, index: number) => {
                        // console.log('index: ', index)
                        return (
                          <Select.Option key={index} value={product?.value}>
                            {product?.title}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  {/* <Form.Item
                      shouldUpdate
                      wrapperCol={{
                        span: 24,
                      }}
                    >
                      {() => {
                        return ( */}
                  <Form.Item
                    style={{
                      // display: "flex",
                      alignItems: "center",
                      marginBottom: 30,
                    }}
                    // label='Leather Size'
                    label={<LabelComponent>Size</LabelComponent>}
                    name="leather_size"
                    rules={[
                      {
                        required: true,
                        message: "Please input Leather Size!",
                      },
                    ]}
                  >
                    {/* <InputSelect
                            defaultValue={state?.leather_size || ""}
                            option={size}
                            handleChange={handelChangeSelect}
                            fieldName={"leather_size"}
                          /> */}
                    <Select
                      style={{ minWidth: 300 }}
                      onChange={(e) => {
                        const test = selectedLeather.size_range.filter(
                          (size: any) => size.size === e
                        );
                        const price = test && test?.[0]?.price[0]?.value;
                        setSizeRangeId(test?.[0]?._id);
                        form.setFieldValue("total_amount", price);
                      }}
                    >
                      {size?.map((product: any) => {
                        return (
                          <Select.Option value={product?.value}>{product?.value}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 30,
                    }}
                    // label='Hardware'
                    label={<LabelComponent>Hardware</LabelComponent>}
                    name="hardware"
                    rules={[
                      {
                        required: true,
                        message: "Please input Hardware!",
                      },
                    ]}
                  >
                    <InputSelect
                      defaultValue={state?.hardware || ""}
                      option={hardware}
                      handleChange={handelChangeSelect}
                      fieldName={"hardware"}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 30,
                    }}
                    // label='Lining'
                    label={<LabelComponent>Lining</LabelComponent>}
                    name="lining"
                    rules={[
                      {
                        required: true,
                        message: "Please input Hardware!",
                      },
                    ]}
                  >
                    <InputSelect
                      defaultValue={state?.lining || ""}
                      option={lining}
                      handleChange={handelChangeSelect}
                      fieldName={"lining"}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{
                      alignItems: "center",
                      marginBottom: 30,
                    }}
                    // label='Polyfill'
                    label={<LabelComponent>Polyfill</LabelComponent>}
                    name="pollyfill"
                    rules={[
                      {
                        required: true,
                        message: "Please input polyfill!",
                      },
                    ]}
                  >
                    {selectedProduct?.pollyfill ? (
                      // <InputSelect
                      //   defaultValue={state?.pollyfill || ""}
                      //   option={selectOptions}
                      //   handleChange={handelChangeSelect}
                      //   fieldName={"polyfill"}
                      // />
                      <Select
                        onChange={(e) => {
                          handelChangeSelect(e, "polyfill");
                        }}
                        style={{ minWidth: 300 }}
                      >
                        {selectOptions?.map((product: any) => {
                          return (
                            <Select.Option value={product?.value}>{product?.title}</Select.Option>
                          );
                        })}
                      </Select>
                    ) : (
                      <p
                        style={{
                          display: "flex",
                          marginTop: 20,
                        }}
                      >
                        No
                      </p>
                    )}
                  </Form.Item>

                  <Form.Item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 30,
                    }}
                    // label='Rib'
                    label={<LabelComponent>Rib</LabelComponent>}
                    name="rib"
                    rules={[
                      {
                        required: false,
                        message: "Please input Rib!",
                      },
                    ]}
                  >
                    {selectedProduct?.rib ? (
                      <InputSelect
                        defaultValue={state?.rib || ""}
                        option={selectOptions}
                        handleChange={handelChangeSelect}
                        fieldName={"rib"}
                      />
                    ) : (
                      <p
                        style={{
                          display: "flex",
                          marginTop: 20,
                        }}
                      >
                        No
                      </p>
                    )}
                  </Form.Item>
                </Col>
                <Col
                  sm={24}
                  xl={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {selectedProduct?.primary_image ? (
                    <Image
                      src={selectedProduct?.primary_image}
                      alt="profile"
                      preview={false}
                      style={{ width: "300px" }}
                    />
                  ) : (
                    <p>Please, select product and leather profile.</p>
                  )}
                </Col>
              </Row>
            </Card>
          </Panel>

          {/* panel 2*/}
          <Panel
            header="2. Remarks"
            key="2"
            extra={
              errorSection?.remarks ? (
                <div>
                  <ExclamationOutlined />
                </div>
              ) : (
                ""
              )
            }
          >
            {/* third card */}
            <Row style={{ margin: "40px 0", width: "100%" }}>
              <Card className="remarks white-border">
                <Form.Item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 30,
                  }}
                  // label='Remarks'
                  label={<LabelComponent>Remarks</LabelComponent>}
                  name="remarks"
                  rules={[
                    {
                      required: true,
                      message: "Please input Remarks!",
                    },
                  ]}
                >
                  <Input.TextArea className="text-area" rows={4} placeholder="Add Remarks" />
                </Form.Item>
              </Card>
            </Row>
          </Panel>

          {/* panel 3*/}

          <Panel
            header="3. Price"
            key="3"
            extra={
              errorSection?.price ? (
                <div>
                  <ExclamationOutlined />
                </div>
              ) : (
                ""
              )
            }
          >
            {/* forth card */}
            <Card className="price white-border">
              <Row gutter={[50, 50]}>
                <Col sm={24} xl={12}>
                  <Form.Item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 30,
                    }}
                    // label='Total Amount'
                    label={<LabelComponent>Total Amount</LabelComponent>}
                    name="total_amount"
                    rules={[
                      {
                        required: true,
                        message: "Please input Total Amount!",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        fontWeight: "bold",
                        color: "gray",
                      }}
                      disabled={true}
                      name="total_amount"
                      className="input-field white-border"
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} xl={12}>
                  <Form.Item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 30,
                    }}
                    // label='Discount'
                    label={<LabelComponent>Discount</LabelComponent>}
                    name="discount"
                    rules={[
                      {
                        required: true,
                        message: "Please input Discount!",
                      },
                    ]}
                  >
                    <Input className="input-field white-border" />
                  </Form.Item>
                </Col>
                <Col sm={24} xl={12}>
                  <Form.Item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 30,
                    }}
                    // label='Discount'
                    label={<LabelComponent>Delivery Date</LabelComponent>}
                    name="delivery_date"
                    rules={[
                      {
                        required: true,
                        message: "Please choose a delivery date!",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{
                        height: "50px",
                        width: "200px",
                      }}
                      disabledDate={(current: any) => disableBackDateFromToday(current)}
                      onChange={handleDateChange}
                    />
                  </Form.Item>
                  {daysLeft ? (
                    <Typography.Text className="delivery_date-warning">
                      {daysLeft} days left to deliver
                    </Typography.Text>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Card>
          </Panel>
        </Collapse>

        {/* buttons row */}
        <Row className="button-container">
          <Button className="cancel-btn" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button type="primary" className="main-btn" htmlType="submit" loading={isLoading}>
            {!state?._id ? "Submit" : "Update"}

            {/* {isLoading ? <Spin size="small" /> : "Submit"} */}
          </Button>
        </Row>
      </Form>
      <SuccessModel visible={visible} handleClose={handleClose} />
      <Modal
        title="OTP Verification"
        visible={otpVisible}
        onOk={handleFormSubmit}
        okText="Submit"
        onCancel={handleClose}
        okButtonProps={{
          loading: isLoading,
        }}
      >
        <Typography.Text style={{ fontWeight: "bold" }}>Please Enter OTP: </Typography.Text>
        <Form className="store-order otp-form">
          {/* <Input maxLength={1} className="input-field" />
          <Input maxLength={1} className="input-field" />
          <Input maxLength={1} className="input-field" />
          <Input maxLength={1} className="input-field" />
          <Input maxLength={1} className="input-field" />
          <Input maxLength={1} className="input-field" /> */}
          <Input maxLength={6} className="input-field" onChange={handleOptChange} />
        </Form>
      </Modal>
    </div>
  );
};
