import React, { FC, useEffect } from "react";
// import 'Themes/App.scss'
import "../theme-config/theme/ant.css";
import MainRoute from "../Routes/MainRoute";
import "./index.scss";
import NotAllowedView from "../Components/NotAllowedView/NotAllowedView";
import MediaQuery from "react-responsive";
import markerSDK from "@marker.io/browser";

const App: FC = () => {
  // const markerInit = async () => {
  //   const widget = await markerSDK.loadWidget({
  //     project: "642e658d6a1d145624806381",
  //   });
  // };

  // useEffect(() => {
  //   markerInit();
  // }, []);
  return (
    <>
      <MediaQuery maxWidth={800}>
        <NotAllowedView />
      </MediaQuery>
      <MediaQuery minWidth={800}>
        <MainRoute />
      </MediaQuery>
    </>
  );
};

export default App;
