import { ClockCircleOutlined, DownloadOutlined, MoreOutlined } from "@ant-design/icons";
import { Col, Divider, Popover, Row, notification } from "antd";
import { useState } from "react";
import { CompletionProcess, OrderManageProcess } from "./OrdersEnum";

interface propsType {
  setIsDeliverModalOpen?: any;
  setIsTransferModalOpen?: any;
  setIsReceiveFromStoreModalOpen?: any;
  setSelectedOrder?: any;
  orderType?: string;
  order?: any;
}

const ActionPopover = ({
  setIsDeliverModalOpen = () => {},
  setIsTransferModalOpen = () => {},
  setIsReceiveFromStoreModalOpen = () => {},
  setSelectedOrder = () => {},
  order = {},
  orderType = "customer",
}: propsType) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const handelDeliverCLick = () => {
    if (order?.completion_process === CompletionProcess.IN_STORE) {
      setIsDeliverModalOpen(true);
      setSelectedOrder(order);
      setVisible(false);
    } else {
      notification.error({
        message: "Product not in store!!",
        placement: "top",
      });
    }
  };
  const handelTransferCLick = () => {
    if (order.completion_process.toLowerCase() === CompletionProcess.DELIVERED) {
      notification.info({
        message: "Product already Delivered!",
        placement: "top",
        style: { background: "skyblue"}
      });
      return;
    }
    // order?.for_store is true if this is store order or transferred to store
    if (order?.for_store) {
      if (order.completion_process === CompletionProcess.IN_STORE) {
        setIsTransferModalOpen(true);
        setSelectedOrder(order);
        setVisible(false);
        return;
      } else {
        notification.error({
          message: "Not in store to transfer to costumer",
          placement: "top",
        });
        return;
      }
    } else {
      setIsTransferModalOpen(true);
      setSelectedOrder(order);
      setVisible(false);
    }
  };

  function handelReceiveFromStoreCLick() {
    if (order?.completion_process.toLowerCase() === CompletionProcess.IN_STORE) {
      notification.info({
        message: "Product already in store!",
        placement: "top",
        style: {background: "skyblue"}
      });
      return;
    }
    if (order?.completion_process.toLowerCase() === CompletionProcess.DELIVERED) {
      notification.info({
        message: "Product already delivered!",
        placement: "top",
        style: {background: "skyblue"}
      });
      return;
    }
    if (order?.completion_process === CompletionProcess.DISPATCHED) {
      order.completion_process = CompletionProcess.IN_STORE;
      order.order_manage_process = OrderManageProcess.COMPLETED;
      setSelectedOrder(order);
      setIsReceiveFromStoreModalOpen(true);
      setVisible(false);
    } else {
      notification.error({
        message: "Not dispatched to receive in store!!",
        placement: "top",
      });
    }
  }

  return (
    <Popover
      content={
        <div style={{ padding: 10 }}>
          {orderType !== "store" ? (
            <>
              <Row style={{ cursor: "pointer" }} onClick={() => handelDeliverCLick()}>
                <Col style={{ marginRight: 15, color: "var(--blue)" }}>
                  <ClockCircleOutlined />
                </Col>
                <Col> Deliver </Col>
              </Row>
              <Divider style={{ padding: 0, margin: "1rem" }} />
            </>
          ) : null}
          <Row style={{ cursor: "pointer" }} onClick={() => handelTransferCLick()}>
            <Col
              style={{
                marginRight: 15,
                transform: "rotate(-90deg)",
                color: "var(--blue)",
              }}
            >
              <DownloadOutlined rotate={order?.for_store ? 180 : 0} />
            </Col>
            <Col>
              {orderType === "customer" ? "Transfer to Store" : "Deliver to Customer"}
            </Col>
          </Row>
          <Divider style={{ padding: 0, margin: "1rem" }} />
          <Row
            style={{ cursor: "pointer" }}
            // hidden={orderType === "store"}
            onClick={handelReceiveFromStoreCLick}
          >
            <Col
              style={{
                marginRight: 15,
                transform: "rotate(-90deg)",
                color: "var(--blue)",
              }}
            >
              <DownloadOutlined rotate={order?.for_store ? 0 :180} />
            </Col>
            <Col>Receive in Store</Col>
          </Row>
        </div>
      }
      // title='action'
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <MoreOutlined className="customer-order__value__icon" />
    </Popover>
  );
};

export default ActionPopover;
