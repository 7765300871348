import { Reducer } from 'redux'
import {
  FETCH_NEWSLETTER_TABLE_CONTENT_FAILED,
  FETCH_NEWSLETTER_TABLE_CONTENT_REQUEST,
  FETCH_NEWSLETTER_TABLE_CONTENT_SUCCESS,
  GET_PROMOCODE_FAILURE,
  GET_PROMOCODE_REQUEST,
  GET_PROMOCODE_SUCCESS,
  POST_NEWSLETTER_FORM_FAILURE,
  POST_NEWSLETTER_FORM_REQUEST,
  POST_NEWSLETTER_FORM_SUCCESS,
  POST_PROMOCODE_FAILURE,
  POST_PROMOCODE_REQUEST,
  POST_PROMOCODE_SUCCESS,
} from '../Constants/constants'

const INIT_STATE = {
  newsLetterList: [],
  loading: false,
  error: [],
  PromoCodeList: [],
}

export const gridReducer: Reducer<any, any> = (
  state = INIT_STATE,
  action: any
) => {
  switch (action.type) {
    //newsletter table contents
    case FETCH_NEWSLETTER_TABLE_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_NEWSLETTER_TABLE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        newsLetterList: action?.payload?.data,
      }
    case FETCH_NEWSLETTER_TABLE_CONTENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.data,
      }
    case POST_NEWSLETTER_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case POST_NEWSLETTER_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case POST_NEWSLETTER_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.data,
      }
    case GET_PROMOCODE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_PROMOCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        PromoCodeList: action.payload.data,
      }
    case GET_PROMOCODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.data,
      }
    case POST_PROMOCODE_REQUEST:
      return {
        ...state,
        loading: false,
      }
    case POST_PROMOCODE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case POST_PROMOCODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.data,
      }
    default:
      return { ...state }
  }
}
