export const FETCH_BLOGS = "FETCH_BLOGS";
export const FETCH_BLOGS_SUCCESS = "FETCH_BLOGS_SUCCESS";
export const FETCH_BLOGS_FAILURE = "FETCH_BLOGS_FAILURE";

export const FETCH_BLOG_DETAILS = "FETCH_BLOG_DETAILS";
export const FETCH_BLOG_DETAILS_SUCCESS = "FETCH_BLOG_DETAILS_SUCCESS";
export const FETCH_BLOG_DETAILS_FAILURE = "FETCH_BLOG_DETAILS_FAILURE";

export const CREATE_BLOG = "CREATE_BLOG";
export const CREATE_BLOG_SUCCESS = "CREATE_BLOG_SUCCESS";
export const CREATE_BLOG_FAILURE = "CREATE_BLOG_FAILURE";

export const UPDATE_BLOG = "UPDATE_BLOG";
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS";
export const UPDATE_BLOG_FAILURE = "UPDATE_BLOG_FAILURE";

export const REMOVE_BLOG = "REMOVE_BLOG";
export const REMOVE_BLOG_SUCCESS = "REMOVE_BLOG_SUCCESS";
export const REMOVE_BLOG_FAILURE = "REMOVE_BLOG_FAILURE";

export interface BlogFetchParams {
  limit?: number;
  page?: number;
  total?: number;
  search?: string;
  sort?: "asc" | "desc";
}
export interface BlogDetailsParams {
  id?: number;
  blogId?: any;
}
export interface CreateBlogParams {
  id?: number;
  name?: string;
  address?: string;
  phone?: string;
  _id: any;
  blogId?: string;
}
