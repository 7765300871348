/** @format */

import { Alert, Button, Card, Col, Image, Modal, Row, Typography } from 'antd'
import { useState } from 'react'
import Template1 from '../../../Assets/Newsletter/template1.png'
import Template2 from '../../../Assets/Newsletter/template2.png'
import Template3 from '../../../Assets/Newsletter/template3.png'
import { useNavigate } from 'react-router'

interface emailTypes {
  type?: any
}

const EmailCard = ({ type }: emailTypes) => {
  const history = useNavigate()
  const [alert, setAlert] = useState(false)
  const [isSelected, setIsSelected] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const handleSelect = () => {
    if (isSelected !== '') {
      setIsModalVisible(false)
      history(`/grid/templates/${type.title}/${isSelected}`)
    } else {
      setAlert(true)
      setIsModalVisible(true)
    }
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <Card>
      <Row>
        <Col
          span={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image src={type.img} preview={false} />
        </Col>
        <Col
          span={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <Typography.Title level={3}>{type.title}</Typography.Title>
          <Typography.Text>{type.description}</Typography.Text>
          <Button
            type='primary'
            shape='round'
            size='middle'
            style={{ marginTop: 10 }}
            onClick={() => setIsModalVisible(true)}
          >
            Create
          </Button>
          <Modal
            closable={false}
            visible={isModalVisible}
            okText='Select'
            cancelText='Cancel'
            onOk={() => {
              handleSelect()
            }}
            onCancel={handleCancel}
            okButtonProps={{ shape: 'round', size: 'middle' }}
            cancelButtonProps={{ type: 'text', size: 'middle' }}
            centered
            width={900}
          >
            {alert && (
              <Alert message='Please Select a Template' type='warning' />
            )}
            <Typography.Title level={3}>TEMPLATE</Typography.Title>
            <Row gutter={[20, 20]} justify='space-around' align='middle'>
              <Col span={8}>
                <Image
                  style={{
                    border: isSelected === 'long-form' ? '1px solid black' : '',
                  }}
                  src={Template1}
                  alt='long form'
                  preview={false}
                  onClick={() => setIsSelected('long-form')}
                />
              </Col>
              <Col span={8}>
                <Image
                  style={{
                    border:
                      isSelected === 'short-form' ? '1px solid black' : '',
                  }}
                  src={Template2}
                  alt='short form'
                  preview={false}
                  onClick={() => setIsSelected('short-form')}
                />
              </Col>
              <Col span={8}>
                <Image
                  style={{
                    border:
                      isSelected === 'shorter-form' ? '1px solid black' : '',
                  }}
                  src={Template3}
                  alt='shorter form'
                  preview={false}
                  onClick={() => setIsSelected('shorter-form')}
                />
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
    </Card>
  )
}
export default EmailCard
