import { AxiosResponse } from 'axios'
import { createRoutine } from 'redux-saga-routines'
import { Error, ReducerActionType } from '../../Utils/types'
import {
  REQUEST_LOGIN,
  REQUEST_LOGIN_FAILURE,
  REQUEST_LOGIN_SUCCESS,
  RequestLoginParams,
  SET_AUTH_USER,
} from './AuthTypes'

//login request
export const requestLogin = (
  params: RequestLoginParams
): ReducerActionType => ({
  type: REQUEST_LOGIN,
  payload: params,
})

export const requestLoginSuccess = (
  response: AxiosResponse
): ReducerActionType => ({
  type: REQUEST_LOGIN_SUCCESS,
  payload: response,
})

export const requestLoginFailure = (error: Error): ReducerActionType => ({
  type: REQUEST_LOGIN_FAILURE,
  payload: error,
})
export const setAuthUser = (user: any) => ({
  type: SET_AUTH_USER,
  payload: user,
})

export const FORGOT_PASSWORD = createRoutine('FORGOT_PASSWORD')
