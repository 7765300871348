import { Col, Empty, Row, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import RcQueueAnim from "rc-queue-anim";
import ExchangeRefundItem from "../ExchangeRefundItem/ExchangeRefundItem";
import { useHorizontalScroll } from "../../../Hooks/useHorizontalScroll";

export const ExchangeRefundTab = (props: any) => {
  const { data, loading, onDetails } = props;
  const scrollRef: any = useHorizontalScroll();
  return (
    <Content
      style={{
        width: "calc(100% + 100px) !important",
        minWidth: "calc(100% + 100px) !important",
        overflowX: "scroll",
        height: "calc(100% + 30px)",
        // padding: "20px",
        paddingBottom: 0,
        margin: "0px -40px",
      }}
      ref={scrollRef}
    >
      <Content
        style={{
          width: "100%",
          height: "fit-content",
          padding: "20px",
          paddingBottom: 0,
        }}
      >
        {/* {loading && (
        <Row align='middle' justify='center' style={{ margin: '40px' }}>
          <Spin tip='Getting Data' />
        </Row>
      )} */}
        <RcQueueAnim
          component={"row"}
          componentProps={{
            gutter: [20, 0],
            style: {
              width: data.length * 270,
              flexWrap: true,
            },
          }}
          type="bottom"
        >
          {data && data.length <= 0 && <Empty description="No Data" />}
          <div style={{ display: "flex" }}>
            {!loading &&
              data.map((d: any, i: number) => {
                console.log(d, "data");
                return (
                  <Col span={5}>
                    {d.map((child: any, ii: number) => {
                      return (
                        <ExchangeRefundItem
                          onClick={(e: number) => onDetails(e)}
                          data={child}
                          size={ii % 2 === i % 2 ? "small" : "medium"}
                        />
                      );
                    })}
                  </Col>
                );
              })}
          </div>
        </RcQueueAnim>
      </Content>
    </Content>
  );
};

export default ExchangeRefundTab;
