/** @format */

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Col, Pagination, Popconfirm, Row, Space, Tag, Typography, notification } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import LineEllipsis from "react-lines-ellipsis";
import { useDispatch } from "react-redux";
import { deleteBlog, fetchBlogs } from "../../../Redux/BlogRedux/BlogActions";
// import NeuMorph from "../../../Components/Admin/NeuMorph/NeuMorph";
import LatidoTable from "../../../Components/Admin/Table/Table";

export const BlogsList = (props: any) => {
  const { data, loading } = props;
  const history = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = (blogId: string) => {
    dispatch(deleteBlog({ blogId }));
  };

  const cancel = () => {
    console.log("canceled");
  };

  const ActionList = (props: any) => {
    const menu = (
      // <NeuMorph>
      <Space
        className="content"
        style={{ paddingRight: 10, alignItems: "center", display: "flex", background: "#e3edf7" }}
      >
        <EditOutlined
          onClick={(e: any) => history(`/blogs/${props?.id}`)}
          style={{ fontSize: 18 }}
        />
        <Popconfirm
          title="Delete the Blog?"
          onConfirm={() => handleDelete(props?.id)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined
            style={{ fontSize: 18, color: "red" }}
            // onClick={handleDelete}
          />
        </Popconfirm>
      </Space>
      // </NeuMorph>
    );
    return menu;
    // <Dropdown placement="topCenter" overlay={menu} trigger={["click"]}>
    //   <Typography.Text strong style={{ fontSize: 18, fontWeight: "bold", color: "black" }}>
    //     ...
    //   </Typography.Text>
    // </Dropdown>
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text: string, record: any) => {
        return (
          <Space style={{ alignItems: "center" }}>
            <Typography.Link
              onClick={() => {
                history(`/blogs/details/${record?.id}`);
              }}
            >
              <LineEllipsis text={text} maxLine={2} />
            </Typography.Link>

            {/* <EyeFilled
              onClick={() => history(`/blogs/details/${record?.id}`)}
              style={{ fontSize: 18 }}
            /> */}
          </Space>
        );
      },
      width: "20%",
    },
    {
      title: "Description",
      dataIndex: "shortDescription",
      key: "shortDescription",
      render: (text: string) => <LineEllipsis text={text} maxLine={2} />,
      width: "20%",
    },
    {
      title: "Author",
      key: "author",
      dataIndex: "author",
    },
    {
      title: "Date",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (record: any) => <>{dayjs(record).format("LLL")}</>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (record: any) => {
        return <Typography.Text>{record ? "Published" : "Draft"}</Typography.Text>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (record: any, text: any): any => (
        <Space size="middle">
          <ActionList {...record} />
        </Space>
      ),
    },
  ];

  const onShowSizeChange = (current: number, size: number) => {
    dispatch(fetchBlogs({ limit: size, page: current }));
  };
  const onPageChange = (page: number, pageSize?: number) => {
    dispatch(fetchBlogs({ limit: pageSize, page: page }));
  };
  return (
    <Row justify="center">
      <Col lg={24}>
        <LatidoTable
          pagination={false}
          loading={loading}
          dataSource={data.list}
          columns={columns}
        />
      </Col>
      <Col>
        {!loading && (
          <Pagination
            showSizeChanger
            style={{ marginTop: 30 }}
            onShowSizeChange={onShowSizeChange}
            defaultCurrent={data.current}
            current={data.current}
            total={data.total}
            onChange={onPageChange}
          />
        )}
      </Col>
    </Row>
  );
};

export default BlogsList;
