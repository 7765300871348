/** @format */

import { Col, Row, Typography } from 'antd'
import EmailCard from '../../../../../Components/Admin/EmailCard/EmailCard'
import { EmailTypesList } from '../../../../../Data/emailTypesList'

const TypesOfEmail = () => {
  return (
    <>
      <Row justify='start' align='middle'>
        <Col span={24}>
          <Typography.Title>SELECT TYPES OF EMAIL</Typography.Title>
        </Col>
      </Row>
      <Row gutter={[30, 30]} style={{ marginTop: 20 }}>
        {EmailTypesList.map((type) => {
          return (
            <Col sm={24} md={24} lg={12} key={type.id}>
              <EmailCard type={type} />
            </Col>
          )
        })}
      </Row>
    </>
  )
}
export default TypesOfEmail
