/** @format */

import { Button, Card, Col, Form, Input, Radio, Row, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PostPromocodeRequest } from '../../../Redux/Actions/GridAction'
import UploadFiles from '../UploadFiles/UploadFiles'

const spanTwenty = {
  labelCol: { span: 8 },
  wrapperCol: { sm: 24, md: 16 },
}
const fullSpanLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
}
const EditPromoCode: FC = () => {
  const [value1, setValue1] = useState()
  const [form] = useForm()
  const dispatch = useDispatch()

  const onFinish = (values: any) => {
    dispatch(PostPromocodeRequest(values))
  }

  function onChange(checked: any) {}

  return (
    <>
      <Typography.Title level={1}>PROMO CODE</Typography.Title>
      <Card style={{ padding: '0 200px' }}>
        <Form layout='vertical' form={form} onFinish={onFinish}>
          <Typography.Title level={3}>EDIT PROMO CODE</Typography.Title>
          <Row>
            <Col span={12}>
              <Form.Item
                {...spanTwenty}
                label='title'
                name='Title'
                rules={[{ required: true, message: 'Title is required.' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...spanTwenty}
                label='Sub Text'
                name='Sub Text'
                rules={[{ required: true, message: 'Sub Text is required.' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                {...spanTwenty}
                label='Discount'
                name='Discount'
                rules={[
                  {
                    required: true,
                    message: 'Discount is required.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            {...fullSpanLayout}
            label='descripton'
            name='descripton'
            rules={[{ required: true, message: 'Descripton is required.' }]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item
                {...spanTwenty}
                label='status'
                name='Status'
                rules={[{ required: true, message: 'Status is required.' }]}
              >
                <Radio.Group
                  onChange={(e) => setValue1(e.target.value)}
                  value={value1}
                >
                  <Radio value='Published'>Published</Radio>
                  <Radio value='Draft'>Draft</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...spanTwenty}
                label='Send Promo Order By'
                name='Send Promo Order By'
                rules={[
                  {
                    required: true,
                    message: 'Send Promo Order By is required.',
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => setValue1(e.target.value)}
                  value={value1}
                >
                  <Radio value='Email'>Email</Radio>
                  <Radio value='Notification'>Notification</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            {...fullSpanLayout}
            name='files'
            rules={[{ required: true, message: 'Descripton is required.' }]}
          >
            <UploadFiles />
          </Form.Item>
          <Form.Item {...fullSpanLayout} name='buttons'>
            <Button
              type='primary'
              size='middle'
              shape='round'
              htmlType='submit'
            >
              Save
            </Button>
            <Button type='text'>Cancel</Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  )
}

export default EditPromoCode
