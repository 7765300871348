/** @format */

import React, { useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './QuillBody.scss'

import { Divider } from 'antd'

const CustomToolbar = () => (
  <div id='toolbar' className='toolbar'>
    <button className='ql-bold' />
    <button className='ql-italic' />
    <button className='ql-underline' />
  </div>
)

function QuillBody({ onChange }: any) {
  const handleChange = (html: any) => {
    onChange(html)
  }

  const THEME = 'snow'

  const modules = {
    toolbar: {
      container: '#toolbar',
      handlers: {},
    },
  }

  const formats = ['bold', 'italic', 'underline'] //Add align for paragraph alignment

  return (
    <div className='text-editor'>
      <CustomToolbar />
      <Divider style={{ border: '0.5px solid #9ea2ad', margin: '0px' }} />
      {/* <ReactQuill
        onChange={handleChange}
        modules={modules}
        formats={formats}
        className='my-editing-area'
        theme={THEME}
      /> */}
      {ReactQuill}
    </div>
  )
}

export default QuillBody
