const textTruncate = (str: any, length: number, ending: string) => {
  if (length == null) {
    length = 100
  }
  if (ending == null) {
    ending = '...'
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  }
  else {
    return str
  }
}

export default textTruncate