/** @format */

import { Route, Routes } from 'react-router'
import CreateCampaign from '../Components/Admin/CreateCampaign/CreateCampaign'
import EditPromoCode from '../Components/Admin/EditPromoCode/EditPromoCode'
import PreviewCard from '../Components/Admin/PreviewCard/PreviewCard'
import withPermission from '../Utils/checkPermission'
import Grid from '../Views/Admin/Grid/grid'
import Newsletter from '../Views/Admin/Grid/Newsletter/Newsletter'
import Templates from '../Views/Admin/Grid/Newsletter/Templates/Templates'
import TypesOfEmail from '../Views/Admin/Grid/Newsletter/TypesofEmail/TypesOfEmail'
import PromoCodes from '../Views/Admin/Grid/PromoCode/PromoCodes'

export const GridRoute = (props: any) => {
  return (
    <Routes>
      <Route path={`/`} element={<Grid />} />
      <Route path={`/promo-code`} element={<PromoCodes />} />
      <Route path={`/promo-code/add`} element={<CreateCampaign />} />
      <Route path={`/promo-code/edit`} element={<EditPromoCode />} />
      <Route
        path={`/promo-code/preview-promo-codes`}
        element={<PreviewCard />}
      />
      <Route path={`/newsletter`} element={<Newsletter />} />
      <Route path={`/types-of-email`} element={<TypesOfEmail />} />
      <Route path={`/templates/:title/:templateType`} element={<Templates />} />
    </Routes>
  )
}
export default withPermission(GridRoute, 'grid')
