import { Reducer } from "react";
import { retry } from "redux-saga/effects";
import {
  CREATE_INVENTORY_CATEGORIES,
  CREATE_INVENTORY_CATEGORIES_FAILURE,
  CREATE_INVENTORY_CATEGORIES_SUCCESS,
  FETCH_INVENTORY_CATEGORIES,
  FETCH_INVENTORY_CATEGORIES_FAILURE,
  FETCH_INVENTORY_CATEGORIES_SUCCESS,
} from "./InventoryCategoryTypes";

const INIT_STATE = {
  inventoryCategories: [],
  error: "",
  loading: false,
  creating: false,
};

export const InventoryCategoryReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_INVENTORY_CATEGORIES:
      return { ...state, loading: true };
    case FETCH_INVENTORY_CATEGORIES_SUCCESS:
      return { ...state, loading: false, inventoryCategories: action.payload };
    case FETCH_INVENTORY_CATEGORIES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    //create  Inventory Categories
    case CREATE_INVENTORY_CATEGORIES:
      return { ...state, creating: true, created: false };
    case CREATE_INVENTORY_CATEGORIES_SUCCESS:
      return {
        ...state,
        creating: false,
        created: true,
        inventoryCategories: [
          ...state?.inventoryCategories?.filter((cat: any) => cat?._id != action?.payload?._id),
          { ...action?.payload, items: [] },
        ],
      };
    case CREATE_INVENTORY_CATEGORIES_FAILURE:
      return { ...state, creating: false, created: false };

    default:
      return state;
  }
};
