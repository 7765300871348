import { CheckOutlined, CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Card, Col, Divider, Image, Row, Select, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { Pagination } from "antd";
import { useQuery } from "react-query";
import { createOrder, fetchOrders } from "../../API";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import ActionPopover from "./Popover";
import { LatidoAnimatedLogoLoader } from "../../Components/LatidoAnimateLogo/LatidoAnimateLogo";
import { formatDateToString } from "../../Utils";
import { CompletionProcess, PaymentStatus, TransferState } from "./OrdersEnum";
import transfer from "../../Assets/Icons/transfer.svg";

interface propsType {
  listType?: string;
  setIsDeliverModalOpen?: any;
  setIsTransferModalOpen?: any;
  setIsReceiveFromStoreModalOpen?: any;
  setSelectedOrder?: any;
  search?: string;
  count?: any;
  pagination?: any;
  handlePaginationChange?: any;
}

type OrderStates = "order_status" | "order_id" | "name" | "";
type CallState = "called" | "not_received" | "messaged";

const CustomerOrder = ({
  listType = "list",
  setIsDeliverModalOpen = () => {},
  setIsTransferModalOpen = () => {},
  setIsReceiveFromStoreModalOpen = () => {},
  setSelectedOrder = () => {},
  search = "",
  count,
  pagination,
  handlePaginationChange,
}: propsType) => {
  const [limit] = useState(10);
  const navigation = useNavigate();

  useEffect(() => {
    if (search.length >= 1) {
      handlePaginationChange(1, 10);
    }
  }, [search]);

  // fetching orders
  const { data, error, isFetching, refetch } = useQuery(
    ["customerOrdersList", pagination.current || 1, limit, search],
    () => fetchOrders(pagination.current || 1, limit, "customerOrder", search),
    {
      enabled: Boolean([pagination.current || 1, limit, search]),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const [sortOrdersBy, setSortOrdersBy] = useState<OrderStates>("");
  const [asc, setAsc] = useState<boolean>(false);

  const handletoDetails = (id: string) => {
    navigation(`/store/order/${id}`);
  };

  const handleSortOrders = (sortBy: OrderStates) => {
    setSortOrdersBy(sortBy);
    setAsc(!asc);
  };

  useEffect(() => {
    refetch();
  }, [count]);

  // const sorted = useMemo(() => {
  //   // ORDER STATE
  //   const arr = data?.data || [];

  //   arr.sort(
  //     sortByPredicate((o: any) => {
  //       if (sortOrdersBy === "order_status") return o.completion_process;
  //       if (sortOrdersBy === "order_id") return `${o?.order_no}`;
  //       if (sortOrdersBy === "name") return `${o?.user_id?.name}`;
  //       return o.completion_process;
  //     }, asc)
  //   );

  //   return arr;
  // }, [data, sortOrdersBy, asc]);

  return (
    <>
      {listType === "list" && (
        <Card className="customer-order">
          <Row gutter={[10, 10]} className="customer-order__title">
            <Col
              sm={2}
              style={{ cursor: "pointer" }}
              // onClick={() => handleSortOrders("order_id")}
            >
              <h4>ORDER ID</h4>
            </Col>
            <Col
              sm={4}
              style={{ cursor: "pointer" }}
              // onClick={() => handleSortOrders("name")}
            >
              <h4> FULL NAME</h4>
            </Col>
            <Col sm={2}>
              <h4> CONTACT NO.</h4>
            </Col>
            <Col sm={4}>
              <h4> PRODUCT</h4>
            </Col>
            <Col sm={3}>
              <h4> DELIVERY DATE</h4>
            </Col>
            <Col sm={2}>
              <h4>CONTACT STATUS</h4>
            </Col>
            <Col sm={3}>
              <h4
                style={{ cursor: "pointer" }}
                // onClick={() => handleSortOrders("order_status")}
              >
                ORDER STATE
              </h4>
            </Col>
            <Col sm={2}>
              <h4>PAYMENT STATUS</h4>
            </Col>
            <Col sm={2}></Col>
          </Row>
          <Divider />
          {isFetching && (
            <Row justify="center" style={{ width: "100%" }}>
              {/* <Spin size='large' /> */}
              <LatidoAnimatedLogoLoader />
            </Row>
          )}
          {!isFetching &&
            data?.data.map((order: any) => {
              const completion_process = order?.order_state;
              const user_name = order?.user_id?.name;
              const user_contact_number = order?.user_id?.phone;
              const ordered_product = order?.product?.name;
              const payment_status = order?.payment_status;

              return (
                <Row gutter={[10, 10]} className="customer-order__value" key={order?._id}>
                  <Col sm={2} className="with-transfer">
                    <h5 onClick={() => handletoDetails(order?._id)}>
                      {order?.order_no}
                    </h5>
                    {order?.transfer_state ? (
                      <div>
                        <Tooltip
                          title={
                            order?.transfer_state === TransferState.STORE_CUSTOMER
                              ? "Store to Customer"
                              : ""
                          }
                        >
                          {order?.transfer_state && (
                            <img src={transfer} width={"18px"} alt="transfer" />
                          )}
                        </Tooltip>
                      </div>
                    ) : null}
                  </Col>
                  <Col sm={4}>
                    <h5 style={{ cursor: "pointer" }} onClick={() => handletoDetails(order?._id)}>
                      {user_name}
                    </h5>
                  </Col>
                  <Col sm={2}>
                    <h5>{user_contact_number}</h5>
                  </Col>
                  <Col sm={4}>
                    <h5
                      style={{ cursor: "pointer" }}
                      onClick={() => navigation(`/product/${order?.product?._id}`)}
                    >
                      {ordered_product}
                    </h5>
                  </Col>
                  <Col sm={3}>
                    <h5 className={"warning"}>{formatDateToString(order?.delivery_date)}</h5>
                  </Col>
                  <Col sm={2}>
                    <Select
                      style={{ width: 120 }}
                      defaultValue={order?.call_state}
                      onChange={async (value: any) => {
                        const data = await createOrder({
                          ...order,
                          call_state: value,
                          bypass_otp: true,
                        });
                        // update the order state to reflect the latest changes
                        if (data) {
                          order.call_state = data?.call_state;
                        }
                      }}
                      options={[
                        {
                          value: "not_called",
                          label: "Not Called",
                        },
                        {
                          value: "called",
                          label: "Called",
                        },
                        {
                          value: "not_received",
                          label: "Not Received",
                        },
                        {
                          value: "messaged",
                          label: "Messaged",
                        },
                      ]}
                    />
                  </Col>
                  <Col sm={3}>
                    <Tooltip>
                      <h5
                        style={{
                          textTransform: "capitalize",
                        }}
                        className={
                          order?.completion_process.toLowerCase() === CompletionProcess.DELIVERED
                            ? "complete"
                            : "inProgress"
                        }
                      >
                        {
                          // order?.completion_process +" | " +order?.order_manage_process +" | " +order?.order_state
                          order?.completion_process?.split("_")?.join(" ")
                        }
                      </h5>
                    </Tooltip>
                  </Col>

                  <Col
                    sm={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip
                      title={
                        payment_status === PaymentStatus.PAYMENT_COMPLETE
                          ? "PAYMENT COMPLETE"
                          : "PAYMENT INCOMPLETE"
                      }
                    >
                      {payment_status === "payment_complete" ? (
                        <CheckCircleFilled style={{ color: "#32a964", fontSize: 20 }} />
                      ) : (
                        <CloseCircleFilled style={{ color: "#eb5757", fontSize: 20 }} />
                      )}
                    </Tooltip>
                  </Col>

                  <Col sm={2}>
                    <ActionPopover
                      setIsReceiveFromStoreModalOpen={setIsReceiveFromStoreModalOpen}
                      setIsDeliverModalOpen={setIsDeliverModalOpen}
                      setIsTransferModalOpen={setIsTransferModalOpen}
                      order={order}
                      setSelectedOrder={setSelectedOrder}
                      orderType="customer"
                    />
                  </Col>
                </Row>
              );
            })}

          {!isFetching && data?.data.length > 0 && (
            <Row className="customer-order__pagination">
              <Pagination
                total={data?.total_data}
                current={+pagination.current || 1}
                defaultPageSize={limit}
                onChange={(e) => {
                  handlePaginationChange(e, data?.total_data);
                }}
                showSizeChanger={false}
              />
            </Row>
          )}
          {!isFetching && data?.data.length === 0 && (
            <>
              <Row justify="center" className="customer-order__pagination">
                <Image src={require("../../Assets/Icons/folderIcon.png")} preview={false} />
              </Row>
              <Row justify="center" className="customer-order__pagination">
                <h3>No Orders Available</h3>
              </Row>
            </>
          )}
        </Card>
      )}
      {listType === "grid" && (
        <>
          <div className="grid">
            {!isFetching &&
              data?.data.map((order: any) => {
                const payment_status = order?.payment_status;
                return (
                  <Card
                    key={order?._id}
                    className="product-card white-border"
                    onClick={() => handletoDetails(order?._id)}
                  >
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <div className="tick-icon">
                        <CheckOutlined />
                      </div> */}
                      <Tooltip
                        title={
                          payment_status === PaymentStatus.PAYMENT_COMPLETE
                            ? "PAYMENT COMPLETE"
                            : "PAYMENT INCOMPLETE"
                        }
                      >
                        <div
                          className={
                            order?.completion_process &&
                            order.completion_process.toLowerCase() === CompletionProcess.DELIVERED
                              ? "tick-icon"
                              : ""
                          }
                        >
                          {order?.completion_process &&
                          order.completion_process.toLowerCase() === CompletionProcess.DELIVERED ? (
                            <CheckOutlined />
                          ) : (
                            <CloseCircleFilled style={{ color: "#eb5757", fontSize: 20 }} />
                          )}
                        </div>
                      </Tooltip>
                      <p>{Moment(order?.delivery_date).format("DD MMM YYYY")}</p>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px 0",
                      }}
                    >
                      <Image
                        src={order?.product?.primary_image}
                        alt={order?.product?.name}
                        className="image"
                        preview={false}
                      />
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h5>{order?.order_no}</h5>
                      <h4>{order?.user_id?.name}</h4>
                    </Row>
                  </Card>
                );
              })}
          </div>
          {!isFetching && data?.data.length > 0 && (
            <Row className="customer-order__pagination">
              <Pagination
                total={data?.total_data}
                current={+pagination.current || 1}
                defaultPageSize={limit}
                onChange={(e) => {
                  handlePaginationChange(e, data?.total_data);
                }}
                showSizeChanger={false}
              />
            </Row>
          )}
          {isFetching && (
            <Row justify="center" style={{ width: "100%" }}>
              <Spin size="large" />
            </Row>
          )}
          {!isFetching && data?.data.length === 0 && (
            <>
              <Row justify="center" className="customer-order__pagination">
                <Image src={require("../../Assets/Icons/folderIcon.png")} preview={false} />
              </Row>
              <Row justify="center" className="customer-order__pagination">
                <h3>No Orders Available</h3>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CustomerOrder;
