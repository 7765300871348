/** @format */

import {
  Typography,
  Form,
  Radio,
  Row,
  Col,
  Select,
  Space,
  Button,
  Card,
} from 'antd'

import { useEffect, useState } from 'react'
import './EditFormStaticContent.scss'
import { Input } from 'antd'
import 'react-quill/dist/quill.snow.css'
import { useDispatch, useSelector } from 'react-redux'

import { useParams } from 'react-router'
import { FormOutlined } from '@ant-design/icons'
import { postStaticContentForm } from '../../../Redux/Actions/StaticContentsActions'
import QuillBody from '../Quill/QuillBody/QuillBody'
import UploadFiles from '../UploadFiles/UploadFiles'
import PreviewStaticContent from '../PreviewStaticContent/PreviewStaticContent'

interface FtTypes {
  data?: any
  title: string
  history?: any
}
const StaticContentsForm = ({ history }: FtTypes) => {
  const [statusValue, setStatusValue] = useState<boolean>(false)
  const [description, setDescription] = useState<any>()
  const [form] = Form.useForm()
  const [value1, setValue1] = useState<number>(1)
  const [slugParam, setSlugParam] = useState<string>('')
  const [formSlug, setFormSlug] = useState<string>('')
  const { contentId, slug } = useParams<any>()

  const contents = useSelector((state: any) => state.staticContents)
  const dispatch = useDispatch()
  const handleUploadFile = (e: any) => {
    form.setFieldsValue({ mediaFile: e })
  }
  const onFinish = (data: any) => {
    dispatch(
      postStaticContentForm({
        ...data,
        position: contents[formSlug]?.innerHtml.length,
        slugParam,
      })
    )
  }

  useEffect(() => {
    const checked = contents[formSlug]?.innerHtml[0]?.status
    if (checked) {
      setStatusValue(true)
    } else {
      setStatusValue(false)
    }
  }, [])

  useEffect(() => {
    const details = contents[slug ?? '']?.innerHtml?.filter(
      (d: any) => d._id === contentId
    )
    form.setFieldsValue(details && details[0])
  }, [contentId, slug])

  useEffect(() => {
    form.setFieldsValue({ description: description })
  }, [description])

  useEffect(() => {
    if (contentId !== 'add') {
      if (slug === 'factoryTour') {
        setSlugParam('factory-tour-lower')
        setFormSlug(slug)
      } else if (slug === 'howTo') {
        setSlugParam('how-to-latido-products')
        setFormSlug(slug)
      } else if (slug === 'aboutUs') {
        setSlugParam('about')
        setFormSlug(slug)
      }
    } else {
      setSlugParam(slug ?? '')
    }
  }, [slug])

  useEffect(() => {
    if (contentId === 'add') {
      if (slug === 'factory-tour-lower') {
        setFormSlug('factoryTour')
      } else if (slug === 'how-to-latido-products') {
        setFormSlug('howTo')
      } else if (slug === 'about') {
        setFormSlug('aboutUs')
      }
    }
  }, [slug])

  return (
    <Row
      gutter={[-20, 20]}
      style={{ marginTop: 30 }}
      align='top'
      justify='space-between'
    >
      <Col span={24}>
        <Typography.Title
          className='product-name'
          level={1}
          style={{ textTransform: 'uppercase' }}
        >
          {contents[formSlug]?.title}
        </Typography.Title>
      </Col>
      <Col sm={24} md={16}>
        <Card>
          <Typography.Title level={2} style={{ textTransform: 'uppercase' }}>
            {contentId === 'add' ? 'ADD' : 'EDIT'} {contents[formSlug]?.title}
          </Typography.Title>
          <Form layout='vertical' form={form} onFinish={onFinish}>
            <Row gutter={[40, 10]}>
              <Col sm={24} lg={12}>
                <Form.Item
                  label='Title'
                  name='titleText'
                  rules={[{ required: true, message: 'Title is require.' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={24} lg={12}>
                <Form.Item
                  name='subText'
                  label='Sub Text'
                  rules={[{ required: true, message: 'Sub text is require.' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={24} lg={12}>
                <Form.Item
                  name='subCategory'
                  label='Sub Category'
                  rules={[
                    {
                      required: true,
                      message: 'Sub Category is required.',
                    },
                  ]}
                >
                  <Select placeholder='Select sub category' allowClear>
                    <Select.Option value='cat 1'>cat 1</Select.Option>
                    <Select.Option value='cat 2'>cat 2</Select.Option>
                    <Select.Option value='cat 3'>cat 3</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={24} lg={24}>
                <Form.Item
                  name='status'
                  label='status'
                  rules={[{ required: true, message: 'Status is required.' }]}
                >
                  <Radio.Group
                    onChange={(e) => setValue1(e.target.value)}
                    value={value1}
                  >
                    <Radio checked={statusValue} value='Published'>
                      Published
                    </Radio>
                    <Radio checked={!statusValue} value='Draft'>
                      Draft
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name='content'
                  label='Description'
                  rules={[
                    {
                      required: true,
                      message: 'Description is required.',
                    },
                  ]}
                >
                  <QuillBody onChange={(e: any) => setDescription(e)} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name='mediaFile'>
                  <UploadFiles onChange={(e: any) => handleUploadFile(e)} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Space>
                  <Button
                    type='primary'
                    shape='round'
                    size='middle'
                    htmlType='submit'
                  >
                    Save
                  </Button>
                  <Button
                    type='text'
                    onClick={() =>
                      history.push(`/static-contents/${slugParam}`)
                    }
                  >
                    Cancel
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col style={{ height: '100%' }} sm={24} md={7}>
        <PreviewStaticContent />
      </Col>
    </Row>
  )
}

export default StaticContentsForm
