import React from "react";
import Chart from "react-google-charts";
const PieChart: React.FC = (props: any) => {
  const { totalMale, totalFemale, totalOther } = props;
  return (
    <Chart
      chartType="PieChart"
      loader={<div>Loading Chart</div>}
      data={[
        ["Gender", "Percentage over Total "],
        ["Female", totalFemale],
        ["Male", totalMale],
        ["Other", totalOther],
      ]}
      options={{
        // title: 'My Daily Activities',
        backgroundColor: "transparent",
        // Just add this option
        is3D: false,
        pieHole: 0.4,
        slices: {
          0: { color: "#FF5660", offset: 0.05 },
          1: { color: "#24CCB8", offset: 0.05 },
          2: { color: "#ffda81", offset: 0.05 },
        },
        // sliceVisibilityThreshold: 0.25
      }}
      rootProps={{ "data-testid": "2" }}
    />
  );
};
export default PieChart;
