/** @format */

import { Modal } from "antd";
import React from "react";

interface PreviewDetailModalProps {
  isModalVisible: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
}

const PreviewDetailModal: React.FC<PreviewDetailModalProps> = ({
  isModalVisible,
  handleOk = () => {},
  handleCancel = () => {},
}) => {
  return (
    <Modal
      title='Mobile View'
      visible={isModalVisible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
    >
      <p>Contents and Images</p>
    </Modal>
  );
};

export default PreviewDetailModal;
