import {
  Row,
  Col,
  Typography,
  Space,
  Select,
  Form,
  Input,
  Card,
  Modal,
  Table,
  Dropdown,
} from "antd";
import React, { useEffect, useState } from "react";
import "./Customer.scss";
import {
  DeleteOutlined,
  EditOutlined,
  EyeFilled,
  FormOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import ImageHolder from "../../Components/ImageHolder/ImageHolder";
import MediaUrl from "../../Utils/mediaUrl";
import { useNavigate } from "react-router";
import NeuMorph from "../../Components/Admin/NeuMorph/NeuMorph";
import { useMutation, useQuery } from "react-query";
import { deleteCustomer, getCustomers } from "../../API/CustomerApi";
import dayjs from "dayjs";
import { useDebounce } from "use-debounce";

function Customer() {
  const [filterText, setFilterText] = useState("");
  const [debounceFilterText] = useDebounce(filterText, 500);
  const [filter, setFilter] = useState<any>({
    page: 1,
    limit: 10,
  });
  const { data, isLoading, refetch } = useQuery(
    ["customers", { ...filter, search: debounceFilterText }],
    getCustomers
  );
  const navigate = useNavigate();
  const { mutate, isSuccess } = useMutation({
    mutationFn: deleteCustomer,
  });

  const handleDeleteIconClick = (id: string) => {
    Modal.confirm({
      title: "Are you sure?",
      icon: <DeleteOutlined />,
      content: <p>You cannot undo once you delete this customer.</p>,
      onOk: async () => {
        await mutate({ _id: id });
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      refetch({});
    }
  }, [isSuccess]);

  const ActionList = (props: any) => {
    const menu = (
      <NeuMorph>
        <Space className="content" style={{ padding: 10, alignItems: "center", display: "flex" }}>
          <EyeFilled
            onClick={() => navigate(`/customer-management/${props.userId}`)}
            style={{ fontSize: 18 }}
          />
          <EditOutlined
            onClick={() => navigate(`/customer-management/edit/${props.userId}`)}
            style={{ fontSize: 18 }}
          />
          <DeleteOutlined
            style={{ fontSize: 18 }}
            onClick={() => handleDeleteIconClick(props?.userId)}
          />
        </Space>
      </NeuMorph>
    );
    return (
      <Dropdown placement="topCenter" overlay={menu} trigger={["click"]}>
        <Typography.Text strong style={{ fontSize: 18, fontWeight: "bold", color: "black" }}>
          ...
        </Typography.Text>
      </Dropdown>
    );
  };
  const customerColumns = [
    {
      dataIndex: "profile_image",
      key: "profile_image",
      width: 50,
      render: (text: any, record: any) => (
        <ImageHolder
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "10px",
            border: "2px solid #fff",
            objectFit: "cover",
          }}
          src={MediaUrl(text)}
          height="80px"
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
        <Typography.Link onClick={() => navigate(`/customer-management/${record?.id}`)}>
          <Space direction="vertical">
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              {text}
            </Typography.Title>
          </Space>
        </Typography.Link>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Contact",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Joined Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) => <span>{dayjs(text).format("YYYY/MM/DD")}</span>,
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      width: "100",
      render: (text: any, record: any) => (
        <Space>
          <ActionList userId={record.id} />
        </Space>
      ),
    },
  ];
  return (
    <Row className="customer_page-wrapper">
      <Col lg={24}>
        <Space>
          <Typography.Title className="product-name" style={{ marginBottom: 0 }}>
            CUSTOMER LIST
          </Typography.Title>
        </Space>
        <Row
          gutter={[40, 40]}
          align="middle"
          justify="space-between"
          className="customer_page-filters"
        >
          <Col>
            <Row align="middle" gutter={[40, 40]}>
              {/* <Col>
                <Typography.Text>Show</Typography.Text>
              </Col>
              <Col>
                <Form.Item label="">
                  <Select defaultValue={"1"} style={{ width: 80 }}>
                    <Select.Option value="1">1</Select.Option>
                    <Select.Option value="2">2</Select.Option>
                    <Select.Option value="3">3</Select.Option>
                    <Select.Option value="4">4</Select.Option>
                    <Select.Option value="5">5</Select.Option>
                    <Select.Option value="6">6</Select.Option>
                  </Select>
                </Form.Item>
              </Col> */}
              <Col>
                <label htmlFor="gender">Gender</label>
                <Form.Item name="gender">
                  <Select
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        limit: 10,
                        page: 1,
                        gender: e,
                      })
                    }
                    defaultValue={""}
                    style={{ width: 180 }}
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="male">Male</Select.Option>
                    <Select.Option value="female">Female</Select.Option>
                    <Select.Option value="others">Others</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col>
              <label htmlFor="status">Status</label>
                <Form.Item name="status">
                  <Select
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        limit: 10,
                        page: 1,
                        status: e,
                      })
                    }
                    defaultValue={""}
                    style={{ width: 180 }}
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="active">Active</Select.Option>
                    <Select.Option value="inactive">In-Active</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col>
              <label htmlFor="search">Search</label>
                <Form.Item name="search">
                  <Input
                    onChange={(e) => setFilterText(e.target.value)}
                    placeholder="search"
                    suffix={<SearchOutlined />}
                    className="search-field"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col>
            <Card>
              <Row>
                <Space direction="vertical" className="customer_page-totalusers">
                  <Typography.Text>Total Users</Typography.Text>
                  <Typography.Title level={1}>{data?.data?.total_data}</Typography.Title>
                </Space>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={24}>
            <Table
              loading={isLoading}
              columns={customerColumns}
              dataSource={data?.data?.data}
              pagination={{
                total: data?.data?.total_data,
                position: ["bottomCenter"],
                onChange(page, pageSize) {
                  setFilter({
                    page: page,
                    limit: pageSize,
                  });
                },
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Customer;
