import { Reducer } from "react";
import {
  CreateProductParams,
  CREATE_PRODUCT,
  CREATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_SUCCESS,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCT,
  FETCH_PRODUCT_FAILURE,
  FETCH_PRODUCT_SUCCESS,
  REMOVE_PRODUCT,
  REMOVE_PRODUCT_FAILURE,
  REMOVE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_SUCCESS,
  ProductDetailsParams,
} from "./ProductTypes";

const INIT_STATE = {
  list: [],
  error: "",
  detailsError: "",
  details: {},
  loading: false,
  loaded: false,
  detailing: false,
  detailed: false,
  creating: false,
  created: false,
  deleting: false,
  deleted: false,
  updating: false,
  updated: false,
  pageNumber: 0,
  pageSize: 0,
  totalPages: 1,
  totalRecords: 8,
  sizeDetail: [],
};

export const ProductReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
  switch (action.type) {
    //fetch Products
    case FETCH_PRODUCTS:
      return { ...state, list: [], loaded: false, loading: true };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.payload.data,
        pageNumber: action.payload.page_no,
        pageSize: action.payload.limit,
        totalPages: action.payload.total_pages,
        totalRecords: action.payload.total_data,
      };
    case FETCH_PRODUCTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    //fetch Product Details
    case FETCH_PRODUCT:
      return { ...state, detailed: false, detailing: true };
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        detailing: false,
        detailed: false,
        details: action.payload?.productDetail || false,
        sizeDetail: action.payload?.sizeDetail || [],
      };
    case FETCH_PRODUCT_FAILURE:
      return { ...state, detailing: false, detailsError: action.payload };

    //create Product
    case CREATE_PRODUCT:
      return { ...state, created: false, creating: true, error: false };
    case CREATE_PRODUCT_SUCCESS:
      state.list.push(action.payload);
      return { ...state, creating: false, created: true };
    case CREATE_PRODUCT_FAILURE:
      return { ...state, creating: false, error: action.payload.error };

    //update Product
    case UPDATE_PRODUCT:
      return { ...state, updating: true, updated: false, error: false };
    case UPDATE_PRODUCT_SUCCESS:
      const tempList = state.list.filter((d: CreateProductParams) => d.id !== action.payload.id);
      tempList.push(action.payload);
      return { ...state, updated: true, updating: false, list: tempList };
    case UPDATE_PRODUCT_FAILURE:
      return { ...state, updating: false, error: action.payload.error };

    //remove Product
    case REMOVE_PRODUCT:
      return { ...state, deleted: false, deleting: true };
    case REMOVE_PRODUCT_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleted: true,
        list: state.list.filter((d: ProductDetailsParams) => d.id !== action.payload.id),
      };
    case REMOVE_PRODUCT_FAILURE:
      return { ...state, deleting: false };
    default:
      return { ...state };
  }
};
