import { Button, Col, Input, Pagination, Row, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import MembersList from "./MembersList";
import AddMember from "./AddMember";
import { useDispatch, useSelector } from "react-redux";
import { fetchMembers } from "../../../Redux/MemberRedux/MemberActions";
import PageLoading from "../../../Components/Admin/PageLoading/PageLoading";
import { useDebounce } from "use-debounce";
import qs from "query-string";
import { useLocation, useNavigate } from "react-router";

const { Option } = Select;

export interface filterTypes {
  search?: string;
  role?: any;
  page?: number;
  limit?: any;
  add?: boolean;
}

export const AdminRoles = [
  { value: "superAdmin", title: "Super Admin" },
  { value: "admin", title: "Admin" },
  { value: "storeManager", title: "Store Manager" },
  { value: "productManager", title: "Product Manager" },
  { value: "factoryManager", title: "Factory Manager" },
  { value: "inventoryManager", title: "Inventory Manager" },
  { value: "orderManager", title: "Order Manager" },
  { value: "karigar", title: "Karigar" },
  // { value: "unassigned", title: "Unassigned" },
];
export const Members = (props: any) => {
  const members = useSelector((state: any) => state.MemberReducer);
  const location = useLocation();
  const [filteredData, setFilteredData] = useState<filterTypes>(
    location?.search
      ? qs.parse(location?.search)
      : {
          limit: 12,
          page: 1,
          add: false,
        }
  );

  useEffect(() => {
    if (location?.search) {
    } else {
      setFilteredData(
        location?.search
          ? qs.parse(location?.search)
          : {
              limit: 12,
              page: 1,
              add: false,
            }
      );
    }

    console.log(location);
  }, [location]);

  const [filterText, setFilterText] = useState("");
  const [debounceFilterText] = useDebounce(filterText, 500);

  useEffect(() => {
    setFilteredData({ ...filteredData, search: debounceFilterText });
  }, [debounceFilterText]);

  const [roles, setRoles] = useState<any>(AdminRoles);

  const [editData, setEditData] = useState(false);

  const onEditHandler = (data: any) => {
    setEditData(data);
    // setAddMember(true);
    setFilteredData({ ...filteredData, add: true });
  };

  const dispatch = useDispatch();
  const history = useNavigate();
  useEffect(() => {
    history(`?${qs.stringify(filteredData)}`);

    if (members?.page_no != filteredData?.page || members?.limit != filteredData?.limit) {
      dispatch(fetchMembers({ ...filteredData }));
    }
  }, [filteredData]);

  // useEffect(() => {
  //   setFilteredData({ ...filteredData, ...qs?.parse(location.search) });
  // }, [location]);

  const formMember: any = qs?.parse(location.search)?.add == "true";

  const refetch = () => {
    dispatch(fetchMembers({ ...filteredData }));
  };

  useEffect(() => {
    console.log("this is required");
  }, [0]);
  return (
    <>
      <Row align="middle" justify="space-between" className="neumorphic">
        <Col lg={24}>
          <Space>
            <Typography.Title className="product-name" style={{ marginBottom: 0 }}>
              MANAGE MEMBER
            </Typography.Title>
            <Button
              onClick={() => {
                // setAddMember(true);
                setFilteredData({ ...filteredData, add: true });

                setEditData(false);
              }}
              type="primary"
              size="middle"
              shape="round"
              hidden={formMember || editData}
            >
              <span style={{ fontWeight: 100 }}>
                Add User <PlusOutlined />
              </span>
            </Button>
          </Space>
        </Col>
        {!qs?.parse(location.search)?.add && (
          <Col lg={8} md={10} hidden>
            <Row justify="end">
              <Select
                showSearch
                style={{ width: 150, marginRight: 20 }}
                placeholder="Users"
                optionFilterProp="children"
                onChange={(e: any) => setFilteredData({ ...filteredData, role: e })}
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="">All</Option>

                {roles.map((d: any) => {
                  return <Option value={d?.value}>{d?.title}</Option>;
                })}
              </Select>
              <div>
                <Input.Search
                  style={{ width: 250 }}
                  placeholder="input search text"
                  onChange={(e) => setFilterText(e.target.value)}
                />
              </div>
            </Row>
          </Col>
        )}
      </Row>
      {formMember ? (
        <AddMember
          data={editData ? editData : false}
          onCancel={() => {
            // setAddMember(false);
            setFilteredData({ ...filteredData, add: false });

            setEditData(false);
          }}
          refetch={() => {
            setFilteredData({ ...filteredData, add: false });

            setEditData(false);
            refetch();
          }}
        />
      ) : (
        <PageLoading loading={members.loading}>
          <MembersList
            onEdit={(e: any) => onEditHandler(e)}
            data={members?.list}
            filter={filteredData}
          />
        </PageLoading>
      )}
      {!formMember && members?.list.length > 0 && (
        <Row justify="center" style={{ marginTop: 40 }}>
          <Col>
            <Pagination
              onChange={(page: number) => {
                setFilteredData({ ...filteredData, page: page, add: false });
              }}
              current={filteredData?.page}
              total={members?.total_data}
              pageSize={filteredData?.limit}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
export default Members;
