import { API, Auth } from "aws-amplify";
import { ApiHeaders, INVENTORY_API_NAME } from "../../API/variable";
// import { INVENTORY_API_NAME, ApiHeaders } from 'Api/Api'

//get Vendors
export const takeVendors = async (data: any) => {
  const { limit, page, value, sortBy } = data;
  const res = await API.get(
    INVENTORY_API_NAME,
    `/vendor/vendor_list?page=${page}&limit=${limit}&search=${value}&sort=${sortBy}`,
    {
      headers: ApiHeaders,
    }
  );
  return res;
};

//get Vendor
export const takeVendor = async (id: string) => {
  const res = await API.get(INVENTORY_API_NAME, `/vendor/vendor_detail?vendor_id=${id}`, {
    headers: ApiHeaders,
  });

  return res;
};

//get Vendore
export const makeVendor = async (data: any) => {
  const res = await API.post(INVENTORY_API_NAME, "/vendor/add_vendor", {
    body: {
      ...data,
    },
    headers: ApiHeaders,
  });
  return res;
};

//delete Post
export const cutVendor = async (id: string) => {
  const res = await API.del(INVENTORY_API_NAME, `/vendor/delete_vendor?vendor_id=${id}`, {
    headers: ApiHeaders,
  });
  return res;
};
