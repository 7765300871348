import { Card, Col, Divider, Image, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import moment from "moment";
import ImageHolder from "../../Components/ImageHolder/ImageHolder";
import MediaUrl from "../../Utils/mediaUrl";

const ReportsDetails = () => {
  const location = useLocation();
  const [data, setData] = useState<any>({});

  useEffect(() => {
    const { state }: any = location;
    setData(state?.data);
  }, [location]);

  return (
    <div className="report-detail">
      <Row style={{ margin: "30px 0" }} gutter={[20, 0]}>
        <p className="page-title">Reporting Details</p>
      </Row>

      <Card style={{ padding: "30px" }}>
        <Row>
          <ImageHolder
            src={MediaUrl(data?.media?.[0])}
            className="report-detail__image"
            alt="reportin"
          />
          <div className="report-detail__title">
            <p>{data?.inventory_item?.inventory_category?.inventory_category}</p>
            <h4>{data?.inventory_item?.item_name}</h4>
          </div>
        </Row>
        <Divider
          style={{
            width: "100%",
            marginTop: 30,
            marginBottom: 50,
            boxShadow: "0px 1px 2px 1px white",
          }}
        />

        <Row gutter={[30, 30]}>
          <Col xl={6} lg={8} sm={12} xs={24}>
            <h4 className="report-detail__content-value">{data?.vendor?.vendor_name}</h4>
            <p className="report-detail__content-title">Vendor</p>
          </Col>
          <Col xl={6} lg={8} sm={12} xs={24}>
            <h4 className="report-detail__content-value">
              {moment(data?.vendor?.received_date).format("MMM DD, YYYY")}
            </h4>
            <p className="report-detail__content-title">Recived Date</p>
          </Col>
          <Col xl={6} lg={8} sm={12} xs={24}>
            <h4 className="report-detail__content-value">{data?.inventory_item?.color}</h4>
            <p className="report-detail__content-title">Color</p>
          </Col>
          <Col xl={6} lg={8} sm={12} xs={24}>
            <h4 className="report-detail__content-value">
              {data?.vendor?.contact_person?.location}
            </h4>
            <p className="report-detail__content-title">Location</p>
          </Col>
          <Col xl={6} lg={8} sm={12} xs={24}>
            <h4 className="report-detail__content-value">
              {data?.material_select?.total_quantity}
            </h4>
            <p className="report-detail__content-title">Quantity</p>
          </Col>
          <Col xl={6} lg={8} sm={12} xs={24}>
            <h4 className="report-detail__content-value">{data?.price}</h4>
            <p className="report-detail__content-title">Price</p>
          </Col>
          <Col xl={6} lg={8} sm={12} xs={24}>
            <h4 className="report-detail__content-value">{data?.quality}</h4>
            <p className="report-detail__content-title">Quality</p>
          </Col>
          <Col xl={6} lg={8} sm={12} xs={24}>
            <h4 className="report-detail__content-value">{data?.vendor?.vendor_name}</h4>
            <p className="report-detail__content-title">Inch</p>
          </Col>
          <Col xl={6} lg={8} sm={12} xs={24}>
            <h4 className="report-detail__content-value">{data?.transport_mode}</h4>
            <p className="report-detail__content-title">Transportation Mode</p>
          </Col>
          <Col xl={6} lg={8} sm={12} xs={24}>
            <h4 className="report-detail__content-value">{data?.payment_mode}</h4>
            <p className="report-detail__content-title">Payment Mode</p>
          </Col>
          <Col xl={6} lg={8} sm={12} xs={24}>
            <h4 className="report-detail__content-value">{data?.received_by}</h4>
            <p className="report-detail__content-title">Received By</p>
          </Col>
          <Col xl={6} lg={8} sm={12} xs={24}>
            <Image src={MediaUrl(data?.bill)} width={80} alt="bill" style={{ borderRadius: 5 }} />
            <p className="report-detail__content-title">Bill Attachment</p>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ReportsDetails;
