import { Auth } from "@aws-amplify/auth";
import { notification } from "antd";
import { AxiosResponse } from "axios";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
// import { history } from 'Redux/AppStore'
import { requestLoginFailure, requestLoginSuccess, setAuthUser } from "./AuthActions";
import { REQUEST_LOGIN, RequestLoginParams } from "./AuthTypes";

//get auths
const tryLoginAsync = async (data: RequestLoginParams) =>
  await Auth.signIn(data.username, data.password);

function* tryLogin(params: any) {
  const { payload } = params;
  try {
    const auths: AxiosResponse = yield call(tryLoginAsync, payload);
    if (auths) {
      const temp: any = { ...auths };
      if (temp?.challengeName === "NEW_PASSWORD_REQUIRED") {
        // history.push(`/auth/set-password/${payload.username}`)
        yield put(setAuthUser(auths));
      } else {
        yield put(requestLoginSuccess(auths));
      }
    } else {
      yield put(
        requestLoginFailure({
          title: "Login Failed",
          message: "Could not get list of auths",
        })
      );
      notification.error({
        message: "Could not login at the moment",
        placement: "bottom",
      });
    }
  } catch (error: any) {
    yield put(
      requestLoginFailure({
        title: "Login Failed",
        message: "Network Error while logging in",
      })
    );
    notification.error({
      message: "Could not login at the moment",
      placement: "bottom",
    });
  }
}

function* watchTryLogin() {
  yield takeLatest(REQUEST_LOGIN, tryLogin);
}

export default function* AuthSagas() {
  yield all([fork(watchTryLogin)]);
}
