import { CreateMemberParams, MemberDetailsParams, MemberFetchParams } from "./MemberTypes";
import { API } from "aws-amplify";
import { ApiHeaders, API_NAME } from "../../API/variable";
// import { ApiHeaders, API_NAME } from "Api/Api";

//get Member
export const takeMembers = (data: MemberFetchParams) =>
  API.get(API_NAME, "/internal_admin", {
    headers: ApiHeaders,
    queryStringParameters: data,
  });

//get Member Details
export const takeMember = (data: MemberDetailsParams) =>
  API.get(API_NAME, "/member", { headers: ApiHeaders, data: data });

//create Member Details
export const makeMember = async (data: CreateMemberParams) => {
  console.log({ data });

  const res = await API.post(API_NAME, "/user/create", {
    headers: ApiHeaders,
    body: data,
  });
  return res;
};

//modify Member
export const modifyMember = (data: any) =>
  API.post(API_NAME, "/admin/update-admin", { headers: ApiHeaders, body: data });

//delete Member
export const cutMember = (data: any) =>
  API.del(API_NAME, `/admin/${data?._id}`, { headers: ApiHeaders, data: data });

//TODO
// Disable user in cognito
// Yo state ni aauah user ko list ma
// Tesko aadhar ma list ni render gardeu na

// POST
// {email}

export const deleteMember = (data: MemberDetailsParams) =>
  API.post(API_NAME, "/user/delete", { headers: ApiHeaders, data: data });

export const UpdatePassword = (data: { email: string; password: string }) => {
  return API.put(API_NAME, "/internal_admin", {
    headers: ApiHeaders,
    body: { ...data },
  });
};
