import Auth from "@aws-amplify/auth";
import { Amplify } from "aws-amplify";
import { environment } from "./environment";

export const configureAmplify = (): void => {
  Amplify.configure({
    Auth: {
      region: environment.region,
      userPoolId: environment.userPoolId,
      userPoolWebClientId: environment.webClientId,
      mandatorySignIn: true,
      identityPoolRegion: environment.region,
      identityPoolId: environment.identityPoolId,
      authenticationFlowType: environment.authFlow,
    },
    Storage: {
      AWSS3: {
        bucket: environment.bucketName, //REQUIRED -  Amazon S3 bucket name
        region: environment.bucketRegion, //OPTIONAL -  Amazon service region
      },
    },
    API: {
      endpoints: [
        {
          name: "api",
          endpoint: environment.baseUrl,
          region: environment.region,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            };
          },
        },
        {
          name: "inventory-api",
          endpoint: environment.inventoryBaseUrl,
          region: environment.region,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            };
          },
        },
      ],
    },
  });
};
