import { notification } from "antd";
import { AxiosResponse } from "axios";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  createCustomerFailure,
  createCustomerSuccess,
  deleteCustomerFailure,
  deleteCustomerSuccess,
  fetchCustomerFailure,
  fetchCustomersFailure,
  fetchCustomersSuccess,
  fetchCustomerSuccess,
  fetchCustomerTimelineFailure,
  fetchCustomerTimelineSuccess,
  updateCustomerFailure,
  updateCustomerSuccess,
} from "./CustomerActions";
import {
  cutCustomer,
  makeCustomer,
  modifyCustomer,
  takeCustomer,
  takeCustomers,
  takeCustomerTimeline,
} from "./CustomerApis";
import {
  CreateCustomerParams,
  FETCH_CUSTOMERS,
  UPDATE_CUSTOMER,
  CREATE_CUSTOMER,
  REMOVE_CUSTOMER,
  CustomerDetailsParams,
  CustomerFetchParams,
  FETCH_CUSTOMER_DETAILS,
  FETCH_CUSTOMER_TIMELINE,
} from "./CustomerTypes";

//get customers
const getCustomersAsync = async (data: CustomerFetchParams) => await takeCustomers(data);

function* getCustomers(params: any) {
  const { payload } = params;
  try {
    const customers: AxiosResponse = yield call(getCustomersAsync, payload);
    if (customers) {
      yield put(fetchCustomersSuccess(customers?.data));
    } else {
      yield put(fetchCustomersFailure("Could not get list of customers"));
      notification.error({
        message: "Could not get list of customers",
        placement: "bottom",
      });
    }
  } catch (error) {
    yield put(fetchCustomersFailure("Network Error while getting Customers"));
    notification.error({
      message: "Network Error while getting Customers",
      placement: "bottom",
    });
  }
}

function* watchGetCustomers() {
  yield takeLatest(FETCH_CUSTOMERS, getCustomers);
}

//get customer details
const getCustomerAsync = async (data: CustomerDetailsParams) => await takeCustomer(data);

function* getCustomer(params: any) {
  const { payload } = params;
  try {
    const customers: AxiosResponse = yield call(getCustomerAsync, payload);
    if (customers) {
      yield put(fetchCustomerSuccess(customers?.data));
    } else {
      yield put(fetchCustomerFailure("Could not get list of customers"));
      notification.error({
        message: "Could not get list of customers",
        placement: "bottom",
      });
    }
  } catch (error) {
    yield put(fetchCustomerFailure("Network Error while getting Customers"));
    notification.error({
      message: "Network Error while getting Customers",
      placement: "bottom",
    });
  }
}

function* watchGetCustomer() {
  yield takeLatest(FETCH_CUSTOMER_DETAILS, getCustomer);
}

//get customer timeline details
const getCustomerTimelineAsync = async (data: CustomerDetailsParams) =>
  await takeCustomerTimeline(data);

function* getCustomerTimeline(params: any) {
  const { payload } = params;
  try {
    const customers: AxiosResponse = yield call(getCustomerTimelineAsync, payload);
    if (customers) {
      yield put(fetchCustomerTimelineSuccess(customers?.data));
    } else {
      yield put(fetchCustomerTimelineFailure("Could not get list of customers"));
      notification.error({
        message: "Could not get list of customers",
        placement: "bottom",
      });
    }
  } catch (error) {
    yield put(fetchCustomerTimelineFailure("Network Error while getting Customers"));
    notification.error({
      message: "Network Error while getting Customers",
      placement: "bottom",
    });
  }
}

function* watchGetCustomerTimeline() {
  yield takeLatest(FETCH_CUSTOMER_TIMELINE, getCustomerTimeline);
}

//create Customer
const generateCustomerAsync = async (data: CreateCustomerParams) => await makeCustomer(data);

function* generateCustomer(params: any) {
  const { payload } = params;
  try {
    const customers: AxiosResponse = yield call(generateCustomerAsync, payload);
    if (customers) {
      yield put(createCustomerSuccess(customers.data));
      notification.success({
        message: "Customer created successfully",
        placement: "bottom",
      });
    } else {
      const message = "Could not create Customer";
      yield put(createCustomerFailure(message));
      notification.error({
        message: message,
        placement: "bottom",
      });
    }
  } catch (error) {
    const message = "Network Error while creating Customer";
    yield put(createCustomerFailure(message));
    notification.error({
      message: message,
      placement: "bottom",
    });
  }
}

function* watchGenerateCustomer() {
  yield takeLatest(CREATE_CUSTOMER, generateCustomer);
}

//update Customer
const amendCustomerAsync = async (data: any) => await modifyCustomer(data);

function* amendCustomer(params: any) {
  const { payload } = params;
  try {
    const customers: AxiosResponse = yield call(amendCustomerAsync, payload);
    if (customers) {
      yield put(updateCustomerSuccess(customers.data));
      notification.success({
        message: "Customer updated successfully",
        placement: "bottom",
      });
    } else {
      const message = "Could not Update Customer";
      yield put(updateCustomerFailure(message));
      notification.error({
        message: message,
        placement: "bottom",
      });
    }
  } catch (error) {
    const message = "Network Error while updating Customer";
    yield put(updateCustomerFailure(message));
    notification.error({
      message: message,
      placement: "bottom",
    });
  }
}

function* watchAmendCustomer() {
  yield takeLatest(UPDATE_CUSTOMER, amendCustomer);
}

//Delete Customer
const removeCustomerAsync = async (data: CustomerDetailsParams) => await cutCustomer(data);

function* removeCustomer(params: any) {
  const { payload } = params;
  try {
    const customers: AxiosResponse = yield call(removeCustomerAsync, payload);
    if (customers) {
      yield put(deleteCustomerSuccess(customers.data));
      notification.success({
        message: "Customer deleted successfully",
        placement: "bottom",
      });
    } else {
      const message = "Could not delete Customer";
      yield put(deleteCustomerFailure(message));
      notification.error({
        message: message,
        placement: "bottom",
      });
    }
  } catch (error) {
    const message = "Network Error while deleting Customer";
    yield put(deleteCustomerFailure(message));
    notification.error({
      message: message,
      placement: "bottom",
    });
  }
}

function* watchRemoveCustomer() {
  yield takeLatest(REMOVE_CUSTOMER, removeCustomer);
}

export default function* CustomerSagas() {
  yield all([
    fork(watchGetCustomers),
    fork(watchGetCustomer),
    fork(watchGenerateCustomer),
    fork(watchAmendCustomer),
    fork(watchRemoveCustomer),
    fork(watchGetCustomerTimeline),
  ]);
}
