import { Card, Col, Descriptions, Row, Space, Typography } from "antd";
import Jacket from "../../../Assets/Products/jacket.png";
import React from "react";
import { ReactComponent as OrderSlipFilled } from "Assets/Icons/OrderSlipFilled.svg";
import { ReactComponent as ArrowRightCircle } from "Assets/Icons/ArrowRightCircle.svg";
import Title from "antd/lib/typography/Title";
import { Content } from "antd/lib/layout/layout";
import ColorBox from "../ColorBox/ColorBox";
import { defaultFunction, defaultFunctionArg } from "Redux/Constants/constants";

export interface CustomerOrder {
  _id?: string;
  product_specification?: ProductSpecification;
  assignee?: Assignee;
  shipping_details?: ShippingDetails;
  client_order_status?: ClientOrderStatus;
  pollyfill?: boolean;
  rib?: boolean;
  tools_selection_completed?: boolean;
  assignee_completed?: boolean;
  payment_mode?: PaymentMode;
  order_manage_process?: OrderManageProcess;
  delivery_date?: Date;
  is_deleted?: boolean;
  location?: Location;
  completion_process?: CompletionProcess;
  payment_status?: PaymentStatus;
  call_state?: CallState;
  for_store?: boolean;
  from_store?: boolean;
  gift?: boolean;
  user_id?: any;
  product?: Product;
  sizing?: Sizing;
  hardware?: Hardware;
  lining?: Lining;
  special_request?: string;
  advance_amount?: number;
  total_discount?: number;
  total_amount?: number;
  createdBy?: CreatedBy;
  inventory_item?: Item[];
  leather_item?: Item[];
  createdAt?: Date;
  updatedAt?: Date;
  order_no?: number;
  __v?: number;
  id?: string;
}

export interface Assignee {
  pattern_selection?: PatternSelection[];
  leather_cutting?: LeatherCutting[];
  checking_verification?: CheckingVerification[];
  stitching?: Stitching[];
  cleaning?: Cleaning[];
}

export interface CheckingVerification {
  _id?: string;
  inspector_name?: string;
  date?: Date;
}

export interface Cleaning {
  _id?: string;
  cleaner_name?: string;
  date?: Date;
}

export interface LeatherCutting {
  _id?: string;
  leather_cutter_name?: string;
  date?: Date;
}

export interface PatternSelection {
  _id?: string;
  pattern_selector_name?: PatternSelectorName;
  date?: Date;
}

export enum PatternSelectorName {
  The63Aebb61E6B7Df000849F7A9 = "63aebb61e6b7df000849f7a9",
  The63Aebb8Fe6B7Df000849F7Ad = "63aebb8fe6b7df000849f7ad",
  The63Aebcc5E6B7Df000849F7C4 = "63aebcc5e6b7df000849f7c4",
}

export interface Stitching {
  payment?: boolean;
  _id?: string;
  seamstress_name?: string;
  date?: Date;
}

export enum CallState {
  Messaged = "Messaged",
  NotCalled = "not_called",
}

export enum ClientOrderStatus {
  Order = "order",
}

export enum CompletionProcess {
  Delivered = "delivered",
  Ordered = "ordered",
}

export enum CreatedBy {
  The63Aec4Bbeff1900008Bc6E48 = "63aec4bbeff1900008bc6e48",
  The63Aec516Eff1900008Bc6E50 = "63aec516eff1900008bc6e50",
  The63B26833F009E8000820A164 = "63b26833f009e8000820a164",
}

export enum Hardware {
  GoldenAntique = "Golden Antique",
  Silver = "Silver",
}

export interface Item {
  _id?: string;
  item_id?: string;
  quantity?: number;
  leather_quantity?: number;
}

export enum Lining {
  Black = "Black",
}

export enum Location {
  Factory = "factory",
  Store = "store",
}

export enum OrderManageProcess {
  Assigned = "assigned",
  Completed = "completed",
}

export enum PaymentMode {
  CashOnDelivery = "cash_on_delivery",
}

export enum PaymentStatus {
  Incomplete = "incomplete",
  PaymentComplete = "payment_complete",
}

export interface Product {
  _id?: string;
  primary_image?: string;
  name?: string;
  slug?: string;
}

export interface ProductSpecification {
  status?: boolean;
  secondary_image?: any[];
  product_specification_id?: string;
  leather_id?: LeatherID;
  size?: number;
  size_range_id?: string;
}

export interface LeatherID {
  _id?: string;
  item_name?: string;
  latido_code?: string;
  brand?: Brand;
  color?: string;
  icon?: string;
}

export enum Brand {
  Akm = "AKM",
  Sheep = "Sheep",
}

export interface ShippingDetails {
  full_name?: string;
  phone_number?: string;
  email?: string;
  country?: string;
  city?: string;
  address?: string;
  landmark?: string;
}

export interface Sizing {
  front?: string;
  shoulder?: number;
  waist?: number;
  length?: number;
  arms?: number;
  hips?: number;
  chest?: number;
  weight?: number;
  sleeves?: number;
  body_type?: BaseSize;
  base_size?: BaseSize;
  note?: string;
  height?: number;
}

export enum BaseSize {
  Fit = "fit",
  Loose = "loose",
}

export interface OrderItemTypes {
  price?: boolean;
  gift?: boolean;
  item?: any;
  onDetail?: () => void;
  onClick?: (e: any) => void;
  data?: CustomerOrder;
}
const defaultButtonPress = () => {};
export const UsersOrderItem: React.FC<OrderItemTypes> = ({
  data,
  onClick = defaultFunctionArg,
}) => {
  const descriptionStyle = {
    labelStyle: {
      width: "50%",
    },
    contentStyle: {
      width: "50%",
      display: "flex",
      justifyContent: "flex-end",
      fontWeight: 700,
    },
  };
  return (
    <Content
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
      onClick={() => onClick(data)}
    >
      <Content style={{ marginTop: 30, height: 200, marginBottom: 30, overflow: "hidden" }}>
        <img width="100%" src={data?.product?.primary_image || Jacket} alt={"product"} />
      </Content>
      <Row
        justify="center"
        align="middle"
        style={{ flexDirection: "column", marginTop: 20, width: "100%" }}
      >
        <Title className="product-name" level={3}>
          {data?.product?.name}
        </Title>
        <Col lg={18}>
          <Descriptions
            column={1}
            style={{ marginTop: 20 }}
            className="table-auto"
            {...descriptionStyle}
          >
            <Descriptions.Item label="Size">
              {data?.product_specification?.size || "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Color">
              <ColorBox color={data?.hardware} />
            </Descriptions.Item>
            <Descriptions.Item label="Quantity">{"N/A"}</Descriptions.Item>
            <Descriptions.Item label="Price">
              <Typography.Title className="product-name" level={5} style={{ fontWeight: "bold" }}>
                {data?.total_amount}
              </Typography.Title>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Content>
  );
};

export default UsersOrderItem;
