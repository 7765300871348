import { API } from 'aws-amplify'
import { ApiHeaders, API_NAME } from './variable'

export const getMediaUrl = (params: any) =>
  API.get(API_NAME, '/bucket/put-signed-url', {
    headers: ApiHeaders,
    queryStringParameters: params,
  })

export const postMedia = (params: any) =>
  API.put('', params.url, { headers: ApiHeaders, data: params.data })
