import { Input, Select } from "antd";
import { useQuery } from "react-query";
import BlogCard from "../BlogCard/BlogCard";
import { getBlogForDropdown } from "../../../Redux/BlogRedux/BlogApis";
import { useState } from "react";
import { useDebounce } from "use-debounce";

const ProductBlogAssociation = ({ images, details, updating, onFinish, noEdit = false }: any) => {
  const [searchKey, setSearchKey] = useState("");

  const [debounceSearchKey] = useDebounce(searchKey, 500);
  const { data } = useQuery(
    ["blogsDropdown", { limit: 10, page: 1, search: debounceSearchKey }],
    getBlogForDropdown
  );

  const blogCardSelect = (d: any) => {
    onFinish(
      {
        _id: details?._id,
        associated_blog: d._id,
      },
      true
    );
  };

  const blogCardUnselect = () => {
    // set associated_blog as null by sending empty object
    onFinish({}, true);
  };

  return (
    <div>
      <Input.Search
        onChange={(e) => setSearchKey(e.target.value)}
        placeholder="Search blog by title"
        style={{ width: 400 }}
      />
      {data?.data?.list?.map((d: any) => {
        return (
          <BlogCard
            title={d?.title}
            isAssigned={d?._id === details?.associated_blog}
            onClickSelect={() => blogCardSelect(d)}
            onClickUnselect={blogCardUnselect}
          />
        );
      })}
      {/* <Select
      style={{width: 500}}
      showSearch
      defaultValue={details?.associated_blog?._id}
      onChange={(e)=> {
        onFinish({
          _id: details?._id,
          associated_blog: e,
        });
      }}
      options={options} /> */}
    </div>
  );
};

export default ProductBlogAssociation;
