import { Reducer } from "react";
import {
  CreateBlogParams,
  CREATE_BLOG,
  CREATE_BLOG_FAILURE,
  CREATE_BLOG_SUCCESS,
  FETCH_BLOGS,
  FETCH_BLOGS_FAILURE,
  FETCH_BLOGS_SUCCESS,
  FETCH_BLOG_DETAILS,
  FETCH_BLOG_DETAILS_FAILURE,
  FETCH_BLOG_DETAILS_SUCCESS,
  REMOVE_BLOG,
  REMOVE_BLOG_FAILURE,
  REMOVE_BLOG_SUCCESS,
  UPDATE_BLOG,
  UPDATE_BLOG_FAILURE,
  UPDATE_BLOG_SUCCESS,
  BlogDetailsParams,
} from "./BlogTypes";

const INIT_STATE = {
  list: [],
  error: "",
  detailsError: "",
  details: {},
  loading: false,
  loaded: false,
  detailing: false,
  detailed: false,
  creating: false,
  created: false,
  deleting: false,
  deleted: false,
  updating: false,
  updated: false,
  total: 0,
  page: 1,
};

export const BlogReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
  switch (action.type) {
    //fetch Blogs
    case FETCH_BLOGS:
      return { ...state, loading: true };
    case FETCH_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.payload.list,
        total: action.payload.total_data,
        current: action.payload.page_no,
      };
    case FETCH_BLOGS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    //fetch Blog Details
    case FETCH_BLOG_DETAILS:
      return { ...state, detailing: true, created: false };
    case FETCH_BLOG_DETAILS_SUCCESS:
      return { ...state, detailing: false, details: action.payload };
    case FETCH_BLOG_DETAILS_FAILURE:
      return { ...state, detailing: false, detailsError: action.payload };

    //create Blog
    case CREATE_BLOG:
      return { ...state, creating: true, created: false };
    case CREATE_BLOG_SUCCESS:
      state.list.push(action.payload);
      return { ...state, creating: false, created: true };
    case CREATE_BLOG_FAILURE:
      return { ...state, creating: false, created: false };

    //update Blog
    case UPDATE_BLOG:
      return { ...state, updating: true };
    case UPDATE_BLOG_SUCCESS:
      const tempList = state.list.filter((d: CreateBlogParams) => d._id != action.payload.id);
      tempList.push(action.payload);
      return { ...state, updating: false, list: tempList };
    case UPDATE_BLOG_FAILURE:
      return { ...state, updating: false };

    //remove Blog
    case REMOVE_BLOG:
      return { ...state, deleting: true };
    case REMOVE_BLOG_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: state.list.filter((d: BlogDetailsParams) => d.id != action.payload.id),
      };
    case REMOVE_BLOG_FAILURE:
      return { ...state, deleting: false };

    default:
      return { ...state };
  }
};
