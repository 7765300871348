import { Button, Col, Row, Space, Typography } from "antd";
import MyContent from "../Content/Content";
import { useNavigate } from "react-router";
import { defaultFunction } from "../../../Redux/Constants/constants";
import "./PageHeader.scss";
export interface PageHeaderProps {
  title: string;
  module: string;
  extra?: any;
  onAdd?: () => void;
  defaultAdd?: boolean;
  buttonText?: string;
}
export const PageHeader = ({
  title,
  module,
  extra,
  onAdd = defaultFunction,
  defaultAdd = true,
  buttonText,
}: PageHeaderProps) => {
  const history = useNavigate();
  return (
    <MyContent style={{ marginBottom: 40 }}>
      <Row justify="space-between">
        <Col>
          <Space align="center">
            <Typography.Title
              style={{
                textTransform: "uppercase",
                fontWeight: 600,
                marginBottom: 0,
              }}
            >
              {title}
            </Typography.Title>
            <Button
              className="add__button"
              type="primary"
              size="middle"
              shape="round"
              onClick={() => (defaultAdd ? history(`/${module}/add`) : onAdd())}
            >
              {buttonText || "Add New +"}
            </Button>
          </Space>
        </Col>
        <Col>{extra}</Col>
      </Row>
    </MyContent>
  );
};

export default PageHeader;
