import React from "react";

import landscapeImg from "../../Assets/Icons/landscape.png";
export default function NotAllowedView() {
  return (
    <div
      id="landscapePage"
      style={{
        position: "absolute",
        backgroundColor: "#000000e0",
        width: "100%",
        height: "100%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
      }}
    >
      <div
        className="center"
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "120px", marginBottom: 50 }} src={landscapeImg} alt="" />
        <p>
          This is an Internal Suite of Latido, Designed for{" "}
          <span style={{ color: "#04a2dc" }}>Desktop</span> and{" "}
          <span style={{ color: "#04a2dc" }}>Tablets</span> only. Please access it from another
          device.
        </p>
      </div>
    </div>
  );
}
