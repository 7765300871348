import { PaperClipOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Typography, Upload } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import axios from 'axios'
import { getMediaUrl } from '../../../../../API/MediaApi'
import './BlogAudio.scss'
export const BlogAudio = (props: any) => {
  const beforeUpload = (file: any) => {
    // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    getMediaUrl({ filePath: 'blogAudio', fileType: file.type })
      .then((data: any) => {
        uploadFile({ file: file, urls: data })
        return true
      })
      .catch((error: any) => {
        return false
      })

    return true
  }

  const uploadFile = (data: any) => {
    axios.put(data?.urls?.signedUrlPut, data?.file).then((d: any) => {
      handleReplaceData(data?.urls?.filePathURL)
    })
  }

  const handleReplaceData = (data: any) => {
    let newData: any = {}
    newData.playlist = [data]
    props.onChange(newData)
  }
  return (
    <Content className='audio-block'>
      <Row align='middle' gutter={[20, 20]}>
        <Col lg={12}>
          <Typography.Text>Upload Audio</Typography.Text>
        </Col>
        <Col lg={12}>
          <Upload
            beforeUpload={(e: any) => beforeUpload(e)}
            style={{ width: '100%' }}
            className='block-upload'
          >
            <Button
              block
              icon={
                <PaperClipOutlined style={{ transform: 'rotate(45deg)' }} />
              }
            >
              Upload Audio Files
            </Button>
          </Upload>
        </Col>
        <Col lg={12}>
          <Typography.Text>Caption</Typography.Text>
        </Col>
        <Col lg={12}>
          <Input />
        </Col>
      </Row>
    </Content>
  )
}

export default BlogAudio
