import { Col, Input, Pagination, Row, Typography, Select, Form, Empty } from "antd";
import React, { useEffect, useState } from "react";

import { SingleItems } from "./singleItems";
import { useDispatch, useSelector } from "react-redux";
import { CombineReducerType } from "../../Redux/Reducers/rootReducers";
import { fetchInventoryItemTrack } from "../../Redux/InventoryItem/InventoryItemActions";
import { inventoryItemTrackDataType } from "../../Redux/InventoryItem/InventoryItemTypes";
import { LatidoAnimatedLogoLoader } from "../../Components/LatidoAnimateLogo/LatidoAnimateLogo";
import { useDebounce } from "use-debounce";
import { history } from "../../Redux/AppStore";
import QueryString from "qs";
import { useSearchParams } from "react-router-dom";

export const ReportList = () => {
  const qr: any = QueryString.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });

  const [queryParameters] = useSearchParams();
  const [page, setPage] = useState(qr?.page ? parseInt(qr?.page || "1") : 1);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState<string>("-1");
  const { inventoryItemTrack, loading } = useSelector(
    (state: CombineReducerType) => state?.InventoryItemReducer
  );

  const dispatch = useDispatch();

  const [debounceSearch] = useDebounce(searchText, 500);

  useEffect(() => {
    setPage(1);
  }, [debounceSearch]);

  useEffect(() => {
    setPage(parseInt(queryParameters.get("page") || "1"));
  }, [queryParameters.get("page")]);

  useEffect(() => {
    dispatch(
      fetchInventoryItemTrack({
        searchText: debounceSearch,
        page: page,
        limit: 10,
        sortBy: sortBy,
      })
    );
  }, [page, sortBy, debounceSearch, page]);

  const handleChange = (value: string) => {
    setSortBy(value);
  };
  return (
    <>
      <Row style={{ margin: "30px 0" }} gutter={[20, 0]}>
        <Col
          span={15}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography.Title
            className="product-name"
            style={{
              marginTop: "13px",
            }}
          >
            REPORTING
          </Typography.Title>
        </Col>
        <Col
          span={4}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Form.Item label="">
            <Select defaultValue="-1" style={{ width: 120 }} onChange={handleChange}>
              <Select.Option value="1">Oldest First </Select.Option>
              <Select.Option value="-1">Newest First </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Input.Search
            placeholder="Search Report"
            size="middle"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Col>
      </Row>
      <Row style={{ width: "100%" }} align="middle" justify="center">
        <Col hidden={inventoryItemTrack?.data?.length > 0 || loading}>
          <Empty description="No data found" />
        </Col>
        {loading ? (
          // <Spin />
          <LatidoAnimatedLogoLoader />
        ) : (
          inventoryItemTrack?.data?.map((item: inventoryItemTrackDataType) => {
            return <SingleItems key={item?.id} data={item} />;
          })
        )}
      </Row>
      {!loading && (
        <Row style={{ marginTop: 30 }} justify="center">
          <Col>
            <Pagination
              showTotal={(total, range) => (
                <Typography.Text>
                  {range[0]} - {range[1]} of <b>{total}</b>
                </Typography.Text>
              )}
              defaultCurrent={page}
              total={inventoryItemTrack?.total_data}
              onChange={(e) => {
                history.push(`/inventory/reporting?page=${e}`);

                setPage(e);
              }}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
