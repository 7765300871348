import { queryKeys } from "constant/keys";
import { useMutation, useQuery } from "react-query";
import { API } from "aws-amplify";
import { API_NAME, ApiHeaders } from "API/variable";

interface IConversionRateResponseDTO {
    data: {
        conversion_rate: number;
    };
    message: string;
}

interface IConversionRatePostDTO {
    conversion_rate: number
}

const getConversionRate = (): Promise<IConversionRateResponseDTO> => {
    return API.get(API_NAME, "/admin/conversion-rate", {
        headers: ApiHeaders,
    });
}

const updateConversionRate = (payload: IConversionRatePostDTO): Promise<IConversionRateResponseDTO> => {
    return API.post(API_NAME, "/admin/conversion-rate-update", {
        headers: ApiHeaders,
        body: {
            conversion_rate: payload.conversion_rate
        }
    });
}


export const useConversionRate = () => {
    const mutation = useMutation({
        mutationKey: [queryKeys.EXCHANGE_RATE_MUTATION],
        mutationFn: (payload: IConversionRatePostDTO) => updateConversionRate(payload)
    });


    const data = useQuery({
        queryKey: [queryKeys.EXCHANGE_RATE],
        queryFn: () => getConversionRate()
    });




    return { conversionRate: data, addConversionData: mutation }
}
