import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Pagination,
  Popconfirm,
  Popover,
  Result,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import MyContent from "../../../Components/Admin/Content/Content";
import PageHeader from "../../../Components/Admin/PageHeader/PageHeader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { deleteProduct, fetchProducts } from "../../../Redux/ProductRedux/ProductActions";
import { FilterType } from "../../../Types/Types";
import { useDebounce } from "use-debounce";
import ProductItemCard from "./Components/ProductItemCard/ProductItemCard";
import { LatidoAnimatedLogoLoader } from "../../../Components/LatidoAnimateLogo/LatidoAnimateLogo";
import qs from "query-string";
import LinesEllipsis from "react-lines-ellipsis";
import Loading from "../../../Components/Admin/Loading/Loading";
import DatePicker from "antd/lib/date-picker";

import {
  UnorderedListOutlined,
  AppstoreOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  SearchOutlined,
  EditOutlined,
} from "@ant-design/icons";
import LatidoTable from "../../../Components/Admin/Table/Table";
import Search from "antd/lib/transfer/search";
import PageLoading from "../../../Components/Admin/PageLoading/PageLoading";
import axios from "axios";
import { useConversionRate } from "./hooks/useConversionRate";
import { useForm } from "antd/lib/form/Form";

export const ProductList = (props: any) => {
  const products = useSelector((state: any) => state.ProductReducer);
  const location = useLocation();
  const [view, setView] = useState<any>(qs.parse(location?.search)?.view || "card");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const [form] = useForm();

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
    setIsEditable(false);

    const conversionRateForm = Number(form.getFieldValue("conversion_rate"));

    if (conversionRateForm && conversionRateForm !== conversionRate.data?.data.conversion_rate) {
      const { data, message } = await addConversionData.mutateAsync({
        conversion_rate: Number(conversionRateForm),
      });

      if (data) {
        notification.success({ message: "Exchange rate updated", placement: "top" });
      } else {
        notification.error({ message: message, placement: "top" });
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsEditable(false);
  };

  const [filter, setFilter] = useState<FilterType>(
    location?.search
      ? qs.parse(location?.search)
      : {
          limit: 12,
          page: 1,
        }
  );

  useEffect(() => {
    setFilter({ ...filter, view: view });
  }, [view]);

  const [filterText, setFilterText] = useState("");
  const [debounceFilter] = useDebounce(filterText, 1000);
  const dispatch = useDispatch();

  const history = useNavigate();

  const { loading, list, totalRecords } = products;

  const { addConversionData, conversionRate } = useConversionRate();

  //Fetch data with filter
  const fetchWithFilter = (data: any) => {
    if (data?.search && data?.search.length > 1) {
      dispatch(fetchProducts({ ...filter, ...data, page: 1 }));
    } else {
      dispatch(fetchProducts({ ...filter, ...data }));
    }
    // if (products?.pageNumber != filter?.page || products?.pageSize != filter?.limit) {
    // }
  };

  //edit button click handler
  const onEditHandler = (data: any) => {
    history(`product/edit/${data?.id}/edit`);
  };

  const onDeleteHandler = (id: number) => {
    dispatch(deleteProduct({ id: id }));
  };

  useEffect(() => {
    history(`?${qs.stringify(filter)}`);

    if (filter) {
      if (debounceFilter && debounceFilter?.length > 1) {
        fetchWithFilter({ ...filter, search: debounceFilter });
      } else {
        fetchWithFilter({ ...filter });
      }
    }
  }, [filter, debounceFilter]);

  const defaultConversionRate = conversionRate?.data?.data.conversion_rate;

  return (
    <>
      <PageHeader
        module="product"
        title="Product List"
        extra={
          <Space>
            {/* <DatePicker />
            <DatePicker /> */}
            <Search
              value={filterText}
              placeholder="Search"
              onChange={(e: any) => setFilterText(e.currentTarget?.value)}
            />
            <div className="order-list">
              <div className="icon-card-container">
                <Tooltip placement="top" title="List">
                  <Card
                    className={`icon-card white-border ${view === "list" ? "selected" : ""}`}
                    onClick={() => setView("list")}
                  >
                    <UnorderedListOutlined className={`icon`} />
                  </Card>
                </Tooltip>
                <Tooltip placement="top" title="Grid">
                  <Card
                    className={`icon-card white-border ${view === "card" ? "selected" : ""}`}
                    onClick={() => setView("card")}
                  >
                    <AppstoreOutlined className={`icon`} />
                  </Card>
                </Tooltip>
              </div>
            </div>
          </Space>
        }
      />

      {defaultConversionRate ? (
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            type="primary"
            size="middle"
            shape="round"
            onClick={showModal}
            style={{
              display: "flex",
              marginBottom: "2rem",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            Exchange Rate
            <EditOutlined />
          </Button>
        </Row>
      ) : null}
      {/* <div
        style={{
          display: "flex",
          margin: "10px 0",
          cursor: "pointer",
        }}
        onClick={showModal}
        id="ChangePasswordButton"
      >
        Exchange Rate
      </div> */}

      <Modal title="Exchange Rate" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          style={{ maxWidth: 600, width: "100%", display: "flex" }}
        >
          <Form.Item
            label="Dollar Exchange Rate"
            name="conversion_rate"
            initialValue={defaultConversionRate}
            rules={[{ required: true }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ width: "100%" }}
          >
            <Row gutter={0} style={{ display: "flex" }}>
              <Col flex="auto">
                <Input
                  accept="number"
                  type="number"
                  disabled={!isEditable}
                  defaultValue={defaultConversionRate}
                  style={{
                    borderRadius: "4px 0 0 4px",
                  }}
                />
              </Col>
              <Col>
                <Popconfirm
                  title="Are you sure, you want to edit exchange rate?"
                  onConfirm={handleEditClick}
                >
                  <Button
                    style={{
                      borderRadius: "0 4px 4px 0",
                      border: "0.25px solid #b5b5b566",
                      borderLeft: "0",
                    }}
                  >
                    <EditOutlined />
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <MyContent>
        {loading && view === "list" && (
          <div>
            <Loading fullScreen />
          </div>
        )}
        {view === "list" && (
          <LatidoTable
            dataSource={list}
            columns={[
              {
                title: "#",
                dataIndex: "name",
                render: (value: any, record: any, index: any) => {
                  return (filter?.limit || 0) * (filter?.page ? filter?.page - 1 : 0) + index + 1;
                },
              },
              {
                title: "Name",
                dataIndex: "name",
                render: (text: any, row: any) => (
                  <Typography.Link
                    onClick={() => {
                      history(`/product/${row?._id}`);
                    }}
                  >
                    {text}
                  </Typography.Link>
                ),
              },

              {
                title: "Description",
                dataIndex: "description",
                width: 300,
                render: (text: any, record: any) => (
                  <Popover
                    trigger="hover"
                    content={text}
                    style={{ width: 300 }}
                    overlayStyle={{ width: 300 }}
                  >
                    <LinesEllipsis text={text} maxLine={3} />
                  </Popover>
                ),
              },
              {
                title: "Gender",
                dataIndex: "gender",
              },
              {
                title: "Hardware",
                dataIndex: "hardware",
                render(value: any, record: any, index: any) {
                  return value?.join(", ");
                },
              },
              {
                title: "Lining",
                dataIndex: "lining",
                render(value: any, record: any, index: any) {
                  return value?.join(", ");
                },
              },
              {
                title: "Pollyfill",
                dataIndex: "pollyfill",
                render(value: any, record: any, index: any) {
                  return value ? (
                    <CheckCircleFilled style={{ color: "green", fontSize: 24 }} />
                  ) : (
                    <CloseCircleFilled style={{ color: "red", fontSize: 24 }} />
                  );
                },
              },
              {
                title: "Rib",
                dataIndex: "rib",
                render(value: any, record: any, index: any) {
                  return value ? (
                    <CheckCircleFilled style={{ color: "green", fontSize: 24 }} />
                  ) : (
                    <CloseCircleFilled style={{ color: "red", fontSize: 24 }} />
                  );
                },
              },
            ]}
            pagination={false}
            loading={false}
          />
        )}
        {view === "card" && (
          <div style={{ minHeight: "80vh" }}>
            {list?.length === 0 || loading ? (
              <>
                {!loading ? (
                  <Result
                    status={404}
                    title="PRODUCTS NOT FOUND"
                    subTitle={
                      <Typography.Text>
                        Unfortunately, Products are not available. Please try again with different
                        filter or{" "}
                        <Typography.Link
                          onClick={() => {
                            setFilter({
                              limit: 12,
                              page: 1,
                            });
                            setFilterText("");
                          }}
                        >
                          RESET FILTER
                        </Typography.Link>
                      </Typography.Text>
                    }
                  />
                ) : (
                  <Row justify="center" style={{ width: "100%" }}>
                    {/* <Spin size='large' /> */}
                    <LatidoAnimatedLogoLoader />
                  </Row>
                )}
              </>
            ) : (
              <Row gutter={[20, 20]} align="stretch">
                {list?.map((product: any) => {
                  return (
                    <Col lg={6}>
                      <ProductItemCard data={product} />
                    </Col>
                  );
                })}
              </Row>
            )}
          </div>
        )}

        {list && list.length > 0 && (
          <Row justify="center" style={{ marginTop: 20 }}>
            <Col>
              <Pagination
                onChange={(page: number, pageSize?: number) => {
                  setFilter({ ...filter, page: page, limit: pageSize });
                }}
                total={totalRecords}
                pageSize={filter.limit}
                pageSizeOptions={[10, 12, 20, 50, 100]}
                current={products?.pageNumber}
                defaultPageSize={12}
              />
            </Col>
          </Row>
        )}
      </MyContent>
    </>
  );
};

export default ProductList;
