import { Card, Descriptions } from "antd";
import React from "react";
export interface BodyMeasure {
  fashion_for: string;
  arms: string;
  height: string;
  collars: string;
  weight: string;
  armhole: string;
  shoulder: string;
  jacket_size: string;
  sleeves: string;
  cuff: string;
  age: string;
  waist: string;
  chest: string;
  neck: string;
}

const bodyKeys = [
  // "fashion_for",
  "arms",
  "height",
  "collars",
  "weight",
  "armhole",
  "shoulder",
  // "jacket_size",
  "sleeves",
  "cuff",
  // "age",
  "waist",
  "chest",
  "neck",
];
export const UserMeasurement = (props: any) => {
  // const keys = Object.keys(props?.data) as Array<keyof BodyMeasure>;
  const keys = Object.keys(props?.data || {});
  console.log(props, "measurement data");
  return (
    <Card>
      <Descriptions
        column={2}
        colon={false}
        labelStyle={{
          textTransform: "uppercase",
          fontSize: "16x",
          minWidth: "50%",
          margin: "20px 0px",
        }}
        contentStyle={{
          fontWeight: "bold",
          fontSize: "16px",
          color: "black",
          margin: "20px 0px",
        }}
      >
        {bodyKeys?.map((key) => {
          return (
            <Descriptions.Item span={1} label={key}>
              {props?.data?.[key] || "N/A"}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    </Card>
  );
};

export default UserMeasurement;
