/** @format */

import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from 'antd'
import QuillBody from '../../../../Components/Admin/Quill/QuillBody/QuillBody'
import UploadFiles from '../../../../Components/Admin/UploadFiles/UploadFiles'
import { FC, useState } from 'react'
import { ReactComponent as PlacementButtonIcon } from '../../../../Assets/Icons/PlacementButtonIcon.svg'
import Type1 from '../../../../Assets/feature product/Type1.png'
import Type2 from '../../../../Assets/feature product/Type2.png'
import Type3 from '../../../../Assets/feature product/Type3.png'
import Type4 from '../../../../Assets/feature product/Type4.png'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

const formHalfLayout = {
  labelCol: {
    md: { span: 12 },
  },
  wrapperCol: {
    md: { span: 12 },
  },
}
const menuStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}
interface stateType {
  history?: any
  onInitialFormState?: (e: string) => void
  onTitle?: (e: string) => void
  onDescription?: (e: string) => void
  title?: any
  description?: any
  placementFormValues?: (e: any) => void
}
const FeatureProductForm: FC<stateType> = ({
  history,
  onInitialFormState = () => {},
  onTitle = () => {},
  onDescription = () => {},
  title,
  description,
  placementFormValues,
}) => {
  const [isSelected, setIsSelected] = useState<string>('')
  const [popUpTitle, setPopUpTitle] = useState<string>('')
  const [value1, setValue1] = useState<any>()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const { slug } = useParams<any>()
  const handleMenu = (e: any) => {
    setPopUpTitle(e)
    setIsModalVisible(true)
  }

  const handleOk = () => {
    onInitialFormState('placement_form')
    setIsModalVisible(false)
    //history.push(`/feature-product/${slug}/${isSelected}`);
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const dispatch = useDispatch()
  const onFinish = (values: any) => {
    //dispatch(addFeatureProduct(values));
  }

  return (
    <>
      <Typography.Title className='product-name' level={1}>
        {slug === 'add' ? 'ADD' : 'EDIT'} FEATURE PRODUCT
      </Typography.Title>
      <Card style={{ marginTop: 20 }}>
        <Row align='middle' justify='center'>
          <Col span={16}>
            <Typography.Title level={2} style={{ textTransform: 'uppercase' }}>
              {slug === 'add' ? 'add' : 'edit'}
            </Typography.Title>
            <Form layout='vertical' onFinish={onFinish}>
              <Form.Item
                {...formHalfLayout}
                name='title'
                label='Title'
                rules={[{ required: true, message: 'Title is require.' }]}
              >
                <Input
                  defaultValue={title}
                  onChange={(e: any) => onTitle(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name='description'
                label='Description'
                rules={[{ required: true, message: 'Description is require.' }]}
              >
                <QuillBody
                  defaultValue={description}
                  onChange={(e: any) => onDescription(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                {...formHalfLayout}
                name='placement'
                label='Placement'
                rules={[{ required: true, message: 'Placement is require.' }]}
              >
                <Select onSelect={handleMenu} showArrow={false}>
                  <Select.Option value='Feature Page'>
                    <Space style={menuStyle}>
                      Feature Page
                      <Avatar
                        shape='circle'
                        className='neumorphify'
                        style={{ background: '#E3EDF7' }}
                        size={36}
                        icon={
                          <PlacementButtonIcon
                            width={30}
                            style={{ fontSize: 18 }}
                          />
                        }
                      />
                    </Space>
                  </Select.Option>
                  <Select.Option value='Inner Page'>
                    <Space style={menuStyle}>
                      Inner Page
                      <Avatar
                        shape='circle'
                        className='neumorphify'
                        style={{ background: '#E3EDF7' }}
                        size={36}
                        icon={
                          <PlacementButtonIcon
                            width={30}
                            style={{ fontSize: 18 }}
                          />
                        }
                      />
                    </Space>
                  </Select.Option>
                  <Select.Option value='Home Page'>
                    <Space style={menuStyle}>
                      Home Page
                      <Avatar
                        shape='circle'
                        className='neumorphify'
                        style={{ background: '#E3EDF7' }}
                        size={36}
                        icon={
                          <PlacementButtonIcon
                            width={30}
                            style={{ fontSize: 18 }}
                          />
                        }
                      />
                    </Space>
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name='status'
                label='status'
                rules={[{ required: true, message: 'Status is required.' }]}
              >
                <Radio.Group
                  onChange={(e) => setValue1(e.target.value)}
                  value={value1}
                >
                  <Radio value='Published'>Published</Radio>
                  <Radio value='Draft'>Draft</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name='Upload'
                rules={[{ message: 'Please Upload FIle.' }]}
              >
                <UploadFiles />
              </Form.Item>
              <Button
                type='primary'
                size='middle'
                shape='round'
                htmlType='submit'
              >
                Save
              </Button>
              <Button type='text' size='middle' shape='round'>
                Cancel
              </Button>
            </Form>
          </Col>
          <Modal
            width={700}
            closable={false}
            title={popUpTitle}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button type='text' key='back' onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                key='submit'
                type='primary'
                size='middle'
                shape='round'
                onClick={handleOk}
              >
                Customize
              </Button>,
            ]}
          >
            <Row gutter={[20, 20]} justify='space-between' align='middle'>
              <Col
                style={{
                  border: isSelected === 'type1' ? '1px solid black' : '',
                }}
                onClick={() => setIsSelected('type1')}
              >
                <Image
                  width={200}
                  style={{ cursor: 'pointer' }}
                  preview={false}
                  src={Type1}
                />
              </Col>
              <Col
                style={{
                  border: isSelected === 'type2' ? '1px solid black' : '',
                }}
                onClick={() => setIsSelected('type2')}
              >
                <Image
                  width={200}
                  style={{ cursor: 'pointer' }}
                  preview={false}
                  src={Type2}
                />
              </Col>
              <Col
                style={{
                  border: isSelected === 'type3' ? '1px solid black' : '',
                }}
                onClick={() => setIsSelected('type3')}
              >
                <Image
                  width={200}
                  style={{ cursor: 'pointer' }}
                  preview={false}
                  src={Type3}
                />
              </Col>
              <Col
                style={{
                  border: isSelected === 'type4' ? '1px solid black' : '',
                }}
                onClick={() => setIsSelected('type4')}
              >
                <Image
                  width={200}
                  style={{ cursor: 'pointer' }}
                  preview={false}
                  src={Type4}
                />
              </Col>
            </Row>
          </Modal>
        </Row>
      </Card>
    </>
  )
}
export default FeatureProductForm
