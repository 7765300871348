import { Card, Col, Row, Tooltip, Typography } from "antd";
import "./BlogCard.scss";
import { CheckCircleFilled, PlusCircleOutlined } from "@ant-design/icons";

const BlogCard = ({ onClickSelect, onClickUnselect, title, isAssigned }: any) => {
  return (
    <Card className="blog__card">
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Text>{title || "No Title"}</Typography.Text>
        </Col>
        <Col>
          <Tooltip title={isAssigned ? "Unselect Blog" : "Attach Blog"}>
            {isAssigned ? (
              <CheckCircleFilled
                style={{ color: "limegreen" }}
                onClick={isAssigned && onClickUnselect}
              />
            ) : (
              <PlusCircleOutlined
                style={{ cursor: "pointer" }}
                onClick={!isAssigned && onClickSelect}
              />
            )}
          </Tooltip>
        </Col>
      </Row>
    </Card>
  );
};

export default BlogCard;
