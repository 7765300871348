import { Button, Col, Form, Input, message, Row, Typography, Radio } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createNewUser } from "../../API";
import { environment } from "../../Config/environment";
import DatePicker from "antd/lib/date-picker";
import { parseError } from "../../Utils/ParseError";

export const CreateNewCustomer = () => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const { id } = useParams();

  let queryParamsObject: { [key: string]: string } = {};

  if (id && id.length > 10) {
    const splitedSearch = id.split("&");
    splitedSearch.forEach((param) => {
      const [key, value] = param.split("=");
      queryParamsObject[key] = value;
    });
  }

  useEffect(() => {
    if (id && id.length > 10) {
      form.setFieldsValue({
        phone: queryParamsObject?.phone,
      });
    } else {
      form.setFieldsValue({
        phone: id,
      });
    }
  }, [id]);

  const handleNext = (selectedUser: any) => {
    if (queryParamsObject && queryParamsObject?.store) {
      navigation("/store");
      window.close();
    } else {
      navigation("/store/create/order/customer", {
        state: selectedUser,
      });
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);

    await createNewUser(values)
      .then((data) => {
        handleNext(data?.data);
      })
      .catch((err) => {
        const error = parseError(err);
        if (error?.message.includes("E11000")) {
          message.error(`Email ${values?.email} already exists!!`);
        } else {
          message.error(parseError(err)?.message || "Something Went Wrong");
        }
      });

    setLoading(false);
  };
  return (
    <div className="contact-number">
      <Form autoComplete="off" form={form} layout="vertical" onFinish={onFinish}>
        <Row justify="center">
          <Col lg={14}>
            <Typography.Title style={{ marginBottom: 20 }}>Create New Customer</Typography.Title>

            <Row gutter={50}>
              <Col lg={12}>
                <Form.Item
                  rules={[
                    { required: true, message: "Required" },
                    {
                      pattern: /^(?:\+977)?[9][6-9]\d{8}$/,
                      message: "Please enter valid phone number",
                    },
                  ]}
                  name="phone"
                  label="Phone Number"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  rules={[{ required: true, message: "Required" }]}
                  name="name"
                  label="Customer Name"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  rules={[
                    { required: true, message: "Required" },
                    {
                      pattern:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "Please enter a valid email address",
                    },
                  ]}
                  name="email"
                  label="Email"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Please select an gender!",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="male">Male</Radio>
                    <Radio value="female">Female</Radio>
                    <Radio value="others">Others</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col lg={12}>
                <Form.Item rules={[{ required: true, message: "Required" }]} name="dob" label="DOB">
                  <DatePicker />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <Form.Item
                  rules={[{ required: true, message: "Required" }]}
                  name="address"
                  label="Address"
                  hidden
                ></Form.Item>
                <Form.Item shouldUpdate>
                  {() => {
                    return (
                      <Form.Item
                        label="Address"
                        extra={
                          <Typography.Text type="danger">
                            {form.getFieldError("address")}
                          </Typography.Text>
                        }
                      >
                        <ReactGoogleAutocomplete
                          apiKey={environment.googleApiKey}
                          onPlaceSelected={(place) =>
                            form.setFieldsValue({ address: place?.formatted_address })
                          }
                          className="ant-input googl-search"
                          inputAutocompleteValue="hjghjghj"
                          options={{
                            types: ["geocode", "establishment"],
                            // componentRestrictions: { country },
                          }}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              <Col lg={24}>
                <Form.Item>
                  <Button loading={loading} htmlType="submit" type="primary">
                    Create New Customer
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
