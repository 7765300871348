import { message } from "antd";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { setLayout } from "../Actions/LayoutActions";

import {
  createProductCategoryFailure,
  createProductCategorySuccess,
  deleteProductCategoryFailure,
  deleteProductCategorySuccess,
  fetchProductCategoryFailure,
  fetchProductCategorysFailure,
  fetchProductCategorysSuccess,
  fetchProductCategorySuccess,
  updateProductCategoryFailure,
  updateProductCategorySuccess,
} from "./ProductCategoryActions";
import {
  cutProductCategory,
  makeProductCategory,
  modifyProductCategory,
  takeProductCategory,
  takeProductCategorys,
} from "./ProductCategoryApis";
import {
  CreateProductCategoryParams,
  CREATE_PRODUCT_CATEGORY,
  REMOVE_PRODUCT_CATEGORY,
  FETCH_PRODUCT_CATEGORYS,
  FETCH_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY,
  ProductCategoryDetailsParams,
  ProductCategoryFetchParams,
} from "./ProductCategoryTypes";

//get product category
const getProductCategorysAsync = async (data: ProductCategoryFetchParams) =>
  await takeProductCategorys(data);

function* getProductCategorys(params: any) {
  const { payload } = params;
  try {
    const ProductCategorys: AxiosResponse = yield call(getProductCategorysAsync, payload);
    if (ProductCategorys) {
      yield put(fetchProductCategorysSuccess(ProductCategorys.data));
    } else {
      yield put(fetchProductCategorysFailure("Could not get list of product category"));
      message.error("Could not get list of product category");
    }
  } catch (error) {
    yield put(fetchProductCategorysFailure("Network Error while getting ProductCategorys"));
    message.error("Could not get list of product category");
  }
}

function* watchGetProductCategorys() {
  yield takeLatest(FETCH_PRODUCT_CATEGORYS, getProductCategorys);
}

//get user details
const getProductCategoryAsync = async (data: ProductCategoryDetailsParams) =>
  await takeProductCategory(data);

function* getProductCategory(params: any) {
  const { payload } = params;
  try {
    const ProductCategorys: AxiosResponse = yield call(getProductCategoryAsync, payload);
    if (ProductCategorys) {
      yield put(fetchProductCategorySuccess(ProductCategorys.data));
    } else {
      yield put(fetchProductCategoryFailure("Could not get list of product category"));
      message.error("Could not get list of product category");
    }
  } catch (error) {
    yield put(fetchProductCategoryFailure("Network Error while getting ProductCategorys"));
    message.error("Could not get list of product category");
  }
}

function* watchGetProductCategory() {
  yield takeLatest(FETCH_PRODUCT_CATEGORY, getProductCategory);
}

//create ProductCategory
const generateProductCategoryAsync = async (data: CreateProductCategoryParams) =>
  await makeProductCategory(data);

function* generateProductCategory(params: any) {
  const { payload } = params;
  try {
    const ProductCategorys: AxiosResponse = yield call(generateProductCategoryAsync, payload);
    if (ProductCategorys) {
      yield put(createProductCategorySuccess(ProductCategorys.data));
      message.success("ProductCategory created successfully");
      yield put(
        setLayout({
          created: true,
        })
      );
    } else {
      const messages = "Could not create ProductCategory";
      yield put(createProductCategoryFailure(messages));
      message.error(messages);
    }
  } catch (error: any) {
    let messages = '';
    if (error.response) {
      messages = error.response.data.message;
    } else {
      messages = "Network Error while creating ProductCategory";
    }
    // console.log("error: ", error?.response.message);
    yield put(createProductCategoryFailure(messages));
    message.error(messages);
  }
}

function* watchGenerateProductCategory() {
  yield takeLatest(CREATE_PRODUCT_CATEGORY, generateProductCategory);
}

//update ProductCategory
const amendProductCategoryAsync = async (data: CreateProductCategoryParams) =>
  await modifyProductCategory(data);

function* amendProductCategory(params: any) {
  const { payload } = params;
  try {
    const ProductCategorys: AxiosResponse = yield call(amendProductCategoryAsync, payload);
    if (ProductCategorys) {
      yield put(updateProductCategorySuccess(ProductCategorys.data));
      message.success("ProductCategory updated successfully");
      yield put(
        setLayout({
          created: true,
        })
      );
    } else {
      const messages = "Could not Update ProductCategory";
      yield put(updateProductCategoryFailure(messages));
      message.error(messages);
    }
  } catch (error) {
    const messages = "Network Error while updating ProductCategory";
    yield put(updateProductCategoryFailure(messages));
    message.error(messages);
  }
}

function* watchAmendProductCategory() {
  yield takeLatest(UPDATE_PRODUCT_CATEGORY, amendProductCategory);
}

//Delete ProductCategory
const removeProductCategoryAsync = async (data: ProductCategoryDetailsParams) =>
  await cutProductCategory(data);

function* removeProductCategory(params: any) {
  const { payload } = params;
  try {
    const ProductCategorys: AxiosResponse = yield call(removeProductCategoryAsync, payload);
    if (ProductCategorys) {
      yield put(deleteProductCategorySuccess(ProductCategorys.data));
      message.success("ProductCategory Deleted successfully");
    } else {
      const messages = "Could not delete ProductCategory";
      yield put(deleteProductCategoryFailure(messages));
      message.error(messages);
    }
  } catch (error) {
    const messages = "Network Error while deleting ProductCategory";
    yield put(deleteProductCategoryFailure(messages));
    message.error(messages);
  }
}

function* watchRemoveProductCategory() {
  yield takeLatest(REMOVE_PRODUCT_CATEGORY, removeProductCategory);
}

export default function* ProductCategorySagas() {
  yield all([
    fork(watchGetProductCategorys),
    fork(watchGetProductCategory),
    fork(watchGenerateProductCategory),
    fork(watchAmendProductCategory),
    fork(watchRemoveProductCategory),
  ]);
}
