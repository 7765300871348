import { Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { LatidoAnimatedLogoLoader } from "../../Components/LatidoAnimateLogo/LatidoAnimateLogo";
import { VendorForm } from "../../Components/VendorForm";
import { CombineReducerType } from "../../Redux/Reducers/rootReducers";
import { createVendor, fetchVendor } from "../../Redux/Vendor/VendorActions";

const initialState = {
  _id: "",
  vendor_id: "",
  vendor_name: "",
  supplies_type: [""],
  location: "",
  phone_no: "",
  email: "",
  icon: "https://picsum.photos/200",
  description: "",
  contact_person: {
    contact_name: "",
    phone_no: "",
    email: "",
    location: "",
  },
};
export const EditVendor = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { id }: any = useParams();
  const { vendor, loading } = useSelector((state: CombineReducerType) => state?.VendorReducer);
  const [formState, setFormState] = useState(initialState);

  const handleFormSubmit = async (formState: any) => {
    const data = {
      ...vendor,
      ...formState,
    };
    await dispatch(createVendor(data));
    history(`/inventory/vendor/${vendor?._id}`);
    setFormState(initialState);
  };

  useEffect(() => {
    dispatch(fetchVendor(id));
  }, []);

  useEffect(() => {
    if (!loading && Object.keys(vendor).length > 0) {
      setFormState({
        _id: vendor?._id,
        vendor_id: vendor?.vendor_id,
        vendor_name: vendor?.vendor_name,
        supplies_type: vendor?.supplies_type,
        location: vendor?.location,
        phone_no: vendor?.phone_no,
        email: vendor?.email,
        icon: vendor?.icon,
        description: vendor?.description,
        contact_person: vendor?.contact_person,
      });
    }
  }, [vendor, loading]);

  return (
    <>
      {loading && Object.keys(vendor).length > 0 ? (
        <Row justify="center" align="middle">
          {/* <Spin size='large' /> */}
          <LatidoAnimatedLogoLoader />
        </Row>
      ) : (
        <VendorForm initialState={formState} handleFormSubmit={handleFormSubmit} edit />
      )}
    </>
  );
};
