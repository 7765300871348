import {
  GetLeatherAddOn,
  GetLeatherProfile,
} from './../Actions/LeatherProfileAction'
import { message } from 'antd'
import {
  GET_LEATHER_ADD_ON,
  GET_LEATHER_INVENTORY,
  POST_LEATHER_ADD_ON,
} from './../Constants/constants'
import { all, call, fork, put, takeEvery } from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'
import {
  fetchleatherAddOn,
  fetchleatherInventory,
  fetchleatherProfile,
  postleatherAddOn,
  postLeatherProfile,
} from '../../Apis/LeatherProfileApi'
import {
  GetLeatherAddOnFailure,
  GetLeatherAddOnSuccess,
  GetLeatherInventoryFailure,
  GetLeatherInventorySuccess,
  GetLeatherProfileFailure,
  GetLeatherProfileSuccess,
  PostLeatherAddOnFailure,
  PostLeatherAddOnSuccess,
  PostLeatherProfileFailure,
  PostLeatherProfileSuccess,
} from '../Actions/LeatherProfileAction'
import {
  GET_LEATHER_PROFILE,
  POST_LEATHER_PROFILE,
} from '../Constants/constants'
import { history } from '../AppStore'

//get leather profile with params
const takeLeatherProfileApi = async (params: any) =>
  await fetchleatherProfile(params)

function* takeLeatherProfile({ payload }: any) {
  try {
    const LeatherProfile: AxiosResponse = yield call(
      takeLeatherProfileApi,
      payload
    )
    if (LeatherProfile) {
      yield put(GetLeatherProfileSuccess(LeatherProfile.data))
    }
  } catch (error) {
    yield put(GetLeatherProfileFailure(error))
  }
}
function* watchTakenLeatherProfile() {
  yield takeEvery(GET_LEATHER_PROFILE, takeLeatherProfile)
}

//get leather add on
const takeLeatherAddOnApi = async (params: any) =>
  await fetchleatherAddOn(params)

function* takeLeatherAddOn({ payload }: any) {
  try {
    const LeatherAddOn: AxiosResponse = yield call(takeLeatherAddOnApi, payload)
    if (LeatherAddOn) {
      yield put(GetLeatherAddOnSuccess(LeatherAddOn.data))
    }
  } catch (error) {
    yield put(GetLeatherAddOnFailure(error))
  }
}
function* watchTakenLeatherAddOn() {
  yield takeEvery(GET_LEATHER_ADD_ON, takeLeatherAddOn)
}

//Post leather add on
const PostLeatherAddOnApi = async (params: any) =>
  await postleatherAddOn(params)

function* PostLeatherAddOn({ payload }: any) {
  try {
    const LeatherAddOn: AxiosResponse = yield call(PostLeatherAddOnApi, payload)
    if (LeatherAddOn) {
      yield put(PostLeatherAddOnSuccess())
      message.success('Leather Add On Added Successfully')
      yield put(GetLeatherAddOn({ page: 0, limit: 0 }))
    }
  } catch (error: any) {
    yield put(PostLeatherAddOnFailure(error))
    message.error(error?.response?.data?.message || 'Something went wrong')
  }
}
function* watchPostLeatherAddOn() {
  yield takeEvery(POST_LEATHER_ADD_ON, PostLeatherAddOn)
}

//get leather Inventory
const takeLeatherInventoryApi = async (params: any) =>
  await fetchleatherInventory(params)

function* takeLeatherInventory({ payload }: any) {
  try {
    const LeatherInventory: AxiosResponse = yield call(
      takeLeatherInventoryApi,
      payload
    )
    if (LeatherInventory) {
      yield put(GetLeatherInventorySuccess(LeatherInventory.data))
    }
  } catch (error) {
    yield put(GetLeatherInventoryFailure(error))
  }
}
function* watchTakenLeatherInventory() {
  yield takeEvery(GET_LEATHER_INVENTORY, takeLeatherInventory)
}

//post leather profile data
const takePostLeatherProfileApi = async (data: any) =>
  await postLeatherProfile(data)

function* takePostLeatherProfile({ payload }: any) {
  try {
    const PostLeatherProfile: AxiosResponse = yield call(
      takePostLeatherProfileApi,
      payload
    )
    if (PostLeatherProfile) {
      yield put(PostLeatherProfileSuccess())
      message.success('Leather Profile Added Successfully')
      window.location.href = '/leather'
    }
  } catch (error: any) {
    message.error(error?.response?.data?.message || 'Something went wrong')
    yield put(PostLeatherProfileFailure(error))
  }
}
function* watchTakenPostLeatherProfile() {
  yield takeEvery(POST_LEATHER_PROFILE, takePostLeatherProfile)
}
export default function* MemberSaga() {
  yield all([
    fork(watchTakenLeatherProfile),
    fork(watchTakenPostLeatherProfile),
    fork(watchTakenLeatherAddOn),
    fork(watchTakenLeatherInventory),
    fork(watchPostLeatherAddOn),
  ])
}
