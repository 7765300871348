import { CheckCircleFilled } from "@ant-design/icons";
import { Descriptions, Image, Tabs, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

import "./ProductSpecifications.scss";

import { ReactComponent as CheckCircle } from "../../../Assets/Icons/check-blue.svg";
import {
  LeatherID,
  ProductSpecification,
} from "../../../Views/Admin/Details/Details/ProductDetails";
import MediaUrl from "../../../Utils/mediaUrl";
import { availableSizes } from "../../../Views/Admin/Forms/Forms/ProductDetailsForm";

interface ProductSpecificationProps {
  data: ProductSpecification[] | undefined;
}
const { TabPane } = Tabs;
export const ProductSpecifications = ({ data }: ProductSpecificationProps) => {
  const TabHeader = ({ item_name, icon }: LeatherID) => {
    return (
      <div
        style={{ position: "relative", minWidth: "15vw", maxHeight: 140 }}
        className="tab__header"
      >
        <Image
          preview={false}
          src={
            MediaUrl(icon) ||
            "https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-black-leather-texture-background-image_356768.jpg"
          }
        />
        <div className="seclected__indicator">
          <CheckCircle style={{ fontSize: 20, color: "blue" }} />
        </div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            bottom: 10,
            left: 0,
            padding: 10,
            paddingLeft: 30,
          }}
        >
          <Typography.Text style={{ color: "white", fontWeight: 300 }}>{item_name}</Typography.Text>
        </div>
      </div>
    );
  };

  const sizeName = (id: any) => {
    return availableSizes?.find((size) => size.value === id)?.name;
  };
  return (
    <Content className="product__specifications">
      <Tabs popupClassName="product__more__tab">
        {data &&
          data?.map((productSpecification, index) => {
            return (
              <>
                {productSpecification && (
                  <TabPane
                    key={index}
                    tab={<TabHeader {...(productSpecification?.leather_id || {})} />}
                  >
                    <Descriptions
                      title={
                        <Typography.Title level={2} style={{ textTransform: "uppercase" }}>
                          {productSpecification?.leather_id?.item_name}
                        </Typography.Title>
                      }
                      colon={false}
                      layout="vertical"
                      labelStyle={{
                        fontWeight: 600,
                        fontSize: 18,
                      }}
                      contentStyle={{ marginBottom: 30 }}
                      column={2}
                    >
                      {productSpecification.size_range?.map((size) => {
                        return (
                          <Descriptions.Item label={sizeName(size?.size)}>
                            <Typography.Text type="secondary">
                              {size?.price?.[0]?.value + " " + size?.price?.[0]?.currency}
                            </Typography.Text>
                          </Descriptions.Item>
                        );
                      })}
                    </Descriptions>
                  </TabPane>
                )}
              </>
            );
          })}
      </Tabs>
    </Content>
  );
};

export default ProductSpecifications;
