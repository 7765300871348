import {
  CREATE_VENDOR,
  CREATE_VENDOR_FAILURE,
  CREATE_VENDOR_SUCCESS,
  FETCH_VENDOR,
  FETCH_VENDORS,
  FETCH_VENDORS_FAILURE,
  FETCH_VENDORS_SUCCESS,
  FETCH_VENDOR_FAILURE,
  FETCH_VENDOR_SUCCESS,
  REMOVE_VENDOR,
  REMOVE_VENDOR_FAILURE,
  REMOVE_VENDOR_SUCCESS,
} from './VendorTypes'

//fetch Vendors
export const fetchVendors = (
  page: number,
  limit: number,
  value: string,
  sortBy: string
) => ({
  type: FETCH_VENDORS,
  payload: { page, limit, value, sortBy },
})

export const fetchVendorsSuccess = (response: any) => ({
  type: FETCH_VENDORS_SUCCESS,
  payload: response,
})

export const fetchVendorsFailure = (error: any) => ({
  type: FETCH_VENDORS_FAILURE,
  payload: error,
})

//fetch Vendors
export const fetchVendor = (id: string) => ({
  type: FETCH_VENDOR,
  payload: id,
})

export const fetchVendorSuccess = (response: any) => ({
  type: FETCH_VENDOR_SUCCESS,
  payload: response,
})

export const fetchVendorFailure = (error: any) => ({
  type: FETCH_VENDOR_FAILURE,
  payload: error,
})

//create Vendor
export const createVendor = (data: any) => ({
  type: CREATE_VENDOR,
  payload: data,
})

export const createVendorSuccess = (response: any) => ({
  type: CREATE_VENDOR_SUCCESS,
  payload: response,
})

export const createVendorFailure = (error: any) => ({
  type: CREATE_VENDOR_FAILURE,
  payload: error,
})

//delete Vendor
export const deleteVendor = (id: string) => ({
  type: REMOVE_VENDOR,
  payload: id,
})
export const deleteVendorSuccess = (response: any) => ({
  type: REMOVE_VENDOR_SUCCESS,
  payload: response,
})
export const deleteVendorFailure = (error: any) => ({
  type: REMOVE_VENDOR_FAILURE,
  payload: error,
})
