import {
  CREATE_INVENTORY_ITEM,
  CREATE_INVENTORY_ITEM_FAILURE,
  CREATE_INVENTORY_ITEM_SUCCESS,
  CREATE_INVENTORY_ITEM_TRACK,
  CREATE_INVENTORY_ITEM_TRACK_FAILURE,
  CREATE_INVENTORY_ITEM_TRACK_SUCCESS,
  FETCH_INVENTORY_ITEM,
  FETCH_INVENTORY_ITEM_FAILURE,
  FETCH_INVENTORY_ITEM_SUCCESS,
  FETCH_INVENTORY_ITEM_TRACK,
  FETCH_INVENTORY_ITEM_TRACK_FAILURE,
  FETCH_INVENTORY_ITEM_TRACK_SUCCESS,
  FETCH_VENDOR_OF_INVENTORY_ITEM,
  FETCH_VENDOR_OF_INVENTORY_ITEM_FAILURE,
  FETCH_VENDOR_OF_INVENTORY_ITEM_SUCCESS,
} from './InventoryItemTypes'

//fetch Inventory Item
export const fetchInventoryItem = (id:string) => ({
  type: FETCH_INVENTORY_ITEM,
  payload: id
})

export const fetchInventoryItemSuccess = (response: any) => ({
  type: FETCH_INVENTORY_ITEM_SUCCESS,
  payload: response,
})

export const fetchInventoryItemFailure = (error: any) => ({
  type: FETCH_INVENTORY_ITEM_FAILURE,
  payload: error,
})


//create Inventory Item
export const createInventoryItem = (data: any) => ({
  type: CREATE_INVENTORY_ITEM,
  payload: data
})

export const createInventoryItemSuccess = (response:any) => ({
  type: CREATE_INVENTORY_ITEM_SUCCESS,
  payload: response
})

export const createInventoryItemFailure = (error: any) => ({
  type: CREATE_INVENTORY_ITEM_FAILURE,
  payload: error
})

//create Inventory Item Track
export const createInventoryItemTrack = (data: any) => ({
  type: CREATE_INVENTORY_ITEM_TRACK,
  payload: data
})

export const createInventoryItemTrackSuccess = (response:any) => ({
  type: CREATE_INVENTORY_ITEM_TRACK_SUCCESS,
  payload: response
})

export const createInventoryItemTrackFailure = (error: any) => ({
  type: CREATE_INVENTORY_ITEM_TRACK_FAILURE,
  payload: error
})


//fetch Inventory Item Track
export const fetchInventoryItemTrack = (data: any) => ({
  type: FETCH_INVENTORY_ITEM_TRACK,
  payload: data
})

export const fetchInventoryItemTrackSuccess = (response:any) => ({
  type: FETCH_INVENTORY_ITEM_TRACK_SUCCESS,
  payload: response
})

export const fetchInventoryItemTrackFailure = (error: any) => ({
  type: FETCH_INVENTORY_ITEM_TRACK_FAILURE,
  payload: error
})


//fetch Inventory Item Track
export const fetchVendorOfInventoryItem = (data: any) => ({
  type: FETCH_VENDOR_OF_INVENTORY_ITEM,
  payload: data
})

export const fetchVendorOfInventoryItemSuccess = (response:any) => ({
  type: FETCH_VENDOR_OF_INVENTORY_ITEM_SUCCESS,
  payload: response
})

export const fetchVendorOfInventoryItemFailure = (error: any) => ({
  type: FETCH_VENDOR_OF_INVENTORY_ITEM_FAILURE,
  payload: error
})