import { Route, Routes } from "react-router";
import withPermission from "../Utils/checkPermission";
import BlogForm from "../Views/Admin/Blogs/Add/BlogForm";
import Blog from "../Views/Admin/Blogs/Blog";
import BlogsDetails from "../Views/Admin/Blogs/BlogsDetails";

export const BlogRoute = (props: any) => {
  return (
    <Routes>
      <Route path={`/details/:slug`} element={<BlogsDetails />} />
      <Route path={`/:slug`} element={<BlogForm />} />
      <Route path={`/`} element={<Blog />} />
    </Routes>
  );
};

export default withPermission(BlogRoute, "blog");
