import { Reducer } from 'redux'
import {
  ADD_FEATURE_PRODUCT_REQUEST,
  ADD_FEATURE_PRODUCT_SUCCESS,
  ADD_FEATURE_PRODUCT_FAILURE,
  ADD_PLACEMENT_FORM_REQUEST,
  ADD_PLACEMENT_FORM_SUCCESS,
  ADD_PLACEMENT_FORM_FAILURE,
  GET_FEATURE_PRODUCTS_REQUEST,
  GET_FEATURE_PRODUCTS_SUCCESS,
  GET_FEATURE_PRODUCTS_FAILED,
} from './../Constants/constants'

const INIT_STATE = {
  featureProductList: [],
  loading: false,
  addFeatureProductResponse: false,
  placementFormResponse: false,
  error: false,
}

export const FeatureProductReducer: Reducer<any, any> = (
  state = INIT_STATE,
  action: any
) => {
  switch (action.type) {
    //Fetch feature product list
    case GET_FEATURE_PRODUCTS_REQUEST:
      return {
        loading: true,
        ...state,
      }
    case GET_FEATURE_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        featureProductList: action.payload.data,
      }
    case GET_FEATURE_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    //add Feature Product
    case ADD_FEATURE_PRODUCT_REQUEST:
      return {
        loading: true,
        ...state,
      }
    case ADD_FEATURE_PRODUCT_SUCCESS: {
      const addedProduct: any = action.payload.data
      const updatedData: any = [...state.featureProductList, addedProduct]
      return {
        ...state,
        loading: false,
        addFeatureProductResponse: action.payload.data,
        featureProductList: updatedData,
      }
    }
    case ADD_FEATURE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        addFeatureProductResponse: action.payload.error,
      }
    //add placement form
    case ADD_PLACEMENT_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ADD_PLACEMENT_FORM_SUCCESS: {
      return {
        ...state,
        loading: false,
        placementFormResponse: action.payload.data,
      }
    }
    case ADD_PLACEMENT_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        placementFormResponse: action.payload.error,
      }
    default:
      return {
        ...state,
      }
  }
}
