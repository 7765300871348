import React from "react";
import { Route, Routes } from "react-router";
import StaticContentsRoute from "./StaticContentRoutes";
import UserRoute from "./UserRoute";
import MemberRoute from "./MemberRoute";
import BlogRoute from "./BlogRoute";
import ProductRoute from "./ProductRoute";
import GridRoute from "./GridRoutes";
import FeatureProductRoutes from "./FeatureProduct";
import ProductCategoryRoute from "./ProductCategoryRoute";
import LeatherProfileRoute from "./LeatherProfileRoute";
import ExchangeRefundRoute from "./ExchangeRefundRoute";
import withPermission from "../Utils/checkPermission";
import CustomerRoute from "./CustomerRoute";

export const AdminRoute = (props: any) => {
  return (
    <Routes>
      <Route path={`/*`} element={<ProductRoute />} />
      <Route path={`/static-contents`} element={<StaticContentsRoute />} />
      <Route path={`/user-management`} element={<UserRoute />} />
      <Route path={`/member-management`} element={<MemberRoute />} />
      <Route path={`/exchange-refund/*`} element={<ExchangeRefundRoute />} />
      <Route path={`/customer-management/*`} element={<CustomerRoute />} />
      <Route path={`/blogs/*`} element={<BlogRoute />} />
      {/* <Route path={`/`}  element={<Dashboard />} /> */}
      <Route path={`/grid`} element={<GridRoute />} />
      <Route path={`/feature-product`} element={<FeatureProductRoutes />} />

      <Route path={`/product-category/*`} element={<ProductCategoryRoute />} />
      <Route path={`/product/*`} element={<ProductRoute />} />
    </Routes>
  );
};

export default withPermission(AdminRoute, "admin");
