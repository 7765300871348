export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS'
export const REQUEST_LOGIN_FAILURE = 'REQUEST_LOGIN_FAILURE'

export const SET_AUTH_USER = 'SET_AUTH_USER'

export const SET_CURRENT_USER = 'SET_CURRENT_USER'

export interface RequestLoginParams {
  username: string
  password: string
}

export interface AuthFetchParams {
  limit?: number
  page?: number
}
export interface AuthDetailsParams {
  id?: number
}
export interface CreateAuthParams {
  id?: number
  name?: string
  address?: string
  phone?: string
}
