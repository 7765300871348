import React from "react";
import ReactQuill from "react-quill";

const Editor = ({ onChange, value }) => {
  function handleChange(html) {
    onChange(html);
  }
  return (
    <ReactQuill
      className="custom_text-editor"
      onChange={handleChange}
      value={value}
      placeholder={"Enter text content here"}
      modules={Editor.modules}
      formats={Editor.formats}
      theme={"snow"}
    />
  );
};

export default Editor;
