import {
  CreateCustomerParams,
  CustomerDetailsParams,
  CustomerFetchParams,
} from './CustomerTypes'
import { API } from 'aws-amplify'
import { ApiHeaders, API_NAME } from '../../API/variable'
// import { ApiHeaders, API_NAME } from 'Api/Api';
//get Customer
export const takeCustomers = (data: CustomerFetchParams) =>
  API.get(API_NAME, '/admin_customer/user-list', {
    headers: ApiHeaders,
    queryStringParameters: data,
  })

//get Customer Details
export const takeCustomer = (data: CustomerDetailsParams) =>
  API.get(API_NAME, '/admin_customer/user-details', {
    headers: ApiHeaders,
    data: data,
    queryStringParameters: data,
  })

export const takeCustomerTimeline = (data: CustomerDetailsParams) =>
  API.get(API_NAME, '/admin_customer/user-time-line', {
    headers: ApiHeaders,
    data: data,
    queryStringParameters: data,
  })

export const takeCustomerOrders = (data: CustomerDetailsParams) =>
  API.get(API_NAME, '/admin_customer/cart-list', {
    headers: ApiHeaders,
    data: data,
    queryStringParameters: data,
  })
//create Customer Details
export const makeCustomer = (data: CreateCustomerParams) =>
  API.post(API_NAME, '/admin_customer', { headers: ApiHeaders, data: data })

//modify Customer
export const modifyCustomer = (data: any) => {
  const slug = data?.slug
  if (data?.slug) {
    delete data?.slug

    return API.post(API_NAME, `/${slug}`, { headers: ApiHeaders, body: data })
  } else {
    return API.post(API_NAME, `/admin_customer/${slug}`, {
      headers: ApiHeaders,
      body: data,
    })
  }
}

//delete Customer
export const cutCustomer = (data: CustomerDetailsParams) =>
  API.post(API_NAME, '/admin_customer', { headers: ApiHeaders, data: data })
