import { Col, Form, Input, Layout, Row, Select, Tabs, Typography } from "antd";
import { ReactComponent as ExchangeIcon } from "../../../Assets/Icons/Exchange.svg";
import { ReactComponent as RefundIcon } from "../../../Assets/Icons/Refund.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { fetchExchangeRefund } from "../../../Redux/Actions/ExchangeRefundAction";
import ExchangeRefundTab from "../../../Components/Admin/ExchangeRefundTab/ExchangeRefundTab";
import { useDispatch, useSelector } from "react-redux";
import ArrayChunk from "../../../Utils/ArrayChunk";
import Jacket from "../../../Assets/Products/jacket2.png";
const { Option } = Select;
const { Content } = Layout;
const { TabPane } = Tabs;
export const ExchangeRefund = (props: any) => {
  const exchangeRefund = useSelector((state: any) => state.ExchangeRefundReducer);
  const [finalData, setFinalData] = useState<any>();
  const dispatch = useDispatch();
  const history = useNavigate();
  function callback(key: string) {
    switch (key) {
      case "refund":
        return dispatch(fetchExchangeRefund({ type: "return_requested", total: 10 }));
      case "exchange":
        return dispatch(fetchExchangeRefund({ type: "exchange", total: 20 }));
      default:
        return dispatch(fetchExchangeRefund(""));
    }
  }

  useEffect(() => {
    const currentData = ArrayChunk({
      data: [
        {
          order_number: 8,
          products: {
            name: "mollit",
            primary_image: Jacket,
          },
        },
        {
          order_number: 1,
          products: {
            name: "ut",
            primary_image: Jacket,
          },
        },
        {
          order_number: 1,
          products: {
            name: "dolor",
            primary_image: Jacket,
          },
        },
        {
          order_number: 1,
          products: {
            name: "sit",
            primary_image: Jacket,
          },
        },
        {
          order_number: 3,
          products: {
            name: "culpa",
            primary_image: Jacket,
          },
        },
        {
          order_number: 5,
          products: {
            name: "ullamco",
            primary_image: Jacket,
          },
        },
        {
          order_number: 5,
          products: {
            name: "dolore",
            primary_image: Jacket,
          },
        },
        {
          order_number: 3,
          products: {
            name: "dolor",
            primary_image: Jacket,
          },
        },
        {
          order_number: 3,
          products: {
            name: "eiusmod",
            primary_image: Jacket,
          },
        },
        {
          order_number: 4,
          products: {
            name: "esse",
            primary_image: Jacket,
          },
        },
        {
          order_number: 9,
          products: {
            name: "elit",
            primary_image: Jacket,
          },
        },
        {
          order_number: 10,
          products: {
            name: "eu",
            primary_image: Jacket,
          },
        },
        {
          order_number: 7,
          products: {
            name: "laboris",
            primary_image: Jacket,
          },
        },
        {
          order_number: 7,
          products: {
            name: "aute",
            primary_image: Jacket,
          },
        },
        {
          order_number: 9,
          products: {
            name: "in",
            primary_image: Jacket,
          },
        },
        {
          order_number: 9,
          products: {
            name: "tempor",
            primary_image: Jacket,
          },
        },
        {
          order_number: 5,
          products: {
            name: "qui",
            primary_image: Jacket,
          },
        },
        {
          order_number: 8,
          products: {
            name: "quis",
            primary_image: Jacket,
          },
        },
        {
          order_number: 2,
          products: {
            name: "adipisicing",
            primary_image: Jacket,
          },
        },
        {
          order_number: 0,
          products: {
            name: "nostrud",
            primary_image: Jacket,
          },
        },
        {
          order_number: 9,
          products: {
            name: "ut",
            primary_image: Jacket,
          },
        },
      ],
      size: 2,
    });
    setFinalData(currentData);
  }, [exchangeRefund]);

  useEffect(() => {
    dispatch(fetchExchangeRefund({ limit: 10 }));
  }, []);

  const onDetails = (e: number) => {
    history(`/exchange-refund/${e}`);
  };
  return (
    <Content>
      <Row align="middle" justify="space-between">
        <Col flex="auto" style={{ marginBottom: 24 }}>
          <Typography.Title className="product-name" style={{ marginBottom: 0 }}>
            EXCHANGE AND REFUND
          </Typography.Title>
        </Col>

        <Col flex="auto">
          <Form>
            <Row justify="end">
              <Form.Item name="product">
                <Select
                  showSearch
                  style={{ width: 200, marginRight: 20 }}
                  placeholder="All Products"
                  optionFilterProp="children"
                  // onChange={(e)=> setFilteredData({...filteredData, role: e})}
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="">All</Option>
                </Select>
              </Form.Item>
              <Form.Item name="group">
                <Select
                  showSearch
                  style={{ width: 150, marginRight: 20 }}
                  placeholder="Group By"
                  optionFilterProp="children"
                  // onChange={(e)=> setFilteredData({...filteredData, role: e})}
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="">All</Option>
                </Select>
              </Form.Item>
              <Form.Item name="date">
                <Select
                  showSearch
                  style={{ width: 150, marginRight: 20 }}
                  placeholder="Date"
                  optionFilterProp="children"
                  // onChange={(e)=> setFilteredData({...filteredData, role: e})}
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="">All</Option>
                </Select>
              </Form.Item>
              <Form.Item name="text">
                <Input.Search
                  style={{ width: 250 }}
                  placeholder="Search Order"
                  // onChange={(e)=> setFilteredData({...filteredData, search: e.target.value})}
                />
              </Form.Item>
            </Row>
          </Form>
        </Col>
      </Row>
      <Content>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="All" key="all">
            <ExchangeRefundTab
              data={finalData}
              loading={exchangeRefund.loading}
              onDetails={(e: number) => onDetails(e)}
            />
          </TabPane>
          <TabPane
            tab={
              <Row align="middle">
                <ExchangeIcon style={{ marginRight: 10 }} /> Exchange
              </Row>
            }
            key="exchange"
          >
            <ExchangeRefundTab
              data={finalData}
              loading={exchangeRefund.loading}
              onDetails={onDetails}
            />
          </TabPane>
          <TabPane
            tab={
              <span>
                <RefundIcon style={{ marginRight: 10 }} /> Refund
              </span>
            }
            key="refund"
          >
            <ExchangeRefundTab
              data={finalData}
              loading={exchangeRefund.loading}
              onDetails={onDetails}
            />
          </TabPane>
        </Tabs>
      </Content>
    </Content>
  );
};

export default ExchangeRefund;
