import { Empty, Spin } from 'antd';
import React from 'react';
import './PageHeader.scss';

interface PageHolderTypes {
    loading: boolean;
    empty: boolean;
    loadingText?: any;
    emptyText?: any;
}
export const PageHolder: React.FC<PageHolderTypes> = ({ loading, empty, children, loadingText, emptyText }) => {
    return (
        <div>
            {!loading && !empty ? (
                <>{ children}</>
            ): (
                    <div className="page__holder">
                        {loading ? (
                            <Spin tip={ loadingText || 'Loading...' }/>
                        ) : (
                                <Empty description={ emptyText || 'Sorry! Nothing to show at the moment!'}/>
                        )}
                </div>
            )
                
            }
        </div>
    );
};

export default PageHolder;
