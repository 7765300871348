import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Modal,
  Pagination,
  Row,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { EditFilled, PlusCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";

import moment from "moment";
import { CombineReducerType } from "../../Redux/Reducers/rootReducers";
import {
  fetchInventoryItem,
  fetchVendorOfInventoryItem,
} from "../../Redux/InventoryItem/InventoryItemActions";
import ImageHolder from "../../Components/ImageHolder/ImageHolder";
import MediaUrl from "../../Utils/mediaUrl";
import { LatidoAnimatedLogoLoader } from "../../Components/LatidoAnimateLogo/LatidoAnimateLogo";

interface itemDetailsType {
  title: string;
  value: any;
}
export const RawMaterialDetails = (props: any) => {
  const [imageToggle, setImageToggle] = useState(false);
  const {
    loading,
    inventoryItem,
    vendorsOfInventoryItem,
    vendorsOfInventoryItemPagination,
    fetchingVendor,
  } = useSelector((state: CombineReducerType) => state?.InventoryItemReducer);

  const [overview, setOverView] = useState<any>([]);
  const [itemDetails, setItemDetails] = useState<Array<itemDetailsType>>([]);
  const { id }: any = useParams();
  const { Link } = Typography;
  const dispatch = useDispatch();
  const history = useNavigate();
  const location: any = useLocation();

  useEffect(() => {
    dispatch(fetchInventoryItem(id));
    dispatch(
      fetchVendorOfInventoryItem({
        page: 1,
        limit: 4,
        inventory_item: id,
      })
    );
  }, [id, location]);

  useEffect(() => {
    const { state }: any = location;
    // if (!state?.inventory_category) {
    //   history(-1);
    // }
  }, [props]);

  useEffect(() => {
    if (inventoryItem) {
      let tag = "";
      inventoryItem?.tags?.map((item: string) => (tag = `${tag} ${item}`));
      setItemDetails([
        { title: "Brand", value: inventoryItem?.brand },
        { title: "Colors", value: inventoryItem?.color },
        { title: "Tags", value: tag },
        { title: "Weight", value: inventoryItem?.weight },
      ]);
      setOverView([
        { title: "SKU", value: inventoryItem?.sku },
        { title: "Latido Code", value: inventoryItem?.latido_code },
        {
          title: "Available Stock",
          value: inventoryItem?.material_select?.total_quantity,
        },
        // { title: 'Number of Vendor', value: vendorsOfInventoryItem},
      ]);
    }
  }, [inventoryItem]);

  const [allVendors, setAllVendors] = useState(false);

  useEffect(() => {
    if (allVendors) {
      dispatch(
        fetchVendorOfInventoryItem({
          page: 1,
          limit: 5,
          inventory_item: id,
        })
      );
    } else {
      dispatch(
        fetchVendorOfInventoryItem({
          page: 1,
          limit: 4,
          inventory_item: id,
        })
      );
    }
  }, [allVendors]);

  return (
    <>
      <Modal
        title="FULL REPORT"
        onCancel={() => setAllVendors(false)}
        width="80vw"
        visible={allVendors}
        footer={false}
      >
        <Table
          loading={fetchingVendor}
          pagination={{
            total: vendorsOfInventoryItemPagination?.total_data,
            position: ["bottomCenter"],
            defaultPageSize: 5,
            onChange(page, pageSize) {
              dispatch(
                fetchVendorOfInventoryItem({
                  page: page,
                  limit: pageSize,
                  inventory_item: id,
                })
              );
            },
          }}
          className="min-height-0 no-shadow no-spacing"
          dataSource={vendorsOfInventoryItem}
          columns={[
            {
              key: "vendor",
              dataIndex: "vendor",
              width: 100,
              render: (record, row, index) => (
                <ImageHolder
                  height={50}
                  src={MediaUrl(record?.icon)}
                  alt="vendor"
                  className="vendor-image"
                />
              ),
            },
            {
              title: "Name",
              key: "vendor",
              dataIndex: "vendor",
              render: (record, row, index) => (
                <>
                  <Typography.Link
                    disabled={record?.is_deleted}
                    onClick={() => history(`/inventory/vendor/${record?._id}`)}
                  >
                    {record?.vendor_name}
                  </Typography.Link>
                  <br />
                  <Typography.Paragraph style={{ marginBottom: 0 }} ellipsis>
                    {record?.contact_person?.email}
                  </Typography.Paragraph>
                </>
              ),
            },
            {
              title: "Quantity",
              key: "vendor",
              dataIndex: "vendor",
              render: (record, vendor, index) => vendor?.material_select?.total_quantity,
            },
            {
              title: "Price",
              key: "vendor",
              dataIndex: "vendor",
              render: (record, vendor, index) => vendor?.price,
            },
            {
              title: "Ordered Date",
              key: "ordered_date",
              dataIndex: "ordered_date",
              render: (record, vendor, index) => moment(record).format("MMM DD, YYYY"),
            },
            {
              title: "Received Date",
              key: "recived_date",
              dataIndex: "received_date",
              render: (record, vendor, index) => moment(record).format("MMM DD, YYYY"),
            },
          ]}
        />
      </Modal>
      {loading ? (
        <Col
          xl={24}
          sm={24}
          md={24}
          lg={24}
          xxl={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Spin size='large' /> */}
          <LatidoAnimatedLogoLoader />
        </Col>
      ) : (
        <>
          <Row style={{ margin: "30px 0" }} gutter={[20, 0]}>
            <Col
              span={15}
              style={{
                alignItems: "center",
              }}
            >
              <h1 className="page-title" style={{ marginBottom: 5 }}>
                {" "}
                Item Details
              </h1>

              <h5
                style={{
                  // fontSize: 22,
                  color: "#828282",
                  wordSpacing: 5,
                }}
              >
                <Typography.Link onClick={() => history("/inventory/raw-materials")}>
                  {`Inventory >`}{" "}
                </Typography.Link>
                <Typography.Link
                  onClick={() =>
                    history(
                      `/inventory/raw-materials?category_id=${inventoryItem?.inventory_category?._id}`
                    )
                  }
                >
                  {`${inventoryItem?.inventory_category?.inventory_category} >`}
                </Typography.Link>
                <Typography.Text type="secondary"> {inventoryItem?.item_name}</Typography.Text>
              </h5>
            </Col>
          </Row>
          <Row gutter={[40, 40]}>
            <Col sm={24} lg={8}>
              <Card>
                <Row style={{ padding: "30px" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ position: "absolute", top: 15, right: 15 }}>
                      <Tooltip
                        title={
                          imageToggle ? "Switch to primary image" : "Switch to leather ball image"
                        }
                      >
                        <Switch
                          size="small"
                          checked={imageToggle}
                          onChange={(e) => setImageToggle(e)}
                          disabled={!inventoryItem?.ball_image}
                        />
                      </Tooltip>
                    </div>
                    <ImageHolder
                      style={{ width: "100%" }}
                      src={
                        imageToggle
                          ? MediaUrl(inventoryItem?.ball_image)
                          : MediaUrl(inventoryItem?.icon)
                      }
                      alt="silver_zipper"
                      className="raw-material-image"
                      preview={false}
                    />
                  </div>
                </Row>
                <Divider style={{ width: "100%", boxShadow: "0px 1px 2px 1px white" }} />
                <Row
                  style={{
                    padding: "40px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <h2 className="page-title" style={{ fontSize: 26, color: "#44464B" }}>
                      Overview
                    </h2>
                  </div>
                  {overview.map((item: any, i: string) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: 20,
                        }}
                        key={i}
                      >
                        <h2>{item.title}</h2>
                        <h2 style={{ fontWeight: 300, color: "black" }}>{item.value}</h2>
                      </div>
                    );
                  })}
                </Row>
                <Row className="center">
                  <Button
                    type="text"
                    icon={<PlusCircleOutlined />}
                    ghost
                    onClick={() =>
                      history(`/inventory/add-inventory?inventory_item=${inventoryItem?._id}`)
                    }
                    // {
                    //   state: {
                    //     inventory_category: location?.state?.inventory_category,
                    //     inventory_item: inventoryItem?._id,
                    //     image: inventoryItem?.icon,
                    //     special_unit: location?.state?.special_unit,
                    //     inventory_category_name:
                    //       inventoryItem?.inventory_category?.inventory_category,
                    //     item_name: inventoryItem?.item_name,
                    //   },
                    // }
                    style={{
                      borderColor: "#2F80ED",
                      color: "#2F80ED",
                      borderRadius: 10,
                    }}
                  >
                    Add Inventory
                  </Button>
                </Row>
              </Card>
            </Col>
            <Col sm={24} lg={16}>
              <Row>
                <Col span={24} style={{ marginBottom: 40 }}>
                  <Card style={{ minHeight: 300, padding: "0 40px" }}>
                    <Row justify="space-between" align="middle">
                      <Col>
                        <h2
                          className="page-title"
                          style={{
                            fontSize: 20,
                            color: "#44464B",
                            textTransform: "uppercase",
                          }}
                        >
                          {inventoryItem?.item_name}
                        </h2>
                      </Col>
                      <Col>
                        <Typography.Link
                          onClick={() => history(`/inventory/edit-items/${inventoryItem._id}`)}
                        >
                          <EditFilled style={{ fontSize: 24 }} />
                        </Typography.Link>
                      </Col>
                    </Row>
                    <Divider
                      style={{
                        width: "100%",
                        boxShadow: "0px 1px 2px 1px white",
                        marginTop: -5,
                      }}
                    />
                    <Row>
                      <Space size={60}>
                        {itemDetails.map((item, i) => {
                          return (
                            <Col style={{ flexDirection: "column" }} className="center" key={i}>
                              <h3 style={{ fontSize: 18, fontWeight: 600 }}>{item?.value}</h3>
                              <p>{item?.title}</p>
                            </Col>
                          );
                        })}
                      </Space>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                      <p style={{ color: "#828282", fontSize: 17 }}>{inventoryItem?.description}</p>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Card style={{ minHeight: 300, padding: "0 40px" }}>
                    <Row>
                      <Col span={20}>
                        <h2
                          className="page-title"
                          style={{
                            fontSize: 20,
                            color: "#44464B",
                          }}
                        >
                          Vendors
                        </h2>
                      </Col>
                      <Col>
                        <Link
                          onClick={() => {
                            setAllVendors(true);
                            // history("/inventory/vendors");
                          }}
                          target="_blank"
                        >
                          See All
                        </Link>
                      </Col>
                    </Row>
                    <Divider
                      style={{
                        width: "100%",
                        boxShadow: "0px 1px 2px 1px white",
                        marginTop: -5,
                      }}
                    />
                    <div className="vendor-table">
                      <Row className="vendor-table-title-group" gutter={[20, 20]}>
                        <Col xl={2} xxl={2} sm={2} className="title "></Col>
                        <Col xl={6} xxl={6} sm={6} className="title">
                          Name
                        </Col>
                        <Col xl={3} xxl={3} sm={3} className="title">
                          Quantity
                        </Col>
                        <Col xl={3} xxl={3} sm={3} className="title ">
                          Price
                        </Col>
                        <Col xl={5} xxl={5} sm={5} className="title ">
                          Ordered Date
                        </Col>
                        <Col xl={5} xxl={5} sm={5} className="title ">
                          Recived Date
                        </Col>
                      </Row>

                      {vendorsOfInventoryItem?.map((vendor: any, i: string) => {
                        return (
                          <div key={i}>
                            <Row className="vendor-table-content-group" gutter={[20, 20]}>
                              <Col xl={2} xxl={2} sm={2} className="title ">
                                <ImageHolder
                                  src={MediaUrl(vendor?.vendor?.icon)}
                                  alt="vendor"
                                  className="vendor-image"
                                />
                              </Col>
                              <Col xl={6} xxl={6} sm={6} className="title">
                                <Typography.Link
                                  disabled={vendor?.vendor?.is_deleted}
                                  onClick={() =>
                                    vendor?.vendor?.is_deleted
                                      ? false
                                      : history(`/inventory/vendor/${vendor?.vendor?._id}`)
                                  }
                                >
                                  {vendor?.vendor?.vendor_name}
                                </Typography.Link>
                                <br />
                                <Typography.Paragraph style={{ marginBottom: 0 }} ellipsis>
                                  {vendor?.vendor?.contact_person?.email}
                                </Typography.Paragraph>
                              </Col>
                              <Col xl={3} xxl={3} sm={3} className="title">
                                {vendor?.material_select?.total_quantity}
                              </Col>
                              <Col xl={3} xxl={3} sm={3} className="title ">
                                {vendor?.price}
                              </Col>
                              <Col xl={5} xxl={5} sm={5} className="title ">
                                {moment(vendor?.ordered_date).format("MMM DD, YYYY")}
                              </Col>
                              <Col xl={5} xxl={5} sm={5} className="title ">
                                {moment(vendor?.recived_date).format("MMM DD, YYYY")}
                              </Col>
                            </Row>
                            <Divider
                              style={{
                                width: "100%",
                                boxShadow: "0px 1px 2px 1px white",
                                marginTop: -5,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
