import { API } from "aws-amplify";
import { ApiHeaders, API_NAME } from "../../API/variable";
import { ProductFetchParams, ProductDetailsParams, CreateProductParams } from "./ProductTypes";

//get User
export const takeProducts = (data: ProductFetchParams) =>
  API.get(API_NAME, "/product_management", {
    headers: ApiHeaders,
    queryStringParameters: data,
  });

//get Product Details
export const takeProduct = (data: ProductDetailsParams) =>
  API.get(API_NAME, `/product_management/${data?.id}`, {
    headers: ApiHeaders,
    data: data,
  });

//create Product Details
export const makeProduct = (data: CreateProductParams) =>
  API.post(API_NAME, "/product_management", {
    headers: ApiHeaders,
    body: data,
  });

//modify Product
export const modifyProduct = (data: any) =>
  API.post(API_NAME, `/product_management/${data?._id}`, {
    headers: ApiHeaders,
    body: data,
  });

//delete Product
export const cutProduct = (data: ProductDetailsParams) =>
  API.post(API_NAME, "/insuranceProductAdmin", {
    headers: ApiHeaders,
    data: data,
  });

//dropdown items
export const getProductDropDownItems = (data: any) =>
  API.get(API_NAME, "/item_dropdown", {
    headers: ApiHeaders,
    queryStringParameters: data,
  });
export const getNonLeather = (data: any) =>
  API.get(API_NAME, "/non_leather", {
    headers: ApiHeaders,
    queryStringParameters: data,
  });
export const addProductSizeHardware = (data: any) =>
  API.post(API_NAME, `/product_default_inventory`, {
    headers: ApiHeaders,
    body: data,
  });

export const deleteProductInventory = (id: any) => {
  const data = API.del(API_NAME, `/product_default_inventory/${id}`, {
    headers: ApiHeaders,
  });
  return data;
};
export const getProductSizeHardware = (data: { id: string }) =>
  API.get(API_NAME, `/product_default_inventory/${data?.id}`, {
    headers: ApiHeaders,
  });

export const duplicateLeather = (data: {
  productId: any;
  duplicateProductDefaultInventory: any;
  leatherId: any;
}) =>
  API.post(API_NAME, `/duplicateProductDefaultInventory`, {
    headers: ApiHeaders,
    body: data,
  });
