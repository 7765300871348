/** @format */

import { Route, Routes } from 'react-router'
import SizingForm from '../Components/Admin/SizingForm/SizingForm'
import StaticContentsForm from '../Components/Admin/StaticContentsForm/StaticContentsForm'
import withPermission from '../Utils/checkPermission'
import SizingPreview from '../Views/Admin/StaticContents/SizingPreview/SizingPreview'
import StaticContents from '../Views/Admin/StaticContents/StaticContents'
import Sizing from '../Views/Orders/Sizing'

export const StaticContentsRoute = (props: any) => {
  return (
    <Routes>
      <Route path='/static-contents/sizing' element={<Sizing />} />
      <Route
        path='/static-contents/sizing/preview/:productId'
        element={<SizingPreview />}
      />
      {/* <Route
      
        path={`${props.match?.path}/sizing/add`}
        element={<SizingForm />}
      /> */}
      <Route path={`/sizing/:productId`} element={<SizingForm />} />
      <Route path={`/:slug`} element={<StaticContents />} />
      <Route
        path={`/:slug/:contentId`}
        element={<StaticContentsForm title='' />}
      />
    </Routes>
  )
}
export default withPermission(StaticContentsRoute, 'staticContents')
