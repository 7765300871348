/** @format */

import { Card, Col, Divider, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect } from "react";
import { useForm } from "antd/lib/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { fetchBlog } from "../../../Redux/BlogRedux/BlogActions";
import PostBuilder from "v2/BlogBuilder/BlogBuilder";
import { BlogWrapper } from "v2/BlogBuilder/BlogWrapper";
import PageLoading from "Components/Admin/PageLoading/PageLoading";

export interface ImageLayoutProps {
  id: number;
  isRow: boolean;
  parentId?: number;
  contentId?: number;
  size?: number;
  contentType?: string;
  url?: any;
}

export const Blogetails = () => {
  const dispatch = useDispatch();
  const blogs = useSelector((state: any) => state.BlogReducer);

  const { slug } = useParams<any>();
  const { details } = blogs;
  useEffect(() => {
    dispatch(fetchBlog({ blogId: slug }));
  }, [slug]);
  return (
    <Content>
      <PageLoading loading={blogs?.detailing}>
        <Row justify="center">
          <Col lg={15}>
            <Card>
              <Typography.Title>{details?.title}</Typography.Title>
              <Divider />
              <BlogWrapper data={details?.content} primaryMedia={details?.primaryMedia} />
            </Card>
          </Col>
        </Row>
      </PageLoading>
    </Content>
  );
};

export default Blogetails;

//https://clauderic.github.io/react-sortable-hoc/#/basic-configuration/drag-handle?_k=2m5u57
