// import { ArrowUpOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";

import CommonS3UploadFiles from "../../../Components/Admin/S3UploadFiles/CommonS3UploadFiles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FORGOT_PASSWORD } from "../../../Redux/AuthRedux/AuthActions";
import { createMember, updateMember } from "../../../Redux/MemberRedux/MemberActions";
import { AdminRoles } from "./Members";
import MediaUrl from "../../../Utils/mediaUrl";
import { UpdatePassword } from "../../../Redux/MemberRedux/MemberApis";
import { setLayout } from "../../../Redux/Actions/LayoutActions";
import dayjs from "dayjs";
const { Option } = Select;

const ResetPasswordButton = ({ data }: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onSubmit = async (value: any) => {
    console.log({ value });
    try {
      await UpdatePassword({ ...value });
      message.success("Password updated successfully");
      handleCancel();
    } catch (err: any) {
      message.error(err.response.data.message);
    }

    // dispatch(FORGOT_PASSWORD.request(value))
  };

  const onFinishFailed = (errorInfo: any) => {};
  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
  const validatePassword = (password: string) => {
    return strongRegex.test(password);
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <Button type="primary" onClick={showModal}>
            Reset Password
          </Button>
        </Col>
      </Row>
      <Modal
        title="Reset Password"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={""}
      >
        <Form
          // form={form}
          onFinish={onSubmit}
          layout="vertical"
          initialValues={data}
          // onReset={onCancel}
          onFinishFailed={onFinishFailed}
        >
          <Col lg={24}>
            <Form.Item label="Email" name="email" read-only="">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              label="New Password"
              name="password"
              rules={[
                { required: true, message: "Temporary Password is required" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!validatePassword(value)) {
                      return Promise.reject(new Error("Password not strong enough"));
                    }
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error("The two passwords that you entered do not match!")
                    );
                  },
                }),
              ]}
              help="Password policy uppercase letters, lowercase letters, special characters,
                    numbers"
            >
              <Input />
            </Form.Item>
            <Col span={24}>
              <Button type="primary" htmlType="submit">
                Reset Password
              </Button>
            </Col>
          </Col>
        </Form>
      </Modal>
    </>
  );
};

export const AddMember = (props: any) => {
  const { data, onCancel, refetch } = props;

  const members = useSelector((state: any) => state.MemberReducer);

  const layout = useSelector((state: any) => state.LayoutReducers);

  const [form] = useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    form.resetFields();
    const newEmail = data?.email?.substring(0, data?.email.indexOf("@"));
    const newdata = { ...data, email: newEmail };
    form.setFieldsValue(newdata);
  }, [data]);

  useEffect(() => {
    if (layout?.created || layout?.updated) {
      refetch();
      dispatch(
        setLayout({
          created: false,
          updated: false,
        })
      );
    }
  }, [layout?.created, layout?.updated]);

  const onSubmit = (value: any) => {
    const email = value?.email + "@latido.com.np";
    if (data) {
      dispatch(updateMember({ ...value, _id: data?._id, email: email }));
    } else {
      dispatch(createMember({ ...value, email: email }));
    }
    // onCancel();
  };

  return (
    <Card style={{ marginTop: 40 }}>
      <Row style={{ marginTop: 40 }} justify="center">
        <Col lg={12}>
          <Typography.Title level={3} style={{ marginBottom: 40 }}>
            {data ? "EDIT" : "ADD"}
          </Typography.Title>
          {data && (
            <>
              <Descriptions
                column={2}
                layout="horizontal"
                style={{ marginTop: 20 }}
                labelStyle={{ fontSize: 12, paddingBottom: 0 }}
                contentStyle={{ fontWeight: "bold", fontSize: 12 }}
              >
                <Descriptions.Item label="Created At" span={1}>
                  {dayjs(data?.createdAt)?.format("lll")}
                </Descriptions.Item>
                <Descriptions.Item label="Last Updated At" span={1}>
                  {dayjs(data?.updatedAt)?.format("lll")}
                </Descriptions.Item>
                {/* <Descriptions.Item label='Connect With'>
              <FacebookFilled style={{ fontSize: 18 }} />
              <WhatsAppOutlined style={{ fontSize: 18, marginLeft: 10 }} />
            </Descriptions.Item> */}
              </Descriptions>
              <Divider />
            </>
          )}
          <Form
            form={form}
            onFinish={onSubmit}
            layout="vertical"
            initialValues={data ? data : null}
            onReset={onCancel}
          >
            <Row justify="start" gutter={[20, 20]}>
              <Form.Item label="_id" hidden name="name">
                <Input placeholder="Name" />
              </Form.Item>
              <Col lg={12}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Designation"
                  name="role"
                  rules={[{ required: true, message: "Role is required" }]}
                >
                  <Select mode="multiple">
                    {AdminRoles?.map((role) => {
                      return <Option value={role?.value}>{role.title}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    { required: true, message: "Email is required" },
                    {
                      pattern: new RegExp("^[a-zA-Z0-9+_.-]+$"),
                      message: "Please enter correct format!!",
                    },
                  ]}
                >
                  <Input
                    disabled={data?.email}
                    type="text"
                    placeholder="example123"
                    suffix="@latido.com.np"
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Optional Email Address"
                  name="altEmail"
                  rules={[{ type: "email", message: "Email is invalid" }]}
                >
                  <Input type="email" />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Phone Number"
                  name="phone_number"
                  rules={[
                    { required: true, message: "Phone is required" },
                    {
                      pattern: /^(?:\+977)?[9][6-9]\d{8}$/,
                      message: "Phone is invalid",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Whatsapp Number" name="whats_app">
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Address" name="address">
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Facebook Link" name="facebook_url">
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Instagram Link" name="instagram_url">
                  <Input />
                </Form.Item>
              </Col>
              {!data && (
                <Col lg={12}>
                  <Form.Item
                    label="Password"
                    name="temporaryPassword"
                    rules={[
                      {
                        required: data ? false : true,
                        message: "Temporary Password is required",
                      },
                    ]}
                    help="Password policy uppercase letters, lowercase letters, special characters,
                    numbers"
                  >
                    <Input.Password disabled={data ? true : false} />
                  </Form.Item>
                </Col>
              )}
              {data && (
                <Col lg={24}>
                  <ResetPasswordButton data={data} />
                </Col>
              )}
              <Col sm={24}>
                {/* <Dragger className="custom-dragger">
                  <DraggerUpload />
                </Dragger> */}
                <Form.Item name="profile_image">
                  <CommonS3UploadFiles
                    type="product"
                    module="product"
                    ratio={[1, 1]}
                    url={data?.profile_image}
                    style={{ width: "100%", minHeight: 400 }}
                    onUpload={(e) => {
                      form.setFieldsValue({ profile_image: MediaUrl(e) });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={24} style={{ marginTop: 50 }}>
                <Form.Item>
                  <Button
                    loading={members?.creating || members?.updating}
                    shape="round"
                    htmlType="submit"
                    type="primary"
                  >
                    {data ? "Update" : "Save"}
                  </Button>
                  <Button
                    disabled={members?.creating || members?.updating}
                    shape="round"
                    htmlType="reset"
                    style={{ marginLeft: 15 }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

export default AddMember;
