import { Progress, Typography } from "antd";

export const CircularProgress = (props: any) => {
  const { size, color, negative, pr, trailingColor } = props;

  console.log(pr, "pr");
  return (
    <div>
      <svg
        width="100%"
        height="100%"
        style={{ visibility: "hidden", position: "absolute", zIndex: -1 }}
      >
        <filter
          id="filter1"
          x="-20%"
          y="-20%"
          width="140%"
          height="140%"
          filterUnits="objectBoundingBox"
          primitiveUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feDropShadow
            stdDeviation="9 9"
            in="SourceGraphic"
            dx="0"
            dy="5"
            flood-color={color}
            flood-opacity="1"
            x="0%"
            y="0%"
            width="100%"
            height="100%"
            result="dropShadow"
          />
        </filter>
        <filter id="shadow2">
          <feDropShadow dx="0" dy="0" stdDeviation={5} flood-color={color} />
        </filter>
      </svg>
      <Progress
        className="custom-progress"
        strokeColor={color}
        strokeLinecap="round"
        type="circle"
        percent={pr}
        width={size}
        strokeWidth={15}
        style={{ padding: 20 }}
        trailColor={trailingColor || "#E8E8E8"}
        format={(percent) => (
          // ${
          //   negative ? "-" : "+"

          //   }
          <Typography.Text className="inside-progress">{`
      
          ${percent}%`}</Typography.Text>
        )}
      />
    </div>
  );
};

export default CircularProgress;
