/** @format */

import { Card, Col, Row, Typography } from 'antd'
import { FC } from 'react'
import PreviewHorizontalTable from '../PreviewHorizontalTable/PreviewHorizontalTable'

interface previewCard {
  data?: any
}
const PreviewCard: FC<previewCard> = ({ data }) => {
  return (
    <>
      <Card style={{ padding: '20px 40px' }}>
        <Typography.Title level={1}>{data.campaign}</Typography.Title>
        <Typography.Text>{data.details}</Typography.Text>
        <Row style={{ marginTop: 30 }}>
          <Col span={20}>
            <PreviewHorizontalTable data={data.cardTable} />
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default PreviewCard
