import { CheckOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { fetchUsers } from "../../API";

export const ContactNumber = () => {
  const [selectedUser, setSelectedUser] = useState<any>({});
  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const navigation = useNavigate();
  // fetching user data with phone number
  const { data, isFetching } = useQuery(["users", searchQuery], () => fetchUsers(searchQuery), {
    enabled: Boolean(searchQuery),
    refetchOnWindowFocus: false,
  });

  const getContactNumber = (e: any) => {
    setInputValue(e?.target?.value);
  };

  useEffect(() => {
    if (inputValue?.length > 2 && inputValue !== selectedUser?.phone) {
      setSearchQuery(inputValue);
    }
  }, [inputValue]);

  const createNewCustomer = () => {
    const regex = /^(?:\+977)?[9][6-9]\d{8}$/;
    if (regex.test(inputValue)) {
      navigation(
        `/store/create/order/create-new/${
          inputValue?.includes("+977") ? inputValue?.replace("+977", "") : inputValue
        }`
      );
    } else {
      message.error("Please enter valid number");
    }
  };

  useEffect(() => {
    const phoneNumber = selectedUser?.phone;
    const length = phoneNumber?.length;
    if (phoneNumber?.startsWith("+")) {
      setInputValue(phoneNumber?.substring(4, length));
    } else {
      setInputValue(phoneNumber);
    }
  }, [selectedUser]);

  const handleNext = () => {
    navigation("/store/create/order/customer", {
      state: selectedUser,
    });
  };
  return (
    <div className="contact-number">
      <h1 className="page-title">Create Order</h1>
      <div className="contact-number__content">
        <Form>
          <Row style={{ marginTop: 50, display: "flex" }}>
            <Col style={{ height: 45, display: "flex", alignItems: "center" }}>
              <label htmlFor="contact">Contact Number</label>
            </Col>
            <Col>
              <Input
                id="contact"
                className="input-field white-border contact__number"
                // type="number"
                placeholder="98XXXXXXXXX"
                value={inputValue}
                onChange={(e) => getContactNumber(e)}
                prefix="+977"
                style={{ fontSize: 16 }}
                maxLength={10}
              />
              <div className="search-result">
                {isFetching ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px 0",
                    }}
                  >
                    <Spin />
                  </div>
                ) : (
                  data?.slice(0, 6).map((item: any) => {
                    return (
                      <div
                        key={item?.uuid}
                        className={`search-result__item ${
                          selectedUser?._id === item?._id ? "selected" : ""
                        }`}
                        onClick={() => setSelectedUser(item)}
                      >
                        <div
                          className={`icon-container ${
                            selectedUser?._id === item?._id ? "selected" : ""
                          }`}
                        >
                          <CheckOutlined />
                        </div>
                        <div>
                          <h4>{item?.phone}</h4>
                          <p>{item?.name}</p>
                        </div>
                      </div>
                    );
                  })
                )}

                {data?.length === 0 && (
                  <div
                    // key={item?.uuid}
                    className={`search-result__item`}
                    onClick={() => createNewCustomer()}
                  >
                    <div className={`icon-container`}>
                      <CheckOutlined />
                    </div>
                    <div>
                      <h4>{"Not Found"}</h4>
                      <p>{"Create New Customer"}</p>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Form>
        {selectedUser?.phone && (
          <Row style={{ marginTop: 40 }}>
            <Button style={{ width: 120 }} onClick={handleNext}>
              Next
            </Button>
          </Row>
        )}
      </div>
    </div>
  );
};
