import {
  GET_LEATHER_ADD_ON,
  GET_LEATHER_ADD_ON_FAILURE,
  GET_LEATHER_ADD_ON_SUCCESS,
  GET_LEATHER_INVENTORY,
  GET_LEATHER_INVENTORY_FAILURE,
  GET_LEATHER_INVENTORY_SUCCESS,
  GET_LEATHER_PROFILE,
  GET_LEATHER_PROFILE_FAILURE,
  GET_LEATHER_PROFILE_SUCCESS,
  POST_LEATHER_ADD_ON,
  POST_LEATHER_ADD_ON_FAILURE,
  POST_LEATHER_ADD_ON_SUCCESS,
  POST_LEATHER_PROFILE,
  POST_LEATHER_PROFILE_FAILURE,
  POST_LEATHER_PROFILE_SUCCESS,
} from '../Constants/constants'

//Get Leather PRofile data
export const GetLeatherProfile = (params: any) => {
  return { type: GET_LEATHER_PROFILE, payload: params }
}
export const GetLeatherProfileSuccess = (data: any) => ({
  type: GET_LEATHER_PROFILE_SUCCESS,
  payload: data,
})
export const GetLeatherProfileFailure = (error: any) => ({
  type: GET_LEATHER_PROFILE_FAILURE,
  payload: error,
})

//post leather profile data
export const PostLeatherProfile = (data: any) => ({
  type: POST_LEATHER_PROFILE,
  payload: data,
})
export const PostLeatherProfileSuccess = () => ({
  type: POST_LEATHER_PROFILE_SUCCESS,
})
export const PostLeatherProfileFailure = (error: any) => ({
  type: POST_LEATHER_PROFILE_FAILURE,
  payload: error,
})

//Get Leather Add on
export const GetLeatherAddOn = (params: any) => {
  return { type: GET_LEATHER_ADD_ON, payload: params }
}
export const GetLeatherAddOnSuccess = (data: any) => ({
  type: GET_LEATHER_ADD_ON_SUCCESS,
  payload: data,
})
export const GetLeatherAddOnFailure = (error: any) => ({
  type: GET_LEATHER_ADD_ON_FAILURE,
  payload: error,
})

//PostLeather Add on
export const PostLeatherAddOn = (params: any) => {
  return { type: POST_LEATHER_ADD_ON, payload: params }
}
export const PostLeatherAddOnSuccess = () => ({
  type: POST_LEATHER_ADD_ON_SUCCESS,
})
export const PostLeatherAddOnFailure = (error: any) => ({
  type: POST_LEATHER_ADD_ON_FAILURE,
  payload: error,
})

//Get Leather PRofile data
export const GetLeatherInventory = (params: any) => {
  return { type: GET_LEATHER_INVENTORY, payload: params }
}
export const GetLeatherInventorySuccess = (data: any) => ({
  type: GET_LEATHER_INVENTORY_SUCCESS,
  payload: data,
})
export const GetLeatherInventoryFailure = (error: any) => ({
  type: GET_LEATHER_INVENTORY_FAILURE,
  payload: error,
})
