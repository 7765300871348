import { CheckOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Form, Input, Result, Row, Space, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { fetchTrackOrder } from "../../API";
import "./style.scss";
import Moment from "moment";
import { LatidoAnimatedLogoLoader } from "../../Components/LatidoAnimateLogo/LatidoAnimateLogo";
import { ReactComponent as NotFound } from "../../Assets/Icons/not-found.svg";
import { TrackOrderDetailsType } from "../../Types/TrackOrderType";

const TrackOrderDetails = () => {
  const { id }: any = useParams();

  const [orderId, setOrderId] = useState(id);
  const [FactoryData, setFactoryData] = useState<any>([]);
  const [storeData, setStoreData] = useState<any>([]);
  // fetching order details
  const {
    data,
    error,
    isFetching,
  }: { data?: TrackOrderDetailsType; error: any; isFetching: boolean } = useQuery(
    ["orderDetails", id],
    () => fetchTrackOrder(id),
    {
      enabled: Boolean([id]),
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5000,
    }
  );
  useEffect(() => {
    if (data) {
      setFactoryData([
        {
          title: "Pattern Selection",
          isCompleted: data?.orderProgress?.package_selection_complete,
          name: data?.orderDetail?.assignee?.pattern_selection
            ?.map((sel) => sel.pattern_selector_name?.name)
            ?.join(","),
          remaining_date: data?.orderDetail?.assignee?.pattern_selection
            ?.map((sel) => sel?.date)
            ?.join(","),
        },
        {
          title: "Leather Selection",
          isCompleted: data?.orderProgress?.leather_cutting_complete,
          // name: data?.orderDetail?.assignee?.leather_cutting?.[0]?.leather_cutter_name?.name,
          // remaining_date: data?.orderDetail?.assignee?.leather_cutting?.[0]?.date,
          name: data?.orderDetail?.assignee?.leather_cutting
            ?.map((sel) => sel.leather_cutter_name?.name)
            ?.join(","),
          remaining_date: data?.orderDetail?.assignee?.leather_cutting
            ?.map((sel) => sel?.date)
            ?.join(","),
        },
        {
          title: "Stitching",
          isCompleted: data?.orderProgress?.leather_stitching_complete,
          // name: data?.orderDetail?.assignee?.stitching?.[0]?.seamstress_name?.name,
          // remaining_date: data?.orderDetail?.assignee?.stitching?.[0]?.date,

          name: data?.orderDetail?.assignee?.stitching
            ?.map((sel) => sel.seamstress_name?.name)
            ?.join(","),
          remaining_date: data?.orderDetail?.assignee?.stitching
            ?.map((sel) => sel?.date)
            ?.join(","),
        },
        {
          title: "Verification",
          isCompleted: data?.orderProgress?.leather_verification_complete,
          // name: data?.orderDetail?.assignee?.checking_verification?.[0]?.inspector_name?.name,
          // remaining_date: data?.orderDetail?.assignee?.checking_verification?.[0]?.date,

          name: data?.orderDetail?.assignee?.checking_verification
            ?.map((sel) => sel.inspector_name?.name)
            ?.join(","),
          remaining_date: data?.orderDetail?.assignee?.checking_verification
            ?.map((sel) => sel?.date)
            ?.join(","),
        },
        {
          title: "Cleaning",
          isCompleted: data?.orderProgress?.leather_cleaning_complete,
          // name: data?.orderDetail?.assignee?.cleaning?.[0]?.cleaner_name?.name,
          // remaining_date: data?.orderDetail?.assignee?.cleaning?.[0]?.date,

          name: data?.orderDetail?.assignee?.cleaning
            ?.map((sel) => sel?.cleaner_name?.name)
            ?.join(","),
          remaining_date: data?.orderDetail?.assignee?.cleaning?.map((sel) => sel?.date)?.join(","),
        },
      ]);
      setStoreData([
        {
          title: "Verify",
          isCompleted: data?.orderProgress?.package_selection_complete,
        },
        {
          title: "Sent to Delivery",
          isCompleted: data?.orderProgress?.package_selection_complete,
        },
      ]);
    } else {
      setFactoryData([]);
    }
  }, [data]);

  const navigation = useNavigate();

  const onSubmit = () => {
    navigation(`/store/track-order/${orderId}`);
  };

  return (
    <div className="track-order">
      <h1 className="page-title">TRACK ORDER</h1>
      <div className="track-order__content">
        <Row
          gutter={30}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Col>
            <h4>Order Number</h4>
          </Col>
          <Col>
            <Form>
              <Input
                className="track-order__input"
                type="number"
                value={orderId}
                onChange={(e) => {
                  setOrderId(e.currentTarget?.value);
                }}
                disabled={false}
              />
              <Row hidden style={{ marginTop: 40 }}>
                <Button style={{ width: 120 }} htmlType="submit" onClick={onSubmit}></Button>
              </Row>
            </Form>
          </Col>
        </Row>
        {isFetching ? (
          <Row justify="center" align="middle" style={{ height: 100, width: "100%" }}>
            {/* <Spin /> */}
            <LatidoAnimatedLogoLoader />
          </Row>
        ) : !data ? (
          <Result
            status={404}
            title="ORDER NOT FOUND"
            subTitle="Unfortunately, Order associated with provided order number not found. Please check order number and try again."
          />
        ) : (
          <Row style={{ marginTop: 45 }}>
            <Card style={{ width: "100%" }}>
              {/* factory */}
              <Row className=" row">
                <div className="section-title">
                  <div className="icon-container">
                    <CheckOutlined />
                  </div>
                  <span>Factory</span>
                </div>
                    <div style={{
                      marginBottom: "2rem",
                      fontSize: "1rem",
                      fontWeight: "bold"
                }}>
                      Estd. Delivery Date:{" "}
                      <span style={{
                        color: "#4D9DE7"
                      }}>
                  {data?.orderDetail?.delivery_date &&
                    Moment(data?.orderDetail?.delivery_date).format("DD MMM YYYY")}
                    </span>
                </div>
              </Row>
              <Row gutter={30} className="table-title">
                <Col className="table-title__text" sm={6}>
                  Status
                </Col>
                <Col className="table-title__text" sm={6}>
                  Order Process
                </Col>
                <Col className="table-title__text" sm={6}>
                  Workers
                </Col>
                <Col className="table-title__text" sm={6}>
                  ETC
                </Col>
              </Row>
              <div className="table-container">
                {FactoryData.length > 0 ? (
                  FactoryData?.map((data: any, index: any) => {
                    return (
                      <>
                        {index > 0 && (
                          <Row style={{ width: "100%" }} gutter={[30, 30]}>
                            <Col sm={6} className={`table-content `}>
                              <div className={`line  ${data?.isCompleted ? "" : "unActive"}`}></div>
                            </Col>
                          </Row>
                        )}
                        <Row gutter={[30, 30]} style={{ width: "100%", margin: "6px 0" }}>
                          <Col className="table-content" sm={6}>
                            <div
                              className={`icon-container ${data?.isCompleted ? "" : "unActive"}`}
                            >
                              <CheckOutlined />
                            </div>
                          </Col>
                          <Col className="table-content" sm={6}>
                            {data?.title}
                          </Col>
                          <Col className="table-content" sm={6}>
                            {data?.name}
                          </Col>
                          <Col className="table-content" sm={6}>
                            {data?.remaining_date
                              ?.split(",")
                              ?.map((data: any) => Moment(data).format("DD MMM YYYY"))
                              ?.join(",")}
                          </Col>
                        </Row>
                      </>
                    );
                  })
                ) : (
                  <Space align="center" direction="vertical">
                    <NotFound />

                    <Typography.Paragraph style={{ fontWeight: "bold" }}>
                      We do not have any status for this order.
                    </Typography.Paragraph>
                  </Space>
                )}
              </div>

              {/* Store */}
              <Row>
                <div
                  className={`section-title ${
                    data?.orderDetail?.location === "store" ? "" : "store"
                  }`}
                >
                  <div className="icon-container">
                    <CheckOutlined />
                  </div>
                  <span>Store</span>
                </div>
              </Row>
              <Row gutter={30} className="table-title" hidden>
                <Col className="table-title__text" sm={6}>
                  Status
                </Col>
                <Col className="table-title__text" sm={6}>
                  Order Process
                </Col>
                <Col className="table-title__text" sm={12}>
                  Workers
                </Col>
              </Row>
              {/* <div className="table-container">
                <Row gutter={[30, 30]} style={{ width: "100%", margin: "4px 0" }}>
                  <Col className="table-content" sm={6}>
                    <div className="icon-container unActive">
                      <CheckOutlined />
                    </div>
                  </Col>
                  <Col className="table-content" sm={6}>
                    Stitching
                  </Col>
                  <Col className="table-content" sm={12}>
                    Ram Bhahadur 2
                  </Col>
                </Row>
                <Row style={{ width: "100%" }} gutter={[30, 30]}>
                  <Col sm={6} className="table-content">
                    <div className="line unActive"></div>
                  </Col>
                </Row>
                <Row gutter={[30, 30]} style={{ width: "100%", margin: "4px 0" }}>
                  <Col className="table-content" sm={6}>
                    <div className="icon-container unActive">
                      <CheckOutlined />
                    </div>
                  </Col>
                  <Col className="table-content" sm={6}>
                    Verifying
                  </Col>
                  <Col className="table-content" sm={12}>
                    Ram Bhahadur 3
                  </Col>
                </Row>
              </div> */}
            </Card>
          </Row>
        )}
      </div>
    </div>
  );
};

export default TrackOrderDetails;
