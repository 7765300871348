import { Reducer } from "react";
import { retry } from "redux-saga/effects";
import {
  CREATE_INVENTORY_ITEM,
  CREATE_INVENTORY_ITEM_FAILURE,
  CREATE_INVENTORY_ITEM_SUCCESS,
  CREATE_INVENTORY_ITEM_TRACK,
  CREATE_INVENTORY_ITEM_TRACK_FAILURE,
  CREATE_INVENTORY_ITEM_TRACK_SUCCESS,
  FETCH_INVENTORY_ITEM,
  FETCH_INVENTORY_ITEM_FAILURE,
  FETCH_INVENTORY_ITEM_SUCCESS,
  FETCH_INVENTORY_ITEM_TRACK,
  FETCH_INVENTORY_ITEM_TRACK_FAILURE,
  FETCH_INVENTORY_ITEM_TRACK_SUCCESS,
  FETCH_VENDOR_OF_INVENTORY_ITEM,
  FETCH_VENDOR_OF_INVENTORY_ITEM_FAILURE,
  FETCH_VENDOR_OF_INVENTORY_ITEM_SUCCESS,
} from "./InventoryItemTypes";

const INIT_STATE = {
  inventoryItem: {},
  vendorsOfInventoryItem: [],
  inventoryItemTrack: [],
  vendorsOfInventoryItemPagination: {},
  error: "",
  loading: false,
  fetchingVendor: false,
  creating: false,
  created: false,
};

export const InventoryItemReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
  switch (action.type) {
    //fetch  Inventory Item
    case FETCH_INVENTORY_ITEM:
      return { ...state, loading: true, created: false };
    case FETCH_INVENTORY_ITEM_SUCCESS:
      return { ...state, loading: false, inventoryItem: action.payload, created: true };
    case FETCH_INVENTORY_ITEM_FAILURE:
      return { ...state, loading: false, error: action.payload };

    //fetch  Inventory Item
    case CREATE_INVENTORY_ITEM:
      return { ...state, creating: true };
    case CREATE_INVENTORY_ITEM_SUCCESS:
      return { ...state, creating: false };
    case CREATE_INVENTORY_ITEM_FAILURE:
      return { ...state, creating: false };

    //fetch  Inventory Item
    case CREATE_INVENTORY_ITEM_TRACK:
      return { ...state, creating: true };
    case CREATE_INVENTORY_ITEM_TRACK_SUCCESS:
      return { ...state, creating: true };
    case CREATE_INVENTORY_ITEM_TRACK_FAILURE:
      return { ...state, creating: false };

    //create  Inventory Item Track
    case FETCH_INVENTORY_ITEM_TRACK:
      return { ...state, loading: true, creating: true };
    case FETCH_INVENTORY_ITEM_TRACK_SUCCESS:
      return { ...state, loading: false, inventoryItemTrack: action.payload, creating: false };
    case FETCH_INVENTORY_ITEM_TRACK_FAILURE:
      return { ...state, loading: false, creating: false };

    //create  Inventory Item Track
    case FETCH_VENDOR_OF_INVENTORY_ITEM:
      return { ...state, fetchingVendor: true };
    case FETCH_VENDOR_OF_INVENTORY_ITEM_SUCCESS:
      return {
        ...state,
        fetchingVendor: false,
        vendorsOfInventoryItemPagination: action?.payload,
        vendorsOfInventoryItem: action.payload?.data,
      };
    case FETCH_VENDOR_OF_INVENTORY_ITEM_FAILURE:
      return { ...state, fetchingVendor: false };

    default:
      return state;
  }
};
