import { FC, useEffect, useState } from "react";
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Row, Spin, Typography } from "antd";
import InputSelect from "../../Components/Select";
import { useCreateOrder, useUpdateOrder } from "../../API/customHooks";
import { parseLeftToPay } from "../../Utils";
// import { getPaymentMode } from "../../API";
import { notification } from "antd";
import { CompletionProcess, OrderManageProcess, PaymentOptions, PaymentStatus } from "./OrdersEnum";

interface Props {
  visible?: boolean;
  order?: any;
  handleClose?: () => void;
}
const paymentOption = PaymentOptions.map((i) => {
  return { value: i, title: i.split("_").join(" ").toUpperCase() };
});

//     [
//   {
//     value: "cash_on_delivery",
//     title: "Cash On Delivery",
//   },
//   {
//     value: "wallet",
//     title: "Wallet",
//   },
//   {
//     value: "mobile_banking",
//     title: "Mobile Banking",
//   },
// ];
const DeliveryFormModal: FC<Props> = ({ visible = false, handleClose = () => { }, order = {} }) => {
  const [form] = Form.useForm();
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

  const {
    mutate: updateOrder,
    isLoading: updateLoading,
    status: updateStatus,
    error: updateError,
  }: any = useUpdateOrder();
  const { mutate: createOrder, isLoading, status, error: createError }: any = useCreateOrder();

  const [paymentMode, setPaymentMode] = useState("");

  const handleSubmit = async (values: any) => {
    const data = { ...order, payment_status: isPaymentSuccess ? PaymentStatus.PAYMENT_COMPLETE : PaymentStatus.PAYMENT_INCOMPLETE };

    // await updateOrder(data);
    if (isPaymentSuccess) {
      await createOrder({
        ...data,
        payment_mode: paymentMode,
        payment_success: isPaymentSuccess,
        bypass_otp: true,
        // order_state: "Delivered",
        completion_process: CompletionProcess.DELIVERED,
      });
    }
    else {
      notification.error({
        message: "Cannot deliver the product without payment!!",
        placement: "top",
      });
    }
  };

  const handelSelectChange = (value: string) => {
    // console.log('value: ', value);
    setPaymentMode(value);
    // getPaymentMode(order?._id, value);
  };
  useEffect(() => {
    setIsPaymentSuccess(order.payment_status === PaymentStatus.PAYMENT_COMPLETE ? true : false);
    const advance_amount = order?.advance_amount;
    const total_amount = order?.total_amount;
    const total_discount = order?.total_discount;
    form.setFieldsValue({
      // payment_gateway: order?.payment_gateway,
      payment_mode: order?.payment_mode,
      advance_amount,
      store_payment: order?.store_payment,
      base_size: order?.sizing?.base_size,
      username: order?.user_id?.name,
      phone: order?.user_id?.phone,
      name: order?.product?.name,
      leather_profile: order?.product_specification?.leather_id?.item_name,
      total_amount,
      total_discount,
      tax_amount: order?.tax_amount,
      total_payable: order?.total_payable,
      left_to_pay: parseLeftToPay({
        advance_amount,
        total_amount,
        total_discount,
      }),
    });
  }, [order]);

  useEffect(() => {
    if (status === "success") {
      handleClose();
    }
  }, [status]);

  return (
    <Modal
      centered
      visible={visible}
      onCancel={handleClose}
      footer={null}
      closable={false}
      maskStyle={{ backdropFilter: "blur(4px)", background: "rgba(0,0,0,0.3)" }}
      destroyOnClose={true}
      width={"45%"}
      bodyStyle={{ padding: "30px" }}
      className="delivery-form"
    >
      <Row align="middle" className="delivery-form__header">
        <Col className="delivery-form__header__orderId">
          <h4>Order No. #{order?.order_no}</h4>
        </Col>
        <Col className="delivery-form__header__title">
          <h2>Delivery Form</h2>
        </Col>
      </Row>
      <Divider style={{ padding: 0, margin: 0 }} />

      <Form
        layout="vertical"
        className="delivery-form__container"
        form={form}
        onFinish={handleSubmit}
        colon={false}
        requiredMark={false}
      >
        {/* <Row gutter={[50, 50]} className='form-row'>
          <Col sm={24} lg={12}>
            <Form.Item label='Product Name' name='name'>
              <Input type='text' className='input-field' disabled />
            </Form.Item>
          </Col>
          <Col sm={24} lg={12}>
            <Form.Item label='Leather Profile' name='leather_profile'>
              <Input type='text' className='input-field' disabled />
            </Form.Item>
          </Col>
        </Row>
        */}
        <Row gutter={[50, 50]} className="form-row">
          {/*
          <Col sm={24} lg={12}>
            <Form.Item label='Base SIze' name='base_size'>
              <Input type='text' className='input-field' disabled />
            </Form.Item>
          </Col>
          */}

          <Col sm={24} lg={12}>
            <Form.Item label="Total Amount" name="total_amount" required>
              <Input type="text" className="input-field" disabled />
            </Form.Item>
          </Col>
          <Col sm={24} lg={12}>
            <Form.Item label="Total Discount" name="total_discount" required>
              <Input type="text" className="input-field-container" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[50, 50]} className="form-row">
          {/*<Col sm={24} lg={12}>*/}
          {/*  <Form.Item label="Tax Amount" name="tax_amount" required>*/}
          {/*    <Input type="text" className="input-field" />*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}
          <Col sm={24} lg={12}>
            <Form.Item label="advance" name="advance_amount" required>
              <Input type="text" className="input-field" disabled />
            </Form.Item>
          </Col>
          <Col sm={24} lg={12}>
            <Form.Item label="Left To Pay" name="left_to_pay">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[50, 50]} className="form-row">
          <Col sm={24} lg={12}>
            <Form.Item label="Payment Mode" name="store_payment">
              <InputSelect
                handleChange={handelSelectChange}
                defaultValue={order?.payment_mode}
                option={paymentOption}
              />
            </Form.Item>
          </Col>
          <Col sm={24} lg={12}>
            <Form.Item label="Payment" name="payment_success">
              {/* <Input
                type='checkbox'
                name='payment_success'
                // disabled
                className='input-field'
              /> */}
              <Checkbox
                name="payment_success"
                onChange={(e) => setIsPaymentSuccess(e?.target?.checked)}
                checked={isPaymentSuccess}
              >
                Payment Status
              </Checkbox>
            </Form.Item>
          </Col>

          {/* <Col sm={24} lg={12}>
            <Form.Item label='Full Name' name='username'>
              <Input
                type='text'
                name='username'
                disabled
                className='input-field'
              />
            </Form.Item>
          </Col> */}
          {/* <Col sm={24} lg={12}>
            <Form.Item label='Contact Name' name='phone'>
              <Input type='text' className='input-field' disabled />
            </Form.Item>
          </Col> */}
        </Row>
        {/*
        <Row gutter={[50, 50]} className='form-row'>
          <Col sm={24} lg={12}>
            <Form.Item label='Total Payable' name='total_payable' required>
              <Input type='text' className='input-field' />
            </Form.Item>
          </Col>
          <Col sm={24} lg={12}>
            <Form.Item label='Payment Gateway' name='payment_gateway' required>
              <InputSelect
                defaultValue='cash_on_delivery'
                handleChange={handelSelectChange}
                option={paymentOption}
              />
            </Form.Item>
          </Col>
        </Row> */}

        <Typography.Paragraph type="danger">
          {updateError?.response?.data?.message}
        </Typography.Paragraph>
        <Row className="delivery-form__footer">
          <Button className="cancel-btn btn" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="cancel-submit btn"
            type="primary"
            htmlType="submit"
            disabled={isLoading}
          >
            {isLoading ? <Spin size="small" /> : "Submit"}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default DeliveryFormModal;
