export const FETCH_VENDORS = 'FETCH_VENDORS'
export const FETCH_VENDORS_SUCCESS =
  'FETCH_VENDORS_SUCCESS'
export const FETCH_VENDORS_FAILURE =
  'FETCH_VENDORS_FAILURE'
export const FETCH_VENDOR = 'FETCH_VENDOR'
export const FETCH_VENDOR_SUCCESS = 'FETCH_VENDOR_SUCCESS'
export const FETCH_VENDOR_FAILURE = 'FETCH_VENDOR_FAILURE'
export const CREATE_VENDOR = 'CREATE_VENDOR'
export const CREATE_VENDOR_SUCCESS = 'CREATE_VENDOR_SUCCESS'
export const CREATE_VENDOR_FAILURE = 'CREATE_VENDOR_FAILURE'

export const REMOVE_VENDOR = 'REMOVE_VENDOR'
export const REMOVE_VENDOR_SUCCESS = 'REMOVE_VENDOR_SUCCESS'
export const REMOVE_VENDOR_FAILURE = 'REMOVE_VENDOR_FAILURE'

export type contactPersonType = {
  contact_name: string
  phone_no: string
  email: string
  location: string
}

export type MaterialSelectType = {
  total_quantity: number
  total_unit: string
  total_special_quantity: number
}

export type InventoryItemType = {
  _id: string
  item_name: string
}
export type VendorItemType = {
  _id?: string
  id: string
  order_id: string
  ordered_date: string
  received_date: string
  inventory_category: string
  quality: string
  price: number
  vendor: string
  status: false
  bill: string
  payment_mode: string
  transport_mode: string
  received_by: string
  createdAt: string
  updatedAt: string
  media: Array<string>
  material_select: MaterialSelectType
  inventory_item: InventoryItemType
}
export type VendorType = {
  _id: string
  vendor_id: string
  vendor_name: string
  supplies_type: Array<string>
  location: string
  phone_no: string
  email: string
  vendor_tags: Array<string>
  icon: string
  contact_person: contactPersonType
  description: string
  is_verified: boolean
  is_active: boolean
  createdAt: string
  updatedAt: string
  id: string
}

export type VendorInitialState = {
  vendors: VendorsRes
  vendor: VendorType
  error: string
  loading:  boolean
  creating:  boolean
  deleting:  boolean
  vendorItem: Array<VendorItemType>

}

export type VendorsRes = {
  data: Array<VendorType>
  limit:number
  page_no: number
  total_pages: number
  total_data: number
  message?: string

}
