import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { getExchangeRefunds } from '../../API/ExchangeRefundApi'
import {
  fetchExchangeRefundFailure,
  fetchExchangeRefundSuccess,
} from '../Actions/ExchangeRefundAction'
import { GET_EXCHANGE_REFUNDS } from '../Constants'

const takeExchangeRefundAsync = async (params: any) =>
  await getExchangeRefunds(params)

function* takeExchangeRefund(data: any) {
  const { payload } = data
  try {
    const members: AxiosResponse = yield call(takeExchangeRefundAsync, payload)
    if (members) {
      yield put(fetchExchangeRefundSuccess(members))
    } else {
      yield put(fetchExchangeRefundFailure('Something went wrong!'))
    }
  } catch (error) {
    yield put(fetchExchangeRefundFailure('Network error'))
  }
}

function* watchTakenExchangeRefund() {
  yield takeEvery(GET_EXCHANGE_REFUNDS, takeExchangeRefund)
}

export default function* ExchangeRefundSaga() {
  yield all([fork(watchTakenExchangeRefund)])
}
