import { API, Auth } from 'aws-amplify'
import { ApiHeaders, INVENTORY_API_NAME } from '../../API/variable'
// import { INVENTORY_API_NAME, ApiHeaders } from ''

//get Dashboard
export const takeDashboard = async () => {
  const res = await API.get(INVENTORY_API_NAME, `/dashboard/dashboard`, {
    headers: ApiHeaders,
  })

  return res
}
