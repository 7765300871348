import { EditOutlined, EyeFilled } from "@ant-design/icons";
import { Card, Modal, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import ImageHolder from "../../../../Components/Admin/ImageHolder/ImageHolder";
import { useState } from "react";
import { useNavigate } from "react-router";
import { MediaUrl } from "../../../../Utils/mediaUrl";
import ProductCategoryForm from "../../Forms/Forms/ProductCategoryForm";
import "./ProductCategoryItem.scss";

export const ProductCategoryItem = (props: any) => {
  const { image, title, _id } = props?.data;
  const history = useNavigate();

  const [editData, setEditData] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  console.log(editData, "Edit Data", showModal, "show Modal");
  return (
    <Card className="product__item__card">
      <div className="hoverable__action">
        <Space>
          {!props.disableView && (
            <EyeFilled
              onClick={() => history(`/product-category/${_id}`)}
              style={{ color: "white", fontSize: 24 }}
            />
          )}
          {/* <DeleteOutlined style={{ color: "white", fontSize: 24 }} /> */}
          <EditOutlined
            style={{ color: "white", fontSize: 24 }}
            onClick={() => {
              setShowModal(true);
              setEditData(props?.data);
            }}
          />
        </Space>
      </div>
      <div className="product__item__image">
        <ImageHolder src={MediaUrl(image)} width="100%" />
        {/* <div onClick={() => setEditData(props?.data)}>
          <EditOutlined />
        </div> */}
      </div>
      <Typography.Title
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          marginTop: 20,
        }}
        level={5}
      >
        <div onClick={() => history(`/product-category/${_id}`)}>{title}</div>
      </Typography.Title>

      <Modal footer={false} visible={showModal} onCancel={() => setShowModal(false)} destroyOnClose>
        <ProductCategoryForm
          data={editData}
          update
          parentId={editData?.parent_category?._id}
          onCancel={() => {
            setShowModal(false);
            setEditData(null);
          }}
        />
      </Modal>
    </Card>
  );
};

export default ProductCategoryItem;
