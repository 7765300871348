import { Reducer } from 'react'
import { ObjectFilter } from '../../Utils/ObjectFilter'
import {
  CreateCustomerParams,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_FAILURE,
  CREATE_CUSTOMER_SUCCESS,
  FETCH_CUSTOMERS,
  FETCH_CUSTOMERS_FAILURE,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMER_DETAILS,
  FETCH_CUSTOMER_DETAILS_FAILURE,
  FETCH_CUSTOMER_DETAILS_SUCCESS,
  REMOVE_CUSTOMER,
  REMOVE_CUSTOMER_FAILURE,
  REMOVE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_SUCCESS,
  CustomerDetailsParams,
  FETCH_CUSTOMER_TIMELINE,
  FETCH_CUSTOMER_TIMELINE_SUCCESS,
  FETCH_CUSTOMER_TIMELINE_FAILURE,
} from './CustomerTypes'

const INIT_STATE = {
  list: [],
  error: '',
  detailsError: '',
  details: {},
  loading: false,
  loaded: false,
  detailing: false,
  detailed: false,
  creating: false,
  created: false,
  deleting: false,
  deleted: false,
  updating: false,
  updated: false,

  timelining: false,
  timelined: false,
  timeline: [],

  total: 0,
  page: 1,
  fitDetails: false,
}

export const CustomerReducer: Reducer<any, any> = (
  state = INIT_STATE,
  action
) => {
  switch (action.type) {
    //fetch Customers
    case FETCH_CUSTOMERS:
      return { ...state, loading: true }
    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        ...ObjectFilter(action.payload, (key: any) => key != 'list'),
        loaded: true,
        error: false,
      }
    case FETCH_CUSTOMERS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    //fetch Customer Details
    case FETCH_CUSTOMER_DETAILS:
      return { ...state, detailing: true }
    case FETCH_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        detailing: false,
        details: ObjectFilter(action.payload, (key: any) => key != 'size'),
        fitDetails: action.payload?.size,
      }
    case FETCH_CUSTOMER_DETAILS_FAILURE:
      return { ...state, detailing: false, detailsError: action.payload }

    //create Customer
    case CREATE_CUSTOMER:
      return { ...state, creating: true, created: false }
    case CREATE_CUSTOMER_SUCCESS:
      state.list.push(action.payload)
      return { ...state, creating: false, created: true }
    case CREATE_CUSTOMER_FAILURE:
      return { ...state, creating: false }

    //update Customer
    case UPDATE_CUSTOMER:
      return { ...state, updating: true, creating: false }
    case UPDATE_CUSTOMER_SUCCESS:
      const tempList = state.list.filter(
        (d: CreateCustomerParams) => d.id != action.payload.id
      )
      tempList.push(action.payload)
      return { ...state, updating: false, list: tempList, created: true }
    case UPDATE_CUSTOMER_FAILURE:
      return { ...state, updating: false }

    //remove Customer
    case REMOVE_CUSTOMER:
      return { ...state, deleting: true }
    case REMOVE_CUSTOMER_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: state.list.filter(
          (d: CustomerDetailsParams) => d.id != action.payload.id
        ),
      }
    case REMOVE_CUSTOMER_FAILURE:
      return { ...state, deleting: false }

    case FETCH_CUSTOMER_TIMELINE:
      return { ...state, timelining: true, timelined: false }
    case FETCH_CUSTOMER_TIMELINE_SUCCESS:
      return {
        ...state,
        timelining: false,
        timelined: true,
        timeline: action.payload?.data,
      }
    case FETCH_CUSTOMER_TIMELINE_FAILURE:
      return {
        ...state,
        timelining: false,
        timelined: true,
        error: action.payload,
      }

    default:
      return { ...state }
  }
}
