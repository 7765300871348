export const FETCH_REPORTS = 'FETCH_REPORTS'
export const FETCH_REPORTS_SUCCESS =
  'FETCH_REPORTS_SUCCESS'
export const FETCH_REPORTS_FAILURE =
  'FETCH_REPORTS_FAILURE'
export const FETCH_DAMAGE_ITEMS = 'FETCH_DAMAGE_ITEMS'
export const FETCH_DAMAGE_ITEMS_SUCCESS =
  'FETCH_DAMAGE_ITEMS_SUCCESS'
export const FETCH_DAMAGE_ITEMS_FAILURE =
  'FETCH_DAMAGE_ITEMS_FAILURE'
export const FETCH_REPORT = 'FETCH_REPORT'
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS'
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE'

export type InventoryCategory = {
  _id: string
  inventory_category: string
}

export type InventoryItem = {
  _id: string
  item_name: string
}

export type MaterialSelect = {
  total_quantity: string
  total_unit: string
}

export type DamageItemType = {
  _id: string
  order_id: string
  inventory_category: InventoryCategory
  inventory_item: InventoryItem
  brand_name: string
  damaged_date: string
  price: string
  createdAt: string
  damaged_by: string
  updatedAt: string
  id: string
  media: Array<any>
  material_select: MaterialSelect
}

export type DamageItemsInitialState = {
  damageItems: DamageItemsRes
  reports: Array<any>
  error: string
  loading:  boolean
  creating:  boolean
  deleting:  boolean
}

export type DamageItemsRes = {
  data: Array<DamageItemType>
  limit:number
  page_no: number
  total_pages: number
  total_data: number

}
