import { SmileTwoTone } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import Jacket from "../../../Assets/Products/jacket.png";
import { useParams } from "react-router";
import { PageWrapper } from "../PageWrapper/PageWrapper";
import { useQuery } from "react-query";
import { getCustomerTimeLine } from "../../../API/CustomerApi";

export const UserTimeline = () => {
  const { id } = useParams<any>();

  const { data, isLoading } = useQuery(["getUserTimeline", { user_id: id }], getCustomerTimeLine);

  return (
    <PageWrapper data={{ loading: isLoading, empty: data?.data?.data?.length === 0 }}>
      <Row>
        <Col lg={24}>
          {data?.data?.data?.map((item: any) => (
            <Card style={{ padding: 30, marginBottom: 20 }}>
              <Row>
                <Col lg={8}>
                  <img src={Jacket} />
                </Col>
                <Col lg={13}>
                  <Row
                    justify="space-around"
                    style={{
                      alignItems: "space-around",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <Title level={3} className="product-name">
                      LEATHER JACKET A
                    </Title>
                    <Title level={3} className="product-name">
                      RS.{item?.total_amount}
                    </Title>
                    <Text type="secondary">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare amet, lorem
                      lacinia diam.{" "}
                    </Text>
                    <Text disabled>08 Aug, 2020 11:28 AM</Text>
                  </Row>
                </Col>
                <Col lg={3}>
                  <SmileTwoTone style={{ fontSize: 36 }} />
                </Col>
              </Row>
            </Card>
          ))}
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default UserTimeline;
