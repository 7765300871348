import { VendorType } from "../Vendor/VendorTypes";

export const FETCH_INVENTORY_ITEM = "FETCH_INVENTORY_ITEM";
export const FETCH_INVENTORY_ITEM_SUCCESS = "FETCH_INVENTORY_ITEM_SUCCESS";
export const FETCH_INVENTORY_ITEM_FAILURE = "FETCH_INVENTORY_ITEM_FAILURE";

export const CREATE_INVENTORY_ITEM = "CREATE_INVENTORY_ITEM";
export const CREATE_INVENTORY_ITEM_SUCCESS = "CREATE_INVENTORY_ITEM_SUCCESS";
export const CREATE_INVENTORY_ITEM_FAILURE = "CREATE_INVENTORY_ITEM_FAILURE";

export const CREATE_INVENTORY_ITEM_TRACK = "CREATE_INVENTORY_ITEM_TRACK";
export const CREATE_INVENTORY_ITEM_TRACK_SUCCESS = "CREATE_INVENTORY_ITEM_TRACK_SUCCESS";
export const CREATE_INVENTORY_ITEM_TRACK_FAILURE = "CREATE_INVENTORY_ITEM_TRACK_FAILURE";

export const FETCH_INVENTORY_ITEM_TRACK = "FETCH_INVENTORY_ITEM_TRACK";
export const FETCH_INVENTORY_ITEM_TRACK_SUCCESS = "FETCH_INVENTORY_ITEM_TRACK_SUCCESS";
export const FETCH_INVENTORY_ITEM_TRACK_FAILURE = "FETCH_INVENTORY_ITEM_TRACK_FAILURE";

export const FETCH_VENDOR_OF_INVENTORY_ITEM = "FETCH_VENDOR_OF_INVENTORY_ITEM";
export const FETCH_VENDOR_OF_INVENTORY_ITEM_SUCCESS = "FETCH_VENDOR_OF_INVENTORY_ITEM_SUCCESS";
export const FETCH_VENDOR_OF_INVENTORY_ITEM_FAILURE = "FETCH_VENDOR_OF_INVENTORY_ITEM_FAILURE";

export type InventorCyategoriesType = {
  _id: string;
  inventory_category: string;
  code: string;
  material_select?: materialSelectedType;
  special_unit?: boolean;
};

export type InventoryItemsType = {
  _id: string;
  item_name: string;
  sku: string;
  latido_code: string;
  inventory_category: InventorCyategoriesType;
  brand: string;
  color: string;
  weight: string;
  description: string;
  tags: Array<string>;
  icon: string;
  high_quantity: number;
  material_select?: materialSelectedType;
  ball_image?: string;
};
export type materialSelectedType = {
  total_quantity: number;
  total_special_quantity: number;
  total_unit: string;
  special_unit: string;
  unit?: string;
};

export type inventoryItemTrackDataType = {
  bill: string;
  createdAt: string;
  id: string;
  order_id: string;
  ordered_date: string;
  payment_mode: string;
  quality: string;
  received_by: string;
  received_date: string;
  transport_mode: string;
  updatedAt: string;
  inventory_item: InventoryItemsType;
  material_select: materialSelectedType;
  media: Array<string>;
  vendor: VendorType;
};

export type InventoryItemTrackType = {
  limit: number;
  page_no: number;
  total_pages: number;
  total_data?: number;
  data: Array<inventoryItemTrackDataType>;
};
export type InventoryItemInitialState = {
  inventoryItem: InventoryItemsType;
  inventoryItemTrack: InventoryItemTrackType;
  vendorsOfInventoryItem: any;
  vendorsOfInventoryItemPagination: any;
  error: string;
  loading: boolean;
  fetchingVendor: boolean;
  creating: boolean;
};
