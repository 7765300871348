export const FETCH_DASHBOARD = 'FETCH_DASHBOARD'
export const FETCH_DASHBOARD_SUCCESS =
  'FETCH_DASHBOARD_SUCCESS'
export const FETCH_DASHBOARD_FAILURE =
  'FETCH_DASHBOARD_FAILURE'



type VendorType = {
  _id: string
  vendor_name: string
  supplies_type: Array<string>
  location: string
  phone_no: string
}

type MaterialType = {
  unit?: string
  special_unit?: string
  total_quantity?: number
  total_special_quantity?: number
}

type CategoriesType = {
  _id: string
  inventory_category: string
  code: string
  icon: string
  material_select: MaterialType
  total_quantity: number
}

export type OutOFStockType = {
  _id: string
  icon: string
  item_name: string
}

type AlarmingListType = {
  material_select: MaterialType
  _id: string
  item_name: string
  icon: string
  least_quantity: number
  high_quantity: number
}

export type DashboardType = {
  _id: string
  vendor: Array<VendorType>
  category_list: Array<CategoriesType>
  out_of_stock: Array<OutOFStockType>
  alarming_list: Array<AlarmingListType>
  in_stock_count: number
  alarming_count: number
  out_of_stock_count: number
}

export type DashboardInitialState = {
  error: string
  loading:  boolean
  data: DashboardType
}
