export const FETCH_PRODUCT_CATEGORYS = 'FETCH_PRODUCT_CATEGORYS'
export const FETCH_PRODUCT_CATEGORYS_SUCCESS = 'FETCH_PRODUCT_CATEGORYS_SUCCESS'
export const FETCH_PRODUCT_CATEGORYS_FAILURE = 'FETCH_PRODUCT_CATEGORYS_FAILURE'

export const FETCH_PRODUCT_CATEGORY = 'FETCH_PRODUCT_CATEGORY'
export const FETCH_PRODUCT_CATEGORY_SUCCESS = 'FETCH_PRODUCT_CATEGORY_SUCCESS'
export const FETCH_PRODUCT_CATEGORY_FAILURE = 'FETCH_PRODUCT_CATEGORY_FAILURE'

export const CREATE_PRODUCT_CATEGORY = 'CREATE_PRODUCT_CATEGORY'
export const CREATE_PRODUCT_CATEGORY_SUCCESS = 'CREATE_PRODUCT_CATEGORY_SUCCESS'
export const CREATE_PRODUCT_CATEGORY_FAILURE = 'CREATE_PRODUCT_CATEGORY_FAILURE'

export const UPDATE_PRODUCT_CATEGORY = 'UPDATE_PRODUCT_CATEGORY'
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = 'UPDATE_PRODUCT_CATEGORY_SUCCESS'
export const UPDATE_PRODUCT_CATEGORY_FAILURE = 'UPDATE_PRODUCT_CATEGORY_FAILURE'

export const REMOVE_PRODUCT_CATEGORY = 'REMOVE_PRODUCT_CATEGORY'
export const REMOVE_PRODUCT_CATEGORY_SUCCESS = 'REMOVE_PRODUCT_CATEGORY_SUCCESS'
export const REMOVE_PRODUCT_CATEGORY_FAILURE = 'REMOVE_PRODUCT_CATEGORY_FAILURE'

export interface ProductCategoryFetchParams {
  limit?: number
  page?: number
  PageSize?: number
  PageNumber?: number
  parent_category?: string
  hierarchy?: boolean
}
export interface ProductCategoryDetailsParams {
  id?: number | string
}
export interface CreateProductCategoryParams {
  id?: number
  _id?: string
  name?: string
  address?: string
  phone?: string
  title?: string
  image?: string
}
