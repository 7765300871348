import { notification } from "antd";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { push } from "react-router-redux";
import {
  createInventoryItemFailure,
  createInventoryItemSuccess,
  createInventoryItemTrackSuccess,
  fetchInventoryItemFailure,
  fetchInventoryItemSuccess,
  fetchInventoryItemTrackSuccess,
  fetchVendorOfInventoryItemFailure,
  fetchVendorOfInventoryItemSuccess,
} from "./InventoryItemActions";

import {
  makeInventoryItem,
  makeInventoryItemTrack,
  takeInventoryItem,
  takeInventoryItemTrack,
  takeVendorOfInventoryItem,
} from "./InventoryItemApis";

import {
  CREATE_INVENTORY_ITEM,
  CREATE_INVENTORY_ITEM_TRACK,
  FETCH_INVENTORY_ITEM,
  FETCH_INVENTORY_ITEM_TRACK,
  FETCH_VENDOR_OF_INVENTORY_ITEM,
  InventoryItemsType,
} from "./InventoryItemTypes";
import { history } from "../AppStore";

//get inventory categories
const getInventoryItemAsync = async (id: string) => {
  const res = await takeInventoryItem(id);
  return res;
};
function* getInventoryItem(params: any) {
  const { payload } = params;
  try {
    const inventoryItem: Array<InventoryItemsType> = yield call(getInventoryItemAsync, payload);
    if (inventoryItem) {
      yield put(fetchInventoryItemSuccess(inventoryItem));
    } else {
      yield put(fetchInventoryItemFailure("Could not get inventory item"));
      notification.error({
        message: "Could not get  inventory item",
        placement: "bottom",
      });
    }
  } catch (error: any) {
    yield put(fetchInventoryItemFailure("Network Error while getting  inventory item"));
    notification.error({
      message: error?.response?.data?.message || "Something went wrong",
      placement: "bottom",
    });
  }
}

function* watchGetInventoryItem() {
  yield takeLatest(FETCH_INVENTORY_ITEM, getInventoryItem);
}

//create inventory Item
const generateInventoryItemAsync = async (data: any) => {
  const res = await makeInventoryItem(data);
  return res;
};
function* generateInventoryItem(params: any) {
  const { payload } = params;
  try {
    const response: Array<InventoryItemsType> = yield call(generateInventoryItemAsync, payload);
    if (response) {
      notification.success({
        message: "Inventory item created successfully",
        placement: "bottom",
      });

      yield put(createInventoryItemSuccess(response));
      if (payload?._id) {
        history.push(`/inventory/item-details/${payload?._id}`);
        window.location.href = `/inventory/item-details/${payload?._id}`;
      } else {
        window.location.href = "/inventory/raw-materials";
      }
    } else {
      yield put(createInventoryItemFailure("Could not create inventory item"));
      notification.error({
        message: "Could not create  inventory item",
        placement: "bottom",
      });
    }
  } catch (error: any) {
    yield put(createInventoryItemFailure("Network Error while creating  inventory item"));
    notification.error({
      message: error?.response?.data?.message || "Something went wrong",
      placement: "bottom",
    });
  }
}

function* watchGenerateInventoryItem() {
  yield takeLatest(CREATE_INVENTORY_ITEM, generateInventoryItem);
}

//create inventory Item Track
const generateInventoryItemTrackAsync = async (data: any) => {
  const res = await makeInventoryItemTrack(data);
  return res;
};
function* generateInventoryItemTrack(params: any) {
  const { payload } = params;
  try {
    const response: Array<InventoryItemsType> = yield call(
      generateInventoryItemTrackAsync,
      payload
    );
    if (response) {
      const temp: any = { ...response };
      notification.success({
        message: "Inventory Item Track created successfully",
        placement: "bottom",
      });
      yield put(createInventoryItemTrackSuccess(response));
      notification.success({
        message: "Inventory Item Track created successfully",
        placement: "bottom",
      });
      window.location.href = `/inventory/item-details/${temp?.inventory_item}`;
      // history.push(`/inventory/item-details/${temp?.inventory_item}`);
    } else {
      yield put(createInventoryItemFailure("Could not create inventory item track"));
      notification.error({
        message: "Could not create  inventory item track",
        placement: "bottom",
      });
    }
  } catch (error: any) {
    yield put(createInventoryItemFailure("Network Error while creating  inventory item track"));
    notification.error({
      message: error?.response?.data?.message || "Something went wrong",
      placement: "bottom",
    });
  }
}

function* watchGenerateInventoryItemTrack() {
  yield takeLatest(CREATE_INVENTORY_ITEM_TRACK, generateInventoryItemTrack);
}

//fetch inventory Item Track
const getInventoryItemTrackAsync = async (data: any) => {
  const res = await takeInventoryItemTrack(data);
  return res;
};
function* getInventoryItemTrack(params: any) {
  const { payload } = params;
  try {
    const response: Array<any> = yield call(getInventoryItemTrackAsync, payload);
    if (response) {
      yield put(fetchInventoryItemTrackSuccess(response));
    } else {
      yield put(fetchInventoryItemFailure("Could not fetch inventory item track"));
      notification.error({
        message: "Could not fetch  inventory item track",
        placement: "bottom",
      });
    }
  } catch (error: any) {
    yield put(fetchInventoryItemFailure("Network Error while fetching  inventory item track"));
    notification.error({
      message: error?.response?.data?.message || "Something went wrong",
      placement: "bottom",
    });
  }
}

function* watchGetInventoryItemTrack() {
  yield takeLatest(FETCH_INVENTORY_ITEM_TRACK, getInventoryItemTrack);
}

//fetch vendor of inventory Item
const getVendorOfInventoryItemAsync = async (data: any) => {
  const res = await takeVendorOfInventoryItem(data);
  return res;
};
function* getVendorOfInventoryItem(params: any) {
  const { payload } = params;
  try {
    const response: Array<any> = yield call(getVendorOfInventoryItemAsync, payload);
    if (response) {
      yield put(fetchVendorOfInventoryItemSuccess(response));
    } else {
      yield put(fetchVendorOfInventoryItemFailure("Could not fetch vendor of inventory item."));
      notification.error({
        message: "Could not fetch vendor of inventory item.",
        placement: "bottom",
      });
    }
  } catch (error: any) {
    yield put(
      fetchVendorOfInventoryItemFailure("Network Error while fetching  vendor of inventory item")
    );
    notification.error({
      message: error?.response?.data?.message || "Something went wrong",
      placement: "bottom",
    });
  }
}

function* watchGetVendorOfInventoryItem() {
  yield takeLatest(FETCH_VENDOR_OF_INVENTORY_ITEM, getVendorOfInventoryItem);
}

export default function* InventoryItemSagas() {
  yield all([
    fork(watchGetInventoryItem),
    fork(watchGenerateInventoryItem),
    fork(watchGenerateInventoryItemTrack),
    fork(watchGetInventoryItemTrack),
    fork(watchGetVendorOfInventoryItem),
  ]);
}
