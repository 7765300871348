import { notification } from "antd";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  createInventoryCategoryFailure,
  createInventoryCategorySuccess,
  fetchInventoryCategoryFailure,
  fetchInventoryCategorySuccess,
} from "./InventoryCategoryActions";

import { makeInventoryCategories, takeInventoryCategories } from "./InventoryCategoryApis";
import {
  CREATE_INVENTORY_CATEGORIES,
  FETCH_INVENTORY_CATEGORIES,
  InventoryCategoriesCreateResType,
  InventoryCategoriesType,
} from "./InventoryCategoryTypes";

//get inventory categories
const getInventoryCategoriesAsync = async (q: string) => {
  const res = await takeInventoryCategories(q);
  return res;
};
function* getInventoryCategories(params: any) {
  const { payload } = params;
  try {
    const inventoryCategories: Array<InventoryCategoriesType> = yield call(
      getInventoryCategoriesAsync,
      payload
    );

    if (inventoryCategories) {
      yield put(fetchInventoryCategorySuccess(inventoryCategories));
    } else {
      yield put(fetchInventoryCategoryFailure("Could not get list of categories"));
      notification.error({
        message: "Could not get list of categories",
        placement: "bottom",
      });
    }
  } catch (error: any) {
    yield put(fetchInventoryCategoryFailure("Network Error while getting Categories"));
    notification.error({
      message: error?.response?.data?.message || "Network Error while getting Categories",
      placement: "bottom",
    });
  }
}

function* watchGetInventoryCategories() {
  yield takeLatest(FETCH_INVENTORY_CATEGORIES, getInventoryCategories);
}

//create inventory Item
const generateInventoryCategoriesAsync = async (data: any) => {
  const res = await makeInventoryCategories(data);
  return res;
};
function* generateInventoryCategories(params: any) {
  const { payload } = params;
  try {
    const response: InventoryCategoriesCreateResType = yield call(
      generateInventoryCategoriesAsync,
      payload
    );
    if (response) {
      yield put(createInventoryCategorySuccess(response));
      notification.success({
        message: "Category created successfully",
        placement: "bottom",
      });
    } else {
      yield put(createInventoryCategoryFailure("Could not create inventory categories"));
      notification.error({
        message: "Could not create  inventory categories",
        placement: "bottom",
      });
    }
  } catch (error: any) {
    yield put(createInventoryCategoryFailure("Network Error while creating  inventory categories"));
    notification.error({
      message:
        error?.response?.data?.message || "Network Error while creating  inventory categories",
      placement: "bottom",
    });
  }
}

function* watchGenerateInventoryCategories() {
  yield takeLatest(CREATE_INVENTORY_CATEGORIES, generateInventoryCategories);
}

export default function* InventoryCategoriesSagas() {
  yield all([fork(watchGetInventoryCategories), fork(watchGenerateInventoryCategories)]);
}
