/** @format */

import { Button, Typography } from 'antd'
import ArrowCircle from '../ArrowCircle/ArrowCircle'
import './Button.scss'

const UploadButton = () => {
  return (
    <Button className='browse-btn'>
      <Typography.Text className='browse-files-text'>
        Browse Files
      </Typography.Text>
      <ArrowCircle />
    </Button>
  )
}
export default UploadButton
