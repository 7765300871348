import { Button, Form, Input, Row, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const AddTrackOrder = () => {
  const [search, setSearch] = React.useState("");
  const navigation = useNavigate();

  const onSubmit = () => {
    navigation(`/store/track-order/${search}`);
  };

  return (
    <div className="order-option">
      <Typography.Title
        className="product-name"
        style={{
          marginTop: "13px",
        }}
      >
        TRACK ORDER
      </Typography.Title>
      <div className="order-option__content">
        <Form>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p>Add Order Number</p>
            <Input
              className="order-option__button_1 btn track_order-btn"
              type="number"
              onChange={(e) => setSearch(e?.target?.value)}
            />

            {search && (
              <Row style={{ marginTop: 40 }}>
                <Button style={{ width: 120 }} htmlType="submit" onClick={onSubmit}>
                  Next
                </Button>
              </Row>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddTrackOrder;
