import { Reducer } from 'redux'
import { ObjectFilter } from '../../Utils/ObjectFilter'
import {
  GET_EXCHANGE_REFUNDS,
  GET_EXCHANGE_REFUNDS_FAILURE,
  GET_EXCHANGE_REFUNDS_SUCCESS,
} from '../Constants'

const INIT_STATE = {
  loading: true,
  items: [],
  creating: false,
  error: false,
  loaded: false,
}

export const ExchangeRefundReducer: Reducer<any, any> = (
  state = INIT_STATE,
  action: any
) => {
  switch (action.type) {
    case GET_EXCHANGE_REFUNDS:
      return { ...state, loading: true }
    case GET_EXCHANGE_REFUNDS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.data,
        ...ObjectFilter(action.payload, (key: any) => key != 'data'),
        loaded: true,
        error: false,
      }
    case GET_EXCHANGE_REFUNDS_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return { ...state }
  }
}

export default ExchangeRefundReducer
