import { Badge, Button, Col, Form, Modal, Row, Space, Typography, message } from "antd";
import "./SecondaryImageUpload.scss";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { ReactComponent as MultipleImage } from "../../../../src/Assets/Icons/MultipleImage.svg";
import MediaUrl from "../../../Utils/mediaUrl";
import { useForm } from "antd/lib/form/Form";
import { updateProduct } from "../../../Redux/ProductRedux/ProductActions";
import { useDispatch } from "react-redux";
import CommonS3UploadFiles from "Components/Admin/S3UploadFiles/CommonS3UploadFiles";

const SecondaryImagesUpload = ({ images, details, updating, onFinish }: any) => {
  const [morePhotoModal, setMorePhotoModal] = useState(false);
  const [secondaryImages, setSecondaryImages] = useState<any>([]);
  const dispatch = useDispatch();
  const [form] = useForm();

  const onSubmit = () => {
    // onFinish({
    //   _id: details?._id,
    //   secondary_image: secondaryImages,
    // });
    message.error("Work in progress");
  };
  return (
    <div>
      <Modal
        title="Add Images"
        visible={morePhotoModal}
        onCancel={() => setMorePhotoModal(false)}
        footer={null}
        width="50vw"
      >
        <Form form={form} onFinish={onSubmit}>
          <Row gutter={[30, 30]} style={{ marginTop: 20 }}>
            {secondaryImages?.map((img: any) => {
              return (
                <Col span={8}>
                  <Badge
                    style={{ cursor: "pointer" }}
                    count={
                      <CloseCircleOutlined
                        onClick={() =>
                          setSecondaryImages(secondaryImages?.filter((item: any) => item !== img))
                        }
                      />
                    }
                  >
                    <img width={"100%"} src={img} alt="product_photos" />
                  </Badge>
                </Col>
              );
            })}
            <Col span={8}>
              <CommonS3UploadFiles
                style={{ width: "100%" }}
                type="ratio"
                ratio={[9, 16]}
                url={null}
                skipTemporary={true}
                module="product"
                onUpload={(e) => {
                  setSecondaryImages([...secondaryImages, MediaUrl(e)]);
                }}
              />
            </Col>
          </Row>
          <div style={{ marginTop: 20, display: "flex", gap: 20, justifyContent: "flex-end" }}>
            <Form.Item>
              <Button htmlType="reset" onClick={() => setMorePhotoModal(false)}>
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" loading={updating} type="primary">
                Update
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <div
        onClick={() => {
          setMorePhotoModal(true);
          setSecondaryImages(images);
        }}
        className=""
        style={{
          cursor: "pointer",
          height: "100%",
          textAlign: "center",
          background: "#EBF5FF",
          border: "0.25px solid #B5B5B5",
          padding: "10px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <MultipleImage />
          <Typography.Paragraph style={{ marginBottom: 0 }}>Upload More</Typography.Paragraph>
        </div>
      </div>
    </div>
  );
};

export default SecondaryImagesUpload;
