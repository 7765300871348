import { Reducer } from "react";
import { SET_LAYOUT } from "../Constants/constants";

const INIT_STATE = {
  created: false,
  updated: false,
  deleted: false,
};

export interface UpdateLayoutType {
  created?: boolean;
  updated?: boolean;
  deleted?: boolean;
}

export const LayoutReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case SET_LAYOUT:
      return {
        ...state,
        ...action.payload.params,
      };
    default:
      return { ...state };
  }
};

export default LayoutReducers;
