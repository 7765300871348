import React, { FC, useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Input, Row, Select, Space, Spin, Typography } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  MaterialCategoryBox,
  MaterialCategoryModal,
  MaterialItemBox,
  Search,
} from "../../Components";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Autoplay, Lazy, Mousewheel, Scrollbar } from "swiper";
import { CombineReducerType } from "../../Redux/Reducers/rootReducers";
import { fetchInventoryCategory } from "../../Redux/InventoryCategory/InventoryCategoryActions";
import {
  InventoryCategoriesType,
  ItemsType,
} from "../../Redux/InventoryCategory/InventoryCategoryTypes";
import MediaUrl from "../../Utils/mediaUrl";
import qs from "query-string";
import { useLocation } from "react-router";
import { LatidoAnimatedLogoLoader } from "../../Components/LatidoAnimateLogo/LatidoAnimateLogo";
import { itemAvailabilityData } from "../../Utils/consts";

const initialModalVisibilityState = {
  addCategory: false,
};

export const RawMaterials: FC = () => {
  const { loading, inventoryCategories } = useSelector(
    (state: CombineReducerType) => state?.InventoryCategoryReducer
  );
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [selectedStatus, setSelectedStatus] = useState<any>();
  const [categoryItems, setCategoryItems] = useState<any>();

  const [modalVisibility, setModalVisibility] = useState(initialModalVisibilityState);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [inventorySearchCategories, setInventorySearchCategories] = useState<any>([]);
  const [itemSearch, setItemSearch] = useState("");

  useEffect(() => {
    if (inventoryCategories?.length > 0) {
    } else {
      dispatch(fetchInventoryCategory(""));
    }
  }, []);
  const location = useLocation();
  const id = qs.parse(location?.search)?.category_id;
  useEffect(() => {
    const selected = inventoryCategories?.find((item: any) => {
      return item?._id === id;
    });
    setSelectedCategory(selected);
  }, [id, inventoryCategories]);

  useEffect(() => {
    setInventorySearchCategories(inventoryCategories);
    if (!id) {
      setSelectedCategory(inventoryCategories[0]);
    }
  }, [inventoryCategories]);

  useEffect(() => {
    if (selectedCategory) {
      setCategoryItems(selectedCategory?.items);
    }
  }, [selectedCategory]);

  const handelSearch = (q: string) => {
    setSelectedCategory(false);
    const filteredCategories = inventoryCategories.filter((category: InventoryCategoriesType) => {
      return category.inventory_category.toLowerCase().includes(q.toLowerCase()) && category;
    });
    setInventorySearchCategories(filteredCategories);
  };
  const handleCategoryChange = (category: InventoryCategoriesType) => {
    setSelectedCategory(category);
    history(`?category_id=${category._id}`);
  };

  const changeAddCategoryModalVisibility = (status: boolean) => {
    setModalVisibility((prevState) => ({ ...prevState, addCategory: status }));
  };

  const handleAddItems = () => {
    history(`/inventory/add-items?category_id=${selectedCategory?._id}`, {
      state: {
        item_name: selectedCategory?.inventory_category,
        id: selectedCategory?._id,
      },
    });
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [updateId, setUpdateId] = useState<any>(false);
  const handleCategoryEdit = (id: any) => {
    setModalVisibility({
      addCategory: true,
    });
    setUpdateId(id);
  };
  return (
    <>
      <Row style={{ margin: "30px 0" }} gutter={[20, 0]}>
        <Col span={15}>
          <Typography.Title
            className="product-name"
            style={{
              marginTop: "13px",
            }}
          >
            INVENTORY
          </Typography.Title>
        </Col>
        <Col span={4}></Col>
        <Col span={5}>
          <Input.Search
            size="middle"
            onChange={(e) => handelSearch(e.target.value)}
            placeholder="Search Category"
          />
        </Col>
      </Row>
      {loading && <LatidoAnimatedLogoLoader />}
      <Row gutter={[30, 30]} align="middle">
        <Col span={21}>
          <Swiper
            // pagination={{ clickable: true }}
            slidesPerView={"auto"}
            spaceBetween={30}
            style={{ padding: 20, zIndex: 0 }}
            // scrollbar
            // scrollbar={{
            //   hide: false,
            //   draggable: true,
            //   snapOnRelease: true,
            // }}
            mousewheel={true}
            lazy={true}
            modules={[Scrollbar, Mousewheel, Lazy]}
            grabCursor={true}

            // autoplay={{
            //   delay: 2500,
            //   disableOnInteraction: false,
            //   waitForTransition: true,
            // }}
          >
            {!loading && (
              <>
                {inventorySearchCategories?.map((dm: InventoryCategoriesType, i: number) => (
                  <SwiperSlide key={i}>
                    <MaterialCategoryBox
                      handleCategoryChange={handleCategoryChange}
                      selectedCategory={selectedCategory}
                      category={dm?.inventory_category}
                      icon={MediaUrl(dm?.icon)}
                      title={dm?.inventory_category}
                      items={dm}
                      handleCategoryEdit={(e) => handleCategoryEdit(dm?._id)}
                    />
                  </SwiperSlide>
                ))}
              </>
            )}
          </Swiper>
        </Col>
        <Col span={3} className="material_add_button_wrapper">
          <Button
            type="text"
            className="material_add_button"
            onClick={() => changeAddCategoryModalVisibility(true)}
          >
            <PlusCircleOutlined />
          </Button>
        </Col>
      </Row>
      <Row
        hidden={!selectedCategory || loading}
        className="material_category_breadcrumb_wrapper"
        gutter={20}
      >
        <Col
          span={14}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h1
            className="page-title"
            style={{
              marginBottom: 0,
            }}
          >
            ITEMS
          </h1>

          <Space align="center" size={50}>
            <h5
              style={{
                marginLeft: 30,
                fontSize: 22,
                color: "#828282",
                wordSpacing: 5,
                marginBottom: 0,
              }}
            >
              {selectedCategory?.inventory_category}
            </h5>
            {!loading && categoryItems && (
              <div className="material_item_add_button_wrapper">
                <Button
                  onClick={() => handleAddItems()}
                  type="ghost"
                  className="material_item_add_button"
                >
                  <PlusCircleOutlined /> Add Items
                </Button>
              </div>
            )}
          </Space>
        </Col>
        <Col span={4}>
          <Select
            placeholder="Select Status"
            style={{ width: "100%" }}
            onChange={(e: any) => setSelectedStatus(e)}
          >
            {Object.values(itemAvailabilityData).map((avail: any) => {
              return <Select.Option value={avail?.value}>{avail?.text}</Select.Option>;
            })}
          </Select>
        </Col>
        <Col span={5}>
          <Input.Search
            size="middle"
            onChange={(e) => setItemSearch(e.target.value)}
            placeholder="Search Category"
          />
        </Col>
      </Row>
      <Row hidden={!selectedCategory} gutter={[40, 40]} align="stretch">
        {!loading && categoryItems ? (
          categoryItems
            .filter((data: any) => {
              switch (selectedStatus || "") {
                case "limited_stock":
                  if (
                    data?.material_select?.total_special_quantity &&
                    data?.material_select?.total_special_quantity > 0
                  ) {
                    return data?.material_select?.total_special_quantity < data.least_quantity;
                  } else {
                    if (data?.material_select?.total_quantity > 0) {
                      return data?.material_select?.total_quantity < data.least_quantity;
                    }
                  }
                  break;
                case "out_of_stock":
                  if (data?.material_select?.total_special_quantity) {
                    return data?.material_select?.total_special_quantity <= 0;
                  } else {
                    return data?.material_select?.total_quantity <= 0;
                  }

                case "in_stock":
                  if (
                    data?.material_select?.total_special_quantity &&
                    data?.material_select?.total_special_quantity > 0
                  ) {
                    return data?.material_select?.total_special_quantity >= data.least_quantity;
                  } else {
                    if (data?.material_select?.total_quantity > 0) {
                      return data?.material_select?.total_quantity >= data.least_quantity;
                    }
                  }
                  break;
                default:
                  return data;
              }
            })
            .filter((data: any) => {
              return (
                data?.latido_code.toLowerCase().includes(itemSearch.toLowerCase()) ||
                data?.item_name.toLowerCase().includes(itemSearch.toLowerCase()) ||
                data?.sku.toLowerCase().includes(itemSearch.toLowerCase())
              );
            })
            .map((di: ItemsType, i: string) => (
              <Col span={6} key={i}>
                <MaterialItemBox
                  inventory_category={selectedCategory?._id}
                  unit={selectedCategory?.material_select?.unit}
                  name={di?.item_name}
                  src={MediaUrl(di?.icon)}
                  quantity={di?.material_select?.total_quantity}
                  id={di?._id}
                  high_quantity={di?.high_quantity}
                  least_quantity={di?.least_quantity}
                  total_special_quantity={di?.material_select?.total_special_quantity}
                  special_unit={selectedCategory?.material_select?.special_unit}
                  lc={di?.latido_code}
                  sku={di?.sku}
                  key={i}
                />
              </Col>
            ))
        ) : (
          <Col xl={24} sm={24} md={24} lg={24} xxl={24} hidden={loading}>
            <h2>Please select category</h2>
          </Col>
        )}
      </Row>
      <MaterialCategoryModal
        visible={modalVisibility.addCategory}
        handleClose={() => {
          changeAddCategoryModalVisibility(false);
          setUpdateId(false);
        }}
        id={updateId}
      />
    </>
  );
};
