import { DeleteOutlined, EditOutlined, FacebookFilled, WhatsAppOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Descriptions, message, Popconfirm, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import dayjs from "dayjs";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchMemberSuccess,
  fetchMembers,
  fetchMembersSuccess,
} from "../../../Redux/MemberRedux/MemberActions";
import { cutMember } from "../../../Redux/MemberRedux/MemberApis";

export const MemberCard = (props: any) => {
  const { data, list, filter } = props;
  const CardCover = () => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const dispatch = useDispatch();
    const showPopconfirm = () => {
      setVisible(!visible);
    };

    const handleOk = async () => {
      setConfirmLoading(true);

      await cutMember({
        _id: data?._id,
      })
        .then((data: any) => {
          dispatch(fetchMembers(filter));
        })
        .catch(() => {
          message.error("Unable to delete member");
        });
      setConfirmLoading(false);
    };

    const handleCancel = () => {
      setVisible(false);
    };
    return (
      <Content>
        <img
          style={{ height: "350px", width: "100%", objectFit: "cover" }}
          alt="example"
          src={
            data?.profile_image ||
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxEucDxrnCHJSnxEXDjU46ULi_AI1tHRbSWA&usqp=CAU"
          }
        />
        <Content style={{ position: "absolute", top: 20, right: 20 }}>
          <Avatar icon={<EditOutlined onClick={() => props.onEdit(data)} />} />

          <Popconfirm
            title="Are you sure to delete this member?"
            okText="Delete"
            cancelText="Cancel"
            visible={visible}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
            destroyTooltipOnHide
            zIndex={1}
          >
            <Avatar icon={<DeleteOutlined onClick={showPopconfirm} />} style={{ marginLeft: 10 }} />
          </Popconfirm>
        </Content>
      </Content>
    );
  };
  return (
    <Card cover={<CardCover />} style={{ height: "100%" }}>
      <Row>
        <Col lg={24} style={{ textAlign: "center", marginBottom: 10 }}>
          <Typography.Title level={4} style={{ textTransform: "capitalize" }}>
            {data?.name}
          </Typography.Title>
          <Typography.Paragraph style={{ textTransform: "uppercase" }}>
            {data?.role?.join(", ")}
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <Typography.Text strong>Contact Information</Typography.Text>
          <Descriptions
            column={2}
            layout="vertical"
            style={{ marginTop: 20 }}
            labelStyle={{ fontSize: 12, paddingBottom: 0 }}
            contentStyle={{ fontWeight: "bold", fontSize: 12 }}
          >
            <Descriptions.Item label="Email Address">{data?.email}</Descriptions.Item>
            <Descriptions.Item label="Phone Number">{data?.phone_number}</Descriptions.Item>
            <Descriptions.Item label="Address" span={2}>
              {data?.address}
            </Descriptions.Item>
            <Descriptions.Item label="Created At" span={1}>
              {dayjs(data?.createdAt)?.format("lll")}
            </Descriptions.Item>
            <Descriptions.Item label="Last Updated At" span={1}>
              {dayjs(data?.updatedAt)?.format("lll")}
            </Descriptions.Item>
            {/* <Descriptions.Item label='Connect With'>
              <FacebookFilled style={{ fontSize: 18 }} />
              <WhatsAppOutlined style={{ fontSize: 18, marginLeft: 10 }} />
            </Descriptions.Item> */}
          </Descriptions>
        </Col>
      </Row>
    </Card>
  );
};

export default MemberCard;
