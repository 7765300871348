export const GET_MEMBERS = 'GET_MEMBERS';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_FAILURE = 'GET_MEMBERS_FAILURE';

export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_FAILURE = 'GET_CUSTOMERS_FAILURE';

export const GET_EXCHANGE_REFUNDS = 'GET_EXCHANGE_REFUNDS';
export const GET_EXCHANGE_REFUNDS_SUCCESS ='GET_EXCHANGE_REFUNDS_SUCCESS';
export  const GET_EXCHANGE_REFUNDS_FAILURE = 'GET_EXCHANGE_REFUNDS_FAILURE';