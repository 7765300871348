import {
  POST_JACKET_SIZES,
  POST_STATIC_CONTENT_REQUEST,
  POST_STATIC_CONTENT_SUCCESS,
  POST_STATIC_CONTENT_FAILED,
  SWAP_POSITION_REQUEST,
  SWAP_POSITION_SUCCESS,
  SWAP_POSITION_FAILED,
  GET_STATIC_CONTENTS_DATA,
  GET_STATIC_CONTENTS_DATA_FAILURE,
  GET_STATIC_CONTENTS_DATA_SUCCESS,
  GET_SIZING_PRODUCTS,
  GET_SIZING_PRODUCTS_SUCCESS,
  GET_SIZING_PRODUCTS_FAILURE,
} from '../Constants/constants'
import {
  FETCH_EACH_PRODUCT_SIZE_FAILED,
  FETCH_EACH_PRODUCT_SIZE_REQUEST,
  FETCH_EACH_PRODUCT_SIZE_SUCCESS,
} from './../Constants/constants'

//sizing product items
export const GetSizingProducts = (props: any) => ({
  type: GET_SIZING_PRODUCTS,
  payload: props,
})
export const GetSizingProductsSuccess = (data: any) => ({
  type: GET_SIZING_PRODUCTS_SUCCESS,
  payload: { data },
})
export const GetSizingProductsFailure = (error: any) => ({
  type: GET_SIZING_PRODUCTS_FAILURE,
  payload: { error },
})

//get static-contents table data using params, short code
export const GetStaticContentData = (props: any) => ({
  type: GET_STATIC_CONTENTS_DATA,
  payload: props,
})
export const getStaticContentsDataSuccess = (data: any) => ({
  type: GET_STATIC_CONTENTS_DATA_SUCCESS,
  payload: data,
})
export const GetStaticContentDataFailure = (error: any) => ({
  type: GET_STATIC_CONTENTS_DATA_FAILURE,
  payload: error,
})

//post jacket sizes action
export const PostJacketSizes = (props: any) => ({
  type: POST_JACKET_SIZES.REQUEST,
  payload: props,
})
export const PostJacketSizesSuccess = (data: any) => ({
  type: POST_JACKET_SIZES.SUCCESS,
  payload: { data: data },
})
export const PostJacketSizesFailed = (error: any) => ({
  type: POST_JACKET_SIZES.FAILED,
  payload: { error: error },
})

//get size of each product
export const productSizeRequest = (id: any) => ({
  type: FETCH_EACH_PRODUCT_SIZE_REQUEST,
  payload: { productId: id },
})
export const productSizeSuccess = (data: any) => {
  return {
    type: FETCH_EACH_PRODUCT_SIZE_SUCCESS,
    payload: {
      data: data,
    },
  }
}
export const productSizeFailure = (error: any) => {
  return {
    type: FETCH_EACH_PRODUCT_SIZE_FAILED,
    payload: {
      error: error,
    },
  }
}

//post static contents with shortCode, title and values
export const postStaticContentForm = (props: any) => {
  return {
    type: POST_STATIC_CONTENT_REQUEST,
    payload: props,
  }
}

export const postStaticContentFormSuccess = (data: any) => {
  return {
    type: POST_STATIC_CONTENT_SUCCESS,
    payload: {
      data,
    },
  }
}

export const postStaticContentFormFailed = (error: any) => {
  return {
    type: POST_STATIC_CONTENT_FAILED,
    payload: {
      error,
    },
  }
}

//swap position
export const swapPosition = (props: any) => {
  return {
    type: SWAP_POSITION_REQUEST,
    payload: props,
  }
}

export const swapPositionSuccess = (data: any) => {
  return {
    type: SWAP_POSITION_SUCCESS,
    payload: data,
  }
}

export const swapPositionFailure = (error: any) => {
  return {
    type: SWAP_POSITION_FAILED,
    payload: {
      data: error,
    },
  }
}

// code for commit
