import { Col, Image, Row } from "antd";
import React, { FC } from "react";
import AuthBackground from "../Assets/Company/login-background.jpg";
import { ReactComponent as DarkLogo } from "../Assets/Company/logo-dark.svg";
import { Content } from "antd/lib/layout/layout";

const AuthLaylout: FC = ({ children }) => {
  return (
    <Content>
      <Row
        style={{
          margin: 0,
          minHeight: "100vh",
          maxHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <Col lg={12} className="p-0 overflow-hidden site-layout-background">
          {/* <Image src={AuthBackground} className='' preview={false}  /> */}
          <div className="overflow--black" style={{ padding: 20 }}>
            <DarkLogo width="calc(100vw - 500px)" />
          </div>
        </Col>
        <Col lg={12} style={{ height: "100vh" }}>
          {children}
        </Col>
      </Row>
    </Content>
  );
};

export default AuthLaylout;
