import { Button, Card, Form, Input, notification, Row } from "antd";
import { Auth } from "aws-amplify";
import "./style.scss";
import { useState } from "react";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);

  const onFinishFailed = (errorInfo: any) => {};
  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
  const validatePassword = (password: string) => {
    return strongRegex.test(password);
  };
  const onFinish = ({ oldPassword, password }: any) => {
    setLoading(true);

    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, password);
      })
      .then((data) => {
        setLoading(false);
        notification.success({ message: "Success changing password", placement: "bottom" });
      })
      .catch((err) => {
        setLoading(false);
        notification.error({
          message: err?.message || "Error changing password",
          placement: "bottom",
        });
      });
  };
  return (
    <Card className="newPassword">
      <h1
        style={{
          fontSize: "38px",
        }}
      >
        Change Password
      </h1>

      <Form layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item
          label="Old Password"
          name="oldPassword"
          rules={[{ required: true, message: "Please input your old password!" }]}
          className="newPasswordField"
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          // help={[
          //   `The passwords need to have \n`,
          //   `- more than 8 characters! \n`,
          //   `- a capital letter`,
          //   `- a symbol`,
          //   `- a number`,
          // ]}
          hasFeedback
          className="newPasswordField"
        >
          <Input.Password type="password" />
        </Form.Item>
        <div className="password-rules">
          <p>The passwords need to have</p>
          <p>- more than 8 characters!</p>
          <p>- a capital letter</p>
          <p>- a symbol</p>
          <p>- a number </p>
        </div>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!validatePassword(value)) {
                  return Promise.reject(new Error("Password not strong enough"));
                }
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
          className="newPasswordField"
        >
          <Input.Password type="password" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading} loading={loading} block>
            CHANGE PASSWORD
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ChangePassword;
