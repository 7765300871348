export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';

export const FETCH_CUSTOMER_DETAILS = 'FETCH_CUSTOMER_DETAILS';
export const FETCH_CUSTOMER_DETAILS_SUCCESS = 'FETCH_CUSTOMER_DETAILS_SUCCESS';
export const FETCH_CUSTOMER_DETAILS_FAILURE = 'FETCH_CUSTOMER_DETAILS_FAILURE';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER';
export const REMOVE_CUSTOMER_SUCCESS = 'REMOVE_CUSTOMER_SUCCESS';
export const REMOVE_CUSTOMER_FAILURE = 'REMOVE_CUSTOMER_FAILURE';

export const FETCH_CUSTOMER_TIMELINE = 'FETCH_CUSTOMER_TIMELINE';
export const FETCH_CUSTOMER_TIMELINE_SUCCESS = 'FETCH_CUSTOMER_TIMELINE_SUCCESS';
export const FETCH_CUSTOMER_TIMELINE_FAILURE = 'FETCH_CUSTOMER_TIMELINE_FAILURE';

export interface CustomerFetchParams {
	limit?: number;
	page?: number;
	user_id?: string;
}
export interface CustomerDetailsParams {
	id?: any;
	user_id?: any;
}
export interface CreateCustomerParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
