/** @format */

import { Col, Row, Select, Space, Typography } from 'antd'
import NeuMorph from '../../../../Components/Admin/NeuMorph/NeuMorph'
import LatidoTable from '../../../../Components/Admin/Table/Table'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import ActionList from '../ActionList/ActionList'
import { RootStateOrAny } from 'react-redux'
import { productSizeRequest } from '../../../../Redux/Actions/StaticContentsActions'

interface SizingPreviewProps {}
const SizingPreview: React.FC<SizingPreviewProps> = () => {
  const [countryCode, setCountryCode] = useState<string>('UK')
  const { productId } = useParams<any>()
  const history = useNavigate()
  const dispatch = useDispatch()
  const ProductSizesPreview = useSelector(
    (state: RootStateOrAny) => state.staticContents
  )
  const { ProductSizes, loading } = ProductSizesPreview
  const columns = [
    {
      title: `Size - ${countryCode}`,
      render: (text: any, record: any) => (
        <Typography.Text>{text.name}</Typography.Text>
      ),
      key: 'name',
    },
    {
      title: 'XS',
      dataIndex: 'XS',
      render: (text: any, record: any) => (
        <Typography.Text>{record?.sizes[0].XS}</Typography.Text>
      ),
      key: 'XS',
    },
    {
      title: 'S',
      dataIndex: 'S',
      key: 'S',
      render: (text: any, record: any) => (
        <Typography.Text>{record?.sizes[0].S}</Typography.Text>
      ),
    },
    {
      title: 'M',
      key: 'M',
      dataIndex: 'M',
      render: (text: any, record: any) => (
        <Typography.Text>{record?.sizes[0].M}</Typography.Text>
      ),
    },
    {
      title: 'ML',
      key: 'ML',
      dataIndex: 'ML',
      render: (text: any, record: any) => (
        <Typography.Text>{record?.sizes[0].ML}</Typography.Text>
      ),
    },
    {
      title: 'L',
      key: 'L',
      dataIndex: 'L',
      render: (text: any, record: any) => (
        <Typography.Text>{record?.sizes[0].L}</Typography.Text>
      ),
    },
    {
      title: 'XL',
      key: 'XL',
      dataIndex: 'XL',
      render: (text: any, record: any) => (
        <Typography.Text>{record?.sizes[0].XL}</Typography.Text>
      ),
    },
    {
      title: 'XXL',
      key: 'XXL',
      dataIndex: 'XXL',
      render: (text: any, record: any) => (
        <Typography.Text>{record?.sizes[0].XXL}</Typography.Text>
      ),
    },
    {
      title: 'XXXL',
      key: 'XXXL',
      dataIndex: 'XXXL',
      render: (text: any, record: any) => (
        <Typography.Text>{record?.sizes[0].XXXL}</Typography.Text>
      ),
    },
    {
      title: '4XL',
      dataIndex: '4XL',
      key: '4XL',
      render: (text: any, record: any) => (
        <Typography.Text>{record?.sizes[0]['4XL']}</Typography.Text>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any): any => {
        return (
          <Space size='middle'>
            <ActionList
              onEdit={() => history(`/static-contents/sizing/${record._id}`)}
              onDetail={() =>
                history(`/static-contents/sizing/preview/${record._id}`)
              }
            />
          </Space>
        )
      },
    },
  ]

  const EachProductSizes = ProductSizes[countryCode]?.filter(
    (product: any) => product.category_id === productId
  )

  useEffect(() => {
    if (!ProductSizes && productId) {
      dispatch(productSizeRequest(productId))
    }
  }, [productId])

  return (
    <Row>
      <Col span={24}>
        <Space
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography.Title className='product-name' level={2}>
            SIZING
          </Typography.Title>
          {countryCode && (
            <Select
              placeholder='Size: UK'
              allowClear
              showArrow
              onSelect={(value: string) => setCountryCode(value)}
              style={{ width: 181, borderRadius: '20px' }}
            >
              <Select.Option value='UK'>Size: UK</Select.Option>
              <Select.Option value='US'>Size: US</Select.Option>
            </Select>
          )}
        </Space>
      </Col>
      <Col span={24} style={{ marginTop: 30 }}>
        <LatidoTable
          loading={loading}
          columns={columns}
          dataSource={EachProductSizes}
        />
      </Col>
    </Row>
  )
}

export default SizingPreview
