import { DashboardCardColor } from './enums'
import {
  DashboardCardType,
  MaterialCategoryType,
  MaterialItemType,
} from './types'

import buttons from '../Assets/MaterialCategory/buttons.png'
import elastic from '../Assets/MaterialCategory/elastic.png'
import needle from '../Assets/MaterialCategory/needle.png'
import polyfill from '../Assets/MaterialCategory/polyfill.png'
import thread from '../Assets/MaterialCategory/thread.png'
import zipper from '../Assets/MaterialCategory/zipper.png'

import silver_zipper from '../Assets/MaterialItems/silver_zipper.png'
import golden_zipper from '../Assets/MaterialItems/golden_zipper.png'
import zinc_zipper from '../Assets/MaterialItems/zinc_zipper.png'

export interface MenuType {
  title: string
  route: string
  children?: MenuChildren[]
}

export interface MenuChildren {
  title: string
  route: string
}

export const DummyMenu = [
  {
    title: 'Dashboard',
    route: '/',
  },
  {
    title: 'Inventory',
    route: '/inventory/raw-materials',
  },
  {
    title: 'Vendors',
    route: '/vendors',
  },
  {
    title: 'Reporting',
    route: '/reporting',
  },
]

export const DummyDashboardCard: DashboardCardType[] = [
  {
    title: 'IN STOCK',
    amount: 40689,
    iconColor: DashboardCardColor.BLUE,
  },
  {
    title: 'ALRAMING STOCK',
    amount: 40689,
    iconColor: DashboardCardColor.ORANGE,
  },
  {
    title: 'OUT OF STOCK',
    amount: 40689,
    iconColor: DashboardCardColor.GREEN,
  },
]

export const DummySummary = {
  revenue: 1009990,
  profit: 279,
  orders: 6,
}

export const DummyMaterialCategories: MaterialCategoryType[] = [
  {
    title: 'Zipper',
    category: 'zipper',
    icon: zipper,
  },
  {
    title: 'Polyfill',
    category: 'polyfill',
    icon: polyfill,
  },
  {
    title: 'Buttons',
    category: 'button',
    icon: buttons,
  },
  {
    title: 'Threads',
    category: 'thread',
    icon: thread,
  },
  {
    title: 'Needles',
    category: 'needle',
    icon: needle,
  },
  {
    title: 'Elastic',
    category: 'elastic',
    icon: elastic,
  },
  {
    title: 'ReadyMade',
    category: 'readymade',
    icon: zipper,
  },
]

export const DummyItems: MaterialItemType[] = [
  {
    image: silver_zipper,
    name: 'Silver Zipper 1',
    quantity: 100,
    category: 'zipper',
  },
  {
    image: silver_zipper,
    name: 'Silver Zipper 2',
    quantity: 0,
    category: 'zipper',
  },
  {
    image: silver_zipper,
    name: 'Silver Zipper 3',
    quantity: 400,
    category: 'zipper',
  },
  {
    image: golden_zipper,
    name: 'Golden Zipper 1',
    quantity: 344,
    category: 'polyfill',
  },
  {
    image: golden_zipper,
    name: 'Golden Zipper 1',
    quantity: 344,
    category: 'polyfill',
  },
  {
    image: golden_zipper,
    name: 'Golden Zipper 3',
    quantity: 55,
    category: 'polyfill',
  },
  {
    image: zinc_zipper,
    name: 'Zinc Zipper 1',
    quantity: 1,
    category: 'polyfill',
  },
  { image: zinc_zipper, name: 'Zinc Zipper 2', category: 'thread' },
  { image: zinc_zipper, name: 'Zinc Zipper 3', category: 'thread' },
  { name: 'Zinc Zipper 3', quantity: 1, category: 'thread' },
  { image: zinc_zipper, name: 'Zinc Zipper 4', category: 'thread' },
  { image: zinc_zipper, name: 'Zinc Zipper 4', category: 'thread' },
]
