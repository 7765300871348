import { Route, Routes } from "react-router";
import SmoothScroll from "../Container/SmoothScroll";
import AuthRoute from "./AuthRoute";
import ProtectedRoute from "./ProtectedRoute";

export const MainRoute = () => {
  return (
    <SmoothScroll>
      <Routes>
        <Route path="/auth" element={<AuthRoute />} />
        <Route path="/*" element={<ProtectedRoute />} />
      </Routes>
    </SmoothScroll>
  );
};
export default MainRoute;
