import { VendorReducer } from "./../Vendor/VendorReducer";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { AuthReducer } from "../AuthRedux/AuthReducer";
import { InventoryCategoryReducer } from "../InventoryCategory/InventoryCategoryReducer";
import { InventoryItemReducer } from "../InventoryItem/InventoryItemReducer";
import { ReportingReducer } from "../Reporting/ReportReducer";
import { DashboardReducer } from "../Dashboard/DashboardReducer";
import { InventoryCategoriesInitialState } from "../InventoryCategory/InventoryCategoryTypes";
import { InventoryItemInitialState } from "../InventoryItem/InventoryItemTypes";
import { VendorInitialState } from "../Vendor/VendorTypes";
import { DamageItemsInitialState } from "../Reporting/ReportTypes";
import { DashboardInitialState } from "../Dashboard/DashboardTypes";
import StaticContentsReducer from "./StaticContentsReducers";
import { MemberReducer } from "../MemberRedux/MemberReducer";
import { CustomerReducer } from "../CustomerRedux/CustomerReducer";
import ExchangeRefundReducer from "./ExchangeRefundReducer";
import { BlogReducer } from "../BlogRedux/BlogReducer";
import { gridReducer } from "./GridReducer";
import { FeatureProductReducer } from "./FeatureProductReducer";
import { LeatherProfileReducer } from "./LeatherProfileReducer";
import { ProductReducer } from "../ProductRedux/ProductReducer";
import { ProductCategoryReducer } from "../ProductCategoryRedux/ProductCategoryReducer";
import LayoutReducers from "./LayoutReducers";

const reducers = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    AuthReducer: AuthReducer,
    InventoryCategoryReducer: InventoryCategoryReducer,
    InventoryItemReducer: InventoryItemReducer,
    VendorReducer: VendorReducer,
    ReportingReducer: ReportingReducer,
    DashboardReducer: DashboardReducer,
    staticContents: StaticContentsReducer,
    MemberReducer: MemberReducer,
    CustomerReducer: CustomerReducer,
    ExchangeRefundReducer: ExchangeRefundReducer,
    BlogReducer: BlogReducer,
    gridReducer: gridReducer,
    featureProductReducer: FeatureProductReducer,
    LeatherProfileReducer: LeatherProfileReducer,
    ProductReducer: ProductReducer,
    ProductCategoryReducer: ProductCategoryReducer,
    LayoutReducers: LayoutReducers,
  });

export default reducers;

export type CombineReducerType = {
  InventoryCategoryReducer: InventoryCategoriesInitialState;
  InventoryItemReducer: InventoryItemInitialState;
  VendorReducer: VendorInitialState;
  ReportingReducer: DamageItemsInitialState;
  DashboardReducer: DashboardInitialState;
};
