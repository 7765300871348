import React from "react";
import styled, { css } from "styled-components";

const trackH = "0.4em";
const thumbD = "0.4em";
// const thumbD = "1.5em";

const progressC = "#c4c4c4";
const trackC = "#333"; //right
const filllC = progressC; //progress
const thumbColor = "transparent"; //progress

const track = css`
  box-sizing: border-box;
  border: none;
  height: 4px;
  background: ${trackC};
  border-radius: 8px;
`;

const trackFill = css`
  ${track};
  height: 4px;
  background-color: transparent;
  background-image: linear-gradient(${filllC}, ${filllC}), linear-gradient(${trackC}, ${trackC});
  background-size: var(--sx) 6px, calc(100% - var(--sx)) 4px;
  background-position: left center, right center;
  background-repeat: no-repeat;
`;

const fill = css`
  height: ${trackH};
  background: ${filllC};
  border-radius: 4px;
`;

const thumb = css`
  box-sizing: border-box;
  border: none;
  width: ${thumbD};
  height: ${thumbD};
  outline: none;
  border-radius: 50%;
  background: ${thumbColor};
  box-shadow: 0px 0px 5px rgba(66, 97, 255, 0.5);
`;

const Input = styled.input`
  overflow: hidden;
  margin: 0 16px !important;

  &,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    outline: none !important; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  &:focus {
    outline: none;
  }

  &:focus::-webkit-slider-thumb {
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:focus::-moz-range-thumb {
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:focus::-ms-thumb {
    outline: -webkit-focus-ring-color auto 5px;
  }

  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min)) / var(--range));
  --sx: calc(0.5 * ${thumbD} + var(--ratio) * (100% - ${thumbD}));

  margin: 0;
  padding: 0;
  height: ${thumbD};
  background: transparent;
  font: 1em/1 arial, sans-serif;

  &::-webkit-slider-runnable-track {
    ${trackFill};
  }

  &::-moz-range-track {
    ${track};
  }

  &::-ms-track {
    ${track};
  }

  &::-moz-range-progress {
    ${fill};
  }

  &::-ms-fill-lower {
    ${fill};
  }

  &::-webkit-slider-thumb {
    margin-top: calc(0.5 * (${trackH} - ${thumbD}));
    ${thumb};
  }

  &::-moz-range-thumb {
    ${thumb};
  }

  &::-ms-thumb {
    margin-top: 0;
    ${thumb};
  }

  &::-ms-tooltip {
    display: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }
`;

const SeekBarStyle = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  & input[type="range"] {
    flex: 1;
    padding: 0px;
    cursor: pointer;
    outline: none !important;
  }
`;

const calculateTime = (secs: any) => {
  const minutes = Math.floor(secs / 60);
  const seconds = Math.floor(secs % 60);
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${minutes}:${returnedSeconds}`;
};

export const SeekBar = ({ onChange, duration = 500, current = 0 }: any) => {
  const [currentTime, setCurrentTime] = React.useState("0:00");
  const [totalTime, setTotalTime] = React.useState("0:00");
  const [progress, setProgress] = React.useState(0);
  const onInput = ({ target }: any) => {
    let value = target.value;
    setProgress(value);
    onChange(value);
    value = calculateTime(value);
    setCurrentTime(value);
  };
  React.useEffect(() => {
    setTotalTime(calculateTime(duration));
    setProgress(current);
    setCurrentTime(calculateTime(current));
  }, [duration, current]);
  return (
    <SeekBarStyle className="seek__bar">
      <span className="current__time">{currentTime}</span>
      <Input
        type="range"
        onInput={onInput}
        max={duration}
        min={0}
        style={{
          width: "100%",
          // "--min": 0,
          // "--max": duration,
          // "--val": progress,
        }}
        value={progress}
      />
      <span className="total__time">{totalTime} </span>
    </SeekBarStyle>
  );
};

// const useSeekBar = () => {
//     const [duration, setDuration] = React.useState()
//     return [onChange]
// }
