/** @format */

import { Upload, message, Typography, Space, Divider } from 'antd'
import './UploadFiles.scss'
import Logo_dragAndDrop from '../../../Assets/Icons/Group 43.png'
import axios from 'axios'
import { getMediaUrl } from '../../../API/MediaApi'
import { defaultFunction } from '../../../Redux/Constants/constants'
import UploadButton from '../UploadButton/UploadButton'

const { Dragger } = Upload

interface PropType {
  info?: any
  onChange?: any
  onUpload?: (e: any) => void
  circle?: boolean
}
const UploadFiles = ({
  info,
  onChange,
  onUpload = defaultFunction,
  circle,
}: PropType) => {
  const beforeUpload = (file: any) => {
    getMediaUrl({ filePath: 'blogAudio', fileType: file.type })
      .then((data: any) => {
        uploadFile({ file: file, urls: data })
        return true
      })
      .catch((error: any) => {
        return false
      })

    return true
  }

  const uploadFile = (data: any) => {
    axios.put(data?.urls?.signedUrlPut, data?.file).then((d: any) => {
      onUpload(data?.urls?.filePathURL)
    })
  }

  return circle ? (
    <Dragger
      className='old--container'
      beforeUpload={(e: any) => beforeUpload(e)}
      method='PUT'
      action=''
    >
      <Space direction='vertical' size={-15}>
        <Typography.Text className='ant-upload-text'>
          Upload Image
        </Typography.Text>
        <Divider plain>OR</Divider>
        <UploadButton />
      </Space>
    </Dragger>
  ) : (
    <Dragger beforeUpload={(e: any) => beforeUpload(e)} method='PUT' action=''>
      <Space direction='vertical' size={-15}>
        <img className='logo-dnd' src={Logo_dragAndDrop} alt='dnd' />
        <Typography.Text className='ant-upload-text'>
          Drag and Drop your files here.
        </Typography.Text>
        <Divider plain>OR</Divider>
        <UploadButton />
      </Space>
    </Dragger>
  )
}
export default UploadFiles
