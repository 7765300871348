/** @format */

import { Upload, Typography, Space, Card, Button, message, Popconfirm } from "antd";
import "./CommonS3UploadFiles.scss";
import { CameraOutlined, CloseCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import { Storage } from "aws-amplify";
import dayjs from "dayjs";
import { useState } from "react";

import { ReactComponent as PlusIcon } from "../../../Assets/Icons/plus-icon.svg";
import { ReactComponent as MultipleImage } from "../../../Assets/Icons/MultipleImage.svg";
import { ReactComponent as AddImage } from "../../../Assets/Icons/add-image.svg";
import Text from "antd/lib/typography/Text";
import ImgCrop from "antd-img-crop";
import { defaultFunction } from "../../../Redux/Constants/constants";
import MediaUrl from "../../../Utils/mediaUrl";
import Loading from "../Loading/Loading";

const { Dragger } = Upload;

interface PropType {
  info?: any;
  onChange?: any;
  onUpload: (e: any) => void;
  title?: string;
  style?: React.CSSProperties;
  height?: number;
  small?: boolean;
  url?: any | string;
  module: string;
  prefix?: string;
  disabled?: boolean;
  accept?: string;
  circular?: boolean;
  type?: string;
  ratio?: any;
  skipTemporary?: boolean;
  setIsImageUploading?: (e:boolean)=>void;
}

const CircularContainer = (props: any) => {
  return (
    <div className="circular__container">
      {props?.url ? (
        <img width="100%" src={props?.url} />
      ) : (
        <div>
          <CameraOutlined />
          <Typography.Paragraph>Upload Image</Typography.Paragraph>
        </div>
      )}
    </div>
  );
};

const RatioUploader = (props: any) => {
  const { ratio } = props;
  return (
    <div className="ratio__container" style={{ width: 20 * ratio?.[0], height: 20 * ratio?.[1] }}>
      {props?.url ? (
        <img width="100%" src={props?.url} />
      ) : (
        <div>
          <CameraOutlined />
          <Typography.Paragraph>Upload Image</Typography.Paragraph>
        </div>
      )}
    </div>
  );
};

const ProductContainer = (props: any) => {
  return (
    <Card className="product__image__container" style={{ minHeight: 300 }}>
      {props?.url ? (
        <img alt="Uploadd" height="300px" src={props?.url} />
      ) : (
        <div>
          <PlusIcon />
          <Typography.Paragraph strong>Upload Profile Picture</Typography.Paragraph>
        </div>
      )}
    </Card>
  );
};

const ButtonContainer = (props: any) => {
  return (
    <Button
      className=""
      style={{
        color: "#6F869D",
        borderColor: "#6F869D",
        borderRadius: 0,
        borderWidth: 2,
        display: "flex",
        alignItems: "center",
      }}
      icon={<AddImage />}
    >
      <Text
        style={{
          color: "#6F869D",
          display: "inline-block",
          marginLeft: 10,
        }}
      >
        Upload More Images
      </Text>
    </Button>
    // <Card className="product__image__container" style={{ minHeight: 300 }}>
    //   {props?.url ? (
    //     <img alt="Uploadd" width="100%" src={props?.url} />
    //   ) : (
    //     <div>
    //       <PlusIcon />
    //       <Typography.Paragraph strong>
    //         UPLOAD PRIMARY IMAGES
    //       </Typography.Paragraph>
    //     </div>
    //   )}
    // </Card>
  );
};

const ButtonContainerCard = (props: any) => {
  return (
    <Card
      className=""
      style={{
        color: "#6F869D",
        borderColor: "#6F869D",
        borderRadius: 0,
        borderWidth: 2,
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
      // icon={<AddImage />}
    >
      <div>
        <AddImage />
        <Text
          style={{
            color: "#6F869D",
            display: "inline-block",
            marginLeft: 10,
          }}
        >
          Upload More Images
        </Text>
        <p className="ant-upload-hint">Click or drag file to this area to upload</p>
      </div>
    </Card>
    // <Card className="product__image__container" style={{ minHeight: 300 }}>
    //   {props?.url ? (
    //     <img alt="Uploadd" width="100%" src={props?.url} />
    //   ) : (
    //     <div>
    //       <PlusIcon />
    //       <Typography.Paragraph strong>
    //         UPLOAD PRIMARY IMAGES
    //       </Typography.Paragraph>
    //     </div>
    //   )}
    // </Card>
  );
};

export const ProductFeatureContainer = (props: any) => {
  return (
    <div className="product__image__container" style={{ width: "100%" }}>
      {props?.url ? (
        <img alt="Uploadd" width="100%" src={props?.url} />
      ) : (
        <div>
          <MultipleImage />
          <Typography.Paragraph strong>UPLOAD MULTIPLE IMAGES</Typography.Paragraph>
        </div>
      )}
    </div>
  );
};

const DefaultContainer = (props: any) => {
  return (
    <Space direction="vertical" size={15}>
      <img
        alt="Default"
        width="100%"
        src="https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg"
      />
      <Typography.Text style={{ fontSize: 14, color: "#8285A3" }}>
        {props?.title || "Upload Image"}
      </Typography.Text>
    </Space>
  );
};

const supportedFileTypes = ["image/jpeg", "image/png", "image/jpg", "image/webp", "image/avif"];
const DefaultTwoContainer = (title: string) => {
  return (
    <Space size={2} direction="vertical">
      <PlusCircleOutlined style={{ fontSize: 10, lineHeight: "10px" }} />
      <Typography.Text style={{ fontSize: 10, lineHeight: "10px", color: "#cccccc" }}>
        Add Image
      </Typography.Text>
    </Space>
  );
};
const CommonS3UploadFiles: React.FC<PropType> = ({
  info,
  onChange,
  onUpload = defaultFunction,
  title,
  children,
  style,
  height,
  small,
  url,
  module,
  prefix,
  disabled,
  accept,
  circular,
  type = "default",
  ratio,
  skipTemporary,
  setIsImageUploading
}) => {
  const [tempUri, setTempUri] = useState<any>(false);
  const [loading, setLoading] = useState(false);

  const s3FileUpload = (file: any) => {
    // check if file is supported
    if (!supportedFileTypes.includes(file.type)) {
      message.error("File type not supported, please upload jpeg, png or jpg");
      return;
    }
    const tempName = file?.name?.split(".");
    const name =
      tempName.slice(0, tempName.length - 1).join("-") +
      dayjs()?.unix() +
      "." +
      tempName[tempName.length - 1];
    setLoading(true);
    setIsImageUploading && setIsImageUploading(true);
    Storage.put(`${prefix || module}/${name.split(" ").join("-")}`, file, {
      contentType: file.type,
    })
      .then((result: any) => {
        setLoading(false);
        setIsImageUploading && setIsImageUploading(false);

        onUpload(`public/${result?.key}`);
        if (!skipTemporary) setTempUri(MediaUrl("public/" + result?.key));
      })
      .catch((err) => {
        setLoading(false);
        setIsImageUploading && setIsImageUploading(false);
        // this.setState({ response: `Cannot uploading file: ${err}` });
      });
    return true;
  };

  const getContainer = (key: string) => {
    switch (key) {
      case "circular":
        return <CircularContainer url={tempUri || url} />;
      case "product":
        return <ProductContainer style={style} url={tempUri || url} />;
      case "product-feature":
        return <ProductFeatureContainer style={style} url={tempUri || url} />;
      case "button":
        return <ButtonContainer style={style} url={tempUri || url} />;
      case "button-card":
        return <ButtonContainerCard style={style} url={tempUri || url} />;
      case "ratio":
        return <RatioUploader style={style} url={tempUri || url} ratio={ratio} />;
      default:
        return <DefaultContainer url={tempUri || url} title={title} />;
    }
  };

  return (
    <>
    {/* <input type="file" onChange={(e: any) => s3FileUpload(e?.target?.files[0])} />
    <ImgCrop quality={0.9} fillColor="transparent" aspect={ratio?.[0] / ratio?.[1] || 1 / 1.5}> */}
      <Dragger
        accept={accept}
        disabled={disabled || loading}
        showUploadList={false}
        style={style}
        className={`${type} common-s3-upload--container ${circular ? "circular" : ""} ${
          small ? "small" : ""
        }`}
        beforeUpload={(e: any) => s3FileUpload(e)}
        method="PUT"
        action=""
      >
        <div style={{ position: "relative" }}>
          {!loading && (tempUri || url) && (
            <div style={{ position: "absolute", top: 5, right: 5, zIndex: 20 }}>
              <Popconfirm
                title="Are you sure you want to remove image?"
                onConfirm={(e) => {
                  e?.stopPropagation();
                  // e.stopPropagation()
                  setTempUri("");
                  onUpload(null);
                }}
              >
                <CloseCircleFilled color="red" onClick={(e) => e.stopPropagation()} />
              </Popconfirm>
            </div>
          )}
          <>{children ? <>{children}</> : <>{getContainer(type)}</>}</>
          {loading && (
            <div className="uploading--wrapper">
              <Loading tip="Uploading..." />
            </div>
          )}
        </div>
      </Dragger>
    {/* </ImgCrop> */}
    </>
  );
};
export default CommonS3UploadFiles;
