import {
  CreditCardFilled,
  CreditCardOutlined,
  EditOutlined,
  FacebookFilled,
  InstagramFilled,
  TwitterCircleFilled,
} from "@ant-design/icons";
import { Avatar, Card, Col, Descriptions, Divider, Row, Space, Tabs, Typography } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import UserMeasurement from "../../../../Components/Admin/UserMeasurement/UserMeasurement";
import UsersOrders from "../../../../Components/Admin/UsersOrders/UsersOrders";
import UserTimeline from "../../../../Components/Admin/UserTimeline/UserTimeline";
import dayjs from "dayjs";
import React from "react";
const { TabPane } = Tabs;

export const ProfileSummary = (props: any) => {
  const { data } = props;
  return (
    <Card>
      {!props.noEdit && (
        <Row justify="end">
          <Col onClick={() => props.onEdit()}>
            <Text>
              Edit Profile{" "}
              <Avatar
                size={24}
                style={{ background: "black" }}
                icon={<EditOutlined style={{ fontSize: 14 }} />}
              />
            </Text>
          </Col>
        </Row>
      )}
      {
        <Row justify="end">
          <Col>{props.popover}</Col>
        </Row>
      }
      <Row justify="center" align="middle" style={{ width: "100%", flexDirection: "column" }}>
        <Avatar size={140} style={{ marginBottom: 20 }} src={data?.profileImage} />
        <Title level={4}>{data?.name || "N/A"}</Title>
      </Row>
      <Divider />
      <Descriptions
        title="Contact Information"
        layout="vertical"
        column={2}
        labelStyle={{ color: "#636770" }}
        contentStyle={{ color: "black" }}
      >
        <Descriptions.Item label="Email Address">{data?.email || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Phone Number">{data?.phone || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Address">{data?.address || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Joined Date">
          {data?.createdAt ? dayjs(data?.createdAt).format("LL") : "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label="Connect with">
          <Space size="small">
            <FacebookFilled /> <InstagramFilled /> <TwitterCircleFilled />
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export const UserProfileDetails = (props: any) => {
  function callback(key: string) {}

  return (
    <Row gutter={[20, 20]}>
      <Col lg={8}>
        <Typography.Title>PROFILE</Typography.Title>

        <ProfileSummary data={props?.data?.details} onEdit={props.onEdit} />
      </Col>
      <Col lg={14}>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Timeline" key="timeline">
            <UserTimeline />
          </TabPane>
          <TabPane tab="Measurements" key="measurements">
            <UserMeasurement data={props?.data?.size} />
          </TabPane>
          <TabPane tab="Order" key="order">
            <UsersOrders />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default UserProfileDetails;
