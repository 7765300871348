import { notification } from "antd";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { fetchDashboardFailure, fetchDashboardSuccess } from "./DashboardActions";

import { takeDashboard } from "./DashboardApis";

import { DashboardType, FETCH_DASHBOARD } from "./DashboardTypes";

//get dashboard
const getDashboardAsync = async () => {
  const res = await takeDashboard();
  return res;
};
function* getDashboard() {
  try {
    const dashboard: DashboardType = yield call(getDashboardAsync);
    if (dashboard) {
      yield put(fetchDashboardSuccess(dashboard));
    } else {
      yield put(fetchDashboardFailure("Could not get Dashboard"));
      notification.error({
        message: "Could not get  Dashboard",
        placement: "bottom",
      });
    }
  } catch (error: any) {
    yield put(fetchDashboardFailure(error?.message));
    notification.error({
      message: error?.message,
      placement: "bottom",
    });
  }
}

function* watchGetDashboard() {
  yield takeLatest(FETCH_DASHBOARD, getDashboard);
}

export default function* DashboardSagas() {
  yield all([fork(watchGetDashboard)]);
}
