import {
	CreateProductCategoryParams,
	CREATE_PRODUCT_CATEGORY,
	CREATE_PRODUCT_CATEGORY_FAILURE,
	CREATE_PRODUCT_CATEGORY_SUCCESS,
	FETCH_PRODUCT_CATEGORYS,
	FETCH_PRODUCT_CATEGORYS_FAILURE,
	FETCH_PRODUCT_CATEGORYS_SUCCESS,
	FETCH_PRODUCT_CATEGORY,
	FETCH_PRODUCT_CATEGORY_FAILURE,
	FETCH_PRODUCT_CATEGORY_SUCCESS,
	REMOVE_PRODUCT_CATEGORY,
	REMOVE_PRODUCT_CATEGORY_FAILURE,
	REMOVE_PRODUCT_CATEGORY_SUCCESS,
	UPDATE_PRODUCT_CATEGORY,
	UPDATE_PRODUCT_CATEGORY_FAILURE,
	UPDATE_PRODUCT_CATEGORY_SUCCESS,
	ProductCategoryDetailsParams,
	ProductCategoryFetchParams
} from './ProductCategoryTypes';

//fetch ProductCategory List
export const fetchProductCategorys = (params: ProductCategoryFetchParams) => ({
	type: FETCH_PRODUCT_CATEGORYS,
	payload: params
});

export const fetchProductCategorysSuccess = (response: any) => ({
	type: FETCH_PRODUCT_CATEGORYS_SUCCESS,
	payload: response
});

export const fetchProductCategorysFailure = (error: any) => ({
	type: FETCH_PRODUCT_CATEGORYS_FAILURE,
	payload: error
});

//fetch ProductCategory Details
export const fetchProductCategory = (params: ProductCategoryDetailsParams) => ({
	type: FETCH_PRODUCT_CATEGORY,
	payload: params
});

export const fetchProductCategorySuccess = (response: any) => ({
	type: FETCH_PRODUCT_CATEGORY_SUCCESS,
	payload: response
});

export const fetchProductCategoryFailure = (error: any) => ({
	type: FETCH_PRODUCT_CATEGORY_FAILURE,
	payload: error
});

//create ProductCategory
export const createProductCategory = (params: CreateProductCategoryParams) => ({
	type: CREATE_PRODUCT_CATEGORY,
	payload: params
});

export const createProductCategorySuccess = (response: CreateProductCategoryParams) => ({
	type: CREATE_PRODUCT_CATEGORY_SUCCESS,
	payload: response
});
export const createProductCategoryFailure = (error: any) => ({
	type: CREATE_PRODUCT_CATEGORY_FAILURE,
	payload: error
});

//update ProductCategory
export const updateProductCategory = (params: CreateProductCategoryParams) => ({
	type: UPDATE_PRODUCT_CATEGORY,
	payload: params
});

export const updateProductCategorySuccess = (response: CreateProductCategoryParams) => ({
	type: UPDATE_PRODUCT_CATEGORY_SUCCESS,
	payload: response
});

export const updateProductCategoryFailure = (error: any) => ({
	type: UPDATE_PRODUCT_CATEGORY_FAILURE,
	payload: error
});

//delete ProductCategory
export const deleteProductCategory = (params: ProductCategoryDetailsParams) => ({
	type: REMOVE_PRODUCT_CATEGORY,
	payload: params
});
export const deleteProductCategorySuccess = (response: ProductCategoryDetailsParams) => ({
	type: REMOVE_PRODUCT_CATEGORY_SUCCESS,
	payload: response
});
export const deleteProductCategoryFailure = (error: any) => ({
	type: REMOVE_PRODUCT_CATEGORY_FAILURE,
	payload: error
});
