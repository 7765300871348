import { useState } from 'react'

export function LatidoAnimatedLogoLoader() {
  const [dur] = useState('5s')

  const [len] = useState(360)

  return (
    <div
      className='App'
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        overflow: 'hidden',
        width: '100%',
        height: '200px',
        display: 'flex',
      }}
    >
      <svg height='200' width='150' viewBox='0 0 54 56' fill='none'>
        <path
          strokeDashoffset={len}
          strokeDasharray={len}
          d='M46.7999 5.53873C45.3791 2.20028 42.8689 0.0373467 39.7904 0.366489C37.0435 0.648612 33.302 3.37579 30.4129 8.97122C26.2451 17.1057 25.7242 36.196 17.8622 44.3776C11.4685 51.0544 4.31692 51.1485 3.41706 49.0796C2.61192 47.2458 5.21679 44.6597 8.8636 44.3776C11.8474 44.1425 16.1572 45.2239 23.3088 49.2677C30.4603 53.3584 36.4278 55.9916 44.0056 55.1452C50.873 54.3459 53.3831 49.3617 53.3831 49.3617L50.8256 44.3305C50.8256 44.3305 47.2261 50.02 40.5009 50.2551C33.4914 50.4902 27.4292 47.9041 23.9718 46.1173C20.5144 44.3305 13.5524 38.406 7.3954 38.9232C1.23845 39.4404 -2.31364 44.4246 1.71206 50.7723C5.73776 57.0731 12.463 56.1797 18.5253 51.9008C24.6349 47.622 27.4765 40.0517 29.2763 34.6443C31.076 29.237 32.8284 19.0806 34.7228 13.6262C36.6172 8.17187 41.6375 4.45726 43.4373 6.29106C44.9528 7.84273 38.1328 15.0368 33.6809 17.2468C24.8243 21.6667 16.3467 20.7733 16.3467 20.7733L18.9989 25.9926C18.9989 25.9926 27.8554 26.7449 36.5225 22.0899C47.0841 16.4475 48.647 9.81758 46.7999 5.53873Z'
          fill='none'
          stroke='#000'
          strokeWidth={0.4}
        >
          <animate
            attributeName='stroke-dashoffset'
            values='357;0;357' // len;0;len
            dur={dur}
            fill='freeze'
            repeatCount='indefinite'
          ></animate>
        </path>
      </svg>
    </div>
  )
}
