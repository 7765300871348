import { Route, Routes } from 'react-router'
import withPermission from '../Utils/checkPermission'
import ProductCategorys from '../Views/Admin/ProductCategorys/ProductCategorys'
import SingleProductCategory from '../Views/Admin/ProductCategorys/SingleProductCategory'

export const ProductCategoryRoute = (props: any) => {
  return (
    <Routes>
      <Route path={`/`} element={<ProductCategorys />} />
      <Route path={`/:id/`} element={<SingleProductCategory />} />
      <Route
        path={`/:id/:type?/:childId?/:childType?`}
        element={<SingleProductCategory />}
      />
    </Routes>
  )
}

export default withPermission(ProductCategoryRoute, 'productCategory')
