import React from "react";
import { Route, Routes } from "react-router";
import withPermission from "../Utils/checkPermission";
import Members from "../Views/Admin/Members/Members";
import UserProfile from "../Views/Admin/Users/UserProfile/UserProfile";

export const MemberRoute = (props: any) => {
  return (
    <Routes>
      <Route path={`/:userId`} element={<UserProfile />} />
      <Route path={`/`} element={<Members />} />
    </Routes>
  );
};

export default withPermission(MemberRoute, "member");
