import React from "react";
export const [
  SET_PLAYLIST,
  SET_CURRENT_MEDIA,
  SET_CURRENT_TIME,
  SET_DURATION,
  SET_JUMP_TIME,
  SET_RANDOM,
  TOGGLE_PLAY,
  RANDOM_MEDIA,
  PLAY,
  PAUSE,
  NEXT,
  PREV,
  TOGGLE_MUTE,
  SET_VOLUME,
] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "NEXT", "PREV", "TOGGLE_MUTE", "SET_VOLUME"];
const initialState = {
  current: {
    author: "",
    title: "",
    src: "",
    duration: "",
    currenTime: "",
    playing: false,
    muted: false,
  },
  playlist: [],

  index: 0,
};

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case PREV:
      if (!state.playlist?.length) return state;
      state.index--;
      const p = state.index % state.playlist.length | 0;
      return {
        ...state,
        current: { ...state.playlist[p < 0 ? p * -1 : p], playing: true },
      };
    case NEXT:
      if (!state.playlist?.length) return state;
      state.index++;
      const n = state.index % state.playlist.length | 0;
      return {
        ...state,
        current: { ...state.playlist[n], playing: true },
      };
    case PAUSE:
      return { ...state, current: { ...state.current, playing: false } };
    case PLAY:
      return { ...state, current: { ...state.current, playing: true } };
    case TOGGLE_MUTE:
      return {
        ...state,
        current: { ...state.current, muted: !state.current.muted },
      };
    case SET_VOLUME:
      return {
        ...state,
        current: { ...state.current, volume: parseFloat(action.payload) },
      };
    case SET_PLAYLIST:
      console.log(
        "🚀 ~ file: PlayBarRedux.js ~ line 34 ~ reducer ~ action.payload",
        action.payload
      );
      if (action.payload < 1) return state;
      return { ...state, playlist: action.payload, current: action.payload[0] };
    case RANDOM_MEDIA:
      if (state.playlist.length === 0) return state;
      const r = state.playlist[(Math.random() * 10) % state.playlist.length | 0];

      return {
        ...state,
        current: { ...r, playing: true },
      };
    case SET_CURRENT_MEDIA:
      return { ...state, current: action.payload };
    case SET_JUMP_TIME:
      return { ...state, jump: action.payload };
    case SET_CURRENT_TIME:
      return { ...state, current: { ...state.current, time: action.payload } };
    case SET_DURATION:
      return {
        ...state,
        current: { ...state.current, duration: action.payload },
      };
    default:
      return state;
  }
};

export const MediaPlayerStoreContext = React.createContext(null);

export const PlaybarReducer = () => React.useReducer(reducer, initialState);
