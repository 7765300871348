import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Typography,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import UploadFiles from '../../../../../Components/Admin/UploadFiles/UploadFiles'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { PostNewsletterForm } from '../../../../../Redux/Actions/GridAction'

interface TemplatesTypes {
  history?: any
}
const Templates: FC<TemplatesTypes> = ({ history }) => {
  const [value1, setValue1] = useState()
  const [form] = useForm()

  const dispatch = useDispatch()
  const { title, templateType } = useParams<any>()

  const onFinish = (values: any) => {
    dispatch(PostNewsletterForm(values))
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { sm: 24, md: 10 },
  }

  const fullSpanLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 24 },
  }
  return (
    <>
      <Card>
        <Row justify='center' align='middle'>
          <Col span={16}>
            <Form layout='vertical' form={form} onFinish={onFinish}>
              <Typography.Title
                level={3}
                style={{ textTransform: 'uppercase' }}
              >
                CREATE {title}
              </Typography.Title>
              <Form.Item
                {...layout}
                label='title'
                name='Title'
                rules={[{ required: true, message: 'Title is required.' }]}
              >
                <Input />
              </Form.Item>
              {templateType === 'short-form' && (
                <Form.Item
                  {...layout}
                  name='productName'
                  label='Product Name'
                  rules={[
                    { required: true, message: 'Product Name is required.' },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
              <Form.Item
                {...fullSpanLayout}
                label='message'
                name='message'
                rules={[{ required: true, message: 'Message is required.' }]}
              >
                <Input.TextArea rows={5} />
              </Form.Item>
              {templateType === 'long-form' && (
                <>
                  {' '}
                  <Form.Item
                    {...layout}
                    label='orderStatus'
                    name='Order Status'
                    rules={[
                      { required: true, message: 'Order Status is required.' },
                    ]}
                  >
                    <Select placeholder='Order Status category' allowClear>
                      <Select.Option value='cat 1'>cat 1</Select.Option>
                      <Select.Option value='cat 2'>cat 2</Select.Option>
                      <Select.Option value='cat 3'>cat 3</Select.Option>
                    </Select>
                  </Form.Item>
                  <Divider />
                  <Form.Item {...fullSpanLayout}>
                    <Row justify='space-between'>
                      <Col xs={24} sm={24} md={10}>
                        <Form.Item
                          label='orderDate'
                          name='Order Date'
                          rules={[
                            {
                              required: true,
                              message: 'Order Date is required.',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={10}>
                        <Form.Item
                          label='orderName'
                          name='Order Name'
                          rules={[
                            {
                              required: true,
                              message: 'Order Name is required.',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item {...fullSpanLayout} className='form-content'>
                    <Form.Item
                      {...layout}
                      label='payment'
                      name='Payment'
                      rules={[
                        { required: true, message: 'Payment is required.' },
                      ]}
                    >
                      <Select placeholder='Payment category' allowClear>
                        <Select.Option value='cat 1'>cat 1</Select.Option>
                        <Select.Option value='cat 2'>cat 2</Select.Option>
                        <Select.Option value='cat 3'>cat 3</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...layout}
                      label='delivery'
                      name='Delivery'
                      rules={[
                        { required: true, message: 'Delivery is required.' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Form.Item>
                  <Divider />
                  <Form.Item {...fullSpanLayout}>
                    <Row justify='space-between'>
                      <Col xs={24} sm={24} md={10}>
                        <Form.Item
                          label='productName'
                          name='Product Name'
                          rules={[
                            {
                              required: true,
                              message: 'Product Name is required.',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={10}>
                        <Form.Item
                          label='shippngCharge'
                          name='Shipping Charge'
                          rules={[
                            {
                              required: true,
                              message: 'Shipping Charge is required.',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item {...fullSpanLayout}>
                    <Row justify='space-between'>
                      <Col xs={24} sm={24} md={10}>
                        <Row justify='space-between'>
                          <Col span={10}>
                            <Form.Item
                              label='size'
                              name='Size'
                              rules={[
                                {
                                  required: true,
                                  message: 'Size is required.',
                                },
                              ]}
                            >
                              <Select placeholder='Payment category' allowClear>
                                <Select.Option value='cat 1'>
                                  cat 1
                                </Select.Option>
                                <Select.Option value='cat 2'>
                                  cat 2
                                </Select.Option>
                                <Select.Option value='cat 3'>
                                  cat 3
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item
                              label='quality'
                              name='Quality'
                              rules={[
                                {
                                  required: true,
                                  message: 'Quality is required.',
                                },
                              ]}
                            >
                              <Select placeholder='Payment category' allowClear>
                                <Select.Option value='cat 1'>
                                  cat 1
                                </Select.Option>
                                <Select.Option value='cat 2'>
                                  cat 2
                                </Select.Option>
                                <Select.Option value='cat 3'>
                                  cat 3
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={10}>
                        <Form.Item
                          label='totalAmount'
                          name='Total Amount'
                          rules={[
                            {
                              required: true,
                              message: 'Total Amount is required.',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                </>
              )}
              <Divider />
              <Form.Item
                {...fullSpanLayout}
                label='status'
                name='Status'
                rules={[{ required: true, message: 'Status is required.' }]}
              >
                <Radio.Group
                  onChange={(e) => setValue1(e.target.value)}
                  value={value1}
                >
                  <Radio value='Published'>Published</Radio>
                  <Radio value='Draft'>Draft</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item {...fullSpanLayout}>
                <UploadFiles />
              </Form.Item>
              <Form.Item {...fullSpanLayout}>
                <Button
                  type='primary'
                  shape='round'
                  size='middle'
                  htmlType='submit'
                >
                  Save
                </Button>
                <Button
                  type='text'
                  onClick={() => history.push('/grid/types-of-email')}
                >
                  cancel
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}
export default Templates
