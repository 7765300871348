import {
  ADD_FEATURE_PRODUCT_FAILURE,
  ADD_FEATURE_PRODUCT_REQUEST,
  ADD_FEATURE_PRODUCT_SUCCESS,
  ADD_PLACEMENT_FORM_FAILURE,
  ADD_PLACEMENT_FORM_REQUEST,
  ADD_PLACEMENT_FORM_SUCCESS,
  GET_FEATURE_PRODUCTS_FAILED,
  GET_FEATURE_PRODUCTS_REQUEST,
  GET_FEATURE_PRODUCTS_SUCCESS,
} from '../Constants/constants'

//fetch feature product list
export const GetFeatureProductRequest = () => ({
  type: GET_FEATURE_PRODUCTS_REQUEST,
})

export const GetFeatureProductSuccess = (data: any) => ({
  type: GET_FEATURE_PRODUCTS_SUCCESS,
  payload: { data },
})

export const GetFeatureProductFailed = (error: any) => ({
  type: GET_FEATURE_PRODUCTS_FAILED,
  payload: { error },
})

//add feature product
export const addFeatureProduct = (values: any) => {
  return {
    type: ADD_FEATURE_PRODUCT_REQUEST,
    payload: { values },
  }
}
export const addFeatureProductSuccess = (data: any) => {
  return {
    type: ADD_FEATURE_PRODUCT_SUCCESS,
    payload: { data: data },
  }
}
export const addFeatureProductFailure = (error: any) => ({
  type: ADD_FEATURE_PRODUCT_FAILURE,
  payload: { error },
})

//add placement form
export const addPlacementForm = (data: any) => {
  return {
    type: ADD_PLACEMENT_FORM_REQUEST,
    payload: { data },
  }
}
export const addPlacementFormSuccess = (data: any) => {
  return {
    type: ADD_PLACEMENT_FORM_SUCCESS,
    payload: { data },
  }
}
export const addPlacementFormFailure = (error: any) => {
  return {
    type: ADD_PLACEMENT_FORM_FAILURE,
    payload: { error },
  }
}
