import { Reducer } from 'react'
import { ObjectFilter } from '../../Utils/ObjectFilter'
import {
  CreateMemberParams,
  CREATE_MEMBER,
  CREATE_MEMBER_FAILURE,
  CREATE_MEMBER_SUCCESS,
  FETCH_MEMBERS,
  FETCH_MEMBERS_FAILURE,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBER_DETAILS,
  FETCH_MEMBER_DETAILS_FAILURE,
  FETCH_MEMBER_DETAILS_SUCCESS,
  REMOVE_MEMBER,
  REMOVE_MEMBER_FAILURE,
  REMOVE_MEMBER_SUCCESS,
  UPDATE_MEMBER,
  UPDATE_MEMBER_FAILURE,
  UPDATE_MEMBER_SUCCESS,
  MemberDetailsParams,
} from './MemberTypes'

const INIT_STATE = {
  list: [],
  error: '',
  detailsError: '',
  details: {},
  loading: false,
  loaded: false,
  detailing: false,
  detailed: false,
  creating: false,
  created: false,
  deleting: false,
  deleted: false,
  updating: false,
  updated: false,
  total: 0,
  page: 1,
}

export const MemberReducer: Reducer<any, any> = (
  state = INIT_STATE,
  action
) => {
  switch (action.type) {
    //fetch Members
    case FETCH_MEMBERS:
      return { ...state, loading: true }
    case FETCH_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload?.data,
        ...ObjectFilter(action.payload, (key: any) => key != 'data'),
        loaded: true,
        error: false,
      }
    case FETCH_MEMBERS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    //fetch Member Details
    case FETCH_MEMBER_DETAILS:
      return { ...state, detailing: true }
    case FETCH_MEMBER_DETAILS_SUCCESS:
      return { ...state, detailing: false, details: action.payload }
    case FETCH_MEMBER_DETAILS_FAILURE:
      return { ...state, detailing: false, detailsError: action.payload }

    //create Member
    case CREATE_MEMBER:
      return { ...state, creating: true, created: false }
    case CREATE_MEMBER_SUCCESS:
      state.list.push(action.payload)
      return { ...state, creating: false, created: true }
    case CREATE_MEMBER_FAILURE:
      return { ...state, creating: false }

    //update Member
    case UPDATE_MEMBER:
      return { ...state, updating: true, updated: false }
    case UPDATE_MEMBER_SUCCESS:
      const tempList = state.list.filter(
        (d: CreateMemberParams) => d.id != action.payload.id
      )
      tempList.push(action.payload)
      return { ...state, updating: false, list: tempList, updated: true }
    case UPDATE_MEMBER_FAILURE:
      return { ...state, updating: false }

    //remove Member
    case REMOVE_MEMBER:
      return { ...state, deleting: true }
    case REMOVE_MEMBER_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: state.list.filter(
          (d: MemberDetailsParams) => d.id != action.payload.id
        ),
      }
    case REMOVE_MEMBER_FAILURE:
      return { ...state, deleting: false }

    default:
      return { ...state }
  }
}
