/** @format */

import { Route, Routes } from 'react-router'
import PlacementForm from '../Components/Admin/PlacementForm/PlacementForm'
import withPermission from '../Utils/checkPermission'
import FeatureProductMainForm from '../Views/Admin/FeatureProductManagement/FeatureProductMainForm/FeatureProductMainForm'
import FeatureProductManagement from '../Views/Admin/FeatureProductManagement/FeatureProductManagement.tsx/FeatureProductManagement'

const FeatureProductRoutes = (props: any) => {
  return (
    <Routes>
      <Route
        path={`/feature-product-management`}
        element={<FeatureProductManagement />}
      />
      <Route path={`/:slug`} element={<FeatureProductMainForm />} />
      <Route path={`/:slug/:formType`} element={<PlacementForm />} />
    </Routes>
  )
}
export default withPermission(FeatureProductRoutes, 'featureProduct')
