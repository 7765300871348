import { Route, Routes } from 'react-router'
import withPermission from '../Utils/checkPermission'
import Products from '../Views/Admin/Products/Products'
import SingleProduct from '../Views/Admin/Products/SingleProduct'

export const ProductRoute = (props: any) => {
  return (
    <Routes>
      <Route path={`/`} element={<Products />} />
      <Route
        path={`/:id/:type?/:childId?/:childType?`}
        element={<SingleProduct />}
      />
      <Route path={`/:id/:type`} element={<SingleProduct />} />
      <Route path={`/:id`} element={<SingleProduct />} />
    </Routes>
  )
}
export default withPermission(ProductRoute, 'product')
