import { API, Auth } from "aws-amplify";
import { ApiHeaders, INVENTORY_API_NAME } from "../../API/variable";

//get Inventory Categories
export const takeInventoryCategories = async (q: string) => {
  const res = await API.get(
    INVENTORY_API_NAME,
    `/inventory_category/inventory_category_list?search=${q}`,
    {
      headers: ApiHeaders,
    }
  );
  return res;
};

//get Inventory Categories
export const takeInventoryCategoriy = async (id: any) => {
  const res = await API.get(
    INVENTORY_API_NAME,
    `/inventory_category/inventory_category_detail?category_id=${id}`,
    {
      headers: ApiHeaders,
    }
  );
  return res;
};

//create Inventory Categories
export const makeInventoryCategories = async (data: any) => {
  const res = await API.post(INVENTORY_API_NAME, "/inventory_category/add_inventory_category", {
    body: {
      ...data,
    },
    headers: ApiHeaders,
  });
  return res;
};

// //modify Post
// export const modifyPost = (data: any) =>
//   API.post(API_NAME, '/post', { headers: ApiHeaders, data: data })

// //delete Post
// export const cutPost = (data: any) =>
//   API.post(API_NAME, '/post', { headers: ApiHeaders, data: data })
