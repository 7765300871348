import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Empty, Modal, Pagination, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import MyContent from "../../../../Components/Admin/Content/Content";
import Loading from "../../../../Components/Admin/Loading/Loading";
import PageHeader from "../../../../Components/Admin/PageHeader/PageHeader";
import PageHolder from "../../../../Components/Admin/PageHolder/PageHolder";
import { useEffect, Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  fetchProductCategory,
  fetchProductCategorys,
} from "../../../../Redux/ProductCategoryRedux/ProductCategoryActions";
import ProductCategoryForm from "../../Forms/Forms/ProductCategoryForm";
import ProductCategoryItem from "../..//ProductCategorys/ProductCategoryItem/ProductCategoryItem";
import { useForm } from "antd/lib/form/Form";

export const ProductCategoryDetails = (props: any) => {
  const productcategory = useSelector((state: any) => state.ProductCategoryReducer);
  const { id } = useParams<any>();
  const { detailing, details, list, totalRecords, loading } = productcategory;
  const dispatch = useDispatch();

  const history = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(fetchProductCategorys({ parent_category: id, limit: 10, page: 1 }));
      dispatch(fetchProductCategory({ id: id }));
    }
  }, [id]);

  const [addForm, setAddForm] = useState(false);
  const [form] = useForm();

  return (
    <MyContent>
      <Suspense fallback={<Loading fullScreen />}>
        <Content>
          <Space align="center" size="large">
            <Typography.Title level={5}>{details?.title}</Typography.Title>
            {/* <Button
              onClick={() => history.push(`${details?.id}/edit`)}
              shape="circle"
              size="small"
              icon={<EditOutlined />}
            /> */}
          </Space>
          <Divider />
          <PageHolder loading={false} empty={!details}>
            <Row gutter={[60, 20]}>
              {/* <Col lg={8}>
                <ProductCategoryItem data={details} />
              </Col> */}
              <Col lg={24}>
                <PageHeader
                  buttonText="Add Sub Category"
                  module="product"
                  title=""
                  defaultAdd={false}
                  onAdd={() => setAddForm(true)}
                  // extra={
                  //   <Select>
                  //     <Select.Option value="test">test</Select.Option>
                  //   </Select>
                  // }
                />
                <Row gutter={[20, 20]} align="stretch">
                  {!loading &&
                    list?.map((item: any) => {
                      return (
                        <Col lg={6}>
                          <ProductCategoryItem disableView data={item} />
                        </Col>
                      );
                    })}
                  <Col span={24} hidden={!loading && list?.length === 0}>
                    <Empty description="Sub categories not found" />
                  </Col>

                  {loading && (
                    <>
                      {Array(5)
                        .fill(null)
                        ?.map(() => {
                          return (
                            <Col lg={6}>
                              <Card style={{ minHeight: 200 }} loading={loading} />
                            </Col>
                          );
                        })}
                    </>
                  )}
                </Row>
                <Row style={{ marginTop: 50 }} justify="center">
                  <Col>
                    <Pagination hideOnSinglePage total={totalRecords} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </PageHolder>
          <Modal
            footer={false}
            visible={addForm}
            onCancel={() => {
              setAddForm(false);
              form.resetFields();
            }}
          >
            <ProductCategoryForm
              parentId={id}
              onCancel={() => {
                form.resetFields();

                setAddForm(false);
              }}
              form={form}
            />
          </Modal>
        </Content>
      </Suspense>
    </MyContent>
  );
};

export default ProductCategoryDetails;
