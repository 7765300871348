import { notification } from "antd";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  fetchDamageItemsFailure,
  fetchDamageItemsSuccess,
  fetchReportFailure,
  fetchReportSuccess,
  fetchReportsFailure,
  fetchReportsSuccess,
} from "./ReportActions";

import { takeDamageItems, takeReport, takeReports } from "./ReportApis";

import { DamageItemsRes, FETCH_DAMAGE_ITEMS, FETCH_REPORT, FETCH_REPORTS } from "./ReportTypes";

//get Reports
const getReportsAsync = async (data: any) => {
  const res = await takeReports(data);
  return res;
};
function* getReports(params: any) {
  const { payload } = params;
  try {
    const vendors: Array<any> = yield call(getReportsAsync, payload);
    if (vendors) {
      yield put(fetchReportsSuccess(vendors));
    } else {
      yield put(fetchReportsFailure("Could not get Reports"));
      notification.error({
        message: "Could not get  Reports",
        placement: "bottom",
      });
    }
  } catch (error: any) {
    yield put(fetchReportsFailure(error?.message));
    notification.error({
      message: error?.message,
      placement: "bottom",
    });
  }
}

function* watchGetReports() {
  yield takeLatest(FETCH_REPORTS, getReports);
}

//get Report
const getReportAsync = async (data: any) => {
  const res = await takeReport(data);
  return res;
};
function* getReport(params: any) {
  const { payload } = params;
  try {
    const report: Array<string> = yield call(getReportAsync, payload);
    if (report) {
      yield put(fetchReportSuccess(report));
    } else {
      yield put(fetchReportFailure("Could not get Report"));
      notification.error({
        message: "Could not get Report",
        placement: "bottom",
      });
    }
  } catch (error: any) {
    yield put(fetchReportFailure(error?.message));
    notification.error({
      message: error?.message,
      placement: "bottom",
    });
  }
}

function* watchGetReport() {
  yield takeLatest(FETCH_REPORT, getReport);
}

//get Damage Items
const getDamageItemsAsync = async (data: any) => {
  const res = await takeDamageItems(data);
  return res;
};
function* getDamageItems(params: any) {
  const { payload } = params;
  try {
    const vendors: DamageItemsRes = yield call(getDamageItemsAsync, payload);
    if (vendors) {
      yield put(fetchDamageItemsSuccess(vendors));
    } else {
      yield put(fetchDamageItemsFailure("Could not get Damage Items"));
      notification.error({
        message: "Could not get  Damage Items",
        placement: "bottom",
      });
    }
  } catch (error: any) {
    yield put(fetchDamageItemsFailure(error?.message));
    notification.error({
      message: error?.message,
      placement: "bottom",
    });
  }
}

function* watchGetDamageItems() {
  yield takeLatest(FETCH_DAMAGE_ITEMS, getDamageItems);
}

export default function* ReportSagas() {
  yield all([fork(watchGetReports), fork(watchGetReport), fork(watchGetDamageItems)]);
}
