import { Content } from "antd/lib/layout/layout";
import ReactQuill from "react-quill";
import "./BlogText.scss";
import Editor from "./TextEditor";
import { useEffect, useState } from "react";
export const BlogText = ({ onChange, value }: any) => {
  console.log(value, "vl");
  return (
    <Content>
      {/* <ReactQuill value={props.value?.content} onChange={(e: any) => props.onChange(e)} className="custom-quill" modules={{ toolbar: ['bold', 'italic', 'underline'] }} /> */}
      {/* {ReactQuill} */}
      <Editor onChange={onChange} value={value?.content} />
    </Content>
  );
};

export default BlogText;
