import { Content } from "antd/lib/layout/layout";
import CustomerDetail from "./CustomerDetail";

export const UserProfile = (props: any) => {
  return (
    <Content>
      <CustomerDetail />
    </Content>
  );
};

export default UserProfile;
