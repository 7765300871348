/** @format */

import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  TimePicker,
  Typography,
} from 'antd'
import { createRef, FC, useEffect, useRef, useState } from 'react'
import { useForm } from 'antd/lib/form/Form'
import IphoneX from '../../../Assets/iPhone X 1.png'
import { Content } from 'antd/lib/layout/layout'
import upload_logo from '../../../Assets/Icons/Group 43.png'
import ReactQuill from 'react-quill'
import { useDispatch } from 'react-redux'
import { Option } from 'antd/lib/mentions'
import { useParams } from 'react-router'
import UploadFiles from '../UploadFiles/UploadFiles'
import PreviewModalButton from '../PreviewModalButton/PreviewModal'

interface stateType {
  onNewState?: any
  placementText?: any
  type?: string
  onState?: any
  onInitialFormState?: (e: string) => void
  onPlacementFormValues?: (e: {}) => void
}
interface ObjectTypes {
  date?: string
  time?: string
  placement?: string
}
const PlacementForm: FC<stateType> = ({
  type,
  onNewState,
  placementText,
  onInitialFormState = () => {},
  onPlacementFormValues = () => {},
}) => {
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [secondForm, setSecondForm] = useState<ObjectTypes>({
    date: '',
    time: '',
    placement: placementText,
  })

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const { slug, formType } = useParams<any>()
  const handleDatePicker = (date: any, dateString: any) => {
    setSecondForm({ ...secondForm, date: dateString })
  }
  const handleTimePicker = (time: any, timeString: any) => {
    setSecondForm({ ...secondForm, time: timeString })
  }
  const dispatch = useDispatch()

  const onFinish = (values: any) => {
    const data = { values, secondForm }
    onInitialFormState('normal_form')
    onPlacementFormValues(values)
    //onNewState("");
    //dispatch(addPlacementForm(data));
  }

  const labelLayout = {
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
    },
  }
  const handleChange = (value: any) => {}

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Title level={1} style={{ textTransform: 'uppercase' }}>
              {slug}
            </Typography.Title>
          </Col>
          <Col span={8}>
            <Card>Leather Jacket </Card>
          </Col>
        </Row>
        <Row gutter={[60, 20]} style={{ marginTop: 30 }}>
          <Col span={16}>
            <Form layout='vertical' form={form} onFinish={onFinish}>
              <Card style={{ padding: 40 }}>
                {/*<UploadFormFeatureProduct />*/}
                <Row>
                  <Col push={15} span={10}>
                    <Form.Item name='textZero'>
                      {formType === 'type1' && ReactQuill}
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  style={{
                    height: 460,
                    maxWidth: 620,
                    margin: 'auto',
                    background: '#EBF5FF',
                    position: 'relative',
                  }}
                >
                  {formType !== 'type4' && (
                    <>
                      <Col pull={2} span={8} style={{ marginTop: 100 }}>
                        <Form.Item name='textOne'>
                          <Input defaultValue='V Text' />
                        </Form.Item>
                      </Col>
                      <Col
                        push={
                          formType === 'type1'
                            ? 13
                            : formType === 'type2' || formType === 'type3'
                            ? 10
                            : ''
                        }
                        span={4}
                        style={{
                          marginTop:
                            formType === 'type1'
                              ? 150
                              : formType === 'type2' || formType === 'type3'
                              ? 100
                              : '',
                        }}
                      >
                        <Form.Item name='textTwo'>
                          <Input
                            defaultValue={
                              formType === 'type3'
                                ? 'View More >'
                                : 'Winter Picks >'
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col
                            push={14}
                            span={12}
                            style={{
                              marginTop: formType === 'type1' ? 150 : '',
                            }}
                          >
                            <Form.Item name='textThree'>
                              <Input defaultValue='V Text' />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
                  <Col
                    onClick={() => setIsModalVisible(true)}
                    span={24}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%,-50%)',
                    }}
                  >
                    <Image
                      preview={false}
                      src={upload_logo}
                      style={{ height: 46, width: 'auto' }}
                    />
                  </Col>
                  {type === 'type4' && (
                    <Col
                      span={24}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '40%',
                      }}
                    >
                      <Form.Item name='textFour'>
                        <Input defaultValue='V Text' style={{ width: '40%' }} />
                      </Form.Item>
                      <Button
                        style={{
                          width: '15%',
                          border: '0.25px solid #B5B5B566',
                          backgroundColor: '#EBF5FF',
                          marginTop: 10,
                        }}
                      >
                        DISCOVER
                      </Button>
                    </Col>
                  )}
                  <Modal
                    closable={false}
                    footer={null}
                    onCancel={handleCancel}
                    visible={isModalVisible}
                  >
                    <Content
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <UploadFiles />
                    </Content>
                  </Modal>
                </Row>
                <Content style={{ marginTop: 50 }}>
                  <Button
                    style={{ marginLeft: 20 }}
                    type='primary'
                    shape='round'
                    size='middle'
                    htmlType='submit'
                  >
                    Save
                  </Button>
                  <Button
                    type='text'
                    size='middle'
                    onClick={() => onInitialFormState('normal_form')}
                  >
                    Cancel
                  </Button>
                </Content>
              </Card>
            </Form>
          </Col>
          <Col span={8}>
            <Card>
              <Form.Item
                {...labelLayout}
                name='Placement'
                label='Placement'
                rules={[{ required: true, message: 'Required' }]}
              >
                <Select defaultValue={placementText} onChange={handleChange}>
                  <Option key='Home Page'>Home Page</Option>
                  <Option key='Inner Page'>Inner Page</Option>
                  <Option key='Feature Page'>Feature Page</Option>
                </Select>
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Required' }]}
                name='Schedule'
                label='Schedule'
              >
                <Row gutter={[20, 0]}>
                  <Col span={12}>
                    <Form.Item name='Placement'>
                      {/* <DatePicker onChange={handleDatePicker} /> */}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='Schedule'>
                      <TimePicker onChange={handleTimePicker} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Card>
            <Card style={{ marginTop: 30 }}>
              <Typography.Title level={4}>Preview</Typography.Title>
              <Typography.Paragraph>
                See the preview of the product. In the same way, users will see
                a product in the market.
              </Typography.Paragraph>
              <Content
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Image preview={false} src={IphoneX} />
              </Content>
              <Typography.Title
                level={5}
                style={{ textAlign: 'center', marginTop: 20 }}
              >
                Mobile View V
              </Typography.Title>
              <PreviewModalButton />
            </Card>
          </Col>
        </Row>

        {/*</Row>*/}
      </Col>
    </Row>
  )
}
export default PlacementForm
