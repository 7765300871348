import { Card, Col, Divider, Form, Input, Row } from "antd";
import { useEffect } from "react";

interface propsType {
  data?: any;
}
const Sizing = ({ data }: propsType) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...data,
    });
  }, [data]);

  return (
    <Card className="sizing white-border">
      <h2 className="page-title">Body Measurment</h2>

      <div className="sizing__content">
        <Form
          form={form}
          colon={false}
          requiredMark={false}
          labelCol={{ span: 4 }}
          labelAlign={"left"}
          labelWrap={true}
        >
          <Row style={{ marginTop: 50 }}>
            <Col sm={8}>
              <Form.Item label="Height" name="height">
                <Input
                  suffix="ft."
                  disabled
                  id="height"
                  className="input-field white-border"
                  type="text"
                />
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item label="shoulder" name="shoulder">
                <Input
                  suffix="in."
                  id="sholder"
                  className="input-field white-border"
                  type="text"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item label="Waist" name="waist">
                <Input
                  suffix="in."
                  id="waist"
                  className="input-field white-border"
                  type="text"
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: 0 }} />

          <Row style={{ marginTop: 30 }}>
            <Col sm={8}>
              <Form.Item label="length" name="length">
                <Input
                  suffix="in."
                  disabled
                  id="length"
                  className="input-field white-border"
                  type="text"
                />
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item label="arms" name="arms">
                <Input
                  suffix="in."
                  disabled
                  id="arms"
                  className="input-field white-border"
                  type="text"
                />
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item label="hips" name="hips">
                <Input
                  suffix="in."
                  disabled
                  id="hips"
                  className="input-field white-border"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: 0 }} />

          <Row style={{ marginTop: 30 }}>
            <Col sm={8}>
              <Form.Item label="chest" name="chest">
                <Input
                  suffix="in."
                  disabled
                  id="chest"
                  className="input-field white-border"
                  type="text"
                />
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item label="weight" name="weight">
                <Input
                  suffix="Kg."
                  disabled
                  id="weight"
                  className="input-field white-border"
                  type="text"
                />
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item label="sleeves" name="sleeves">
                <Input
                  suffix="in."
                  disabled
                  id="sleeves"
                  className="input-field white-border"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: 0 }} />
        </Form>
      </div>
      <div className="footer">
        <h4>Answers</h4>
        <p> : Loose/Indoor/Slim</p>
      </div>
    </Card>
  );
};

export default Sizing;
