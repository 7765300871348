import { Card, Col, Image, Row } from "antd";

interface propsType {
  data?: any;
}
const UserInfo = ({ data }: propsType) => {
  return (
    <Card className="order-details__basicInfo white-border">
      <h2 className="page-title">User Information</h2>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "50px 0",
        }}
      >
        <Image src={data?.profile_image} alt={`${data?.name}'s Profile Image`} width={250} />
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 50,
        }}
      >
        <Col className="col">
          <p>Name</p>
          <h3>{data?.name}</h3>
        </Col>
        <Col className="col">
          <p>Gender</p>
          <h3>{data?.gender}</h3>
        </Col>
        <Col className="col">
          <p>Email</p>
          <h3>{data?.email}</h3>
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 50,
        }}
      >
        <Col className="col">
          <p>Contact No.</p>
          <h3>{data?.phone}</h3>
        </Col>
        <Col className="col">
          <p>Address</p>
          <h3>{data?.address?.city || data?.address}</h3>
        </Col>
        <Col className="col"></Col>
      </Row>
    </Card>
  );
};

export default UserInfo;
