import {
	CreateCustomerParams,
	CREATE_CUSTOMER,
	CREATE_CUSTOMER_FAILURE,
	CREATE_CUSTOMER_SUCCESS,
	FETCH_CUSTOMERS,
	FETCH_CUSTOMERS_FAILURE,
	FETCH_CUSTOMERS_SUCCESS,
	FETCH_CUSTOMER_DETAILS,
	FETCH_CUSTOMER_DETAILS_FAILURE,
	FETCH_CUSTOMER_DETAILS_SUCCESS,
	REMOVE_CUSTOMER,
	REMOVE_CUSTOMER_FAILURE,
	REMOVE_CUSTOMER_SUCCESS,
	UPDATE_CUSTOMER,
	UPDATE_CUSTOMER_FAILURE,
	UPDATE_CUSTOMER_SUCCESS,
	CustomerDetailsParams,
	CustomerFetchParams,
	FETCH_CUSTOMER_TIMELINE,
	FETCH_CUSTOMER_TIMELINE_SUCCESS,
	FETCH_CUSTOMER_TIMELINE_FAILURE
} from './CustomerTypes';

//fetch Customer List
export const fetchCustomers = (params: CustomerFetchParams) => ({
	type: FETCH_CUSTOMERS,
	payload: params
});

export const fetchCustomersSuccess = (response: any) => ({
	type: FETCH_CUSTOMERS_SUCCESS,
	payload: response
});

export const fetchCustomersFailure = (error: any) => ({
	type: FETCH_CUSTOMERS_FAILURE,
	payload: error
});

//fetch Customer List
export const fetchCustomerTimeline = (params: CustomerFetchParams) => ({
	type: FETCH_CUSTOMER_TIMELINE,
	payload: params
});

export const fetchCustomerTimelineSuccess = (response: any) => ({
	type: FETCH_CUSTOMER_TIMELINE_SUCCESS,
	payload: response
});

export const fetchCustomerTimelineFailure = (error: any) => ({
	type: FETCH_CUSTOMER_TIMELINE_FAILURE,
	payload: error
});

//fetch Customer Details
export const fetchCustomer = (params: CustomerDetailsParams) => ({
	type: FETCH_CUSTOMER_DETAILS,
	payload: params
});

export const fetchCustomerSuccess = (response: any) => ({
	type: FETCH_CUSTOMER_DETAILS_SUCCESS,
	payload: response
});

export const fetchCustomerFailure = (error: any) => ({
	type: FETCH_CUSTOMER_DETAILS_FAILURE,
	payload: error
});

//create Customer
export const createCustomer = (params: CreateCustomerParams) => ({
	type: CREATE_CUSTOMER,
	payload: params
});

export const createCustomerSuccess = (response: CreateCustomerParams) => ({
	type: CREATE_CUSTOMER_SUCCESS,
	payload: response
});
export const createCustomerFailure = (error: any) => ({
	type: CREATE_CUSTOMER_FAILURE,
	payload: error
});

//update Customer
export const updateCustomer = (params: any) => ({
	type: UPDATE_CUSTOMER,
	payload: params
});

export const updateCustomerSuccess = (response: CreateCustomerParams) => ({
	type: UPDATE_CUSTOMER_SUCCESS,
	payload: response
});

export const updateCustomerFailure = (error: any) => ({
	type: UPDATE_CUSTOMER_FAILURE,
	payload: error
});

//delete Customer
export const deleteCustomer = (params: CustomerDetailsParams) => ({
	type: REMOVE_CUSTOMER,
	payload: params
});
export const deleteCustomerSuccess = (response: CustomerDetailsParams) => ({
	type: REMOVE_CUSTOMER_SUCCESS,
	payload: response
});
export const deleteCustomerFailure = (error: any) => ({
	type: REMOVE_CUSTOMER_FAILURE,
	payload: error
});
