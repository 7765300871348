import { API } from 'aws-amplify'
import { ApiHeaders, API_NAME } from '../API/variable'

//get static content data
export const GetStaticContentApi = (data: any) => {
  return API.get(API_NAME, `/static-content/get-content`, {
    headers: ApiHeaders,
    queryStringParameters: data,
  })
}

//products of sizing
export const GetSizingProductsApi = (props: any) =>
  API.get(API_NAME, `/static-content/all-size-list`, {
    headers: ApiHeaders,
    queryStringParameters: props,
  })

//fetch each product sizes
export const ProductSizesApi = (props: any) =>
  API.get(API_NAME, `/static-content/size-list`, {
    headers: ApiHeaders,
    queryStringParameters: props,
  })

//send product Name and values for product sizing
export const SizingFormApi = (props: any) =>
  API.post(API_NAME, `/static-content/add-size`, {
    headers: ApiHeaders,
    body: props,
  })
//{
//	let url = `https://q97zu13n7b.execute-api.ap-northeast-2.amazonaws.com/test/static-content/add-size`
//	//const { data } = await axios.post(url, {})
//	//return { data }
//}

//send shortCode and form value to server using short code and ant design form
export const postStaticContentFormApi = (props: any) =>
  API.post(API_NAME, `/static-content/add-content`, {
    headers: ApiHeaders,
    body: props,
  })

//swap Position api
export const postSwapPosApi = (props: any) =>
  API.post(API_NAME, '/static-content/swap-content-position', {
    headers: ApiHeaders,
    body: props,
  })
