import { CheckOutlined, CloseCircleFilled } from "@ant-design/icons";
import { Card, Col, Divider, Image, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Pagination } from "antd";
import { fetchOrders } from "../../API";
import { useQuery } from "react-query";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import ActionPopover from "./Popover";
import { LatidoAnimatedLogoLoader } from "../../Components/LatidoAnimateLogo/LatidoAnimateLogo";
import { formatDateToString } from "../../Utils";
import { CompletionProcess, TransferState } from "./OrdersEnum";
import transfer from "../../Assets/Icons/transfer.svg";

interface propsType {
  listType?: string;
  setIsDeliverModalOpen?: any;
  setIsTransferModalOpen?: any;
  setSelectedOrder?: any;
  search?: string;
  count?: any;
  pagination?: any;
  handlePaginationChange?: any;
  setIsReceiveFromStoreModalOpen?: any;
}

const StoreOrder = ({
  listType = "list",
  setIsDeliverModalOpen = () => {},
  setIsTransferModalOpen = () => {},
  setSelectedOrder = () => {},
  search = "",
  count,
  pagination,
  handlePaginationChange,
  setIsReceiveFromStoreModalOpen = () => {}
}: propsType) => {
  // const [pagination?.current || 1, setpagination?.current || 1] = useState(1);
  const [limit, setLimit] = useState(10);
  const navigation = useNavigate();
  
  useEffect(() => {
    if (search.length >= 1) {
      handlePaginationChange(1, 10);
    }
  }, [search]);

  // fetching orders
  const { data, error, isFetching, refetch } = useQuery(
    ["storeOrdersList", pagination?.current || 1, limit, search],
    () => fetchOrders(pagination?.current || 1, limit, "storeOrder", search),
    {
      enabled: Boolean([pagination?.current || 1, limit, search]),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 5000,
    }
  );
  useEffect(() => {
    refetch();
  }, [count]);

  const handletoDetails = (id: string) => {
    navigation(`/store/order/${id}`);
  };
  return (
    <>
      {listType === "list" && (
        <Card className="customer-order">
          <Row gutter={[10, 10]} className="customer-order__title">
            <Col sm={3}>
              <h4> ORDER ID</h4>
            </Col>
            <Col sm={4}>
              <h4> PRODUCT</h4>
            </Col>
            <Col sm={5}>
              <h4> LEATHER PROFILE </h4>
            </Col>
            <Col sm={4}>
              <h4> Delivery Date </h4>
            </Col>
            <Col sm={3}>
              <h4> BASE SIZE</h4>
            </Col>
            <Col sm={4}>
              <h4> ORDER STATE</h4>
            </Col>
            <Col sm={1}></Col>
          </Row>
          <Divider />
          {!isFetching &&
            data?.data.map((order: any) => {
              return (
                <Row gutter={[10, 10]} className="customer-order__value" key={order?._id}>
                  <Col sm={3} className="with-transfer">
                    <h5 onClick={() => handletoDetails(order?._id)}>
                      {order?.order_no}
                    </h5>
                      {order?.transfer_state ? (
                      <div>
                        <Tooltip
                          title={
                            order?.transfer_state === TransferState.CUSTOMER_STORE
                              ? "Customer to Store"
                              : order?.transfer_state === TransferState.CONSUMER_STORE ? "Consumer to Store" : ""
                          }
                        >
                          {order?.transfer_state && (
                            <img src={transfer} width={"16px"} alt="transfer" />
                          )}
                        </Tooltip>
                      </div>
                    ) : null}
                  </Col>
                  <Col sm={4}>
                    <h5
                      // onClick={() => handletoDetails(order?._id)}
                      onClick={() => navigation(`/product/${order?.product?._id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      {order?.product?.name}
                    </h5>
                  </Col>
                  <Col sm={5}>
                    <h5> {order?.product_specification?.leather_id?.item_name} </h5>
                  </Col>
                  <Col sm={4}>
                    <h5> {formatDateToString(order?.delivery_date)} </h5>
                  </Col>
                  <Col sm={3}>
                    <h5> {order?.product_specification?.size} </h5>
                  </Col>
                  <Col sm={4}>
                    <Tooltip title={order.order_manage_process}>
                      <h5
                        className={
                          order?.completion_process.toLowerCase() === CompletionProcess.DELIVERED ? "complete" : "inProgress"
                        }
                      >
                        {order?.completion_process.slice(0, 1).toUpperCase() +
                          order?.completion_process.slice(1)}
                      </h5>
                    </Tooltip>
                  </Col>
                  <Col sm={1}>
                    <ActionPopover
                      setIsDeliverModalOpen={setIsDeliverModalOpen}
                      setIsTransferModalOpen={setIsTransferModalOpen}
                      setIsReceiveFromStoreModalOpen={setIsReceiveFromStoreModalOpen}
                      order={order}
                      setSelectedOrder={setSelectedOrder}
                      orderType="store"
                    />
                  </Col>
                </Row>
              );
            })}

          {!isFetching && data?.data.length > 0 && (
            <Row className="customer-order__pagination">
              <Pagination
                total={data?.total_data}
                current={+pagination?.current || 1}
                defaultPageSize={limit}
                onChange={(e) => {
                  handlePaginationChange(e, data?.total_data)
                }}
                showSizeChanger={false}
              />
            </Row>
          )}
          {isFetching && (
            <Row justify="center" style={{ width: "100%" }}>
              {/* <Spin size='large' /> */}
              <LatidoAnimatedLogoLoader />
            </Row>
          )}
          {!isFetching && data?.data.length === 0 && (
            <>
              <Row justify="center" className="customer-order__pagination">
                <Image src={require("../../Assets/Icons/folderIcon.png")} preview={false} />
              </Row>
              <Row justify="center" className="customer-order__pagination">
                <h3>No Orders Available</h3>
              </Row>
            </>
          )}
        </Card>
      )}
      {listType === "grid" && (
        <>
          <div className="grid">
            {!isFetching &&
              data?.data.map((order: any) => {
                return (
                  <Card
                    key={order?._id}
                    className="product-card white-border"
                    onClick={() => handletoDetails(order?._id)}
                  >
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className={
                          order?.completion_process && (order.completion_process.toLowerCase() === CompletionProcess.DELIVERED) ? "tick-icon" : ""
                        }
                      >
                        {/* <CloseCircleFilled style={{ color: "#eb5757", fontSize: 20 }} /> */}
                        {
                          order?.completion_process && (order.completion_process.toLowerCase() === CompletionProcess.DELIVERED)  ? (
                          <CheckOutlined />
                        ) : (
                          <CloseCircleFilled style={{ color: "#eb5757", fontSize: 20 }} />
                        )}
                      </div>
                      <p>{Moment(order?.delivery_date).format("DD MMM YYYY")}</p>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px 0",
                      }}
                    >
                      <Image
                        src={order?.product?.primary_image}
                        alt={order?.product?.name}
                        className="image"
                        preview={false}
                      />
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h5>{order?.order_no}</h5>
                      <h4>{order?.user_id?.name}</h4>
                    </Row>
                  </Card>
                );
              })}
          </div>
          {!isFetching && data?.data.length > 0 && (
            <Row className="customer-order__pagination">
              <Pagination
                total={data?.total_data}
                current={+pagination?.current || 1}
                defaultPageSize={limit}
                onChange={(e) => {
                  handlePaginationChange(e, data?.total_data)
                }}
                showSizeChanger={false}
              />
            </Row>
          )}
          {isFetching && (
            <Row justify="center" style={{ width: "100%" }}>
              {/* <Spin size='large' /> */}
              <LatidoAnimatedLogoLoader />
            </Row>
          )}
          {!isFetching && data?.data.length === 0 && (
            <>
              <Row justify="center" className="customer-order__pagination">
                <Image src={require("../../Assets/Icons/folderIcon.png")} preview={false} />
              </Row>
              <Row justify="center" className="customer-order__pagination">
                <h3>No Orders Available</h3>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default StoreOrder;
