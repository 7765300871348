import React from 'react'
import { Route, Routes } from 'react-router'
import AuthLaylout from '../Layouts/AuthLaylout'
import Login from '../Views/Auth/Login'

export const AuthRoute = (props: any) => {
  return (
    <AuthLaylout>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route element={<Login />} />
      </Routes>
    </AuthLaylout>
  )
}

export default AuthRoute
