import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VendorForm } from '../../Components/VendorForm'
import { CombineReducerType } from '../../Redux/Reducers/rootReducers'
import { createVendor } from '../../Redux/Vendor/VendorActions'

const initialState = {
  vendor_id: '',
  vendor_name: '',
  supplies_type: [''],
  location: '',
  phone_no: '',
  email: '',
  icon: '',
  description: '',
  contact_person: {
    contact_name: '',
    phone_no: '',
    email: '',
    location: '',
  },
}
export const AddVendor = () => {
  const dispatch = useDispatch()
  const { creating } = useSelector(
    (state: CombineReducerType) => state?.VendorReducer
  )

  const handleFormSubmit = (formState: any) => {
    const data = { ...formState }
    dispatch(createVendor(data))
  }

  return (
    <>
      <VendorForm
        initialState={initialState}
        handleFormSubmit={handleFormSubmit}
      />
    </>
  )
}
