import React from "react";
import ImageGallerySwiper from "../../ImageGallerySwiper/ImageGallerySwiper";
import SecondaryImagesUpload from "./SecondaryImagesUpload";
import { Divider } from "antd";

const SecondaryImageUploadContainer = ({
  images,
  details,
  updating,
  onFinish,
  noEdit = false,
}: any) => {
  return (
    <div>
      <div style={{ margin: "30px auto 30px auto", textAlign: "center" }}>
        <ImageGallerySwiper images={images} />
        <Divider />
        {!noEdit && (
          <SecondaryImagesUpload
            details={details}
            updating={updating}
            images={images}
            onFinish={onFinish}
          />
        )}
      </div>
    </div>
  );
};

export default SecondaryImageUploadContainer;
