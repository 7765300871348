import {
  FETCH_DASHBOARD,
  FETCH_DASHBOARD_FAILURE,
  FETCH_DASHBOARD_SUCCESS,
} from './DashboardTypes'



//fetch Dashboard
export const fetchDashboard = () => ({
  type: FETCH_DASHBOARD,
})

export const fetchDashboardSuccess = (response: any) => ({
  type: FETCH_DASHBOARD_SUCCESS,
  payload: response,
})

export const fetchDashboardFailure = (error: any) => ({
  type: FETCH_DASHBOARD_FAILURE,
  payload: error,
})
