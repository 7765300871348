import { Store, applyMiddleware, compose, createStore } from 'redux'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import rootReducers from './Reducers/rootReducers'
import rootSagas from './Saga/rootSaga'

export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware]

let store

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose
  }
}

export const configureStore = (): Store => {
  const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : compose

  store = createStore(
    rootReducers(history),
    compose(
      applyMiddleware(...middleware),
      process.env.NODE_ENV === 'development' ? reduxDevTools : compose
    )
  )

  sagaMiddleware.run(rootSagas)

  return store
}

export default store
