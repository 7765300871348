import { ItemAvailability } from "./enums";

const itemAvailabilityData = {
  [ItemAvailability.AVAILABLE]: {
    text: "In Stock",
    value: "in_stock",
    color: "green",
  },
  [ItemAvailability.NOT_AVAILABLE]: {
    text: "Out of Stock",
    value: "out_of_stock",
    color: "red",
  },
  [ItemAvailability.LIMITED]: {
    text: "Limited Stock",
    value: "limited_stock",
    color: "orange",
  },
};

const categoryUnits = [
  { label: "Box", value: "box" },
  { label: "Litre", value: "litre" },
  { label: "Piece", value: "piece" },
  { label: "Kilogram", value: "kilogram" },
  { label: "Meter", value: "meter" },
];

export { itemAvailabilityData, categoryUnits };
