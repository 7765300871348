import { Reducer } from "react";
import {
  CreateProductCategoryParams,
  CREATE_PRODUCT_CATEGORY,
  CREATE_PRODUCT_CATEGORY_FAILURE,
  CREATE_PRODUCT_CATEGORY_SUCCESS,
  FETCH_PRODUCT_CATEGORYS,
  FETCH_PRODUCT_CATEGORYS_FAILURE,
  FETCH_PRODUCT_CATEGORYS_SUCCESS,
  FETCH_PRODUCT_CATEGORY,
  FETCH_PRODUCT_CATEGORY_FAILURE,
  FETCH_PRODUCT_CATEGORY_SUCCESS,
  REMOVE_PRODUCT_CATEGORY,
  REMOVE_PRODUCT_CATEGORY_FAILURE,
  REMOVE_PRODUCT_CATEGORY_SUCCESS,
  UPDATE_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY_FAILURE,
  UPDATE_PRODUCT_CATEGORY_SUCCESS,
  ProductCategoryDetailsParams,
} from "./ProductCategoryTypes";

const INIT_STATE = {
  list: [],
  error: "",
  detailsError: "",
  details: {},
  loading: false,
  loaded: false,
  detailing: false,
  detailed: false,
  creating: false,
  created: false,
  deleting: false,
  deleted: false,
  updating: false,
  updated: false,
  pageNumber: 0,
  pageSize: 0,
  totalPages: 1,
  totalRecords: 8,
};

export const ProductCategoryReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
  switch (action.type) {
    //fetch ProductCategorys
    case FETCH_PRODUCT_CATEGORYS:
      return { ...state, loaded: false, loading: true };
    case FETCH_PRODUCT_CATEGORYS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.payload.data || action.payload,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.total_data,
      };
    case FETCH_PRODUCT_CATEGORYS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    //fetch ProductCategory Details
    case FETCH_PRODUCT_CATEGORY:
      return { ...state, detailed: false, detailing: true };
    case FETCH_PRODUCT_CATEGORY_SUCCESS:
      return { ...state, detailing: false, detailed: false, details: action.payload || false };
    case FETCH_PRODUCT_CATEGORY_FAILURE:
      return { ...state, detailing: false, detailsError: action.payload };

    //create ProductCategory
    case CREATE_PRODUCT_CATEGORY:
      return { ...state, created: false, creating: true, error: false };
    case CREATE_PRODUCT_CATEGORY_SUCCESS:
      state.list.push(action.payload);
      return { ...state, creating: false, created: true };
    case CREATE_PRODUCT_CATEGORY_FAILURE:
      return { ...state, creating: false, error: action.payload.error };

    //update ProductCategory
    case UPDATE_PRODUCT_CATEGORY:
      return { ...state, updating: true, updated: false, error: false };
    case UPDATE_PRODUCT_CATEGORY_SUCCESS:
      const tempList = state.list.filter(
        (d: CreateProductCategoryParams) => d._id !== action.payload._id
      );
      tempList.push(action.payload);
      return { ...state, updated: true, updating: false, list: tempList };
    case UPDATE_PRODUCT_CATEGORY_FAILURE:
      return { ...state, updating: false, error: action.payload.error };

    //remove ProductCategory
    case REMOVE_PRODUCT_CATEGORY:
      return { ...state, deleted: false, deleting: true };
    case REMOVE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleted: true,
        list: state.list.filter((d: ProductCategoryDetailsParams) => d.id !== action.payload.id),
      };
    case REMOVE_PRODUCT_CATEGORY_FAILURE:
      return { ...state, deleting: false };
    default:
      return { ...state };
  }
};
