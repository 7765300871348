import { Button, Col, message, Row, Spin, Typography, Upload } from 'antd'
import { useState } from 'react'
import { ReactComponent as UploadIcon } from '../../../../../Assets/Icons/UploadFile.svg'
import axios from 'axios'
import './GridBuilder.scss'
import { getMediaUrl } from '../../../../../API/MediaApi'
export const GridBuilder = (props: any) => {
  const { upload } = props
  const [urls, setUrls] = useState<any>({})
  const mainData = props?.data

  const [loading, setLoading] = useState(false)

  const beforeUpload = (file: any, id: any) => {
    setLoading(true)
    getMediaUrl({ filePath: 'blogImages', fileType: file.type })
      .then((data: any) => {
        uploadFile({ file: file, urls: data }, id)
        return true
      })
      .catch((error: any) => {
        setLoading(false)

        return false
      })

    return true
  }

  const uploadFile = async (data: any, id: any) => {
    await axios
      .put(data?.urls?.data?.signedUrlPut, data?.file)
      .then((d: any) => {
        handleReplaceData(data?.urls?.data?.filePathURL, id)
      })
    setLoading(false)
  }

  const handleReplaceData = (data: any, index: number) => {
    let newData = mainData.filter((d: any) => d.id === index)[0]
    newData.url = urls?.filePathURL
    let newArray = mainData
    newArray.splice(newArray.indexOf(newData), 1, { ...newData, url: data })
    props.onChange(newArray)
  }
  const Column = (props: any) => {
    const { data } = props
    const myData = mainData.filter((d: any) => d.parentId === data.id)
    return (
      <Col lg={data.size}>
        {myData.length > 1 ? (
          <MyRow data={data} />
        ) : (
          <>
            {!upload ? (
              <div
                style={{
                  width: '100%',
                  height: 'calc(100% - 6px)',
                  minHeight: 40,
                  margin: '3px 0px',
                  background: '#E3EDF7',
                }}
              >
                <div
                  style={{
                    background: '#636770',
                    width: '100%',
                    height: '100%',
                    minHeight: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                ></div>
              </div>
            ) : (
              <>
                <Upload
                  accept='image/png, image/jpeg'
                  beforeUpload={(e) => beforeUpload(e, data?.id)}
                  className='upload--field'
                  showUploadList={false}
                >
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      overflow: 'hidden',
                      height: 'calc(100% - 6px)',
                      minHeight: 40,
                      margin: '3px 0px',
                      background: '#E3EDF7',
                    }}
                  >
                    <div
                      style={{
                        background: '#636770',
                        width: '100%',
                        height: '100%',
                        minHeight: 300,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {loading && (
                        <div
                          style={{
                            backgroundColor: '#ffffff82',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Spin />
                        </div>
                      )}
                      <UploadIcon width={30} />
                      <img
                        style={{
                          position: 'absolute',
                          minWidth: '100%',
                          minHeight: '100%',
                          width: '100%',
                        }}
                        src={data?.url}
                      />
                    </div>
                  </div>
                </Upload>
                {/* <Button onClick={()=> handleUpload()} style={{position: 'absolute', zIndex: 2}} type="primary">UPLOAD</Button> */}
              </>
            )}
          </>
        )}
      </Col>
    )
  }

  const MyRow = (props: any) => {
    const { data } = props
    const myData = mainData.filter((d: any) => d.parentId === data.id)
    return (
      <Row align='stretch' gutter={[6, 0]} style={{ position: 'relative' }}>
        {/* <PlusCircleOutlined onClick={() => addColumn(data.id)} style={{ position: 'absolute', top: 0, zIndex: 2 }} /> */}
        {myData &&
          myData.length > 0 &&
          myData.map((columns: any, i: number) => {
            return <Column key={i} data={columns} />
          })}
      </Row>
    )
  }
  return (
    <Row align='middle' style={{ height: '100%' }}>
      <Col lg={24}>
        {mainData &&
          mainData
            .filter((d: any) => !d.hasOwnProperty('parentId'))
            .map((rows: any, i: number) => {
              return <MyRow key={i} data={rows} />
            })}
      </Col>
    </Row>
  )
}

export default GridBuilder
