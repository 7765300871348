/** @format */

import { DeleteOutlined, EditOutlined, EyeFilled } from '@ant-design/icons'
import { Space } from 'antd'
import NeuMorph from '../../../../Components/Admin/NeuMorph/NeuMorph'
import React from 'react'

interface ActionListProps {
  onDetail?: () => void
  onEdit?: () => void
  onDelete?: () => void
}

const ActionList: React.FC<ActionListProps> = ({
  onDetail = () => {},
  onEdit = () => {},
  onDelete = () => {},
}) => {
  return (
    <NeuMorph>
      <Space
        className='content'
        style={{ padding: 10, alignItems: 'center', display: 'flex' }}
      >
        <EyeFilled onClick={() => onDetail()} style={{ fontSize: 18 }} />
        <EditOutlined onClick={() => onEdit()} style={{ fontSize: 18 }} />
        <DeleteOutlined onClick={() => onDelete()} style={{ fontSize: 18 }} />
      </Space>
    </NeuMorph>
  )
}

export default ActionList
