import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  Typography,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import MyContent from "../../../../Components/Admin/Content/Content";
import PageLoading from "../../../../Components/Admin/PageLoading/PageLoading";
import CommonS3UploadFiles from "../../../../Components/Admin/S3UploadFiles/CommonS3UploadFiles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { fetchProductCategorys } from "../../../../Redux/ProductCategoryRedux/ProductCategoryActions";
import {
  createProduct,
  fetchProduct,
  updateProduct,
} from "../../../../Redux/ProductRedux/ProductActions";
import { MediaUrl } from "../../../../Utils/mediaUrl";
import { slugify } from "../../../../Utils/slugify";
import "./ProductForm.scss";
import ProductDetailsForm from "./ProductDetailsForm";
import { Product } from "../../../../Types/product";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import SecondaryImageUploadContainer from "../../../../Components/SecondaryImages/SecondaryImagesUpload/SecondaryImageUploadContainer";
import ProductBlogAssociation from "../../../../Components/v2/ProductBlogAssociation/ProductBlogAssociation";

const { TabPane } = Tabs;

interface Price {
  currency: string;
  value: number;
}

interface Size {
  size: number;
  price: Price[];
}

interface ProductSpecification {
  secondary_image: any[];
  leather_id: string;
  default_hardware: string;
  default_lining: string;
  size_range: Size[];
}

interface Answer {
  question: string;
  answer: string[];
}

interface PatternPackage {
  [size: string]: string;
}

interface Payload {
  duplicate: boolean;
  product_specification: ProductSpecification[];
  find_my_jacket: Answer[];
  primary_image: string;
  name: string;
  slug: string;
  category: string;
  gender: string;
  level: number;
  tags: string[];
  hardware: string[];
  lining: string[];
  pollyfill: boolean;
  rib: boolean;
  status: string;
  description: string;
  pattern_package: PatternPackage;
  _id: string;
  pricing?: string;
  package_weight: string;
}

export const ProductForm = (props: any) => {
  const dispatch = useDispatch();
  const product = useSelector((state: any) => state.ProductReducer);
  const category = useSelector((state: any) => state.ProductCategoryReducer);
  const {
    creating,
    updating,
    updated,
    created,
    details,
    detailing,
  }: {
    detailing: boolean;
    details: Product;
    updating: boolean;
    creating: boolean;
    created: boolean;
    updated: boolean;
  } = product;

  const { update } = props;

  const { id } = useParams<any>();

  const [form] = useForm();
  const [specificationForm] = useForm();

  const history = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const onBackHandler = () => {
    if (id !== "add") {
      history(`/product/${id}`);
    } else {
      history(`/product`);
    }
  };

  const onFinish = (values: any, blogUpdate: boolean = false) => {
    values.find_my_jacket = [
      {
        question: "Lifestyle",
        answer: ["Adventure", "Corporate", "Academic"],
      },
      {
        question: "Purpose",
        answer: ["Day to day casual", "Party", "Biking Meetings"],
      },
      {
        question: "Design",
        answer: ["Trending", "New Arrivals", "Traditional", "Seasonal"],
      },
    ];

    if (update) {
      const data: Payload = {
        ...values,
        ...form.getFieldsValue(),
        _id: details?._id,
        slug: details?.slug,
        associated_blog: blogUpdate
          ? values?.associated_blog
          : details?.associated_blog
          ? details?.associated_blog
          : undefined,
      };
      let isDuplicateFound = false;
      data &&
        data?.product_specification?.forEach((productSpecs: ProductSpecification) => {
          const currentSizeList: number[] = [];
          return productSpecs?.size_range?.forEach((sizeRange: Size) => {
            if (sizeRange?.size && currentSizeList?.includes(sizeRange?.size)) {
              isDuplicateFound = true;
              message.error("Duplicate size found. Remove duplicate sizes and try again");
            } else {
              currentSizeList.push(sizeRange?.size);
            }
          });
        });
      if (!isDuplicateFound) {
        dispatch(
          updateProduct({
            ...values,
            ...form.getFieldsValue(),
            _id: details?._id,
            slug: details?.slug,
            associated_blog: blogUpdate
              ? values?.associated_blog
              : details?.associated_blog
              ? details?.associated_blog
              : undefined,
            pricing: values?.pricing ? values?.pricing : "N/A",
          })
        );
      }
    } else {
      dispatch(createProduct({ ...values, pricing: values?.pricing ? values?.pricing : "N/A" }));
    }
  };

  useEffect(() => {
    if (details) {
      form.setFieldsValue({
        ...details,
        category: details?.category?._id,
      });
    } else {
      form.resetFields();
    }
  }, [details]);

  const [forceStopLoading, setForceStopLoading] = useState(false);

  useEffect(() => {
    if (updated || created) {
      setForceStopLoading(true);
      dispatch(fetchProduct({ id: id }));
    }
  }, [updated, created]);

  useEffect(() => {
    if (!product?.detailed && id && id !== "add") {
      dispatch(fetchProduct({ id: id }));
    } else {
      form.resetFields();
    }
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(fetchProductCategorys({ limit: 0, page: 0, hierarchy: true }));
  }, [0, dispatch]);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const colorSelection = ["Silver", "Black", "Golden Antique"];
  const filteredColorSection = colorSelection.filter((o: string) => !selectedItems.includes(o));

  const [activePane, setActivePane] = useState("general-details");

  const activeKey: any = searchParams.get("tab");
  useEffect(() => {
    setActivePane(activeKey || "general-details");
  }, [activeKey]);
  // console.log(activeKey, searchParams);

  const [subCategories, setSubCategories] = useState([]);

  const getAllSubCategories = () => {
    let temporary: any = [];
    category?.list?.map((category: any) => {
      temporary = [...temporary, ...(category?.sub_category ? category?.sub_category : [])];
    });

    setSubCategories(temporary);
  };

  useEffect(() => {
    getAllSubCategories();
  }, [category]);

  const patternPackagesSize = [
    "30",
    "32",
    "34",
    "36",
    "38",
    "40",
    "42",
    "44",
    "46",
    "48",
    "50",
    "52",
    "54",
    "56",
    "58",
    "60",
  ];

  return (
    <PageLoading loading={!forceStopLoading && detailing}>
      <MyContent className="product__form">
        <Space align="center" size="large">
          <Typography.Title level={2}>{update ? details?.name : "ADD PRODUCT"}</Typography.Title>
        </Space>
        <Divider />
        <Row gutter={[60, 60]} align="top">
          <Col lg={7}>
            <Form form={form}>
              <Form.Item
                // hidden
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
                name="primary_image"
              ></Form.Item>
              <CommonS3UploadFiles
                type="product"
                module="product"
                url={update ? details?.primary_image : ""}
                style={{ width: "100%", minHeight: 400 }}
                onUpload={(e) => form.setFieldsValue({ primary_image: MediaUrl(e) })}
              />
            </Form>
          </Col>
          <Col lg={17}>
            <Typography.Title level={4}>ADD DETAILS</Typography.Title>

            <Tabs activeKey={activePane} onChange={(e) => setActivePane(e)}>
              <TabPane forceRender tab="Add Details" key="general-details">
                <Form
                  layout="vertical"
                  form={form}
                  name="basic"
                  scrollToFirstError={{ behavior: "smooth", block: "center" }}
                  onFinish={onFinish}
                  className="style--kbbs"
                >
                  <Card>
                    <Row gutter={[20, 0]}>
                      <Col lg={12}>
                        <Form.Item
                          label="PRODUCT NAME"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Product name is required!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) => {
                              form.setFieldsValue({
                                slug: slugify(e.target?.value),
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item
                          label="SLUG"
                          name="slug"
                          rules={[
                            {
                              required: true,
                              message: "Product slug is required!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item
                          label="CATEGORY"
                          name="category"
                          rules={[
                            {
                              required: true,
                              message: "Category is required!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            filterOption={(inputValue, category: any) => {
                              if (category) {
                                return (
                                  category?.children
                                    // .join("")
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase())
                                );
                              }
                            }}
                          >
                            {subCategories?.map((category: any) => {
                              return (
                                <Select.Option value={category?._id} key={category?._id}>
                                  {category?.title}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item
                          label="Gender"
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: "Gender is required!",
                            },
                          ]}
                        >
                          <Select>
                            {["male", "female", "unisex"]?.map((category: any, index: number) => {
                              return (
                                <Select.Option value={category} key={index}>
                                  {category}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item
                          label="Ranking (Level)"
                          name="level"
                          rules={[
                            {
                              required: true,
                              message: "Ranking (Level) is required!",
                            },
                          ]}
                        >
                          <Select>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((category: any) => {
                              return (
                                <Select.Option value={category} key={category}>
                                  {category}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item
                          label="TAGS"
                          name="tags"
                          rules={[
                            {
                              required: false,
                              message: "Tags is required!",
                            },
                          ]}
                        >
                          <Select mode="tags">
                            {/* {category?.list?.map((category: any) => {
															return <Select.Option value={category?._id}>{category?.title}</Select.Option>
														})} */}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item
                          label="HARDWARE"
                          name="hardware"
                          rules={[
                            {
                              required: true,
                              message: "Hardware is required!",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            value={selectedItems}
                            onChange={setSelectedItems}
                            options={filteredColorSection.map((category: any) => ({
                              value: category,
                              label: category,
                            }))}
                          />
                          {/* {colorSelection?.map((category: any) => {
                              return <Select.Option value={category}>{category}</Select.Option>;
                            })}
                          </Select> */}
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item
                          label="LINING"
                          name="lining"
                          rules={[
                            {
                              required: true,
                              message: "Lining is required!",
                            },
                          ]}
                        >
                          <Select mode="tags">
                            {colorSelection?.map((category: any, index: number) => {
                              return (
                                <Select.Option value={category} key={index}>
                                  {category}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item label="BASE PRICE" name="pricing">
                          <Input
                            onChange={(e) => {
                              form.setFieldsValue({
                                pricing: e.target?.value?.toString(),
                              });
                            }}
                            defaultValue="N/A"
                          />
                        </Form.Item>
                      </Col>

                      <Col lg={6}>
                        <Form.Item label="POLYFILL" name="pollyfill" valuePropName="checked">
                          <Switch />
                        </Form.Item>
                      </Col>
                      <Col lg={6}>
                        <Form.Item label="RIB" name="rib" valuePropName="checked">
                          <Switch defaultChecked={false} />
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item
                          label="DESCRIPTION"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: "Description is required!",
                            },
                          ]}
                        >
                          <TextArea maxLength={500} showCount />
                        </Form.Item>
                      </Col>

                      <Col lg={6}>
                        <Form.Item
                          label="Status"
                          name="status"
                          rules={[
                            {
                              required: true,
                              message: "Status is required",
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value="published">Published</Radio>
                            <Radio value="draft">Draft</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>

                      {/* <Col lg={6}>
                        <Form.Item
                          label="Avg. weight"
                          name="package_weight"
                          rules={[
                            {
                              required: true,
                              message: "Avg. weight is required",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) => {
                              form.setFieldsValue({
                                package_weight: e.target?.value?.toString(),
                              });
                            }}
                            // defaultValue="1"
                            addonAfter="kg."
                          />
                        </Form.Item>
                      </Col> */}

                      <Row>
                        <Col span={24}>
                          <Divider />
                          <Typography.Text strong>PATTERN PACKAGES</Typography.Text>
                          <Divider />
                        </Col>
                        <Col lg={20}>
                          <Row gutter={[30, 0]}>
                            {patternPackagesSize?.map((pps) => {
                              return (
                                <Col lg={6}>
                                  <Form.Item label={pps} name={["pattern_package", pps]}>
                                    <Input />
                                  </Form.Item>
                                </Col>
                              );
                            })}
                          </Row>
                        </Col>
                      </Row>

                      <Col lg={24}>
                        <Form.Item shouldUpdate>
                          <Button
                            loading={creating || updating}
                            type="primary"
                            // size="large"
                            shape="round"
                            htmlType="submit"
                          >
                            {update ? "UPDATE" : "ADD"}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Form>
              </TabPane>
              <TabPane
                forceRender
                disabled={id === "add"}
                // destroyInactiveTabPane
                key="leather-details"
                tab="Add Leather Profile"
              >
                <ProductDetailsForm
                  productId={details._id}
                  data={id !== "add" ? details?.product_specification : undefined}
                  hardware={details?.hardware || ["red", "black"]}
                  lining={details?.lining || ["red", "black"]}
                  update={update}
                  updating={updating}
                  creating={creating}
                  onSubmit={onFinish}
                />
              </TabPane>
              <TabPane
                forceRender
                disabled={id === "add"}
                key="secondary_images"
                tab="Secondary Images"
              >
                <SecondaryImageUploadContainer
                  images={details?.secondary_image || []}
                  details={details}
                  updating={updating}
                  update={update}
                  creating={creating}
                  onFinish={onFinish}
                />
              </TabPane>
              <Tabs.TabPane key="blog" tab="Blog" disabled={id === "add"}>
                <ProductBlogAssociation
                  details={details}
                  updating={updating}
                  images={details?.secondary_image}
                  onFinish={onFinish}
                  noEdit={true}
                />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </MyContent>
    </PageLoading>
  );
};

export default ProductForm;
