/** @format */

import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import { PlusOutlined, UserAddOutlined } from "@ant-design/icons";
import GridContainer from "../Components/GridContainer/GridContainer";
import { useForm } from "antd/lib/form/Form";
import "./AddBlog.scss";
import { createBlog, fetchBlog, updateBlog } from "../../../../Redux/BlogRedux/BlogActions";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { useNavigate, useParams } from "react-router";
import { getBlogContent } from "../../../../Utils/GetBlogContents";
import BlogBlock from "../Components/BlogBlock/BlogBlock";
import UploadFiles from "../../../../Components/Admin/UploadFiles/UploadFiles";
import { PageWrapper } from "Components/Admin/PageWrapper/PageWrapper";
import CommonS3UploadFiles from "Components/Admin/S3UploadFiles/CommonS3UploadFiles";
import MediaUrl from "Utils/mediaUrl";
const { Option } = Select;

export interface ImageLayoutProps {
  id: number;
  isRow: boolean;
  parentId?: number;
  contentId?: number;
  size?: number;
  contentType?: string;
  url?: any;
}

interface BlockListProps {
  type: "image" | "text" | "audio";
  content: any;
  layout?: ImageLayoutProps[];
}

export const BlogForm = (props: any) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const history = useNavigate();

  const blogs = useSelector((state: any) => state.BlogReducer);

  const { slug } = useParams<any>();
  const { details, creating, updating, detailing } = blogs;

  const defaultLayout: ImageLayoutProps[] = [
    {
      id: 1,
      isRow: true,
    },
    {
      id: 3,
      parentId: 1,
      isRow: false,
      contentId: 1,
      size: 24,
      url: false,
    },
    {
      id: 2,
      isRow: true,
    },
    {
      id: 4,
      parentId: 2,
      isRow: false,
      contentId: 2,
      size: 12,
      contentType: "image",
      url: false,
    },
    {
      id: 5,
      parentId: 2,
      isRow: false,
      contentId: 3,
      size: 12,
      contentType: "image",
      url: false,
    },
    {
      id: 6,
      isRow: true,
    },
    {
      id: 7,
      parentId: 6,
      isRow: false,
      size: 18,
    },
    {
      id: 8,
      parentId: 7,
      isRow: false,
      contentId: 4,
      size: 12,
      contentType: "image",
      url: false,
    },
    {
      id: 9,
      parentId: 6,
      isRow: false,
      contentId: 4,
      size: 6,
      contentType: "image",
      url: false,
    },
  ];

  const listItems: BlockListProps[] = [
    {
      type: "text",
      content: "",
    },
  ];

  const [blogsBlock, setBlogsBlock] = useState<BlockListProps[]>(listItems);
  const [imageLayout, setImageLayout] = useState<number>(-1);

  const getContentType = (value: any) => {
    if (Array.isArray(value)) {
      return "image";
    } else if (typeof value === "object") {
      return "audio";
    } else {
      return "text";
    }
  };
  useEffect(() => {
    let editableData: any[] = [];
    details?.content?.map((d: any) => {
      editableData = editableData.concat([{ type: getContentType(d), content: d }]);
    });
    setBlogsBlock(editableData);
    form.setFieldsValue(details);
    setPrimaryMedia(details?.primaryMedia);
  }, [slug, details]);

  const changeType = (data: any) => {
    const { dIndex, type } = data;
    let tempData = blogsBlock;
    if (type === "image") {
      tempData[dIndex] = {
        type: "image",
        layout: defaultLayout,
        content: defaultLayout,
      };
    } else {
      tempData[dIndex] = {
        type: type,
        content: "",
      };
    }
    setBlogsBlock([]);
    setTimeout(() => {
      setBlogsBlock(tempData);
    }, 1);

    //shoul;d be removed once the state update bug fixes
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    setBlogsBlock(arrayMove(blogsBlock, oldIndex, newIndex));
  };
  const handleBlogAdd = (value: any) => {
    const finalData = {
      ...value,
      // shortCode: value?.title?.split(" ").join("-"),
      content: getBlogContent(blogsBlock),
    };
    if (slug === "add") {
      if (finalData.content && finalData?.content.length !== 0) {
        if (
          finalData?.content?.some((item: any) => {
            return item === "";
          })
        ) {
          message.error("Content Cannot Be Empty!!!");
        } else {
          dispatch(createBlog(finalData));
        }
      } else {
        message.error("Content is required");
      }
    } else {
      dispatch(updateBlog({ ...finalData, blogId: details?._id }));
      history(`/blogs`);
    }
  };

  const addBlogBlock = () => {
    // setBlogsBlock(blogsBlock.push({ type: 'text', content: '' }))
    setBlogsBlock(blogsBlock.concat({ type: "text", content: "" }));
  };

  const changeImageLayout = (value: any) => {
    let tempBlock = blogsBlock[imageLayout];
    tempBlock.content = value;
    blogsBlock.splice(imageLayout, 1, tempBlock);
    setImageLayout(-1);
    // setBlogsBlock(finalBlocks)
  };

  const setContent = (params: any) => {
    const { content, index } = params;
    let tempBlock = blogsBlock[index];
    tempBlock.content = content;

    let finalBlocks = blogsBlock;
    finalBlocks.splice(index, 1, tempBlock);
    setBlogsBlock(finalBlocks);
    // setBlogsBlock([])
    // setTimeout(() => {
    // 	setBlogsBlock(finalBlocks);
    // }, 1)
  };

  const setImages = (params: any) => {
    const { content, index } = params;
    let tempBlock = blogsBlock[index];
    tempBlock.content = content;

    let finalBlocks = blogsBlock;
    finalBlocks.splice(index, 1, tempBlock);
    // setBlogsBlock(finalBlocks)
    setBlogsBlock([]);
    setTimeout(() => {
      setBlogsBlock(finalBlocks);
    }, 1);
  };

  const deleteBlogBlock = (id: any) => {
    let finalBlocks = blogsBlock;
    finalBlocks.splice(id, 1);
    // setBlogsBlock(finalBlocks)
    setBlogsBlock([]);
    setTimeout(() => {
      setBlogsBlock(finalBlocks);
    }, 1);
  };

  const SortableItem = SortableElement(({ value, dataIndex }: any) => (
    <div>
      <BlogBlock
        removeBlock={(id: any) => deleteBlogBlock(dataIndex)}
        imageChanges={(e: any) => setImages({ index: dataIndex, content: e })}
        contentChange={(e: any) => setContent({ index: dataIndex, content: e })}
        viewLayout={() => setImageLayout(dataIndex)}
        value={value}
        index={dataIndex}
        dataIndex={dataIndex}
        onChangeType={(data: any) => changeType(data)}
      />
    </div>
  ));

  const SortableList = SortableContainer(({ items }: any) => {
    return (
      <Row gutter={[20, 20]}>
        {items &&
          items.map((value: any, index: any) => (
            <Col lg={24} key={`item-${index}`}>
              {/* <SortableItem
                pressDelay={2000}
                key={`item-${index}`}
                index={index}
                dataIndex={index}
                value={value}
              /> */}
              {SortableItem}
            </Col>
          ))}
      </Row>
    );
  });

  useEffect(() => {
    if (slug === "add") {
      form.resetFields();
      setBlogsBlock(listItems);
      setPrimaryMedia("");
    }
    if (slug !== "add") {
      dispatch(fetchBlog({ blogId: slug }));
    }
  }, [slug]);

  const [primaryMedia, setPrimaryMedia] = useState(details?.primaryMedia);

  return (
    <Content>
      <Typography.Title>{slug && slug !== "add" ? "UPDATE" : "ADD"}</Typography.Title>
      <PageWrapper data={blogs}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleBlogAdd}
          onReset={() => setBlogsBlock([])}
        >
          <Row>
            <Col lg={17}>
              <Card>
                <Typography.Title level={3}>CONTENT</Typography.Title>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[{ required: true, message: "Title is Required" }]}
                >
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({ shortCode: e?.target?.value?.split(" ").join("-") });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="shortCode"
                  label="Short Code"
                  rules={[{ required: true, message: "Slug is Required" }]}
                >
                  <Input />
                </Form.Item>
                <Row gutter={[20, 20]}>
                  <Col lg={24}>
                    {/* <SortableList
                    pressDelay={200}
                    items={blogsBlock}
                    // updateBeforeSortStart={(e: any) => setGrabbed(true)}
                    onSortEnd={onSortEnd}
                  /> */}
                    {/* {SortableItem} */}
                    {blogsBlock?.map((bb: any, dataIndex: number) => {
                      return (
                        <div style={{ margin: "20px 0" }}>
                          <BlogBlock
                            removeBlock={(id: any) => deleteBlogBlock(dataIndex)}
                            imageChanges={(e: any) => setImages({ index: dataIndex, content: e })}
                            contentChange={(e: any) => setContent({ index: dataIndex, content: e })}
                            viewLayout={() => setImageLayout(dataIndex)}
                            value={bb}
                            index={dataIndex}
                            dataIndex={dataIndex}
                            onChangeType={(data: any) => changeType(data)}
                          />
                        </div>
                      );
                    })}
                  </Col>
                </Row>

                <Row className="add-blog-footer-items">
                  <Col lg={24} style={{ marginTop: 30 }}>
                    <Button className="add-block-button" onClick={() => addBlogBlock()}>
                      <Avatar
                        size={18}
                        className="neumorphify"
                        icon={<PlusOutlined style={{ color: "black" }} />}
                      />
                      Add block
                    </Button>
                  </Col>
                  <Col lg={24} style={{ marginTop: 50 }}>
                    <Row>
                      <Col lg={12}>
                        <Form.Item
                          name="status"
                          label="status"
                          rules={[{ required: true, message: "Status is required." }]}
                        >
                          <Radio.Group>
                            <Radio value={true}>Published</Radio>
                            <Radio value={false}>Draft</Radio>
                          </Radio.Group>
                        </Form.Item>
                        {/* <Form.Item
												name='isFeatured'
												label='Set as Featured Blog'
												rules={[{ required: true, message: "Status is required." }]}
												initialValue={false}
											>
												<Switch />
											</Form.Item> */}
                        <Form.Item>
                          <Button
                            loading={creating || updating}
                            shape="round"
                            htmlType="submit"
                            type="primary"
                          >
                            {slug !== "add" ? "Update" : "Save"}
                          </Button>
                          <Button shape="round" htmlType="reset" style={{ marginLeft: 15 }}>
                            Cancel
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item name="primaryMedia" label="Featured Image">
                          <CommonS3UploadFiles
                            type="ratio"
                            ratio={[16, 9]}
                            module="blog"
                            url={primaryMedia ? MediaUrl(primaryMedia) : false}
                            onUpload={(url: any) => {
                              console.log(url);
                              setPrimaryMedia(url);
                              form.setFieldsValue({ primaryMedia: MediaUrl(url) });
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col offset={1} lg={6}>
              <Card>
                <Form.Item
                  label="Author"
                  name="author"
                  rules={[{ required: true, message: "Author is Required!" }]}
                >
                  <Select defaultValue="">
                    <Option value="Latido Admin">
                      <Avatar size={26} style={{ background: "gray" }} icon={<UserAddOutlined />} />{" "}
                      Latido Admin
                    </Option>
                  </Select>
                </Form.Item>
                <Row align="bottom">
                  <Col lg={24}>
                    <Form.Item label="Description" name="shortDescription">
                      <TextArea />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label="Type" name="type">
                  <Select>
                    <Option value="image">Image</Option>
                    <Option value="video">Video</Option>
                    <Option value="podcast">Podcast</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Tags" name="tags">
                  <Select mode="tags" />
                </Form.Item>
              </Card>
              {/* <Card style={{ marginTop: 20 }}>
              <PreviewStaticContent />
            </Card> */}
            </Col>
          </Row>
        </Form>
      </PageWrapper>
      <Modal
        width="80vw"
        visible={imageLayout !== -1}
        onCancel={() => setImageLayout(-1)}
        footer={false}
      >
        <Typography.Title className="product-name">LAYOUT</Typography.Title>
        <GridContainer onLayoutChange={(e: any) => changeImageLayout(e)} />
      </Modal>
    </Content>
  );
};

export default BlogForm;

//https://clauderic.github.io/react-sortable-hoc/#/basic-configuration/drag-handle?_k=2m5u57
