import {
  CREATE_INVENTORY_CATEGORIES,
  CREATE_INVENTORY_CATEGORIES_FAILURE,
  CREATE_INVENTORY_CATEGORIES_SUCCESS,
  FETCH_INVENTORY_CATEGORIES,
  FETCH_INVENTORY_CATEGORIES_FAILURE,
  FETCH_INVENTORY_CATEGORIES_SUCCESS,
} from './InventoryCategoryTypes'

//fetch Inventory Category
export const fetchInventoryCategory = (q:string) => ({
  type: FETCH_INVENTORY_CATEGORIES,
  payload: q
})

export const fetchInventoryCategorySuccess = (response: any) => ({
  type: FETCH_INVENTORY_CATEGORIES_SUCCESS,
  payload: response,
})

export const fetchInventoryCategoryFailure = (error: any) => ({
  type: FETCH_INVENTORY_CATEGORIES_FAILURE,
  payload: error,
})


//create Inventory Category
export const createInventoryCategory = (data: any) => ({
  type: CREATE_INVENTORY_CATEGORIES,
  payload: data
})

export const createInventoryCategorySuccess = (response:any) => ({
  type: CREATE_INVENTORY_CATEGORIES_SUCCESS,
  payload: response
})

export const createInventoryCategoryFailure = (error: any) => ({
  type: CREATE_INVENTORY_CATEGORIES_FAILURE,
  payload: error
})