import API from '@aws-amplify/api'

import axios from 'axios'
import { ApiHeaders, API_NAME } from '../API/variable'

//api for newsletter table content
export const newsletterTableContentApi = (title: any) => {
  let url = `http://localhost:8080/grid?title=${title}`
  return axios({
    method: 'GET',
    url: url,
  })
}

export const PostNewsletterForm = (props: any) =>
  API.post(API_NAME, 'URL', {
    headers: ApiHeaders,
    queryStringParamaters: props,
  })

export const GetPromoCode = (props: any) =>
  API.post(API_NAME, 'URL', {
    headers: ApiHeaders,
    queryStringParamaters: props,
  })

export const PostPromoCodeForm = (props: any) =>
  API.post(API_NAME, 'URL', {
    headers: ApiHeaders,
    queryStringParamaters: props,
  })
