import { notification } from "antd";
import { AxiosResponse } from "axios";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  createBlogFailure,
  createBlogSuccess,
  deleteBlogFailure,
  deleteBlogSuccess,
  fetchBlogFailure,
  fetchBlogsFailure,
  fetchBlogsSuccess,
  fetchBlogSuccess,
  updateBlogFailure,
  updateBlogSuccess,
} from "./BlogActions";
import { cutBlog, makeBlog, modifyBlog, takeBlog, takeBlogs } from "./BlogApis";
import {
  CreateBlogParams,
  FETCH_BLOG_DETAILS,
  FETCH_BLOGS,
  UPDATE_BLOG,
  BlogDetailsParams,
  BlogFetchParams,
  CREATE_BLOG,
  REMOVE_BLOG,
} from "./BlogTypes";
import { history } from "../AppStore";
import { parseError } from "Utils/ParseError";

//get blogs
const getBlogsAsync = async (data: BlogFetchParams) => await takeBlogs(data);

function* getBlogs(params: any) {
  const { payload } = params;
  try {
    const blogs: AxiosResponse = yield call(getBlogsAsync, payload);
    if (blogs) {
      yield put(fetchBlogsSuccess(blogs?.data));
    } else {
      yield put(fetchBlogsFailure("Could not get list of blogs"));
      notification.error({
        message: "Could not get list of blogs",
        placement: "bottom",
      });
    }
  } catch (error) {
    yield put(fetchBlogsFailure("Network Error while getting Blogs"));
    notification.error({
      message: "Network Error while getting Blogs",
      placement: "bottom",
    });
  }
}

function* watchGetBlogs() {
  yield takeLatest(FETCH_BLOGS, getBlogs);
}

//get blog details
const getBlogAsync = async (data: BlogDetailsParams) => await takeBlog(data);

function* getBlog(params: any) {
  const { payload } = params;
  try {
    const blogs: AxiosResponse = yield call(getBlogAsync, payload);
    if (blogs) {
      yield put(fetchBlogSuccess(blogs.data));
    } else {
      yield put(fetchBlogFailure("Could not get list of blogs"));
      notification.error({
        message: "Could not get list of blogs",
        placement: "bottom",
      });
    }
  } catch (error) {
    yield put(fetchBlogFailure("Network Error while getting Blogs"));
    notification.error({
      message: "Network Error while getting Blogs",
      placement: "bottom",
    });
  }
}

function* watchGetBlog() {
  yield takeLatest(FETCH_BLOG_DETAILS, getBlog);
}

//create Blog
const generateBlogAsync = async (data: CreateBlogParams) => await makeBlog(data);

function* generateBlog(params: any) {
  const { payload } = params;
  try {
    const blogs: AxiosResponse = yield call(generateBlogAsync, payload);
    if (blogs) {
      yield put(createBlogSuccess(blogs.data));
      notification.success({
        message: "Blog created successfully",
        placement: "bottom",
      });
      history.push("/blogs");
      window.location.href = "/blogs";
    } else {
      const message = "Could not create Blog";
      yield put(createBlogFailure(message));
      notification.error({
        message: message,
        placement: "bottom",
      });
    }
  } catch (error) {
    const message = parseError(error)?.message || "Network Error while creating Blog";
    yield put(createBlogFailure(message));
    notification.error({
      message: message,
      placement: "bottom",
    });
  }
}

function* watchGenerateBlog() {
  yield takeLatest(CREATE_BLOG, generateBlog);
}

//update Blog
const amendBlogAsync = async (data: CreateBlogParams) => await modifyBlog(data);

function* amendBlog(params: any) {
  const { payload } = params;
  try {
    const blogs: AxiosResponse = yield call(amendBlogAsync, payload);
    if (blogs) {
      yield put(updateBlogSuccess(blogs.data));
      notification.success({
        message: "Blog updated successfully",
        placement: "bottom",
      });
      // history.push(`/blogs/details/${payload?.blogId}`);
      // window.location.href = `/blogs/details/${payload?.blogId}`;
    } else {
      const message = "Could not Update Blog";
      yield put(updateBlogFailure(message));
      notification.error({
        message: message,
        placement: "bottom",
      });
    }
  } catch (error) {
    const message = "Network Error while updating Blog";
    yield put(updateBlogFailure(message));
    notification.error({
      message: message,
      placement: "bottom",
    });
  }
}

function* watchAmendBlog() {
  yield takeLatest(UPDATE_BLOG, amendBlog);
}

//Delete Blog
const removeBlogAsync = async (data: BlogDetailsParams) => await cutBlog(data);

function* removeBlog(params: any) {
  const { payload } = params;
  try {
    const blogs: AxiosResponse = yield call(removeBlogAsync, payload);
    if (blogs) {
      yield put(deleteBlogSuccess({ id: payload.blogId }));
      notification.success({
        message: "Blog deleted successfully",
        placement: "bottom",
      });
    } else {
      const message = "Could not delete Blog";
      yield put(deleteBlogFailure(message));
      notification.error({
        message: message,
        placement: "bottom",
      });
    }
  } catch (error) {
    const message = "Network Error while deleting Blog";
    yield put(deleteBlogFailure(message));
    notification.error({
      message: message,
      placement: "bottom",
    });
  }
}

function* watchRemoveBlog() {
  yield takeLatest(REMOVE_BLOG, removeBlog);
}

export default function* BlogSagas() {
  yield all([
    fork(watchGetBlogs),
    fork(watchGetBlog),
    fork(watchGenerateBlog),
    fork(watchAmendBlog),
    fork(watchRemoveBlog),
  ]);
}
