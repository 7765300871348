import React from "react";
import { Row, Col, Typography, Card, Avatar, Divider, Descriptions, Space, Tabs } from "antd";
import {
  EditOutlined,
  FacebookFilled,
  InstagramFilled,
  TwitterCircleFilled,
} from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import dayjs from "dayjs";
import UserTimeline from "../../Components/Admin/UserTimeline/UserTimeline";
import UserMeasurement from "../../Components/Admin/UserMeasurement/UserMeasurement";
import UsersOrders from "../../Components/Admin/UsersOrders/UsersOrders";
import { useNavigate, useParams } from "react-router";
import { useQuery } from "react-query";
import { getCustomer, getCustomerMeasurements } from "../../API/CustomerApi";
const { TabPane } = Tabs;

const ProfileSummary = (props: any) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data } = useQuery(["getCustomerDetail", { user_id: id }], getCustomer);

  const customer = data?.data[0];

  return (
    <Card>
      <Row justify="end">
        <Col onClick={() => navigate(`/customer-management/edit/${id}`)}>
          <Text>
            Edit Profile{" "}
            <Avatar
              size={24}
              style={{ background: "black" }}
              icon={<EditOutlined style={{ fontSize: 14 }} />}
            />
          </Text>
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ width: "100%", flexDirection: "column" }}>
        <Avatar size={140} style={{ marginBottom: 20 }} src={customer?.profile_image} />
        <Title level={4}>{customer?.name || "N/A"}</Title>
      </Row>
      <Divider />
      <Descriptions
        title="Contact Information"
        layout="vertical"
        column={2}
        labelStyle={{ color: "#636770" }}
        contentStyle={{ color: "black" }}
      >
        <Descriptions.Item label="Email Address">{customer?.email || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Phone Number">{customer?.phone || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Address">{customer?.address || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Joined Date">
          {customer?.createdAt ? dayjs(customer?.createdAt).format("LL") : "N/A"}
        </Descriptions.Item>
        {/* <Descriptions.Item label="Connect with">
          <Space size="small">
            <FacebookFilled /> <InstagramFilled /> <TwitterCircleFilled />
          </Space>
        </Descriptions.Item> */}
      </Descriptions>
    </Card>
  );
};

function CustomerDetail(props: any) {
  const { id } = useParams();
  const { data } = useQuery(["getCustomerMeasurements", { id: id }], getCustomerMeasurements);
  return (
    <Row gutter={[20, 20]}>
      <Col lg={8}>
        <Typography.Title>Profile</Typography.Title>
        <ProfileSummary />
      </Col>
      <Col lg={14}>
        <Tabs defaultActiveKey="1" destroyInactiveTabPane>
          {/* <TabPane tab="Timeline" key="timeline" forceRender>
            <UserTimeline />
          </TabPane> */}
          <TabPane tab="Measurements" key="measurements">
            <UserMeasurement data={data?.data[0]} />
          </TabPane>
          <TabPane tab="Order" key="order">
            <UsersOrders />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
}

export default CustomerDetail;
