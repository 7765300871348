import { Route, Routes } from "react-router";
import withPermission from "../Utils/checkPermission";
import Customer from "../Views/Customer/Customer";
import CustomerProfile from "../Views/Customer/CustomerProfile";
import UserDetailsEdit from "../Views/Customer/CustomerDetailEdit";

export const CustomerRoute = () => {
  return (
    <Routes>
      <Route path={`/:id`} element={<CustomerProfile />} />
      <Route path={"/edit/:id"} element={<UserDetailsEdit />} />
      <Route path={`/`} element={<Customer />} />
    </Routes>
  );
};

export default withPermission(CustomerRoute, "customer");
