import { Reducer } from 'react'
import {
  FETCH_DAMAGE_ITEMS,
  FETCH_DAMAGE_ITEMS_FAILURE,
  FETCH_DAMAGE_ITEMS_SUCCESS,
  FETCH_REPORT,
  FETCH_REPORTS,
  FETCH_REPORTS_FAILURE,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORT_FAILURE,
  FETCH_REPORT_SUCCESS,

} from './ReportTypes'


const INIT_STATE = {
  reports: [],
  damageItems: {},
  report: {},
  error: '',
  loading: false,
  creating: false,
  deleting: false
}

export const ReportingReducer: Reducer<any, any> = (
  state = INIT_STATE,
  action
) => {
  switch (action.type) {

  //fetch  Reports
  case FETCH_REPORTS:
    return { ...state, loading: true }
  case FETCH_REPORTS_SUCCESS:
    return { ...state, loading: false, reports: action.payload }
  case FETCH_REPORTS_FAILURE:
    return { ...state, loading: false, error: action.payload }

  //fetch  Report
  case FETCH_REPORT:
    return { ...state, loading: true }
  case FETCH_REPORT_SUCCESS:
    return { ...state, loading: false, report : action.payload }
  case FETCH_REPORT_FAILURE:
    return { ...state, loading: false, error: action.payload }

  //fetch  DamagesItems
  case FETCH_DAMAGE_ITEMS:
    return { ...state, loading: true }
  case FETCH_DAMAGE_ITEMS_SUCCESS:
    return { ...state, loading: false, damageItems: action.payload }
  case FETCH_DAMAGE_ITEMS_FAILURE:
    return { ...state, loading: false, error: action.payload }
  default:
    return state

  }
}
