// import { ApiHeaders, ApiName, API_NAME } from 'Api/Api';
import { API } from "aws-amplify";
import { ApiHeaders, API_NAME } from "../../API/variable";
import { CreateBlogParams, BlogDetailsParams, BlogFetchParams } from "./BlogTypes";

export const getExchangeRefunds = (params: any) =>
  API.get(API_NAME, "/exchange-refunds", {
    headers: ApiHeaders,
    queryStringParameters: params,
  });

//get Blog
export const takeBlogs = (data: BlogFetchParams) => {
  return API.get(API_NAME, "/static-content/get-blog-list", {
    headers: ApiHeaders,
    queryStringParameters: data,
  });
};

//get Blog Details
export const takeBlog = (data: BlogDetailsParams) => {
  return API.get(API_NAME, "/static-content/get-blog", {
    headers: ApiHeaders,
    queryStringParameters: data,
  });
};

//create Blog Details
export const makeBlog = (data: CreateBlogParams) => {
  return API.post(API_NAME, "/static-content/add-blog", {
    headers: ApiHeaders,
    body: data,
  });
};

//modify Blog
export const modifyBlog = (data: any) => {
  return API.patch(API_NAME, "/static-content/update-blog", {
    headers: ApiHeaders,
    body: { _id: data?.blogId, ...data },
  });
};

//delete Blog
export const cutBlog = (data: BlogDetailsParams) => {
  return API.del(API_NAME, "/static-content/delete-blog", {
    headers: ApiHeaders,
    body: data,
  });
};

export const getBlogForDropdown = (params: any) =>
  API.get(API_NAME, "/static-content/get-blog-list-dropdown", {
    headers: ApiHeaders,
    queryStringParameters: params?.queryKey?.[1],
  });
