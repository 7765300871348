/** @format */

import { Button, Card, Image, Space, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useState } from 'react'
import img from '../../../Assets/MacBook Pro 16 2.png'
import PreviewDetailModal from '../PreviewDetailModal/PreviewDetailModal'
// import "./PreviewStaticContent.scss";

const PreviewStaticContent = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <Card>
      <Space direction='vertical' size={40}>
        <Typography.Title level={3}>Preview</Typography.Title>
        <Typography.Paragraph>
          See the preview of the product. In the same way, users will see a
          product in the market.
        </Typography.Paragraph>
        <Content style={{ display: 'flex', justifyContent: 'center' }}>
          <Image preview={false} src={img} alt='mcbookpro' />
        </Content>
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          Dekstop View v
        </Typography.Title>
        <Button
          onClick={() => setIsModalVisible(true)}
          style={{ width: '100%' }}
        >
          Preview Mobile
        </Button>
        <PreviewDetailModal
          isModalVisible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      </Space>
    </Card>
  )
}
export default PreviewStaticContent
