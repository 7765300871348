import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {
  GetPromoCode,
  newsletterTableContentApi,
  PostNewsletterForm,
  PostPromoCodeForm,
} from '../../Apis/gridApis'
import {
  GetNewsletterTableContentsFailed,
  GetNewsletterTableContentsSuccess,
  GetPromocodeFailure,
  GetPromocodeSuccess,
  PostNewsletterFormFailure,
  PostNewsletterFormSuccess,
  PostPromocodeFailure,
  PostPromocodeSuccess,
} from '../Actions/GridAction'
import { FETCH_NEWSLETTER_TABLE_CONTENT_REQUEST } from '../Constants/constants'

//fetch newsletter table contents
const takeNewsletterTableContent = async (data: any) =>
  await newsletterTableContentApi(data)

function* getNewsletterTableContent({ payload }: any) {
  const { data } = payload
  try {
    const NewsletterTableContent: AxiosResponse = yield call(
      takeNewsletterTableContent,
      data
    )
    if (NewsletterTableContent) {
      yield put(
        GetNewsletterTableContentsSuccess(
          NewsletterTableContent && NewsletterTableContent.data
        )
      )
    }
  } catch (error) {
    yield put(GetNewsletterTableContentsFailed(error))
  }
}

function* watchNewsletterTableContent() {
  yield takeEvery(
    FETCH_NEWSLETTER_TABLE_CONTENT_REQUEST,
    getNewsletterTableContent
  )
}
const takePostNewsletterForm = async (data: any) =>
  await PostNewsletterForm(data)

function* getPostNewsletterForm({ payload }: any) {
  const { data } = payload
  try {
    const PostNewsletterRes: AxiosResponse = yield call(
      takePostNewsletterForm,
      data
    )
    if (PostNewsletterRes) {
      yield put(
        PostNewsletterFormSuccess(PostNewsletterRes && PostNewsletterRes.data)
      )
    }
  } catch (error) {
    yield put(PostNewsletterFormFailure(error))
  }
}

function* watchPostNewsletterForm() {
  yield takeEvery(FETCH_NEWSLETTER_TABLE_CONTENT_REQUEST, getPostNewsletterForm)
}

const takeGetPromoCode = async (data: any) => await GetPromoCode(data)

function* getGetPromoCode({ payload }: any) {
  const { data } = payload
  try {
    const PostNewsletterRes: AxiosResponse = yield call(takeGetPromoCode, data)
    if (PostNewsletterRes) {
      yield put(
        GetPromocodeSuccess(PostNewsletterRes && PostNewsletterRes.data)
      )
    }
  } catch (error) {
    yield put(GetPromocodeFailure(error))
  }
}

function* watchGetpromoCode() {
  yield takeEvery(FETCH_NEWSLETTER_TABLE_CONTENT_REQUEST, getGetPromoCode)
}

const takePostPromoCode = async (data: any) => await PostPromoCodeForm(data)

function* getPostPromoCode({ payload }: any) {
  const { data } = payload
  try {
    const PostNewsletterRes: AxiosResponse = yield call(takePostPromoCode, data)
    if (PostNewsletterRes) {
      yield put(
        PostPromocodeSuccess(PostNewsletterRes && PostNewsletterRes.data)
      )
    }
  } catch (error) {
    yield put(PostPromocodeFailure(error))
  }
}

function* watchPostPromoCode() {
  yield takeEvery(FETCH_NEWSLETTER_TABLE_CONTENT_REQUEST, getPostPromoCode)
}

export default function* rootSaga() {
  yield all([
    fork(watchPostNewsletterForm),
    fork(watchNewsletterTableContent),
    fork(watchGetpromoCode),
    fork(watchPostPromoCode),
  ])
}
