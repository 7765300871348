/** @format */
import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { API, Auth } from "aws-amplify";
import { ApiHeaders, API_NAME } from "../../API/variable";
import { useDispatch } from "react-redux";
import { SET_CURRENT_USER } from "../../Redux/AuthRedux/AuthTypes";
import { FontSizeOutlined } from "@ant-design/icons";

export const Login = () => {
  const [stepPassword, setStepPassword] = useState(false);
  const navigate = useNavigate();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const onFinish = async (values: any) => {
    setLoading(true);

    try {
      const res = await Auth.signIn(values.email, values.password)
        .then(async () => {
          const user = await API.get(API_NAME, "/user/adminProfile", {
            headers: ApiHeaders,
          });
          dispatch({ type: SET_CURRENT_USER, payload: user?.data });
          navigate("/");
        })
        .catch((e) => {
          // message.error("Username or password incorrect");
          form.setFields([
            {
              name: "email",
              errors: ["Please check username"],
            },
            {
              name: "password",
              errors: ["Please check password"],
            },
          ]);
        });
    } catch (e: any) {
      setError(e.message);
    }

    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {};

  const handleCheckingAuth = async () => {
    const res = await Auth.currentAuthenticatedUser();
    if (res || localStorage.getItem("amplify-authenticator-authState") === "signedIn") {
      navigate("/");
    }
  };

  useEffect(() => {
    handleCheckingAuth();
  }, [Auth]);

  return (
    <Row justify="center" align="middle" style={{ minHeight: "100%" }}>
      <Col lg={12}>
        <div className="">
          <Typography.Title className="shadow-text section-title" style={{ marginBottom: "0" }}>
            LOG IN
            <Typography.Paragraph
              style={{
                fontWeight: "bold",
                fontSize: "30px",
              }}
            >
              BETA
            </Typography.Paragraph>
          </Typography.Title>
        </div>
        <Typography.Paragraph style={{ marginBottom: 50, fontSize: 24, fontWeight: 200 }}>
          to access PORTAL
        </Typography.Paragraph>

        <Form
          layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="neumorphic"
          form={form}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Password"
                name="password"
                // className="animate__animated animate__fadeIn"
                rules={[{ required: true, message: "Please input your password!" }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              // loading={Auth.logging}
              type="primary"
              htmlType="submit"
              block
              loading={loading}
              id="LoginButton"
            >
              LOG IN
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Login;
