import { Card, Col, Divider, Image, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { Tabs } from "antd";
import { EditOutlined } from "@ant-design/icons";
import UserInfo from "./UserInfo";
import Sizing from "./Sizing";
import Payments from "./Payments";
import Remarks from "./Remarks";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchOrder } from "../../API";
import { useSelector } from "react-redux";
import { permission } from "../../Layouts/ProtectedLayout";
import dayjs from "dayjs";
import ShippingDetails from "./ShippingDetails";
import { CompletionProcess } from "./OrdersEnum";

const OrderDetails = () => {
  const { TabPane } = Tabs;
  const [selectedTab, setSelectedTab] = useState("shipping-details");
  const { id }: any = useParams();
  const navigate = useNavigate();

  // fetching order details
  const { data, isFetching } = useQuery(["orderDetails", id], () => fetchOrder(id), {
    enabled: Boolean([id]),
    refetchOnWindowFocus: false,

    staleTime: 5000,
  });

  const handleEditClick = () => {
    if (data?.for_store) {
      navigate("/store/create/order/store", {
        state: data,
      });
    } else {
      navigate("/store/create/order/customer", {
        state: data,
      });
    }
  };
  const { user: currentUser } = useSelector((state: any) => state.AuthReducer);

  const [editEnabled, setEditEnabled] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setEditEnabled(currentUser?.role?.some((cu: any) => permission[cu].includes("editOrder")));
    }
  }, [currentUser]);
  return (
    <div className="order-details">
      {isFetching ? (
        <Row align="middle" justify="center" style={{ width: "100%" }}>
          <Spin />
        </Row>
      ) : (
        <>
          <Row style={{ width: "100%" }} className="order-details__header" gutter={20}>
            <Col sm={24} lg={8} xl={7}>
              <h2 className="page-title">ORDER DETAILS</h2>
            </Col>
            <Col sm={24} lg={16} xl={16}>
              <Row gutter={[20, 20]}>
                <Tabs
                  defaultActiveKey="shipping-details"
                  onChange={(key) => setSelectedTab(key)}
                  style={{
                    minWidth: "700px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <TabPane tab="SHIPPING DETAILS" key="shipping-details"></TabPane>
                  <TabPane tab="USER INFO" key="user-info"></TabPane>
                  <TabPane tab="SIZING" key="sizing"></TabPane>
                  <TabPane tab="PAYMENT" key="payment"></TabPane>
                  <TabPane tab="REMARKS" key="remarks"></TabPane>
                </Tabs>
              </Row>
            </Col>
          </Row>
          <Row className="order-details__container" gutter={[20, 20]}>
            <Col sm={24} lg={8} xl={7}>
              <Card className="order-details__details white-border">
                <Row style={{ display: "flex", justifyContent: "space-between" }}>
                  <Col>
                    <p>Order No.</p>
                    <h3>{data?.order_no}</h3>
                  </Col>
                  <Col>
                    {editEnabled && (
                      <div className="edit-icon" onClick={handleEditClick}>
                        <EditOutlined />
                      </div>
                    )}
                  </Col>
                </Row>

                <Row className="order-details__details__image">
                  <Image src={data?.product?.primary_image} alt={data?.product?.name} />
                </Row>
                <Row style={{ display: "flex", justifyContent: "space-between" }}>
                  <Col className="col">
                    <p>Product Name</p>
                    <h3>{data?.product?.name}</h3>
                  </Col>
                  <Col className="col">
                    <p>Leather Profile</p>
                    <h3>{data?.product_specification?.leather_id?.item_name}</h3>
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "20px 0",
                  }}
                >
                  <Col className="col">
                    <p>Hardware</p>
                    <h3>{data?.hardware}</h3>
                  </Col>
                  <Col className="col">
                    <p>Lining</p>
                    <h3>{data?.lining}</h3>
                  </Col>
                </Row>
                <Divider />
                <Row style={{ display: "flex", justifyContent: "space-between" }}>
                  <Col className="col">
                    <p>Polyfill</p>
                    <h3>{data?.pollyfill ? "Yes" : "No"}</h3>
                  </Col>
                  <Col className="col">
                    <p>Rib</p>
                    <h3>{data?.rib ? "Yes" : "No"}</h3>
                  </Col>
                  <Col>
                    <p>Size</p>
                    <h3>{data?.product_specification?.size}</h3>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col sm={24} lg={16} xl={17}>
              {selectedTab === "shipping-details" && (
                <ShippingDetails data={data?.shipping_details} />
              )}
              {selectedTab === "user-info" && <UserInfo data={data?.user_id} />}
              {selectedTab === "sizing" && <Sizing data={data?.sizing} />}
              {selectedTab === "payment" && <Payments data={data} delivered={data.completion_process === CompletionProcess.DELIVERED} />}
              {selectedTab === "remarks" && (
                <Remarks
                  data={
                    data?.special_request
                      ? data?.special_request +
                        " By: " +
                        data?.createdBy?.name +
                        " at " +
                        dayjs(data?.createdBy?.createdAt).format("MMM D | HH:mm")
                      : ""
                  }
                />
                // Swornim at 26th Aug | 13:08
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default OrderDetails;
