import { RiseOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Row, Spin, Typography } from "antd";

export const DashboardCard = ({ name, count, loading, cardIcon }: any) => {
  return (
    <Card style={{ borderRadius: 10 }}>
      <Row justify="space-between" align="middle" style={{ marginBottom: 40 }}>
        <Col>
          <Typography.Text>{name ?? ""}</Typography.Text>
          {loading ? (
            <Typography.Title level={1} style={{ margin: "10px 0px", color: "black" }}>
              <Spin />
            </Typography.Title>
          ) : (
            <Typography.Title level={1} style={{ margin: "10px 0px", color: "black" }}>
              {count ?? 0}
            </Typography.Title>
          )}
        </Col>
        <Col>
          <Avatar
            size={38}
            style={{
              background: "linear-gradient(313.32deg, #262626 8.79%, #707580 93.86%)",
              opacity: 0.8,
            }}
            icon={cardIcon}
          />
        </Col>
      </Row>
      {/* <Row>
        <Typography.Text style={{ color: "#39B54A" }}>
          <RiseOutlined /> 8.5%
        </Typography.Text>
        <Typography.Text style={{ marginLeft: 5 }}>Upto from yesterday</Typography.Text>
      </Row> */}
    </Card>
  );
};

export default DashboardCard;
