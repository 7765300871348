import ReactImageFallback from "react-image-fallback";
import SkeletonImage from "antd/lib/skeleton/Image";

export const ImageHolder = (props: any) => {
  const { fallbackImage } = props;
  return (
    <ReactImageFallback
      {...props}
      fallbackImage={
        fallbackImage ||
        "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
      }
    />
  );
};

export default ImageHolder;
