import { CloseOutlined } from '@ant-design/icons'
import { Button, Form, Image, Input, Modal, Row, Typography } from 'antd'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  visible?: boolean
  handleClose?: () => void
}

const SuccessModel: FC<Props> = ({
  visible = false,
  handleClose = () => {},
}) => {
  const navigate = useNavigate()
  return (
    <Modal
      centered
      visible={visible}
      onCancel={handleClose}
      footer={null}
      closable={false}
      maskStyle={{
        backdropFilter: 'blur(4px)',
        background: 'rgba(0,0,0,0.3)',
      }}
      destroyOnClose={true}
      width={'45%'}
      bodyStyle={{ padding: '40px' }}
      className='success-model'
      maskClosable={false}
    >
      <Row justify='center'>
        <Image
          src={require('../../Assets/Icons/success-file.png')}
          preview={false}
          style={{ width: 80 }}
        />
      </Row>
      <Row justify='center' style={{ marginTop: '30px' }}>
        <Typography.Title level={3} style={{ color: '#34C05B' }}>
          Order Succesfully Created
        </Typography.Title>
      </Row>
      <Row justify='center'>
        <p style={{ fontSize: 'clamp(14px, 0.9vw, 20px)', marginTop: -10 }}>
          Order information is sent to the email of the user.
        </p>
      </Row>

      <Row justify='center' style={{ marginTop: '30px' }} gutter={[40, 40]}>
        <Button onClick={() => navigate('/store')} className='cancel-btn btn'>
          Go To Order List
        </Button>
        <Button
          onClick={() => navigate('/store/create/order/option')}
          className='confirm-btn btn'
        >
          Create New Order
        </Button>
      </Row>
    </Modal>
  )
}

export default SuccessModel
