export const FETCH_MEMBERS = 'FETCH_MEMBERS';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_FAILURE = 'FETCH_MEMBERS_FAILURE';

export const FETCH_MEMBER_DETAILS = 'FETCH_MEMBER_DETAILS';
export const FETCH_MEMBER_DETAILS_SUCCESS = 'FETCH_MEMBER_DETAILS_SUCCESS';
export const FETCH_MEMBER_DETAILS_FAILURE = 'FETCH_MEMBER_DETAILS_FAILURE';

export const CREATE_MEMBER = 'CREATE_MEMBER';
export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_FAILURE = 'CREATE_MEMBER_FAILURE';

export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAILURE = 'UPDATE_MEMBER_FAILURE';

export const REMOVE_MEMBER = 'REMOVE_MEMBER';
export const REMOVE_MEMBER_SUCCESS = 'REMOVE_MEMBER_SUCCESS';
export const REMOVE_MEMBER_FAILURE = 'REMOVE_MEMBER_FAILURE';

export interface MemberFetchParams {
	limit?: number;
	page?: number;
}
export interface MemberDetailsParams {
	id?: number;
}
export interface CreateMemberParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
