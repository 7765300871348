export const FETCH_INVENTORY_CATEGORIES = 'FETCH_INVENTORY_CATEGORIES'
export const FETCH_INVENTORY_CATEGORIES_SUCCESS =
  'FETCH_INVENTORY_CATEGORIES_SUCCESS'
export const FETCH_INVENTORY_CATEGORIES_FAILURE =
  'FETCH_INVENTORY_CATEGORIES_FAILURE'

export const CREATE_INVENTORY_CATEGORIES = 'CREATE_INVENTORY_CATEGORIES'
export const CREATE_INVENTORY_CATEGORIES_SUCCESS =
  'CREATE_INVENTORY_CATEGORIES_SUCCESS'
export const CREATE_INVENTORY_CATEGORIES_FAILURE =
  'CREATE_INVENTORY_CATEGORIES_FAILURE'

export type MaterialType = {
  unit: string
  special_unit?: string
}
export type ItemsType = {
  _id: string
  item_name: string
  sku: string
  latido_code: string
  inventory_category: string
  brand: string
  color: string
  weight: string
  tags: Array<string>
  icon: string
  high_quantity: number, 
  least_quantity?: number,
  material_select?: MaterialSelectType 
}

export interface MaterialSelectType{
  total_quantity: number,
  total_special_quantity: number
}

export type InventoryCategoriesType = {
  _id: string
  inventory_category: string
  code: string
  icon: string
  material_select: MaterialType
  items: Array<ItemsType>
}

export type InventoryCategoriesInitialState = {
  inventoryCategories : Array<InventoryCategoriesType>
  error :string
  loading : boolean
  creating: boolean
  created?: boolean
}

export type InventoryCategoriesCreateResType = {
  _id: string
  inventory_category: string
  code: string
  icon: string
  material_select: MaterialType
  special_unit: boolean
  description: string
  createdAt: string
  updatedAt: string
}