import { Card, Col, Divider, Row, Typography } from 'antd'
import ImageHolder from '../ImageHolder/ImageHolder'
import React, { useState } from 'react'
// import { OutOFStockType } from '../Dashboard/DashboardTypes'
import MediaUrl from '../../Utils/mediaUrl'
import './DashboardList.scss'
import { OutOFStockType } from '../../Redux/Dashboard/DashboardTypes'
import { useNavigate } from 'react-router'

type propsType = {
  data?: any
  total?: number
  title?: string
}
const DashboardList = ({ data, total = 20, title = 'Title' }: propsType) => {
  const [shownAll, setShownAll] = useState(false)

  const navigate = useNavigate()

  const handleClick = (item: any) => {
    console.log({ item })

    item?.inventory_category &&
      navigate(`/inventory/item-details/${item?.id}`, {
        state: {
          inventory_category: item?.inventory_category,
          special_unit: item?.material_select?.total_special_quantity,
        },
      })
  }

  const toggleListNumber = () => {
    setShownAll(!shownAll)
  }
  return (
    <Card className='dashboardList'>
      <Row justify='space-between' className='dashboard-list'>
        <Col>
          <Typography.Title className='dashboard-list-title' level={5}>
            {title}
          </Typography.Title>
        </Col>
        <Col
          style={{
            color: '#27AE60',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
          }}
        >
          <Typography.Title className='dashboard-list-more' level={5}>
            Total: {total}
          </Typography.Title>
        </Col>
      </Row>
      <div className='listContainer'>
        <div className='innerList'>
          {data?.slice(0, shownAll ? data.length : 4)?.map((item: any) => {
            return (
              <div key={item?.id}>
                <Row gutter={[20, 20]} className='dashboardList-col'>
                  <Col>
                    <ImageHolder
                      src={MediaUrl(item?.image)}
                      style={{ width: 60, height: 60 }}
                      alt='placeholder'
                      className='dashboard-list-body-image'
                    />
                  </Col>
                  <Col
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography.Title
                      className='dashboardList-col__title'
                      level={5}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleClick(item)}
                    >
                      {item?.name}
                    </Typography.Title>
                    <p className='dashboardList-col__description'>
                      From 8th July, 2021
                    </p>
                  </Col>
                </Row>
                <Divider
                  style={{
                    width: '100%',
                    marginTop: 10,
                    boxShadow: '0px 1px 2px 1px white',
                  }}
                />
              </div>
            )
          })}
        </div>
      </div>
      {data?.length > 4 && (
        <div className='showmore' onClick={toggleListNumber}>
          <Typography.Link>
            {shownAll ? 'Show Less' : 'Show More'}
          </Typography.Link>
        </div>
      )}
    </Card>
  )
}

export default DashboardList
