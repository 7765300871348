import { API } from "aws-amplify";
import { ApiHeaders, API_NAME } from "./variable";

export const getCustomers = (params: any) => {
  console.log(params);
  return API.get(API_NAME, "/customer/list", {
    headers: ApiHeaders,
    queryStringParameters: params?.queryKey[1],
  });
};

export const getCustomer = (params: any) =>
  API.get(API_NAME, "/customer/details", {
    headers: ApiHeaders,
    queryStringParameters: params?.queryKey?.[1],
  });

export const getCustomerTimeLine = (params: any) => {
  return API.get(API_NAME, "/customer/time-line", {
    headers: ApiHeaders,
    queryStringParameters: params?.queryKey?.[1],
  });
};

export const getCustomerMeasurements = (params: any) =>
  API.get(API_NAME, "/customer_sizing", {
    headers: ApiHeaders,
    queryStringParameters: params?.queryKey?.[1],
  });

export const getCustomerOrder = (params: any) =>
  API.get(API_NAME, `/customer_order/${params?.queryKey?.[1].id}`, {
    headers: ApiHeaders,
    // queryStringParameters: params?.queryKey?.[1],
  });

export const deleteCustomer = (data: any) =>
  API.del(API_NAME, "/customer/details", {
    headers: ApiHeaders,
    body: data,
  });

export const updateCustomerDetails = (data: any) =>
  API.patch(API_NAME, "/customer/details", {
    headers: ApiHeaders,
    body: data,
  });

export const updateCustomerMeasurements = (data: any) =>
  API.patch(API_NAME, "/customer_sizing", {
    headers: ApiHeaders,
    body: data,
  });

export const resetPassword = (data: any) =>
  API.put(API_NAME, "/user/forgot-password", {
    headers: ApiHeaders,
    body: data,
  });
