import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { categoryUnits } from "../../../../Utils/consts";

import "./MaterialCategoryModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { CombineReducerType } from "../../../../Redux/Reducers/rootReducers";
import {
  createInventoryCategory,
  fetchInventoryCategory,
} from "../../../../Redux/InventoryCategory/InventoryCategoryActions";
// import CommonS3UploadFiles from "../../../S3UploadFiles/CommonS3UploadFiles";
import MediaUrl from "../../../../Utils/mediaUrl";
import { fetchInventoryItem } from "../../../../Redux/InventoryItem/InventoryItemActions";
import { takeInventoryCategoriy } from "../../../../Redux/InventoryCategory/InventoryCategoryApis";
import CommonS3UploadFiles from "../../../Admin/S3UploadFiles/CommonS3UploadFiles";
import { useForm } from "antd/lib/form/Form";

interface MaterialCategoryModalProps {
  visible: boolean;
  handleClose: () => void;
  id?: any;
}

type initialFormStateType = {
  name: string;
  code: string;
  unitOne: string;
  unitTwo: string;
  image: string;
  description: string;
  _id?: any;
};
const initialFormState = {
  name: "",
  code: "",
  unitOne: "",
  unitTwo: "",
  image: "",
  description: "",
};

export const MaterialCategoryModal: FC<MaterialCategoryModalProps> = ({
  visible,
  handleClose,
  id,
}) => {
  const [formState, setFormState] = useState<initialFormStateType>(initialFormState);
  const [isLether, setIsLether] = useState(false);
  const dispatch = useDispatch();
  const { creating, created } = useSelector(
    (state: CombineReducerType) => state?.InventoryCategoryReducer
  );

  useEffect(() => {
    if (isLether) {
      setFormState({
        ...formState,
        unitOne: "pc",
        unitTwo: "dcm",
      });
    } else {
      setFormState({
        ...formState,
        unitOne: "",
        unitTwo: "",
      });
    }
  }, [isLether]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleUnitOneSelect = (value: string) => {
    setFormState((prevState) => ({ ...prevState, unitOne: value }));
  };

  useEffect(() => {
    if (created) {
      setFormState(initialFormState);
      form.resetFields();
      handleClose();
    }
  }, [created]);

  const specialCategoryUnits = [{ label: "Decametre (DCM)", value: "dcm" }];

  const getInventory = async (id: any) => {
    await takeInventoryCategoriy(id)
      .then((data) => {
        console.log(data);
        setFormState({
          ...data,
          name: data?.inventory_category,
          image: MediaUrl(data?.icon),
        });

        setIsLether(data?.special_unit);
        form.setFieldsValue({
          description: data?.description,
          code: data?.code,
          name: data?.inventory_category,
          image: MediaUrl(data?.icon),
          unitOne: data?.material_select?.unit,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (id) {
      getInventory(id);
    } else {
      form.setFieldsValue({});
    }
  }, [id]);

  const handleSubmitForm = (values: any) => {
    console.log(values);
    const data: any = {
      special_unit: isLether,
      inventory_category: formState?.name,
      description: formState?.description,
      code: formState?.code,
      material_select: {
        unit: formState?.unitOne || values?.unitOne,
      },
      icon: formState?.image,
    };
    if (id) {
      data._id = id;
    }
    if (formState?.unitTwo?.length > 0) {
      data.material_select.special_unit = formState?.unitTwo;
    }
    dispatch(createInventoryCategory(data));
    created && form.setFieldsValue({});
  };

  const handleCheckBoxChange = (e: any) => {
    if (e?.target?.checked) {
      setIsLether(e?.target?.checked);
    } else {
      setIsLether(e?.target?.checked);
    }
  };

  const [form] = useForm();

  console.log(formState);
  return (
    <Modal
      centered
      visible={visible}
      onCancel={() => {
        setFormState(initialFormState);
        form.resetFields();
        setIsLether(false);
        handleClose();
      }}
      footer={null}
      closable={false}
      maskStyle={{ backdropFilter: "blur(4px)", background: "rgba(0,0,0,0.3)" }}
      destroyOnClose={true}
      width={"45%"}
      bodyStyle={{ padding: "5% 15%" }}
      className="add_category_modal"
    >
      <Typography.Title level={4} style={{ textAlign: "center" }}>
        Add Category
      </Typography.Title>
      <Divider />
      <Form form={form} layout="vertical" onFinish={handleSubmitForm}>
        <Row justify="center">
          <Col>
            <Form.Item
              name="image"
              label="Image"
              rules={[
                {
                  required: true,
                  message: "Image must be provided",
                },
              ]}
            >
              {/* {formState?.image?.length == 0 ? ( */}
              <CommonS3UploadFiles
                module="item"
                onUpload={(e) => {
                  form.setFieldsValue({
                    image: e,
                  });
                  setFormState({
                    ...formState,
                    image: e,
                  });
                }}
                prefix="inventory/category"
                type="ratio"
                ratio={[8, 8]}
                title="Drag or drop the images / icons"
                url={formState?.image ? MediaUrl(formState?.image) : false}
              />
              {/* // ) : (
              //   <Image
              //     preview={false}
              //     src={MediaUrl(formState?.image)}
              //     style={{ maxWidth: 400, maxHeight: 400, objectFit: "cover" }}
              //     alt="category"
              //     className="add_category_image"
              //   />
              // )} */}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Name"
          rules={[
            {
              required: true,
              message: "Required",
            },
            {
              pattern: /^\s*\S.*$/,
              message: "Only whitespace are not allwed",
            },
          ]}
          name="name"
        >
          <Input
            type="text"
            name="name"
            value={formState.name}
            onChange={handleChange}
            required={true}
            autoFocus={true}
          />
        </Form.Item>
        <Form.Item
          label="Code"
          name="code"
          rules={[
            {
              required: true,
              message: "Required",
            },
            {
              pattern: /^\s*\S.*$/,
              message: "Only whitespace are not allwed",
            },
          ]}
        >
          <Input
            type="text"
            name="code"
            value={formState.code}
            onChange={handleChange}
            required={true}
          />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Required",
            },
            {
              pattern: /^\s*\S.*$/,
              message: "Only whitespace are not allwed",
            },
          ]}
        >
          <Input
            type="text"
            name="description"
            value={formState?.description}
            onChange={handleChange}
            required={true}
          />
        </Form.Item>

        <Checkbox
          checked={isLether}
          onChange={(e: any) => handleCheckBoxChange(e)}
          style={{ marginBottom: 30 }}
        >
          {" "}
          Leather{" "}
        </Checkbox>
        {!isLether && (
          <Form.Item
            name="unitOne"
            label={`Select Unit`}
            className="add_category_form_select_wrapper"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <Select
              options={categoryUnits?.sort((a, b) => a?.label.localeCompare(b.label))}
              allowClear={true}
              onChange={handleUnitOneSelect}
              placeholder="Select unit one"
              aria-required={true}
              className="add_category_form_select"
            />
          </Form.Item>
        )}

        <Row justify="end">
          <Col>
            <Space>
              <Button
                onClick={() => {
                  handleClose();
                  form.resetFields();
                  setFormState(initialFormState);
                }}
                type="text"
              >
                Cancel
              </Button>
              <Button
                // size='large'
                type="primary"
                disabled={creating}
                loading={creating}
                htmlType="submit"
              >
                Save
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
