import {
  CarOutlined,
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
  TagsOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Col, Descriptions, Divider, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import ImageHolder from "../../Components/ImageHolder/ImageHolder";
import { LatidoAnimatedLogoLoader } from "../../Components/LatidoAnimateLogo/LatidoAnimateLogo";
import { CombineReducerType } from "../../Redux/Reducers/rootReducers";
import { fetchVendor } from "../../Redux/Vendor/VendorActions";
import MediaUrl from "../../Utils/mediaUrl";
import Table from "./Table";

export const VendorDetails = () => {
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const { vendor, loading } = useSelector((state: CombineReducerType) => state?.VendorReducer);
  const [companyDetails, setCompanyDetails] = useState<any>([]);
  const [contactPersonDetails, setContactPersonDetails] = useState<any>([]);
  const history = useNavigate();

  useEffect(() => {
    if (Object.keys(vendor).length > 1 && !loading) {
      const data = [
        {
          title: "Phone Number",
          icon: <PhoneOutlined />,
          value: vendor?.phone_no,
        },
        {
          title: "Location",
          icon: <EnvironmentOutlined />,
          value: vendor?.location,
        },
        {
          title: "Email",
          icon: <MailOutlined />,
          value: (
            <Typography.Link href={`mailto:${vendor?.email}`} target="_blank">
              {vendor?.email}
            </Typography.Link>
          ),
        },
        {
          title: "Supply",
          icon: <CarOutlined />,
          value: vendor?.supplies_type[0],
        },
        {
          title: "Tags",
          icon: <TagsOutlined />,
          value: vendor?.vendor_tags?.join(", "),
        },
      ];
      setCompanyDetails(data);
      const contactData = [
        {
          icon: <UserOutlined />,
          value: vendor?.contact_person?.contact_name,
          title: "Contact Name",
        },
        {
          icon: <MailOutlined />,
          value: vendor?.contact_person?.email,
          title: "E-mail",
        },
        {
          icon: <PhoneOutlined />,
          value: vendor?.contact_person?.phone_no,
          title: "Phone Number",
        },
        {
          icon: <EnvironmentOutlined />,
          value: vendor?.contact_person?.location,
          title: "Location",
        },
      ];
      setContactPersonDetails(contactData);
    }
  }, [vendor]);
  useEffect(() => {
    dispatch(fetchVendor(id));
  }, []);

  const tableTitle = ["", "Name", "Quantity", "Price", "Ordered Date", "Recived Date"];

  // useEffect(() => {

  // }, [vendor])
  return (
    <>
      {loading ? (
        <Row justify="center" align="middle" style={{ height: 100 }}>
          {/* <Spin /> */}
          <LatidoAnimatedLogoLoader />
        </Row>
      ) : (
        <>
          <Row style={{ margin: "30px 0" }} gutter={[20, 0]}>
            <Col span={15}>
              <h1 className="page-title">{vendor?.vendor_name}</h1>
              <h5
                style={{
                  // fontSize: 22,
                  color: "#828282",
                  wordSpacing: 5,
                }}
              >
                <Typography.Link onClick={() => history("/inventory/vendors")}>
                  {`Vendors >`}{" "}
                </Typography.Link>
                <Typography.Text type="secondary"> Vendor Detail</Typography.Text>
              </h5>
            </Col>
          </Row>
          <Row className="vendor-info">
            <Card style={{ width: "100%", padding: "30px 50px" }}>
              <Row>
                <Col md={24} lg={24} xl={6} sm={24} className="image-wrapper">
                  <ImageHolder
                    width={"100%"}
                    className="vendor-image"
                    src={MediaUrl(vendor?.icon)}
                    alt={vendor?.vendor_name}
                    preview={false}
                  />

                  <h3 className="vendor-id">
                    Vendor Id <span>{vendor?.vendor_id}</span>
                  </h3>
                </Col>

                <Col style={{ padding: "0px 50px" }} md={24} lg={24} xl={18} sm={24}>
                  <Row>
                    <h2 style={{ fontSize: 32, marginTop: -25, color: "black" }}>
                      {vendor?.vendor_name}
                    </h2>
                  </Row>
                  <Descriptions layout="vertical" labelStyle={{ color: "#2F80ED" }}>
                    {companyDetails?.map((data: any) => {
                      return (
                        <Descriptions.Item
                          label={
                            <Space>
                              {data?.icon}
                              <span>{data?.title}</span>
                            </Space>
                          }
                        >
                          {data?.value}
                        </Descriptions.Item>
                      );
                    })}
                  </Descriptions>
                  {/* <Row>
                    {companyDetails?.map((detail: any) => {
                      return (
                        <Col
                          md={12}
                          lg={8}
                          xl={8}
                          sm={24}
                          xs={24}
                          key={detail?.value}
                          className="details-card"
                        >
                          <h4 className="detail-title">
                            {detail?.icon}
                            <span>{detail?.title}</span>
                          </h4>
                          <h5 className="detail-title">{detail?.value}</h5>
                        </Col>
                      );
                    })}
                  </Row> */}
                </Col>
              </Row>
            </Card>
          </Row>
          <Row gutter={[40, 40]} style={{ marginTop: 40 }}>
            <Col md={24} lg={8} xl={8} sm={24} xs={24}>
              <Card style={{ width: "100%", padding: "30px 20px" }} className="contact-card">
                <h3 style={{ fontSize: 22 }}>Contact Person Details</h3>
                <Divider
                  style={{
                    width: "100%",
                    boxShadow: "0px 1px 2px 1px white",
                    marginTop: -5,
                  }}
                />
                {/* {contactPersonDetails?.map((detail: any) => {
                  return (
                    <Row key={detail?.value} className="details-card">
                      <h4 className="detail-title">
                        {detail?.icon}
                        <span>{detail?.title}</span>
                      </h4>
                      <h5 className="detail-title">{detail?.value}</h5>
                    </Row>
                  );
                })} */}

                <Descriptions column={1} layout="vertical" labelStyle={{ color: "#2F80ED" }}>
                  {contactPersonDetails?.map((data: any) => {
                    return (
                      <Descriptions.Item
                        label={
                          <Space>
                            {data?.icon}
                            <span>{data?.title}</span>
                          </Space>
                        }
                      >
                        {data?.value}
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
              </Card>
            </Col>
            <Col md={24} lg={16} xl={16} sm={24} xs={24}>
              <Card title="Purchase Record" style={{ width: "100%", padding: "30px 50px" }}>
                <Table />
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
