import { all, call, fork, put, takeEvery } from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'
import {
  addFeatureProductFailure,
  addFeatureProductSuccess,
  addPlacementFormFailure,
  addPlacementFormSuccess,
  GetFeatureProductFailed,
  GetFeatureProductSuccess,
} from '../Actions/FeatureProductAction'
import {
  ADD_FEATURE_PRODUCT_REQUEST,
  ADD_PLACEMENT_FORM_REQUEST,
  GET_FEATURE_PRODUCTS_REQUEST,
} from '../Constants/constants'
import {
  addFeatureProductApi,
  addPlacementFormApi,
  featureProductsListApi,
} from './../../Apis/FeatureProductApi'

//fetch feature product list
const takeFeatureProductList = async () => await featureProductsListApi()

function* getFeatureProduct() {
  try {
    const featureProduct: AxiosResponse = yield call(takeFeatureProductList)
    if (featureProduct) {
      yield put(GetFeatureProductSuccess(featureProduct && featureProduct.data))
    }
  } catch (error) {
    yield put(GetFeatureProductFailed(error))
  }
}

function* watchFeatureProduct() {
  yield takeEvery(GET_FEATURE_PRODUCTS_REQUEST, getFeatureProduct)
}

//add placement form
const takeAddPlacementForm = async (data: any) =>
  await addPlacementFormApi(data)
function* getAddPlacementForm({ payload }: any) {
  const { data }: { data: any } = payload
  try {
    const addPlacementForm: AxiosResponse = yield call(
      takeAddPlacementForm,
      data
    )
    if (addPlacementForm) {
      yield put(addPlacementFormSuccess({ addPlacementForm, data }))
    }
  } catch (error) {
    yield put(addPlacementFormFailure(error))
  }
}

function* watchAddPlacementForm() {
  yield takeEvery(ADD_PLACEMENT_FORM_REQUEST, getAddPlacementForm)
}
//add feature product
const takeAddFeatureProduct = async ({
  title,
  description,
  placement,
  status,
}: any) => addFeatureProductApi({ title, description, placement, status })

function* getAddFeatureProduct({ payload }: any) {
  const { values } = payload
  const { title, description, placement, status } = values
  try {
    const addFeatureProductResponse: AxiosResponse = yield call(
      takeAddFeatureProduct,
      { title, description, placement, status }
    )
    if (addFeatureProductResponse) {
      yield put(addFeatureProductSuccess(values))
    }
  } catch (error) {
    yield put(addFeatureProductFailure(error))
  }
}

function* watchAddFeatureProduct() {
  yield takeEvery(ADD_FEATURE_PRODUCT_REQUEST, getAddFeatureProduct)
}

export default function* rootSaga() {
  yield all([
    fork(watchFeatureProduct),
    fork(watchAddFeatureProduct),
    fork(watchAddPlacementForm),
  ])
}
