/** @format */

import { Card, Space, Spin, Typography, Upload, notification } from "antd";
import "./CommonS3UploadFiles.scss";
import { CameraOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Storage } from "aws-amplify";
import dayjs from "dayjs";
import { useState } from "react";
import MediaUrl from "../../Utils/mediaUrl";
const { Dragger } = Upload;

interface PropType {
  info?: any;
  onChange?: any;
  onUpload: (e: any) => void;
  title?: string;
  style?: React.CSSProperties;
  height?: number;
  small?: boolean;
  url?: string;
  module: string;
  prefix?: string;
  disabled?: boolean;
  accept?: string;
  circular?: boolean;
  type?: string;
}

const CircularContainer = (props: any) => {
  return (
    <div className="circular__container">
      {props?.url ? (
        <img width="100%" src={props?.url} />
      ) : (
        <div>
          <CameraOutlined />
          <Typography.Paragraph>Upload Image</Typography.Paragraph>
        </div>
      )}
    </div>
  );
};

const ProductContainer = (props: any) => {
  return (
    <Card className="product__image__container">
      {props?.url ? (
        <img width={"100%"} src={props?.url} />
      ) : (
        <div>
          {/* + */}
          <Typography.Paragraph strong>
            {props?.title || "UPLOAD PRIMARY IMAGES"}
          </Typography.Paragraph>
        </div>
      )}
    </Card>
  );
};

const MultipleProductContainer = (props: any) => {
  return (
    <Card className="product__image__container">
      {props?.url ? (
        <img width="100%" src={props?.url} />
      ) : (
        <div>
          {/* + */}
          <Typography.Paragraph strong>
            {props?.title || "UPLOAD PRIMARY IMAGES"}
          </Typography.Paragraph>
        </div>
      )}
    </Card>
  );
};

const DefaultContainer = (props: any) => {
  return (
    <Space direction="vertical" size={15}>
      <img
        width="100%"
        src="https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg"
      />
      <Typography.Text style={{ fontSize: 14, color: "#8285A3" }}>
        {props?.title || "Upload Image"}
      </Typography.Text>
    </Space>
  );
};

const DefaultTwoContainer = (title: string) => {
  return (
    <Space size={2} direction="vertical">
      <PlusCircleOutlined style={{ fontSize: 10, lineHeight: "10px" }} />
      <Typography.Text style={{ fontSize: 10, lineHeight: "10px", color: "#cccccc" }}>
        Add Image
      </Typography.Text>
    </Space>
  );
};
const CommonS3UploadFiles: React.FC<PropType> = ({
  info,
  onChange,
  onUpload,
  title,
  children,
  style,
  height,
  small,
  url,
  module,
  prefix,
  disabled,
  accept,
  circular,
  type = "default",
}) => {
  const [tempUri, setTempUri] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const s3FileUpload = (file: any) => {
    if (file?.size <= 5242880) {
      const tempName = file?.name?.split(".");
      const name =
        tempName.slice(0, tempName.length - 1).join("-") +
        dayjs()?.unix() +
        "." +
        tempName[tempName.length - 1];
      setLoading(true);
      Storage.put(`${prefix || module}/${name.split(" ").join("-")}`, file, {
        contentType: file.type,
      })
        .then((result: any) => {
          setLoading(false);
          onUpload(`public/${result?.key}`);
          setTempUri(MediaUrl("public/" + result?.key));
        })
        .catch((err) => {
          setLoading(false);
          // this.setState({ response: `Cannot uploading file: ${err}` })
        });
      return true;
    } else {
      notification.error({
        message: "Image Size must be less than 5MB",
        placement: "bottom",
      });
      return false;
    }
  };

  const getContainer = (key: string) => {
    switch (key) {
      case "circular":
        return <CircularContainer url={tempUri || url} />;
      case "product":
        return <ProductContainer title={title} url={tempUri || url} />;
      case "multiple":
        return <MultipleProductContainer title={title} />;
      default:
        return <DefaultContainer url={tempUri || url} title={title} />;
    }
  };

  return (
    // <input type='file' onChange={(e: any) => s3FileUpload(e?.target?.files[0])} />
    <Dragger
      accept={accept}
      disabled={disabled || loading}
      showUploadList={false}
      style={style}
      className={`common-s3-upload--container ${circular ? "circular" : ""} ${
        small ? "small" : ""
      }`}
      beforeUpload={(e: any) => s3FileUpload(e)}
      method="PUT"
      action=""
    >
      <div style={{ width: "100%" }}>
        <>{children ? <>{children}</> : <>{getContainer(type)}</>}</>
        {loading && (
          <div className="uploading--wrapper">
            <Spin tip="Uploading..." />
          </div>
        )}
      </div>
    </Dragger>
  );
};
export default CommonS3UploadFiles;
