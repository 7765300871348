import { Card, Col, Descriptions, Divider, Row, Space, Tabs, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect, Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { EditOutlined } from "@ant-design/icons";
import { fetchProduct, updateProduct } from "../../../../Redux/ProductRedux/ProductActions";
import MyContent from "../../../../Components/Admin/Content/Content";
import Loading from "../../../../Components/Admin/Loading/Loading";
import PageHolder from "../../../../Components/Admin/PageHolder/PageHolder";
import ProductSpecifications from "../../../../Components/Admin/ProductSpecifications/ProductSpecifications";
import SecondaryImageUploadContainer from "../../../../Components/SecondaryImages/SecondaryImagesUpload/SecondaryImageUploadContainer";
import ProductBlogAssociation from "../../../../Components/v2/ProductBlogAssociation/ProductBlogAssociation";

export interface Product {
  _id?: string;
  hardware?: string[];
  lining?: string[];
  pollyfill?: boolean;
  rib?: boolean;
  status?: string;
  availability_status?: string;
  tags?: any[];
  notes?: any[];
  new?: boolean;
  primary_image?: string;
  name?: string;
  category?: Category;
  description?: string;
  product_specification?: ProductSpecification[];
  slug?: string;
  pattern_package?: any[];
  createdAt?: Date;
  updatedAt?: Date;
  secondary_image?: string[];
  __v?: number;
}

export interface Category {
  _id?: string;
  title?: string;
}

export interface ProductSpecification {
  status?: boolean;
  secondary_image?: string[];
  _id?: string;
  size_range?: SizeRange[];
  leather_id?: LeatherID;
}

export interface LeatherID {
  _id?: string;
  item_name?: string;
  icon?: string;
}

export interface SizeRange {
  _id?: string;
  price?: Price[];
  size?: number;
}

export interface Price {
  _id?: string;
  currency?: string;
  value?: number;
}

export const ProductDetails = (props: any) => {
  const product = useSelector((state: any) => state.ProductReducer);
  const { id } = useParams<any>();
  const {
    detailing,
    details,
    updating,
  }: { detailing: boolean; details: Product; updating: boolean } = product;
  const dispatch = useDispatch();
  const [images, setImages] = useState<any[]>([]);

  const history = useNavigate();
  //   useEffect(() => {
  //     if (details?.team?.id) {
  //       // dispatch(fetchTeam({ id: details?.team?._id }))
  //     }
  //   }, [details]);

  useEffect(() => {
    const tempImages = [details?.primary_image];

    let currentImages = [details?.primary_image];
    details?.product_specification?.map((ps) => {
      if (ps.secondary_image) {
        currentImages = [...currentImages, ...ps.secondary_image];
      }
    });

    setImages(currentImages);
  }, [details]);

  useEffect(() => {
    if (id) {
      dispatch(fetchProduct({ id: id }));
    }
  }, [id]);

  const [nav1, setNav1] = useState<any>();
  const [nav2, setNav2] = useState<any>();

  const showableProperty = [
    {
      label: "CATEGORY",
      value: details?.category?.title,
    },
    {
      label: "TAGS",
      value: details?.tags?.join(", "),
    },
    {
      label: "HARDWARE",
      value: details?.hardware?.join("/"),
    },
    {
      label: "LINING",
      value: details?.lining?.join("/"),
    },
    {
      label: "POLYFILL",
      value: details?.pollyfill ? "Yes" : "No",
    },
    {
      label: "RIB",
      value: details?.rib ? "Yes" : "No",
    },
  ];

  const onFinish = (values: any) => {
    // dispatch(
    //   updateProduct({
    //     ...values,
    //     _id: details?._id,
    //     slug: details?.slug,
    //     name: details?.name,
    //   })
    // );
  };

  return (
    <MyContent>
      <Suspense fallback={<Loading fullScreen />}>
        <Content>
          <Typography.Title level={1}>GENERAL INFO</Typography.Title>

          <Divider />
          <PageHolder loading={detailing} empty={!details}>
            <Row gutter={[60, 20]}>
              <Col lg={9}>
                <Card>
                  <Row justify="center">
                    {/* <Col lg={18}>
                      <Image
                        preview={false}
                        src={details?.primary_image}
                        style={{ width: "100%" }}
                      />
                    </Col> */}
                    <Col lg={24}>
                      <div style={{ padding: "0px 20px", margin: "20px 0px" }}>
                        <Row justify="center">
                          <Col lg={18}>
                            <Slider
                              arrows={false}
                              asNavFor={nav2}
                              ref={(slider) => setNav1(slider)}
                              // slidesToShow={1}
                            >
                              {images?.map((item) => {
                                return (
                                  <div>
                                    <img alt="Primary" src={item} style={{ width: "100%" }} />
                                  </div>
                                );
                              })}
                            </Slider>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={24}>
                      <Typography.Title level={4} style={{ marginBottom: 20 }}>
                        DETAILS
                      </Typography.Title>
                      <Descriptions
                        column={1}
                        labelStyle={{ color: "#828282", minWidth: 150, fontWeight: "bold" }}
                        contentStyle={{ color: "#828282" }}
                      >
                        {showableProperty?.map((item, key) => (
                          <Descriptions.Item label={item.label}>{item.value}</Descriptions.Item>
                        ))}
                      </Descriptions>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col lg={15}>
                <Tabs>
                  <Tabs.TabPane key="details" tab="Product Details">
                    <Row justify="end" style={{ marginBottom: 40 }}>
                      <Col lg={5}>
                        <Space>
                          <EditOutlined
                            onClick={() => history(`/product/${details?._id}/edit`)}
                            style={{ fontSize: 22 }}
                          />
                          {/* <DeleteOutlined style={{ fontSize: 22 }} /> */}
                        </Space>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={15}>
                        <Typography.Title style={{ color: "black", textTransform: "uppercase" }}>
                          {details?.name}
                        </Typography.Title>
                        <Typography.Paragraph
                        //   style={{ color: "black" }}
                        >
                          {details?.description}
                        </Typography.Paragraph>
                        <Typography.Title level={4}>LEATHER PROFILE</Typography.Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={24}>
                        <ProductSpecifications data={details?.product_specification} />
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="secondary_image" tab="More Images">
                    <SecondaryImageUploadContainer
                      details={details}
                      updating={updating}
                      images={details?.secondary_image}
                      onFinish={onFinish}
                      noEdit={true}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </Col>
            </Row>
          </PageHolder>
        </Content>
      </Suspense>
    </MyContent>
  );
};

export default ProductDetails;
