import { FC, useEffect, useState } from "react";
import { Button, Col, Divider, Form, Input, message, Modal, Row, Select } from "antd";

import { useSelector } from "react-redux";
import { createOrder } from "../../API";

interface Props {
  visible?: boolean;
  order?: any;
  handleClose?: (e: any) => void;
}

const ReceiveFromStoreModal: FC<Props> = ({
  visible = false,
  handleClose = (e: any) => {},
  order = {},
}) => {
  const [form] = Form.useForm();
  const { user } = useSelector((state: any) => state.AuthReducer);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    setLoading(true);
    const data = { ...order };
    console.log("confirm clicked");
    // await updateOrder(data);
    await createOrder({ ...order, location: "store", bypass_otp: true })
      .then(() => {})
      .then(() => {
        handleClose(true);
      })
      .catch((err: any) => {
        message.error(err.response?.data?.message);
      });
    setLoading(false);
  };

  useEffect(() => {
    form.setFieldsValue({ received_by: user?.name });
  }, [user]);

  return (
    <Modal
      centered
      visible={visible}
      onCancel={handleClose}
      footer={null}
      closable={false}
      maskStyle={{ backdropFilter: "blur(4px)", background: "rgba(0,0,0,0.3)" }}
      destroyOnClose={true}
      width={"45%"}
      bodyStyle={{ padding: "30px" }}
      className="delivery-form"
    >
      <Row align="middle" className="delivery-form__header">
        <Col className="delivery-form__header__orderId">
          <h4>Order No. #{order?.order_no}</h4>
        </Col>
        <Col className="delivery-form__header__title">
          <h2>Receive in Store</h2>
        </Col>
      </Row>
      <Divider style={{ padding: 0, margin: 0 }} />

      <Form
        layout="vertical"
        className="delivery-form__container"
        form={form}
        onFinish={handleSubmit}
        colon={false}
        requiredMark={false}
      >
        <Row gutter={[20, 20]} className="form-row">
          <Col sm={24} lg={12}>
            <Form.Item label="Received in store Confirmed by" name="received_by">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col sm={24} lg={12}>
            <Form.Item label="Location" name="location">
              <Select
                options={[
                  {
                    value: "factory",
                    label: "Factory",
                  },
                  {
                    value: "store",
                    label: "Store",
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="delivery-form__footer">
          <Button className="cancel-btn btn" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="cancel-submit btn"
            type="primary"
            htmlType="submit"
            // disabled={isLoading}
            loading={loading}
          >
            Confirm
            {/*{isLoading ? <Spin size="small" /> : "Submit"}*/}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default ReceiveFromStoreModal;
