/** @format */

import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Space,
  Tabs,
  Tooltip,
  Typography,
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { FC, useEffect, useState } from 'react'
import ActivePromoCodes from '../../../../Data/ActivePromoCodes.json'
import HistoryPromoCodes from '../../../../Data/HistoryPromoCodes.json'
import NeuMorph from '../../../../Components/Admin/NeuMorph/NeuMorph'
import { DeleteOutlined, EditOutlined, EyeFilled } from '@ant-design/icons'
import LatidoTable from '../../../../Components/Admin/Table/Table'
import CampaignCard from '../../../../Data/CampaignCard.json'
import { useDispatch } from 'react-redux'
import { GetPromocodeRequest } from '../../../../Redux/Actions/GridAction'

const { TabPane } = Tabs

const content = (
  <Content style={{ padding: 20, width: '289px', color: '#000000' }}>
    <Typography.Paragraph>Add</Typography.Paragraph>
    <Typography.Paragraph>
      The tip is shown on mouse enter, and is hidden on mouse leave.
    </Typography.Paragraph>
  </Content>
)
const PromoCodes: FC = ({ history }: any) => {
  const [state, setState] = useState<string>('')
  const [dataSource, setDataSource] = useState<any>()

  const dispatch = useDispatch()

  const ActionList = (props: any) => {
    return (
      <NeuMorph>
        <Space
          className='content'
          style={{ padding: 10, alignItems: 'center', display: 'flex' }}
        >
          <EyeFilled
            onClick={() => history.push('/grid/promo-code/preview-promo-codes')}
            style={{ fontSize: 18 }}
          />
          <EditOutlined
            onClick={() => history.push('/grid/promo-code/edit')}
            style={{ fontSize: 18 }}
          />
          <DeleteOutlined style={{ fontSize: 18 }} />
        </Space>
      </NeuMorph>
    )
  }

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'campaign',
      dataIndex: 'campaign',
      key: 'campaign',
    },
    {
      title: 'code',
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: 'discount details',
      key: 'discount_details',
      dataIndex: 'discount_details',
    },
    {
      title: 'status',
      key: 'status',
      render: (text: any, record: any) => (
        <Typography.Text
          style={{ color: text.status === 'Running' ? '#0DC209' : '#FF0000' }}
        >
          {text.status}
        </Typography.Text>
      ),
    },
    {
      title: 'actions',
      key: 'actions',
      render: (text: any, record: any): any => (
        <Space size='middle'>
          <ActionList userId={record.id} />
        </Space>
      ),
    },
  ]

  const handleSearch = (e: any) => {
    if (ActivePromoCodes) {
      const searchProduct = ActivePromoCodes.filter(
        (product: any) =>
          product.title.toLowerCase().indexOf(e.toLowerCase()) > -1
      )
      setDataSource(searchProduct)
    }
  }

  const handleChange = (value: any) => {
    if (value === 'date') {
      const sortProduct = ActivePromoCodes.sort((a: any, b: any) => {
        var c: any = new Date(a.date)
        var d: any = new Date(b.date)
        return c - d
      })
      setDataSource(sortProduct)
    } else if (value === 'title') {
      const sortProduct1 = ActivePromoCodes.sort((a: any, b: any) =>
        a.title > b.title ? 1 : -1
      )
      setDataSource(sortProduct1)
    }
  }
  useEffect(() => {
    dispatch(GetPromocodeRequest('data'))
  })
  return (
    <>
      <Row justify='space-between'>
        <Col sm={24} md={16} lg={16} xl={14}>
          <Row align='middle' style={{ marginTop: 30 }}>
            <Col>
              <Typography.Title level={1} style={{ margin: 'auto' }}>
                {state === 'add_new' ? 'CAMPAIGN' : ' PROMO CODE'}
              </Typography.Title>
            </Col>
            <Col style={{ marginLeft: 30 }}>
              <Tooltip placement='right' color={'#C4C4C4'} title={content}>
                <Button
                  type='primary'
                  size='middle'
                  shape='round'
                  onClick={() => history.push('/grid/promo-code/add')}
                >
                  Add New +
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={24} md={8} lg={8}>
          <Row
            justify='space-between'
            align='middle'
            gutter={[30, 10]}
            style={{ marginTop: 30 }}
          >
            <Col sm={12}>
              <Input.Search placeholder='Search' />
            </Col>
            <Col sm={12}>
              <Input placeholder='Sort by: Date' />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify='center' align='middle'>
        <Col span={24}>
          <Tabs defaultActiveKey='1' centered>
            <TabPane tab='Active' key='1' style={{ height: 200 }}>
              <LatidoTable columns={columns} dataSource={ActivePromoCodes} />
            </TabPane>
            <TabPane tab='History' key='2'>
              <LatidoTable columns={columns} dataSource={HistoryPromoCodes} />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  )
}

export default PromoCodes
