export const parseError = (data: any) => {
  console.log(data?.response?.data, "err");
  const errorData = data?.response?.data;
  return {
    status: "error",
    message:
      typeof errorData?.message === "string"
        ? errorData.message
        : typeof data?.message === "string"
        ? data?.message
        : "Server Error",
  };
};
