/** @format */

import { ArrowUpOutlined } from "@ant-design/icons";
import "./ArrowCircle.scss";

const ArrowCircle = () => {
  return (
    <div className='outer-circle'>
      <ArrowUpOutlined />
    </div>
  );
};
export default ArrowCircle;
