import { Card, Carousel, Col, Descriptions, Divider, Empty, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

import { useEffect, useState } from "react";
import { useParams } from "react-router";
import UserOrderItems, { OrderType } from "../UserOrderItems/UserOrderItems";
import UsersOrderItem, { CustomerOrder } from "../UsersOrderItem/UsersOrderItem";
import { useQuery } from "react-query";
import { getCustomerOrder } from "../../../API/CustomerApi";

export const UsersOrders = (props: any) => {
  const [multiProduct, setMultiProduct] = useState<OrderType[]>([]);
  const [singleOrder, setSingleOrder] = useState<CustomerOrder>();

  const { id } = useParams<any>();
  const { data, isLoading } = useQuery(["getCustomerOrders", { id: id }], getCustomerOrder);
  const descriptionStyle = {
    labelStyle: {
      width: "50%",
      color: "#9DA4B2",
    },
    contentStyle: {
      width: "50%",
      display: "flex",
      justifyContent: "flex-end",
      fontWeight: 300,
      color: "#9DA4B2",
    },
  };
  useEffect(() => {
    setMultiProduct([]);
  }, []);
  return (
    <Content>
      <Row gutter={[10, 10]} hidden={!!singleOrder}>
        {data?.data?.data?.map((d: any) => {
          return (
            <Col lg={8}>
              <Card>
                <UsersOrderItem data={d} onClick={(e: CustomerOrder) => setSingleOrder(e)} />
              </Card>
            </Col>
          );
        })}
      </Row>
      <Card hidden={!singleOrder}>
        <Row justify="center">
          <Divider type="horizontal" style={{ height: "auto" }} />
          <Col lg={12}>
            <UsersOrderItem data={singleOrder} />

            <Descriptions
              column={1}
              style={{ marginTop: 20 }}
              className="table-auto"
              {...descriptionStyle}
            >
              <Descriptions.Item label="Subtotal">NPR. 40,000.00</Descriptions.Item>
              <Descriptions.Item label="Tax">NPR. 15.00</Descriptions.Item>
              <Descriptions.Item
                label={
                  <Typography.Title level={4} className="product-name">
                    TOTAL
                  </Typography.Title>
                }
              >
                <Typography.Title level={4} className="product-name">
                  NPR. 23,000
                </Typography.Title>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Card>
    </Content>
  );
};

export default UsersOrders;
