import { Button, Col, Divider, Form, Input, Radio, Row, Select, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import MyContent from "../../../../Components/Admin/Content/Content";
import PageLoading from "../../../../Components/Admin/PageLoading/PageLoading";
import CommonS3UploadFiles from "../../../../Components/Admin/S3UploadFiles/CommonS3UploadFiles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  createProductCategory,
  updateProductCategory,
} from "../../../../Redux/ProductCategoryRedux/ProductCategoryActions";
import { takeProductCategorys } from "../../../../Redux/ProductCategoryRedux/ProductCategoryApis";
import MediaUrl from "../../../../Utils/mediaUrl";
import { slugify } from "../../../../Utils/slugify";
import { setLayout } from "../../../../Redux/Actions/LayoutActions";

export const ProductCategoryForm = (props: any) => {
  const dispatch = useDispatch();
  // const { form } = props;
  const [form] = useForm();
  const [parents, setParents] = useState([]);
  const productcategory = useSelector((state: any) => state.ProductCategoryReducer);
  const layout = useSelector((state: any) => state.LayoutReducers);

  const { list, creating, updating, created, updated, details, detailing } = productcategory;

  const { update, data, parentId } = props;

  const { id } = useParams<any>();

  const history = useNavigate();

  const onBackHandler = () => {
    if (id !== "add") {
      history(`/product category/${id}`);
    } else {
      history(`/product category`);
    }
  };

  const getDropCategory = async () => {
    await takeProductCategorys({
      page: 0,
      limit: 0,
    })
      .then((data) => {
        setParents(data?.data);
      })
      .catch((err) => {});
  };
  const onFinish = (values: any) => {
    if (update) {
      dispatch(
        updateProductCategory({
          ...values,
          _id: data?._id,

          //flag for Status: "published" or "draft"
          is_active: values?.is_active !== null ? values?.is_active : true,
        })
      );
    } else {
      dispatch(
        createProductCategory({
          ...values,
          slug: slugify(values?.title),

          //flag for Status: "published" or "draft"
          is_active: values?.is_active !== null ? values?.is_active : true,
        })
      );
    }
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({
      parent_category: parentId || data?.parent_category?._id,
      title: data?.title,
      image: MediaUrl(data?.image),
      slug: data?.slug,
      is_active: data?.is_active,
    });
    getDropCategory();
  }, [data]);
  useEffect(() => {
    if (layout?.created || layout?.updated) {
      props.onCancel();
      dispatch(
        setLayout({
          created: false,
          updated: false,
        })
      );
    }
  }, [layout?.created, layout?.updated]);

  return (
    <PageLoading loading={detailing}>
      <MyContent>
        <Typography.Title level={3} style={{ textAlign: "center" }}>
          ADD {parentId ? "SUB" : ""} CATEGORY
        </Typography.Title>

        <Divider />

        <Form
          layout="vertical"
          form={form}
          name="basic"
          onFinish={onFinish}
          className="style--kbbs"
        >
          <Row gutter={[20, 0]} justify="center">
            <Form.Item name="slug" hidden>
              <Input />
            </Form.Item>

            <Col lg={21}>
              <Form.Item name="image" label="Add Image">
                <CommonS3UploadFiles
                  type="ratio"
                  ratio={[10, 10]}
                  url={data?.image ? MediaUrl(data?.image) : false}
                  module="product"
                  onUpload={(e) => form.setFieldsValue({ image: e })}
                />
              </Form.Item>
            </Col>

            <Col lg={21}>
              <Form.Item
                label={`${parentId ? "Sub" : ""} Category Name`}
                name="title"
                rules={[{ required: true, message: "Category Name is required!" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={21}>
              <Form.Item label="Status" name="is_active">
                <Radio.Group>
                  <Radio value={true}>Published</Radio>
                  <Radio value={false}>Draft</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            {parentId && (
              <Col lg={21}>
                <Form.Item hidden label="Parent Category" name="parent_category">
                  <Select
                    //  onSearch={onSearch}
                    showSearch
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    defaultValue={parentId}
                  >
                    {parents?.map((item: any) => {
                      return <Select.Option value={item?._id}>{item?.title}</Select.Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            )}

            <Col lg={21} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Form.Item>
                <Button
                  style={{ width: "150px" }}
                  disabled={creating || updating}
                  type="text"
                  onClick={() => {
                    props?.onCancel();
                    form.resetFields();
                  }}
                >
                  {update ? "CANCEL" : "CANCEL"}
                </Button>
                <Button
                  style={{ width: "150px" }}
                  loading={creating || updating}
                  type="primary"
                  htmlType="submit"
                >
                  {update ? "UPDATE" : "ADD"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </MyContent>
    </PageLoading>
  );
};

export default ProductCategoryForm;
