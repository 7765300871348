import { FC, useState } from "react";
import { Button, Card, Typography } from "antd";

import { MaterialCategoryType } from "../../../../Utils/types";

import "./MaterialCategoryBox.scss";
import { InventoryCategoriesType } from "../../../../Redux/InventoryCategory/InventoryCategoryTypes";
import ImageHolder from "../../../ImageHolder/ImageHolder";
import { EditFilled } from "@ant-design/icons";

interface MaterialCategoryBoxProps extends MaterialCategoryType {
  selectedCategory: InventoryCategoriesType;
  handleCategoryChange: (c: any) => void;
  handleCategoryEdit: (c: any) => void;
  items: InventoryCategoriesType;
}

export const MaterialCategoryBox: FC<MaterialCategoryBoxProps> = (props) => {
  const {
    icon,
    title,
    category,
    selectedCategory,
    handleCategoryChange,
    items,
    handleCategoryEdit,
  } = props;

  const handleSelectCategory = (selectedCategory: InventoryCategoriesType) => {
    handleCategoryChange(selectedCategory);
  };

  return (
    <Card
      className={`material_box_wrapper ${
        selectedCategory?.inventory_category === category
          ? "material_box_wrapper__status_active"
          : ""
      }`}
      onClick={() => handleSelectCategory(items)}
    >
      <div
        className="edit__button"
        style={{ position: "absolute", top: 20, right: 20, zIndex: 1000 }}
      >
        <Button
          size="small"
          shape="circle"
          type="link"
          icon={<EditFilled style={{ fontSize: 18 }} />}
          onClick={() => handleCategoryEdit(category)}
        />
      </div>
      <ImageHolder
        src={icon}
        alt={title}
        className="material_box_image"
        // style={{ height: 60 }}
      />
      <Typography.Title ellipsis={{ tooltip: `${title}` }} level={4} className="material_box_title">
        {title}
      </Typography.Title>
    </Card>
  );
};
