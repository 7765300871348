import {
  CreateMemberParams,
  CREATE_MEMBER,
  CREATE_MEMBER_FAILURE,
  CREATE_MEMBER_SUCCESS,
  FETCH_MEMBERS,
  FETCH_MEMBERS_FAILURE,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBER_DETAILS,
  FETCH_MEMBER_DETAILS_FAILURE,
  FETCH_MEMBER_DETAILS_SUCCESS,
  REMOVE_MEMBER,
  REMOVE_MEMBER_FAILURE,
  REMOVE_MEMBER_SUCCESS,
  UPDATE_MEMBER,
  UPDATE_MEMBER_FAILURE,
  UPDATE_MEMBER_SUCCESS,
  MemberDetailsParams,
  MemberFetchParams,
} from './MemberTypes'

//fetch Member List
export const fetchMembers = (params: MemberFetchParams) => ({
  type: FETCH_MEMBERS,
  payload: params,
})

export const fetchMembersSuccess = (response: any) => ({
  type: FETCH_MEMBERS_SUCCESS,
  payload: response,
})

export const fetchMembersFailure = (error: any) => ({
  type: FETCH_MEMBERS_FAILURE,
  payload: error,
})

//fetch Member Details
export const fetchMember = (params: MemberDetailsParams) => ({
  type: FETCH_MEMBER_DETAILS,
  payload: params,
})

export const fetchMemberSuccess = (response: any) => ({
  type: FETCH_MEMBER_DETAILS_SUCCESS,
  payload: response,
})

export const fetchMemberFailure = (error: any) => ({
  type: FETCH_MEMBER_DETAILS_FAILURE,
  payload: error,
})

//create Member
export const createMember = (params: CreateMemberParams) => ({
  type: CREATE_MEMBER,
  payload: params,
})

export const createMemberSuccess = (response: CreateMemberParams) => ({
  type: CREATE_MEMBER_SUCCESS,
  payload: response,
})
export const createMemberFailure = (error: any) => ({
  type: CREATE_MEMBER_FAILURE,
  payload: error,
})

//update Member
export const updateMember = (params: any) => ({
  type: UPDATE_MEMBER,
  payload: params,
})

export const updateMemberSuccess = (response: CreateMemberParams) => ({
  type: UPDATE_MEMBER_SUCCESS,
  payload: response,
})

export const updateMemberFailure = (error: any) => ({
  type: UPDATE_MEMBER_FAILURE,
  payload: error,
})

//delete Member
export const deleteMember = (params: MemberDetailsParams) => ({
  type: REMOVE_MEMBER,
  payload: params,
})
export const deleteMemberSuccess = (response: MemberDetailsParams) => ({
  type: REMOVE_MEMBER_SUCCESS,
  payload: response,
})
export const deleteMemberFailure = (error: any) => ({
  type: REMOVE_MEMBER_FAILURE,
  payload: error,
})
