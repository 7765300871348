import {
  Avatar,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Row,
  Tabs,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import bodyData from "../../../../Data/body.json";
import { BodyMeasure } from "../../../../Components/Admin/UserMeasurement/UserMeasurement";
import { useForm } from "antd/lib/form/Form";
import { updateCustomer } from "../../../../Redux/CustomerRedux/CustomerActions";
import { useDispatch, useSelector } from "react-redux";
import { RouteParams } from "./UserProfile";
import { useParams } from "react-router";

export const UserDetailsEdit = (props: any) => {
  const { userId } = useParams<any>();
  const user = useSelector((state: any) => state.CustomerReducer);

  const { data } = props;
  const [form] = useForm();
  const [formMeasurements] = useForm();
  const dispatch = useDispatch();
  function callback(key: string) {}
  const keys = Object.keys(bodyData) as Array<keyof BodyMeasure>;

  const handleSubmit = (values: any) => {
    let userDetails = {
      name: values?.firstName + " " + values?.lastName,
      dob: values?.dob,
      address: values?.address,
      phone: values?.phone,
      user_id: userId,
    };
    dispatch(updateCustomer({ slug: "user-update-general", ...userDetails }));
  };

  const handleMeasurementSubmit = (values: any) => {
    dispatch(updateCustomer({ slug: "customer_sizing", ...values, user_id: userId }));
  };
  useEffect(() => {
    form.setFieldsValue({
      ...data,
      firstName: data?.name?.split(" ")[0],
      lastName: data?.name?.split(" ")[1],
    });
    formMeasurements.setFieldsValue({
      ...data,
      firstName: data?.name?.split(" ")[0],
      lastName: data?.name?.split(" ")[1],
    });
  }, [data]);

  useEffect(() => {
    if (user?.created || user?.updated) {
      props?.onCancel();
    }
  }, [user?.created, user?.updated]);

  return (
    <Row gutter={[20, 20]}>
      <Col lg={24}>
        <Typography.Title className="product-name" style={{ paddingBottom: 20 }}>
          EDIT PROFILE
        </Typography.Title>

        <Card style={{ padding: "20px 50px" }}>
          <Row justify="start">
            <Avatar
              size={140}
              style={{ marginBottom: 20 }}
              src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"
            />
          </Row>

          <Form
            onFinish={handleSubmit}
            layout="vertical"
            form={form}
            className="non-neumorphic"
            onReset={() => props?.onCancel()}
          >
            <Row gutter={[60, 20]}>
              <Col lg={12}>
                <Form.Item label="First Name" name="firstName">
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Last Name" name="lastName">
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Phone Number"
                  name="phoneNumber"
                  rules={[
                    {
                      pattern: /^(?:\+977)?[9][6-9]\d{8}$/,
                      message: "Please enter valid phone number",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Address" name="address">
                  <Input />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <Form.Item>
                  <Button
                    loading={user?.creating || user?.updating}
                    type="primary"
                    shape="round"
                    size="middle"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                  <Button disabled={user?.creating || user?.updating} type="text" htmlType="reset">
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Form
            form={formMeasurements}
            onFinish={handleMeasurementSubmit}
            layout="vertical"
            className="non-neumorphic"
            onReset={() => props?.onCancel()}
          >
            <Divider />
            <Typography.Title className="product-name" style={{ paddingLeft: 30 }}>
              SIZE
            </Typography.Title>
            <Descriptions
              column={3}
              colon={false}
              labelStyle={{
                textTransform: "uppercase",
                fontSize: "16x",
                minWidth: "150px",
                margin: "20px 0px",
                display: "flex",
              }}
              contentStyle={{
                width: "50px",
                display: "flex",
                alignItems: "center",
              }}
              style={{ marginLeft: 30 }}
            >
              {keys.map((key) => {
                return (
                  <Descriptions.Item span={1} label={key}>
                    {/* {bodyData[key]} */}
                    <Form.Item
                      // initialValue={bodyData[key]}
                      style={{ marginBottom: 0 }}
                      name={key}
                    >
                      <Input style={{ width: 70 }} />
                    </Form.Item>
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
            <Form.Item>
              <Button
                loading={user?.creating || user?.updating}
                type="primary"
                shape="round"
                size="middle"
                htmlType="submit"
              >
                Save
              </Button>
              <Button disabled={user?.creating || user?.updating} type="text" htmlType="reset">
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default UserDetailsEdit;
