/** @format */

import { Table } from "antd";
import { LatidoAnimatedLogoLoader } from "../../LatidoAnimateLogo/LatidoAnimateLogo";
import "./Table.scss";

export const LatidoTable = (props: any) => {
  const { moveRow } = props;
  return (
    <Table
      components={props.components}
      pagination={{ position: ["bottomCenter"] }}
      onRow={(record, index) => ({
        index,
        moveRow,
      })}
      {...props}
    />
  );
};

export default LatidoTable;
