import React from "react";
import PostBuilder from "./BlogBuilder";
import PlayBar from "v2/PlayBar/PlayBar";
import parse from "html-react-parser";
import MediaUrl from "Utils/mediaUrl";
import { Divider } from "antd";

export const BlogWrapper = ({ primaryMedia, data }: any) => {
  const postRef: any = React.useRef<HTMLDivElement>();

  return (
    <div ref={postRef} style={{ width: "100%" }}>
      <div className="post__container">
        {primaryMedia && (
          <div className="main__image__container">
            <img alt={"banner"} src={MediaUrl(primaryMedia)} style={{ width: "100%" }} />
          </div>
        )}
        <Divider />

        {data &&
          data?.map((i: any, n: any) => {
            if (Array.isArray(i)) {
              return <PostBuilder data={i} key={n} />;
            }
            if (typeof i === "object") {
              const p = i.playlist.map((i: any) => ({
                src: i,
                title: data?.title,
                author: data?.author,
                time: 0,
                duration: 0,
              }));
              return <PlayBar playlist={p} key={n} />;
            }
            return parse(i);
          })}
      </div>
    </div>
  );
};
