import { createOrder, updateOrder } from './index'
import { useMutation } from 'react-query'

export const useCreateOrder = () => {
  return useMutation(createOrder)
}

export const useUpdateOrder = () => {
  return useMutation(updateOrder)
}
