import {
  POST_PROMOCODE_REQUEST,
  POST_PROMOCODE_SUCCESS,
  POST_PROMOCODE_FAILURE,
  POST_NEWSLETTER_FORM_REQUEST,
  POST_NEWSLETTER_FORM_SUCCESS,
  POST_NEWSLETTER_FORM_FAILURE,
  FETCH_NEWSLETTER_TABLE_CONTENT_FAILED,
  FETCH_NEWSLETTER_TABLE_CONTENT_REQUEST,
  FETCH_NEWSLETTER_TABLE_CONTENT_SUCCESS,
  GET_PROMOCODE_REQUEST,
  GET_PROMOCODE_SUCCESS,
  GET_PROMOCODE_FAILURE,
} from './../Constants/constants'

export const GetNewsletterTableContents = (data: any) => ({
  type: FETCH_NEWSLETTER_TABLE_CONTENT_REQUEST,
  payload: { data: data },
})
export const GetNewsletterTableContentsSuccess = (data: any) => ({
  type: FETCH_NEWSLETTER_TABLE_CONTENT_SUCCESS,
  payload: { data: data },
})
export const GetNewsletterTableContentsFailed = (error: any) => ({
  type: FETCH_NEWSLETTER_TABLE_CONTENT_FAILED,
  payload: { error: error },
})

export const PostNewsletterForm = (props: any) => ({
  type: POST_NEWSLETTER_FORM_REQUEST,
  payload: props,
})
export const PostNewsletterFormSuccess = (data: any) => ({
  type: POST_NEWSLETTER_FORM_SUCCESS,
  payload: data,
})
export const PostNewsletterFormFailure = (data: any) => ({
  type: POST_NEWSLETTER_FORM_FAILURE,
  payload: data,
})

export const GetPromocodeRequest = (props: any) => ({
  type: GET_PROMOCODE_REQUEST,
  payload: props,
})
export const GetPromocodeSuccess = (data: any) => ({
  type: GET_PROMOCODE_SUCCESS,
  payload: data,
})
export const GetPromocodeFailure = (data: any) => ({
  type: GET_PROMOCODE_FAILURE,
  payload: data,
})

export const PostPromocodeRequest = (props: any) => ({
  type: POST_PROMOCODE_REQUEST,
  payload: props,
})
export const PostPromocodeSuccess = (data: any) => ({
  type: POST_PROMOCODE_SUCCESS,
  payload: data,
})
export const PostPromocodeFailure = (data: any) => ({
  type: POST_PROMOCODE_FAILURE,
  payload: data,
})
