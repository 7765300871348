import {
  Button,
  Card,
  Col,
  Collapse,
  // DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import DatePicker from "antd/lib/date-picker";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchProducts, getOpt } from "../../API";
import InputSelect from "../../Components/Select";
import SuccessModel from "./SuccessModel";
import Moment from "moment";
import { useCreateOrder } from "../../API/customHooks";
import { ExclamationOutlined } from "@ant-design/icons";
import { disableBackDateFromToday } from "../../Utils";
import { parse } from "qs";
import { NULL } from "node-sass";
import { environment } from "../../Config/environment";
import dayjs from "dayjs";
import moment from "moment";
import { availableSizes } from "../Admin/Forms/Forms/ProductDetailsForm";
import { NumberInput } from "../../Components/NumberInput/NumberInput";
import Autocomplete from "react-google-autocomplete";
import { Origin } from "Views/Orders/OrdersEnum";

const { Panel } = Collapse;

const selectOptions = [
  {
    value: false,
    title: "No",
  },
  {
    value: true,
    title: "Yes",
  },
];

const bodyTypeOption = [
  {
    value: "FIT",
    title: "Fit",
  },
  {
    value: "SKIN_TIGHT",
    title: "Skin Tight",
  },
  {
    value: "GENERAL_FIT",
    title: "General Fit",
  },
  {
    value: "COMFORT",
    title: "Comfort",
  },
  {
    value: "LAYERED",
    title: "Layered",
  },
];

const LabelComponent = ({ children, hint }: { children: any; hint?: any }) => (
  <div
  // style={{ width: "clamp(150px, 10vw, 220px)" }}
  >
    {children}
    <span
      style={{
        marginLeft: 10,
        color: "#d1d1d1",
        textTransform: "none",
        fontWeight: 500,
        fontSize: 18,
      }}
    >
      {" "}
      {hint}
    </span>
  </div>
);

export const CustomerOrder = () => {
  const [form]: any = Form.useForm();
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [selectedLeather, setSelectedLeather] = useState<any>({});
  const [daysLeft, setDaysLeft] = useState<number | undefined>();
  const [advanceAmount, setAdvanceAmount] = useState<any>();
  const [otpCode, setOtpCode] = useState<any>();
  const [totalAmount, setTotalAmount] = useState<any>();
  const [visible, setVisible] = useState<boolean>(false);
  const [otpVisible, setOtpVisible] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const { state }: any = useLocation();
  const [products, setProducts] = useState([]);
  const [leather, setLeather] = useState([]);
  const [hardware, setHardware] = useState([]);
  const [lining, setLining] = useState([]);
  const [size, setSize] = useState([]);
  const userInfoItem = ["address", "userName", "email", "gender"];
  const navigate = useNavigate();

  const [sizeRangeId, setSizeRangeId] = useState("");

  const [discountPercent, setDiscountPercent] = useState(false);

  const productInfoItem = [
    "name",
    "leather_profile",
    "leather_size",
    "hardware",
    "lining",
    "polyfill",
    "rib",
  ];
  const bodyMeasurementItem = [
    "base_size",
    "body_type",
    "length",
    "chest",
    "waist",
    "hips",
    "shoulder",
    "sleeves",
    "arms",
    "weight",
    "height",
  ];

  const priceItem = ["total_amount", "discount", "advance", "reminning_amonut", "delivery_date"];
  const [errorSection, setErrorSection] = useState({
    userInfo: false,
    productInfo: false,
    bodyMeasurement: false,
    remarks: false,
    price: false,
  });

  const { mutate: createOrder, isLoading, status, error: createError }: any = useCreateOrder();

  // fetching products
  const { data, isFetching } = useQuery(["products"], () => fetchProducts(), {
    refetchOnWindowFocus: false,
  });

  const handleClose = () => {
    setVisible(false);
    setOtpVisible(false);
  };

  useEffect(() => {
    if (status === "success") {
      setVisible(true);
      setOtpVisible(false);
    }
    if (status === "error") {
      console.log(createError?.response?.data?.message);
      notification.error({
        message: createError?.response?.data?.message || createError?.message,
        placement: "bottom",
      });
    }
  }, [status]);

  useEffect(() => {
    setSizeRangeId(state?.product_specification?.size_range_id);
    setAdvanceAmount(state?.advance_amount || 0);
    setTotalAmount(state?.total_amount || 0);
    if (state?.product) {
      form.setFieldsValue({
        armhole: state?.sizing?.armhole || 0,
        arms: state?.sizing?.arms || 0,
        base_size: state?.product_specification?.size || 0,
        body_type: state?.sizing?.body_type || 0,
        chest: state?.sizing?.chest || 0,
        height: state?.sizing?.height || 0,
        hips: state?.sizing?.hips || 0,
        shoulder: state?.sizing?.shoulder || 0,
        length: state?.sizing?.length || 0,
        sleeves: state?.sizing?.sleeves || 0,
        waist: state?.sizing?.waist || 0,
        weight: state?.sizing?.weight || 0,
        remarks: state?.special_request || "",
        discount: state?.total_discount || 0,
        total_amount: state?.total_amount || 0,
        name: state?.product?._id || "",
        hardware: state?.hardware,
        lining: state?.lining,
        leather_profile: state?.product_specification?.leather_id?._id,
        pollyfill: state?.pollyfill,
        rib: state?.rib,
        userName: state?.user_id?.name || "",
        gender: state?.user_id?.gender || "",
        email: state?.user_id?.email || "",
        phone: state?.user_id?.phone || "",
        address: state?.user_id?.address || "",
        advance: state?.advance_amount || "",
        // reminning_amonut: state?.reminning_amonut || "",
        delivery_date: Moment(state?.delivery_date, "YYYY-MM-DD") || "",
        leather_size: state?.product_specification?.size,
        reminning_amonut: state?.total_amount - state?.advance_amount - state?.total_discount,
        // discount: state?.total_discount,
      });

      const filtredProduct = data?.filter((product: any) => product._id === state?.product?._id);

      setSelectedProduct(filtredProduct?.[0]);

      const filtredLeather = filtredProduct?.[0]?.product_specification?.filter(
        (item: any) => item?.leather_id?._id === state?.product_specification?.leather_id?._id
      );
      setSelectedLeather(filtredLeather?.[0]);
    } else {
      form.setFieldsValue({
        userName: state?.name,
        phone: state?.phone,
        email: state?.email,
        gender: state?.gender,
        address: typeof state?.address === "object" ? state?.address?.city : state?.address,
      });
    }
  }, [state, data]);

  const handleDateChange = (value: any) => {
    const utcDateValue = value?.utc();
    setDaysLeft(utcDateValue?.startOf("day").diff(moment().startOf("day"), "days"));
  };
  useEffect(() => {
    handleDateChange(moment(state?.deliver_date).startOf("day"));
  }, [state?.delivery_date]);
  useEffect(() => {
    if (selectedLeather) {
      const sizeOption = selectedLeather?.size_range?.map((item: any) => {
        return {
          value: item.size,
          title: availableSizes?.find((as) => as.value === item.size)?.name,
        };
      });
      sizeOption?.sort((a: any, b: any) => a?.value - b?.value);
      setSize(sizeOption);
    }
  }, [selectedLeather]);

  useEffect(() => {
    // getting selected products leather
    const leather = selectedProduct?.product_specification?.map((leather: any) => {
      return {
        value: leather.leather_id?._id,
        title: leather?.leather_id?.item_name,
      };
    });
    setLeather(leather);

    if (!selectedProduct?.pollyfill) {
      form.setFieldsValue({
        pollyfill: false,
      });
    }
    if (!selectedProduct?.rib) {
      form.setFieldsValue({
        rib: false,
      });
    }
    // getting selected products hardware
    const hardware = selectedProduct?.hardware?.map((item: any) => {
      return {
        value: item,
        title: item,
      };
    });
    setHardware(hardware);

    // getting selected products lining
    const lining = selectedProduct?.lining?.map((item: any) => {
      return {
        value: item,
        title: item,
      };
    });
    setLining(lining);
  }, [selectedProduct]);

  //otp code
  const handleOptChange = (e: any) => {
    setOtpCode(parseInt(e?.target?.value));
  };
  // form subbmitted error
  const handleFormSubmitError = (e: any) => {
    const isError = {
      userInfo: false,
      productInfo: false,
      bodyMeasurement: false,
      remarks: false,
      price: false,
    };
    e?.errorFields?.map((field: any) => {
      if (userInfoItem.includes(field?.name[0])) {
        isError.userInfo = true;
      }
      if (productInfoItem.includes(field?.name[0])) {
        isError.productInfo = true;
      }
      if (bodyMeasurementItem.includes(field?.name[0])) {
        isError.bodyMeasurement = true;
      }
      if (priceItem.includes(field?.name[0])) {
        isError.price = true;
      }
      if (field?.name[0] === "remarks") {
        isError.remarks = true;
      }
    });
    setErrorSection(isError);
    window.scrollTo(0, 0);
  };

  // handling form submit
  const handleFormSubmit = async () => {
    setOtpLoading(true);
    await getOpt()
      .then(() => {
        setOtpVisible(true);
      })
      .catch(() => {
        message.error("Error while requesting OTP");
      });
    setOtpLoading(false);
  };

  const handleSubmitModal = async () => {
    setErrorSection({
      userInfo: false,
      productInfo: false,
      bodyMeasurement: false,
      remarks: false,
      price: false,
    });
    const formData = form?.getFieldsValue();

    // leather_size
    const filtredSize = selectedLeather?.size_range?.filter(
      (item: any) => item?.size === formData?.leather_size
    );

    const data: any = {
      product_specification: {
        price: {
          currency: filtredSize[0]?.price[0]?.currency,
          value: filtredSize[0]?.price[0]?.value,
        },
        status: selectedLeather?.status,
        secondary_image: [...selectedLeather?.secondary_image],
        product_specification_id: selectedLeather?._id,
        size_range_id: sizeRangeId,
        leather_id: selectedLeather?.leather_id?._id,
        size: filtredSize[0]?.size,
      },
      pollyfill: formData?.pollyfill,
      rib: formData?.rib,
      delivery_date: Moment(formData?.delivery_date).format(),
      gift: false,
      user_id: state?._id,
      product: formData?.name,
      hardware: formData?.hardware,
      lining: formData?.lining,
      special_request: formData?.remarks,
      sizing: {
        height: parseFloat(formData?.height),
        shoulder: parseFloat(formData?.shoulder),
        waist: parseFloat(formData?.waist),
        length: parseFloat(formData?.length),
        arms: parseFloat(formData?.arms),
        hips: parseFloat(formData?.hips),
        chest: parseFloat(formData?.chest),
        weight: parseFloat(formData?.weight),
        sleeves: parseFloat(formData?.sleeves),
        body_type: formData?.body_type,
        note: "",
      },
      from_store: true,
      for_store: false,
      advance_amount: formData?.advance,
      total_discount: parseInt(formData?.discount),
      total_amount: parseInt(formData?.total_amount),
      otp: otpCode,
      shipping_details: {
        full_name: state?.name,
        phone_number: state?.phone,
        email: state?.email,
        country: state?.country ?? "",
        city: state?.city ?? "",
        address: state?.address,
        landmark: state?.landmark ?? "",
      },
      origin: Origin.CUSTOMER
    };

    if (state?.product) {
      data._id = state?._id;
      data.user_id = state?.user_id?._id;
    }
    await createOrder(data);
  };
  useEffect(() => {
    if (!isFetching) {
      const product = data?.map((product: any) => {
        return { value: product._id, title: product.name };
      });
      setProducts(product);
    }
  }, [data, state]);

  useEffect(() => {
    console.log("here");
    if (data) {
      const product = data?.map((product: any) => {
        return { value: product._id, title: product.name };
      });
      setProducts(product);
    }
  }, []);

  // handeling select change value
  const handelChangeSelect = (value: any, fieldName: string) => {
    console.log(fieldName);
    // if (fieldName == "name") {
    //   form.setFieldsValue({
    //     leather_size: null,
    //     leather_profile: null,
    //   });
    // }
    form.setFieldsValue({
      [fieldName]: value,
    });

    if (fieldName === "leather_size") {
      const filtredLeather = selectedProduct?.product_specification?.find(
        (item: any) => item?.leather_id?._id === form.getFieldsValue()?.leather_profile
      );
      const selectedSize = filtredLeather?.size_range?.find((item: any) => {
        return item?.size === value;
      });

      form.setFieldsValue({
        total_amount: selectedSize?.price[0]?.value,
      });
      setSizeRangeId(selectedSize?._id);
      handleDiscountChange();
    }

    // on leather profile value change getting primary and secondary image from leather profile
    if (fieldName === "leather_profile") {
      const filtredLeather = selectedProduct?.product_specification?.filter(
        (item: any) => item?.leather_id?._id === value
      );
      setSelectedLeather(filtredLeather?.[0]);
    }

    // on product name change getting product specification
    if (fieldName === "name") {
      const selectedProduct = data?.filter((product: any) => product._id === value);
      // form.setFieldValue({
      //   leather_size: "",
      //   leather_profile: "",
      // });
      form.resetFields(["leather_size", "leather_profile"]);
      setSelectedProduct(selectedProduct?.[0]);
    }
  };

  const handleAmountChange = (e: any) => {
    const formValues = form.getFieldsValue();
    const advance = parseInt(e?.target?.value) || 0;
    const discount_amount = parseInt(formValues?.discount) || 0;
    const total_amount = parseInt(formValues?.total_amount) || 0;
    const amountAfterDiscount = total_amount - discount_amount;
    const remaining_amount = amountAfterDiscount - advance;
    form.setFieldsValue({
      reminning_amonut: remaining_amount,
    });
  };

  const handleDiscountChange = () => {
    console.log("discount change");
    const formValues = form.getFieldsValue();
    const discount_amount = parseInt(formValues?.discount) || 0;
    const total_amount = parseInt(formValues?.total_amount) || 0;

    const amountAfterDiscount = total_amount - discount_amount;
    const advance: any = Math.round(amountAfterDiscount * 0.4);
    setAdvanceAmount(advance);
    setTotalAmount(amountAfterDiscount);
    const remaining_amount = amountAfterDiscount - advance;
    form.setFieldsValue({
      reminning_amonut: remaining_amount,
      advance: advance,
    });
  };

  return (
    <div className="store-order">
      <h2 className="page-title">Create Order</h2>
      {data?.length > 0 && products?.length > 0 && !isFetching && (
        <Form
          className="store-order__content"
          colon={false}
          requiredMark={false}
          labelCol={{ xl: 5, lg: 9, md: 7, sm: 8, xs: 12 }}
          labelAlign={"left"}
          labelWrap={true}
          form={form}
          onFinish={() => handleFormSubmit()}
          onFinishFailed={(e) => handleFormSubmitError(e)}
          wrapperCol={{ xl: 19, lg: 15, md: 17, sm: 16, xs: 12 }}
        >
          <Row className="select-container">
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
                {
                  pattern: /^(?:\+977)?[9][6-9]\d{8}$/,
                  message: "Please enter valid phone number",
                },
              ]}
            >
              <Input className="input-field" placeholder="Contact Number" />
            </Form.Item>
          </Row>

          <Collapse
            bordered={false}
            defaultActiveKey={["1", "2", "3", "4", "5"]}
            style={{ marginTop: 50 }}
            ghost
          >
            {/* panel 1 */}
            <Panel
              header="1. Customer Details"
              key="1"
              extra={
                errorSection?.userInfo ? (
                  <div>
                    <ExclamationOutlined />
                  </div>
                ) : (
                  ""
                )
              }
            >
              <Card className="product-info white-border">
                <Row gutter={50}>
                  <Col lg={12} md={24} sm={24} xl={12}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Full Name'
                      label={<LabelComponent>Full Name</LabelComponent>}
                      name="userName"
                      rules={[
                        {
                          required: true,
                          message: "Please input Full Name!",
                        },
                      ]}
                    >
                      <Input disabled className="input-field white-border" />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={24} sm={24} xl={12}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Gender'
                      label={<LabelComponent>Gender</LabelComponent>}
                      name="gender"
                      rules={[
                        {
                          required: true,
                          message: "Please input Gender!",
                        },
                      ]}
                    >
                      <Radio.Group disabled>
                        <Tooltip placement="top" title="Male">
                          <Radio.Button value="male">
                            <Image
                              preview={false}
                              src={require("../../Assets/Icons/male.png")}
                              alt="male icon"
                            />
                          </Radio.Button>
                        </Tooltip>
                        <Tooltip placement="top" title="Female">
                          <Radio.Button value="female">
                            <Image
                              preview={false}
                              src={require("../../Assets/Icons/female.png")}
                              alt="male icon"
                            />
                          </Radio.Button>
                        </Tooltip>
                        <Tooltip placement="top" title="Others">
                          <Radio.Button value="others">
                            <Image
                              preview={false}
                              src={require("../../Assets/Icons/none.png")}
                              alt="male icon"
                            />
                          </Radio.Button>
                        </Tooltip>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={50}>
                  <Col lg={12} md={24} sm={24} xl={12}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Email ID'
                      label={<LabelComponent>Email ID</LabelComponent>}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input Email ID!",
                        },
                      ]}
                    >
                      <Input disabled required className="input-field white-border" />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Address'
                      label={<LabelComponent>Address</LabelComponent>}
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Please input Address!",
                        },
                      ]}
                    >
                      <Input disabled className="input-field white-border" />
                      {/* <Autocomplete
                        apiKey={environment.googleApiKey}
                        onPlaceSelected={(place) =>
                          form.setFieldsValue({
                            address: place?.formatted_address,
                          })
                        }
                        options={{
                          types: ["geocode", "establishment"],
                          // componentRestrictions: { country },
                        }}
                        className="input-field white-border googl-search"
                        aria-disabled={true}
                      /> */}
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Panel>

            {/* panel 2 */}
            <Panel
              header="2. Product Information"
              key="2"
              extra={
                errorSection?.productInfo ? (
                  <div>
                    <ExclamationOutlined />
                  </div>
                ) : (
                  ""
                )
              }
            >
              <Card className="product-info white-border">
                <Row>
                  <Col sm={24} lg={14}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Product Name'
                      label={<LabelComponent>Product Name</LabelComponent>}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input Product Name!",
                        },
                      ]}
                    >
                      {/* <InputSelect
                      defaultValue={selectedProduct?.name}
                      option={products}
                      handleChange={handelChangeSelect}
                      fieldName={"name"}
                    /> */}
                      <Select
                        showSearch
                        filterOption={(inputValue, category: any) => {
                          if (category) {
                            return (
                              category?.children
                                // .join("")
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            );
                          }
                        }}
                        onChange={(e) => {
                          handelChangeSelect(e, "name");
                        }}
                        style={{ minWidth: 300 }}
                      >
                        {products?.map((product: any) => {
                          return (
                            <Select.Option value={product?.value}>{product?.title}</Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Leather Profile'
                      label={<LabelComponent>Leather Profile</LabelComponent>}
                      name="leather_profile"
                      rules={[
                        {
                          required: true,
                          message: "Please input Leather Profile!",
                        },
                      ]}
                    >
                      {/* <InputSelect
                      defaultValue={selectedLeather?.leather_id?.item_name || ""}
                      option={leather}
                      handleChange={handelChangeSelect}
                      fieldName={"leather_profile"}
                    /> */}
                      <Select
                        style={{ minWidth: 300 }}
                        showSearch
                        filterOption={(inputValue, category: any) => {
                          if (category) {
                            return (
                              category?.children
                                // .join("")
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            );
                          }
                        }}
                        onChange={(e) => {
                          handelChangeSelect(e, "leather_profile");
                          form.setFieldValue('leather_size', undefined);
                        }}
                      >
                        {leather?.map((product: any, index:number) => {
                          return (
                            <Select.Option key={index} value={product?.value}>{product?.title}</Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    {/* <Form.Item
                      shouldUpdate
                      wrapperCol={{
                        span: 24,
                      }}
                    >
                      {() => {
                        return ( */}
                    <Form.Item
                      style={{
                        // display: "flex",
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Leather Size'
                      label={<LabelComponent>Size</LabelComponent>}
                      name="leather_size"
                      rules={[
                        {
                          required: true,
                          message: "Please input Leather Size!",
                        },
                      ]}
                    >
                      {/* <InputSelect
                            defaultValue={state?.leather_size || ""}
                            option={size}
                            handleChange={handelChangeSelect}
                            fieldName={"leather_size"}
                          /> */}
                      <Select
                        showSearch
                        filterOption={(inputValue, category) =>
                          Number(category?.value) === Number(inputValue)
                        }
                        onChange={(e) => {
                          handelChangeSelect(e, "leather_size");
                          const test = selectedLeather.size_range.filter(
                            (size: any) => size.size === e
                          );
                          setSizeRangeId(test?.[0]?._id);
                        }}
                        style={{ minWidth: 300 }}
                      >
                        {size?.map((product: any) => {
                          return (
                            <Select.Option value={product?.value}>{product?.value}</Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    {/* );
                      }}
                    </Form.Item> */}

                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Hardware'
                      label={<LabelComponent>Hardware</LabelComponent>}
                      name="hardware"
                      rules={[
                        {
                          required: true,
                          message: "Please input Hardware!",
                        },
                      ]}
                    >
                      <InputSelect
                        defaultValue={state?.hardware || ""}
                        option={hardware}
                        handleChange={handelChangeSelect}
                        fieldName={"hardware"}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Lining'
                      label={<LabelComponent>Lining</LabelComponent>}
                      name="lining"
                      rules={[
                        {
                          required: true,
                          message: "Please input Hardware!",
                        },
                      ]}
                    >
                      <InputSelect
                        defaultValue={state?.lining || ""}
                        option={lining}
                        handleChange={handelChangeSelect}
                        fieldName={"lining"}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Polyfill'
                      label={<LabelComponent>Polyfill</LabelComponent>}
                      name="pollyfill"
                      rules={[
                        {
                          required: true,
                          message: "Please input polyfill!",
                        },
                      ]}
                    >
                      {selectedProduct?.pollyfill ? (
                        // <InputSelect
                        //   defaultValue={state?.pollyfill || ""}
                        //   option={selectOptions}
                        //   handleChange={handelChangeSelect}
                        //   fieldName={"polyfill"}
                        // />
                        <Select
                          onChange={(e) => {
                            handelChangeSelect(e, "polyfill");
                          }}
                          style={{ minWidth: 300 }}
                        >
                          {selectOptions?.map((product: any) => {
                            return (
                              <Select.Option value={product?.value}>{product?.title}</Select.Option>
                            );
                          })}
                        </Select>
                      ) : (
                        <p
                          style={{
                            display: "flex",
                            marginTop: 20,
                          }}
                        >
                          No
                        </p>
                      )}
                    </Form.Item>

                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Rib'
                      label={<LabelComponent>Rib</LabelComponent>}
                      name="rib"
                      rules={[
                        {
                          required: true,
                          message: "Please input Rib!",
                        },
                      ]}
                    >
                      {selectedProduct?.rib ? (
                        // <InputSelect
                        //   defaultValue={state?.rib || ""}
                        //   option={selectOptions}
                        //   handleChange={handelChangeSelect}
                        //   fieldName={"rib"}
                        // />
                        <Select
                          onChange={(e) => {
                            handelChangeSelect(e, "rib");
                          }}
                          style={{ minWidth: 300 }}
                        >
                          {selectOptions?.map((product: any) => {
                            return (
                              <Select.Option value={product?.value}>{product?.title}</Select.Option>
                            );
                          })}
                        </Select>
                      ) : (
                        <p
                          style={{
                            display: "flex",
                            marginTop: 20,
                          }}
                        >
                          No
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col
                    sm={24}
                    lg={10}
                    style={{
                      // display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {selectedProduct?.primary_image ? (
                      <Image
                        src={selectedProduct?.primary_image}
                        alt="profile"
                        preview={false}
                        style={{ width: "50%" }}
                        wrapperStyle={{ width: "100%", display: "flex", justifyContent: "center" }}
                      />
                    ) : (
                      <p>Please, select product and leather profile.</p>
                    )}
                  </Col>
                </Row>
              </Card>
            </Panel>

            {/* panel 3*/}
            <Panel
              header="3. Body Measurement"
              key="3"
              extra={
                errorSection?.bodyMeasurement ? (
                  <div>
                    <ExclamationOutlined />
                  </div>
                ) : (
                  ""
                )
              }
            >
              <Card className="price white-border order-form">
                <Row gutter={50}>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      style={{
                        // display: "flex",
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Base Size'
                      label={<LabelComponent>Base Size</LabelComponent>}
                      name="base_size"
                      rules={[
                        {
                          required: false,
                          message: "Please input Base Size!",
                        },
                      ]}
                    >
                      {/* <InputSelect
                      option={bodyTypeOption}
                      handleChange={handelChangeSelect}
                      fieldName={"base_size"}
                    /> */}
                      <Select
                        showSearch
                        style={{ width: "100%", minWidth: 300 }}>
                        {availableSizes?.map((product: any) => {
                          return (
                            <Select.Option value={product?.value}>{product?.value}</Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={24} xl={12}>
                    <Form.Item
                      style={{
                        // display: "flex",
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Armhole'
                      label={<LabelComponent>Armhole</LabelComponent>}
                      name="armhole"
                      rules={[
                        {
                          required: false,
                          message: "Please input Armhole!",
                        },
                        {
                          pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                          message: "must be positive number",
                        },
                      ]}
                    >
                      <Input type="number" className="input-field white-border" />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      style={{
                        marginBottom: 30,
                        alignItems: "center",
                      }}
                      // label='Height'
                      label={<LabelComponent>Height</LabelComponent>}
                      name="height"
                      rules={[
                        {
                          required: true,
                          message: "Please input Height!",
                        },
                        {
                          pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                          message: "must be positive number",
                        },
                      ]}
                    >
                      <NumberInput
                        type="number"
                        className="input-field white-border"
                        suffix="in/ft"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      style={{
                        marginBottom: 30,
                        alignItems: "center",
                      }}
                      // label='Shoulder'
                      label={<LabelComponent>Shoulder</LabelComponent>}
                      name="shoulder"
                      rules={[
                        {
                          required: false,
                          message: "Please input Shoulder!",
                        },
                        {
                          pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                          message: "must be positive number",
                        },
                      ]}
                    >
                      <NumberInput
                        suffix="in/ft"
                        type="number"
                        className="input-field white-border"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={50}>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Weight'
                      label={<LabelComponent>Weight</LabelComponent>}
                      name="weight"
                      rules={[
                        {
                          required: true,
                          message: "Please input Weight!",
                        },
                        {
                          pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                          message: "must be positive number",
                        },
                      ]}
                    >
                      <NumberInput
                        suffix="Kg."
                        type="number"
                        className="input-field white-border"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Length'
                      label={<LabelComponent>Length</LabelComponent>}
                      name="length"
                      rules={[
                        {
                          required: false,
                          message: "Please input Length!",
                        },
                        {
                          pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                          message: "must be positive number",
                        },
                      ]}
                    >
                      <NumberInput
                        suffix="in/ft"
                        type="number"
                        className="input-field white-border"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={50}>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Arms'
                      label={<LabelComponent>Arms</LabelComponent>}
                      name="arms"
                      rules={[
                        {
                          required: false,
                          message: "Please input Arms!",
                        },
                        {
                          pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                          message: "must be positive number",
                        },
                      ]}
                    >
                      <NumberInput
                        suffix="in/ft"
                        type="number"
                        className="input-field white-border"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Hips'
                      label={<LabelComponent>Hips</LabelComponent>}
                      name="hips"
                      rules={[
                        {
                          required: false,
                          message: "Please input Hips!",
                        },
                        {
                          pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                          message: "must be positive number",
                        },
                      ]}
                    >
                      <NumberInput
                        suffix="in/ft"
                        type="number"
                        className="input-field white-border"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={50}>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Chest'
                      label={<LabelComponent>Chest</LabelComponent>}
                      name="chest"
                      rules={[
                        {
                          required: false,
                          message: "Please input Chest!",
                        },
                        {
                          pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                          message: "must be positive number",
                        },
                      ]}
                    >
                      <NumberInput
                        suffix="in/ft"
                        type="number"
                        className="input-field white-border"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Waist'
                      label={<LabelComponent>Waist</LabelComponent>}
                      name="waist"
                      rules={[
                        {
                          required: false,
                          message: "Please input Waist!",
                        },
                        {
                          pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                          message: "must be positive number",
                        },
                      ]}
                    >
                      <NumberInput
                        suffix="in/ft"
                        type="number"
                        className="input-field white-border"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={50}>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Sleeves'
                      label={<LabelComponent>Sleeves</LabelComponent>}
                      name="sleeves"
                      rules={[
                        {
                          required: false,
                          message: "Please input Sleeves!",
                        },
                        {
                          pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                          message: "must be positive number",
                        },
                      ]}
                    >
                      <NumberInput
                        suffix="in/ft"
                        type="number"
                        className="input-field white-border"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={50}>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Body Type'
                      label={<LabelComponent>Fit Preference</LabelComponent>}
                      name="body_type"
                      rules={[
                        {
                          required: true,
                          message: "Please input Fit Preference!",
                        },
                      ]}
                    >
                      {/* <InputSelect
                      option={bodyTypeOption}
                      handleChange={handelChangeSelect}
                      fieldName={"body_type"}
                    /> */}
                      <Select
                        showSearch
                        onChange={(e) => {
                          handelChangeSelect(e, "body_type");
                        }}
                        style={{ minWidth: 300 }}
                      >
                        {bodyTypeOption?.map((product: any) => {
                          return (
                            <Select.Option value={product?.value}>{product?.title}</Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Panel>

            {/* panel 4*/}
            <Panel
              header="4. Remarks"
              key="4"
              extra={
                errorSection?.remarks ? (
                  <div>
                    <ExclamationOutlined />
                  </div>
                ) : (
                  ""
                )
              }
            >
              <Card className="remarks white-border">
                <Form.Item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 30,
                  }}
                  // label='Remarks'
                  label={<LabelComponent>Remarks</LabelComponent>}
                  name="remarks"
                >
                  <Input.TextArea className="text-area" rows={4} placeholder="Add Remarks" />
                </Form.Item>
              </Card>
            </Panel>

            {/* panel 5*/}
            <Panel
              header="5. Payment"
              key="5"
              extra={
                errorSection?.price ? (
                  <div>
                    <ExclamationOutlined />
                  </div>
                ) : (
                  ""
                )
              }
            >
              <Card className="price white-border">
                <Row gutter={50}>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Total Amount'
                      label={<LabelComponent>Total Amount</LabelComponent>}
                      name="total_amount"
                      rules={[
                        {
                          required: true,
                          message: "Please input Total Amount!",
                        },
                      ]}
                      wrapperCol={{
                        lg: 16,
                        md: 16,
                        sm: 12,
                      }}
                      labelCol={{
                        lg: 8,
                        md: 8,
                        sm: 12,
                      }}
                    >
                      <Input
                        disabled
                        onChange={handleAmountChange}
                        className="input-field white-border"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      style={{
                        // display: "flex",
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      label="Discount"
                      // label={<LabelComponent>Discount</LabelComponent>}
                      name="discount"
                      wrapperCol={{
                        lg: 8,
                        md: 8,
                        sm: 6,
                      }}
                      labelCol={{
                        lg: 8,
                        md: 8,
                        sm: 12,
                      }}
                      rules={[
                        {
                          pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                          message: "Discount must be positive number",
                        },
                      ]}
                    >
                      <Input
                        // controls={false}
                        min={0}
                        onBlur={(e) => {
                          if (parseInt(e.currentTarget?.value) < 0) {
                            return;
                          }
                          discountPercent
                            ? form.setFieldsValue({
                                discount:
                                  (parseFloat(form.getFieldsValue()?.total_amount) *
                                    parseFloat(e.currentTarget.value)) /
                                    100 || 0,
                              })
                            : form.setFieldsValue({ discount: e?.currentTarget?.value || 0 });
                          handleDiscountChange();
                        }}
                        className="input-field white-border"
                      />
                    </Form.Item>
                    <Select
                      size="large"
                      style={{ width: 100, position: "absolute", right: 30, top: 0 }}
                      defaultActiveFirstOption
                      className="neomorphic--select"
                      onChange={(e) => setDiscountPercent(e === "percent")}
                      defaultValue="amount"
                    >
                      <Select.Option value="amount">NRS</Select.Option>
                      <Select.Option value="percent">%</Select.Option>
                    </Select>
                    <Form.Item
                      hidden
                      // style={{
                      //   display: "flex",
                      //   alignItems: "center",
                      //   marginBottom: 30,
                      // }}
                      // label='Discount'
                      label={<LabelComponent>Discount</LabelComponent>}
                      name="discount"
                      rules={[
                        {
                          required: false,
                          message: "Please input Discount!",
                        },
                      ]}
                    >
                      <Input
                        min={0}
                        onChange={handleDiscountChange}
                        className="input-field white-border"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={50}>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      shouldUpdate
                      wrapperCol={{
                        span: 24,
                      }}
                    >
                      {() => (
                        <div style={{ width: "100%" }}>
                          <Form.Item
                            style={{
                              // display: "flex",
                              alignItems: "center",
                              marginBottom: 30,
                            }}
                            label="Advance"
                            // label={<>Advance</LabelComponent>}
                            name="advance"
                            wrapperCol={{
                              lg: 16,
                              md: 16,
                              sm: 12,
                            }}
                            labelCol={{
                              lg: 8,
                              md: 8,
                              sm: 12,
                            }}
                            rules={[
                              {
                                required: true,

                                message: "Please input Advance!",
                              },
                              {
                                validator: (_, value) => {
                                  return parseFloat(value) <= totalAmount &&
                                    parseFloat(value) >= advanceAmount
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error(
                                          parseFloat(value) > totalAmount
                                            ? `Advance amount cannot be more then ${totalAmount}`
                                            : `Advance amount must be more then ${advanceAmount}`
                                        )
                                      );
                                },
                              },
                            ]}
                          >
                            <Input
                              onChange={handleAmountChange}
                              className="input-field white-border"
                            />
                          </Form.Item>
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      // label='Left To Pay'
                      label={<LabelComponent>Left To Pay</LabelComponent>}
                      name="reminning_amonut"
                      wrapperCol={{
                        lg: 16,
                        md: 16,
                        sm: 12,
                      }}
                      labelCol={{
                        lg: 8,
                        md: 8,
                        sm: 12,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please input Left to pay!",
                        },
                      ]}
                    >
                      <Input disabled className="input-field white-border" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={50}>
                  <Col xl={12} lg={12} md={24} sm={24}>
                    <Form.Item
                      style={{
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                      wrapperCol={{
                        lg: 16,
                        md: 16,
                        sm: 12,
                      }}
                      labelCol={{
                        lg: 8,
                        md: 8,
                        sm: 12,
                      }}
                      // label='Deliver Date'
                      label="Deliver Date"
                      name="delivery_date"
                      rules={[
                        {
                          required: true,
                          message: "Please input Deliver Date!",
                        },
                      ]}
                    >
                      <DatePicker
                        disabledDate={(current: any) => disableBackDateFromToday(current)}
                        onChange={handleDateChange}
                      />
                    </Form.Item>
                  {daysLeft ? (
                    <Typography.Text className="delivery_date-warning">
                      {daysLeft} days left to deliver
                    </Typography.Text>
                  ) : (
                    ""
                  )}
                  </Col>
                </Row>
              </Card>
            </Panel>
          </Collapse>

          {/* buttons row */}
          <Row className="button-container">
            <Button onClick={() => navigate(-1)} className="cancel-btn">
              Cancel
            </Button>
            <Button
              type="primary"
              className="main-btn"
              htmlType="submit"
              loading={isLoading || otpLoading}
            >
              {state?._id ? "Submit" : "Update"}
              {/* {isLoading || otpLoading ? <Spin size="small" /> : "Submit"} */}
            </Button>
          </Row>
        </Form>
      )}
      <SuccessModel visible={visible} handleClose={handleClose} />
      <Modal
        title="OTP Verification"
        visible={otpVisible}
        onOk={handleSubmitModal}
        okText="Submit"
        onCancel={handleClose}
        okButtonProps={{
          loading: isLoading,
        }}
      >
        <Typography.Text style={{ fontWeight: "bold" }}>Please Enter OTP: </Typography.Text>
        <Form onFinish={handleSubmitModal} className="store-order otp-form">
          {/* <Input maxLength={1} className="input-field" />
          <Input maxLength={1} className="input-field" />
          <Input maxLength={1} className="input-field" />
          <Input maxLength={1} className="input-field" />
          <Input maxLength={1} className="input-field" />
          <Input maxLength={1} className="input-field" /> */}
          <Input maxLength={6} className="input-field" onChange={handleOptChange} />
          <Button htmlType="submit" hidden>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

//dummy  commit
