/** @format */

import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Switch,
  Typography,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC, useState } from 'react'
import UploadFiles from '../UploadFiles/UploadFiles'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { sm: 24, md: 10 },
}

const spanTwenty = {
  labelCol: { span: 8 },
  wrapperCol: { sm: 24, md: 16 },
}
const fullSpanLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
}
const CreateCampaign: FC = () => {
  const [value1, setValue1] = useState()
  const [form] = useForm()
  const onFinish = (values: any) => {}

  function onChange(checked: any) {}
  return (
    <>
      <Typography.Title level={1}>CAMPAIGN</Typography.Title>
      <Card style={{ padding: '0 200px' }}>
        <Form layout='vertical' form={form} onFinish={onFinish}>
          <Typography.Title level={3}>CREATE</Typography.Title>
          <Row>
            <Col span={12}>
              <Form.Item
                {...spanTwenty}
                label='title'
                name='Title'
                rules={[{ required: true, message: 'Title is required.' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...spanTwenty}
                label='Sub Text'
                name='Sub Text'
                rules={[{ required: true, message: 'Sub Text is required.' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            {...layout}
            label='Valid To - Valid From'
            name='Valid To - Valid From'
            rules={[
              {
                required: true,
                message: 'Valid To - Valid From is required.',
              },
            ]}
          >
            {/* <DatePicker
              format='YYYY-MM-DD HH:mm:ss'
              //disabledDate={disabledDate}
              //disabledTime={disabledDateTime}
              //showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
            /> */}
          </Form.Item>
          <Form.Item
            {...fullSpanLayout}
            label='descripton'
            name='descripton'
            rules={[{ required: true, message: 'Descripton is required.' }]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item
            {...fullSpanLayout}
            name='Status'
            rules={[{ required: true, message: 'Status is required.' }]}
          >
            <Radio.Group
              onChange={(e) => setValue1(e.target.value)}
              value={value1}
            >
              <Radio value='Published'>Published</Radio>
              <Radio value='Draft'>Draft</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item {...fullSpanLayout}>
            Free Delivery?
            <Switch style={{ marginLeft: 20 }} onChange={onChange} />
          </Form.Item>
          <Form.Item
            {...fullSpanLayout}
            name='files'
            rules={[{ required: true, message: 'Descripton is required.' }]}
          >
            <UploadFiles />
          </Form.Item>
          <Form.Item {...fullSpanLayout} name='buttons'>
            <Button
              type='primary'
              size='middle'
              shape='round'
              htmlType='submit'
            >
              Save
            </Button>
            <Button type='text'>Cancel</Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  )
}

export default CreateCampaign
