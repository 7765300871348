import { Col, Row } from "antd";
export const PostBuilder = ({ primaryMedia, data: mainData }: any) => {
  const Column = (props: any) => {
    const { data } = props;
    const myData = mainData.filter((d: any) => d.parentId === data.id);
    return (
      <Col lg={data.size} style={{ padding: 0 }}>
        {myData.length > 1 ? (
          <MyRow data={data} />
        ) : (
          <>
            <div
              style={{
                position: "relative",
                width: "calc(100% - 10px)",
                overflow: "hidden",
                margin: "5px",
                height: "calc(100% - 10px)",
                minHeight: 40,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  minHeight: 300,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <UploadIcon onClick={() => console.log('this is upload')} width={30} /> */}
                <img style={{ minWidth: "100%", minHeight: "100%" }} src={data?.url} />
              </div>
            </div>
          </>
        )}
      </Col>
    );
  };

  const MyRow = (props: any) => {
    const { data } = props;
    const myData = mainData.filter((d: any) => d.parentId === data.id);
    return (
      <Row
        className="align-items-stretch"
        // gutter={[6, 0]}
        style={{ position: "relative" }}
      >
        {/* <PlusCircleOutlined onClick={() => addColumn(data.id)} style={{ position: 'absolute', top: 0, zIndex: 2 }} /> */}
        {myData &&
          myData.length > 0 &&
          myData.map((columns: any, i: any) => {
            return <Column key={i} data={columns} />;
          })}
      </Row>
    );
  };
  console.log(mainData, "data");
  return (
    <Row className="align-items-center" style={{ height: "100%", margin: "40px 0px" }}>
      <Col lg={24}>
        {mainData &&
          mainData
            .filter((d: any) => !d.hasOwnProperty("parentId"))
            .map((rows: any, i: any) => {
              return <MyRow key={i} data={rows} />;
            })}
      </Col>
    </Row>
  );
};

export default PostBuilder;
