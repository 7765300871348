import {
  Avatar,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Row,
  Tabs,
  Radio,
  Typography,
  message,
} from "antd";
import React, { useEffect } from "react";
import bodyData from "../../Data/body.json";
import { BodyMeasure } from "../../Components/UserMeasurement/UserMeasurement";
import { useForm } from "antd/lib/form/Form";
// import { updateCustomer } from "Redux/CustomerRedux/CustomerActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getCustomer,
  getCustomerMeasurements,
  updateCustomerDetails,
  updateCustomerMeasurements,
} from "../../API/CustomerApi";
import { useMutation, useQuery } from "react-query";

export const UserDetailsEdit = (props: any) => {
  const { id } = useParams();
  const user = useSelector((state: any) => state.CustomerReducer);
  const { data: customerData, refetch } = useQuery(
    ["getCustomerDetail", { user_id: id }],
    getCustomer
  );
  const { data: measurementsData, refetch: refetchMeasurements } = useQuery(
    ["getCustomerMeasurements", { id: id }],
    getCustomerMeasurements
  );

  const { mutate, isSuccess } = useMutation({
    mutationFn: updateCustomerDetails,
  });

  const { mutate: updateMeasurements, isSuccess: updateMeasurementsSuccess } = useMutation({
    mutationFn: updateCustomerMeasurements,
  });

  const data = customerData?.data[0];
  const measurementData = measurementsData?.data[0];
  const [form] = useForm();
  const [formMeasurements] = useForm();

  const keys = Object.keys(bodyData) as Array<keyof BodyMeasure>;

  const handleSubmit = async (values: any) => {
    let userDetails = {
      ...data,
      name: values?.firstName + " " + values?.lastName,
      address: values?.address,
      phone: values?.phone,
      gender: values?.gender,
      _id: id,
    };
    await mutate(userDetails);
  };

  const handleMeasurementSubmit = (values: any) => {
    updateMeasurements({ ...values, user_id: id });
  };
  useEffect(() => {
    form.setFieldsValue({
      ...data,
      firstName: data?.name?.split(" ")[0],
      lastName: data?.name?.split(" ")[1],
    });
    formMeasurements.setFieldsValue({
      ...measurementData,
    });
  }, [data, measurementData]);

  useEffect(() => {
    if (isSuccess) {
      message.success("Updated customer successfully");
      refetch();
    }
    if (updateMeasurementsSuccess) {
      message.success("Updated customer measurements successfully");
      refetchMeasurements();
    }
  }, [isSuccess, updateMeasurementsSuccess]);

  return (
    <Row gutter={[20, 20]}>
      <Col lg={24}>
        <Typography.Title className="product-name" style={{ paddingBottom: 20 }}>
          EDIT PROFILE
        </Typography.Title>

        <Card style={{ padding: "20px 50px" }}>
          <Row justify="start">
            <Avatar
              size={140}
              style={{ marginBottom: 20 }}
              src={
                data?.profile_image ||
                "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"
              }
            />
          </Row>

          <Form
            onFinish={handleSubmit}
            layout="vertical"
            form={form}
            className="non-neumorphic"
            onReset={() => props?.onCancel()}
          >
            <Row gutter={[60, 20]}>
              <Col lg={12}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "First name is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Last name is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Phone is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Address"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Address is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Please select an gender!",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="male">Male</Radio>
                    <Radio value="female">Female</Radio>
                    <Radio value="others">Others</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col lg={12}>
                <Form.Item>
                  <Button
                    loading={user?.creating || user?.updating}
                    type="primary"
                    shape="round"
                    size="middle"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                  <Button disabled={user?.creating || user?.updating} type="text" htmlType="reset">
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Form
            form={formMeasurements}
            onFinish={handleMeasurementSubmit}
            layout="vertical"
            className="non-neumorphic"
            onReset={() => props?.onCancel()}
          >
            <Divider />
            <Typography.Title className="product-name" style={{ paddingLeft: 30 }}>
              SIZE
            </Typography.Title>
            <Descriptions
              column={3}
              colon={false}
              labelStyle={{
                textTransform: "uppercase",
                fontSize: "16x",
                minWidth: "150px",
                margin: "20px 0px",
                display: "flex",
              }}
              contentStyle={{
                width: "50px",
                display: "flex",
                alignItems: "center",
              }}
              style={{ marginLeft: 30 }}
            >
              {keys.map((key) => {
                return (
                  <Descriptions.Item span={1} label={key}>
                    {/* {bodyData[key]} */}
                    <Form.Item
                      // initialValue={bodyData[key]}
                      style={{ marginBottom: 0 }}
                      rules={[
                        ...(key === "height" || key === "weight"
                          ? [
                              {
                                required: true,
                                message: `${key} is required!`,
                              },
                            ]
                          : []),
                      ]}
                      name={key}
                    >
                      <Input style={{ width: 70 }} />
                    </Form.Item>
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
            <Form.Item>
              <Button
                loading={user?.creating || user?.updating}
                type="primary"
                shape="round"
                size="middle"
                htmlType="submit"
              >
                Save
              </Button>
              <Button disabled={user?.creating || user?.updating} type="text" htmlType="reset">
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default UserDetailsEdit;
