/** @format */

import { Button, Modal } from "antd";
import { FC, useState } from "react";

const PreviewModalButton: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(true);
  };
  return (
    <>
      <Button
        onClick={() => setIsModalVisible(true)}
        type='default'
        style={{ width: "100%", border: "1px solid #020202" }}
      >
        See Preview
      </Button>
      <Modal
        title='Mobile View'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Contents and Images</p>
      </Modal>
    </>
  );
};
export default PreviewModalButton;
