import { Col, Empty, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import Loading from "../Loading/Loading";

export const PageWrapper = (props: any) => {
  const { loading, detailing, deleting, creating, updating, empty } = props?.data;
  return (
    <Content style={{ minHeight: "70vh" }}>
      <Row justify="center" style={{ minHeight: "70vh" }} align="middle">
        <Col span={24}>
          {loading || detailing || deleting || updating || creating || updating ? (
            <Loading title="Loading ..." />
          ) : (
            <>{empty ? <Empty /> : props.children}</>
          )}
        </Col>
      </Row>
    </Content>
  );
};
