import {
  AlignLeftOutlined,
  DeleteFilled,
  DownOutlined,
  FileImageOutlined,
  SoundOutlined,
} from "@ant-design/icons";
import { Avatar, Col, Divider, Dropdown, Menu, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import "./BlogBlock.scss";
import { ReactComponent as DragIcon } from "../../../../../Assets/Icons/DragIcon.svg";
import { ReactComponent as LayoutIcon } from "../../../../../Assets/Icons/LayoutIcon.svg";
import BlogAudio from "../BlogAudio/BlogAudio";
import BlogImages from "../BlogImages/BlogImages";
import BlogText from "../BlogText/BlogText";
import Animate from "rc-animate";

export const BlogBlock = (props: any) => {
  const { dataIndex, value } = props;
  const menuClickHandler = (value: any) => {
    props?.onChangeType({ dIndex: dataIndex, type: value?.key });
  };
  const menu = (
    <Menu onClick={menuClickHandler}>
      <Menu.Item key="text" icon={<AlignLeftOutlined />}>
        Text
      </Menu.Item>
      <Menu.Item key="image" icon={<FileImageOutlined />}>
        Image
      </Menu.Item>
      {/* <Menu.Item key="audio" icon={<SoundOutlined />}>
        Audio
      </Menu.Item> */}
    </Menu>
  );

  const getBlogContent = (key: any) => {
    switch (key) {
      case "text":
        return <BlogText value={value} onChange={(e: any) => props.contentChange(e)} />;
      case "audio":
        return <BlogAudio onChange={(e: any) => props.imageChanges(e)} />;
      case "image":
        return (
          <BlogImages layout={props?.value?.content} onChange={(e: any) => props.imageChanges(e)} />
        );
      default:
        return <BlogText onChange={(e: any) => props.contentChange(e)} />;
    }
  };
  return (
    <Animate transitionAppear transitionEnter>
      <Content className="block-blog-wrapper">
        <Row justify="space-between" gutter={[20, 0]}>
          <Col lg={24}>
            <Row justify="space-between">
              <Col>
                <Dropdown overlay={menu} trigger={["hover"]}>
                  <Typography.Text
                    style={{
                      cursor: "pointer",
                      fontSize: 18,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DownOutlined style={{ fontSize: 14, marginRight: 10 }} />{" "}
                    <span style={{ textTransform: "capitalize" }}>{props?.value?.type}</span>
                  </Typography.Text>
                </Dropdown>
              </Col>
              <Col>
                <Space size="middle">
                  <a onClick={() => props?.removeBlock()}>
                    <Avatar
                      style={{
                        background: "#E3EDF7",
                        borderRadius: 15,
                        cursor: "pointer",
                      }}
                      shape="square"
                      className="neumorphify"
                      size={36}
                      icon={<DeleteFilled style={{ color: "#636770", fontSize: 18 }} />}
                    />
                  </a>

                  <Avatar
                    style={{
                      background: "#E3EDF7",
                      borderRadius: 15,
                      cursor: "grabbing",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    shape="square"
                    className="neumorphify"
                    size={36}
                    icon={<DragIcon />}
                  />

                  {props?.value?.type === "image" && (
                    <a onClick={() => props?.viewLayout()}>
                      <Avatar
                        style={{
                          background: "#E3EDF7",
                          borderRadius: 15,
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        shape="square"
                        className="neumorphify"
                        size={36}
                        icon={<LayoutIcon />}
                      />
                    </a>
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
          <Divider />
          <Col lg={24}>{getBlogContent(props?.value?.type)}</Col>
        </Row>
      </Content>
    </Animate>
  );
};

export default BlogBlock;
