import { API } from 'aws-amplify'
import { ApiHeaders, API_NAME } from './variable'

export const getExchangeRefunds = (params: any) =>
  API.get(API_NAME, '/order/exchange-and-refund-list', {
    headers: ApiHeaders,
    queryStringParameters: params,
  })
export const getExchangeRefundsDetails = (params: any) =>
  API.get(API_NAME, '/exchange-refunds/details', {
    headers: ApiHeaders,
    queryStringParameters: params,
  })
