/** @format */

import { Route, Routes } from 'react-router'
import withPermission from '../Utils/checkPermission'
import AddLeather from '../Views/Admin/LeatherProfile/AddLeather'
import LeatherProfile from '../Views/Admin/LeatherProfile/LeatherProfile'
import LeathertDetails from '../Views/Admin/LeatherProfile/LeathertDetails'

const LeatherProfileRoute = (props: any) => {
  return (
    <Routes>
      <Route path={`/*`} element={<LeatherProfile />} />
      <Route path={`/:id/*`} element={<LeathertDetails />} />
      <Route path={`/add`} element={<AddLeather />} />
    </Routes>
  )
}
export default withPermission(LeatherProfileRoute, 'leatherProfile')
