/** @format */

import { Content } from "antd/lib/layout/layout";
import "./NeuMorph.scss";

export const NeuMorph = (props: any) => {
  return <Content className='neu-morph-design'>{props.children}</Content>;
};

export default NeuMorph;
