import { API, Auth } from "aws-amplify";
import { ApiHeaders, INVENTORY_API_NAME } from "../../API/variable";
// import { API_NAME, ApiHeaders } from 'Api/Api'

//get Inventory Item
export const takeInventoryItem = async (id: string) => {
  const res = await API.get(
    INVENTORY_API_NAME,
    `/inventory_category/inventory_item_detail?item_id=${id}`,
    {
      headers: ApiHeaders,
    }
  );
  return res;
};

//get Inventory Item
export const makeInventoryItem = async (data: any) => {
  const res = await API.post(INVENTORY_API_NAME, "/inventory_category/add_inventory_item", {
    body: {
      ...data,
    },
    headers: ApiHeaders,
  });
  return res;
};

//create Inventory Item Track
export const makeInventoryItemTrack = async (data: any) => {
  const res = await API.post(INVENTORY_API_NAME, "/inventory_category/add_inventory_item_track", {
    body: {
      ...data,
    },
    headers: ApiHeaders,
  });
  return res;
};

//get Inventory Item Track
export const takeInventoryItemTrack = async (data: any) => {
  const params = `page=${data?.page}&limit=${data?.limit}&search=${data?.searchText}&sort=${data?.sortBy}`;
  const res = await API.get(
    INVENTORY_API_NAME,
    `/inventory_category/inventory_item_track_list?${params}`,
    {
      body: {
        ...data,
      },
      headers: ApiHeaders,
    }
  );
  return res;
};

//get Vendor of Inventory Item
export const takeVendorOfInventoryItem = async (data: any) => {
  const params = `inventory_item=${data?.inventory_item}&page=${data?.page}&limit=${data?.limit}`;

  const res = await API.get(
    INVENTORY_API_NAME,
    `/inventory_category/inventory_item_track_list?${params}`,
    {
      body: {
        ...data,
      },
      headers: ApiHeaders,
    }
  );

  return res;
};
