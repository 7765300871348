import { Content } from "antd/lib/layout/layout";
import React, { CSSProperties } from "react";
import "./Content.scss";

interface MyContentTypes {
  shadow?: boolean;
  style?: CSSProperties;
  className?: string;
}
export const MyContent: React.FC<MyContentTypes> = ({
  shadow,
  children,
  className,
  ...rest
}) => {
  return (
    <Content className={`primary__container ${className}`} {...rest}>
      {children}
    </Content>
  );
};

export default MyContent;
