import React from 'react'
import { Route, Routes } from 'react-router'
import withPermission from '../Utils/checkPermission'
import UserProfile from '../Views/Admin/Users/UserProfile/UserProfile'
import Users from '../Views/Admin/Users/Users'

export const UserRoute = (props: any) => {
  return (
    <Routes>
      <Route path={`/`} element={<Users />} />
      <Route path={`/:userId`} element={<UserProfile />} />
    </Routes>
  )
}

export default withPermission(UserRoute, 'user')
