import { API } from "aws-amplify";
import { ApiHeaders, API_NAME, BASE_URL } from "./variable";

//get user
export const fetchUsers = async (query: string) => {
  const res: any = await API.get(API_NAME, `/store_user/?phone=${query}`, {
    headers: ApiHeaders,
  });

  return res?.data;
};

//get product
export const fetchProducts = async () => {
  const res: any = await API.get(API_NAME, `/store_product?limit=0&page=0`, {
    headers: ApiHeaders,
  });

  return res?.data;
};

// create order
export const createOrder = async (data: any) => {
  const res: any = await API.post(API_NAME, `/store_order`, {
    body: {
      ...data,
    },
    headers: ApiHeaders,
  });
  return res?.data;
};
// update order
export const updateOrder = async (data: any) => {
  const res: any = await API.get(API_NAME, `/order_deliver/${data?._id}`, {
    headers: ApiHeaders,
  });
  return res?.data;
};

// get orders
export const fetchOrders = async (
  page: number,
  limit: number,
  ordererType: string,
  search: string
) => {
  // const query = isCustomer ? `customer_order=${true}` : `store_order=${true}`;
  const orderType = () => {
    switch (ordererType) {
      case "customerOrder":
        return `customer_order=${true}`;
      case "consumerOrder":
        return `consumer_order=${true}`;
      case "storeOrder":
        return `store_order=${true}`;
      default:
        return `customer_order=${true}`;
    }
  };
  const query = orderType();
  const res: any = await API.get(
    API_NAME,
    `/store_order?page=${page}&limit=${limit}&${query}&order_no=${
      parseInt(search) ? search : ""
    }&search=${parseInt(search) ? "" : search}`,
    {
      headers: ApiHeaders,
    }
  );
  return res?.data;
};

// get order
export const fetchOrder = async (id: string) => {
  const res: any = await API.get(API_NAME, `/store_order/${id}`, {
    headers: ApiHeaders,
  });
  return res?.data;
};

export const updateOrders = async (data: any) => {
  const res: any = await API.post(API_NAME, `/store_order/${data?._id}`, {
    body: {
      ...data,
    },
    headers: ApiHeaders,
  });
};

// get order
export const fetchTrackOrder = async (id: string) => {
  const res: any = await API.get(API_NAME, `/store_order_track/${id}`, {
    headers: ApiHeaders,
  });
  return res?.data;
};
export const getPaymentMode = async (id: string, query: string) => {
  const res: any = await API.get(API_NAME, `/order_deliver/${id}?payment_mode=${query}`, {
    headers: ApiHeaders,
  });
  return res?.data;
};
export const getOpt = async () => {
  const res: any = await API.get(API_NAME, "/order_otp", {
    headers: ApiHeaders,
  });
  return res?.data;
};
export const createNewUser = async (data: string) =>
  API.post(API_NAME, `/store_user/`, {
    headers: ApiHeaders,
    body: data,
  });
