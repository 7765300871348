/** @format */

import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Space,
  Card,
  Typography,
  Select,
  InputNumber,
  Spin,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./AddButtonSizing.scss";
import { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router";
import { PostJacketSizes, productSizeRequest } from "../../../Redux/Actions/StaticContentsActions";

const SizingForm = ({ history }: any) => {
  const [form] = useForm();
  const [countryCode, setCountryCode] = useState<string>("UK");
  const dispatch = useDispatch();

  const { productId } = useParams<any>();

  const ProductSizesPreview = useSelector((state: RootStateOrAny) => state.staticContents);
  const { ProductSizes, loading } = ProductSizesPreview;

  const onFinish = (values: any) => {
    dispatch(
      PostJacketSizes({
        sizes: values,
        _id: ProductSizes[0]?._id,
        category_id: ProductSizes[0]?.category_id,
        country: countryCode,
      })
    );
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  useEffect(() => {
    if (productId && !ProductSizes) {
      dispatch(productSizeRequest(productId));
    }
  }, [productId]);

  useEffect(() => {
    if (
      ProductSizes &&
      ProductSizes.length != 0 &&
      ProductSizes[0]?.sizes != null &&
      ProductSizes[0]?.sizes
    ) {
      form.setFieldsValue({ sizes: ProductSizes[0]?.sizes });
    }
  }, [ProductSizes, countryCode]);
  return (
    <Row>
      <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Title className="product-name" level={1}>
          SIZING
        </Typography.Title>
        <Select
          placeholder="Size: UK"
          allowClear
          showArrow
          onSelect={(value: string) => setCountryCode(value)}
          style={{ width: 181, borderRadius: "20px" }}
        >
          <Select.Option value="UK">Size: UK</Select.Option>
          <Select.Option value="US">Size: US</Select.Option>
        </Select>
      </Col>
      <Col span={16} style={{ marginTop: 30 }}>
        <Card>
          {loading ? (
            <Row justify="center" align="middle" style={{ height: "50vh" }}>
              <Col span={2}>
                <Spin size="large" />
              </Col>
            </Row>
          ) : (
            <Form name="dynamic_form_item" form={form} onFinish={onFinish} layout="vertical">
              <Form.List name={["sizes"]} {...formItemLayout}>
                {(fields, { add }) => {
                  return (
                    <>
                      {fields.map((field: any, index: any) => {
                        return (
                          <Row
                            gutter={[10, 20]}
                            key={field.key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="middle"
                            justify="space-between"
                          >
                            <Col span={4}>
                              <Form.Item
                                label={index === 0 ? `Size - ${countryCode}` : ""}
                                {...field}
                                name={[field.name, "name"]}
                                fieldKey={[field.fieldKey, "name"]}
                                rules={[{ required: true, message: "Required" }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                label={index === 0 ? "XS" : ""}
                                {...field}
                                name={[field.name, "XS"]}
                                fieldKey={[field.fieldKey, "XS"]}
                                rules={[{ required: true, message: "Required" }]}
                              >
                                <InputNumber
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#ebf5ff",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                label={index === 0 ? "S" : ""}
                                {...field}
                                name={[field.name, "S"]}
                                fieldKey={[field.fieldKey, "S"]}
                                rules={[{ required: true, message: "Required" }]}
                              >
                                <InputNumber
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#ebf5ff",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                label={index === 0 ? "M" : ""}
                                {...field}
                                name={[field.name, "M"]}
                                fieldKey={[field.fieldKey, "M"]}
                                rules={[{ required: true, message: "Required" }]}
                              >
                                <InputNumber
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#ebf5ff",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                {...field}
                                label={index === 0 ? "ML" : ""}
                                name={[field.name, "ML"]}
                                fieldKey={[field.fieldKey, "ML"]}
                                rules={[{ required: true, message: "Required" }]}
                              >
                                <InputNumber
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#ebf5ff",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                label={index === 0 ? "L" : ""}
                                {...field}
                                name={[field.name, "L"]}
                                fieldKey={[field.fieldKey, "L"]}
                                rules={[{ required: true, message: "Required" }]}
                              >
                                <InputNumber
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#ebf5ff",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                label={index === 0 ? "XL" : ""}
                                {...field}
                                name={[field.name, "XL"]}
                                fieldKey={[field.fieldKey, "XL"]}
                                rules={[{ required: true, message: "Required" }]}
                              >
                                <InputNumber
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#ebf5ff",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                label={index === 0 ? "XXL" : ""}
                                {...field}
                                name={[field.name, "XXL"]}
                                fieldKey={[field.fieldKey, "XXL"]}
                                rules={[{ required: true, message: "Required" }]}
                              >
                                <InputNumber
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#ebf5ff",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                label={index === 0 ? "XXXL" : ""}
                                {...field}
                                name={[field.name, "XXXL"]}
                                fieldKey={[field.fieldKey, "XXXL"]}
                                rules={[{ required: true, message: "Required" }]}
                              >
                                <InputNumber
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#ebf5ff",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Form.Item
                                label={index === 0 ? "4XL" : ""}
                                {...field}
                                name={[field.name, "4XL"]}
                                fieldKey={[field.fieldKey, "4XL"]}
                                rules={[{ required: true, message: "Required" }]}
                              >
                                <InputNumber
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#ebf5ff",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      })}
                      <Row align="middle" justify="end">
                        <Form.Item>
                          <Button
                            onClick={() => add()}
                            type="primary"
                            shape="circle"
                            size="small"
                            icon={<PlusOutlined />}
                          />
                        </Form.Item>
                      </Row>
                    </>
                  );
                }}
              </Form.List>
              <Form.Item>
                <Row>
                  <Col lg={24}>
                    <Space>
                      <Button type="primary" shape="round" htmlType="submit">
                        Save
                      </Button>
                      <Button type="text" onClick={() => history.push("/static-contents/sizing")}>
                        Cancel
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          )}
        </Card>
      </Col>
    </Row>
  );
};
export default SizingForm;
