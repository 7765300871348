/** @format */
import {
  Col,
  Row,
  Typography,
  Input,
  Space,
  Button,
  Select,
  Pagination,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { LatidoTable } from '../../../Components/Admin/Table/Table'
import { useCallback, useEffect, useRef, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import {
  GetStaticContentData,
  swapPosition,
} from '../../../Redux/Actions/StaticContentsActions'
import { useParams } from 'react-router'
import LinesEllipsis from 'react-lines-ellipsis'
import { ColumnsType } from 'antd/lib/table'
import ActionList from './ActionList/ActionList'
import PreviewDetailModal from '../../../Components/Admin/PreviewDetailModal/PreviewDetailModal'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

interface paramsTypes {
  slug: string
}

const StaticContents = ({ history }: any) => {
  const [title, setTitle] = useState<string>('')
  const [dataSource, setDataSource] = useState<[]>()
  const [contentType, setContentType] = useState<string>('')
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const { slug } = useParams<any>()
  const dispatch = useDispatch()

  const staticContents = useSelector(
    (state: RootStateOrAny) => state.staticContents
  )

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleSearch = (searchParams: any) => {
    dispatch(GetStaticContentData({ slug, contentType, searchParams }))
  }

  const onShowSizeChange = (current: number, size: number) => {
    dispatch(GetStaticContentData({ limit: size, page: current }))
  }
  const onPageChange = (page: number, pageSize?: number) => {
    dispatch(GetStaticContentData({ limit: pageSize, page: page }))
  }
  const type = 'DraggableBodyRow'
  const DragableBodyRow = ({
    index,
    moveRow,
    className,
    style,
    ...restProps
  }: any) => {
    const ref = useRef()
    const [{ isOver, dropClassName }, drop] = useDrop(
      () => ({
        accept: type,
        collect: (monitor) => {
          const { index: dragIndex } = monitor.getItem() || {}
          if (dragIndex === index) {
            return {}
          }
          return {
            isOver: monitor.isOver(),
            dropClassName:
              dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
          }
        },
        drop: (item: any) => {
          moveRow(item.index, index)
        },
      }),
      [index]
    )
    const [, drag] = useDrag(
      () => ({
        item: { type, index },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      }),
      []
    )
    drop(drag(ref))
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        style={{ cursor: 'move', ...style }}
        {...restProps}
      />
    )
  }
  const components = {
    body: {
      row: DragableBodyRow,
    },
  }
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragContent = staticContents[contentType]?.innerHtml[dragIndex]._id
      const hoverContent =
        staticContents[contentType]?.innerHtml[hoverIndex]._id
      dispatch(
        swapPosition({
          dragContent,
          dragIndex,
          hoverContent,
          hoverIndex,
          contentType,
          slug,
          title: staticContents[contentType].title,
        })
      )
    },
    [staticContents[contentType], slug, contentType]
  )

  useEffect(() => {
    setDataSource(staticContents[contentType]?.innerHtml)
  }, [contentType, slug, staticContents])

  useEffect(() => {
    if (slug === 'factory-tour-lower') {
      setContentType('factoryTour')
      setTitle('Factory Tour')
    } else if (slug === 'how-to-latido-products') {
      setContentType('howTo')
      setTitle('How To')
    } else {
      setContentType('aboutUs')
      setTitle('About Us')
    }
  }, [slug])

  useEffect(() => {
    if (contentType !== '' && !!!staticContents[contentType]?.title) {
      dispatch(GetStaticContentData({ slug, contentType, page: 1 }))
    }
  }, [contentType, slug])

  const columns: ColumnsType = [
    {
      title: 'Title',
      key: 'titleText',
      dataIndex: 'titleText',
      sorter: (a: any, b: any) => (a.titleText > b.titleText ? 1 : -1),
    },
    {
      title: 'description',
      dataIndex: 'content',
      key: 'content',
      width: 400,
      render: (text: any, record: any) => (
        <LinesEllipsis text={text} maxLine={2} ellipsis='. . .' />
      ),
    },
    {
      title: 'date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a: any, b: any) => a.createdAt - b.createdAt,
      render: (text: any, record: any): any => (
        <Typography.Text>{record.createdAt.slice(0, 10)}</Typography.Text>
      ),
    },
    {
      title: 'status',
      key: 'status',
      render: (text: any, record: any) => (
        <Typography.Text>{text ? 'Published' : 'Draft'}</Typography.Text>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any): any => (
        <Space size='middle'>
          <ActionList
            onEdit={() =>
              history.push(`/static-contents/${contentType}/${record._id}`)
            }
            onDetail={() => setIsModalVisible(true)}
          />
        </Space>
      ),
    },
  ]
  return (
    <>
      <PreviewDetailModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <Row justify='space-between' align='middle'>
        <Col sm={24} md={16} lg={16} xl={14}>
          <Space>
            <Typography.Title
              className='product-name'
              style={{ textTransform: 'uppercase' }}
            >
              {title}
            </Typography.Title>
            <Button
              onClick={() => history.push(`/static-contents/${slug}/add`)}
              type='primary'
              size='middle'
              shape='round'
            >
              <span style={{ fontWeight: 100 }}>
                Add New <PlusOutlined />
              </span>
            </Button>
          </Space>
        </Col>
        <Col sm={24} md={8} lg={8}>
          <Row
            justify='space-between'
            align='middle'
            gutter={[30, 10]}
            style={{ marginTop: 30 }}
          >
            <Col sm={12}>
              <Input.Search
                placeholder='Search'
                onSearch={(e) => handleSearch(e)}
              />
            </Col>
            {/*sorting doesnt work for now.. using ant table sorting*/}
            <Col sm={12}>
              <Select style={{ width: '100%' }} placeholder='Sort By: '>
                <Select.Option value='asc'>Date: Ascending</Select.Option>
                <Select.Option value='dsc'>Date: Descending</Select.Option>
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 30 }} justify='center' align='middle'>
        <Col span={24}>
          <DndProvider backend={HTML5Backend}>
            <LatidoTable
              components={components}
              moveRow={moveRow}
              pagination={false}
              columns={columns}
              loading={staticContents?.loading && !!staticContents?.factoryTour}
              dataSource={dataSource && dataSource}
            />
          </DndProvider>
        </Col>
        <Col>
          {!staticContents?.loading && (
            <Pagination
              showSizeChanger
              style={{ marginTop: 30 }}
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={1}
              total={staticContents[contentType]?.innerHtml?.length}
              onChange={onPageChange}
            />
          )}
        </Col>
      </Row>
    </>
  )
}

export default StaticContents
