/** @format */

import { Button, Col, Input, Row, Select, Space, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import BlogsList from './BlogsList'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { fetchBlogs } from '../../../Redux/BlogRedux/BlogActions'
import { useDebounce } from 'use-debounce'
const { Option } = Select

export interface filterTypes {
  search?: string
  sort?: 'asc' | 'desc'
}

export const Blogs = (props: any) => {
  const blogs = useSelector((state: any) => state.BlogReducer)
  const [filteredData, setFilteredData] = useState<filterTypes>()
  const [filteredItems, setFilteredItems] = useState([])

  const [searchText] = useDebounce(filteredData, 1000)

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>()

  const history = useNavigate()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchBlogs({ page: 1 }))
  }, [])

  useEffect(() => {
    // let tempData = blogs.list;
    // if (filteredData) {
    //     if (filteredData?.search) tempData = tempData.filter((e: any) => e.title.toLowerCase().indexOf(filteredData.search?.toLowerCase()) > -1);
    // }
    // setFilteredItems(tempData)
    dispatch(
      fetchBlogs({ search: searchText?.search, page: 1, sort: sortOrder })
    )
  }, [searchText, sortOrder])

  useEffect(() => {
    setFilteredItems(blogs.list)
  }, [blogs.list])

  return (
    <>
      <Row align='middle' justify='space-between' className='neumorphic'>
        <Col lg={8}>
          <Space align='center' >
            <div style={{ display: "flex", gap: "8px"}}>

            <Typography.Title className='product-name'>Blogs</Typography.Title>
            <Button
              onClick={() => history('/blogs/add')}
              type='primary'
              size='middle'
              shape='round'
              style={{ marginTop: "4px"}}
            >
              <span style={{ fontWeight: 100 }}>
                Add New <PlusOutlined />
              </span>{' '}
              </Button>
            </div>
              
          </Space>
        </Col>
        <Col lg={8} md={10}>
          <Row justify='end'>
            <div style={{
              display: "flex",
              placeItems: "center",
              justifyContent: "space-between"
            }}>
            <div>
              <Input.Search
                style={{ width: 250, marginRight: 20 }}
                placeholder='Search'
                onChange={(e: any) =>
                  setFilteredData({ ...filteredData, search: e.target.value })
                }
              />
            </div>
            <Select
              style={{ width: 200 }}
              placeholder='Sort by: Order'
              optionFilterProp='children'
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e: 'asc' | 'desc') => setSortOrder(e)}
            >
              <Option value='asc'>Sort by Order: ASC</Option>
              <Option value='desc'>Sort by Order: DESC</Option>
            </Select>
            </div>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col lg={24} style={{ marginTop: 20 }}>
          <BlogsList
            loading={blogs.loading}
            onEdit={(e: any) => history('blog/add')}
            data={blogs}
          />
        </Col>
      </Row>
    </>
  )
}
export default Blogs
