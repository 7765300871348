import { DeleteFilled, DeleteOutlined, EditOutlined, EyeFilled } from "@ant-design/icons";
import { Card, Space, Typography } from "antd";
import ImageHolder from "../../../../../Components/Admin/ImageHolder/ImageHolder";
import { useNavigate } from "react-router";
import "./ProductItemCard.scss";

export const ProductItemCard = (props: any) => {
  const { primary_image, name, id, _id, gender } = props?.data;
  const history = useNavigate();

  const onDetails = () => {
    history(`/product/${id || _id}`);
  };
  return (
    <Card className="product__item__card" hoverable>
      <div className="hoverable__action">
        <Space>
          <EyeFilled onClick={() => onDetails()} style={{ color: "white", fontSize: 24 }} />
          {/* <DeleteOutlined style={{ color: "white", fontSize: 24 }} /> */}
          <EditOutlined
            style={{ color: "white", fontSize: 24 }}
            onClick={() => history(`/product/${id || _id}/edit`)}
          />
        </Space>
      </div>
      <div className="product__item__image">
        <ImageHolder src={primary_image} width="100%" />
      </div>
      <Typography.Title
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          marginTop: 20,
        }}
        level={5}
      >
        {name}
      </Typography.Title>
      <Typography.Title
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          marginTop: 20,
        }}
        level={5}
      >
        {gender}
      </Typography.Title>
    </Card>
  );
};

export default ProductItemCard;
