import { Card, Space, Tooltip } from "antd";
import { Input } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

interface propsType {
  data?: string;
}

const Remarks = ({ data }: propsType) => {
  const { TextArea } = Input;

  return (
    <Card className="remarks white-border">
      <Space align="center">
        <h2 className="page-title">Remarks</h2>
        <Tooltip title="Special Request">
          <InfoCircleFilled
            style={{ fontSize: 16, color: "#00bcd4", marginBottom: 15, cursor: "pointer" }}
          />
        </Tooltip>
      </Space>
      <TextArea value={data} disabled rows={4} style={{ background: "#EBF5FF" }} />
    </Card>
  );
};

export default Remarks;
