import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Row,
  Select,
  Spin,
  Typography,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CombineReducerType } from "../../Redux/Reducers/rootReducers";
import MediaUrl from "../../Utils/mediaUrl";
import CommonS3UploadFiles from "../S3UploadFiles/CommonS3UploadFiles";

interface initialStateType {
  _id?: string;
  vendor_id: string;
  vendor_name: string;
  supplies_type: string[];
  location: string;
  phone_no: string;
  email: string;
  icon: string;
  description: string;
  contact_person: any;
}
interface propsType {
  initialState: initialStateType;
  edit?: boolean;
  handleFormSubmit: (data: initialStateType) => void;
}

export const VendorForm = ({ initialState, edit = false, handleFormSubmit }: propsType) => {
  const { creating } = useSelector((state: CombineReducerType) => state?.VendorReducer);
  const [formState, setFormState] = useState(initialState);
  const history = useNavigate();

  const [form] = useForm();

  useEffect(() => {
    setFormState(initialState);
  }, [initialState]);

  useEffect(() => {
    if (edit) {
      form.setFieldsValue(initialState);
    }
  }, [edit, initialState]);

  const onSubmit = (values: any) => {
    if (edit) {
      handleFormSubmit({ ...values, _id: initialState?._id });
    } else {
      handleFormSubmit(values);
    }
  };

  return (
    <>
      <Row style={{ margin: "30px 0" }} gutter={[20, 0]}>
        <Col span={15}>
          <h1 className="page-title">Vendor</h1>
          <h5
            style={{
              // fontSize: 22,
              color: "#828282",
              wordSpacing: 5,
            }}
          >
            <Typography.Link onClick={() => history("/inventory/vendors")}>
              {`Vendors >`}{" "}
            </Typography.Link>
            <Typography.Link
              hidden={!initialState?.vendor_name}
              onClick={() => history(`/inventory/vendor/${initialState?._id}`)}
            >
              {`${initialState?.vendor_name} >`}
            </Typography.Link>
            <Typography.Text type="secondary"> {edit ? " Edit" : " Add"}</Typography.Text>
          </h5>
        </Col>
      </Row>
      <Row>
        <Card style={{ width: "100%", padding: "30px 50px" }}>
          <Row>
            <h4 style={{ fontSize: 22, textTransform: "uppercase", color: "black" }}>
              {edit ? "Edit" : "Add"} Vendor information
            </h4>
          </Row>
          <Divider
            style={{
              width: "100%",
              boxShadow: "0px 1px 2px 1px white",
              marginTop: -5,
            }}
          />
          <Row gutter={[20, 20]} align="middle">
            <Col>
              {/* {form.getFieldsError()} */}
              {/* <Upload>
                <Button icon={<UploadOutlined />}>Add Image</Button>
              </Upload> */}
            </Col>
          </Row>
          <Form form={form} layout="vertical" onFinish={onSubmit}>
            <Row gutter={[40, 40]} style={{ marginTop: 40 }}>
              <Col span={8}>
                <Form.Item
                  label="Icon"
                  rules={[{ required: true, message: "Vendor Image is required" }]}
                  name="icon"
                >
                  <CommonS3UploadFiles
                    module="item"
                    type="circular"
                    accept=".jpg, .jpeg, .png"
                    onUpload={(e) => {
                      form.setFieldsValue({
                        icon: e,
                      });
                    }}
                    url={initialState?.icon ? MediaUrl(initialState?.icon) : false}
                    prefix="inventory/vendor"
                    // type="product"
                    title="Add Image"
                  />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Row gutter={[40, 20]}>
                  <Col span={12} xxl={12} lg={12} sm={24} xs={24}>
                    <Form.Item
                      label="Vendor Id"
                      rules={[{ required: true, message: "Please input vendor id" }]}
                      name="vendor_id"
                    >
                      <Input type="text" name="vendor_id" />
                    </Form.Item>
                  </Col>
                  <Col span={12} xxl={12} lg={12} sm={24} xs={24}>
                    <Form.Item
                      label="Vendor Name"
                      rules={[{ required: true, message: "Vendor name is required" }]}
                      name="vendor_name"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} xxl={12} lg={12} sm={24} xs={24}>
                    <Form.Item
                      label="Phone Number"
                      name="phone_no"
                      rules={[
                        { required: true, message: "Phone is required" },
                        {
                          pattern: /^(?:\+977)?[9][6-9]\d{8}$/,
                          message: "Please enter valid phone number",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} xxl={12} lg={12} sm={24} xs={24}>
                    <Form.Item
                      label="E-mail"
                      rules={[
                        { required: true, message: "Email is required" },
                        { type: "email", message: "Invalid email!" },
                      ]}
                      name="email"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[40, 0]} style={{ marginTop: 10 }}>
              <Col span={12} xxl={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Supplies Type"
                  name="supplies_type"
                  rules={[{ required: true, message: "Supplies type is required" }]}
                >
                  <Select mode="tags" />
                </Form.Item>
              </Col>
              <Col span={12} xxl={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Location"
                  name="location"
                  rules={[{ required: true, message: "Location is required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[40, 0]} style={{ marginTop: 10 }}></Row>
            <Row gutter={[40, 0]} style={{ marginTop: 10 }}>
              <Col span={12} xxl={12} lg={12} sm={24} xs={24}>
                <Form.Item label="Description" name="description">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <h4
                style={{ fontSize: 22, marginTop: 30, textTransform: "uppercase", color: "black" }}
              >
                Add Contact Person information
              </h4>
            </Row>
            <Divider
              style={{
                width: "100%",
                boxShadow: "0px 1px 2px 1px white",
                marginTop: -5,
              }}
            />
            <Row gutter={[40, 0]} style={{ marginTop: 10 }}>
              <Col span={12} xxl={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Contact Name"
                  name={["contact_person", "contact_name"]}
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} xxl={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Location"
                  name={["contact_person", "location"]}
                  rules={[{ required: true, message: "Location is required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[40, 0]} style={{ marginTop: 10 }}>
              <Col span={12} xxl={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Phone Number"
                  name={["contact_person", "phone_no"]}
                  rules={[
                    { required: true, message: "Phone is required" },
                    {
                      pattern: /^(?:\+977)?[9][6-9]\d{8}$/,
                      message: "Phone is invalid",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} xxl={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="E- Mail"
                  name={["contact_person", "email"]}
                  rules={[
                    { required: true, message: "Email is required" },
                    { type: "email", message: "Invalid email!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Button type="text" onClick={() => history(-1)}>
                Cancel
              </Button>
              <Button
                type="primary"
                loading={creating}
                htmlType="submit"
                // style={{ padding: "10px 35px", marginLeft: 15 }}
              >
                Save
              </Button>
            </Row>
          </Form>
        </Card>
      </Row>
    </>
  );
};
