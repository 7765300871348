/** @format */

import { Col, Divider, Row, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { FC } from 'react'

interface dataTable {
  data?: any
}
const PreviewHorizontalTable: FC<dataTable> = ({ data }) => {
  return (
    <>
      {data.map((datum: any) => {
        return (
          <>
            <Row style={{ marginTop: -10 }}>
              <Col span={15}>
                <Typography.Text>{datum.title}</Typography.Text>
              </Col>
              <Col span={9}>
                <Typography.Title level={5} style={{ textAlign: 'left' }}>
                  {datum.value}
                </Typography.Title>
              </Col>
            </Row>
            <Divider style={{ marginTop: -5 }} />
          </>
        )
      })}
    </>
  )
}
export default PreviewHorizontalTable
