import { all } from 'redux-saga/effects'
import AuthSagas from '../AuthRedux/AuthSagas'
import BlogSagas from '../BlogRedux/BlogSagas'
import CustomerSagas from '../CustomerRedux/CustomerSagas'
import DashboardSagas from '../Dashboard/DashboardSagas'
import InventoryCategoriesSagas from '../InventoryCategory/InventoryCategorySagas'
import InventoryItemSagas from '../InventoryItem/InventoryItemSagas'
import MemberSagas from '../MemberRedux/MemberSagas'
import ProductCategorySagas from '../ProductCategoryRedux/ProductCategorySagas'
import ProductSagas from '../ProductRedux/ProductSagas'
import ReportSagas from '../Reporting/ReportSagas'
import VendorSagas from '../Vendor/VendorSagas'
import ExchangeRefundSaga from './ExchangeRefundSaga'
import gridSaga from './GridSaga'
import productSizeSaga from './StaticContentsSagas'
import featureProductSaga from './FeatureProductSaga'
import LeatherProfileSaga from './LeatherProfilesaga'

export default function* rootSagas() {
  yield all([
    AuthSagas(),
    InventoryCategoriesSagas(),
    InventoryItemSagas(),
    VendorSagas(),
    ReportSagas(),
    DashboardSagas(),
    MemberSagas(),
    CustomerSagas(),
    ExchangeRefundSaga(),
    BlogSagas(),
    productSizeSaga(),
    gridSaga(),
    featureProductSaga(),
    LeatherProfileSaga(),
    ProductSagas(),
    ProductCategorySagas(),
  ])
}
