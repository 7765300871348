import { message } from "antd";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import {
  createProductFailure,
  createProductSuccess,
  deleteProductFailure,
  deleteProductSuccess,
  fetchProductFailure,
  fetchProductsFailure,
  fetchProductsSuccess,
  fetchProductSuccess,
  updateProductFailure,
  updateProductSuccess,
} from "./ProductActions";
import { cutProduct, makeProduct, modifyProduct, takeProduct, takeProducts } from "./ProductApis";
import {
  CreateProductParams,
  CREATE_PRODUCT,
  REMOVE_PRODUCT,
  FETCH_PRODUCTS,
  FETCH_PRODUCT,
  UPDATE_PRODUCT,
  ProductDetailsParams,
  ProductFetchParams,
} from "./ProductTypes";

import { history } from "../AppStore";

//get product
const getProductsAsync = async (data: ProductFetchParams) => await takeProducts(data);

function* getProducts(params: any) {
  const { payload } = params;
  try {
    const Products: AxiosResponse = yield call(getProductsAsync, payload);
    if (Products) {
      yield put(fetchProductsSuccess(Products.data));
    } else {
      yield put(fetchProductsFailure("Could not get list of product"));
      // message.error("Could not get list of product");
    }
  } catch (error) {
    yield put(fetchProductsFailure("Network Error while getting Products"));
    // message.error("Could not get list of product");
  }
}

function* watchGetProducts() {
  yield takeLatest(FETCH_PRODUCTS, getProducts);
}

//get user details
const getProductAsync = async (data: ProductDetailsParams) => await takeProduct(data);

function* getProduct(params: any) {
  const { payload } = params;
  try {
    const Products: AxiosResponse = yield call(getProductAsync, payload);
    if (Products) {
      yield put(fetchProductSuccess(Products.data));
    } else {
      yield put(fetchProductFailure("Could not get list of product"));
      // message.error("Could not get list of product");
    }
  } catch (error) {
    yield put(fetchProductFailure("Network Error while getting Products"));
    message.error("Could not get list of product");
  }
}

function* watchGetProduct() {
  yield takeLatest(FETCH_PRODUCT, getProduct);
}

//create Product
const generateProductAsync = async (data: CreateProductParams) => {
  try {
    return await makeProduct(data)
  } catch (error) {
    if (error instanceof Error) {
      console.error("Error: ", error);
    }
  }
};

function* generateProduct(params: any) {
  const { payload } = params;
  try {
    const Products: AxiosResponse = yield call(generateProductAsync, payload);

    console.log('products in axios response: ', Products);
    if (Products) {
      yield put(createProductSuccess(Products.data));
      history.push(`/product/${Products.data?._id}/edit?tab=leather-details`);
      message.success("Product created successfully");
      window.location.reload();
    } else {
      const messages = "Could not create Product";
      yield put(createProductFailure(messages));
      message.error(messages);
    }
  } catch (error: any) {
    const messages = "Network Error while creating Product";
    yield put(createProductFailure(messages));
    message.error(error?.response?.data?.message || messages);
  }
}

function* watchGenerateProduct() {
  yield takeLatest(CREATE_PRODUCT, generateProduct);
}

//update Product
const amendProductAsync = async (data: CreateProductParams) => {
  try {
    return await modifyProduct(data);
  } catch (error) {
    if (error instanceof Error) {
      console.error("Error: ", error);
    }
  }
}

function* amendProduct(params: any) {
  const { payload } = params;
  try {
    const Products: AxiosResponse = yield call(amendProductAsync, payload);
    if (Products) {
      yield put(updateProductSuccess(Products.data));
      message.success("Product updated successfully");
    } else {
      const messages = "Could not Update Product";
      yield put(updateProductFailure(messages));
      message.error(messages);
    }
  } catch (error: any) {
    const messages = "Network Error while updating Product";
    yield put(updateProductFailure(messages));
    message.error(error?.response?.data?.message || messages);
  }
}

function* watchAmendProduct() {
  yield takeLatest(UPDATE_PRODUCT, amendProduct);
}

//Delete Product
const removeProductAsync = async (data: ProductDetailsParams) => await cutProduct(data);

function* removeProduct(params: any) {
  const { payload } = params;
  try {
    const Products: AxiosResponse = yield call(removeProductAsync, payload);
    if (Products) {
      yield put(deleteProductSuccess(Products.data));
      message.success("Product Deleted successfully");
    } else {
      const messages = "Could not delete Product";
      yield put(deleteProductFailure(messages));
      message.error(messages);
    }
  } catch (error: any) {
    const messages = "Network Error while deleting Product";
    yield put(deleteProductFailure(messages));
    message.error(error?.response?.data?.message || messages);
  }
}

function* watchRemoveProduct() {
  yield takeLatest(REMOVE_PRODUCT, removeProduct);
}

export default function* ProductSagas() {
  yield all([
    fork(watchGetProducts),
    fork(watchGetProduct),
    fork(watchGenerateProduct),
    fork(watchAmendProduct),
    fork(watchRemoveProduct),
  ]);
}
