import { FC } from "react";
import { Avatar, Card, Col, Row, Typography } from "antd";
import { ShoppingCartOutlined, UserOutlined } from "@ant-design/icons";

import { DashboardCardType } from "../../Utils/types";

import "./DashboardCard.scss";

type DashboardCardProps = {
  title: string;
  amount: number;
  iconColor: string;
  backGroundColor: string;
};

export const DashboardCard: FC<DashboardCardProps> = ({
  title,
  amount,
  iconColor,
  backGroundColor,
}) => {
  return (
    <Card className="dashboard-card-wrapper" style={{ width: "100%" }}>
      <Row justify="space-between" align="middle" className="dashboard-card-body">
        <Col>
          <Typography.Text className="dashboard-card-title">{title}</Typography.Text>
          <Typography.Title className="dashboard-card-amount" level={3}>
            {amount}
          </Typography.Title>
        </Col>
        <Col>
          <Avatar
            size={60}
            className={`dashboard-card-icon`}
            style={{
              background: backGroundColor,
              border: `2px solid ${iconColor}`,
              color: iconColor,
            }}
            icon={<ShoppingCartOutlined size={10} />}
          />
        </Col>
      </Row>
    </Card>
  );
};
