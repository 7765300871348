import { Route, Routes } from 'react-router'
import { OrderList } from '../Views/Orders'
import OrderDetails from '../Views/Orders/OrderDetails'
import {
  ContactNumber,
  OrderOption,
  StoreOrder,
  CustomerOrder,
} from '../Views/CreateOrder'
import AddTrackOrder from '../Views/TrackOrder/AddTrackOrder'
import TrackOrderDetails from '../Views/TrackOrder/TrackOrderDetails'
import { CreateNewCustomer } from '../Views/CreateOrder/CreateNewCustomer'
import withPermission from '../Utils/checkPermission'

export const StoreRoute = (props: any) => {
  return (
    <Routes>
      <Route path={'/'} element={<OrderList />} />
      <Route path='/order/:id' element={<OrderDetails />} />
      <Route path='/create/order/option' element={<OrderOption />} />
      <Route path='/create/order/contact' element={<ContactNumber />} />
      <Route
        path='/create/order/create-new/:id'
        element={<CreateNewCustomer />}
      />
      <Route path='/create/order/store' element={<StoreOrder />} />
      <Route path='/create/order/customer' element={<CustomerOrder />} />
      <Route path='/track-order/' element={<AddTrackOrder />} />
      <Route path='/track-order/:id' element={<TrackOrderDetails />} />
      <Route path={`/`} element={<OrderList />} />
    </Routes>
  )
}

export default withPermission(StoreRoute, 'store')
