import { DoubleRightOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Row, Typography } from "antd";
import ImageHolder from "..//ImageHolder/ImageHolder";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import MediaUrl from "../../Utils/mediaUrl";
import "./DashboardTable.scss";

type propsType = {
  data?: Array<any>;
  title?: string;
  isVendor?: boolean;
};

const DashboardTable = ({ data, title = "title", isVendor = false }: propsType) => {
  const history = useNavigate();
  const handleNavigate = () => {
    if (isVendor) {
      history("/inventory/vendors");
    } else {
      history("/inventory/raw-materials");
    }
  };
  return (
    <Card>
      <Row justify="space-between" className="dashboard-list">
        <Col>
          <Typography.Title className="dashboard-list-title" level={5}>
            {title}
          </Typography.Title>
        </Col>
        <Col
          onClick={() => handleNavigate()}
          style={{
            color: "#27AE60",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 15,
          }}
        >
          <Typography.Title className="dashboard-list-more" level={5}>
            View Full Report
          </Typography.Title>
          <DoubleRightOutlined color="#27AE60" />
        </Col>
      </Row>
      <Row className="dashboard-list-header" justify="center" gutter={[20, 20]}>
        <Col lg={4} xs={6}></Col>
        <Col lg={10} xs={6}>
          <Typography.Title className="dashboard-list-header-title" level={5}>
            {isVendor ? "NAME" : "CATEGORIES"}
          </Typography.Title>
        </Col>
        <Col lg={5} xs={6}>
          <Typography.Title className="dashboard-list-header-title" level={5}>
            {isVendor ? "NUMBER" : "AVAILABLE"}
          </Typography.Title>
        </Col>
        <Col lg={5} xs={6}>
          <Typography.Title className="dashboard-list-header-title" level={5}>
            {isVendor ? "TYPE" : " DAILY USAGE "}
          </Typography.Title>
        </Col>
      </Row>

      {data?.map((item: any) => {
        return (
          <div key={item?.id}>
            <Row className="dashboard-list-body">
              <Col lg={4} xs={6} className="dashboard-list-body-item">
                <ImageHolder
                  src={MediaUrl(item?.image)}
                  alt="placeholder"
                  className="dashboard-list-body-image"
                />
              </Col>
              <Col
                lg={10}
                xs={6}
                className="dashboard-list-body-item"
                style={{
                  cursor: isVendor ? "pointer" : "auto",
                }}
                onClick={() => {
                  isVendor
                    ? history(`/inventory/vendor/${item?.id}`)
                    : history(`/inventory/raw-materials?category_id=${item?.id}`);
                }}
              >
                <Typography.Title className="dashboard-list-body-value" level={5}>
                  <Typography.Link> {item?.second}</Typography.Link>
                </Typography.Title>
              </Col>
              <Col lg={5} xs={6} className="dashboard-list-body-item">
                <Typography.Title className="dashboard-list-body-value" level={5}>
                  {item?.third}
                </Typography.Title>
              </Col>
              <Col lg={5} xs={6} className="dashboard-list-body-item">
                <Typography.Title className="dashboard-list-body-value" level={5}>
                  {item?.fourth}
                </Typography.Title>
              </Col>
            </Row>
            <Divider
              style={{
                width: "100%",
                boxShadow: "0px 1px 2px 1px white",
              }}
            />
          </div>
        );
      })}
    </Card>
  );
};

export default DashboardTable;
