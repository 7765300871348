export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILURE = "FETCH_PRODUCT_FAILURE";

export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const REMOVE_PRODUCT_SUCCESS = "REMOVE_PRODUCT_SUCCESS";
export const REMOVE_PRODUCT_FAILURE = "REMOVE_PRODUCT_FAILURE";

export interface ProductFetchParams {
  limit?: number;
  page?: number;
  PageSize?: number;
  PageNumber?: number;
  search?: string;
}
export interface ProductDetailsParams {
  id?: number | string;
}
export interface CreateProductParams {
  id?: number;
  name?: string;
  address?: string;
  phone?: string;
}

export interface Product {
  _id?: string;
  hardware?: string[];
  lining?: string[];
  pollyfill?: boolean;
  rib?: boolean;
  status?: null;
  availability_status?: null;
  tags?: string[];
  notes?: any[];
  new?: boolean;
  name?: string;
  category?: null;
  description?: string;
  product_specification?: ProductSpecification[];
  slug?: string;
  pattern_package?: any[];
  createdAt?: Date;
  updatedAt?: Date;
  __v?: number;
  main_tag?: null;
  primary_image?: string;
  sub_category?: null;
  pricing?: string;
}

export interface ProductSpecification {
  status?: boolean;
  secondary_image?: string[];
  _id?: string;
  size_range?: SizeRange[];
  leather_id?: LeatherID;
}

export interface LeatherID {
  _id?: string;
  item_name?: string;
}

export interface SizeRange {
  _id?: string;
  price?: Price[];
  size?: number;
}

export interface Price {
  _id?: string;
  currency?: string;
  value?: number;
}
