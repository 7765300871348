import { Route, Routes } from "react-router";
import Dashboard from "../Views/Dashboard/Dashboard";
import { AddInventory, AddItems, RawMaterialDetails, RawMaterials } from "../Views/Inventory";
import { AddVendor, EditVendor, VendorDetails, VendorsList } from "../Views/Vendor";
import { ReportList } from "../Views/Reports";
import ReportsDetails from "../Views/Reports/ReportsDetails";
import withPermission from "../Utils/checkPermission";

export const InventoryRoute = (props: any) => {
  return (
    <Routes>
      <Route path={`/dashboard`} element={<Dashboard />} />
      <Route path="/raw-materials" element={<RawMaterials />} />
      <Route path="/add-items" element={<AddItems />} />
      <Route path="/edit-items/:id" element={<AddItems />} />
      <Route path="/add-inventory" element={<AddInventory />} />
      <Route path="/vendors" element={<VendorsList />} />
      <Route path="/vendor/add" element={<AddVendor />} />
      <Route path="/vendor/edit/:id" element={<EditVendor />} />
      <Route path="/vendor/:id" element={<VendorDetails />} />
      <Route
        path="/item-details/:id"
        //
        element={<RawMaterialDetails />}
      />
      <Route path="/reporting" element={<ReportList />} />
      <Route path="/reporting/:id" element={<ReportsDetails />} />
    </Routes>
  );
};

export default withPermission(InventoryRoute, "inventory");
