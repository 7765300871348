import { Content } from 'antd/lib/layout/layout'
import { PageWrapper } from '../../../../Components/Admin/PageWrapper/PageWrapper'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { fetchCustomer } from '../../../../Redux/CustomerRedux/CustomerActions'
import UserDetailsEdit from './UserDetailsEdit'
import { UserProfileDetails } from './UserProfileDetails'
export interface RouteParams {
  userId: string
}

export const useForceUpdate = () => {
  const [value, setValue] = useState(0) // integer state
  return () => setValue((value) => value + 1) // update the state to force render
}

export const UserProfile = (props: any) => {
  const { userId } = useParams<any>()
  const [edit, setEdit] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector((state: any) => state.CustomerReducer)
  const userDetails = { ...user?.details, ...user?.fitDetails }

  useEffect(() => {
    dispatch(fetchCustomer({ user_id: userId }))
  }, [userId])

  return (
    <Content>
      <PageWrapper
        data={{ loading: user?.loading, detailing: user?.detailing }}
      >
        {!edit ? (
          <UserProfileDetails
            data={{
              size: user?.fitDetails,
              timeline: user?.timeline,
              details: user?.details,
            }}
            onEdit={() => setEdit(true)}
          />
        ) : (
          <UserDetailsEdit data={userDetails} onCancel={() => setEdit(false)} />
        )}
      </PageWrapper>
    </Content>
  )
}

export default UserProfile
