import { API } from 'aws-amplify'
import { ApiHeaders, API_NAME } from '../../API/variable'
import {
  ProductCategoryFetchParams,
  ProductCategoryDetailsParams,
  CreateProductCategoryParams,
} from './ProductCategoryTypes'
//get User
export const takeProductCategorys = (data: ProductCategoryFetchParams) =>
  API.get(API_NAME, '/admin_product_category', {
    headers: ApiHeaders,
    queryStringParameters: data,
  })

//get ProductCategory Details
export const takeProductCategory = (data: ProductCategoryDetailsParams) =>
  API.get(API_NAME, `/admin_product_category/${data?.id}`, {
    headers: ApiHeaders,
    data: data,
  })

//create ProductCategory Details
export const makeProductCategory = (data: CreateProductCategoryParams) =>
  API.post(API_NAME, '/admin_product_category', {
    headers: ApiHeaders,
    body: data,
  })

//modify ProductCategory
export const modifyProductCategory = (data: any) =>
  API.post(API_NAME, '/admin_product_category', {
    headers: ApiHeaders,
    body: data,
  })

//delete ProductCategory
export const cutProductCategory = (data: ProductCategoryDetailsParams) =>
  API.post(API_NAME, '/admin_product_category', {
    headers: ApiHeaders,
    data: data,
  })
