import { Content } from 'antd/lib/layout/layout'
import './ImageHolder.scss'
import InsuranceLogo from '../../../Assets/Icons/skeleton-image.svg'
import ReactImageFallback from 'react-image-fallback'

export const ImageHolder = (props: any) => {
  const { fallbackImage } = props
  return (
    <ReactImageFallback
      {...props}
      fallbackImage={fallbackImage || InsuranceLogo}
    />
  )
}

export default ImageHolder
