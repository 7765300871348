import React from 'react'
import { Route, Routes } from 'react-router'
import withPermission from '../Utils/checkPermission'
import ExchangeRefund from '../Views/Admin/ExchangeRefund/ExchangeRefund'
import ExchangeRefundDetails from '../Views/Admin/ExchangeRefund/ExchangeRefundDetails'

export const ExchangeRoute = (props: any) => {
  return (
    <Routes>
      <Route path={`/:orderId`} element={<ExchangeRefundDetails />} />
      <Route path={`/`} element={<ExchangeRefund />} />
    </Routes>
  )
}

export default withPermission(ExchangeRoute, 'exchange')
