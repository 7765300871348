enum DashboardCardColor {
    GREEN = 'green',
    ORANGE = 'orange',
    BLUE = 'blue',
}

enum ItemAvailability{
    AVAILABLE = 'AVAILABLE',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    LIMITED = 'LIMITED',
}

export { DashboardCardColor, ItemAvailability }
