import {
  GetSizingProductsApi,
  GetStaticContentApi,
  postStaticContentFormApi,
  postSwapPosApi,
  SizingFormApi,
} from '../../Apis/staticContentApis'
import { call, put, fork, takeEvery, all } from 'redux-saga/effects'
import {
  GetSizingProductsFailure,
  GetSizingProductsSuccess,
  GetStaticContentDataFailure,
  getStaticContentsDataSuccess,
  PostJacketSizesFailed,
  PostJacketSizesSuccess,
  postStaticContentFormFailed,
  postStaticContentFormSuccess,
  productSizeFailure,
  productSizeSuccess,
  swapPositionFailure,
  swapPositionSuccess,
} from '../Actions/StaticContentsActions'
import {
  FETCH_EACH_PRODUCT_SIZE_REQUEST,
  GET_SIZING_PRODUCTS,
  GET_STATIC_CONTENTS_DATA,
  POST_JACKET_SIZES,
  POST_STATIC_CONTENT_REQUEST,
  SWAP_POSITION_REQUEST,
} from '../Constants/constants'
import { AxiosResponse } from 'axios'
import { ProductSizesApi } from './../../Apis/staticContentApis'

//sizing product items
const takeGetSizingProducts = async (props: any) =>
  await GetSizingProductsApi(props)

function* getProductSize({ payload }: any) {
  try {
    const SizingProducts: AxiosResponse = yield call(takeGetSizingProducts, {
      search: payload.searchParam,
    })
    if (SizingProducts) {
      yield put(GetSizingProductsSuccess(SizingProducts?.data))
    }
  } catch (error) {
    yield put(GetSizingProductsFailure(error))
  }
}

function* watchProductSize() {
  yield takeEvery(GET_SIZING_PRODUCTS, getProductSize)
}

//fetch sizes of each product
const takeProductSizes = async (productId: any) =>
  await ProductSizesApi(productId)

function* getProductSizes({ payload }: any) {
  const { productId } = payload
  try {
    const productSizes: AxiosResponse = yield call(takeProductSizes, {
      categoryId: productId,
    })
    if (productSizes) {
      yield put(productSizeSuccess(productSizes?.data))
    }
  } catch (error) {
    yield put(productSizeFailure(error))
  }
}
function* watchProductSizes() {
  yield takeEvery(FETCH_EACH_PRODUCT_SIZE_REQUEST, getProductSizes)
}

//post jacket sizes saga
const takeJacketSizes = async (props: any) => await SizingFormApi(props)

function* postJacketSizes({ payload }: any) {
  try {
    const jacketSizes: AxiosResponse = yield call(takeJacketSizes, payload)
    if (jacketSizes) {
      yield put(PostJacketSizesSuccess(jacketSizes && jacketSizes.data))
    }
  } catch (error) {
    yield put(PostJacketSizesFailed(error))
  }
}

function* watchPostJacketSizes() {
  yield takeEvery(POST_JACKET_SIZES.REQUEST, postJacketSizes)
}

//post request static content form with shortCode, title and values
const takePostStaticContentForm = async (props: any) =>
  await postStaticContentFormApi(props)

function* postStaticContentForm({ payload }: any) {
  try {
    const staticContentForm: AxiosResponse = yield call(
      takePostStaticContentForm,
      payload
    )
    if (staticContentForm) {
      yield put(
        postStaticContentFormSuccess(
          staticContentForm && staticContentForm.data
        )
      )
    }
  } catch (error) {
    yield put(postStaticContentFormFailed(error))
  }
}

function* watchPostStaticContentForm() {
  yield takeEvery(POST_STATIC_CONTENT_REQUEST, postStaticContentForm)
}

//swap pos
const takeSwapPos = async (props: any) => await postSwapPosApi(props)

function* getSwapPos({ payload }: any) {
  const {
    hoverContent,
    dragContent,
    hoverIndex,
    dragIndex,
    contentType,
    slug,
    title,
  } = payload
  try {
    const swapPos: AxiosResponse = yield call(takeSwapPos, {
      contentId1: dragContent,
      contentId2: hoverContent,
      position1: hoverIndex,
      position2: dragIndex,
    })
    if (swapPos) {
      yield put(
        swapPositionSuccess({
          payload,
          hoverIndex,
          dragIndex,
          contentType,
          slug,
          title,
        })
      )
    }
  } catch (error) {
    yield put(swapPositionFailure(error))
  }
}
function* watchSwapPos() {
  yield takeEvery(SWAP_POSITION_REQUEST, getSwapPos)
}

const takeStaticContentApi = async (props: any) =>
  await GetStaticContentApi(props)

function* takeStaticContent({ payload }: any) {
  const { slug, contentType, limit, searchParams, page } = payload
  try {
    const StaticContents: AxiosResponse = yield call(takeStaticContentApi, {
      shortCode: slug,
      limit: limit,
      search: searchParams,
      page: page,
    })
    if (StaticContents) {
      yield put(
        getStaticContentsDataSuccess({
          data: StaticContents?.data,
          slug: contentType,
        })
      )
    }
  } catch (error) {
    yield put(GetStaticContentDataFailure(error))
  }
}

function* watchTakenStaticContent() {
  yield takeEvery(GET_STATIC_CONTENTS_DATA, takeStaticContent)
}

export default function* rootSaga() {
  yield all([
    fork(watchProductSize),
    fork(watchTakenStaticContent),
    fork(watchProductSizes),
    fork(watchPostJacketSizes),
    fork(watchPostStaticContentForm),
    fork(watchSwapPos),
  ])
}
