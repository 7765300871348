import {
  ArrowDownOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeFilled,
  UserOutlined,
  ShoppingCartOutlined,
  LoadingOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  Image,
  Progress,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import CircularProgress from "../../../Components/Admin/CircularProgress/CircularProgress";
import DashboardCard from "../../../Components/Admin/DashboardCard/DashboardCard";
import NeuMorph from "../../../Components/Admin/NeuMorph/NeuMorph";
import PieChart from "../../../Components/Admin/PieChart/PieChart";
import StatLineChart from "../../../Components/Admin/StatLineChart/StatLineChart";
import LatidoTable from "../../../Components/Admin/Table/Table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "./Dashboard.scss";

import { getInventory } from "../../../API/InventoryApi";
import { getOrders } from "../../../API/OrderApi";
import { API } from "aws-amplify";
import { ApiHeaders, API_NAME } from "../../../API/variable";
import MediaUrl from "../../../Utils/mediaUrl";
import withPermission from "../../../Utils/checkPermission";

export const Dashboard = () => {
  const history = useNavigate();

  const [order, setOrder] = useState(false);
  const [inventory, setInventory] = useState(false);

  const [oLoading, setOLoading] = useState(false);
  const [iLoading, setILoading] = useState(false);
  const [dashboard, setDashboard] = useState<any>({});

  const [loading, setLoading] = useState(false);
  const ActionList = (props: any) => {
    const menu = (
      <NeuMorph>
        <Space className="content" style={{ padding: 10, alignItems: "center", display: "flex" }}>
          <EyeFilled
            onClick={() => history(`user-management/${props.userId}`)}
            style={{ fontSize: 18 }}
          />
          <EditOutlined style={{ fontSize: 18 }} />
          <DeleteOutlined style={{ fontSize: 18 }} />
        </Space>
      </NeuMorph>
    );
    return (
      <Dropdown placement="topCenter" overlay={menu} trigger={["click"]}>
        <Typography.Text strong style={{ fontSize: 18, fontWeight: "bold", color: "black" }}>
          ...
        </Typography.Text>
      </Dropdown>
    );
  };

  const orderColumn = [
    {
      title: "Order No",
      dataIndex: "order_no",
      key: "order_no",
      render: (text: any, row: any) =>(
        <Typography.Link
          onClick={() => {
            history(`/store/order/${row?._id}`);
          }}
        >
          {text}
        </Typography.Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "delivery_date",
      key: "delivery_date",
      render: (text: string) => <Typography.Text>{dayjs(text).format("ll")}</Typography.Text>,
    },
    {
      title: "Customer",
      key: "user_id",
      dataIndex: "user_id",
      render: (text: any, row: any) => (
        <Typography.Link
          onClick={() => {
            history(`/store/order/${row?._id}`);
          }}
        >
          {text?.name}
        </Typography.Link>
      ),
    },
    {
      title: "Payment",
      key: "payment_status",
      dataIndex: "payment_status",
      render: (text: string) =>text === "payment_complete" ? <Tag style={{ minWidth: "60px", textAlign: "center"}} color="green">PAID</Tag> : <Tag color="red">UNPAID</Tag>
    },
  ];

  const inventoryColumn = [
    {
      title: "CODE",
      dataIndex: "code",
      key: "code",
      render: (text: string) => <Typography.Link>{text}</Typography.Link>,
    },
    {
      title: "Product",
      dataIndex: "icon",
      key: "icon",
      render: (img: string) => <Image width={50} height={50} preview={false} src={MediaUrl(img)} />,
    },
    {
      title: "Category",
      key: "inventory_category",
      dataIndex: "inventory_category",
      render: (text: string, row: any) => (
        <Typography.Link onClick={() => history(`inventory/raw-materials?category_id=${row?._id}`)}>
          {text}
        </Typography.Link>
      ),
    },
    {
      title: "IN STOCK",
      key: "total_quantity",
      dataIndex: "total_quantity",
      render: (text: string) => <Typography.Link>{text}</Typography.Link>,
    },
    // {
    //   title: '',
    //   key: 'action',
    //   render: (text: any, record: any): any => (
    //     <Space size='middle'>
    //       <ActionList userId={record.id} />
    //     </Space>
    //   ),
    // },
  ];

  const handleOrders = () => {
    setOLoading(true);
    getOrders("")
      .then((data: any) => {
        setOLoading(false);
        setOrder(data.list);
      })
      .catch((err: any) => {
        setOLoading(false);
      });
  };

  const handleInventory = () => {
    setILoading(true);
    getInventory("")
      .then((data: any) => {
        setILoading(false);
        setInventory(data.list);
      })
      .catch((err: any) => {
        setILoading(false);
      });
  };

  useEffect(() => {
    handleOrders();
    handleInventory();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await API.get(API_NAME, `/dashboard`, {
        headers: ApiHeaders,
      });
      console.log(res?.data);

      setDashboard(res?.data);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (Object.keys(dashboard).length === 0) {
      fetchData();
    }
  }, []);

  console.log({ dashboard });

  const totalUsers = (dashboard.activeUser || 0) + (dashboard.inActiveUser || 0);
  const totalGenders = dashboard?.totalMale + dashboard?.totalFemale + dashboard?.totalOther;
  return (
    <Row gutter={[20, 20]}>
      <Col lg={24}>
        <Typography.Title className="product-name" level={2}>
          OVERVIEW
        </Typography.Title>
        {/* <div style={{background: 'black', width: 200, padding: 20}}>

				<TestIcon stroke='white'/>
				</div> */}
      </Col>
      <Col lg={24}>
        <Row gutter={[40, 40]}>
          <Col lg={6}>
            <DashboardCard
              name="Total Customer"
              count={dashboard?.activeUser + dashboard?.adminUsers}
              loading={loading}
              cardIcon={<UserOutlined />}
            />
          </Col>
          <Col lg={6}>
            <DashboardCard
              loading={loading}
              name="Total Order"
              count={dashboard?.totalOrders}
              cardIcon={<ShoppingCartOutlined />}
            />
          </Col>
          <Col lg={6}>
            <DashboardCard
              loading={loading}
              name="Total Sale"
              count={dashboard?.totalClients}
              cardIcon={<DollarOutlined />}
            />
          </Col>
          <Col lg={6}>
            <DashboardCard
              loading={loading}
              name="Total Pending"
              count={dashboard?.pendingOrders}
              cardIcon={<LoadingOutlined />}
            />
          </Col>
        </Row>
      </Col>
      {/* <Col lg={24}>
        <Card style={{ padding: 30 }}>
          <Typography.Title
            level={3}
            style={{ color: 'black', marginBottom: 40 }}
          >
            TOTAL TRANSACTIONS
          </Typography.Title>
          <Row justify='space-between'>
            <Col lg={14}>
              <StatLineChart />
            </Col>
            <Col lg={8}>
              <Row align='stretch'>
                <Col lg={12}>
                  <Typography.Text>Last Week Profit</Typography.Text>
                  <Typography.Title style={{ margin: '20px 0px' }}>
                    +29.7%
                  </Typography.Title>
                </Col>
                <Col lg={12}>
                  <Typography.Text>This Week Losses</Typography.Text>
                  <Typography.Title style={{ margin: '20px 0px' }}>
                    -53.4%
                  </Typography.Title>
                </Col>
                <Col lg={12}>
                  <Typography.Text>Performance</Typography.Text>
                  <Typography.Title
                    level={3}
                    style={{ margin: '20px 0px', color: '#24CCB8' }}
                  >
                    +0.05%
                  </Typography.Title>
                </Col>
                <Col lg={12}>
                  <Button
                    type='primary'
                    shape='round'
                    size='middle'
                    style={{
                      height: 60,
                      paddingRight: 0,
                      background:
                        'linear-gradient(313.32deg, #262626 8.79%, #707580 93.86%)',
                      boxShadow: '0px 10px 30px rgba(26, 26, 26, 0.3)',
                    }}
                  >
                    Download
                    <Avatar
                      size={40}
                      style={{
                        marginLeft: 20,
                        background:
                          'linear-gradient(313.32deg, #262626 8.79%, #707580 93.86%)',
                        border: '1.13333px solid #4D4343',
                      }}
                      icon={<ArrowDownOutlined />}
                    />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col> */}
      <Col lg={24}>
        <Row gutter={[20, 20]}>
          <Col xl={16} md={24}>
            <LatidoTable
              title={() => <Typography.Title level={4}>RECENT ORDERS</Typography.Title>}
              style={{ marginBottom: 20 }}
              pagination={false}
              columns={orderColumn}
              dataSource={dashboard?.orderList?.slice(0, 10)}
            />
            <LatidoTable
              title={() => <Typography.Title level={4}>INVENTORY</Typography.Title>}
              pagination={false}
              columns={inventoryColumn}
              dataSource={dashboard?.inventoryList?.slice(0, 5)}
            />
          </Col>
          <Col xl={8} md={24}>
            <Card>
              <Typography.Title
                level={3}
                style={{ fontWeight: 200, paddingLeft: 20, marginBottom: 40 }}
              >
                ACTIVE USERS
              </Typography.Title>

              <Row justify="center">
                <Col>
                  <CircularProgress
                    size={120}
                    color="#24CCB8"
                    pr={Math.round((dashboard?.activeUser / totalUsers) * 100)}
                    trailingColor="#E8E8E8"
                  />
                  <Row justify="center" style={{ flexDirection: "column" }} align="middle">
                    <Typography.Text>Total Consumers</Typography.Text>
                    <Typography.Title level={5} style={{ marginTop: 10 }}>
                      {dashboard?.activeUser ?? 0}
                    </Typography.Title>
                  </Row>
                </Col>
                <Col>
                  <CircularProgress
                    size={120}
                    color="#FF5660"
                    pr={Math.round((dashboard?.inActiveUser / totalUsers) * 100)}
                    // pr={dashboard?.inActiveUser}
                    negative
                  />
                  <Row justify="center" style={{ flexDirection: "column" }} align="middle">
                    <Typography.Text>Internal User</Typography.Text>
                    <Typography.Title level={5} style={{ marginTop: 10 }}>
                      {dashboard?.adminUsers ?? 0}
                    </Typography.Title>
                  </Row>
                </Col>
              </Row>
            </Card>

            <Card style={{ marginTop: 20 }}>
              <Typography.Title level={3} style={{ fontWeight: 200, paddingLeft: 20 }}>
                TOTAL USERS
              </Typography.Title>
              <Typography.Title
                level={1}
                className="product-name"
                style={{
                  fontWeight: 700,
                  paddingLeft: 20,
                  marginTop: 5,
                  marginBottom: 60,
                }}
              >
                {dashboard?.totalMale + dashboard?.totalFemale + dashboard?.totalOther}
              </Typography.Title>
              <Row justify="center">
                {/* <Col>
                  <CircularProgress
                    size={180}
                    color="#24CCB8"
                    pr={Math.round((dashboard?.totalMale / totalGenders) * 100)}
                    trailingColor="#E8E8E8"
                  />
                  <Row justify="center" style={{ flexDirection: "column" }} align="middle">
                    <Typography.Text>Male</Typography.Text>
                    <Typography.Title level={5} style={{ marginTop: 10 }}>
                      {dashboard?.totalMale ?? 0}
                    </Typography.Title>
                  </Row>
                </Col> */}
                {/* <Col>
                  <CircularProgress
                    size={180}
                    color="#FF5660"
                    pr={Math.round((dashboard?.totalFemale / totalGenders) * 100)}
                    // pr={dashboard?.inActiveUser}
                    negative
                  />
                  <Row justify="center" style={{ flexDirection: "column" }} align="middle">
                    <Typography.Text>Female</Typography.Text>
                    <Typography.Title level={5} style={{ marginTop: 10 }}>
                      {dashboard?.totalFemale ?? 0}
                    </Typography.Title>
                  </Row>
                </Col> */}
                {/* <Col>
                  <CircularProgress
                    size={180}
                    color="#ff9800"
                    pr={Math.round((dashboard?.totalOther / totalGenders) * 100)}
                    // pr={dashboard?.inActiveUser}
                    negative
                  />
                  <Row justify="center" style={{ flexDirection: "column" }} align="middle">
                    <Typography.Text>Others</Typography.Text>
                    <Typography.Title level={5} style={{ marginTop: 10 }}>
                      {dashboard?.totalOther ?? 0}
                    </Typography.Title>
                  </Row>
                </Col> */}
                <Col>
                  <PieChart {...dashboard} />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={8} />
        </Row>
      </Col>
    </Row>
  );
};

export default withPermission(Dashboard, "commonDashboard");
