import React, { FC, useEffect, useState } from "react";
import { Card, Col, Form, Input, message, Pagination, Row, Spin } from "antd";

import { DashboardCard, DashboardSummary, Search } from "../../Components";

import "./Dashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import { CombineReducerType } from "../../Redux/Reducers/rootReducers";
import { fetchDashboard } from "../../Redux/Dashboard/DashboardActions";
import DashboardTable from "../../Components/DashboardTable/DashboardTable";
import DashboardList from "../../Components/DashboardList/DashboardList";
import withPermission from "../../Utils/checkPermission";
import { LatidoAnimatedLogoLoader } from "../../Components/LatidoAnimateLogo/LatidoAnimateLogo";

const Dashboard: FC = () => {
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state: CombineReducerType) => state.DashboardReducer);
  const [outOfStockList, setOutOfStockList] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [alarmingList, setAlarmingList] = useState<any>([]);
  const [vendorList, setVendorList] = useState<any>([]);

  useEffect(() => {
    dispatch(fetchDashboard());
  }, []);

  useEffect(() => {
    if (!loading) {
      const stockData: any = data?.out_of_stock?.map((item: any) => {
        return {
          id: item?._id,
          name: item?.item_name,
          image: item?.icon,
          inventory_category: item?.inventory_category,
        };
      });
      setOutOfStockList(stockData);

      const alarmingData: any = data?.alarming_list?.map((item: any) => {
        return {
          id: item?._id,
          name: item?.item_name,
          image: item?.icon,
          inventory_category: item?.inventory_category,
        };
      });
      setAlarmingList(alarmingData);

      const categoryData: any = data?.category_list?.slice(0, 3).map((item: any) => {
        return {
          id: item?._id,
          second: item?.inventory_category,
          third: item?.total_quantity + " " + item?.material_select?.unit,
          fourth: item?.code,
          image: item?.icon,
        };
      });
      setCategoryList(categoryData);

      const vendorData: any = data?.vendor?.slice(0, 3)?.map((item: any) => {
        return {
          id: item?._id,
          second: item?.vendor_name,
          third: item?.phone_no,
          fourth: item?.supplies_type[0],
          image: item?.icon,
        };
      });
      setVendorList(vendorData);
    }
  }, [data]);

  return (
    <>
      <Row style={{ margin: "30px 0" }}>
        <Col span={19}>
          <h1 className="page-title">DASHBOARD</h1>
        </Col>
        <Col span={5}>{/* <Search /> */}</Col>
      </Row>
      {loading ? (
        <Row justify="center">
          {/* <Spin /> */}
          <LatidoAnimatedLogoLoader />
        </Row>
      ) : (
        <>
          <Row
            gutter={[30, 30]}
            justify="space-between"
            style={{ padding: "0px 15px", marginBottom: 50 }}
          >
            <Col md={24} lg={8}>
              <DashboardCard
                title={"In Stock"}
                amount={data?.in_stock_count}
                backGroundColor={"#C0E4DA"}
                iconColor={"#43B072"}
              />
            </Col>
            <Col md={24} lg={8}>
              <DashboardCard
                title={"Alarming Stock"}
                amount={data?.alarming_count}
                backGroundColor={"#E8E2C1"}
                iconColor={"#EEB27E"}
              />
            </Col>
            <Col md={24} lg={8}>
              <DashboardCard
                title={"Out Of Stock"}
                amount={data?.out_of_stock_count}
                backGroundColor={"#E5C9D1"}
                iconColor={"#EB5757"}
              />
            </Col>
          </Row>

          <Row gutter={[30, 30]} style={{ margin: "30px 0" }}>
            <Col xxl={16} xl={16} md={24} sm={24} xs={24} lg={12}>
              <DashboardTable data={categoryList} title="Category List" />
            </Col>
            <Col xxl={8} xl={8} md={24} sm={24} xs={24} lg={12}>
              <DashboardList
                data={outOfStockList}
                total={data?.out_of_stock_count}
                title="Out of Stock List"
              />
            </Col>
          </Row>

          <Row gutter={[30, 30]} style={{ marginTop: "30px" }}>
            <Col xxl={16} xl={16} md={24} sm={24} xs={24} lg={12}>
              <DashboardTable data={vendorList} title="Vendor List" isVendor />
            </Col>
            <Col xxl={8} xl={8} md={24} sm={24} xs={24} lg={12}>
              <DashboardList
                data={alarmingList}
                total={data?.alarming_count}
                title="Alarming List"
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default withPermission(Dashboard, "inventory");
