/** @format */

import { DeleteOutlined, EditOutlined, EyeFilled } from '@ant-design/icons'
import { Button, Card, Col, Input, Row, Select, Space, Typography } from 'antd'
import NeuMorph from '../../../../Components/Admin/NeuMorph/NeuMorph'
import LatidoTable from '../../../../Components/Admin/Table/Table'
import { FC, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { GetFeatureProductRequest } from '../../../../Redux/Actions/FeatureProductAction'
import { Option } from 'antd/lib/mentions'

const FeatureProductManagement: FC = ({ history }: any) => {
  const [dataSource, setDataSource] = useState<any>()

  const dispatch = useDispatch()
  const featureProductReducer = useSelector(
    (state: RootStateOrAny) => state.featureProductReducer
  )

  const { featureProductList, loading } = featureProductReducer

  useEffect(() => {
    setDataSource(featureProductList)
  }, [featureProductList])

  useEffect(() => {
    dispatch(GetFeatureProductRequest())
  }, [])

  const columns = [
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      //  render: (text: any, record: any): any => (
      //    <div
      //      dangerouslySetInnerHTML={{
      //        __html: `${text.titleText}`,
      //      }}
      //    />
      //  ),
    },
    {
      title: 'description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'placement',
      dataIndex: 'placement',
      key: 'placement',
    },
    {
      title: 'date',
      key: 'date',
      dataIndex: 'date',
      //  render: (text: any, record: any): any => (
      //    <Typography.Text>{record.createdAt.slice(0, 10)}</Typography.Text>
      //  ),
    },
    {
      title: 'status',
      key: 'status',
      render: (text: any, record: any) => (
        <Typography.Text>{text ? 'Published' : 'Draft'}</Typography.Text>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any): any => (
        <Space size='middle'>
          <ActionList userId={record._id} />
        </Space>
      ),
    },
  ]
  const ActionList = (props: any) => {
    return (
      <NeuMorph>
        <Space
          className='content'
          style={{ padding: 10, alignItems: 'center', display: 'flex' }}
        >
          <EyeFilled style={{ fontSize: 18 }} />
          <EditOutlined onClick={() => {}} style={{ fontSize: 18 }} />
          <DeleteOutlined style={{ fontSize: 18 }} />
        </Space>
      </NeuMorph>
    )
  }

  const handleSearch = (e: any) => {
    if (featureProductList) {
      const searchProduct = featureProductList.filter(
        (product: any) =>
          product.title.toLowerCase().indexOf(e.toLowerCase()) > -1
      )
      setDataSource(searchProduct)
    }
  }

  const handleChange = async (value: any) => {
    const sortProduct = await featureProductList.sort((a: any, b: any) => {
      var c: any = new Date(a.date)
      var d: any = new Date(b.date)
      return c - d
    })
    setDataSource(sortProduct)
  }
  return (
    <Row>
      <Col
        span={24}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Space align='center'>
          <Typography.Title className='product-name' level={1}>
            FEATURE PRODUCT MANAGEMENT
          </Typography.Title>
          <Button
            onClick={() => history.push('/feature-product/add')}
            type='primary'
            shape='round'
            size='middle'
            style={{ marginLeft: 20 }}
          >
            Add New +
          </Button>
        </Space>
        <Space>
          <Input.Search
            placeholder='Search'
            onSearch={(e) => handleSearch(e)}
          />
          <Select placeholder='Sort By: ' onChange={handleChange}>
            <Option key='date'>Date</Option>
          </Select>
        </Space>
      </Col>
      <Col span={24} style={{ marginTop: 30 }}>
        <LatidoTable
          loading={loading}
          columns={columns}
          dataSource={dataSource}
        />
      </Col>
    </Row>
  )
}
export default FeatureProductManagement
