import { Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import GridBuilder from "../GridBuilder/GridBuilder";
import "./GridContainer.scss";
// import LayoutData from "../GridBuilder/GridData.json";
import { useState } from "react";
export const GridContainer = (props: any) => {
  const [LayoutData, setLayoutData] = useState([
    [
      {
        id: 1,
        isRow: true,
      },
      {
        id: 3,
        parentId: 1,
        isRow: false,
        contentId: 1,
        size: 24,
        contentType: "image",
      },
      {
        id: 2,
        isRow: true,
      },
      {
        id: 4,
        parentId: 2,
        isRow: false,
        contentId: 2,
        size: 12,
        contentType: "image",
      },
      {
        id: 5,
        parentId: 2,
        isRow: false,
        contentId: 3,
        size: 12,
        contentType: "image",
      },
      {
        id: 6,
        isRow: true,
      },
      {
        id: 7,
        parentId: 6,
        isRow: false,
        size: 18,
      },
      {
        id: 8,
        parentId: 7,
        isRow: false,
        contentId: 4,
        size: 12,
        contentType: "image",
      },
      {
        id: 9,
        parentId: 6,
        isRow: false,
        contentId: 4,
        size: 6,
        contentType: "image",
      },
    ],
    [
      {
        id: 1,
        isRow: true,
      },
      {
        id: 3,
        parentId: 1,
        isRow: false,
        contentId: 1,
        size: 6,
        contentType: "image",
      },
      {
        id: 4,
        parentId: 1,
        isRow: false,
        contentId: 1,
        size: 6,
        contentType: "image",
      },
      {
        id: 5,
        parentId: 1,
        isRow: false,
        contentId: 1,
        size: 12,
        contentType: "image",
      },
      {
        id: 7,
        parentId: 3,
        isRow: false,
        contentId: 1,
        size: 24,
        contentType: "image",
      },
      {
        id: 8,
        parentId: 3,
        isRow: false,
        contentId: 1,
        size: 24,
        contentType: "image",
      },
      {
        id: 9,
        parentId: 3,
        isRow: false,
        contentId: 1,
        size: 24,
        contentType: "image",
      },
    ],
    [
      {
        id: 1,
        isRow: true,
      },
      {
        id: 3,
        parentId: 1,
        isRow: false,
        contentId: 1,
        size: 12,
        contentType: "image",
      },
      {
        id: 3,
        parentId: 1,
        isRow: false,
        contentId: 1,
        size: 12,
        contentType: "image",
      },
      {
        id: 2,
        isRow: true,
      },
      {
        id: 4,
        parentId: 2,
        isRow: false,
        contentId: 2,
        size: 12,
        contentType: "image",
      },
      {
        id: 5,
        parentId: 2,
        isRow: false,
        contentId: 3,
        size: 12,
        contentType: "image",
      },
      {
        id: 6,
        isRow: true,
      },
      {
        id: 7,
        parentId: 6,
        isRow: false,
        size: 18,
      },
      {
        id: 8,
        parentId: 7,
        isRow: false,
        contentId: 4,
        size: 12,
        contentType: "image",
      },
      {
        id: 9,
        parentId: 6,
        isRow: false,
        contentId: 4,
        size: 6,
        contentType: "image",
      },
    ],
    [
      {
        id: 1,
        isRow: true,
      },
      {
        id: 3,
        parentId: 1,
        isRow: false,
        contentId: 1,
        size: 24,
        contentType: "image",
      },
      {
        id: 2,
        isRow: true,
      },
      {
        id: 4,
        parentId: 2,
        isRow: false,
        contentId: 2,
        size: 12,
        contentType: "image",
      },
      {
        id: 5,
        parentId: 2,
        isRow: false,
        contentId: 3,
        size: 12,
        contentType: "image",
      },
    ],
  ]);
  return (
    <Content>
      <Row gutter={[20, 20]}>
        {LayoutData &&
          LayoutData.map((d: any, i: number) => {
            return (
              <Col lg={4}>
                <div
                  onClick={() => props.onLayoutChange(d)}
                  style={{
                    background: "#545353",
                    height: "100%",
                    padding: "20px",
                    borderRadius: 10,
                  }}
                >
                  <GridBuilder data={d} />
                </div>
              </Col>
            );
          })}
      </Row>
    </Content>
  );
};

export default GridContainer;
