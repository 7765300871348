import { CheckCircleFilled } from "@ant-design/icons";
import { Card, Col, Pagination, Row, Select, Table, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import PageHeader from "../../../Components/Admin/PageHeader/PageHeader";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  deleteProductCategory,
  fetchProductCategorys,
} from "../../../Redux/ProductCategoryRedux/ProductCategoryActions";
import { FilterType } from "../../../Types/Types";
import { useDebounce } from "use-debounce";
import ProductCategoryForm from "..//Forms/Forms/ProductCategoryForm";
import ProductCategoryItem from "./ProductCategoryItem/ProductCategoryItem";

export const ProductCategoryList = (props: any) => {
  const productCategorys = useSelector((state: any) => state.ProductCategoryReducer);
  const [filter, setFilter] = useState<FilterType>({
    page: 1,
    limit: 10,
  });
  const [addForm, setAddForm] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [debounceFilter] = useDebounce(filterText, 500);
  const dispatch = useDispatch();

  const history = useNavigate();

  const { loading, list, totalRecords, created, updated } = productCategorys;

  useEffect(() => {
    setFilter({ ...filter });
  }, [debounceFilter]);

  //Fetch data with filter
  const fetchWithFilter = (data: any) => {
    dispatch(fetchProductCategorys({ ...data, ...filter }));
  };

  //edit button click handler
  const onEditHandler = (data: any) => {
    history(`product category/${data?.id}/edit`);
  };

  const onDeleteHandler = (id: number) => {
    dispatch(deleteProductCategory({ id: id }));
  };

  const productcategoryColumns = [
    {
      dataIndex: "isCompleted",
      key: "isCompleted",
      width: "30px",
      render: (row: any, text: any, index: number) => (row ? <CheckCircleFilled /> : ""),
    },
    {
      title: "#",
      render: (row: any, text: any, index: number) => (
        <Typography.Text>{index + 1}</Typography.Text>
      ),
    },
    {
      title: "ProductCategory Number",
      dataIndex: "product categoryNumber",
      key: "product categoryNumber",
    },
    {
      title: "ProductCategory Date",
      dataIndex: "startTime",
      key: "startTime",
      render: (row: any, text: any, index: number) => (
        <Typography.Text>{dayjs(row)?.format("LLL")}</Typography.Text>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
      render: (row: any, text: any, index: number) => (
        <Typography.Link
          strong
          onClick={(e: any) => {
            // dispatch(setLayout({ form: 'product category', update: false, readOnly: true, data: text }))
            history(`/product category/${text?.id}`);
          }}
        >
          {row}
        </Typography.Link>
      ),
    },

    {
      title: "Agenda",
      dataIndex: "agendas",
      key: "agendas",
      render: (row: any, text: any, index: number) => (
        <Typography.Text strong>
          {text?.isCompleted ? text?.agenda_history?.length : text?.agenda?.length || 0}
        </Typography.Text>
      ),
    },

    {
      title: "Comittee",
      dataIndex: "team",
      key: "type",
      render: (text: any, row: any) => <Typography.Text>{row?.team?.title}</Typography.Text>,
    },
  ];

  useEffect(() => {
    if (filter) {
      fetchWithFilter({ ...filter, size: 10 });
    }
  }, [filter]);
  useEffect(() => {
    if (created) {
      setAddForm(false);
      fetchWithFilter({ ...filter, size: 10 });
    }
  }, [created]);

  return (
    <>
      <PageHeader
        module="product"
        title="Product Category"
        defaultAdd={false}
        onAdd={() => setAddForm(true)}
        // extra={
        //   <Select>
        //     <Select.Option value="test">test</Select.Option>
        //   </Select>
        // }
      />
      <Row>
      </Row>
      {list?.length === 0 && loading && (
        <Row gutter={[20, 20]} align="stretch">
          {Array(5)
            .fill(null)
            ?.map(() => {
              return (
                <Col lg={6}>
                  <Card style={{ minHeight: 200 }} loading={loading} />
                </Col>
              );
            })}
        </Row>
      )}
      <Row gutter={[20, 20]} align="stretch">
        {list?.map((item: any) => {
          return (
            <Col lg={6}>
              <ProductCategoryItem data={item} />
            </Col>
          );
        })}
      </Row>
      <Modal footer={false} visible={addForm} onCancel={() => setAddForm(false)}>
        <ProductCategoryForm onCancel={() => setAddForm(false)} />
      </Modal>
      {list && list.length > 0 && (
        <Row justify="center" style={{ marginTop: 20 }}>
          <Col>
            <Pagination
              onChange={(page: number, pageSize?: number) => {
                setFilter({ ...filter, page: page, limit: pageSize });
              }}
              total={totalRecords}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ProductCategoryList;
