/** @format */

import { PoweroffOutlined, UserOutlined } from "@ant-design/icons";
import { Affix, Menu, Modal, Tooltip, Typography } from "antd";
import Layout, { Content, Footer, Header } from "antd/lib/layout/layout";
import { ReactComponent as LogoLight } from "../Assets/Company/logo-light.svg";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
// import { Link } from "react-router-dom";
// import Avatar from "../Assets/Icons/avatar.svg";
import { NavLink } from "react-router-dom";
import "./style.scss";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import SubMenu from "antd/lib/menu/SubMenu";

const navMenu = [
  {
    enable: true,
    title: "Dashboard",
    route: "/",
  },
  {
    enable: true,
    title: "Blogs",
    route: "/blogs",
  },
  {
    enable: true,
    title: "Order List",
    route: "/store",
  },
  {
    enable: true,
    title: "Track Order",
    route: "/store/track-order",
  },
  {
    enable: true,
    title: "Inventory Dashboard",
    route: "/inventory/dashboard",
  },
  {
    enable: true,
    title: "Inventory",
    route: "/inventory/raw-materials",
  },
  {
    enable: true,
    title: "Vendors",
    route: "/inventory/vendors",
  },
  {
    enable: true,
    title: "Reporting",
    route: "/inventory/reporting",
  },

  {
    enable: true,
    title: "Member Management",
    route: "/member-management",
  },

  {
    enable: true,
    title: "Product Management",
    route: "/product",
  },

  {
    enable: true,
    title: "Leather Management",
    route: "/leather",
  },
  {
    enable: true,
    title: "Product Category",
    route: "/product-category",
  },

  // {
  //   enable: true,
  //   title: 'Blogs',
  //   route: '/blogs',
  // },
  // {
  //   enable: true,
  //   title: 'Logout',
  //   route: '/',
  //   icon: (
  //     <Image
  //       src={Avatar}
  //       style={{ width: 'clamp(15px, 0.9vw, 20px)' }}
  //       preview={false}
  //     />
  //   ),
  // },
];

export const permission: any = {
  superAdmin: [
    "Dashboard",
    "Blogs",
    "Order List",
    "Track Order",
    "Inventory Dashboard",
    "Inventory",
    "Vendors",
    "Reporting",
    "Member Management",
    "Change Password",
    "Product Management",
    "Product Category",
    "Blogs",
    "Logout",
    "Leather Management",
    "editOrder",
  ],
  admin: [
    "Dashboard",
    "Blogs",
    "Order List",
    "Track Order",
    "Inventory Dashboard",
    "Inventory",
    "Vendors",
    "Reporting",
    "Change Password",
    "Product Management",
    "Product Category",
    "Blogs",
    "Logout",
    "Leather Management",
    "editOrder",
  ],
  storeManager: ["Dashboard", "Order List", "Track Order", "Logout", "editOrder"],
  productManager: ["Dashboard", "Blogs", "Product Management", "Product Category", "Logout"],
  factoryManager: [
    "Dashboard",
    "Inventory Dashboard",
    "Inventory",
    "Vendors",
    "Reporting",
    "Logout",
  ],
  inventoryManager: [
    "Dashboard",
    "Inventory Dashboard",
    "Inventory",
    "Vendors",
    "Reporting",
    "Logout",
  ],
  orderManager: ["Dashboard", "Order List"],
  karigar: ["Leather Management", "Logout"],
  unassigned: [],
};

function HamburgerMenu({ isMenuOpen }: { isMenuOpen: boolean }) {
  return (
    <svg className={`ham ham3 ${isMenuOpen && "active"}`} viewBox="0 0 100 100" width="50">
      <path
        className="line top"
        d="m 70,33 h -40 c -11.092231,0 11.883874,13.496726 -3.420361,12.956839 -0.962502,-2.089471 -2.222071,-3.282996 -4.545687,-3.282996 -2.323616,0 -5.113897,2.622752 -5.113897,7.071068 0,4.448316 2.080609,7.007933 5.555839,7.007933 2.401943,0 2.96769,-1.283974 4.166879,-3.282995 2.209342,0.273823 4.031294,1.642466 5.857227,-0.252538 v -13.005715 16.288404 h 7.653568"
      />
      <path
        className="line middle"
        d="m 70,50 h -40 c -5.6862,0 -8.534259,5.373483 -8.534259,11.551069 0,7.187738 3.499166,10.922274 13.131984,10.922274 11.021777,0 7.022787,-15.773343 15.531095,-15.773343 3.268142,0 5.177031,-2.159429 5.177031,-6.7 0,-4.540571 -1.766442,-7.33533 -5.087851,-7.326157 -3.321409,0.0092 -5.771288,2.789632 -5.771288,7.326157 0,4.536525 2.478983,6.805271 5.771288,6.7"
      />
      <path
        className="line bottom"
        d="m 70,67 h -40 c 0,0 -3.680675,0.737051 -3.660714,-3.517857 0.02541,-5.415597 3.391687,-10.357143 10.982142,-10.357143 4.048418,0 17.88928,0.178572 23.482143,0.178572 0,2.563604 2.451177,3.403635 4.642857,3.392857 2.19168,-0.01078 4.373905,-1.369814 4.375,-3.392857 0.0011,-2.023043 -1.924401,-2.589191 -4.553571,-4.107143 -2.62917,-1.517952 -4.196429,-1.799562 -4.196429,-3.660714 0,-1.861153 2.442181,-3.118811 4.196429,-3.035715 1.754248,0.0831 4.375,0.890841 4.375,3.125 2.628634,0 6.160714,0.267857 6.160714,0.267857 l -0.178571,-2.946428 10.178571,0 -10.178571,0 v 6.696428 l 8.928571,0 -8.928571,0 v 7.142858 l 10.178571,0 -10.178571,0"
      />
    </svg>
  );
}

export const ProtectedLayout: React.FC = ({ children }) => {
  const [allMenu, setAllMenu] = useState<any>([]);
  const { user: currentUser } = useSelector((state: any) => state.AuthReducer);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      let tempMenu: any = [];
      currentUser?.role?.forEach((role: string) => {
        navMenu?.forEach((menu: any) => {
          if (permission[role].includes(menu.title)) {
            if (!tempMenu.some((item: any) => item.title === menu.title)) {
              tempMenu.push(menu);
            }
          }
        });
      });

      setAllMenu(tempMenu);
    }
  }, [currentUser]);

  const isDesktopMenu = useMediaQuery({
    query: "(min-width: 1400px)",
  });

  useEffect(() => {
    setIsMenuOpen(isMenuOpen && !isDesktopMenu);
  }, [isDesktopMenu]);
  return (
    <Layout
      className="custom-layout-theme"
      style={{
        isolation: "isolate",
      }}
    >
      <Affix>
        <Header
          style={{
            // position: "fixed",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // zIndex: 1000,
            width: "100%",
            maxWidth: "100vw",
            background: "black",
            isolation: "isolate",
          }}
        >
          <div
            className="mobile_menu_toggle"
            style={{ height: "50px", width: "50px" }}
            onClick={() => setIsMenuOpen((prev) => !prev)}
          >
            <HamburgerMenu isMenuOpen={isMenuOpen} />
          </div>
          <Link to="/" style={{ display: "flex", alignItems: "center" }}>
            <LogoLight onClick={() => !isDesktopMenu && setIsMenuOpen((prev) => !prev)} />
            <Typography.Text style={{ color: "#fff", margin: "2px" }}>BETA</Typography.Text>
          </Link>
          {/* big screen menu */}
          <div className="menus">
            {allMenu.map((item: any) => (
              <div key={item?.title} className="menu">
                <NavLink
                  className={({ isActive }: any) => (isActive ? `menu_link active` : `menu_link`)}
                  to={item.route}
                  id={item?.title?.replace(/\s/g, "") + "Menu"}
                >
                  {item.title}
                </NavLink>
              </div>
            ))}
            <div className="menu">
              <Tooltip
                placement="bottom"
                title={<LogoutDropdown name={currentUser?.name} role={currentUser?.role} />}
              >
                <UserOutlined
                  size={22}
                  style={{
                    fontSize: "clamp(18px, 1vw, 20px)",
                    color: "#fff",
                  }}
                />
              </Tooltip>
            </div>
          </div>

          {/* small screen menu */}
          <div className={`side_navbar ${isMenuOpen && "open"}`}>
            <div className="mobile_menus">
              {allMenu.map((item: any) => (
                <div key={item?.title} className="menu">
                  <NavLink
                    className={({ isActive }: any) => (isActive ? `menu_link active` : `menu_link`)}
                    to={item.route}
                    onClick={() => setIsMenuOpen(false)}
                    id={item?.title?.replace(/\s/g, "") + "Menu"}
                  >
                    {item.title}
                  </NavLink>
                </div>
              ))}
              <div className="menu">
                <Tooltip placement="bottom" title={<LogoutDropdown />}>
                  <UserOutlined
                    size={22}
                    style={{
                      fontSize: "clamp(18px, 1vw, 20px)",
                      color: "#fff",
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </Header>
      </Affix>
      <Content
        className="site-layout"
        style={{
          padding: "0 50px",
          paddingTop: 30,
          // marginTop: 64,
          // minHeight: "100vh",
          width: isMenuOpen ? "calc(100% - 270px)" : "100%",
          marginLeft: isMenuOpen ? 270 : 0,
        }}
      >
        {children}
      </Content>
      <Footer lang="en" style={{ textAlign: "center" }}>
        © {dayjs().format("YYYY")} <span lang="en">Latido</span>
      </Footer>
    </Layout>
  );
};

export default ProtectedLayout;

const LogoutDropdown = ({ name, role }: any) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  const handleLogout = async () => {
    await Auth.signOut();
    navigate("/auth");
  };

  return (
    <div
      style={{
        // width: "150px",
        padding: "15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>Hi, {name}</div>
      <span style={{ fontSize: "0.8rem", textAlign: "center" }}>( {role?.join(" / ")} )</span>
      <Menu
        onClick={(e) => {
          return navigate(`/${e.key}`);
        }}
        style={{ marginTop: 20 }}
      >
        <SubMenu title="Under Development">
          <Menu.Item key="exchange-refund">Exchange and Refund</Menu.Item>
          <Menu.Item key="customer-management">Customer Management</Menu.Item>
          <Menu.Item key="blogs">Blog Management</Menu.Item>
        </SubMenu>
      </Menu>

      <div
        style={{
          display: "flex",
          margin: "10px 0",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/change-password");
        }}
        id="ChangePasswordButton"
      >
        Change Password
      </div>
      <div
        style={{
          display: "flex",
          margin: "10px 0",
          cursor: "pointer",
        }}
        onClick={() => setIsVisible(true)}
        id="LogoutButton"
      >
        Logout
      </div>

      <Modal
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        okText="Logout"
        okButtonProps={{
          id: "LogoutConfirmButton",
        }}
        cancelButtonProps={{
          id: "LogoutCancelButton",
        }}
        onOk={handleLogout}
      >
        <div
          style={{
            display: "flex",
            margin: "10px 0",
            cursor: "default",
            alignItems: "center",
            gap: 10,
          }}
        >
          <PoweroffOutlined
            style={{
              transform: "rotate(90deg)",
              marginRight: "10px",
              color: "red",
            }}
          />
          <span>Are you sure you want to logout?</span>
        </div>
      </Modal>
    </div>
  );
};
