import React from "react";
import "./ImageGallerySwiper.scss";
import { Col, Row } from "antd";

const ImageGallerySwiper = ({ images }: any) => {
  return (
    <Row gutter={[10, 10]}>
      {images?.map((sec: any) => {
        return (
          <Col lg={4}>
            <img src={sec} alt="product_image" style={{ width: "100%" }} />
          </Col>
        );
      })}
    </Row>
  );
};

export default ImageGallerySwiper;
