import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router'
import ProtectedLayout from '../Layouts/ProtectedLayout'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import StoreRoute from './StoreRoute'
import AdminRoute from './AdminRoute'
import InventoryRoute from './InventoryRoute'
import ChangePassword from '../Views/ChangePassword/Index'
import LeatherProfileRoute from './LeatherProfileRoute'
import Dashboard from '../Views/Admin/Dashboard/Dashboard'

export const ProtectedRoute = (props: any) => {
  const navigate = useNavigate()
  const { user } = useSelector((state: any) => state.AuthReducer)

  const handleCheckingAuth = async () => {
    try {
      const res = await Auth.currentAuthenticatedUser()

      if (
        res ||
        localStorage.getItem('amplify-authenticator-authState') === 'signedIn'
      ) {
      }
    } catch (err) {
      navigate('/auth')
    }
  }

  useEffect(() => {
    handleCheckingAuth()
  }, [Auth])

  return (
    <ProtectedLayout>
      <div style={{ marginBottom: 40, width: '100%' }}>
        <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route path='/change-password' element={<ChangePassword />} />
          {/* <Route path='/leather' element={<Leather />} /> */}
          <Route path={`/leather/*`} element={<LeatherProfileRoute />} />

          <Route path='/*' element={<AdminRoute />} />
          <Route path='/store/*' element={<StoreRoute />} />
          <Route path='/inventory/*' element={<InventoryRoute />} />
        </Routes>
      </div>
    </ProtectedLayout>
  )
}

export default ProtectedRoute
