import { Reducer } from 'redux'
import { SwapArrayElements } from '../../Utils/SwapArray'
import {
  FETCH_EACH_PRODUCT_SIZE_REQUEST,
  FETCH_EACH_PRODUCT_SIZE_SUCCESS,
  FETCH_EACH_PRODUCT_SIZE_FAILED,
  FETCH_FACTORY_TOUR_TABLE_CONTENTS_REQUEST,
  FETCH_FACTORY_TOUR_TABLE_CONTENTS_SUCCESS,
  FETCH_FACTORY_TOUR_TABLE_CONTENTS_FAILED,
  FETCH_ABOUT_TABLE_CONTENT_REQUEST,
  FETCH_ABOUT_TABLE_CONTENT_SUCCESS,
  FETCH_ABOUT_TABLE_CONTENT_FAILED,
  FETCH_HOW_TO_TABLE_CONTENT_REQUEST,
  FETCH_HOW_TO_TABLE_CONTENT_SUCCESS,
  POST_JACKET_SIZES,
  POST_STATIC_CONTENT_REQUEST,
  POST_STATIC_CONTENT_SUCCESS,
  POST_STATIC_CONTENT_FAILED,
  SWAP_POSITION_REQUEST,
  SWAP_POSITION_SUCCESS,
  SWAP_POSITION_FAILED,
  GET_STATIC_CONTENTS_DATA,
  GET_STATIC_CONTENTS_DATA_FAILURE,
  GET_STATIC_CONTENTS_DATA_SUCCESS,
  GET_SIZING_PRODUCTS,
  GET_SIZING_PRODUCTS_SUCCESS,
  GET_SIZING_PRODUCTS_FAILURE,
} from '../Constants/constants'

const INIT_STATE = {
  SizingProducts: [],
  loading: false,
  ProductSizes: false,
  postJacketSizes: false,
  staticContentForm: false,
  swappedPos: false,
  factoryTour: {},
  aboutUs: {},
  howTo: {},
  sizing: {},
  error: false,
}

export const StaticContentsReducer: Reducer<any, any> = (
  state = INIT_STATE,
  action: any
) => {
  switch (action.type) {
    //Get Static Content
    case GET_STATIC_CONTENTS_DATA:
      return { ...state, loading: true }
    case GET_STATIC_CONTENTS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        [action.payload.slug]: action?.payload?.data,
      }
    }
    case GET_STATIC_CONTENTS_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    //post static content
    case POST_STATIC_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case POST_STATIC_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        staticContentForm: action.payload.data,
      }
    case POST_STATIC_CONTENT_FAILED:
      return {
        ...state,
        loading: false,
        staticContentForm: action.pyload.error,
      }
    //swap position
    case SWAP_POSITION_REQUEST:
      return {
        ...state,
        loading: false,
      }
    case SWAP_POSITION_SUCCESS: {
      let finalOutput = SwapArrayElements(
        state[action.payload.contentType].innerHtml,
        action.payload.hoverIndex,
        action.payload.dragIndex
      )
      return {
        ...state,
        loading: false,
        [action.payload.contentType]: {
          ...state[action.payload.contentType],
          innerHtml: finalOutput,
        },
      }
    }
    case SWAP_POSITION_FAILED:
      return {
        ...state,
        loading: false,
        swappedPos: action.payload.error,
      }
    //product details
    case GET_SIZING_PRODUCTS:
      return { ...state, loading: true }
    case GET_SIZING_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        SizingProducts: action?.payload?.data,
      }
    case GET_SIZING_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.payload?.error,
      }

    //each product Sizes
    case FETCH_EACH_PRODUCT_SIZE_REQUEST:
      return { ...state, loading: true }
    case FETCH_EACH_PRODUCT_SIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
        ProductSizes: action?.payload?.data,
      }
    }
    case FETCH_EACH_PRODUCT_SIZE_FAILED:
      return {
        ...state,
        loading: false,
        error: action?.payload?.error,
      }

    //Post Jacket Sizes
    case POST_JACKET_SIZES.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case POST_JACKET_SIZES.SUCCESS:
      return {
        ...state,
        loading: false,
        postJacketSizes: action.payload.data,
      }
    case POST_JACKET_SIZES.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return { ...state }
  }
}

export default StaticContentsReducer

//some changeType
