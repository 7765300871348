import {
  CiCircleFilled,
  DeleteOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import Loading from "../../../../Components/Admin/Loading/Loading";
import { useEffect, useState } from "react";
import { defaultFunction } from "../../../../Redux/Constants/constants";
import LineEllipsis from "react-lines-ellipsis";

import {
  addProductSizeHardware,
  deleteProductInventory,
  getNonLeather,
  getProductSizeHardware,
} from "../../../../Redux/ProductRedux/ProductApis";
import { getCreateSize, getFormSetData } from "../../../../Utils/ProductSizeHardware";

export interface ProductHardwareSetupProps {
  hardware?: string[];
  sizeRangeId?: string;
  productId?: string;
  productSpecificationId?: string;
  size?: number;
  onClose?: (e: any) => void;
}

const { Panel } = Collapse;

export const ProductHardwareSetup = ({
  hardware,
  sizeRangeId,
  productId,
  size,
  productSpecificationId,
  onClose = defaultFunction,
}: ProductHardwareSetupProps) => {
  const [form] = useForm();
  // form.setFieldsValue({
  //   hardware: hardware?.map((val) => {
  //     return {
  //       hardware: val,
  //       productId: productId,
  //       productSpecificationId: productSpecificationId,
  //       sizeRangeId: sizeRangeId,
  //       size: size,
  //     };
  //   }),
  // });

  const [dropdownData, setDropdownData] = useState([]);

  const [allDropdown, setAllDropdowm] = useState([]);

  const [hardwareData, setHardwareData] = useState<any>();

  const getItems = async () => {
    await getNonLeather({ query: false })
      .then((items) => {
        setDropdownData(items?.data);
        setAllDropdowm(items?.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getItems();
  }, [0]);

  const [loading, setLoading] = useState(false);

  const addSizeItem = async (values: any) => {
    setLoading(true);
    // const data = await addProductSizeHardware(items);

    const items = getCreateSize(values);

    for await (const item of items) {
      await addProductSizeHardware(item)
        ?.then(() => {
          onClose({});
        })
        .catch((error) => {});
    }
    setLoading(false);
  };


  const [getting, setGetting] = useState(false);

  const getInitialData = async () => {
    setGetting(true);
    await getProductSizeHardware({ id: sizeRangeId || "" })
      .then((data) => {
        setGetting(false);

        if (data?.data?.length > 0) {
          const hardwaresData = getFormSetData(data?.data);
          const remainingHardwares = hardware?.filter(
            (h) => !hardwaresData?.hardware?.some((hd) => hd?.hardware === h)
          );

          const remains =
            remainingHardwares?.map((val) => {
              return {
                hardware: val,
                productId: productId,
                productSpecificationId: productSpecificationId,
                sizeRangeId: sizeRangeId,
                size: size,
              };
            }) || [];
          form.setFieldsValue({
            hardware: [...hardwaresData?.hardware, ...remains],
          });
          setHardwareData({
            hardware: [...hardwaresData?.hardware, ...remains],
          });
        } else {
          const hardwareDataElse = {
            hardware: hardware?.map((val) => {
              return {
                hardware: val,
                productId: productId,
                productSpecificationId: productSpecificationId,
                sizeRangeId: sizeRangeId,
                size: size,
              };
            }),
          };
          form.setFieldsValue(hardwareDataElse);
          setHardwareData(hardwareDataElse);
        }
      })
      .catch((error) => {});
    setGetting(false);
  };

  const deleteInventory = async (key: any) => {
    setLoading(true);
    const indexToDelete = key?.key;
    const idToDelete = hardwareData?.hardware?.[indexToDelete]?._id;

    if (!idToDelete) {
      message.error("No inventory found to delete!!");
      setLoading(false);
      return;
    }

    // perform delete operation
    try {
      const data = await deleteProductInventory(idToDelete);
      if (data?.data) {
        getInitialData();
        message.success("Data deleted successfully");
        setLoading(false);
        // onClose({});
      }
    } catch (error) {
      setLoading(false);
      message.error("Some error occur");
    }
  };

  useEffect(() => {
    getInitialData();
  }, [sizeRangeId]);
  return (
    <div style={{ marginTop: 30 }}>
      {(getting || loading) && <Loading />}
      <Form hidden={getting || loading} onFinish={addSizeItem} form={form} layout="vertical">
        <Form.List name="hardware">
          {(fields, { add, remove }, { errors }) => (
            <>
              <Collapse expandIconPosition="right">
                {fields.map((key, name, ...restField) => {
                  // if (hardware?.[key?.name]) {
                  return (
                    <Panel
                      className=""
                      header={
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            // alignItems: "center",
                            width: "95%",
                          }}
                        >
                          <span>{hardwareData?.hardware?.[key?.key]?.hardware}</span>
                          <Popconfirm
                            title="Are you sure you want to delete the inventory?"
                            onConfirm={(e) => {
                              deleteInventory(key);
                              e?.stopPropagation();
                            }}
                            onCancel={(e) => {
                              e?.stopPropagation();
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              style={{
                                color: "red",
                              }}
                            >
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                        </p>
                      }
                      key={name}
                    >
                      <Row justify="start">
                        <Col lg={24}>
                          <Form.List initialValue={[""]} name={[name, "inventory"]}>
                            {(fields, { add: addCategory, remove: removeCategory }) => (
                              <>
                                {fields.map(
                                  (
                                    { key: categoryKey, name: categoryName, ...categoryRestFields },
                                    index: number
                                  ) => {
                                    let inventoryItems: any = [];
                                    let categoryUnit: string = "";
                                    return (
                                      <Row
                                        key={categoryKey}
                                        style={{
                                          display: "flex",
                                          marginBottom: 8,
                                          width: "100%",
                                        }}
                                        gutter={[20, 20]}
                                        align="top"
                                        justify="space-between"
                                      >
                                        <Col lg={6}>
                                          <Form.Item
                                            style={{
                                              marginBottom: 5,
                                            }}
                                            {...restField}
                                            name={[categoryName, "categoryId"]}
                                            label={categoryKey === 0 ? "Category" : false}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Missing category",
                                              },
                                            ]}
                                          >
                                            <Select
                                              style={{
                                                minWidth: 100,
                                              }}
                                              showSearch
                                              // Search on the basis of category label
                                              filterOption={(inputValue, options: any) => {
                                                if (options) {
                                                  return (
                                                    options?.children
                                                      // .join("")
                                                      .toLowerCase()
                                                      .includes(inputValue.toLowerCase())
                                                  );
                                                }
                                              }}
                                            >
                                              {dropdownData?.map((option: any) => {
                                                const selectedCategories = form
                                                  .getFieldsValue()
                                                  ?.hardware[key.key]?.inventory?.map(
                                                    (cat: any) => cat?.categoryId
                                                  );
                                                return (
                                                  <Select.Option
                                                    disabled={selectedCategories?.includes(
                                                      option?._id
                                                    )}
                                                    value={option._id}
                                                  >
                                                    {option?.inventory_category}
                                                  </Select.Option>
                                                );
                                              })}
                                            </Select>
                                          </Form.Item>
                                        </Col>
                                        <Col lg={{ span: 18 }}>
                                          <Form.List
                                            initialValue={[
                                              {
                                                itemId: "",
                                                quantity: 1,
                                                unit: categoryUnit || "pc",
                                              },
                                            ]}
                                            name={[categoryName, "items"]}
                                          >
                                            {(
                                              priceFields,
                                              { add: addItem, remove: removeItem }
                                            ) => (
                                              <>
                                                {priceFields.map(
                                                  (
                                                    {
                                                      key: priceKey,
                                                      name: priceName,
                                                      ...priceRestField
                                                    },
                                                    index: number
                                                  ) => (
                                                    <Row
                                                      gutter={[40, 40]}
                                                      style={{
                                                        display: "flex",
                                                        marginBottom: 8,
                                                      }}
                                                      align="top"
                                                    >
                                                      <Col lg={10}>
                                                        <Form.Item shouldUpdate>
                                                          {() => {
                                                            const tempCatId =
                                                              form.getFieldsValue()?.hardware[
                                                                key.key
                                                              ]?.inventory[categoryName]
                                                                ?.categoryId;
                                                            const newItems: any =
                                                              dropdownData?.find(
                                                                (data: any) =>
                                                                  data?._id === tempCatId
                                                              );

                                                            return (
                                                              <>
                                                                <Form.Item
                                                                  {...restField}
                                                                  name={[priceName, "itemId"]}
                                                                  label={
                                                                    priceKey === 0 &&
                                                                    categoryKey === 0
                                                                      ? "Item"
                                                                      : false
                                                                  }
                                                                  rules={[
                                                                    {
                                                                      required: true,
                                                                      message:
                                                                        "Inventory Item is Required",
                                                                    },
                                                                  ]}
                                                                >
                                                                  <Select
                                                                    style={{
                                                                      width: 250,
                                                                    }}
                                                                    dropdownClassName="disabled__selected_item"
                                                                  >
                                                                    {newItems?.items?.map(
                                                                      (inventoryItem: any) => {
                                                                        const inStock =
                                                                          inventoryItem?.least_quantity <
                                                                          inventoryItem
                                                                            ?.material_select
                                                                            ?.total_quantity;

                                                                        // const isSelected =
                                                                        const isSelected = form
                                                                          .getFieldsValue()
                                                                          ?.hardware[
                                                                            key.key
                                                                          ]?.inventory[
                                                                            categoryName
                                                                          ]?.items?.map(
                                                                            (it: any) => it?.itemId
                                                                          )
                                                                          ?.includes(
                                                                            inventoryItem?._id
                                                                          );

                                                                        return (
                                                                          <Select.Option
                                                                            value={
                                                                              inventoryItem?._id
                                                                            }
                                                                            disabled={isSelected}
                                                                          >
                                                                            <Space>
                                                                              <Tooltip
                                                                                title={
                                                                                  inStock
                                                                                    ? "In Stock"
                                                                                    : "Out of Stock"
                                                                                }
                                                                              >
                                                                                <div
                                                                                  style={{
                                                                                    width: 10,
                                                                                    height: 10,
                                                                                    borderRadius: 5,
                                                                                    backgroundColor:
                                                                                      inStock
                                                                                        ? "green"
                                                                                        : "red",
                                                                                  }}
                                                                                ></div>
                                                                              </Tooltip>
                                                                              <div
                                                                                style={{
                                                                                  width: 180,
                                                                                }}
                                                                              >
                                                                                <Typography.Paragraph
                                                                                  ellipsis
                                                                                  style={{
                                                                                    marginBottom: 0,
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    inventoryItem?.item_name
                                                                                  }
                                                                                </Typography.Paragraph>
                                                                              </div>
                                                                            </Space>
                                                                          </Select.Option>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                  hidden
                                                                  style={{
                                                                    marginBottom: 5,
                                                                  }}
                                                                  label={
                                                                    priceKey === 0 ? "Unit" : false
                                                                  }
                                                                  name={[priceName, "unit"]}
                                                                  initialValue={categoryUnit}
                                                                >
                                                                  <Input />
                                                                </Form.Item>
                                                              </>
                                                            );
                                                          }}
                                                        </Form.Item>
                                                      </Col>
                                                      <Col lg={4}>
                                                        <Form.Item
                                                          style={{
                                                            marginBottom: 5,
                                                          }}
                                                          label={
                                                            priceKey === 0 && categoryKey === 0
                                                              ? "Quantity"
                                                              : false
                                                          }
                                                          name={[priceName, "quantity"]}
                                                        >
                                                          <InputNumber
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                            placeholder="2"
                                                          />
                                                        </Form.Item>
                                                      </Col>
                                                      <Col lg={8}>
                                                        <Form.Item
                                                          style={{
                                                            marginBottom: 5,
                                                          }}
                                                          label={
                                                            categoryKey === 0 && priceKey === 0
                                                              ? "Editable?"
                                                              : false
                                                          }
                                                          name={[priceName, "isEditable"]}
                                                          valuePropName="checked"
                                                          initialValue={false}
                                                        >
                                                          <Switch />
                                                        </Form.Item>

                                                        <Space>
                                                          <PlusCircleOutlined
                                                            onClick={() => addItem()}
                                                          />
                                                          {priceName > 0 && (
                                                            <MinusCircleOutlined
                                                              onClick={() => removeItem(priceName)}
                                                            />
                                                          )}
                                                        </Space>
                                                      </Col>
                                                    </Row>
                                                  )
                                                )}
                                              </>
                                            )}
                                          </Form.List>
                                        </Col>

                                        <Col lg={{ span: 1, offset: 0 }} style={{ paddingTop: 10 }}>
                                          {index === fields?.length - 1 && (
                                            <Space>
                                              <Button onClick={() => removeCategory(categoryName)}>
                                                Remove Category
                                              </Button>
                                              <Button onClick={() => addCategory()} type="primary">
                                                Add Category
                                              </Button>
                                            </Space>
                                          )}
                                        </Col>
                                      </Row>
                                    );
                                  }
                                )}
                                {/* <Row>
                                  <Button onClick={() => deleteInventory(key)}>Delete</Button>
                                </Row> */}
                                {fields.length === 0 ? (
                                  <Button onClick={() => addCategory()} type="primary">
                                    Add Category
                                  </Button>
                                ) : null}
                              </>
                            )}
                          </Form.List>
                        </Col>
                      </Row>
                    </Panel>
                  );
                })}
              </Collapse>
            </>
          )}
        </Form.List>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Col>
            <Button loading={loading} htmlType="submit">
              SUBMIT
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
