import { Reducer } from 'react'
import {
  CREATE_VENDOR,
  CREATE_VENDOR_FAILURE,
  CREATE_VENDOR_SUCCESS,
  FETCH_VENDOR,
  FETCH_VENDORS,
  FETCH_VENDORS_FAILURE,
  FETCH_VENDORS_SUCCESS,
  FETCH_VENDOR_FAILURE,
  FETCH_VENDOR_SUCCESS,
  REMOVE_VENDOR,
  REMOVE_VENDOR_SUCCESS,
  VendorType,

} from './VendorTypes'


const INIT_STATE = {
  vendors: [],
  vendor: {},
  vendorItem:[],
  error: '',
  loading: false,
  creating: false,
  deleting: false
}

export const VendorReducer: Reducer<any, any> = (
  state = INIT_STATE,
  action
) => {
  switch (action.type) {

  //fetch  Vendors
  case FETCH_VENDORS:
    return { ...state, loading: true }
  case FETCH_VENDORS_SUCCESS:
    return { ...state, loading: false, vendors: action.payload }
  case FETCH_VENDORS_FAILURE:
    return { ...state, loading: false, error: action.payload }

  //fetch  Vendor
  case FETCH_VENDOR:
    return { ...state, loading: true }
  case FETCH_VENDOR_SUCCESS:
    return { ...state, loading: false, vendor: action.payload?.vendor, vendorItem: action.payload?.vendor_item }
  case FETCH_VENDOR_FAILURE:
    return { ...state, loading: false, error: action.payload }

  //fetch  Vendor
  case CREATE_VENDOR:
    return { ...state, creating: true }
  case CREATE_VENDOR_SUCCESS:
    return { ...state, creating: false }
  case CREATE_VENDOR_FAILURE:
    return { ...state, creating: false }


  //remove Post
  case REMOVE_VENDOR:
    return { ...state, deleting: true }
  case REMOVE_VENDOR_SUCCESS:
    return {
      ...state,
      deleting: false,
    }
  default:
    return state

  }
}
