import { PaymentStatus } from "Views/Orders/OrdersEnum";
import moment from "moment/moment";

export interface PaymentsData {
  advance_amount: number;
  total_amount: number;
  total_discount: number;

  [key: string]: any;
}
export function parseLeftToPay(data: PaymentsData | any) {
  try {
    if (data.payment_status === PaymentStatus.PAYMENT_COMPLETE) {
      return 0;
    }
    const total = +data?.total_amount || 0;
    const discount = +data?.total_discount || 0;
    const discounted_amount = total - discount;
    return discounted_amount - (+data?.advance_amount || 0);
  } catch (e) {
    console.log("error on parseLeft");
    return 0;
  }
}

export function formatDateToString(date: any) {
  return moment(date).format("DD MMM YYYY");
}

export function sortByPredicate<T extends any>(fn: Function, asc: boolean) {
  return (a: T, b: T) => {
    if (asc) return fn(b).localeCompare(fn(a));
    return fn(a).localeCompare(fn(b));
  };
}

export function disableBackDateFromToday(current: any) {
  const customDate = moment().format("YYYY-MM-DD");
  return current && current < moment(customDate, "YYYY-MM-DD");
}
