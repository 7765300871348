import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Row,
  Spin,
  notification,
  Select,
  Space,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Moment from "moment";
import { CombineReducerType } from "../../Redux/Reducers/rootReducers";
import {
  createInventoryItem,
  fetchInventoryItem,
} from "../../Redux/InventoryItem/InventoryItemActions";
import CommonS3UploadFiles from "../../Components/S3UploadFiles/CommonS3UploadFiles";
import MediaUrl from "../../Utils/mediaUrl";
import { useForm } from "antd/lib/form/Form";
import qs from "query-string";
import { takeInventoryCategoriy } from "../../Redux/InventoryCategory/InventoryCategoryApis";
import QueryString from "qs";

const initialFormState = {
  item_name: "",
  sku: "",
  latido_code: "",
  inventory_category: "",
  brand: "",
  color: "",
  weight: "",
  tags: [],
  description: "",
  icon: "",
  least_quantity: 0,
  high_quantity: 0,
  ball_image: "",
};

export const AddItems = () => {
  const { id } = useParams<any>();
  const [formState, setFormState] = useState<any>(initialFormState);
  const [categoryName, setCategoryName] = useState<string>("");
  const dispatch = useDispatch();
  const { creating } = useSelector((state: CombineReducerType) => state?.InventoryItemReducer);
  const location = useLocation();
  const history = useNavigate();
  const [form] = useForm();
  const { category_id } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  useEffect(() => {
    const { state }: any = location;
    console.log(state, "state");
    if (state?.item_name) {
      setFormState({ ...formState, inventory_category: state?.id });
      setCategoryName(state?.item_name);
    } else {
      if (!id) history(-1);
    }
  }, [location]);

  console.log();
  var { inventoryItem } = useSelector((state: CombineReducerType) => state?.InventoryItemReducer);
  useEffect(() => {
    if (id) {
      dispatch(fetchInventoryItem(id));
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      console.log("incoming", inventoryItem);

      if (inventoryItem.inventory_category.special_unit === false) {
        const data = {
          ...inventoryItem,
          material_select: { ...inventoryItem.material_select, special_unit: undefined },
        };
        setFormState(data);
        form.setFieldsValue(data);
      } else {
        console.log("special_unit", inventoryItem);
        setFormState(inventoryItem);
        form.setFieldsValue(inventoryItem);
      }
    }
  }, [inventoryItem]);

  // set input field value in state.
  // const handleChange = (e: any) => {
  //   const { name, value } = e.target;
  //   setFormState((prevState: any) => ({ ...prevState, [name]: value }));
  // };

  // submit form
  const handleSubmit = async () => {
    if (form.getFieldValue('icon')) {
      const data = {
        ...formState,
        ...form.getFieldsValue(),
        latido_code: form.getFieldsValue()?.latido_code || Moment()?.unix()?.toString(),
      };
      // console.log("DATA JUST BEFORE SUBMITTING STUFF", data);
      if (id) data._id = id;
      await dispatch(createInventoryItem(data));
    } else {
      notification.error({ message: "Please upload Image", placement: "bottom" });
    }
  };

  const handleCancel = () => {
    history(-1);
  };

  const [categoryDetails, setCategoryDetails] = useState<any>({});

  const getInventory = async (id: any) => {
    await takeInventoryCategoriy(id)
      .then((data) => {
        setCategoryDetails(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(categoryDetails, "category");
  useEffect(() => {
    getInventory(category_id);
  }, [category_id]);

  return (
    <>
      <Row style={{ margin: "30px 0" }} gutter={[20, 0]}>
        <Col
          span={15}
          style={{
            display: "flex",
            // justifyContent: 'center',
            alignItems: "center",
          }}
        >
          <h1 className="page-title">Add Items</h1>
          <h5
            style={{
              marginLeft: 30,
              fontSize: 22,
              color: "#828282",
              wordSpacing: 5,
            }}
          >
            {`${categoryName} > Add`}
          </h5>
        </Col>
      </Row>
      <Row>
        <Card style={{ width: "100%", padding: "30px 45px" }}>
          <Form form={form} layout="vertical" onFinish={() => handleSubmit()}>
            <Row gutter={[50, 50]}>
              <Col xxl={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  name="item_name"
                  label="Item Name"
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                    },
                  ]}
                >
                  <Input
                    size="middle"
                    // name="item_name"
                    // value={formState?.item_name}
                    // required={true}
                    // autoFocus={true}
                    // onChange={(e) => handleChange(e)}
                  />
                </Form.Item>
              </Col>
              <Col xxl={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Latido Code"
                  rules={[
                    {
                      required: true,
                      message: "Code is required",
                    },
                  ]}
                  name="latido_code"
                >
                  <Input size="middle" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[50, 50]}>
              <Col xxl={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="SKU"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                  name="sku"
                >
                  <Input size="middle" />
                </Form.Item>
              </Col>
              <Col xxl={12} lg={12} sm={24} xs={24}>
                <Form.Item label="Tags" name="tags">
                  <Select
                    mode="tags"
                    // name='tags'
                    size="middle"
                    value={formState?.tags}
                    // onChange={(e) =>
                    //   handleChange({
                    //     target: {
                    //       name: "tags",
                    //       value: e,
                    //     },
                    //   })
                    // }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[50, 50]}>
              <Col xxl={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Colors"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                  name="color"
                >
                  <Input
                    size="middle"
                    value={formState?.color}
                    // onChange={(e) => handleChange(e)}
                  />
                </Form.Item>
              </Col>
              <Col xxl={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                    {
                      max: 500,
                      message: "Description should be less than 500 characters",
                    },
                  ]}
                  name="description"
                >
                  <Input.TextArea
                    size="middle"
                    // onChange={(e) => handleChange(e)}
                    value={formState?.description}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[50, 50]}>
              <Col xxl={6} lg={6} sm={12} xs={24}>
                <Form.Item
                  label="Least Quantity"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                  name="least_quantity"
                >
                  <Input size="middle" type="number" min={0} />
                </Form.Item>
              </Col>
              <Col xxl={6} lg={6} sm={12} xs={24}>
                <Form.Item
                  label="High Quantity"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                  name="high_quantity"
                >
                  <Input size="middle" min={0} type="number" />
                </Form.Item>
              </Col>
              <Col xxl={6} lg={6} sm={12} xs={12}>
                <Form.Item
                  name="icon"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                  label="Primary Image"
                >
                  <CommonS3UploadFiles
                    accept=".jpg, .jpeg, .png, .webp, .avif"
                    module="item"
                    onUpload={(e) => {
                      setFormState({
                        ...formState,
                        icon: e,
                      });
                      form.setFieldsValue({
                        icon: e,
                      });
                    }}
                    prefix="inventory/category"
                    type="product"
                    url={formState?.icon ? MediaUrl(formState?.icon) : false}
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={6}
                lg={6}
                sm={12}
                xs={12}
                hidden={
                  !(categoryDetails?.special_unit || inventoryItem.inventory_category?.special_unit)
                }
              >
                <Form.Item
                  name="ball_image"
                  rules={[
                    {
                      required: categoryDetails?.special_unit ? true : false,
                      message: "Required",
                    },
                  ]}
                  label="Leather Ball Image"
                >
                  {/* {formState?.icon?.length == 0 ? ( */}
                  <CommonS3UploadFiles
                    accept=".jpg, .jpeg, .png, .webp, .avif"
                    module="item"
                    title="Upload Leather Ball Image"
                    onUpload={(e) => {
                      setFormState({
                        ...formState,
                        ball_image: e,
                      });
                      form.setFieldsValue({
                        ball_image: e,
                      });
                    }}
                    prefix="inventory/category"
                    type="product"
                    url={formState?.ball_image ? MediaUrl(formState?.ball_image) : false}
                  />
                </Form.Item>
              </Col>
              <Col xxl={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Brand"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                  name="brand"
                >
                  <Input size="middle" type="text" />
                </Form.Item>
              </Col>
              <Col xxl={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Weight (KG)"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                  name="weight"
                >
                  <Input size="middle" type="number" min={0} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Col>
                <Space>
                  <Button type="text" size="middle" onClick={() => handleCancel()}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    disabled={creating}
                    htmlType="submit"
                    size="middle"
                    // style={{ padding: "10px 35px", marginLeft: 15 }}
                  >
                    {creating ? <Spin style={{ color: "white" }} /> : "Save"}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      </Row>
    </>
  );
};
