/** @format */

import axios from 'axios'
import { BASE_URL } from '../API/variable'

export const featureProductsListApi = () => {
  let url = `${BASE_URL}/feature-products`
  return axios({
    method: 'GET',
    url: url,
  })
}
export const addFeatureProductApi = async ({
  title,
  description,
  placement,
  status,
}: any) => {
  let url = `${BASE_URL}/feature-products`
  const { data } = await axios.post(url, {
    title,
    description,
    placement,
    status,
  })
  return { data }
  //return axios({
  //  method: "POST",
  //  url: url,
  //});
}

export const addPlacementFormApi = async (value: any) => {
  const { secondForm, values } = value
  const { placement, date, time } = secondForm
  const { textZero, textOne, textTwo, textThree, textFour } = values
  let url = `${BASE_URL}/feature-products`
  const { data }: { data: any } = await axios.post(url, {
    placement,
    date,
    time,
    textZero,
    textOne,
    textTwo,
    textThree,
    textFour,
  })
  return { data }
}
