import React from 'react'
import { Select } from 'antd'
import './style.scss'

interface optionType {
  value: string
  label: string
}

interface propsType {
  option?: any
  handleChange?: (e: string, fieldName: string) => void
  fieldName?: string
  defaultValue?: string
  disabled?: boolean
}
const InputSelect = ({
  option = [],
  handleChange = (e) => {},
  fieldName = 'name',
  defaultValue = '',
  disabled=false
}: propsType) => {
  const { Option } = Select

  return (
    <div>
      <Select
        style={{ width: '100%', minWidth: '300px' }}
        // bordered={false}
        showSearch
        disabled={disabled}
        onChange={(e) => handleChange(e, fieldName)}
        defaultValue={defaultValue}
        className='input-select'
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {option?.map((item: any) => (
          <Option value={item?.value} key={item?.value}>
            {item?.title}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default InputSelect
