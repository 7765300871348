export interface ProductSizeHardware {
  hardware?: Hardware[];
}

export interface Hardware {
  _id?: string;
  hardware?: string;
  productId?: string;
  productSpecificationId?: string;
  sizeRangeId?: string;
  size?: number;
  inventory?: Inventory[];
}

export interface Inventory {
  items?: Item[];
  categoryId?: string;
}

export interface Item {
  itemId?: string;
  quantity?: number;
  unit?: string;
  isEditable?: boolean;
}

export interface ProductSizeHardwareAddVariables {
  productId?: string;
  productSpecificationId?: string;
  sizeRangeId?: string;
  size?: number;
  hardware?: string;
  inventory?: Inventory[];
}

export interface ProductSizeHardwareAddVariablesInventory {
  itemId?: string;
  qunatity?: number;
  unit?: string;
  isEditable?: boolean;
}

export const getCreateSize = (data: ProductSizeHardware) => {
  const addItem: ProductSizeHardwareAddVariables[] | [] | any =
    data.hardware?.map((productSizeHardware) => {
      return {
        _id: productSizeHardware?._id,
        productId: productSizeHardware.productId,
        productSpecificationId: productSizeHardware.productSpecificationId,
        sizeRangeId: productSizeHardware.sizeRangeId,
        hardware: productSizeHardware.hardware,
        size: productSizeHardware.size,
        inventory: productSizeHardware.inventory?.reduce(
          (
            prev: ProductSizeHardwareAddVariablesInventory[],
            curr: Inventory
          ) => {
            return [...prev, ...(curr?.items ? curr?.items : [])];
          },
          []
        ),
      };
    });

  return addItem;
};

export interface ProductSizeHardwareResponse {
  _id?: string;
  productId?: string;
  productSpecificationId?: string;
  sizeRangeId?: string;
  size?: number;
  inventory?: InventoryResponse[];
  hardware?: string;
}

export interface InventoryResponse {
  isEditable?: boolean;
  itemId?: string;
  unit?: string;
  categoryId?: string;
  quantity?: number;
}

export const getFormSetData = (data: ProductSizeHardwareResponse[]) => {
  let hardwares: Hardware[] | [] = data?.map((item) => {
    let groupByCategoryId: Inventory[] = [];

    item?.inventory?.map((inv) => {
      const categoryIndex = groupByCategoryId?.findIndex(
        (gp) => gp.categoryId === inv.categoryId
      );

      if (categoryIndex > -1) {
        groupByCategoryId[categoryIndex] = {
          ...groupByCategoryId[categoryIndex],
          items: [
            ...(groupByCategoryId[categoryIndex].items || []),
            {
              isEditable: inv.isEditable,
              itemId: inv.itemId,
              quantity: inv.quantity,
              unit: inv.unit,
            },
          ],
        };
      } else {
        groupByCategoryId = [
          ...groupByCategoryId,
          {
            categoryId: inv.categoryId,
            items: [
              {
                isEditable: inv.isEditable,
                itemId: inv.itemId,
                unit: inv.unit,
                quantity: inv.quantity,
              },
            ],
          },
        ];
      }
    });
    return {
      _id: item?._id,
      hardware: item?.hardware,
      sizeRangeId: item?.sizeRangeId,
      productId: item?.productId,
      size: item?.size,
      productSpecificationId: item?.productSpecificationId,
      inventory: groupByCategoryId,
    };
  });

  return {
    hardware: hardwares,
  };
};
