import { Card, Col, Form, Input, Row, Typography } from "antd";
import Moment from "moment";
import { parseLeftToPay, PaymentsData } from "../../Utils";
import InputSelect from "../../Components/Select";
import { getPaymentMode } from "../../API";
import moment from "moment";

interface propsType {
  data?: PaymentsData;
  delivered?: boolean;
}
const paymentOption = ["cash", "card", "esewa", "bank_transfer", "paypal", "other"].map((i) => {
  return { value: i, title: i.split("_").join(" ").toUpperCase() };
});
const Payments = ({ data, delivered }: propsType) => {
  const handelSelectChange = (value: string) => {
    getPaymentMode(data?._id, value);
  };
  const left_to_pay = parseLeftToPay(data);

  const leftDays = moment(data?.delivery_date).startOf("day").diff(moment().startOf("day"), "days");
  console.log('left days: ', leftDays);

  return (
    <Card className="payments white-border">
      <h2 className="page-title">Payment</h2>
      <Form layout="vertical" className="payments__forms">
        <Row gutter={[50, 50]} className="form-row">
          <Col sm={24} lg={12}>
            <Form.Item label="Total Amount">
              <Input
                type="text"
                name="name"
                value={data?.total_amount}
                disabled
                className="input-field"
              />
            </Form.Item>
          </Col>
          <Col sm={24} lg={12}>
            <Form.Item label="Discount">
              <Input
                type="text"
                name="name"
                value={data?.total_discount}
                disabled
                className="input-field"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[50, 50]} className="form-row">
          <Col sm={24} lg={12}>
            <Form.Item label="Advance Payment">
              <Input
                type="text"
                name="name"
                value={data?.advance_amount || 0}
                disabled
                className="input-field"
              />
            </Form.Item>
          </Col>
          <Col sm={24} lg={12}>
            <Form.Item label={`${delivered ? "Payment with discount" : "Left To Pay"}`}>
              <Input
                type="text"
                name="left_to_pay"
                value={left_to_pay}
                disabled
                className="input-field"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[50, 50]} className="form-row">
          <Col sm={24} lg={12}>
            <Form.Item label="Deliver Date">
              <Input
                value={Moment(data?.delivery_date).format("YYYY-MM-DD")}
                type="text"
                name="name"
                disabled
                className="input-field"
              />
            </Form.Item>
          </Col>
          <Col sm={24} lg={12}>
            <Form.Item label="Payment Mode" name="store_payment">
              <InputSelect
                handleChange={handelSelectChange}
                defaultValue={data?.payment_mode}
                option={paymentOption}
                disabled
              />
            </Form.Item>
          </Col>
          {delivered ? (
            <Col span={24}>
              <Typography.Text strong type={"success"}>
                Delivered {Math.abs(leftDays)}{" "}
                {leftDays < 0 ? "days ago" : "days before planned delivery"}
              </Typography.Text>
            </Col>
          ) : (
            <Col span={24}>
              <Typography.Text strong type={leftDays < 3 ? "danger" : "warning"}>
                {Math.abs(leftDays)}{" "}
                {leftDays < 0 ? "days past planned delivery" : "days left to deliver"}
              </Typography.Text>
            </Col>
          )}
        </Row>
      </Form>
    </Card>
  );
};

export default Payments;
