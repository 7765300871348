import { API, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiHeaders, API_NAME } from "../API/variable";
import { LatidoAnimatedLogoLoader } from "../Components/LatidoAnimateLogo/LatidoAnimateLogo";
import { SET_CURRENT_USER } from "../Redux/AuthRedux/AuthTypes";

const AdminRoles = [
  { value: "superAdmin", title: "Super Admin" },
  { value: "admin", title: "Admin" },
  { value: "storeManager", title: "Store Manager" },
  { value: "productManager", title: "Product Manager" },
  { value: "factoryManager", title: "Factory Manager" },
  { value: "inventoryManager", title: "Inventory Manager" },
  { value: "orderManager", title: "Order Manager" },
  { value: "karigar", title: "Karigar" },
  { value: "unassigned", title: "Unassigned" },
];

const withPermission = (WrappedComponent: any, portal: string = "") => {
  return (props: any) => {
    const permission: any = {
      superAdmin: [
        "store",
        "product",
        "inventory",
        "staticContents",
        "user",
        "member",
        "exchange",
        // 'blog',
        "grid",
        "featureProduct",
        "leatherProfile",
        "productCategory",
        "admin",
        "leather",
        "commonDashboard",
      ],
      admin: [
        "store",
        "product",
        "inventory",
        "staticContents",
        "user",
        "exchange",
        // 'blog',
        "grid",
        "featureProduct",
        "leatherProfile",
        "productCategory",
        "admin",
        "leather",
        "commonDashboard",
      ],
      storeManager: ["", "store", "commonDashboard"],
      productManager: ["", "productCategory", "product", "commonDashboard"],
      factoryManager: ["", "inventory", "commonDashboard"],
      inventoryManager: ["", "inventory", "commonDashboard"],
      orderManager: ["store", "commonDashboard"],
      karigar: ["leather", "commonDashboard"],
      unassigned: ["", "commonDashboard"],
    };

    const [verified, setVerified] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector((state: any) => state.AuthReducer);

    const checkUserPermission = async () => {
      const res = await Auth.currentAuthenticatedUser();
      if (!res) {
        navigate("/auth/login");
      }
      if (Object.keys(user).length === 0) {
        try {
          const user = await API.get(API_NAME, "/user/adminProfile", {
            headers: ApiHeaders,
          });
          dispatch({ type: SET_CURRENT_USER, payload: user?.data });
        } catch (e) {}
      }
      let hasAccess = false;
      if (user?.role) {
        user.role.forEach((role: string) => {
          if (permission[role].includes(portal)) {
            hasAccess = true;
            return;
          }
        });
      }
      if (!hasAccess) {
        // navigate(-1) hotfix: solve back issue
      }
      setVerified(true);
    };

    useEffect(() => {
      checkUserPermission();
    }, []);

    if (verified) {
      return <WrappedComponent {...props} />;
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            marginTop: 50,
          }}
        >
          <LatidoAnimatedLogoLoader />
        </div>
      );
    }
  };
};

export default withPermission;
