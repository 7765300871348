import { SearchOutlined } from '@ant-design/icons'
import { Card, Input } from 'antd'
import React from 'react'
import './style.scss'

interface propsType {
  placeholder?: string
  value?: string
  setValue?: (e: string) => void
}
const SearchBar = ({
  placeholder = 'Search Order',
  setValue = (e) => {},
  value = '',
}: propsType) => {
  const handleChange = (e: any) => {
    setValue(e?.target?.value)
  }

  return (
    <Input
      placeholder={placeholder}
      suffix={<SearchOutlined />}
      className='search-field'
      onChange={(e) => handleChange(e)}
      value={value}
    />
  )
}

export default SearchBar
