import { FC, useEffect, useState } from "react";
import {
  Button,
  Col,
  Image,
  Modal,
  Row,
  Typography,
  Input,
  message,
  Form,
  Select,
  Checkbox,
  notification,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useCreateOrder } from "../../API/customHooks";
import { useQuery } from "react-query";
import { fetchUsers, getPaymentMode } from "API";
import InputSelect from "Components/Select";
import {
  CompletionProcess,
  PaymentOptions,
  PaymentStatus,
  TransferState,
} from "./OrdersEnum";
import { parseLeftToPay } from "Utils";
import { useSelector } from "react-redux";

interface Props {
  visible?: boolean;
  handleClose?: () => void;
  order?: any;
  refetch?: () => void;
}
interface User {
  address: string;
  createdAt: string;
  email: string;
  email_verified: boolean;
  gender: string;
  is_active: boolean;
  name: string;
  phone: string;
  phone_verified: boolean;
  profile_image: string;
  registered_by: string;
  _id: string;
}

const { TextArea } = Input;

const TransferModal: FC<Props> = ({
  visible = false,
  refetch = () => {},
  handleClose = () => {},
  order = {},
}) => {
  const [form] = Form.useForm();
  const [remarks, setRemarks] = useState("");
  const [selectedUser, setSelectedUser] = useState<any>({});
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debounceSearch, setDebounceSearch] = useState<string>("");
  const [newUserPhone, setNewUserPhone] = useState<string>("");
  const { mutate: createOrder, isLoading, status, error }: any = useCreateOrder();
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const { user: loggedUser } = useSelector((state: any) => state.AuthReducer);

  const { data: userData } = useQuery(["users", debounceSearch], () => fetchUsers(debounceSearch), {
    enabled: Boolean(debounceSearch),
    refetchOnWindowFocus: false,
  });

  const handleSubmit = async () => {
    let data = { ...order, bypass_otp: true, special_request: remarks ? remarks : "", createdBy: loggedUser };
    // if (!order?.for_store && !order?.from_store && !order?.from_suite) {
    //   // transfer consumer to store
    //   console.log('CONSUMER TO STORE')
    //   order.for_store = true;
    //   order.from_store = true;
    // } else if (order?.from_store && !order?.for_store) {
    //   console.log('CUSTOMER TO STORE')
    //   // transfer costumer to store
    //   order.for_store = true;
    // } else if (order?.from_store && order?.for_store) {
    //   console.log('STORE TO CUSTOMER')
    //   // store to customer order transfer
    //   order.for_store = false;
    // }
    // let order_state: any = {};
    if (!order?.for_store && !order?.from_store && !order?.from_suite) {
      // transfer consumer to store
      data.for_store = true;
      data.user_id = null;
      data.shipping_details = null;
      data.transfer_state = TransferState.CONSUMER_STORE;
    } else if (order?.from_store) {
      // transfer customer to store
      data.for_store = true;
      data.from_store = false;
      data.user_id = null;
      data.shipping_details = null;
      data.advance_amount = null;
      data.total_discount = null;
      data.transfer_state = TransferState.CUSTOMER_STORE;
    } else if (order?.for_store) {
      // store to customer order transfer
      if (!isPaymentSuccess) {
        notification.error({ message: "Payment must be done before delivering", placement: "top" });
        return;
      }
      if (selectedUser?._id) {
        data.for_store = false;
        data.from_store = true;
        data.user_id = selectedUser;
        const total_discount = form.getFieldValue("total_discount");
        const payment_mode = form.getFieldValue("payment_mode");
        const payment_status = isPaymentSuccess
          ? PaymentStatus.PAYMENT_COMPLETE
          : PaymentStatus.PAYMENT_INCOMPLETE;

        data.total_discount = Number(total_discount) || 0;
        data.payment_mode = payment_mode;
        data.payment_status = payment_status;
        data.payment_success = isPaymentSuccess;
        data.completion_process = CompletionProcess.DELIVERED;
        data.delivery_date = new Date().toUTCString();
        data.transfer_state = TransferState.STORE_CUSTOMER;
      } else {
        notification.error({ message: "Something went wrong!!", placement: "top" });
        return;
      }
    }
    if (data) {
      try {
        // console.log('payload: ', data);
        await createOrder(data);
      } catch (error:any) {
        console.error("Error:", error);
        notification.error({ message: error?.message || "Something went wrong !!", placement: "top" })
      } finally {
        setSelectedUser({});
        form.resetFields();
        setRemarks("");
      }
    }
  };

  const createNewCustomer = () => {
    const regex = /^(?:\+977)?[9][6-9]\d{8}$/;
    if (regex.test(newUserPhone)) {
      const queryParams = new URLSearchParams({
        phone: newUserPhone?.includes("+977") ? newUserPhone?.replace("+977", "") : newUserPhone,
        store: "true",
      });
      const url = `/store/create/order/create-new/${queryParams.toString()}`;
      window.open(url, "_blank");
    } else {
      message.error("Please enter valid number");
    }
  };

  useEffect(() => {
    if (status === "success") {
      handleClose();
      message.success(
        `Order successfully transferred to ${order?.from_store ? "Store" : "Customer"}`
      );
      refetch();
    } else {
      error?.response?.data?.message && message.error(error?.response?.data?.message);
    }
  }, [status]);

  useEffect(() => {
    setRemarks("");
  }, [visible]);

  const onSearch = (search: string) => {
    setSearchQuery(search);
  };

  useEffect(() => {
    const debounceSet = setTimeout(() => {
      setDebounceSearch(searchQuery);
    }, 500);
    return () => clearTimeout(debounceSet);
  }, [searchQuery]);

  const findSelectedUser = (id: string) => {
    const user = userData?.filter((user: User) => user._id === id);
    setSelectedUser(user?.[0]);
  };

  const handelSelectChange = (value: string) => {
    // store_payment
    form.setFieldValue("payment_mode", value);
  };

  const paymentOption = PaymentOptions.map((i) => {
    return { value: i, title: i.split("_").join(" ").toUpperCase() };
  });

  useEffect(() => {
    setIsPaymentSuccess(order.payment_status === PaymentStatus.PAYMENT_COMPLETE ? true : false);

    const advance_amount = order?.advance_amount;
    const total_amount = order?.total_amount;
    const total_discount = order?.total_discount;
    setRemarks(order?.special_request)
    form.setFieldsValue({
      payment_gateway: order?.payment_gateway,
      advance_amount: order?.advance_amount,
      payment_mode: order?.payment_mode,
      base_size: order?.sizing?.base_size,
      username: order?.user_id?.name,
      phone: order?.user_id?.phone,
      name: order?.product?.name,
      leather_profile: order?.product_specification?.leather_id?.item_name,
      total_amount: order?.total_amount,
      total_discount: order?.total_discount,
      tax_amount: order?.tax_amount,
      total_payable: order?.total_payable,
      left_to_pay: parseLeftToPay({
        advance_amount,
        total_amount,
        total_discount,
      }),
    });
  }, [order]);

  const discount = Form.useWatch("total_discount", form);
  useEffect(() => {
    if (order?.total_amount > discount) {
      const leftToPay = parseLeftToPay({
        total_amount: order?.total_amount,
        advance_amount: discount,
      });
      if (leftToPay) {
        form.setFieldValue("left_to_pay", leftToPay);
      }
    } else if (discount > order?.total_amount) {
      form.setFieldValue("total_discount", 0);
      notification.error({
        message: "Discount cannot be more than total amount!!",
        placement: "top",
      });
      return;
    }
  }, [discount]);

  return (
    <Modal
      centered
      visible={visible}
      onCancel={handleClose}
      footer={null}
      closable={false}
      maskStyle={{ backdropFilter: "blur(4px)", background: "rgba(0,0,0,0.3)" }}
      destroyOnClose={true}
      width={`${order?.for_store ? "80%" : "45%"}`}
      bodyStyle={{ padding: "50px" }}
      className="success-model"
    >
      {order?.for_store ? (
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            scrollToFirstError={{ behavior: "smooth", block: "center" }}
          >
            <Row justify="end">
              <div className="close-icon-container" onClick={() => handleClose()}>
                <CloseOutlined />
              </div>
            </Row>
            <Row justify="center">
              <Image
                src={require("../../Assets/Icons/success-file.png")}
                preview={false}
                style={{ width: 80 }}
              />
            </Row>
            <Row justify="center" style={{ marginTop: "30px" }}>
              <Typography.Title level={2} style={{ color: "#43464D" }}>
                {order?.from_store ? "Transfer to Store": "Deliver to Customer"}
              </Typography.Title>
            </Row>
            <Row justify="center">
              <p style={{ fontSize: "clamp(14px, 0.9vw, 20px)", marginTop: -10 }}>
                Order information is sent to the email of the user.
              </p>
            </Row>
            <Form.Item
              label="Contact number"
              name="contact-number"
              rules={[{ required: true, message: "Please select the customer" }]}
            >
              <Select
                showSearch
                onSearch={onSearch}
                placeholder="Enter customer phone"
                filterOption={false}
                onChange={findSelectedUser}
                notFoundContent={
                  <Form.Item label="Create a new user">
                    <Input
                      className="phone-input-field-prefix"
                      prefix="+977"
                      placeholder="98XXXXXXXXX"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setNewUserPhone(e?.target?.value)
                      }
                      maxLength={10}
                      type="number"
                    />
                    <Button
                      style={{
                        margin: "1rem 0",
                      }}
                      className="btn confirm-btn"
                      onClick={createNewCustomer}
                    >
                      Create new user
                    </Button>
                  </Form.Item>
                }
              >
                {userData?.map((d: User) => {
                  return (
                    <Select.Option key={d._id} value={d._id}>
                      {d.phone} | {d.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {selectedUser?._id ? (
              <Row style={{ margin: "3rem 0" }}>
                <Row gutter={[10, 0]} className="form-row">
                  <Col sm={24}>
                    <Typography.Title level={3} style={{ color: "#707580" }}>
                      Payment Form
                    </Typography.Title>
                  </Col>

                  <Col sm={24} lg={12}>
                    <Form.Item label="Total Amount" name="total_amount" required>
                      <Input type="number" className="input-field" disabled />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item label="Total Discount" name="total_discount" required>
                      <Input type="number" className="input-field-container" />
                    </Form.Item>
                  </Col>
                  {/* </Row> */}
                  {/* <Row gutter={[50, 50]} className="form-row"> */}
                  {/* <Col sm={24} lg={12}>
                    <Form.Item label="advance" name="advance_amount" required>
                      <Input type="number" className="input-field" />
                    </Form.Item>
                  </Col> */}
                  <Col sm={24} lg={12}>
                    <Form.Item label="Amount to pay" name="left_to_pay">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  {/* </Row> */}
                  {/* <Row gutter={[50, 50]} className="form-row"> */}
                  <Col sm={24} lg={12}>
                    <Form.Item label="Payment Mode" name="payment_mode" required>
                      <InputSelect
                        handleChange={handelSelectChange}
                        defaultValue={order?.payment_mode}
                        option={paymentOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item label="Payment" name="payment_success" required>
                      <Checkbox
                        name="payment_success"
                        onChange={(e) => setIsPaymentSuccess(e?.target?.checked)}
                        checked={isPaymentSuccess}
                      >
                        Payment Status
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Row>
            ) : null}
            <Row>
              <Col sm={24}>
                <Typography.Title level={3} style={{ color: "#707580" }}>
                  Add Remarks
                </Typography.Title>
              </Col>
              <Col sm={24}>
                <TextArea
                  rows={4}
                  placeholder="Write your remarks here....."
                  style={{ fontSize: "16px", padding: 15 }}
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </Col>
            </Row>

            <Row justify="center" style={{ marginTop: "30px" }}>
              <Button onClick={() => handleClose()} className="cancel-btn btn">
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                loading={isLoading}
                className="confirm-btn btn"
                htmlType="submit"
              >
                {order?.from_store ? "Confirm": "Deliver"}
              </Button>
            </Row>
          </Form>
        </div>
      ) : (
        <div>
          <Row justify="end">
            <div className="close-icon-container" onClick={() => handleClose()}>
              <CloseOutlined />
            </div>
          </Row>
          <Row justify="center">
            <Image
              src={require("../../Assets/Icons/success-file.png")}
              preview={false}
              style={{ width: 80 }}
            />
          </Row>
          <Row justify="center" style={{ marginTop: "30px" }}>
            <Typography.Title level={2} style={{ color: "#43464D" }}>
              Transfer To {order?.for_store ? "Customer" : "Store"}
            </Typography.Title>
          </Row>
          <Row justify="center">
            <p style={{ fontSize: "clamp(14px, 0.9vw, 20px)", marginTop: -10 }}>
              Order information is sent to the email of the user.
            </p>
          </Row>
          <Row>
            <Col sm={24}>
              <Typography.Title level={3} style={{ color: "#707580" }}>
                Add Remarks
              </Typography.Title>
            </Col>
            <Col sm={24}>
              <TextArea
                rows={4}
                placeholder="Write your remarks here....."
                style={{ fontSize: "16px", padding: 15 }}
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </Col>
          </Row>

          <Row justify="center" style={{ marginTop: "30px" }}>
            <Button onClick={() => handleClose()} className="cancel-btn btn">
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit()}
              disabled={isLoading}
              loading={isLoading}
              className="confirm-btn btn"
            >
              Confirm
            </Button>
          </Row>
        </div>
      )}
    </Modal>
  );
};

export default TransferModal;
