import { Button, Card, Col, Divider, Row, Typography } from "antd";
import jacket from "../../../Assets/Products/jacket.png";
import jacket2 from "../../../Assets/Products/wallet.png";
import { ReactComponent as ExchangeIcon } from "../../../Assets/Icons/Exchange.svg";
import { ReactComponent as RefundIcon } from "../../../Assets/Icons/Refund.svg";
import { Content } from "antd/lib/layout/layout";
import dayjs from "dayjs";
export interface ExchangeCartTypes {
  size?: "small" | "medium";
  refund?: boolean;
}
export const ExchangeRefundItemDetails = (props: any) => {
  const { data } = props;
  return (
    <Card>
      <Row justify="end">
        <Typography.Text>ORDER ID: {data?.orderId}</Typography.Text>
      </Row>
      <Row justify="center">
        <Col lg={12}>
          <Content
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "40px 0px",
            }}
          >
            <img src={data?.productImage} alt="Product" />
          </Content>
          <Typography.Title className="product-name" level={3} style={{ textAlign: "center" }}>
            {data?.productName}
          </Typography.Title>
          <Typography.Title
            className="product-name"
            level={3}
            style={{ textAlign: "center", padding: "12px 0px" }}
          >
            RS. {data?.price}
          </Typography.Title>
          <Typography.Paragraph style={{ textAlign: "center" }}>
            {data?.date ? dayjs(data?.date).format("LLL") : "N/A"}
          </Typography.Paragraph>
        </Col>
      </Row>
      <Divider />
      <Row justify="center" gutter={[20, 20]}>
        <Col lg={22}>
          <Typography.Text strong>Reason for Refund:</Typography.Text>
          <Card
            style={{ marginTop: 20, padding: "10px 20px" }}
            bodyStyle={{ padding: 0 }}
            className="neumorphify inset"
          >
            {data?.remarks}
          </Card>
        </Col>
        <Col lg={22}>
          <Button type="primary" className="neumorphify" shape="round">
            Approve
          </Button>
          <Button type="text" shape="round">
            Cancel
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export const ExchangeRefundItem = (props: any) => {
  const { size, data } = props;
  let image = "";
  let style = {};
  let imgStyle = {};
  if (size === "small") {
    image = jacket2;
    style = {
      // width: 250,
      width: "90%",
      height: "25vh",
    };
    imgStyle = {
      height: "10vh",
      maxWidth: "100%",
    };
  } else {
    image = jacket;
    style = {
      width: "90%",
      height: "40vh",
    };
    imgStyle = {
      height: "20vh",

      maxWidth: "100%",
    };
  }
  return (
    <Card style={{ ...style, marginBottom: 20 }} onClick={() => props.onClick(data?._id)}>
      <Row justify="space-between" align="middle">
        {data?.return_process === "return_requested" ? <RefundIcon /> : <ExchangeIcon />}
        <Typography.Text>{dayjs(data?.createdAt).format("LL")}</Typography.Text>
      </Row>
      <Row
        justify="center"
        style={{
          padding: "10px 0px",
          // marginBottom: 10,
        }}
      >
        <img src={data?.products?.primary_image} style={imgStyle} alt="Product" />
      </Row>
      <Typography.Title level={5} style={{ textAlign: "center" }}>
        #{data?.order_number}
      </Typography.Title>
      <Typography.Paragraph ellipsis style={{ textAlign: "center" }}>
        {data?.products?.name}
      </Typography.Paragraph>
    </Card>
  );
};

export default ExchangeRefundItem;
