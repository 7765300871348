//product names
export const FETCH_PRODUCT_SIZES_REQUEST = "FETCH_PRODUCT_SIZES_REQUEST";
export const FETCH_PRODUCT_SIZES_SUCCESS = "FETCH_PRODUCT_SIZES_SUCCESS";
export const FETCH_PRODUCT_SIZES_FAILED = "FETCH_PRODUCT_SIZES_FAILED";

//get product sizes
export const GET_SIZING_PRODUCTS = "GET_SIZING_PRODUCTS";
export const GET_SIZING_PRODUCTS_SUCCESS = "GET_SIZING_PRODUCTS_SUCCESS";
export const GET_SIZING_PRODUCTS_FAILURE = "GET_SIZING_PRODUCTS_FAILURE";

//product sizes
export const FETCH_EACH_PRODUCT_SIZE_REQUEST = "FETCH_EACH_PRODUCT_SIZE_REQUEST";
export const FETCH_EACH_PRODUCT_SIZE_SUCCESS = "FETCH_EACH_PRODUCT_SIZE_SUCCESS";
export const FETCH_EACH_PRODUCT_SIZE_FAILED = "FETCH_EACH_PRODUCT_SIZE_REQUEST";

//Get Static Contents Data
export const GET_STATIC_CONTENTS_DATA = "GET_STATIC_CONTENT_DATA";
export const GET_STATIC_CONTENTS_DATA_SUCCESS = "GET_STATIC_CONTENTS_DATA_SUCCESS";
export const GET_STATIC_CONTENTS_DATA_FAILURE = "GET_STATIC_CONTENTS_DATA_FAILURE";

//factory tour table contents
export const FETCH_FACTORY_TOUR_TABLE_CONTENTS_REQUEST =
  "FETCH_FACTORY_TOUR_TABLE_CONTENTS_REQUEST";
export const FETCH_FACTORY_TOUR_TABLE_CONTENTS_SUCCESS =
  "FETCH_FACTORY_TOUR_TABLE_CONTENTS_SUCCESS";
export const FETCH_FACTORY_TOUR_TABLE_CONTENTS_FAILED = "FETCH_FACTORY_TOUR_TABLE_CONTENTS_FAILED";

//about us table contents
export const FETCH_ABOUT_TABLE_CONTENT_REQUEST = "FETCH_ABOUT_TABLE_CONTENT_REQUEST";
export const FETCH_ABOUT_TABLE_CONTENT_SUCCESS = "FETCH_ABOUT_TABLE_CONTENT_SUCCESS";
export const FETCH_ABOUT_TABLE_CONTENT_FAILED = "FETCH_ABOUT_TABLE_CONTENT_FAILED";

//how to table contents
export const FETCH_HOW_TO_TABLE_CONTENT_REQUEST = "FETCH_HOW_TO_TABLE_CONTENT_REQUEST";
export const FETCH_HOW_TO_TABLE_CONTENT_SUCCESS = "FETCH_HOW_TO_TABLE_CONTENT_SUCCESS";
export const FETCH_HOW_TO_TABLE_CONTENT_FAILED = "FETCH_HOW_TO_TABLE_CONTENT_FAILED";

//Post request jacket sizes
export enum POST_JACKET_SIZES {
  REQUEST = "REQUEST",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

//Post request static contents form with title, shortCode and values
export const POST_STATIC_CONTENT_REQUEST = "POST_STATIC_CONTENT_REQUEST";
export const POST_STATIC_CONTENT_SUCCESS = "POST_STATIC_CONTENT_SUCCESS";
export const POST_STATIC_CONTENT_FAILED = "POST_STATIC_CONTENT_FAILED";

//Post request static contents form with title, shortCode and values
export const SWAP_POSITION_REQUEST = "SWAP_POSITION_REQUEST";
export const SWAP_POSITION_SUCCESS = "SWAP_POSITION_SUCCESS";
export const SWAP_POSITION_FAILED = "SWAP_POSITION_FAILED";

//newsletter table contents
export const FETCH_NEWSLETTER_TABLE_CONTENT_REQUEST = "FETCH_NEWSLETTER_TABLE_CONTENT_REQUEST";
export const FETCH_NEWSLETTER_TABLE_CONTENT_SUCCESS = "FETCH_NEWSLETTER_TABLE_CONTENT_SUCCESS";
export const FETCH_NEWSLETTER_TABLE_CONTENT_FAILED = "FETCH_NEWSLETTER_TABLE_CONTENT_FAILED";

//newsletter table contents
export const POST_NEWSLETTER_FORM_REQUEST = "POST_NEWSLETTER_FORM_REQUEST";
export const POST_NEWSLETTER_FORM_SUCCESS = "POST_NEWSLETTER_FORM_SUCCESS";
export const POST_NEWSLETTER_FORM_FAILURE = "POST_NEWSLETTER_FORM_FAILURE";

//get promocode data
export const GET_PROMOCODE_REQUEST = "GET_PROMOCODE_REQUEST";
export const GET_PROMOCODE_SUCCESS = "GET_PROMOCODE_SUCCESS";
export const GET_PROMOCODE_FAILURE = "GET_PROMOCODE_FAILURE";

//get promocode data
export const POST_PROMOCODE_REQUEST = "POST_PROMOCODE_REQUEST";
export const POST_PROMOCODE_SUCCESS = "POST_PROMOCODE_SUCCESS";
export const POST_PROMOCODE_FAILURE = "POST_PROMOCODE_FAILURE";

//feature table contents
export const GET_FEATURE_PRODUCTS_REQUEST = "GET_FEATURE_PRODUCTS_REQUEST";
export const GET_FEATURE_PRODUCTS_SUCCESS = "GET_FEATURE_PRODUCTS_SUCCESS";
export const GET_FEATURE_PRODUCTS_FAILED = "GET_FEATURE_PRODUCTS_FAILED";

//add feature product
export const ADD_FEATURE_PRODUCT_REQUEST = "ADD_FEATURE_PRODUCT_REQUEST";
export const ADD_FEATURE_PRODUCT_SUCCESS = "ADD_FEATURE_PRODUCT_SUCCESS";
export const ADD_FEATURE_PRODUCT_FAILURE = "ADD_FEATURE_PRODUCT_FAILURE";

//add placement form values
export const ADD_PLACEMENT_FORM_REQUEST = "ADD_PLACEMENT_FORM_REQUEST";
export const ADD_PLACEMENT_FORM_SUCCESS = "ADD_PLACEMENT_FORM_SUCCESS";
export const ADD_PLACEMENT_FORM_FAILURE = "ADD_PLACEMENT_FORM_FAILURE";

//leather profile
export const GET_LEATHER_PROFILE = "GET_LEATHER_PROFILE";
export const GET_LEATHER_PROFILE_SUCCESS = "GET_LEATHER_PROFILE_SUCCESS";
export const GET_LEATHER_PROFILE_FAILURE = "GET_LEATHER_PROFILE_FAILURE";

//leather Add On
export const GET_LEATHER_ADD_ON = "GET_LEATHER_ADD_ON";
export const GET_LEATHER_ADD_ON_SUCCESS = "GET_LEATHER_ADD_ON_SUCCESS";
export const GET_LEATHER_ADD_ON_FAILURE = "GET_LEATHER_ADD_ON_FAILURE";

export const POST_LEATHER_ADD_ON = "POST_LEATHER_ADD_ON";
export const POST_LEATHER_ADD_ON_SUCCESS = "POST_LEATHER_ADD_ON_SUCCESS";
export const POST_LEATHER_ADD_ON_FAILURE = "POST_LEATHER_ADD_ON_FAILURE";

//leather Inventory On
export const GET_LEATHER_INVENTORY = "GET_LEATHER_INVENTORY";
export const GET_LEATHER_INVENTORY_SUCCESS = "GET_LEATHER_INVENTORY_SUCCESS";
export const GET_LEATHER_INVENTORY_FAILURE = "GET_LEATHER_INVENTORY_FAILURE";

//leather profile
export const POST_LEATHER_PROFILE = "POST_LEATHER_PROFILE";
export const POST_LEATHER_PROFILE_SUCCESS = "POST_LEATHER_PROFILE_SUCCESS";
export const POST_LEATHER_PROFILE_FAILURE = "POST_LEATHER_PROFILE_FAILURE";

export const defaultFunction = () => {};
export const defaultFunctionArg = (e: any) => {};
export const SET_LAYOUT = "SET_LAYOUT";
