/** @format */

import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Card, Carousel, Col, Image, Row, Typography } from 'antd'
import { createRef, FC, useRef } from 'react'

import ImageIcon from '../../../Assets/Icons/ImageIcon.svg'
import MediaUrl from '../../../Utils/mediaUrl'
import './style.css'

interface CarouselTypes {
  onState?: (e: string) => void
  onTypes?: any
  type?: string
  LeatherProfileList?: any[]
  selected?: string
  handleSelect?: (v: string, e: string) => void
  name?: string
}
const CarouselLeatherProfile: FC<CarouselTypes> = ({
  name = '',
  onState = () => {},
  onTypes,
  LeatherProfileList,
  selected = '',
  handleSelect = () => {},
}) => {
  const scrollWrapper = createRef<HTMLDivElement>()

  const moveScrollWrapper = (dir: string) => {
    if (scrollWrapper.current) {
      if (dir === 'left') {
        scrollWrapper.current.scrollLeft -= 190
      } else if (dir === 'right') {
        scrollWrapper.current.scrollLeft += 190
      }
    }
  }
  return (
    <div className='sliderContainer'>
      <div
        className='scroller left-scroll'
        onClick={() => moveScrollWrapper('left')}
      >
        <ArrowLeftOutlined />
      </div>
      <div
        className='scroller right-scroll'
        onClick={() => moveScrollWrapper('right')}
      >
        <ArrowRightOutlined />
      </div>
      <div className='innerSlider' ref={scrollWrapper}>
        {LeatherProfileList?.map((LeatherProfile: any) => {
          return (
            <Col
              key={LeatherProfile?._id}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => handleSelect(name, LeatherProfile?._id)}
            >
              <div
                style={{
                  padding: '10px 20px',
                  width: '300px',
                  borderRadius: '10px',
                  boxShadow:
                    '2px 2px 4px rgba(114, 142, 171, 0.2), -6px -6px 20px 5px rgba(255, 255, 255, 0.7), 4px 4px 20px rgba(111, 140, 176, 0.5)',
                }}
                className={`${
                  selected === LeatherProfile?._id ? 'selected' : ''
                }`}
              >
                <div
                  className='image-container'
                  style={{
                    width: '100%',
                    height: '200px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    style={{
                      width: '100px',
                      height: '100px',
                      objectFit: 'contain',
                    }}
                    preview={false}
                    src={
                      LeatherProfile?.ball_image
                        ? MediaUrl(LeatherProfile?.ball_image)
                        : ImageIcon
                    }
                    alt={LeatherProfile?._id}
                  />
                </div>
                <div>
                  <Row gutter={[30, 30]} justify='center'>
                    <h3
                      style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        marginLeft: 5,
                      }}
                    >
                      {LeatherProfile?.title || LeatherProfile?.item_name}
                    </h3>
                  </Row>
                </div>
              </div>
            </Col>
          )
        })}
      </div>
    </div>
  )
}
export default CarouselLeatherProfile
