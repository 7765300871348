/** @format */

import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Image, Pagination, Row, Space, Spin, Tabs, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetLeatherProfile } from "../../../Redux/Actions/LeatherProfileAction";
import { RootStateOrAny } from "react-redux";
import QueueAnim from "rc-queue-anim";
import ImageIcon from "../../../Assets/Icons/ImageIcon.svg";
import { useLocation, useNavigate } from "react-router";
import MediaUrl from "../../../Utils/mediaUrl";
import { LatidoAnimatedLogoLoader } from "../../../Components/LatidoAnimateLogo/LatidoAnimateLogo";
import qs from "query-string";
interface ObjectTypes {
  type?: string;
  typeName?: string;
}
const LeatherProfile: FC = () => {
  const location = useLocation();
  const [paginate, setPaginate] = useState<any>(
    location?.search
      ? qs.parse(location?.search)
      : {
          page: 1,
          limit: 12,
        }
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const LeatherProfile = useSelector((state: RootStateOrAny) => state?.LeatherProfileReducer);
  const { LeatherProfileList, loading } = LeatherProfile;
  const history = useNavigate();
  useEffect(() => {
    history(`?${qs.stringify(paginate)}`);
    if (LeatherProfile?.page_no != paginate?.page || LeatherProfile?.limit != paginate?.limit) {
      dispatch(GetLeatherProfile({ page: paginate?.page, limit: paginate?.limit }));
    }
  }, [paginate]);

  const handleNavigate = (item: any) => {
    navigate("/leather/" + item.id, { state: { ...item } });
  };

  return (
    <>
      <Row align="middle" gutter={[40, 20]} className="neumorphic" style={{ marginBottom: 40 }}>
        <Col>
          <Typography.Title
            className="product-name"
            style={{
              marginTop: "13px",
            }}
          >
            LEATHER MANAGEMENT
          </Typography.Title>
        </Col>
        <Col>
          <Button
            type="primary"
            size="middle"
            shape="round"
            onClick={() => navigate("/leather/add")}
          >
            <span style={{ fontWeight: 100 }}>
              Add Leather <PlusOutlined />
            </span>
          </Button>
        </Col>
      </Row>
      {!loading && (
        <Row gutter={[30, 30]}>
          {LeatherProfileList &&
            LeatherProfileList?.data?.length > 0 &&
            LeatherProfileList?.data?.map((d: any, i: number) => {
              return (
                <Col
                  lg={6}
                  xxl={4}
                  sm={12}
                  xs={24}
                  key={i}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleNavigate(d)}
                >
                  <div
                    style={{
                      padding: "10px 20px",
                      width: "100%",
                      borderRadius: "10px",
                      boxShadow:
                        "2px 2px 4px rgba(114, 142, 171, 0.2), -6px -6px 20px 5px rgba(255, 255, 255, 1), 4px 4px 20px rgba(111, 140, 176, 0.5)",
                    }}
                  >
                    <div
                      className="image-container"
                      style={{
                        width: "100%",
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        style={{
                          width: "150px",
                          height: "150px",
                          objectFit: "contain",
                        }}
                        preview={false}
                        src={MediaUrl(d?.inventory_item?.icon) || ImageIcon}
                        alt={d?._id}
                      />
                    </div>
                    <div>
                      <Row gutter={[30, 30]} justify="center">
                        <h3
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginLeft: 5,
                          }}
                        >
                          {d?.inventory_item?.item_name}
                        </h3>
                      </Row>
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
      )}
      {!loading && LeatherProfileList?.total_data > 0 && (
        <Row
          justify="center"
          style={{
            marginTop: "50px",
          }}
        >
          <Pagination
            total={LeatherProfileList?.total_data}
            pageSize={paginate.limit}
            pageSizeOptions={[10, 12, 20, 50, 100]}
            onChange={(page: number, pageSize?: number) =>
              setPaginate({ ...paginate, page: page, limit: pageSize })
            }
            current={LeatherProfileList?.page_no}
          />
        </Row>
      )}
      {!loading && LeatherProfile?.total_data === 0 && (
        <Row
          justify="center"
          style={{
            marginTop: "50px",
          }}
        >
          <Typography.Title level={5}>No Leather Profile Found</Typography.Title>
        </Row>
      )}

      {loading && (
        <Row justify="center">
          <LatidoAnimatedLogoLoader />
        </Row>
      )}
    </>
  );
};
export default LeatherProfile;
